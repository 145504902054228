import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { AxiosError } from "axios";
import { ImportListViewArrayODataValue } from "../../../../../modules/api-client/generated";

export const useListImportsOdata = ($top?:number|undefined, $skip?:number|undefined, $orderBy?: string, $filter?: string, $count:boolean|undefined = false, enabled: boolean | undefined = true) => {

    if ($filter === "") {
        $filter = undefined
    }
    
    const key = 'aclImportListOdataGet';

    const response = useQuery<ImportListViewArrayODataValue, unknown, ImportListViewArrayODataValue>(
        [key, $filter, $top, $skip, $count, $orderBy], () => {
        return ApiClient.Acl.Import
            .aclImportListOdataGet($top,$skip,$orderBy, $filter, $count, undefined, undefined)
            .then(d => d.data);
    },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};
