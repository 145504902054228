/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {useEkosietFilter} from '../../../../../shared/context/FilterContext';
import {EkoTable, TableHeader} from '../../../../../shared/components/table';
import {ImportedProfileMappingRepresentation} from '../../../../../modules/api-client/generated';
import ProfilePreviewSvg from '../../../../../shared/components/ProfilePreviewSvg';
import {useDeleteImportProfileMapping} from '../hooks/use-delete-import-profile-mapping';
import {ProfileMapperModal} from './ProfileMapperModal';
import {useGetCustomerImportedProfileMappings} from '../hooks/use-get-customer-imported-profile-mappings';

const CustomerImportProfileMappings: React.FC = () => {
	const {filters} = useEkosietFilter();
	const [selectedProfileMapping, setSelectedProfileMapping] = useState<ImportedProfileMappingRepresentation | undefined>(undefined);
	const customerId = filters.length ? filters[0].values[0] : '';
	const {isInitialLoading, data: customerImportedProfileMappings, isError} = useGetCustomerImportedProfileMappings(customerId);
	var mutationDeleteImportProfileMapping = useDeleteImportProfileMapping();

	if (isInitialLoading) {
		return <>Laden...</>;
	}

	if (isError) {
		return <>Fout bij het ophalen van de klant import profiel mappings!</>;
	}

	if (!filters.length) {
		return <>Selecteer een klant</>;
	}

	return (
		<>
			{(!customerImportedProfileMappings || !customerImportedProfileMappings.length) && <>Geen profiel mappings gevonden voor deze klant</>}

			{selectedProfileMapping && (
				<ProfileMapperModal
					profile={selectedProfileMapping}
					show={selectedProfileMapping !== undefined}
					handleClose={() => {
						setSelectedProfileMapping(undefined);
					}}
				></ProfileMapperModal>
			)}

			<EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
				<TableHeader>
					<th className="min-w-100px">Geïmporteerd Profiel</th>
					<th className="min-w-100px">Klant</th>
					<th className="min-w-100px">Dorpel</th>
					<th className="min-w-100px">Vak indeling</th>
					<th className="min-w-100px">Neut positie</th>
					<th className="min-w-100px">EDS profiel</th>
					<th className="min-w-100px">EDS naam</th>
					<th className="min-w-100px">Acties</th>
				</TableHeader>
				<tbody>
					{customerImportedProfileMappings &&
						customerImportedProfileMappings &&
						customerImportedProfileMappings.map((item: ImportedProfileMappingRepresentation, index: number) => (
							<tr key={index}>
								<td className="fit-content user-select-none">
									<div style={{height: '80px', width: '80px'}}>
										<ProfilePreviewSvg profileSvg={item.leftProfileGeometrySvg} />
									</div>
								</td>
								<td>{item.companyName}</td>
								<td>{item.sillCode}</td>
								<td>{item.compartmentLayoutName}</td>
								<td>standaard, niet ingesprongen</td>
								<td className="fit-content user-select-none">
									{item.leftMappedProfileGeometrySvg && (
										<div style={{height: '80px', width: '80px'}}>
											<ProfilePreviewSvg profileSvg={item.leftMappedProfileGeometrySvg} />
										</div>
									)}
								</td>
								<td>{item.mappedProfileName && <span>{item.mappedProfileName}</span>}</td>
								<td className="fit-content">
									{!item.mappedProfileId ? (
										<button
											className={`btn btn-light btn-sm me-3`}
											title={item.mappedProfileId ? 'Wijzig EDS profiel' : 'Kies EDS Profiel'}
											onClick={() => {
												setSelectedProfileMapping(item);
											}}
										>
											{item.mappedProfileId ? 'Wijzig EDS profiel' : 'Kies EDS Profiel'}
										</button>
									) : (
										<>
											<button
												type="button"
												className={`btn btn-icon btn-sm btn-light me-2 `}
												title="Wijzig EDS profiel"
												onClick={() => {
													setSelectedProfileMapping(item);
												}}
											>
												<i className="fas fa-edit"></i>
											</button>
											{item.importProfileMappingId && (
												<button
													type="button"
													className={`btn btn-icon btn-sm btn-light me-2 `}
													title="Verwijder mapping"
													onClick={async () => {
														if (item.importProfileMappingId) {
															await mutationDeleteImportProfileMapping.mutateAsync(item.importProfileMappingId);
														}
													}}
												>
													{mutationDeleteImportProfileMapping.isLoading ? (
														<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
													) : (
														<i className="fas fa-trash"></i>
													)}
												</button>
											)}
										</>
									)}
								</td>
							</tr>
						))}
				</tbody>
			</EkoTable>
		</>
	);
};

export {CustomerImportProfileMappings};
