/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PropsWithChildren, ReactElement, useState} from 'react';
import {usePutValidationResolve} from '../../../../backoffice/modules/acl/import/hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {
    CompartmentLayoutTypeCategory,
    CompartmentLayoutTypeCode,
    GroeneveldImportCompartmentProfileMissingValidation,
    GroeneveldImportCompartmentProfileSuggestionValidation,
    HefSchuifSchemaCode,
    IgnoreImportValidationCommand,
    ImportedProfileMappingRepresentation, ImportValidationType,
    MappingKind,
    OrderStatusCodes,
    ProfileInfo,
    ResolveCompartmentProfileMissingImportValidationCommand,
    ResolveImportValidationCommandType
} from '../../../../modules/api-client/generated';
import {Form, Formik, FormikHelpers} from 'formik';
import clsx from 'clsx';
import SpinnerButton from '../../../components/SpinnerButton';
import {ValidationResolverProps} from '../../pages/ValidationResolver';
import {
    HefSchuifProfileFacets,
    ProfilePicker,
    ProfilePickerHandle,
    ProfilePickerProps,
    QueryCallback,
    StandardProfileFacets
} from "../../../../backoffice/modules/pim/profile/ProfilePicker";
import ProfileMatchPreviewSvg from "../../../components/ProfileMatchPreviewSvg";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {createPortal} from "react-dom";
import {bool} from "yup";

type FormValues = {
    saveForCustomer: boolean;
    saveForCustomerMappingKind: MappingKind
    applyToAll: boolean;
    profileId: string;
};

export type ProfileResolverProps = Omit<ValidationResolverProps, 'validation'> & {
    validation: GroeneveldImportCompartmentProfileMissingValidation|GroeneveldImportCompartmentProfileSuggestionValidation;
    customerId: string
};

function getType(compartmentLayoutTypeCode: CompartmentLayoutTypeCode): CompartmentLayoutTypeCategory {
    switch (compartmentLayoutTypeCode) {
        case CompartmentLayoutTypeCode.HefSchuif:
        case CompartmentLayoutTypeCode.HefSchuifA:
        case CompartmentLayoutTypeCode.HefSchuifC:
        case CompartmentLayoutTypeCode.HefSchuifD:
        case CompartmentLayoutTypeCode.HefSchuifE:
            return CompartmentLayoutTypeCategory.HefSchuif;
        default:
            return CompartmentLayoutTypeCategory.Standard;
    }
}

const ProfileResolver = ({
                             show,
                             validation,
                             customerId,
                             handleClose,
                         }: PropsWithChildren<ProfileResolverProps>) => {

    function isProfileSuggestionValidation(
        validation: GroeneveldImportCompartmentProfileMissingValidation | GroeneveldImportCompartmentProfileSuggestionValidation
    ): validation is GroeneveldImportCompartmentProfileSuggestionValidation {
        return "suggestedProfileId" in validation;
    }

    var resolveMutation = usePutValidationResolve();
    const profilePicker = React.createRef<ProfilePickerHandle>();


        const suggestedProfile: ProfileInfo | undefined = !isProfileSuggestionValidation(validation) ? undefined : {
            active: true,
            code: "",
            geometry: validation.suggestedProfileGeometry??'',
            hasProgram: false,
            id: validation.suggestedProfileId,
            name: "",
            sillWidth: 0,
            svgPreview: undefined,
            width: 0
        };

    const [selectedProfile, setSelectedProfile] = useState<ProfileInfo | undefined>(suggestedProfile);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)


    const queryCallback: QueryCallback = async (query) => {
        return await ApiClient.Pim.Configurator.searchProfiles(
            validation.sillId,
            validation.neutPositionId || '',
            validation.compartmentLayoutTypeCode!,
            customerId,
            undefined,
            query.filters
        ).then(
            (res) => res.data
        );
    };
    console.log( isProfileSuggestionValidation(validation) ? validation.suggestedProfileId : "asdf");

    const pickerPops: Omit<ProfilePickerProps, 'onConfirm'> = {
        query: queryCallback,
        multi: false,
        visibleFacets: getType(validation.compartmentLayoutTypeCode!) === CompartmentLayoutTypeCategory.HefSchuif ? HefSchuifProfileFacets : StandardProfileFacets,
        initialProfileId: isProfileSuggestionValidation(validation) ? validation.suggestedProfileId : undefined ,
    }


    const initialFormValues: FormValues = {
        profileId: isProfileSuggestionValidation(validation) ? validation.suggestedProfileId : "" ,
        saveForCustomer: false,
        saveForCustomerMappingKind: !isProfileSuggestionValidation(validation) ? MappingKind.Definitive : MappingKind.Suggestion,
        applyToAll: false,
    };

    const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
        return new Promise<void>((resolve, reject) => {
            var command: ResolveCompartmentProfileMissingImportValidationCommand = {
                profileId: values.profileId,
                saveForCustomer: values.saveForCustomer,
                commandType: !isProfileSuggestionValidation(validation) ? ResolveImportValidationCommandType.ResolveCompartmentProfileMissingImportValidationCommand : ResolveImportValidationCommandType.ResolveCompartmentProfileSuggestionValidationCommand,
                applyToAll: values.applyToAll,
                id: validation.id,
                importType: validation.type,
                saveForCustomerMappingKind: values.saveForCustomerMappingKind
            };
            resolveMutation.mutate(command, {
                onSuccess: (model) => {
                    resolve();
                    handleClose();
                },
                onError: () => {
                    reject();
                },
            });
        }).finally(() => setSubmitting(false));
    };
    const ignore = async () => {
        var command: IgnoreImportValidationCommand = {
            commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
            id: validation.id,
            importType: validation.type,
        };
        await resolveMutation.mutateAsync(command);
        handleClose();
    };
    return (
        <Formik initialValues={initialFormValues} onSubmit={resolve}>
            {({values, setFieldValue, getFieldProps, isSubmitting, submitForm}) =>
                (
                    <>
                        <ConfirmModal show={showConfirmModal}
                                      title={'Toepassen'}
                                      handleClose={() => setShowConfirmModal(false)}
                                      resolve={submitForm}
                                      getFieldProps={getFieldProps}
                                      values={values}
                                      isSubmitting={isSubmitting}
                        />
                        <Modal
                            container={modalsRoot}
                            tabIndex={-1}
                            aria-hidden="true"
                            autoFocus={true}
                            className=""
                            dialogClassName={'modal-dialog-centered'}
                            contentClassName={'shadow-lg vh-100'}
                            show={show}
                            fullscreen={true}
                            onHide={handleClose}
                            scrollable={true}
                            animation={false}
                            backdrop={true}
                        >
                            <Form id={'yolo'}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Selecteer een profiel voor {validation.compartmentLayoutTypeCode} op
                                        merk {validation.merk}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className={'alert alert-info'}>Het geimporteerde profiel dient gekoppeld te
                                        worden met
                                        een Ekosiet profiel.
                                        Staat het juiste profiel er niet tussen, neem dan contact op met onze
                                        werkvoorbereiding.
                                        Het kan zijn dat we dit profiel (nog) niet hebben geprogrammeerd of dat het niet
                                        geldig
                                        is in de vakindeling.
                                        <br/> De groene lijn is het geimporteerde profiel. Voor Groeneveld imports geldt
                                        dat de
                                        radiussen ontbreken.
                                        <br/> <strong>Als je deze waarschuwing negeert, wordt een standaard profiel
                                            toegepast op
                                            de
                                            geimporteerde dorpel!</strong>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div
                                                className={'d-flex  flex-column justify-content-end text-center align-items-center sticky-top'}>
                                                <div>
                                                    <ProfileMatchPreviewSvg profileA={validation.edsGeometry}
                                                                            profileB={selectedProfile?.geometry}/>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className="p-3 mx-3 text-wrap d-flex flex-column">
                                                        Merk
                                                        <h1>{validation.merk}</h1>
                                                    </div>
                                                    <div className="p-3 mx-3  text-wrap d-flex flex-column">
                                                        Vakindeling
                                                        <h1>
                                                            {validation.compartmentLayoutTypeCode}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="p-3 mx-3 text-wrap d-flex flex-column">
                                                    Dorpel
                                                    <h1>{validation.sillTitle}</h1>
                                                </div>
                                                <div
                                                    className="p-3 mx-3 text-wrap d-flex flex-column">
                                                    Geometrie
                                                    <h3>
                                                        {validation.groeneveldGeometry?.toString()}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'col'}>
                                            <ProfilePicker ref={profilePicker} {...pickerPops}
                                                           onConfirm={async (selection) => {
                                                               if (selection && selection.length) {
                                                                   var selectedProfile = selection[0];
                                                                   setFieldValue('profileId', selectedProfile.id);
                                                                   setSelectedProfile(selectedProfile);
                                                               }
                                                           }}/>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="link" className="mx-4" onClick={handleClose}>
                                        Sluit
                                    </Button>
                                    <Button
                                        className="btn-light-danger mx-4"
                                        onClick={() => {
                                            ignore().then();
                                        }}
                                    >
                                        Negeer
                                    </Button>
                                    <SpinnerButton className={'btn btn-primary'}
                                                   disabled={!selectedProfile}
                                                   spinning={isSubmitting}
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       setShowConfirmModal(true)
                                                   }}>
                                        Toepassen
                                    </SpinnerButton>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </>
                )}
        </Formik>
    );
};

export {
    ProfileResolver
};
type ConfirmModalProps = {
    show: boolean,
    getFieldProps: any,
    values: any,
    isSubmitting: boolean,
    title: string | null | undefined,
    handleClose: () => void,
    resolve: () => void,
}
const ConfirmModal = ({show, title, handleClose, resolve, getFieldProps, isSubmitting, values}: ConfirmModalProps) => {
    return createPortal(
        <Modal
            id='import_resolver_confirm_modal'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName={'modal-dialog modal-lg'}
            show={show}
            size={'lg'}
            onHide={handleClose}
            animation={true}
            backdrop={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    <div className={'col-1'}>
                        <div
                            className="form-check form-switch ">
                            <input
                                {...getFieldProps('saveForCustomer')}
                                className={clsx('form-check-input mt-3')}
                                type="checkbox"
                                name="saveForCustomer"
                                id="saveForCustomer"
                                value={values.saveForCustomer ? 'true' : 'false'}
                                checked={!!values.saveForCustomer}
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>
                    <div className={'col'}>
                        <label className="fw-bold fs-6  mb-2 form-check-label flex-grow"
                               htmlFor="saveForCustomer">
                            Keuze onthouden
                            <br/>
                            <span className={'text-muted'}>
                                <small>Het gekozen profiel wordt onthouden en bij volgende
                                    imports
                                    automatisch toegepast</small>
                            </span>
                        </label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                       {...getFieldProps('saveForCustomerMappingKind')}
                                       type="radio" name="saveForCustomerMappingKind"
                                       id="definitive"
                                       disabled={!values.saveForCustomer}
                                       checked={!values.saveForCustomer || values.saveForCustomerMappingKind === MappingKind.Definitive}
                                       value={MappingKind.Definitive}
                                />
                                <label className="form-check-label text-bold" htmlFor="definitive">Definitief
                                    <br/>
                                    <span className={'text-muted'}>
                                        <small>Bij volgende import wordt keuze automatisch toegepast.</small>
                                    </span>
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                       {...getFieldProps('saveForCustomerMappingKind')}
                                       type="radio" name="saveForCustomerMappingKind"
                                       id="suggestion"
                                       checked={values.saveForCustomerMappingKind === MappingKind.Suggestion}
                                       value={MappingKind.Suggestion}

                                />
                                <label className="form-check-label" htmlFor="suggestion">
                                    Suggestie
                                    <br/>
                                    <span className={'text-muted'}>
                                        <small>Bij volgende import wordt gevraagd om keuze te bevestigen</small>
                                    </span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex my-5 text-start ">
                    <div
                        className="form-check form-switch d-flex justify-content-start align-items-center">
                        <input
                            {...getFieldProps('applyToAll')}
                            className={clsx('form-check-input')}
                            type="checkbox"
                            name="applyToAll"
                            id="applyToAll"
                            value={values.applyToAll || values.saveForCustomer ? 'true' : 'false'}
                            checked={!!values.applyToAll || values.saveForCustomer}
                            disabled={isSubmitting || values.saveForCustomer}
                        />
                        <label className="fw-bold ms-4 fs-6 mb-2 form-check-label"
                               htmlFor="applyToAll">
                            Toepassen op alle merken in deze import
                            <br/>
                            <span className={'text-muted'}>
                                <small>Het gekozen profiel wordt toegepast op alle
                                    profielen met hetzelfde dorpeltype en vakindeling in deze import.</small>
                            </span>
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="mx-4" onClick={handleClose}>
                    Sluit
                </Button>
                <SpinnerButton className={'btn btn-primary'}
                    spinning={isSubmitting}
                               form={'yolo'}
                               type={'submit'}
                >
                    Toepassen
                </SpinnerButton>

            </Modal.Footer>
        </Modal>,
        modalsRoot
    )
}

