/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {EkoCard, EkoCardBody, KTSVG} from "../../../../../../../_metronic/helpers";
import {Address} from "./Address";
import {Person} from "./Person";
import {
    OrderAddressRepresentation,
    OrderContactPersonRepresentation,
    OrderStatusCodes,
    PaymentConditions,
    PriceListReference
} from "../../../../../../modules/api-client/generated";
import {PutAddressForm} from "./forms/PutAddressForm";
import {OrderAddressTypes} from "../../hooks/use-put-address";
import {PutPaymentConditionForm} from "./forms/PutPaymentConditionForm";
import {PutContactForm} from "./forms/PutContactForm";
import {OrderContactTypes} from "../../hooks/use-put-contact";
import {PutPricelistForm} from "./forms/PutPricelistForm";
import {PutProjectDiscountForm} from "./forms/PutProjectDiscountForm";
import {OrderFinancialStatus} from "./OrderFinancialStatus";

type Props = {
    paymentCondition?: PaymentConditions
    address?: OrderAddressRepresentation
    person?: OrderContactPersonRepresentation
    projectDiscount?: number
    priceList: PriceListReference
    status?: string
    paymentStatus?: string | null
    orderId?: string
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const BillingDetails: React.FC<Props> = ({paymentCondition,
                                             priceList,
                                             projectDiscount,
                                             address,
                                             status,
                                             person,
                                             paymentStatus,
                                             setShowEditModal,
                                             setModalTitle,
                                             setModalComponent,
                                             orderId}) => {
    if (!orderId) return (<></>)

    var editPaymentCondition = () => {
        setModalComponent(()=><PutPaymentConditionForm handleClose={()=>setShowEditModal(false)} paymentCondition={paymentCondition} id={orderId} />)
        setModalTitle('Wijzig betaalconditie')
        setShowEditModal(true)
    }
    var editInvoiceAddress = () => {
        setModalComponent(()=><PutAddressForm handleClose={()=>setShowEditModal(false)} type={OrderAddressTypes.financial} address={address} id={orderId} />)
        setModalTitle('Wijzig factuuradres')
        setShowEditModal(true)
    }
    var editContact = () => {
        setModalComponent(() => <PutContactForm handleClose={() => setShowEditModal(false)}
                                                type={OrderContactTypes.financial} contact={person} id={orderId}/>)
        setModalTitle('Wijzig contactpersoon voor facturatie')
        setShowEditModal(true)
    }
    var editPricelist = () => {
        setModalComponent(() => <PutPricelistForm handleClose={() => setShowEditModal(false)} pricelistId={priceList.id} id={orderId}/>)
        setModalTitle('Wijzig prijslijst')
        setShowEditModal(true)
    }
    var editProjectDiscount = () => {
        setModalComponent(() => <PutProjectDiscountForm handleClose={() => setShowEditModal(false)}
                                             projectDiscount={projectDiscount} id={orderId}/>)
        setModalTitle('Wijzig Projectkorting')
        setShowEditModal(true)
    }
    return (
        <>
            <EkoCard className={'py-4 h-100'} flush={true}>


                {/*begin::Card header*/}
                <div className="card-header">
                    <div className="card-title w-100 d-flex justify-content-between">
                        <h2>Financieel</h2>
                        <OrderFinancialStatus orderId={orderId} status={paymentStatus}/>
                    </div>
                </div>
                {/*end::Card header*/}
                <EkoCardBody className={'pt-0'}>
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">

                            {/*begin::Table body*/}
                            <tbody className="fw-semibold text-gray-600">
                            {/*begin::Payment method*/}
                            <tr>
                                <td className="text-muted">
                                    <div className="d-flex align-items-center">
                                        {/*begin::Svg Icon | path: icons/duotune/finance/fin008.svg*/}
                                        <KTSVG path={'/media/icons/duotune/finance/fin008.svg'}
                                               className='svg-icon-2 me-2'/>
                                        {/*end::Svg Icon*/} Betaalconditie
                                    </div>
                                </td>

                                <td className="text-end">
                                    {status===OrderStatusCodes.Draft || status === OrderStatusCodes.Placed  || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ?
                                        <a href='#'
                                           onClick={() => editPaymentCondition()}>
                                            {paymentCondition === PaymentConditions.OpFactuur30Dagen &&
                                                <>Op factuur (30 dagen)</>

                                            }
                                            {paymentCondition === PaymentConditions.OpFactuur8Dagen &&
                                                <>Op factuur (8 dagen)</>

                                            }
                                            {paymentCondition === PaymentConditions.Vooraf &&
                                                <>Vooraf</>

                                            }
                                            {paymentCondition === PaymentConditions.AutomatischeIncasso &&
                                                <>Automatische incasso</>
                                            }
                                        </a>
                                        :
                                        <>
                                            {paymentCondition === PaymentConditions.OpFactuur30Dagen &&
                                                <>Op factuur (30 dagen)</>

                                            }
                                            {paymentCondition === PaymentConditions.OpFactuur8Dagen &&
                                                <>Op factuur (8 dagen)</>

                                            }
                                            {paymentCondition === PaymentConditions.Vooraf &&
                                                <>Vooraf</>
                                            }
                                            {paymentCondition === PaymentConditions.AutomatischeIncasso &&
                                                <>Automatische incasso</>
                                            }
                                        </>
                                    }
                                </td>
                            </tr>
                            {/*end::Payment method*/}
                            <tr>
                                <td className="text-muted">
                                    <div className="d-flex align-items-center">
                                        {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm008.svg*/}
                                        <KTSVG path={'/media/icons/duotune/maps/map008.svg'}
                                               className='svg-icon-2 me-2'/>
                                        {/*end::Svg Icon*/} Adres
                                    </div>
                                </td>
                                <td className="text-end">
                                    {status===OrderStatusCodes.Draft || status === OrderStatusCodes.Placed  || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ?
                                        <a href='#'
                                           onClick={() => editInvoiceAddress()}>
                                            {address ?
                                            <Address address={address}/>
                                                :
                                                <div className={'text-danger'}>
                                                    <i className={'fas fa-exclamation-triangle'}></i> Voeg adres
                                                    toe
                                                </div>
                                            }
                                        </a>
                                        :
                                        <>
                                            <Address address={address}/>
                                        </>
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td className="text-muted">
                                    <div className="d-flex align-items-center">
                                        {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm008.svg*/}
                                        <KTSVG path={'/media/icons/duotune/communication/com005.svg'}
                                               className='svg-icon-2 me-2'/>
                                        {/*end::Svg Icon*/} Contactpersoon
                                    </div>
                                </td>
                                <td className="text-end">
                                    {status===OrderStatusCodes.Draft || status === OrderStatusCodes.Placed  || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ?
                                        <a href='#'
                                           onClick={() => editContact()}>
                                            {person ?
                                                <Person person={person}/>
                                                :
                                                <div className={'text-danger'}>
                                                    <i className={'fas fa-exclamation-triangle'}></i> Voeg
                                                    contactpersoon toe
                                                </div>
                                            }
                                        </a>
                                        :
                                        <>
                                            <Person person={person}/>
                                        </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="text-muted">
                                    <div className="d-flex align-items-center">
                                        {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm008.svg*/}
                                        <KTSVG path={'/media/icons/duotune/ecommerce/ecm003.svg'}
                                               className='svg-icon-2 me-2'/>
                                        {/*end::Svg Icon*/} Prijslijst
                                    </div>
                                </td>
                                <td className="text-end">
                                    {status===OrderStatusCodes.Draft || status === OrderStatusCodes.Placed  || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed?
                                        <a href='#'
                                           onClick={() => editPricelist()}>
                                            {priceList.name}
                                        </a>
                                        :
                                        <>
                                            {priceList.name}
                                        </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="text-muted">
                                    <div className="d-flex align-items-center">
                                        {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm008.svg*/}
                                        <KTSVG path={'/media/icons/duotune/ecommerce/ecm003.svg'}
                                               className='svg-icon-2 me-2'/>
                                        {/*end::Svg Icon*/} Projectkorting
                                    </div>
                                </td>
                                <td className="text-end">
                                    {status===OrderStatusCodes.Draft || status === OrderStatusCodes.QuoteIssued || status === OrderStatusCodes.Placed  || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed?
                                        <a href='#'
                                           onClick={() => editProjectDiscount()}>
                                            {projectDiscount ?
                                                <>{projectDiscount}</>
                                                :
                                                <>Geen korting</>

                                            }
                                        </a>
                                        :
                                        <>
                                            {projectDiscount ?
                                                <>{projectDiscount}</>
                                                :
                                                <>Geen korting</>
                                            }
                                        </>
                                    }

                                </td>
                            </tr>

                            </tbody>
                            {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}


                    </div>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export {BillingDetails}
