import React, {useRef, useState} from 'react';
import ColorOptionPicker from './ColorOptionPicker';
import CompartmentCountPicker from './CompartmentCountPicker';
import {Preview, PreviewProps} from './Preview';

import {
    AssemblyMethod,
    SillAssemblyWrapOption,
    SillConfigurationCompartment,
    SillConfigurationNeut,
    ValidateConfigurationResponse,
} from '../../../modules/api-client/generated';

import {WallConnectionModal} from './WallConnectionModal';
import {NeutModal} from './NeutModal';
import {CompartmentModal} from './CompartmentModal';
import './Configurator.scss';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../modules/api-client/ApiClient';
import {ConfiguratorPrice} from './ConfiguratorPrice';
import {useSearchParams} from "react-router-dom";
import {useConfigurator} from "./hooks/use-configurator";
import {ConfigurationZijlichtEditButton} from "./ConfigurationZijlichtEditButton";
import Loading from "../Loading";
import Error from "../Error";
import {useDraggable} from "react-use-draggable-scroll";

export interface ConfigurationFormProps {
}


export const ConfigurationForm: React.FC<ConfigurationFormProps> = () => {

    const configurator = useConfigurator();

    const {
        setMountingOption,
        setWrap,
        changeCompartmentCount,
        changeNeutPosition,
        setWallConnectionLeft,
        setWallConnectionRight,
        setNeut,
        setCompartment,
        calculateDrillHoles,
        selectedAssemblyOption,
        mountingOptions,
        defaultCompartmentOptions,
        configuration
    } = configurator;


    const [dialog, setDialog] = useState<string | null>(null);
    const [searchParams] = useSearchParams();

    // const {values} = useFormikContext<SillConfiguration>();

    const priceListId = searchParams.get('pl') ?? undefined;

    const {
        data: previewData,
        isLoading: isPreviewLoading,
        isError: isPreviewError,
        refetch
    } = useQuery<ValidateConfigurationResponse>([configuration], () =>
        ApiClient.Pim.Configurator.validateConfiguration(priceListId, undefined, configuration)
            .then((res) => res.data), {
        enabled: !!configuration.assemblyMethod && !!configuration.mountingOptionId,
        retry: false,
        // refetchOnWindowFocus: false
    });

    const closeDialog = () => setDialog(null);

    const onCompartmentChange = async (index: number, newCompartment: SillConfigurationCompartment): Promise<void> => {
        await setCompartment(index, newCompartment);
        closeDialog();
    };

    const onNeutChange = async (index: number, value: SillConfigurationNeut | null): Promise<void> => {
        await setNeut(index, value);
        closeDialog();
    };

    const onCompartmentCountChanged = async (c: number) => {
        await changeCompartmentCount(c);
    };

    const onNeutPositionChanged = async (neutPositionId: string) => {
        await changeNeutPosition(neutPositionId);
    };



    const sillConfiguration = 'neutPositionId' in configuration ? configuration : null

    return (
        <div>
            <div className='sill-configurator min-h-500px'>
                <div className='row'>
                    <div className='col-12 col-lg-10'>
                        <div className='row g-3'>
                            <div className='col-6 col-lg-2 d-flex flex-column align-items-stretch'>
                                <small className='text-uppercase fw-bold text-muted text-center'>Kleur</small>
                                <ColorOptionPicker name='colorId' options={configurator.data.colorOptions}/>
                            </div>

                            <div className='col-6 col-lg-3 d-flex flex-column align-items-stretch'>
                                <small className='text-uppercase fw-bold text-muted text-center'>Montage</small>
                                <div className="dropdown">
                                    <div className="form-select form-control-lg py-1 px-3 d-flex align-items-center"
                                         tabIndex={0}
                                         data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="flex-fill d-flex  align-items-center">
                                            <span className="fs-5 text-truncate">{
                                                configuration.assemblyMethod === AssemblyMethod.PreAssembly ? 'Voormontage (NeoFix)' :
                                                    configuration.assemblyMethod === AssemblyMethod.PostAssembly ? 'Namontage (Neo)' :
                                                        'Kies montage'}</span>
                                        </div>
                                    </div>

                                    <ul className="dropdown-menu dropdown-menu-lg" style={{minWidth: '100%'}}>
                                        {configurator.postAssemblyMountingOptions &&
                                            <li className="dropdown-item d-flex align-items-center"
                                                onClick={async () => await configurator.changeAssemblyMethod(AssemblyMethod.PostAssembly)}>
                                                <span className="fs-5">Namontage (Neo)</span>
                                            </li>
                                        }
                                        {configurator.preAssemblyMountingOptions &&
                                            <li className="dropdown-item d-flex align-items-center"
                                                onClick={async () => await configurator.changeAssemblyMethod(AssemblyMethod.PreAssembly)}>
                                                <span className="fs-5">Voormontage (Neofix)</span>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='col-6 col-lg-3 d-flex flex-column align-items-stretch'>
                                <small className='text-uppercase fw-bold text-muted text-center'>Bevestiging</small>
                                <select className='form-select'
                                        value={configuration.mountingOptionId ?? ''}
                                        onChange={(e) => setMountingOption(e.target.value)}
                                        disabled={mountingOptions?.length <= 1}>
                                    <option value='' disabled>--Selecteer bevestiging--</option>
                                    {mountingOptions?.map((mountingOption, index) =>
                                        <option key={index} value={mountingOption.id}>{mountingOption.name}</option>,
                                    )}
                                </select>
                            </div>
                            {selectedAssemblyOption?.wrap === SillAssemblyWrapOption.Optional ?
                                <div className='col-6 col-lg-2 d-flex flex-column align-items-stretch'>
                                    <small className='text-uppercase fw-bold text-muted text-center'>Inpakken</small>
                                    <select className='form-select'
                                            value={configuration.wrap ? 'true' : 'false'}
                                            onChange={(e) => setWrap(e.target.value === 'true')}
                                            disabled={selectedAssemblyOption?.wrap !== SillAssemblyWrapOption.Optional}>
                                        {/*{(assemblyOption?.wrap === SillAssemblyWrapOption.No || assemblyOption?.wrap === SillAssemblyWrapOption.Optional) &&*/}
                                        <option value='false'>Nee</option>
                                        {/*}*/}
                                        {/*	{(assemblyOption?.wrap === SillAssemblyWrapOption.Yes || assemblyOption?.wrap === SillAssemblyWrapOption.Optional) &&*/}
                                        <option value='true'>Ja</option>
                                        {/*}*/}
                                    </select>
                                </div>
                                :
                                <input type={'hidden'} name={'wrap'} value={configuration.wrap ? 'true' : 'false'}/>
                            }
                            <input type={'hidden'} value={'true'} name={'kopIsolator'}/>

                            <div className='col-6 col-lg-2 d-flex flex-column align-items-stretch'>
                                {configurator.hasNeuten && <>
                                    <small className='text-uppercase fw-bold text-muted text-center'>Vakken</small>
                                    <CompartmentCountPicker selected={configurator.selectedCompartmentCountOption}
                                                            options={configurator.compartmentCountOptions}
                                                            onChange={(x) => onCompartmentCountChanged(x)}/>
                                </>}
                            </div>

                            {sillConfiguration
                                && configurator.data.compartmentOptions.neutPositions
                                && configurator.data.compartmentOptions.neutPositions.length > 1 &&
                                <div className='col-6 col-lg-2 d-flex flex-column align-items-stretch'>
                                    <small className='text-uppercase fw-bold text-muted text-center'>Neuten
                                        inspringen</small>
                                    <select className='form-select'
                                            value={sillConfiguration.neutPositionId ?? ''}
                                            onChange={(e) => onNeutPositionChanged(e.target.value)}>
                                        {configurator.data.compartmentOptions.neutPositions?.map((np, index) =>
                                            <option key={index} value={np.id}>{np.name}</option>,
                                        )}
                                    </select>
                                </div>
                            }

                        </div>
                    </div>
                    <div className='col-12 col-lg-2 d-flex flex-column align-items-center'>
                        <small className='text-uppercase fw-bold text-muted'>Prijs</small>
                        <ConfiguratorPrice priceSpecification={previewData?.price}/>
                    </div>
                </div>

                {configuration.assemblyMethod && configuration.mountingOptionId && <>


                    <div className='py-5 styled-scrollbars' style={{overflowX: 'scroll'}}>
                        <ConfigurationPreview svg={previewData?.previewSvg}
                                              onRetry={() => refetch()}
                                              isPending={isPreviewLoading}
                                              isError={isPreviewError}
                                              onNeutClick={(index) => setDialog(`neut-${index}`)}
                        />
                    </div>

                    <div className='mb-4'>
                        <div className='card-header d-flex align-items-center'>
                            <h3 className='card-title'>Maatinvoer</h3>
                            <div className='flex-fill d-flex justify-content-center'>
                                <div style={{minWidth: '300px'}}>

                                </div>
                            </div>
                        </div>

                        <div className='py-5 styled-scrollbars' style={{overflowX: 'scroll'}}>

                            <div className='d-flex'>

                                {configurator.data.wallConnectionOptions.hasWallConnection && <>

                                    {dialog === 'wall-connection-left' &&
                                        <WallConnectionModal
                                            value={configuration.wallConnectionLeft!}
                                            handleClose={closeDialog}
                                            onChange={(value, alsoApplyToOppositeSide) => setWallConnectionLeft(value, alsoApplyToOppositeSide)}
                                            wallConnectionEditorOptions={{
                                                width: configurator.data.width,
                                                position: 'left',
                                            }}
                                        />
                                    }

                                    {dialog === 'wall-connection-right' &&
                                        <WallConnectionModal
                                            value={configuration.wallConnectionRight!}
                                            handleClose={closeDialog}
                                            onChange={(value, alsoApplyToOppositeSide) => setWallConnectionRight(value, alsoApplyToOppositeSide)}
                                            wallConnectionEditorOptions={{
                                                width: configurator.data.width,
                                                position: 'right',
                                            }}
                                        />
                                    }

                                    <div className='justify-content-between'>
                                        <button type='button' className='btn btn-primary mx-1'
                                                onClick={() => setDialog('wall-connection-left')}>
                                            Muuraansluiting links
                                        </button>
                                    </div>
                                </>
                                }

                                {sillConfiguration?.compartments?.map((c, index) => (
                                    <React.Fragment key={index}>
                                        {index === 0 && (
                                            <>
                                                {configurator.data.neutOptions.hasNeuten &&
                                                    // TODO: make this a feature!
                                                    <button key={`neut-${index}`} type='button'
                                                            className='btn btn-warning mx-1'
                                                            onClick={() => setDialog(`neut-${index}`)}>
                                                        Neut #{index + 1}
                                                    </button>
                                                }

                                                <NeutModal
                                                    show={dialog === `neut-${index}`}
                                                    title={`Neut #${index + 1}`}
                                                    handleClose={closeDialog}
                                                    editorOptions={{
                                                        value: sillConfiguration.neuten[index],
                                                        onChange: (value) => onNeutChange(index, value),
                                                        canOmit: true,
                                                        neutOptions: configurator.data.neutOptions,
                                                        calculateDefaultDrillHoles: async (neut, positioningStrategyName) => await calculateDrillHoles(index, neut, positioningStrategyName),
                                                    }}
                                                />
                                            </>
                                        )}

                                        <button key={`vak-${index}`} type='button' className='btn btn-info mx-1'
                                                onClick={() => setDialog(`compartment-${index}`)}>
                                            Vak #{index + 1}
                                        </button>

                                        {defaultCompartmentOptions &&
                                            <CompartmentModal
                                                show={dialog === `compartment-${index}`}
                                                title={`Vak #${index + 1}`}
                                                handleClose={closeDialog}
                                                editorOptions={{
                                                    ...defaultCompartmentOptions,
                                                    compartmentIndex: index,
                                                    value: sillConfiguration.compartments[index],
                                                    onChange: (newCompartment) => onCompartmentChange(index, newCompartment),
                                                }}
                                            />
                                        }

                                        {configurator.hasNeuten &&
                                            // TODO: make this a feature!
                                            <button key={`neut-${index + 1}`} type='button'
                                                    className='btn btn-warning mx-1'
                                                    onClick={() => setDialog(`neut-${index + 1}`)}>
                                                Neut #{index + 2}
                                            </button>
                                        }

                                        <NeutModal
                                            show={dialog === `neut-${index + 1}`}
                                            title={`Neut #${index + 2}`}
                                            handleClose={closeDialog}
                                            editorOptions={{
                                                value: sillConfiguration.neuten[index + 1],
                                                onChange: (value) => onNeutChange(index + 1, value),
                                                canOmit: index === sillConfiguration.compartments.length - 1,
                                                neutOptions: configurator.data.neutOptions,
                                                calculateDefaultDrillHoles: async (neut, positioningStrategyName) => await calculateDrillHoles(index + 1, neut, positioningStrategyName),
                                            }}
                                        />
                                    </React.Fragment>
                                ))}

                                {configurator.isZijlicht && <>
                                    <ConfigurationZijlichtEditButton/>
                                </>}

                                {configurator.data.wallConnectionOptions.hasWallConnection &&
                                    <button type='button' className='btn btn-primary mx-1'
                                            onClick={() => setDialog('wall-connection-right')}>
                                        Muuraansluiting rechts
                                    </button>
                                }

                            </div>
                        </div>
                    </div>

                </>}

                {/*<pre>{JSON.stringify(configuration, null, 2)}</pre>*/}
            </div>
        </div>

    );
};


type ConfigurationPreviewProps = {
    isPending: boolean;
    isError: boolean;
    onRetry: () => void;
    svg: string | undefined;
} & PreviewProps;

export const ConfigurationPreview: React.FC<ConfigurationPreviewProps> = (props) => {

    if (props.isPending) return <div style={{height: '300px'}}
                                     className="d-flex justify-content-center align-items-center">
        <Loading message={'Tekening wordt opgehaald...'}/>
    </div>;

    if (props.isError) return <PreviewError onRetry={props.onRetry}/>;

    if (!props.svg) return <div style={{height: '300px'}}>
        <Error message={'Tekening niet beschikbaar...'}/>;
    </div>;

    return <Preview {...props} />;
}

const PreviewError: React.FC<{ onRetry: () => void; }> = (props) => {
    return <div style={{height: '300px'}}
                className="d-flex flex-column justify-content-center align-items-center gap-3 border">
        <div className="h3">
            Fout bij het ophalen van de tekening.
        </div>
        <div>
            <button type="button" className="btn btn-primary" onClick={props.onRetry}>Opnieuw</button>
        </div>
    </div>
};
