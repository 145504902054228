/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'


const CrmMasterData: FC = () => {
    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CRM</span>
                </div>
            </div>
            {/*<MasterDataMenuItem to={CrmRoutePath.link(CrmRoutePath.customers)} title={'Test'}/>*/}
        </>
    )
}

export {CrmMasterData}
