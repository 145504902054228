import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {StandardWallConnectionEditor} from './StandardWallConnectionEditor';
import {
    WallConnectionType,
    SillConfigurationStandardWallConnection, SillConfigurationAngledWallConnection, SillConfigurationWallConnection,
} from '../../../modules/api-client/generated';
import ApiClient from '../../../modules/api-client/ApiClient';
import WallConnectionPreview from '../WallConnectionPreview';


export interface WallConnectionEditorOptions {
    width: number;
    position: 'left' | 'right';
}

export interface WallConnectionEditorProps extends WallConnectionEditorOptions {
    id: string;
}

export type WallConnectionEditorValue =
    SillConfigurationStandardWallConnection
    | SillConfigurationAngledWallConnection;


const WallConnectionEditor: React.FC<WallConnectionEditorProps> = (props) => {

    const [previewSvg, setPreviewSvg] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const {values, setValues, isValid} = useFormikContext<WallConnectionEditorValue>();

    const onChange = async (wc: SillConfigurationWallConnection): Promise<void> => {

        wc.wallConnectionType = WallConnectionType.Standard;

        const response = await ApiClient.Pim.Configurator.validateWallConnection(props.width, props.position === 'right', undefined, wc)
            .then(x => x.data);

        if (response.isValid) {
            await setValues(response.wallConnection!);
            setPreviewSvg(response.svg!)
        } else {
            throw new Error(response.error ?? 'Muuraansluiting in niet geldig');
        }
    };

    useEffect(() => {
        ApiClient.Pim.Configurator.validateWallConnection(props.width, props.position === 'right', undefined, values)
            .then(async (r) => {
                const response = r.data;
                if (response.isValid) {
                    await setValues(response.wallConnection!);
                    setPreviewSvg(response.svg!)
                } else {
                    setError(new Error(response.error ?? 'Muuraansluiting in niet geldig'));
                }
            });
    }, []);

    const setType = async (value: WallConnectionType) => {

        switch (value) {
            case 'angled': {
                await setValues({wallConnectionType: value, angle: 45});
                break;
            }
            default: {
                await setValues({wallConnectionType: value});
                break;
            }
        }
    };

    return (

        <div className='wall-connection-editor'>

            <div className='row'>
                <div className='col-4 d-flex align-items-center'>
                    {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    <WallConnectionPreview svg={previewSvg}/>
                </div>
                <div className='col' style={{minHeight: '340px'}}>
                    {error && <div className="alert alert-danger">{error.message}</div>}
                    <div className='p-3 d-grid gap-3'>
                        {/*<pre>{JSON.stringify(values)}</pre>*/}
                        {/*<div>*/}
                        {/*    <label className='form-label'><small className='text-uppercase text-muted'>Type*/}
                        {/*        aansluiting</small></label>*/}

                        {/*    <select id={`${props.id}_type`} className='form-select'*/}
                        {/*            value={values.wallConnectionType}*/}
                        {/*            onChange={(e) => setType(e.target.value as WallConnectionType)}>*/}
                        {/*        <option value={WallConnectionType.Standard}>Sponning en/of*/}
                        {/*            groef*/}
                        {/*        </option>*/}
                        {/*        <option value={WallConnectionType.Angled} disabled>Schuin</option>*/}
                        {/*        <option value={WallConnectionType.CustomProfile} disabled>Custom*/}
                        {/*            profiel*/}
                        {/*        </option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}

                        <div>
                            <StandardWallConnectionEditor width={props.width} onChange={onChange}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WallConnectionEditor;

