import React from "react";
import {API_GATEWAY_BASE_URL} from "../../constants";

export interface ProfilePreviewSvgProps {
    profileSvg?: string | null;
    profileA?: string | null;
    profileB?: string | null;
    svgWidth?: string;
    mirror?: boolean;
    svgHeight?: string;
    cacheBuster?: number | string | null;

}

const ProfilePreviewSvg: React.FC<ProfilePreviewSvgProps> = (props) => {

    let imgSrc = props.profileA ? `${API_GATEWAY_BASE_URL}/render/preview/profileMatch?geometryA=${encodeURIComponent(props.profileA)}` : undefined;

    if (imgSrc && props.profileB) {
        imgSrc += `&geometryB=${encodeURIComponent(props.profileB)}`;
    }


    if (imgSrc && props.cacheBuster) {
        imgSrc += `&cacheBuster=${props.cacheBuster}`;
    }

    if (!props.profileSvg && !props.profileA) {
        return <></>;
    }

    if (props.profileSvg) {
        return <div className={`preview-svg ${props.mirror ? 'mirror':''}`}
                    dangerouslySetInnerHTML={{__html: props.profileSvg}}/>
    }

    return <img src={imgSrc} style={{
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    }} alt=""/>
}

export default ProfilePreviewSvg;
