import React, {useEffect, useRef, useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {useQuery} from "@tanstack/react-query";
import {
    Metrics,
    ProductionDashboardStats,  Station
} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {EkoTable,TableHeader} from "../../../../shared/components/table";
import {dateToString, formatDate, weekNumber} from "../../../../shared/components/date";
import CountUp from "react-countup";
import {getCSSVariableValue} from "../../../../../_metronic/assets/ts/_utils";
import {DateTime} from "luxon";
import {PmRoutePath} from "../PmRoutes";
import {Link} from "react-router-dom";

const ProductionDashboard: React.FC = () => {

    const chartRef = useRef<HTMLDivElement | null>(null)
    const [mode, setMode] = useState<'sillMetrics' | 'metrics'>('sillMetrics');
    const defaultFrom = DateTime.now().startOf('week').minus({day: DateTime.now().weekday > 3 ? 3 : 0}).toFormat("yyyy-MM-dd")
    const defaultTo = DateTime.now().endOf('week').plus({day: DateTime.now().weekday > 3 ? 3 : 0}).toFormat("yyyy-MM-dd")
    const [startDay, setFromDate] = useState<string>(defaultFrom)
    const [endDay, setToDate] = useState<string>(defaultTo)

    const onChangeFromDate = (date: string) => {
        const newDate = DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
        setFromDate(newDate);
    }
    const onChangeToDate = (date: string) => {
        const newDate = DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
        setToDate(newDate);
    }
    const setDateRange = (currentWeek: number, year: number = DateTime.now().year) => {
        const dt = DateTime.fromObject({
            weekYear: year,
            weekNumber: currentWeek
        });
        setFromDate(dt.startOf('week').toFormat("yyyy-MM-dd"));
        setToDate(dt.endOf('week').toFormat("yyyy-MM-dd"));
    }
    const setDay = (day: string) => {
        const dt = DateTime.fromFormat(day, "yyyy-MM-dd");
        setFromDate(dt.toFormat("yyyy-MM-dd"));
        setToDate(dt.plus({day: 1}).toFormat("yyyy-MM-dd"));
    }

    const resetRange = () => {
        setFromDate(defaultFrom);
        setToDate(defaultTo);
    }

    const toggleMode = () => {
        setMode(prevMode => prevMode === 'sillMetrics' ? 'metrics' : 'sillMetrics');
    }

    const {isInitialLoading, data} = useQuery<ProductionDashboardStats, TypeError, ProductionDashboardStats>(
        ['productionDashboardStats'],
        () => {
            return ApiClient.Pm.Reporting.productionDashboardStats(startDay, endDay).then((res) => res.data);
        },
        {refetchInterval: 3000}
    );


    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
    let currentWeek = 0;

    return (
        <>
            <PageTitle toolbar={<>
                <div className={'d-flex align-items-center'}>
                    <div className={'me-'}>
                        <button className={`btn-sm btn ${mode == 'metrics' ? 'btn-primary' : 'btn-secondary'}`}
                                onClick={toggleMode}>Handelingen</button>
                    </div>
                    <div className={'me-2'}>
                        <button className={`btn-sm btn ${mode == 'sillMetrics' ? 'btn-primary' : 'btn-secondary'}`}
                                onClick={toggleMode}>Dorpels</button>
                    </div>
                    <div className={'me-2'}>
                        <input type="date" id="start" className={'form-control'} name="startDay" value={startDay}
                               onChange={(e) => onChangeFromDate(e.target.value)}/>
                    </div>
                    <div className={'me-2'}>
                        <input type="date" id="end" className={'form-control'} name="endDay" value={endDay}
                               min={startDay}
                               onChange={(e) => onChangeToDate(e.target.value)}/>
                    </div>
                    <div>
                        <button className={'btn btn-sm btn-light'} onClick={() => resetRange()}>Reset</button>
                    </div>
                </div>

            </>} breadcrumbs={[]}>
                Productie dashboard
            </PageTitle>
            <EkoTable classNameDivWrapper={'tableFixHead'} condensed={true}>
                <colgroup>
                    <col width={'3%'}/>
                    <col width={'2%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                </colgroup>
                <TableHeader>
                    <th className={'bg-light'}>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader sillMetrics={data.dorpels!.sillMetrics} taskMetrics={data.dorpels.metrics} title={data.dorpels.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader sillMetrics={data.wm50!.tasks.neutenMaken?.sillMetrics!} taskMetrics={data.wm50!.tasks.neutenMaken?.metrics!}
                                          title={data.wm50!.tasks.neutenMaken?.title!} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader sillMetrics={data.sawMaster!.tasks.latMaken?.sillMetrics!}
                                          taskMetrics={data.sawMaster!.tasks.latMaken?.metrics!}
                                          title={data.sawMaster!.tasks.latMaken?.title!} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader sillMetrics={data.sawMaster!.tasks.dorpelProfielZagen?.sillMetrics!}
                                          taskMetrics={data.sawMaster!.tasks.dorpelProfielZagen?.metrics!}
                                          title={data.sawMaster!.tasks.dorpelProfielZagen?.title!} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader sillMetrics={data.wm50!.tasks.dorpelBewerken?.sillMetrics!}
                                          taskMetrics={data.wm50!.tasks.dorpelBewerken?.metrics!}
                                          title={data.wm50!.tasks.dorpelBewerken?.title!} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader sillMetrics={data.neutMontage!.sillMetrics}
                                          taskMetrics={data.neutMontage!.metrics}
                                          title={data.neutMontage.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader sillMetrics={data.sluitpot!.sillMetrics}
                                          taskMetrics={data.sluitpot!.metrics}
                                          title={data.sluitpot.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader
                            sillMetrics={data.kopisolator!.sillMetrics}
                            taskMetrics={data.kopisolator!.metrics}
                            title={data.kopisolator.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader
                            taskMetrics={data.manchette!.metrics}
                            sillMetrics={data.manchette!.sillMetrics} title={data.manchette.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader
                            taskMetrics={data.latten!.metrics}
                            sillMetrics={data.latten!.sillMetrics} title={data.latten.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader
                            taskMetrics={data.inpak!.metrics}
                            sillMetrics={data.inpak!.sillMetrics} title={data.inpak.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader
                            taskMetrics={data.hefschuif!.metrics}
                            sillMetrics={data.hefschuif!.sillMetrics} title={data.hefschuif.title} mode={mode}/>
                    </th>
                    <th className={'bg-light'}>
                        <ProductionHeader
                            taskMetrics={data.specials!.metrics}
                            sillMetrics={data.specials!.sillMetrics} title={data.specials.title} mode={mode}/>
                    </th>
                </TableHeader>
                <tbody>
                {data.rows?.map((row) => {
                    const newWeek = weekNumber(row.date) !== currentWeek;
                    currentWeek = weekNumber(row.date);

                    return (<>
                            {newWeek &&
                                <tr className={`fw-bold fs-6}`}>
                                    <th colSpan={13}><span style={{cursor: 'pointer'}}
                                                           onClick={() => setDateRange(DateTime.fromFormat(row.date, "yyyy-MM-dd").weekNumber)}><i
                                        className={'fas fa-calendar'}></i> Week {currentWeek}
                                    </span></th>
                                </tr>
                            }
                            <tr key={formatDate(row.date)} className={'text-center'}>
                                <td className={'text-start text-nowrap'}>
                                    {dateToString(row.date, {
                                        weekday: 'short',
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        timeZone: 'UTC'
                                    })}
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.productionItemList) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.dorpels?.sillMetrics!}
                                            taskMetrics={row.stations.dorpels?.metrics!}
                                            mode={mode}
                                        />
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.neutenMaken) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.wm50?.tasks.neutenMaken?.sillMetrics!}
                                            taskMetrics={row.stations.wm50?.tasks.neutenMaken?.metrics!}
                                            mode={mode}/>
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.lattenMaken) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.sawMaster?.tasks.latMaken?.sillMetrics!}
                                            taskMetrics={row.stations.sawMaster?.tasks.latMaken?.metrics!}
                                            mode={mode}/>
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.sawmaster) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.sawMaster?.tasks.dorpelProfielZagen?.sillMetrics!}
                                            taskMetrics={row.stations.sawMaster?.tasks.dorpelProfielZagen?.metrics!}
                                            mode={mode}/>
                                    </Link>
                                </td>

                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.dorpelsBewerken) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.wm50?.tasks.dorpelBewerken?.sillMetrics!}
                                            taskMetrics={row.stations.wm50?.tasks.dorpelBewerken?.metrics!}
                                            mode={mode}/>
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.neutenMonteren) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress sillMetrics={row.stations.neutMontage?.sillMetrics!}
                                                            taskMetrics={row.stations.neutMontage?.metrics!}
                                                            mode={mode}/>
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.sluitpottenGemonteerd) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.sluitpot?.sillMetrics!}
                                            taskMetrics={row.stations.sluitpot?.metrics!}
                                            mode={mode}/>
                                    </Link>
                                </td>

                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.kopisolatorenGemonteerd) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.kopisolator?.sillMetrics!}
                                            taskMetrics={row.stations.kopisolator?.metrics!}
                                            mode={mode}/>
                                    </Link>

                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.manchettesGemonteerd) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.manchette?.sillMetrics!}
                                            taskMetrics={row.stations.manchette?.metrics!}
                                            mode={mode}/>
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.lattenGemonteerd) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.latten!?.sillMetrics!}
                                            taskMetrics={row.stations.latten?.metrics!}
                                            mode={mode}/>
                                    </Link>

                                </td>

                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.inpakken) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.inpak?.sillMetrics!}
                                            taskMetrics={row.stations.inpak?.metrics!}
                                            mode={mode}/>
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.hefschuifVouwwand) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.hefschuif?.sillMetrics!}
                                            taskMetrics={row.stations.hefschuif?.metrics!}
                                            mode={mode}
                                        />
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={PmRoutePath.link(PmRoutePath.custom) + `?f=PlannedProductionDate%3A` + formatDate(row.date)}>
                                        <ProductionProgress
                                            sillMetrics={row.stations.specials?.sillMetrics!}
                                            taskMetrics={row.stations.specials?.metrics!}
                                            mode={mode}
                                        />
                                    </Link>
                                </td>
                            </tr>
                        </>
                    )
                })
                }
                </tbody>
            </EkoTable>


        </>
    )
}
export default ProductionDashboard


export interface ProductionStationProgressProps {
    taskMetrics: Metrics;
    sillMetrics: Metrics;
    mode:string
}

export const ProductionProgress: React.FC<ProductionStationProgressProps> = ({taskMetrics,sillMetrics,mode}) => {
    const metrics = mode === 'sillMetrics' ? sillMetrics : taskMetrics;

    const percentageDone = ((metrics.efficiency) * 100);
    const percentagePossible = ((metrics.currentQueue / metrics.planned) * 100);
    const percentageTodo = ((metrics.dailyQueue/ metrics.planned) * 100);
    return (
        <>
            <div className={'d-flex flex-column text-start align-items-center'}>
                <div className="progress h-20px bg-secondary bg-opacity-50 w-100px">
                    <div title={`${metrics.realised}/${metrics.planned} (${percentageDone.toFixed(2)}%)`}
                         className="progress-bar bg-success" role="progressbar" aria-label="Segment one"
                         style={{width: percentageDone + '%'}}
                         aria-valuenow={percentageDone} aria-valuemin={0} aria-valuemax={100}>
                        {metrics.realised > 0 && metrics.realised}
                    </div>
                    <div title={`${metrics.currentQueue}/${metrics.planned} (${percentagePossible.toFixed(2)}%)`}
                         className="progress-bar bg-warning" role="progressbar" aria-label="Segment two"
                         style={{width: percentagePossible + '%'}} aria-valuenow={percentagePossible} aria-valuemin={0}
                         aria-valuemax={100}>
                        {metrics.currentQueue > 0 && metrics.currentQueue}
                    </div>
                    <div title={`${metrics.realised}/${metrics.planned} (${percentageDone.toFixed(2)}%)`}
                         className="progress-bar bg-secondary" role="progressbar" aria-label="Segment three"
                         style={{width: percentageTodo - percentagePossible + '%'}}
                         aria-valuenow={percentageTodo - percentagePossible} aria-valuemin={0} aria-valuemax={100}>
                        {metrics.dailyQueue - metrics.currentQueue > 0 &&
                            metrics.dailyQueue - metrics.currentQueue
                        }
                    </div>
                </div>

            </div>

        </>
    )
};

export interface ProductionHeaderProps {
    title: string;
    taskMetrics: Metrics;
    sillMetrics: Metrics;
    mode: string;
}

const ProductionHeader: React.FC<ProductionHeaderProps> = ({taskMetrics,sillMetrics,title,mode}) => {
    const metrics = mode === 'sillMetrics' ? sillMetrics : taskMetrics;
    const percentageDone = ((metrics.efficiency));
    const percentagePossible = ((metrics.currentQueue / metrics.planned));
    const percentageTodo = ((metrics.dailyQueue / metrics.planned));

    const chartRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        refreshChart()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percentagePossible])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        setTimeout(() => {
            initChart(70, 11, 145, 100, percentageTodo, percentagePossible, percentageDone, chartRef.current)
        }, 10)
    }

    return (
        <>
            <div className="fs-7 text-center"
            >
                <div
                    id={`kt_card_widget_17_chart`}
                    ref={chartRef}
                    style={{minWidth: 60 + 'px', minHeight: 60 + 'px'}}
                    data-kt-size={60}
                    data-kt-line={8}
                ></div>

                <h3>
                    <CountUp start={Math.max(0, metrics.currentQueue - 10)} end={metrics.currentQueue} delay={0}
                             duration={2.25}>
                        {({countUpRef}) => (
                            <div>
                                <span ref={countUpRef}/>
                            </div>
                        )}
                    </CountUp>
                </h3>
                <div>{title}</div>
            </div>
        </>
    )
};
const initChart = function (
    chartSize: number = 70,
    chartLine: number = 11,
    chartRotate: number = 145,
    total: number = 100,
    todo: number = 10,
    possible: number = 5,
    done: number = 85,
    el: HTMLDivElement | null
) {
    // const el = document.getElementById('kt_card_widget_17_chart')

    if (!el) {
        return
    }
    el.innerHTML = ''

    var options = {
        size: chartSize,
        lineWidth: chartLine,
        rotate: chartRotate,
        //percent:  el.getAttribute('data-kt-percent') ,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
        // @ts-ignore
        G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)

    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    // ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, start: number, end: number) {
        // start = Math.min(Math.max(0, start || 1), 1)
        end = Math.min(Math.max(0, end || 1), 1)
        if (!ctx) {
            return
        }

        ctx.beginPath()
        ctx.arc(0, 0, radius, Math.PI * 2 * start, Math.PI * 2 * end, false)
        ctx.strokeStyle = color
        ctx.lineCap = 'round' // butt, round or square
        ctx.lineWidth = lineWidth
        ctx.stroke()
    }

    // Init 2
    drawCircle('#E4E6EF', options.lineWidth, 0, 100)
    drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, 0, done)
    drawCircle(getCSSVariableValue('--kt-warning'), options.lineWidth, done, done + possible)
}