import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import React from 'react';
import axios from 'axios';
import { Dashboard } from './pages/Dashboard';
import SmRoutes from './modules/sm/SmRoutes';
import { SuspensedView } from '../routing/PrivateRoutes';
import PimRoutes from './modules/pim/PimRoutes';
import { BackofficeRoutePath } from './RoutePath';
import CrmRoutes from './modules/crm/CrmRoutes';
import PmRoutes, { PmRoutePath, PmStationRoutes } from './modules/pm/PmRoutes';
import MgtRoutes from './modules/mgt/MgtRoutes';
import MasterdataRoutes from './modules/masterdata/MasterdataRoutes';
import UmRoutes from './modules/um/UmRoutes';
import EmRoutes, { EmProductionRoutes, EmRoutePath, EmTransporterRoutes } from './modules/em/EmRoutes';
import FmRoutes from './modules/fm/FmRoutes';
import { useEkosietAuth0 } from '../modules/auth0/core/useEkosietAuth0';
import { AdminRoutes } from './modules/admin/AdminRoutes';
import { CoreRoutes } from './modules/core/CoreRoutes';
import ImportRoutes from '../shared/import/routes/ImportRoutes';

const BackofficeRoutes = () => {

	const {
		isProductieMedewerker,
		isManagement,
		isTransportMedewerker,
		isExpeditieMedewerker,
		isSysteemBeheerder,
		isWerkVoorbereider,
	} = useEkosietAuth0();


	const index =
		isTransportMedewerker ? EmRoutePath.link(EmRoutePath.planningTransporteur) :
			isProductieMedewerker ? PmRoutePath.link(PmRoutePath.stationsDashboard) :
				isTransportMedewerker ? EmRoutePath.link(EmRoutePath.planningTransporteur) :
					isExpeditieMedewerker ? EmRoutePath.link(EmRoutePath.planning) :
						BackofficeRoutePath.dashboard;

	return (
		<Routes>
			{(isManagement || isSysteemBeheerder || isWerkVoorbereider) && <>
				<Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
					{/* Pages */}
					<Route path={BackofficeRoutePath.relative(BackofficeRoutePath.dashboard)} element={<Dashboard />} />


					<Route path={BackofficeRoutePath.relative(BackofficeRoutePath.masterdata) + '*'} element={
						<SuspensedView>
							<MasterdataRoutes />
						</SuspensedView>
					} />
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.crm) + '*'}
						element={
							<SuspensedView>
								<CrmRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.sm) + '*'}
						element={
							<SuspensedView>
								<SmRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.pm) + '*'}
						element={
							<SuspensedView>								
								<PmRoutes />								
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.mgt) + '*'}
						element={
							<SuspensedView>
								<MgtRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.pim) + '*'}
						element={
							<SuspensedView>
								<PimRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.core) + '*'}
						element={
							<SuspensedView>
								<CoreRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.um) + '*'}
						element={
							<SuspensedView>
								<UmRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.em) + '*'}
						element={
							<SuspensedView>
								<EmRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.fm) + '*'}
						element={
							<SuspensedView>
								<FmRoutes />
							</SuspensedView>
						}
					/>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.import) + '*'}
						element={
							<SuspensedView>
								<ImportRoutes />
							</SuspensedView>
						}
					/>

				</Route>
			</>
			}

			{isProductieMedewerker && <>
				<Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
					<Route
						path={BackofficeRoutePath.relative(BackofficeRoutePath.pm) + '*'}
						element={
							<SuspensedView>
								<PmStationRoutes />
							</SuspensedView>
						}
					/>
					{/*<Route*/}
					{/*    path={BackofficeRoutePath.relative(BackofficeRoutePath.em) + '*'}*/}
					{/*    element={*/}
					{/*        <SuspensedView>*/}
					{/*            <EmProductionRoutes/>*/}
					{/*        </SuspensedView>*/}
					{/*    }*/}
					{/*/>*/}
				</Route>
			</>}
			{isExpeditieMedewerker &&
				<>
					<Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
						<Route
							path={BackofficeRoutePath.relative(BackofficeRoutePath.em) + '*'}
							element={
								<SuspensedView>
									<EmProductionRoutes />
								</SuspensedView>
							}
						/>
					</Route>
				</>}
			{isTransportMedewerker &&
				<>
					<Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
						<Route
							path={BackofficeRoutePath.relative(BackofficeRoutePath.em) + '*'}
							element={
								<SuspensedView>
									<EmTransporterRoutes />
								</SuspensedView>
							}
						/>
					</Route>
				</>}


			{isSysteemBeheerder && <>
				<Route
					path={BackofficeRoutePath.relative(BackofficeRoutePath.admin) + '*'}
					element={
						<SuspensedView>
							<AdminRoutes />
						</SuspensedView>
					}
				/>
			</>}

			<Route index element={<Navigate to={index} />} />
			<Route path='*' element={<Navigate to='/error/404' />} />
		</Routes>
	);

	// if (!isProductieMedewerker) {
	// 	return (
	// 		<Routes>
	// 			<Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
	// 				{/* Pages */}
	// 				<Route path={BackofficeRoutePath.relative(BackofficeRoutePath.dashboard)} element={<Dashboard />} />
	//
	// 				<Route path={BackofficeRoutePath.relative(BackofficeRoutePath.masterdata) + '*'} element={
	// 					<SuspensedView>
	// 						<MasterdataRoutes />
	// 					</SuspensedView>
	// 				} />
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.crm) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<CrmRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.sm) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<SmRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.pm) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<PmRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.mgt) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<MgtRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.pim) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<PimRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.um) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<UmRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.em) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<EmRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.fm) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<FmRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 			</Route>
	//
	// 			<Route index element={<Navigate to={BackofficeRoutePath.dashboard} />} />
	// 			<Route path='*' element={<Navigate to='/error/404' />} />
	// 		</Routes>
	// 	);
	// } else {
	// 	// isProductieMedewerker
	// 	return (
	// 		<Routes>
	// 			<Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
	// 				<Route
	// 					path={BackofficeRoutePath.relative(BackofficeRoutePath.pm) + '*'}
	// 					element={
	// 						<SuspensedView>
	// 							<PmStationRoutes />
	// 						</SuspensedView>
	// 					}
	// 				/>
	// 			</Route>
	//
	// 			<Route index element={<Navigate to={PmRoutePath.link(PmRoutePath.stationsDashboard)} />} />
	// 			<Route path='*' element={<Navigate to='/error/404' />} />
	// 		</Routes>
	// 	);
	// }
};

const Fallback: React.FC<FallbackProps> = (props) => {

	if (axios.isAxiosError(props.error)) {
		if (props.error.response?.status === 404) {
			return <>Not found</>;
		}
		if (props.error.response?.status === 500) {
			return <>Server error</>;
		}
	}

	return <>Error</>;
};

export default BackofficeRoutes;
