import {useQuery, useQueryClient} from "@tanstack/react-query";
import * as api from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CreateSluitpotBeslagCommand, UpdateSluitpotBeslagCommand
} from "../../../../modules/api-client/generated";
import {FormValues} from "./SluitpotBeslagUpsert";
import {ImageFormValues} from "./SluitpotBeslagImageUpsert";
import {AxiosRequestConfig} from "axios";


export const useSluitpotBeslagUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.SluitpotBeslag, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateSluitpotBeslagCommand | UpdateSluitpotBeslagCommand = {
                code: values.code,
                active: values.active,
                name: values.name,
                description: values.description,
                svg: values.svg,
            };

            if (id) {
                return ApiClient.Pim.SluitpotBeslag.pimSluitpotBeslagIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.SluitpotBeslag.pimSluitpotBeslagPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSluitpotBeslagIdGet', id]).then();
                queryClient.invalidateQueries(['pimSluitpotBeslagGet']).then();
                queryClient.invalidateQueries(['getSluitpotHardware']).then();
            }
        }
    );
};
export const useSluitpotBeslagImageUpsertMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void | undefined, NotFound | ValidationFailed, ImageFormValues>((values) => {
        let options:AxiosRequestConfig = {};
            if (values.image) {
                const formData = new FormData();
                formData.append('image', values.image, 'sdf.jpg');
                options = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: formData
                }
            }
                return ApiClient.Pim.SluitpotBeslag.pimSluitpotBeslagIdImagePut(id, undefined, options).then(response => response.data);

        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSluitpotBeslagIdGet', id]).then();
                queryClient.invalidateQueries(['pimSluitpotBeslagGet']).then();
                queryClient.invalidateQueries(['getSluitpotHardware']).then();
            }
        }
    );
};
export const useSluitpotBeslagQuery = (id: string | undefined) => {
    return useQuery(['pimSluitpotBeslagIdGet', id],
        () => {
            return ApiClient.Pim.SluitpotBeslag.pimSluitpotBeslagIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useSluitpotBeslagDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.SluitpotBeslag.pimSluitpotBeslagIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSluitpotBeslagIdGet', id]).then();
                queryClient.invalidateQueries(['pimSluitpotBeslagGet']).then();
                queryClient.invalidateQueries(['getSluitpotHardware']).then();
            }
        }
    );
};



