import React from "react";

export interface WallConnectionPreviewProps {
    svg?: string | null;
}

const WallConnectionPreview: React.FC<WallConnectionPreviewProps> = (props) => {

        if (props.svg) {
            return <div className="preview-svg"
                        style={{maxHeight: 'unset', height: 'unset'}}
                        dangerouslySetInnerHTML={{__html: props.svg}}/>
        }
        return <></>;
    }
;

export default WallConnectionPreview;
