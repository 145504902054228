import {FC} from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import {OrderContactPersonRepresentation, PutContactPersonRepresentation} from '../../../../../../../modules/api-client/generated';
import {OrderContactTypes, usePutOrderContact} from '../../../hooks/use-put-contact';
import TextField from '../../../../../../../shared/components/TextField';
import clsx from 'clsx';

type Props = {
	id: string;
	type: string;
	contact?: OrderContactPersonRepresentation;
	handleClose: () => void;
};

const PutContactForm: FC<Props> = ({id, type, contact, handleClose}) => {
	const mutation = usePutOrderContact(id, type);

	const initialValues: PutContactPersonRepresentation = contact
		? {
				...contact,
				updateExpedition: false,
				updateFinancial: false,
		  }
		: {
				email: '',
				firstName: '',
				lastName: '',
				telephone: undefined,
				updateExpedition: false,
				updateFinancial: false,
		  };

	const schema = {
		email: Yup.string().email('Geen geldig e-mail').required('Email is verplicht.'),
		firstName: Yup.string().min(1, 'Minimaal 1 karakter.').required('Voornaam is verplicht.'),
		lastName: Yup.string().min(3, 'Minimaal 3 karakters.').required('Achternaam is verplicht.'),
		telephone: Yup.string().notRequired().nullable(),
	};
	const formSchema = () =>
		Yup.lazy((values) => {
			return Yup.object().shape(schema);
		});

	const onSubmit = (values: PutContactPersonRepresentation, {setSubmitting}: FormikHelpers<PutContactPersonRepresentation>) => {
		return new Promise<void>((resolve, reject) => {
			mutation.mutate(values, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
				{({values, handleChange, handleSubmit, getFieldProps, isSubmitting}) => (
					<Form>
						{/* begin form element */}

						<div className="row mb-7">
							<div className={'col'}>
								<TextField label={'Voornaam'} {...getFieldProps('firstName')} />
							</div>
							<div className={'col'}>
								<TextField label={'Achternaam'} {...getFieldProps('lastName')} />
							</div>
						</div>

						<div className="row mb-7">
							<div className={'col'}>
								<TextField label={'Emailadres'} {...getFieldProps('email')} />
							</div>
							<div className={'col'}>
								<TextField label={'Telefoonnummer'} {...getFieldProps('telephone')} />
							</div>
						</div>


{type === OrderContactTypes.order &&
						<div className="row mb-7">
							<div className={'col'}>
								<div className="form-check form-switch">
									{/* begin::Input */}
									<input
										{...getFieldProps('updateExpedition')}
										className={clsx('form-check-input')}
										type="checkbox"
										name="updateExpedition"
										id="updateExpedition"
										value={values.updateExpedition ? 'true' : 'false'}
										checked={values.updateExpedition ? true : false}
										disabled={isSubmitting}
									/>
									{/* end::Input */}

									{/* begin::Label */}
									<label className="fw-bold fs-6 mb-2 form-check-label" htmlFor="updateExpedition">
										Levering \ Expeditie
									</label>
									{/* end::Label */}
								</div>
							
							
                            <div className="form-check form-switch">
									{/* begin::Input */}
									<input
										{...getFieldProps('updateFinancial')}
										className={clsx('form-check-input')}
										type="checkbox"
										name="updateFinancial"
										id="updateFinancial"
										value={values.updateFinancial ? 'true' : 'false'}
										checked={values.updateFinancial ? true : false}
										disabled={isSubmitting}
									/>
									{/* end::Input */}

									{/* begin::Label */}
									<label className="fw-bold fs-6 mb-2 form-check-label" htmlFor="updateFinancial">
										Financieel
									</label>
									{/* end::Label */}
								</div>
							</div>

                            </div>
						
}

						{/* begin::Actions */}
						<div className="text-center pt-5">
							<button type="reset" className="btn btn-light me-3" data-kt-customer-modal-action="cancel" data-dismiss="modal" onClick={() => handleClose()} disabled={isSubmitting}>
								Annuleren
							</button>

							<button type="submit" className="btn btn-primary" data-kt-customer-modal-action="submit" disabled={isSubmitting}>
								<span className="indicator-label">Opslaan</span>
								{isSubmitting && (
									<span className="indicator-progress">
										Even wachten a.u.b
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
						</div>
						{/* end::Actions */}
					</Form>
				)}
			</Formik>
		</>
	);
};

export {PutContactForm};
