import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    ProductionItemRepresentation
} from "../../../../modules/api-client/generated";

export const useProductionItemGetId = (id: string | undefined) => {

    return useQuery<ProductionItemRepresentation, TypeError, ProductionItemRepresentation>(
        ['pmProductionItemIdGet', id],
        () => {
            return ApiClient.Pm.ProductionItem.pmProductionItemIdGet(id!).then((res) => res.data);
        }, {
        useErrorBoundary: true,
        enabled: !!id,
    });
}

export const useProductionItemGetByBarCode = (barCode: string | undefined) => {
    return useQuery<ProductionItemRepresentation, TypeError, ProductionItemRepresentation>(
        ['pmProductionItemBarcodeCodeGet', barCode],
        () => {
            return ApiClient.Pm.ProductionItem.pmProductionItemBarcodeCodeGet(barCode!).then((res) => res.data);
        }, {
        useErrorBoundary: true,
        refetchOnWindowFocus:!!barCode,
        enabled: !!barCode,
    });
}

export const useOrderIdGetByBarCode = (barCode:string) => {
    return useQuery<string, TypeError, string>(
        ['pmOrderIdBarcodeCodeGet', barCode],
        () => {
            return ApiClient.Pm.ProductionItem.pmProductionItemOrderIdByBarcodeCodeGet(barCode!).then((res) => res.data);
        }, {
            useErrorBoundary: true,
            refetchOnWindowFocus:!!barCode,
            enabled: !!barCode,
        });
}
