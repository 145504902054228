/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../../../backoffice/modules/acl/import/hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {IgnoreImportValidationCommand, ResolveImportValidationCommandType, ResolveSillImportValidationMappingCommand} from '../../../../modules/api-client/generated';
import {ValidationResolverProps} from '../../pages/ValidationResolver';
import {SillPicker} from '../../../../backoffice/modules/pim/sill/SillPicker';
import {Form, Formik, FormikHelpers} from 'formik';
import SpinnerButton from '../../../components/SpinnerButton';
import clsx from 'clsx';

type FormValues = {applyToAll: boolean; sillTypeId: string};

const SillTypeResolver = ({show, validation, handleClose, children}: PropsWithChildren<ValidationResolverProps>) => {
	var resolveMutation = usePutValidationResolve();

	const initialFormValues: FormValues = {sillTypeId: '', applyToAll: true};

	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveSillImportValidationMappingCommand = {
				commandType: ResolveImportValidationCommandType.ResolveSillImportValidationMappingCommand,
				applyToAll: values.applyToAll,
				sillId: values.sillTypeId!,
				id: validation.id,
				importType: validation.type,
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const ignore = async () => {

		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};

		await resolveMutation.mutateAsync(command);

		handleClose();
	};

	return (
		<Formik initialValues={initialFormValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Selecteer dorpeltype</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">
							<span className={'fw-bold'}>{validation.message} </span>
							<div className="row my-5">
								<div className={'col-4'}>
									<label className="fw-bold fs-6 mb-2">Dorpeltype</label>
								</div>
								<div className={'col'}>
									<SillPicker name={'sillTypeId'} />
								</div>
							</div>
							<div className="row my-5">
								<div className={'col-4'}>
									<label className="fw-bold fs-6 mb-2"></label>
								</div>
								<div className={'col'}>
									<div className="form-check form-switch">
										{/* begin::Input */}
										<input
											{...getFieldProps('applyToAll')}
											className={clsx('form-check-input')}
											type="checkbox"
											name="applyToAll"
											id="applyToAll"
											value={values.applyToAll ? 'true' : 'false'}
											checked={!!values.applyToAll}
											disabled={isSubmitting}
										/>
										{/* end::Input */}

										{/* begin::Label */}
										<label className="fw-bold fs-6 mb-2 form-check-label" htmlFor="applyToAll">
											Toepassen binnen import
										</label>
										{/* end::Label */}
									</div>
								</div>
							</div>
						</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting} disabled={isSubmitting}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export {SillTypeResolver};
