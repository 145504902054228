import React from 'react';
import {Navigate, Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import axios from 'axios';
import {ImportsPage} from '../pages/ImportsPage';
import {CustomerImportProfileMappingsPage} from '../../../backoffice/modules/acl/import/pages/CustomerImportProfileMappingsPage';
import {ImportPage} from '../pages/ImportPage';
import {CustomerImportSluitpotMappingsPage} from '../../../backoffice/modules/acl/import/pages/CustomerImportSluitpotMappingsPage';
import {ValidationResolver} from "../pages/ValidationResolver";

export const ImportRoutePath = {

	list: 'list',
	importProfileMapping: 'mapping/profile',
	importSluitpotMapping: 'mapping/sluitpot',
	import: ':id',
	importValidation: 'c/:customerId/v/:validationId',

	importLink: (id: string) => {
		return  ImportRoutePath.import.replace(':id', id);
	},

	importValidationLink: (id: string, customerId: string, validationId: string) => {
		return ImportRoutePath.importValidation
				.replace(':id', id)
				.replace(':customerId', customerId)
				.replace(':validationId', validationId)
				;
	},
};

const ImportRoutes: React.FC = () => {	
	return (
		<Routes>
			<Route
				element={
					<ErrorBoundary FallbackComponent={AclFallback}>
						<Outlet />
					</ErrorBoundary>
				}
			>
				<Route
					path={ImportRoutePath.importProfileMapping}
					element={
						<>
							<CustomerImportProfileMappingsPage />
						</>
					}
				></Route>
				<Route
					path={ImportRoutePath.importSluitpotMapping}
					element={
						<>
							<CustomerImportSluitpotMappingsPage />
						</>
					}
				></Route>
				<Route
					path={ImportRoutePath.list}
					element={
							<ImportsPage />
					}
				></Route>
				<Route
					path={ImportRoutePath.import}
					element={
					<>
						<ImportPage />
						<Outlet />
					</>
					}
				>
					<Route path={ImportRoutePath.importValidation} element={<ValidationResolver />} />
				</Route>
			</Route>
			<Route index element={<Navigate to={ImportRoutePath.list} />} />
		</Routes>
	);
};

const AclFallback: React.FC<FallbackProps> = (props) => {
	if (axios.isAxiosError(props.error)) {
		if (props.error.response?.status === 404) {
			return <>Not found</>;
		}
		if (props.error.response?.status === 500) {
			return <>Server error</>;
		}
	}

	return <>Error</>;
};

export default ImportRoutes;
