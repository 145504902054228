import WallConnectionEditor, {WallConnectionEditorOptions, WallConnectionEditorValue} from './WallConnectionEditor';
import React from 'react';
import {
    ConfigurationWallConnectionLeft,
    SillConfigurationStandardWallConnection,
    WallConnectionType
} from '../../../modules/api-client/generated';
import {Button, Modal} from "react-bootstrap";
import {Form, Formik} from "formik";
import {modalsRoot} from "../../../global-helpers";

type WallConnectionModalProps = {
    title?: string;
    value: ConfigurationWallConnectionLeft | null;
    onChange: (result: ConfigurationWallConnectionLeft | null, applyToOppositeSide: boolean) => void;
    handleClose: () => void;
    wallConnectionEditorOptions: WallConnectionEditorOptions;
};

const WallConnectionModal = ({
                                 title,
                                 value,
                                 handleClose,
                                 onChange,
                                 wallConnectionEditorOptions
                             }: WallConnectionModalProps) => {


    const [alsoApplyToOppositeSide, setAlsoApplyToOppositeSide] = React.useState(true);

    const onSubmit = (values: WallConnectionEditorValue) => {

        onChange(values, alsoApplyToOppositeSide);
        handleClose();
    };

    const initialValues: WallConnectionEditorValue = {
        wallConnectionType: WallConnectionType.Standard,
        ...value,
    };

    return (
        <Modal
            container={modalsRoot}
            tabIndex={-1}
            aria-hidden="true"
            autoFocus={true}
            // dialogClassName={'modal-dialog-centered'}
            contentClassName={'shadow-lg'}
            show={true}
            size="lg"
            onHide={() => handleClose()}
            animation={false}
            backdrop={'static'}
        >
            <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
                {({submitForm}) => {
                    return (
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{title ?? `Muuraansluiting  ${wallConnectionEditorOptions.position === 'right' ? 'rechts' : 'links'}`}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <WallConnectionEditor id={'wall-connection-editor'} {...wallConnectionEditorOptions} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="link" className="mx-4" onClick={() => handleClose()}>
                                    Annuleren
                                </Button>
                                <Button type="button" variant="secondary"
                                        onClick={async () => {
                                            setAlsoApplyToOppositeSide(false);
                                            await submitForm();
                                        }}>
                                    {wallConnectionEditorOptions.position === 'left' ? 'Links' : 'Rechts'} toepassen
                                </Button>
                                <Button type="submit" variant="primary">
                                    Links &amp; rechts toepassen
                                </Button>

                            </Modal.Footer>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export {WallConnectionModal};
