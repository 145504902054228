import React from "react";
import {ImportStatus} from "../../../modules/api-client/generated";
export interface Props {
    status?: ImportStatus
    failure?: string
}

const ImportStatusBadge: React.FC<Props> = ({status, failure}) => {
        let label;
        let color;
        switch (status) {
            case ImportStatus.TimeoutExpired: {
                label = 'Time out';
                color = 'danger';
                break;
            }
            case ImportStatus.ValidatingFile: {
                label = 'File validatie';
                color = 'primary';
                break;
            }
            case ImportStatus.ValidatingEdsMapping: {
                label = 'Import Validatie';
                color = 'info';
                break;
            }
            case ImportStatus.ConfiguringSills: {
                label = 'Aanmaken dorpels';
                color = 'warning';
                break;
            }
            case ImportStatus.SillsConfigured: {
                label = 'Dorpels aangemaakt';
                color = 'success';
                break;
            }
            case ImportStatus.Completed: {
                label = 'Import compleet';
                color = 'success';
                break;
            }
            case ImportStatus.InvalidFile: {
                label = 'File ongeldig';
                color = 'danger';
                break;
            }
            case ImportStatus.EdsMappingFailed: {
                label = 'Eds mapping gefaald';
                color = 'danger';
                break;
            }
            case ImportStatus.SillConfigurationFailed: {
                label = 'Dorpels configuratie gefaald';
                color = 'danger';
                break;
            }
            case ImportStatus.Cancelled: {
                label = 'Geannuleerd';
                color = 'danger';
                break;
            }

            default: {
                color = 'light';
                label = 'Onbekend'
            }
        }
        return (
                    <span className={`p-2 bg-${color} text-inverse-${color} text-nowrap `}>
                        {label}
            </span>

                    )
    }
;

export default ImportStatusBadge;
