/* tslint:disable */
/* eslint-disable */
/**
 * Ekosiet Web Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


    import type { ClientConfig } from './configuration';
    import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
    import globalAxios from 'axios';
    // Some imports not used depending on template conditions
    // @ts-ignore
    import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
    import type { RequestArgs } from './base';
    // @ts-ignore
    import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

        /**
 * 
 * @export
 * @interface AanslagNok
 */
export interface AanslagNok {
    /**
     * 
     * @type {number}
     * @memberof AanslagNok
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof AanslagNok
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @interface AanslagNokInfo
 */
export interface AanslagNokInfo {
    /**
     * 
     * @type {number}
     * @memberof AanslagNokInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof AanslagNokInfo
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof AanslagNokInfo
     */
    'displayName': string;
}
        /**
 * @type AclImportValidationImportValidationIdGet200Response
 * @export
 */
export type AclImportValidationImportValidationIdGet200Response = GroeneveldImportCompartmentLayoutMappingValidation | GroeneveldImportCompartmentProfileMissingValidation | GroeneveldImportCompartmentProfileSuggestionValidation | GroeneveldImportCompartmentProfilesNotEqualValidation | GroeneveldImportCornerPieceMappingValidation | GroeneveldImportGlazingBarMappingValidation | GroeneveldImportGlazingProfileMappingValidation | GroeneveldImportSillMappingValidation | GroeneveldImportSluitpotBeslagMappingValidation | GroeneveldImportSluitpotCompartmentMappingValidation | GroeneveldImportSluitpotYPositionValidation | GroeneveldImportStopProfileMappingValidation | ImportCompartmentProfileMissingValidation | ImportCompartmentProfileValidation | ImportMappingValidation | ImportSillMappingValidation;

        /**
 * @type AclImportValidationResolvePutRequest
 * @export
 */
export type AclImportValidationResolvePutRequest = IgnoreImportValidationCommand | ResetImportValidationCommand | ResolveCompartmentProfileMissingImportValidationCommand | ResolveCompartmentProfileSuggestionValidationCommand | ResolveCompartmentProfilesNotEqualImportValidationCommand | ResolveImportValidationWithApplyToAllCommand | ResolveSillImportValidationMappingCommand | ResolveSluitpotImportValidationMappingCommand;

        /**
 * 
 * @export
 * @interface ActionLogRepresentation
 */
export interface ActionLogRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ActionLogRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActionLogRepresentation
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ActionLogRepresentation
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof ActionLogRepresentation
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ActionLogRepresentation
     */
    'date': string;
    /**
     * 
     * @type {OrderStatusCodes}
     * @memberof ActionLogRepresentation
     */
    'beginStatus': OrderStatusCodes;
    /**
     * 
     * @type {OrderStatusCodes}
     * @memberof ActionLogRepresentation
     */
    'endStatus': OrderStatusCodes;
}


        /**
 * 
 * @export
 * @interface AddNeutPosition
 */
export interface AddNeutPosition {
    /**
     * 
     * @type {string}
     * @memberof AddNeutPosition
     */
    'name': string;
    /**
     * 
     * @type {SillNeutIndention}
     * @memberof AddNeutPosition
     */
    'indention': SillNeutIndention;
}
        /**
 * 
 * @export
 * @interface AngledWallConnection
 */
export interface AngledWallConnection extends WallConnection {
    /**
     * 
     * @type {number}
     * @memberof AngledWallConnection
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof AngledWallConnection
     */
    'angle': number;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const AssemblyMethod = {
    PreAssembly: 'preAssembly',
    PostAssembly: 'postAssembly'
} as const;

export type AssemblyMethod = typeof AssemblyMethod[keyof typeof AssemblyMethod];


        /**
 * 
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * 
     * @type {Point}
     * @memberof BoundingBox
     */
    'point1': Point;
    /**
     * 
     * @type {Point}
     * @memberof BoundingBox
     */
    'point2': Point;
    /**
     * 
     * @type {number}
     * @memberof BoundingBox
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof BoundingBox
     */
    'height': number;
}
        /**
 * @type CalculateDrillHoles400Response
 * @export
 */
export type CalculateDrillHoles400Response = FluentValidationProblemDetails | ValidationProblemDetails;

        /**
 * 
 * @export
 * @interface CalculationCatalogItem
 */
export interface CalculationCatalogItem {
    /**
     * 
     * @type {string}
     * @memberof CalculationCatalogItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationCatalogItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationCatalogItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationCatalogItem
     */
    'discountGroupId'?: string | null;
    /**
     * 
     * @type {CatalogItemType}
     * @memberof CalculationCatalogItem
     */
    'catalogItemType': CatalogItemType;
}


        /**
 * 
 * @export
 * @interface Catalog
 */
export interface Catalog {
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CatalogItem
 */
export interface CatalogItem {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof CatalogItem
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof CatalogItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItem
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogItem
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CatalogItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItem
     */
    'description'?: string | null;
    /**
     * 
     * @type {CatalogItemDiscountGroup}
     * @memberof CatalogItem
     */
    'discountGroup'?: CatalogItemDiscountGroup;
    /**
     * 
     * @type {Array<CatalogItemCatalog>}
     * @memberof CatalogItem
     */
    'catalogs'?: Array<CatalogItemCatalog> | null;
    /**
     * 
     * @type {Array<CatalogItemCategory>}
     * @memberof CatalogItem
     */
    'categories'?: Array<CatalogItemCategory> | null;
}


        /**
 * 
 * @export
 * @interface CatalogItemCatalog
 */
export interface CatalogItemCatalog {
    /**
     * 
     * @type {string}
     * @memberof CatalogItemCatalog
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemCatalog
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemCatalog
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CatalogItemCategory
 */
export interface CatalogItemCategory {
    /**
     * 
     * @type {string}
     * @memberof CatalogItemCategory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemCategory
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemCategory
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CatalogItemColor
 */
export interface CatalogItemColor {
    /**
     * 
     * @type {string}
     * @memberof CatalogItemColor
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemColor
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemColor
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemColor
     */
    'hex': string;
}
        /**
 * 
 * @export
 * @interface CatalogItemDiscountGroup
 */
export interface CatalogItemDiscountGroup {
    /**
     * 
     * @type {string}
     * @memberof CatalogItemDiscountGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemDiscountGroup
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemDiscountGroup
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CatalogItemDiscountRule
 */
export interface CatalogItemDiscountRule extends DiscountRule {
    /**
     * 
     * @type {DiscountRuleCatalogItem}
     * @memberof CatalogItemDiscountRule
     */
    'catalogItem': DiscountRuleCatalogItem;
}


        /**
 * 
 * @export
 * @interface CatalogItemProduct
 */
export interface CatalogItemProduct extends CatalogItem {
    /**
     * 
     * @type {number}
     * @memberof CatalogItemProduct
     */
    'price'?: number | null;
}


        /**
 * 
 * @export
 * @interface CatalogItemReference
 */
export interface CatalogItemReference {
    /**
     * 
     * @type {string}
     * @memberof CatalogItemReference
     */
    'id': string;
    /**
     * 
     * @type {CatalogItemType}
     * @memberof CatalogItemReference
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemReference
     */
    'title': string;
}


        /**
 * 
 * @export
 * @interface CatalogItemSbomReference
 */
export interface CatalogItemSbomReference {
    /**
     * 
     * @type {string}
     * @memberof CatalogItemSbomReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemSbomReference
     */
    'code': string;
}
        /**
 * 
 * @export
 * @interface CatalogItemSillModel
 */
export interface CatalogItemSillModel {
    /**
     * 
     * @type {string}
     * @memberof CatalogItemSillModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemSillModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogItemSillModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CatalogItemSillModel
     */
    'width': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const CatalogItemType = {
    Standard: 'standard',
    Transport: 'transport',
    Sill: 'sill',
    Neut: 'neut',
    GlazingBar: 'glazingBar',
    CornerPiece: 'cornerPiece',
    GlazingProfile: 'glazingProfile',
    StopProfile: 'stopProfile',
    LProfile: 'lProfile',
    KopIsolatorSet: 'kopIsolatorSet',
    NeutConfigurator: 'neutConfigurator',
    SillConfigurator: 'sillConfigurator',
    ConfiguredSill: 'configuredSill'
} as const;

export type CatalogItemType = typeof CatalogItemType[keyof typeof CatalogItemType];


        /**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'weight': number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'image'?: string | null;
}
        /**
 * 
 * @export
 * @interface ChangePriceCommand
 */
export interface ChangePriceCommand extends PriceCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangePriceCommand
     */
    'priceListId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChangePriceCommand
     */
    'newPrice': number;
}


        /**
 * 
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof Color
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'hex': string;
}
        /**
 * 
 * @export
 * @interface ColorOptions
 */
export interface ColorOptions {
    /**
     * 
     * @type {string}
     * @memberof ColorOptions
     */
    'default'?: string;
    /**
     * 
     * @type {Array<ConfiguratorColorOption>}
     * @memberof ColorOptions
     */
    'options': Array<ConfiguratorColorOption>;
    /**
     * 
     * @type {boolean}
     * @memberof ColorOptions
     */
    'mandatory': boolean;
}
        /**
 * 
 * @export
 * @interface CompartmentLayoutType
 */
export interface CompartmentLayoutType {
    /**
     * 
     * @type {string}
     * @memberof CompartmentLayoutType
     */
    'id': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof CompartmentLayoutType
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof CompartmentLayoutType
     */
    'rabbetPosition': RabbetPosition;
    /**
     * 
     * @type {string}
     * @memberof CompartmentLayoutType
     */
    'name': string;
    /**
     * 
     * @type {Array<SluitpotBeslagReference>}
     * @memberof CompartmentLayoutType
     */
    'sluitpotBeslags': Array<SluitpotBeslagReference>;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const CompartmentLayoutTypeCategory = {
    Standard: 'standard',
    HefSchuif: 'hefSchuif',
    Zijlicht: 'zijlicht'
} as const;

export type CompartmentLayoutTypeCategory = typeof CompartmentLayoutTypeCategory[keyof typeof CompartmentLayoutTypeCategory];


        /**
 * 
 * @export
 * @enum {string}
 */

export const CompartmentLayoutTypeCode = {
    VastglasBinnen: 'vastglasBinnen',
    VastglasBuiten: 'vastglasBuiten',
    DeurBuitendraaiend: 'deurBuitendraaiend',
    DeurBinnendraaiend: 'deurBinnendraaiend',
    DraaiKiep: 'draaiKiep',
    HefSchuif: 'hefSchuif',
    HefSchuifA: 'hefSchuifA',
    HefSchuifC: 'hefSchuifC',
    HefSchuifD: 'hefSchuifD',
    HefSchuifE: 'hefSchuifE',
    VouwwandBinnen: 'vouwwandBinnen',
    VouwwandBuiten: 'vouwwandBuiten',
    Zijlicht: 'zijlicht'
} as const;

export type CompartmentLayoutTypeCode = typeof CompartmentLayoutTypeCode[keyof typeof CompartmentLayoutTypeCode];


        /**
 * 
 * @export
 * @interface CompartmentLayoutTypeReference
 */
export interface CompartmentLayoutTypeReference {
    /**
     * 
     * @type {string}
     * @memberof CompartmentLayoutTypeReference
     */
    'id': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof CompartmentLayoutTypeReference
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof CompartmentLayoutTypeReference
     */
    'name': string;
}


        /**
 * 
 * @export
 * @interface CompartmentProfileGenerationOptions
 */
export interface CompartmentProfileGenerationOptions {
    /**
     * 
     * @type {Array<CompartmentProfileGenerationOptionsRabbet>}
     * @memberof CompartmentProfileGenerationOptions
     */
    'rabbets': Array<CompartmentProfileGenerationOptionsRabbet>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompartmentProfileGenerationOptions
     */
    'sillWidths': Array<number>;
    /**
     * 
     * @type {Array<CompartmentProfileGenerationOptionsCornerProfile>}
     * @memberof CompartmentProfileGenerationOptions
     */
    'cornerProfiles': Array<CompartmentProfileGenerationOptionsCornerProfile>;
}
        /**
 * 
 * @export
 * @interface CompartmentProfileGenerationOptionsCornerProfile
 */
export interface CompartmentProfileGenerationOptionsCornerProfile {
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationOptionsCornerProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationOptionsCornerProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationOptionsCornerProfile
     */
    'name': string;
    /**
     * 
     * @type {RabbetApplication}
     * @memberof CompartmentProfileGenerationOptionsCornerProfile
     */
    'application': RabbetApplication;
}


        /**
 * 
 * @export
 * @interface CompartmentProfileGenerationOptionsRabbet
 */
export interface CompartmentProfileGenerationOptionsRabbet {
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationOptionsRabbet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationOptionsRabbet
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationOptionsRabbet
     */
    'name': string;
    /**
     * 
     * @type {RabbetApplication}
     * @memberof CompartmentProfileGenerationOptionsRabbet
     */
    'application': RabbetApplication;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileGenerationOptionsRabbet
     */
    'width': number;
}


        /**
 * 
 * @export
 * @interface CompartmentProfileGenerationPreview
 */
export interface CompartmentProfileGenerationPreview {
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationPreview
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationPreview
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileGenerationPreview
     */
    'width': number;
    /**
     * 
     * @type {boolean}
     * @memberof CompartmentProfileGenerationPreview
     */
    'isValid': boolean;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof CompartmentProfileGenerationPreview
     */
    'profile'?: CompartmentProfileValue;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationPreview
     */
    'svg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileGenerationPreview
     */
    'profileId'?: string | null;
}
        /**
 * 
 * @export
 * @interface CompartmentProfileHefSchuifRabbet
 */
export interface CompartmentProfileHefSchuifRabbet {
    /**
     * 
     * @type {HefSchuifGroove}
     * @memberof CompartmentProfileHefSchuifRabbet
     */
    'groove': HefSchuifGroove;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof CompartmentProfileHefSchuifRabbet
     */
    'position': RabbetPosition;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileHefSchuifRabbet
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileHefSchuifRabbet
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileHefSchuifRabbet
     */
    'rabbetId': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileHefSchuifRabbet
     */
    'geometry': string;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileHefSchuifRabbet
     */
    'dagmaatInset': number;
}


        /**
 * 
 * @export
 * @interface CompartmentProfileInfo
 */
export interface CompartmentProfileInfo {
    /**
     * 
     * @type {HefSchuifRabbetInfo | StandardRabbetInfo}
     * @memberof CompartmentProfileInfo
     */
    'rabbet'?: HefSchuifRabbetInfo | StandardRabbetInfo | null;
    /**
     * 
     * @type {CornerProfileInfo}
     * @memberof CompartmentProfileInfo
     */
    'cornerProfileOutside'?: CornerProfileInfo;
    /**
     * 
     * @type {CornerProfileInfo}
     * @memberof CompartmentProfileInfo
     */
    'cornerProfileInside'?: CornerProfileInfo;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileInfo
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompartmentProfileInfo
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileInfo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileInfo
     */
    'sillWidth': number;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileInfo
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileInfo
     */
    'svgPreview'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompartmentProfileInfo
     */
    'hasProgram': boolean;
}
        /**
 * 
 * @export
 * @interface CompartmentProfileRabbet
 */
export interface CompartmentProfileRabbet {
    /**
     * 
     * @type {RabbetPosition}
     * @memberof CompartmentProfileRabbet
     */
    'position': RabbetPosition;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileRabbet
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileRabbet
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileRabbet
     */
    'rabbetId': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileRabbet
     */
    'geometry': string;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileRabbet
     */
    'dagmaatInset': number;
}


        /**
 * 
 * @export
 * @interface CompartmentProfileStandardRabbet
 */
export interface CompartmentProfileStandardRabbet {
    /**
     * 
     * @type {TurboholRabbet}
     * @memberof CompartmentProfileStandardRabbet
     */
    'turboholRabbet'?: TurboholRabbet;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof CompartmentProfileStandardRabbet
     */
    'position': RabbetPosition;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileStandardRabbet
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileStandardRabbet
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileStandardRabbet
     */
    'rabbetId': string;
    /**
     * 
     * @type {string}
     * @memberof CompartmentProfileStandardRabbet
     */
    'geometry': string;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileStandardRabbet
     */
    'dagmaatInset': number;
}


        /**
 * 
 * @export
 * @interface CompartmentProfileValue
 */
export interface CompartmentProfileValue {
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileValue
     */
    'width': number;
    /**
     * 
     * @type {CompartmentProfileHefSchuifRabbet | CompartmentProfileStandardRabbet}
     * @memberof CompartmentProfileValue
     */
    'rabbet'?: CompartmentProfileHefSchuifRabbet | CompartmentProfileStandardRabbet | null;
    /**
     * 
     * @type {CornerProfile}
     * @memberof CompartmentProfileValue
     */
    'cornerProfileOutside'?: CornerProfile;
    /**
     * 
     * @type {CornerProfile}
     * @memberof CompartmentProfileValue
     */
    'cornerProfileInside'?: CornerProfile;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfileValue
     */
    'dagmaatInset': number;
}
        /**
 * 
 * @export
 * @interface CompartmentProfiles
 */
export interface CompartmentProfiles {
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof CompartmentProfiles
     */
    'left': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof CompartmentProfiles
     */
    'right': CompartmentProfileValue;
    /**
     * 
     * @type {number}
     * @memberof CompartmentProfiles
     */
    'totalDagmaatInset': number;
}
        /**
 * 
 * @export
 * @interface Configuration
 */
export interface Configuration {
    /**
     * 
     * @type {string}
     * @memberof Configuration
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof Configuration
     */
    'colorId': string;
    /**
     * 
     * @type {AssemblyMethod}
     * @memberof Configuration
     */
    'assemblyMethod'?: AssemblyMethod;
    /**
     * 
     * @type {string}
     * @memberof Configuration
     */
    'mountingOptionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Configuration
     */
    'wrap'?: boolean | null;
    /**
     * 
     * @type {ConfigurationWallConnectionLeft}
     * @memberof Configuration
     */
    'wallConnectionLeft'?: ConfigurationWallConnectionLeft | null;
    /**
     * 
     * @type {ConfigurationWallConnectionLeft}
     * @memberof Configuration
     */
    'wallConnectionRight'?: ConfigurationWallConnectionLeft | null;
}


        /**
 * @type ConfigurationWallConnectionLeft
 * @export
 */
export type ConfigurationWallConnectionLeft = SillConfigurationAngledWallConnection | SillConfigurationStandardWallConnection;

        /**
 * 
 * @export
 * @interface ConfiguratorAssemblyOption
 */
export interface ConfiguratorAssemblyOption {
    /**
     * 
     * @type {SillAssemblyWrapOption}
     * @memberof ConfiguratorAssemblyOption
     */
    'wrap': SillAssemblyWrapOption;
    /**
     * 
     * @type {Array<ConfiguratorMountingOption>}
     * @memberof ConfiguratorAssemblyOption
     */
    'mountingOptions': Array<ConfiguratorMountingOption>;
}


        /**
 * 
 * @export
 * @interface ConfiguratorAssemblyOptions
 */
export interface ConfiguratorAssemblyOptions {
    /**
     * 
     * @type {ConfiguratorAssemblyOption}
     * @memberof ConfiguratorAssemblyOptions
     */
    'preAssembly'?: ConfiguratorAssemblyOption;
    /**
     * 
     * @type {ConfiguratorAssemblyOption}
     * @memberof ConfiguratorAssemblyOptions
     */
    'postAssembly'?: ConfiguratorAssemblyOption;
}
        /**
 * 
 * @export
 * @interface ConfiguratorColorOption
 */
export interface ConfiguratorColorOption {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorColorOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorColorOption
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorColorOption
     */
    'hex': string;
}
        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayout
 */
export interface ConfiguratorCompartmentLayout {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayout
     */
    '$type': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguratorCompartmentLayout
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentLayoutTypeCategory}
     * @memberof ConfiguratorCompartmentLayout
     */
    'compartmentLayoutTypeCategory': CompartmentLayoutTypeCategory;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorCompartmentLayout
     */
    'profileCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorCompartmentLayout
     */
    'defaultCompartmentWidth': number;
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayoutHefSchuif
 */
export interface ConfiguratorCompartmentLayoutHefSchuif extends ConfiguratorCompartmentLayout {
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof ConfiguratorCompartmentLayoutHefSchuif
     */
    'schemaCode': HefSchuifSchemaCode;
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayoutHefSchuifA
 */
export interface ConfiguratorCompartmentLayoutHefSchuifA extends ConfiguratorCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifA
     */
    'defaultSlidingDirection': HefSchuifSlidingDirection;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifA
     */
    'defaultProfileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifA
     */
    'defaultProfileIdOutside': string;
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayoutHefSchuifC
 */
export interface ConfiguratorCompartmentLayoutHefSchuifC extends ConfiguratorCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifC
     */
    'defaultProfileIdOutside': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifC
     */
    'defaultCenterPrimarySlidingRail': number;
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayoutHefSchuifD
 */
export interface ConfiguratorCompartmentLayoutHefSchuifD extends ConfiguratorCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {HefSchuifInnerDoorPosition}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifD
     */
    'defaultInnerDoorPosition': HefSchuifInnerDoorPosition;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifD
     */
    'defaultProfileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifD
     */
    'defaultProfileIdOutside': string;
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayoutHefSchuifE
 */
export interface ConfiguratorCompartmentLayoutHefSchuifE extends ConfiguratorCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifE
     */
    'defaultSlidingDirection': HefSchuifSlidingDirection;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifE
     */
    'defaultProfileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutHefSchuifE
     */
    'defaultProfileIdOutside': string;
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayoutStandard
 */
export interface ConfiguratorCompartmentLayoutStandard extends ConfiguratorCompartmentLayout {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentLayoutStandard
     */
    'defaultProfileId': string;
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentLayoutZijlicht
 */
export interface ConfiguratorCompartmentLayoutZijlicht extends ConfiguratorCompartmentLayout {
}


        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentOptions
 */
export interface ConfiguratorCompartmentOptions {
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorCompartmentOptions
     */
    'maxCompartmentCount': number;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCompartmentOptions
     */
    'defaultNeutPositionId'?: string | null;
    /**
     * 
     * @type {Array<ConfiguratorNeutPosition>}
     * @memberof ConfiguratorCompartmentOptions
     */
    'neutPositions'?: Array<ConfiguratorNeutPosition> | null;
}
        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentProfile
 */
export interface ConfiguratorCompartmentProfile {
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof ConfiguratorCompartmentProfile
     */
    'profileInfo': CompartmentProfileInfo;
}
        /**
 * 
 * @export
 * @interface ConfiguratorCompartmentProfiles
 */
export interface ConfiguratorCompartmentProfiles {
    /**
     * 
     * @type {ConfiguratorCompartmentProfile}
     * @memberof ConfiguratorCompartmentProfiles
     */
    'left': ConfiguratorCompartmentProfile;
    /**
     * 
     * @type {ConfiguratorCompartmentProfile}
     * @memberof ConfiguratorCompartmentProfiles
     */
    'right': ConfiguratorCompartmentProfile;
}
        /**
 * 
 * @export
 * @interface ConfiguratorCornerPiece
 */
export interface ConfiguratorCornerPiece {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCornerPiece
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCornerPiece
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorCornerPiece
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorCornerPiece
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorCornerPiece
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorCornerPiece
     */
    'length': number;
}
        /**
 * 
 * @export
 * @interface ConfiguratorData
 */
export interface ConfiguratorData {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorData
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorData
     */
    'sillCode': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorData
     */
    'sillName': string;
    /**
     * 
     * @type {SillType}
     * @memberof ConfiguratorData
     */
    'type': SillType;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorData
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorData
     */
    'maxLength': number;
    /**
     * 
     * @type {SillNeutOptions}
     * @memberof ConfiguratorData
     */
    'neutOptions': SillNeutOptions;
    /**
     * 
     * @type {SillWallConnectionOptions}
     * @memberof ConfiguratorData
     */
    'wallConnectionOptions': SillWallConnectionOptions;
    /**
     * 
     * @type {ConfiguratorCompartmentOptions}
     * @memberof ConfiguratorData
     */
    'compartmentOptions': ConfiguratorCompartmentOptions;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorData
     */
    'defaultDagmaat': number;
    /**
     * 
     * @type {{ [key: string]: SillSluitpotArea; }}
     * @memberof ConfiguratorData
     */
    'areas'?: { [key: string]: SillSluitpotArea; } | null;
    /**
     * 
     * @type {Array<ConfiguratorSillCompartmentLayoutOption>}
     * @memberof ConfiguratorData
     */
    'compartmentLayoutOptions': Array<ConfiguratorSillCompartmentLayoutOption>;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguratorData
     */
    'defaultCompartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {ColorOptions}
     * @memberof ConfiguratorData
     */
    'colorOptions': ColorOptions;
    /**
     * 
     * @type {ConfiguratorAssemblyOptions}
     * @memberof ConfiguratorData
     */
    'assemblyOptions': ConfiguratorAssemblyOptions;
    /**
     * 
     * @type {Array<DrillHoleReference>}
     * @memberof ConfiguratorData
     */
    'drillHoles': Array<DrillHoleReference>;
    /**
     * 
     * @type {{ [key: string]: ConfiguratorGlazingBar; }}
     * @memberof ConfiguratorData
     */
    'glazingBars': { [key: string]: ConfiguratorGlazingBar; };
    /**
     * 
     * @type {{ [key: string]: ConfiguratorGlazingProfile; }}
     * @memberof ConfiguratorData
     */
    'glazingProfiles': { [key: string]: ConfiguratorGlazingProfile; };
    /**
     * 
     * @type {{ [key: string]: ConfiguratorStopProfile; }}
     * @memberof ConfiguratorData
     */
    'stopProfiles': { [key: string]: ConfiguratorStopProfile; };
    /**
     * 
     * @type {Array<number>}
     * @memberof ConfiguratorData
     */
    'stopRabbetDepths': Array<number>;
    /**
     * 
     * @type {{ [key: string]: SluitpotReference; }}
     * @memberof ConfiguratorData
     */
    'sluitpotten': { [key: string]: SluitpotReference; };
    /**
     * 
     * @type {{ [key: string]: ConfiguratorSluitpotBeslag; }}
     * @memberof ConfiguratorData
     */
    'sluitpotBeslag': { [key: string]: ConfiguratorSluitpotBeslag; };
}


        /**
 * 
 * @export
 * @interface ConfiguratorGlazingBar
 */
export interface ConfiguratorGlazingBar {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorGlazingBar
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorGlazingBar
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorGlazingBar
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorGlazingBar
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorGlazingBar
     */
    'height': number;
    /**
     * 
     * @type {{ [key: string]: ConfiguratorCornerPiece; }}
     * @memberof ConfiguratorGlazingBar
     */
    'cornerPieces': { [key: string]: ConfiguratorCornerPiece; };
}
        /**
 * 
 * @export
 * @interface ConfiguratorGlazingProfile
 */
export interface ConfiguratorGlazingProfile {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorGlazingProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorGlazingProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorGlazingProfile
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorGlazingProfile
     */
    'rabbetWidth': number;
}
        /**
 * 
 * @export
 * @interface ConfiguratorMountingOption
 */
export interface ConfiguratorMountingOption {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorMountingOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorMountingOption
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface ConfiguratorNeutPosition
 */
export interface ConfiguratorNeutPosition {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorNeutPosition
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorNeutPosition
     */
    'name': string;
    /**
     * 
     * @type {SillNeutIndention}
     * @memberof ConfiguratorNeutPosition
     */
    'indention': SillNeutIndention;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorNeutPosition
     */
    'profileWidth': number;
    /**
     * 
     * @type {Array<ConfiguratorNeutPositionCompartmentLayoutsInner>}
     * @memberof ConfiguratorNeutPosition
     */
    'compartmentLayouts': Array<ConfiguratorNeutPositionCompartmentLayoutsInner>;
}
        /**
 * @type ConfiguratorNeutPositionCompartmentLayoutsInner
 * @export
 */
export type ConfiguratorNeutPositionCompartmentLayoutsInner = ConfiguratorCompartmentLayoutHefSchuif | ConfiguratorCompartmentLayoutHefSchuifA | ConfiguratorCompartmentLayoutHefSchuifC | ConfiguratorCompartmentLayoutHefSchuifD | ConfiguratorCompartmentLayoutHefSchuifE | ConfiguratorCompartmentLayoutStandard | ConfiguratorCompartmentLayoutZijlicht;

        /**
 * 
 * @export
 * @interface ConfiguratorProfilePickerProfile
 */
export interface ConfiguratorProfilePickerProfile {
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'isFavorite': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'favoriteName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'favoriteWeight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'sillWidth': number;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'svgPreview'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'hasProgram': boolean;
    /**
     * 
     * @type {ConfiguratorProfilePickerProfileRabbet}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'rabbet'?: ConfiguratorProfilePickerProfileRabbet | null;
    /**
     * 
     * @type {CornerProfileInfo}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'cornerProfileOutside'?: CornerProfileInfo;
    /**
     * 
     * @type {CornerProfileInfo}
     * @memberof ConfiguratorProfilePickerProfile
     */
    'cornerProfileInside'?: CornerProfileInfo;
}
        /**
 * 
 * @export
 * @interface ConfiguratorProfilePickerProfileProfileSearchResult
 */
export interface ConfiguratorProfilePickerProfileProfileSearchResult {
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorProfilePickerProfileProfileSearchResult
     */
    'totalHits': number;
    /**
     * 
     * @type {{ [key: string]: IFacetResult; }}
     * @memberof ConfiguratorProfilePickerProfileProfileSearchResult
     */
    'facets': { [key: string]: IFacetResult; };
    /**
     * 
     * @type {Array<ConfiguratorProfilePickerProfile>}
     * @memberof ConfiguratorProfilePickerProfileProfileSearchResult
     */
    'profiles': Array<ConfiguratorProfilePickerProfile>;
}
        /**
 * @type ConfiguratorProfilePickerProfileRabbet
 * @export
 */
export type ConfiguratorProfilePickerProfileRabbet = HefSchuifRabbetInfo | StandardRabbetInfo;

        /**
 * 
 * @export
 * @interface ConfiguratorSillCompartmentLayoutOption
 */
export interface ConfiguratorSillCompartmentLayoutOption {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'id': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentLayoutTypeCategory}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'category': CompartmentLayoutTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'canHaveAerationHoles': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'canHaveDrainageHoles': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'defaultAerationHoles'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'defaultDrainageHoles'?: boolean | null;
    /**
     * 
     * @type {{ [key: string]: Array<ConfiguratorSluitPotHardware> | null; }}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'sluitpotten'?: { [key: string]: Array<ConfiguratorSluitPotHardware> | null; } | null;
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'hefSchuifSchemaCode'?: HefSchuifSchemaCode;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'isHefSchuif': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'isZijlicht': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSillCompartmentLayoutOption
     */
    'isStandard': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguratorSluitPotHardware
 */
export interface ConfiguratorSluitPotHardware {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSluitPotHardware
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSluitPotHardware
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSluitPotHardware
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSluitPotHardware
     */
    'active': boolean;
}
        /**
 * 
 * @export
 * @interface ConfiguratorSluitpotBeslag
 */
export interface ConfiguratorSluitpotBeslag {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSluitpotBeslag
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSluitpotBeslag
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSluitpotBeslag
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorSluitpotBeslag
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorSluitpotBeslag
     */
    'imageUrl'?: string | null;
}
        /**
 * 
 * @export
 * @interface ConfiguratorStopProfile
 */
export interface ConfiguratorStopProfile {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorStopProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorStopProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorStopProfile
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorStopProfile
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {Array<CompartmentLayoutTypeReference>}
     * @memberof ConfiguratorStopProfile
     */
    'compartmentLayoutTypes': Array<CompartmentLayoutTypeReference>;
}
        /**
 * 
 * @export
 * @interface ConfiguredNeut
 */
export interface ConfiguredNeut {
    /**
     * 
     * @type {ContraProfileValue}
     * @memberof ConfiguredNeut
     */
    'bottomProfile': ContraProfileValue;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredNeut
     */
    'colorId': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredNeut
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredNeut
     */
    'height': number;
    /**
     * 
     * @type {ConfiguredNeutLeftProfile}
     * @memberof ConfiguredNeut
     */
    'leftProfile': ConfiguredNeutLeftProfile;
    /**
     * 
     * @type {ConfiguredNeutLeftProfile}
     * @memberof ConfiguredNeut
     */
    'rightProfile': ConfiguredNeutLeftProfile;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredNeut
     */
    'indentOutside': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredNeut
     */
    'indentInside': number;
    /**
     * 
     * @type {Array<NeutDrillHole>}
     * @memberof ConfiguredNeut
     */
    'drillHoles'?: Array<NeutDrillHole> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredNeut
     */
    'isIndented': boolean;
}
        /**
 * @type ConfiguredNeutLeftProfile
 * @export
 */
export type ConfiguredNeutLeftProfile = AngledWallConnection | CompartmentProfileValue | ContraProfileValue | CustomProfileWallConnection | StandardWallConnection | WallConnection;

        /**
 * 
 * @export
 * @interface ConfiguredNeutProduct
 */
export interface ConfiguredNeutProduct extends CatalogItem {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof ConfiguredNeutProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {ConfiguredNeut}
     * @memberof ConfiguredNeutProduct
     */
    'configuredNeut': ConfiguredNeut;
}


        /**
 * 
 * @export
 * @interface ConfiguredNeutResponse
 */
export interface ConfiguredNeutResponse {
    /**
     * 
     * @type {ConfiguredNeut}
     * @memberof ConfiguredNeutResponse
     */
    'neut': ConfiguredNeut;
    /**
     * 
     * @type {NeutNbr}
     * @memberof ConfiguredNeutResponse
     */
    'neutNbr': NeutNbr;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredNeutResponse
     */
    'svg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredNeutResponse
     */
    'hash': string;
}
        /**
 * 
 * @export
 * @interface ConfiguredSill
 */
export interface ConfiguredSill {
    /**
     * 
     * @type {SillModel}
     * @memberof ConfiguredSill
     */
    'model': SillModel;
    /**
     * 
     * @type {ConfiguredSillColor}
     * @memberof ConfiguredSill
     */
    'color': ConfiguredSillColor;
    /**
     * 
     * @type {AssemblyMethod}
     * @memberof ConfiguredSill
     */
    'assemblyMethod': AssemblyMethod;
    /**
     * 
     * @type {ConfiguredSillMountingOption}
     * @memberof ConfiguredSill
     */
    'mountingOption': ConfiguredSillMountingOption;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSill
     */
    'wrap': boolean;
    /**
     * 
     * @type {ConfiguredSillNeutPosition}
     * @memberof ConfiguredSill
     */
    'neutPosition'?: ConfiguredSillNeutPosition;
    /**
     * 
     * @type {RenderPreviewWallConnectionPostRequest}
     * @memberof ConfiguredSill
     */
    'wallConnectionLeft': RenderPreviewWallConnectionPostRequest;
    /**
     * 
     * @type {RenderPreviewWallConnectionPostRequest}
     * @memberof ConfiguredSill
     */
    'wallConnectionRight': RenderPreviewWallConnectionPostRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSill
     */
    'kopIsolator': boolean;
    /**
     * 
     * @type {Array<ConfiguredSillCompartmentsInner>}
     * @memberof ConfiguredSill
     */
    'compartments': Array<ConfiguredSillCompartmentsInner>;
    /**
     * 
     * @type {Array<ConfiguredSillNeut>}
     * @memberof ConfiguredSill
     */
    'neuten'?: Array<ConfiguredSillNeut> | null;
    /**
     * 
     * @type {Array<ConfiguredSillPocket>}
     * @memberof ConfiguredSill
     */
    'pockets'?: Array<ConfiguredSillPocket> | null;
    /**
     * 
     * @type {Array<ConfiguredSillDrillHole>}
     * @memberof ConfiguredSill
     */
    'drillHoles'?: Array<ConfiguredSillDrillHole> | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'totalLength': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'drillHoleCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'lattenCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'glazingBarCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'glazingProfileCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'stopProfileCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'lProfileCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'sluitpotCount': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSill
     */
    'hefSchuifFixedDoorBarHoleCount': number;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillAerationHole
 */
export interface ConfiguredSillAerationHole {
    /**
     * 
     * @type {ConfiguredSillSide}
     * @memberof ConfiguredSillAerationHole
     */
    'side': ConfiguredSillSide;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillAerationHole
     */
    'diameter': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillAerationHole
     */
    'depth': number;
    /**
     * 
     * @type {Point}
     * @memberof ConfiguredSillAerationHole
     */
    'position': Point;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillColor
 */
export interface ConfiguredSillColor {
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillColor
     */
    'colorId': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillColor
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillColor
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillCompartment
 */
export interface ConfiguredSillCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillCompartment
     */
    'protector': boolean;
}


        /**
 * @type ConfiguredSillCompartmentsInner
 * @export
 */
export type ConfiguredSillCompartmentsInner = ConfiguredSillDeurBinnendraaiendCompartment | ConfiguredSillDeurBuitendraaiendCompartment | ConfiguredSillDraaiKiepCompartment | ConfiguredSillHefSchuifACompartment | ConfiguredSillHefSchuifCCompartment | ConfiguredSillHefSchuifDCompartment | ConfiguredSillHefSchuifECompartment | ConfiguredSillVastglasBinnenCompartment | ConfiguredSillVastglasBuitenCompartment | ConfiguredSillVouwwandBinnenCompartment | ConfiguredSillVouwwandBuitenCompartment | ConfiguredSillZijlichtCompartment;

        /**
 * 
 * @export
 * @interface ConfiguredSillCornerPiece
 */
export interface ConfiguredSillCornerPiece {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillCornerPiece
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillCornerPiece
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillCornerPiece
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillCornerPiece
     */
    'length': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillDeurBinnendraaiendCompartment
 */
export interface ConfiguredSillDeurBinnendraaiendCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'profile': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {ConfiguredSillLProfile}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'lProfile'?: ConfiguredSillLProfile;
    /**
     * 
     * @type {ConfiguredSillStopProfile}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'stopProfile'?: ConfiguredSillStopProfile;
    /**
     * 
     * @type {Array<ConfiguredSillSluitpot>}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'sluitpotten'?: Array<ConfiguredSillSluitpot> | null;
    /**
     * 
     * @type {Array<ConfiguredSillDrainageHole>}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'drainageHoles'?: Array<ConfiguredSillDrainageHole> | null;
    /**
     * 
     * @type {Array<ConfiguredSillPaddenstoel>}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'paddenstoelen'?: Array<ConfiguredSillPaddenstoel> | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillDeurBinnendraaiendCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillDeurBuitendraaiendCompartment
 */
export interface ConfiguredSillDeurBuitendraaiendCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillDeurBuitendraaiendCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillDeurBuitendraaiendCompartment
     */
    'profile': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillDeurBuitendraaiendCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {Array<ConfiguredSillSluitpot>}
     * @memberof ConfiguredSillDeurBuitendraaiendCompartment
     */
    'sluitpotten'?: Array<ConfiguredSillSluitpot> | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDeurBuitendraaiendCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillDeurBuitendraaiendCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillDiffResult
 */
export interface ConfiguredSillDiffResult {
    /**
     * 
     * @type {ConfiguredSill}
     * @memberof ConfiguredSillDiffResult
     */
    'left': ConfiguredSill;
    /**
     * 
     * @type {ConfiguredSill}
     * @memberof ConfiguredSillDiffResult
     */
    'right': ConfiguredSill;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillDiffResult
     */
    'diff': string;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillDoubleFixedDoorBar
 */
export interface ConfiguredSillDoubleFixedDoorBar {
    /**
     * 
     * @type {ConfiguredSillFixedDoorBarSegment}
     * @memberof ConfiguredSillDoubleFixedDoorBar
     */
    'left': ConfiguredSillFixedDoorBarSegment;
    /**
     * 
     * @type {ConfiguredSillFixedDoorBarSegment}
     * @memberof ConfiguredSillDoubleFixedDoorBar
     */
    'right': ConfiguredSillFixedDoorBarSegment;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDoubleFixedDoorBar
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDoubleFixedDoorBar
     */
    'width': number;
    /**
     * 
     * @type {ConfiguredSillFixedDoorBarRaisedRim}
     * @memberof ConfiguredSillDoubleFixedDoorBar
     */
    'raisedRim': ConfiguredSillFixedDoorBarRaisedRim;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillDraaiKiepCompartment
 */
export interface ConfiguredSillDraaiKiepCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'profile': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {ConfiguredSillStopProfile}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'stopProfile'?: ConfiguredSillStopProfile;
    /**
     * 
     * @type {ConfiguredSillLProfile}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'lProfile'?: ConfiguredSillLProfile;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'stopRabbetDepth': number;
    /**
     * 
     * @type {Array<ConfiguredSillPaddenstoel>}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'paddenstoelen'?: Array<ConfiguredSillPaddenstoel> | null;
    /**
     * 
     * @type {Array<ConfiguredSillDrainageHole>}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'drainageHoles'?: Array<ConfiguredSillDrainageHole> | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillDraaiKiepCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillDrainageHole
 */
export interface ConfiguredSillDrainageHole {
    /**
     * 
     * @type {ConfiguredSillSide}
     * @memberof ConfiguredSillDrainageHole
     */
    'side': ConfiguredSillSide;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDrainageHole
     */
    'diameter': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillDrainageHole
     */
    'depth': number;
    /**
     * 
     * @type {Point}
     * @memberof ConfiguredSillDrainageHole
     */
    'position': Point;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillDrillHole
 */
export interface ConfiguredSillDrillHole {
    /**
     * 
     * @type {ConfiguredSillSide}
     * @memberof ConfiguredSillDrillHole
     */
    'side': ConfiguredSillSide;
    /**
     * 
     * @type {ConfiguredSillDrillHoleKind}
     * @memberof ConfiguredSillDrillHole
     */
    'kind': ConfiguredSillDrillHoleKind;
    /**
     * 
     * @type {Point3D}
     * @memberof ConfiguredSillDrillHole
     */
    'position': Point3D;
    /**
     * 
     * @type {DrillHole}
     * @memberof ConfiguredSillDrillHole
     */
    'drillHole': DrillHole;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ConfiguredSillDrillHoleKind = {
    ZijlichtMounting: 'zijlichtMounting'
} as const;

export type ConfiguredSillDrillHoleKind = typeof ConfiguredSillDrillHoleKind[keyof typeof ConfiguredSillDrillHoleKind];


        /**
 * 
 * @export
 * @interface ConfiguredSillFixedDoorBar
 */
export interface ConfiguredSillFixedDoorBar {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBar
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBar
     */
    'width': number;
    /**
     * 
     * @type {ConfiguredSillFixedDoorBarRaisedRim}
     * @memberof ConfiguredSillFixedDoorBar
     */
    'raisedRim': ConfiguredSillFixedDoorBarRaisedRim;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillFixedDoorBarHole
 */
export interface ConfiguredSillFixedDoorBarHole {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarHole
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarHole
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarHole
     */
    'diameter': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillFixedDoorBarRaisedRim
 */
export interface ConfiguredSillFixedDoorBarRaisedRim {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarRaisedRim
     */
    'center': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarRaisedRim
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillFixedDoorBarSegment
 */
export interface ConfiguredSillFixedDoorBarSegment {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarSegment
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarSegment
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillFixedDoorBarSegment
     */
    'materialLength': number;
    /**
     * 
     * @type {ConfiguredSillHefSchuifBarCutout}
     * @memberof ConfiguredSillFixedDoorBarSegment
     */
    'cutout'?: ConfiguredSillHefSchuifBarCutout;
    /**
     * 
     * @type {Array<ConfiguredSillFixedDoorBarHole>}
     * @memberof ConfiguredSillFixedDoorBarSegment
     */
    'holes': Array<ConfiguredSillFixedDoorBarHole>;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillGlazingBar
 */
export interface ConfiguredSillGlazingBar {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingBar
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingBar
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingBar
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingBar
     */
    'length': number;
    /**
     * 
     * @type {ConfiguredSillCornerPiece}
     * @memberof ConfiguredSillGlazingBar
     */
    'cornerPieceLeft'?: ConfiguredSillCornerPiece;
    /**
     * 
     * @type {ConfiguredSillCornerPiece}
     * @memberof ConfiguredSillGlazingBar
     */
    'cornerPieceRight'?: ConfiguredSillCornerPiece;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillGlazingProfile
 */
export interface ConfiguredSillGlazingProfile {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingProfile
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingProfile
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingProfile
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingProfile
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingProfile
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingProfile
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillGlazingProfile
     */
    'stopPosition': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifACompartment
 */
export interface ConfiguredSillHefSchuifACompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillHefSchuifACompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {ConfiguredSillHefSchuifLayoutSchemaA}
     * @memberof ConfiguredSillHefSchuifACompartment
     */
    'hefSchuifLayout': ConfiguredSillHefSchuifLayoutSchemaA;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillHefSchuifACompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHefSchuifACompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillHefSchuifACompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifBarCutout
 */
export interface ConfiguredSillHefSchuifBarCutout {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHefSchuifBarCutout
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHefSchuifBarCutout
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifCCompartment
 */
export interface ConfiguredSillHefSchuifCCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillHefSchuifCCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {ConfiguredSillHefSchuifLayoutSchemaC}
     * @memberof ConfiguredSillHefSchuifCCompartment
     */
    'hefSchuifLayout': ConfiguredSillHefSchuifLayoutSchemaC;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillHefSchuifCCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHefSchuifCCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillHefSchuifCCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifDCompartment
 */
export interface ConfiguredSillHefSchuifDCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillHefSchuifDCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {ConfiguredSillHefSchuifLayoutSchemaD}
     * @memberof ConfiguredSillHefSchuifDCompartment
     */
    'hefSchuifLayout': ConfiguredSillHefSchuifLayoutSchemaD;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillHefSchuifDCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHefSchuifDCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillHefSchuifDCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifECompartment
 */
export interface ConfiguredSillHefSchuifECompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillHefSchuifECompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {ConfiguredSillHefSchuifLayoutSchemaE}
     * @memberof ConfiguredSillHefSchuifECompartment
     */
    'hefSchuifLayout': ConfiguredSillHefSchuifLayoutSchemaE;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillHefSchuifECompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHefSchuifECompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillHefSchuifECompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifLayout
 */
export interface ConfiguredSillHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof ConfiguredSillHefSchuifLayout
     */
    'schemaCode': HefSchuifSchemaCode;
    /**
     * 
     * @type {ConfiguredSillHefSchuifSlidingRail}
     * @memberof ConfiguredSillHefSchuifLayout
     */
    'primarySlidingRail'?: ConfiguredSillHefSchuifSlidingRail;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayout
     */
    'profileLeft': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayout
     */
    'profileRight': CompartmentProfileValue;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifLayoutSchemaA
 */
export interface ConfiguredSillHefSchuifLayoutSchemaA extends ConfiguredSillHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifProfilesSchemaA}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaA
     */
    'profiles': HefSchuifProfilesSchemaA;
    /**
     * 
     * @type {ConfiguredSillSingleFixedDoorBar}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaA
     */
    'fixedDoorBar': ConfiguredSillSingleFixedDoorBar;
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaA
     */
    'schemaCode': HefSchuifSchemaCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaA
     */
    'profileLeft': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaA
     */
    'profileRight': CompartmentProfileValue;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifLayoutSchemaC
 */
export interface ConfiguredSillHefSchuifLayoutSchemaC extends ConfiguredSillHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifProfilesSchemaC}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaC
     */
    'profiles': HefSchuifProfilesSchemaC;
    /**
     * 
     * @type {ConfiguredSillDoubleFixedDoorBar}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaC
     */
    'fixedDoorBar': ConfiguredSillDoubleFixedDoorBar;
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaC
     */
    'schemaCode': HefSchuifSchemaCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaC
     */
    'profileLeft': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaC
     */
    'profileRight': CompartmentProfileValue;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifLayoutSchemaD
 */
export interface ConfiguredSillHefSchuifLayoutSchemaD extends ConfiguredSillHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifProfilesSchemaD}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaD
     */
    'profiles': HefSchuifProfilesSchemaD;
    /**
     * 
     * @type {ConfiguredSillSlidingDoorBar}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaD
     */
    'slidingDoorBar': ConfiguredSillSlidingDoorBar;
    /**
     * 
     * @type {ConfiguredSillHefSchuifSlidingRail}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaD
     */
    'secondarySlidingRail'?: ConfiguredSillHefSchuifSlidingRail;
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaD
     */
    'schemaCode': HefSchuifSchemaCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaD
     */
    'profileLeft': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaD
     */
    'profileRight': CompartmentProfileValue;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifLayoutSchemaE
 */
export interface ConfiguredSillHefSchuifLayoutSchemaE extends ConfiguredSillHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifProfilesSchemaE}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaE
     */
    'profiles': HefSchuifProfilesSchemaE;
    /**
     * 
     * @type {ConfiguredSillHefSchuifSlidingRail}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaE
     */
    'secondarySlidingRail'?: ConfiguredSillHefSchuifSlidingRail;
    /**
     * 
     * @type {ConfiguredSillSlidingDoorBar}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaE
     */
    'slidingDoorBar': ConfiguredSillSlidingDoorBar;
    /**
     * 
     * @type {ConfiguredSillSingleFixedDoorBar}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaE
     */
    'fixedDoorBar': ConfiguredSillSingleFixedDoorBar;
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaE
     */
    'schemaCode': HefSchuifSchemaCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaE
     */
    'profileLeft': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillHefSchuifLayoutSchemaE
     */
    'profileRight': CompartmentProfileValue;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHefSchuifSlidingRail
 */
export interface ConfiguredSillHefSchuifSlidingRail {
    /**
     * 
     * @type {HefSchuifSlidingRailType}
     * @memberof ConfiguredSillHefSchuifSlidingRail
     */
    'railType': HefSchuifSlidingRailType;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHefSchuifSlidingRail
     */
    'center': number;
    /**
     * 
     * @type {BoundingBox}
     * @memberof ConfiguredSillHefSchuifSlidingRail
     */
    'boundingBox': BoundingBox;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillHole
 */
export interface ConfiguredSillHole {
    /**
     * 
     * @type {ConfiguredSillSide}
     * @memberof ConfiguredSillHole
     */
    'side': ConfiguredSillSide;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHole
     */
    'diameter': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillHole
     */
    'depth': number;
    /**
     * 
     * @type {Point}
     * @memberof ConfiguredSillHole
     */
    'position': Point;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ConfiguredSillHoleKind = {
    Aeration: 'aeration',
    Drainage: 'drainage'
} as const;

export type ConfiguredSillHoleKind = typeof ConfiguredSillHoleKind[keyof typeof ConfiguredSillHoleKind];


        /**
 * 
 * @export
 * @interface ConfiguredSillLProfile
 */
export interface ConfiguredSillLProfile {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillLProfile
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillLProfile
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillLProfile
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillLProfile
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillLProfile
     */
    'y': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillMountingOption
 */
export interface ConfiguredSillMountingOption {
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillMountingOption
     */
    'id': string;
    /**
     * 
     * @type {ConfiguredSillMountingOptionDrillHole}
     * @memberof ConfiguredSillMountingOption
     */
    'drillHole'?: ConfiguredSillMountingOptionDrillHole;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillMountingOptionDrillHole
 */
export interface ConfiguredSillMountingOptionDrillHole {
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillMountingOptionDrillHole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillMountingOptionDrillHole
     */
    'code': string;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillNeut
 */
export interface ConfiguredSillNeut {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillNeut
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillNeut
     */
    'height': number;
    /**
     * 
     * @type {Array<NeutDrillHole>}
     * @memberof ConfiguredSillNeut
     */
    'drillHoles'?: Array<NeutDrillHole> | null;
    /**
     * 
     * @type {ConfiguredSillNeutCustomProfileLeft}
     * @memberof ConfiguredSillNeut
     */
    'customProfileLeft'?: ConfiguredSillNeutCustomProfileLeft | null;
    /**
     * 
     * @type {ConfiguredSillNeutCustomProfileLeft}
     * @memberof ConfiguredSillNeut
     */
    'customProfileRight'?: ConfiguredSillNeutCustomProfileLeft | null;
}
        /**
 * @type ConfiguredSillNeutCustomProfileLeft
 * @export
 */
export type ConfiguredSillNeutCustomProfileLeft = AngledWallConnection | CompartmentProfileValue | ContraProfileValue | CustomProfileWallConnection | StandardWallConnection | WallConnection;

        /**
 * 
 * @export
 * @interface ConfiguredSillNeutPosition
 */
export interface ConfiguredSillNeutPosition {
    /**
     * 
     * @type {SillNeutIndention}
     * @memberof ConfiguredSillNeutPosition
     */
    'indention': SillNeutIndention;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillPaddenstoel
 */
export interface ConfiguredSillPaddenstoel {
    /**
     * 
     * @type {Point3D}
     * @memberof ConfiguredSillPaddenstoel
     */
    'position': Point3D;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillPaddenstoel
     */
    'drillHoleDiameter': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillPocket
 */
export interface ConfiguredSillPocket {
    /**
     * 
     * @type {ConfiguredSillSide}
     * @memberof ConfiguredSillPocket
     */
    'side': ConfiguredSillSide;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillPocket
     */
    'contour': string;
    /**
     * 
     * @type {Point}
     * @memberof ConfiguredSillPocket
     */
    'position': Point;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillPocket
     */
    'depth': number;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillProduct
 */
export interface ConfiguredSillProduct extends CatalogItem {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof ConfiguredSillProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillProduct
     */
    'configuredSillId': string;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillResponse
 */
export interface ConfiguredSillResponse {
    /**
     * 
     * @type {ConfiguredSill}
     * @memberof ConfiguredSillResponse
     */
    'sill': ConfiguredSill;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillResponse
     */
    'svg': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillResponse
     */
    'hash': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ConfiguredSillSide = {
    Front: 'front',
    Back: 'back',
    Top: 'top',
    Bottom: 'bottom',
    LeftEnd: 'leftEnd',
    RightEnd: 'rightEnd'
} as const;

export type ConfiguredSillSide = typeof ConfiguredSillSide[keyof typeof ConfiguredSillSide];


        /**
 * 
 * @export
 * @interface ConfiguredSillSingleFixedDoorBar
 */
export interface ConfiguredSillSingleFixedDoorBar {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'materialLength': number;
    /**
     * 
     * @type {ConfiguredSillHefSchuifBarCutout}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'cutout'?: ConfiguredSillHefSchuifBarCutout;
    /**
     * 
     * @type {Array<ConfiguredSillFixedDoorBarHole>}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'holes': Array<ConfiguredSillFixedDoorBarHole>;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'width': number;
    /**
     * 
     * @type {ConfiguredSillFixedDoorBarRaisedRim}
     * @memberof ConfiguredSillSingleFixedDoorBar
     */
    'raisedRim': ConfiguredSillFixedDoorBarRaisedRim;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillSlidingDoorBar
 */
export interface ConfiguredSillSlidingDoorBar {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSlidingDoorBar
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSlidingDoorBar
     */
    'rabbetDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSlidingDoorBar
     */
    'oppositeRabbetDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSlidingDoorBar
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSlidingDoorBar
     */
    'width': number;
    /**
     * 
     * @type {ConfiguredSillHefSchuifBarCutout}
     * @memberof ConfiguredSillSlidingDoorBar
     */
    'cutout'?: ConfiguredSillHefSchuifBarCutout;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillSlidingDoorBar
     */
    'materialLength': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillSluitpot
 */
export interface ConfiguredSillSluitpot {
    /**
     * 
     * @type {Point3D}
     * @memberof ConfiguredSillSluitpot
     */
    'position': Point3D;
    /**
     * 
     * @type {Pocket}
     * @memberof ConfiguredSillSluitpot
     */
    'pocket': Pocket;
    /**
     * 
     * @type {ConfiguredSillSluitpotBlock}
     * @memberof ConfiguredSillSluitpot
     */
    'sluitpotBlock'?: ConfiguredSillSluitpotBlock;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillSluitpot
     */
    'includeHardware': boolean;
    /**
     * 
     * @type {ConfiguredSillSluitpotHardware}
     * @memberof ConfiguredSillSluitpot
     */
    'sluitpotHardware'?: ConfiguredSillSluitpotHardware;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillSluitpotBlock
 */
export interface ConfiguredSillSluitpotBlock {
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillSluitpotBlock
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillSluitpotBlock
     */
    'code': string;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillSluitpotHardware
 */
export interface ConfiguredSillSluitpotHardware {
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillSluitpotHardware
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillSluitpotHardware
     */
    'code': string;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillStopProfile
 */
export interface ConfiguredSillStopProfile {
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillStopProfile
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillStopProfile
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillStopProfile
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillStopProfile
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillStopProfile
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillStopProfile
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillStopProfile
     */
    'stopPosition': number;
}
        /**
 * 
 * @export
 * @interface ConfiguredSillVastglasBinnenCompartment
 */
export interface ConfiguredSillVastglasBinnenCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'profile': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {ConfiguredSillGlazingProfile}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'glazingProfile'?: ConfiguredSillGlazingProfile;
    /**
     * 
     * @type {ConfiguredSillGlazingBar}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'glazingBar'?: ConfiguredSillGlazingBar;
    /**
     * 
     * @type {Array<ConfiguredSillAerationHole>}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'aerationHoles'?: Array<ConfiguredSillAerationHole> | null;
    /**
     * 
     * @type {Array<ConfiguredSillPaddenstoel>}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'paddenstoelen'?: Array<ConfiguredSillPaddenstoel> | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillVastglasBinnenCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillVastglasBuitenCompartment
 */
export interface ConfiguredSillVastglasBuitenCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillVastglasBuitenCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillVastglasBuitenCompartment
     */
    'profile': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillVastglasBuitenCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {ConfiguredSillGlazingBar}
     * @memberof ConfiguredSillVastglasBuitenCompartment
     */
    'glazingBar'?: ConfiguredSillGlazingBar;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillVastglasBuitenCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillVastglasBuitenCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillVouwwandBinnenCompartment
 */
export interface ConfiguredSillVouwwandBinnenCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillVouwwandBinnenCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillVouwwandBinnenCompartment
     */
    'profile': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillVouwwandBinnenCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {Array<ConfiguredSillSluitpot>}
     * @memberof ConfiguredSillVouwwandBinnenCompartment
     */
    'sluitpotten'?: Array<ConfiguredSillSluitpot> | null;
    /**
     * 
     * @type {Array<ConfiguredSillDrainageHole>}
     * @memberof ConfiguredSillVouwwandBinnenCompartment
     */
    'drainageHoles'?: Array<ConfiguredSillDrainageHole> | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillVouwwandBinnenCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillVouwwandBinnenCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillVouwwandBuitenCompartment
 */
export interface ConfiguredSillVouwwandBuitenCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillVouwwandBuitenCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof ConfiguredSillVouwwandBuitenCompartment
     */
    'profile': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfiles}
     * @memberof ConfiguredSillVouwwandBuitenCompartment
     */
    'profiles': CompartmentProfiles;
    /**
     * 
     * @type {Array<ConfiguredSillSluitpot>}
     * @memberof ConfiguredSillVouwwandBuitenCompartment
     */
    'sluitpotten'?: Array<ConfiguredSillSluitpot> | null;
    /**
     * 
     * @type {Array<ConfiguredSillDrainageHole>}
     * @memberof ConfiguredSillVouwwandBuitenCompartment
     */
    'drainageHoles'?: Array<ConfiguredSillDrainageHole> | null;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillVouwwandBuitenCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillVouwwandBuitenCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillZijlichtCompartment
 */
export interface ConfiguredSillZijlichtCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ConfiguredSillZijlichtCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillZijlichtCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguredSillZijlichtCompartment
     */
    'protector': boolean;
}


        /**
 * 
 * @export
 * @interface ConfiguredSillsListItem
 */
export interface ConfiguredSillsListItem {
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillsListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillsListItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillsListItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillsListItem
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguredSillsListItem
     */
    'model': string;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillsListItem
     */
    'totalSold': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguredSillsListItem
     */
    'totalLength': number;
}
        /**
 * 
 * @export
 * @interface ContactPersonRepresentation
 */
export interface ContactPersonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRepresentation
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRepresentation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRepresentation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRepresentation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRepresentation
     */
    'telephone'?: string | null;
}
        /**
 * 
 * @export
 * @interface ContraProfileInfo
 */
export interface ContraProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof ContraProfileInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContraProfileInfo
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContraProfileInfo
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContraProfileInfo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ContraProfileInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ContraProfileInfo
     */
    'sillWidth': number;
    /**
     * 
     * @type {string}
     * @memberof ContraProfileInfo
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof ContraProfileInfo
     */
    'svgPreview'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContraProfileInfo
     */
    'hasProgram': boolean;
}
        /**
 * 
 * @export
 * @interface ContraProfileValue
 */
export interface ContraProfileValue {
    /**
     * 
     * @type {string}
     * @memberof ContraProfileValue
     */
    'geometry': string;
    /**
     * 
     * @type {number}
     * @memberof ContraProfileValue
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface CopyOrderRepresentation
 */
export interface CopyOrderRepresentation {
    /**
     * 
     * @type {string}
     * @memberof CopyOrderRepresentation
     */
    'remark': string;
}
        /**
 * 
 * @export
 * @interface CopySillOrderLineModel
 */
export interface CopySillOrderLineModel {
    /**
     * 
     * @type {boolean}
     * @memberof CopySillOrderLineModel
     */
    'mirror'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CopySillOrderLineModel
     */
    'merk'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CopySillOrderLineModel
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CopySillOrderLineModel
     */
    'quantity'?: number | null;
}
        /**
 * 
 * @export
 * @interface CornerPiece
 */
export interface CornerPiece {
    /**
     * 
     * @type {string}
     * @memberof CornerPiece
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CornerPiece
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CornerPiece
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CornerPiece
     */
    'name': string;
    /**
     * 
     * @type {GlazingBarReference}
     * @memberof CornerPiece
     */
    'glazingBar': GlazingBarReference;
    /**
     * 
     * @type {number}
     * @memberof CornerPiece
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof CornerPiece
     */
    'contour'?: string;
}
        /**
 * 
 * @export
 * @interface CornerPieceIdReferenceWithIdCodeAndName
 */
export interface CornerPieceIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof CornerPieceIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CornerPieceIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CornerPieceIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CornerPieceProduct
 */
export interface CornerPieceProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof CornerPieceProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {CornerPieceProductCornerPiece}
     * @memberof CornerPieceProduct
     */
    'cornerPiece': CornerPieceProductCornerPiece;
    /**
     * 
     * @type {CatalogItemColor}
     * @memberof CornerPieceProduct
     */
    'color': CatalogItemColor;
}


        /**
 * 
 * @export
 * @interface CornerPieceProductCornerPiece
 */
export interface CornerPieceProductCornerPiece {
    /**
     * 
     * @type {string}
     * @memberof CornerPieceProductCornerPiece
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CornerPieceProductCornerPiece
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CornerPieceProductCornerPiece
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CornerPieceReference
 */
export interface CornerPieceReference {
    /**
     * 
     * @type {number}
     * @memberof CornerPieceReference
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CornerPieceReference
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof CornerPieceReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CornerPieceReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CornerPieceReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CornerProfile
 */
export interface CornerProfile {
    /**
     * 
     * @type {string}
     * @memberof CornerProfile
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof CornerProfile
     */
    'id': string;
}
        /**
 * 
 * @export
 * @interface CornerProfileInfo
 */
export interface CornerProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof CornerProfileInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CornerProfileInfo
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CornerProfileInfo
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CountMessage
 */
export interface CountMessage {
    /**
     * 
     * @type {number}
     * @memberof CountMessage
     */
    'count': number;
}
        /**
 * 
 * @export
 * @interface CreateCatalogCommand
 */
export interface CreateCatalogCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCatalogCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalogCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CreateCatalogItemCommand
 */
export interface CreateCatalogItemCommand {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof CreateCatalogItemCommand
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalogItemCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCatalogItemCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalogItemCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalogItemCommand
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalogItemCommand
     */
    'discountGroupId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCatalogItemCommand
     */
    'catalogIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCatalogItemCommand
     */
    'categoryIds'?: Array<string> | null;
}


        /**
 * 
 * @export
 * @interface CreateCatalogItemDiscountRuleCommand
 */
export interface CreateCatalogItemDiscountRuleCommand extends CreateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCatalogItemDiscountRuleCommand
     */
    'catalogItemId': string;
}


        /**
 * 
 * @export
 * @interface CreateCategoryCommand
 */
export interface CreateCategoryCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CreateColorCommand
 */
export interface CreateColorCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateColorCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateColorCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateColorCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateColorCommand
     */
    'hex': string;
}
        /**
 * 
 * @export
 * @interface CreateCompartmentLayoutTypeCommand
 */
export interface CreateCompartmentLayoutTypeCommand {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof CreateCompartmentLayoutTypeCommand
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof CreateCompartmentLayoutTypeCommand
     */
    'name': string;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof CreateCompartmentLayoutTypeCommand
     */
    'rabbetPosition': RabbetPosition;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCompartmentLayoutTypeCommand
     */
    'sluitpotBeslagIds'?: Array<string> | null;
}


        /**
 * 
 * @export
 * @interface CreateCompartmentProfileModel
 */
export interface CreateCompartmentProfileModel extends CreateProfileModel {
}
        /**
 * 
 * @export
 * @interface CreateConfiguredNeutProductCommand
 */
export interface CreateConfiguredNeutProductCommand extends CreateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'colorId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'bottomProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'leftProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'rightProfileId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'indentOutside': number;
    /**
     * 
     * @type {number}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'indentInside': number;
    /**
     * 
     * @type {object}
     * @memberof CreateConfiguredNeutProductCommand
     */
    'mounting'?: object;
}


        /**
 * 
 * @export
 * @interface CreateConfiguredSillProductCommand
 */
export interface CreateConfiguredSillProductCommand extends CreateCatalogItemCommand {
}


        /**
 * 
 * @export
 * @interface CreateContraProfileModel
 */
export interface CreateContraProfileModel extends CreateProfileModel {
    /**
     * 
     * @type {string}
     * @memberof CreateContraProfileModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContraProfileModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateContraProfileModel
     */
    'sillWidth': number;
    /**
     * 
     * @type {string}
     * @memberof CreateContraProfileModel
     */
    'geometry': string;
}
        /**
 * 
 * @export
 * @interface CreateCornerPieceCommand
 */
export interface CreateCornerPieceCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCornerPieceCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCornerPieceCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCornerPieceCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCornerPieceCommand
     */
    'glazingBarId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCornerPieceCommand
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCornerPieceCommand
     */
    'contour'?: string;
}
        /**
 * 
 * @export
 * @interface CreateCornerPieceProductCommand
 */
export interface CreateCornerPieceProductCommand extends CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCornerPieceProductCommand
     */
    'cornerPieceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCornerPieceProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface CreateDiscountGroupCommand
 */
export interface CreateDiscountGroupCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountGroupCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountGroupCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CreateDiscountGroupDiscountRuleCommand
 */
export interface CreateDiscountGroupDiscountRuleCommand extends CreateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountGroupDiscountRuleCommand
     */
    'discountGroupId': string;
}


        /**
 * 
 * @export
 * @interface CreateDiscountRuleCommand
 */
export interface CreateDiscountRuleCommand {
    /**
     * 
     * @type {DiscountRuleType}
     * @memberof CreateDiscountRuleCommand
     */
    'discountRuleType': DiscountRuleType;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRuleCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRuleCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountRuleCommand
     */
    'discount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRuleCommand
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRuleCommand
     */
    'note'?: string | null;
}


        /**
 * 
 * @export
 * @interface CreateGlazingBarCommand
 */
export interface CreateGlazingBarCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingBarCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateGlazingBarCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingBarCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingBarCommand
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingBarCommand
     */
    'height': number;
}
        /**
 * 
 * @export
 * @interface CreateGlazingBarProductCommand
 */
export interface CreateGlazingBarProductCommand extends CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingBarProductCommand
     */
    'glazingBarId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingBarProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface CreateGlazingProfileCommand
 */
export interface CreateGlazingProfileCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingProfileCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateGlazingProfileCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingProfileCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingProfileCommand
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingProfileCommand
     */
    'defaultWidth': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingProfileCommand
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingProfileCommand
     */
    'mountingSlotPosition': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingProfileCommand
     */
    'stopPosition': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGlazingProfileCommand
     */
    'rabbetWidth': number;
}
        /**
 * 
 * @export
 * @interface CreateGlazingProfileProductCommand
 */
export interface CreateGlazingProfileProductCommand extends CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingProfileProductCommand
     */
    'glazingProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGlazingProfileProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface CreateImportProfileMappingCommand
 */
export interface CreateImportProfileMappingCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateImportProfileMappingCommand
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportProfileMappingCommand
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportProfileMappingCommand
     */
    'compartmentLayoutTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportProfileMappingCommand
     */
    'neutPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportProfileMappingCommand
     */
    'profileGeometry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportProfileMappingCommand
     */
    'profileId': string;
    /**
     * 
     * @type {MappingKind}
     * @memberof CreateImportProfileMappingCommand
     */
    'kind': MappingKind;
}


        /**
 * 
 * @export
 * @interface CreateImportSluitpotMappingCommand
 */
export interface CreateImportSluitpotMappingCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateImportSluitpotMappingCommand
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateImportSluitpotMappingCommand
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportSluitpotMappingCommand
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportSluitpotMappingCommand
     */
    'toId': string;
}
        /**
 * 
 * @export
 * @interface CreateKopIsolatorSetProductCommand
 */
export interface CreateKopIsolatorSetProductCommand extends CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateKopIsolatorSetProductCommand
     */
    'sillId': string;
}


        /**
 * 
 * @export
 * @interface CreateLProfileCommand
 */
export interface CreateLProfileCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateLProfileCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLProfileCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLProfileCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateLProfileCommand
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof CreateLProfileCommand
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface CreateLProfileProductCommand
 */
export interface CreateLProfileProductCommand extends CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateLProfileProductCommand
     */
    'lProfileId': string;
}


        /**
 * 
 * @export
 * @interface CreateMountingOptionCommand
 */
export interface CreateMountingOptionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateMountingOptionCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMountingOptionCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateMountingOptionCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMountingOptionCommand
     */
    'drillHoleId'?: string | null;
}
        /**
 * 
 * @export
 * @interface CreatePriceListAndCatalogItemDiscountRuleCommand
 */
export interface CreatePriceListAndCatalogItemDiscountRuleCommand extends CreateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListAndCatalogItemDiscountRuleCommand
     */
    'priceListId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListAndCatalogItemDiscountRuleCommand
     */
    'catalogItemId': string;
}


        /**
 * 
 * @export
 * @interface CreatePriceListAndDiscountGroupDiscountRuleCommand
 */
export interface CreatePriceListAndDiscountGroupDiscountRuleCommand extends CreateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListAndDiscountGroupDiscountRuleCommand
     */
    'priceListId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListAndDiscountGroupDiscountRuleCommand
     */
    'discountGroupId': string;
}


        /**
 * 
 * @export
 * @interface CreatePriceListCommand
 */
export interface CreatePriceListCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListCommand
     */
    'parentPriceListId'?: string | null;
}
        /**
 * 
 * @export
 * @interface CreatePriceListDiscountRuleCommand
 */
export interface CreatePriceListDiscountRuleCommand extends CreateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePriceListDiscountRuleCommand
     */
    'priceListId': string;
}


        /**
 * 
 * @export
 * @interface CreateProductCommand
 */
export interface CreateProductCommand extends CreateCatalogItemCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    'price': number;
}


        /**
 * 
 * @export
 * @interface CreateProfileModel
 */
export interface CreateProfileModel {
    /**
     * 
     * @type {string}
     * @memberof CreateProfileModel
     */
    'profileType': string;
}
        /**
 * @type CreateProfileRequestInner
 * @export
 */
export type CreateProfileRequestInner = CreateCompartmentProfileModel | CreateContraProfileModel | CreateStandardCompartmentProfileModel | CreateStandardWallProfileModel | CreateWallProfileModel;

        /**
 * 
 * @export
 * @interface CreateRabbetCommand
 */
export interface CreateRabbetCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateRabbetCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRabbetCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRabbetCommand
     */
    'name': string;
    /**
     * 
     * @type {RabbetType}
     * @memberof CreateRabbetCommand
     */
    'type': RabbetType;
    /**
     * 
     * @type {RabbetApplication}
     * @memberof CreateRabbetCommand
     */
    'application'?: RabbetApplication;
    /**
     * 
     * @type {string}
     * @memberof CreateRabbetCommand
     */
    'geometry': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRabbetCommand
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRabbetCommand
     */
    'depth': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRabbetCommand
     */
    'compartmentLayoutTypeIds': Array<string>;
    /**
     * 
     * @type {TurboholRabbet}
     * @memberof CreateRabbetCommand
     */
    'turboholRabbet'?: TurboholRabbet;
    /**
     * 
     * @type {HefSchuifGroove}
     * @memberof CreateRabbetCommand
     */
    'groove'?: HefSchuifGroove;
}


        /**
 * 
 * @export
 * @interface CreateSillCommand
 */
export interface CreateSillCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSillCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSillCommand
     */
    'name': string;
    /**
     * 
     * @type {SillType}
     * @memberof CreateSillCommand
     */
    'type': SillType;
    /**
     * 
     * @type {number}
     * @memberof CreateSillCommand
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSillCommand
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSillCommand
     */
    'rand'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSillCommand
     */
    'description'?: string | null;
    /**
     * 
     * @type {SillAssemblyOptions}
     * @memberof CreateSillCommand
     */
    'assemblyOptions': SillAssemblyOptions;
    /**
     * 
     * @type {string}
     * @memberof CreateSillCommand
     */
    'baseSill': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSillCommand
     */
    'extender'?: string | null;
}


        /**
 * 
 * @export
 * @interface CreateSillConfiguratorProductCommand
 */
export interface CreateSillConfiguratorProductCommand extends CreateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSillConfiguratorProductCommand
     */
    'sillId': string;
}


        /**
 * 
 * @export
 * @interface CreateSillDrainageOptionCommand
 */
export interface CreateSillDrainageOptionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSillDrainageOptionCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSillDrainageOptionCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSillDrainageOptionCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CreateSillProductCommand
 */
export interface CreateSillProductCommand extends CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSillProductCommand
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSillProductCommand
     */
    'colorId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSillProductCommand
     */
    'length': number;
}


        /**
 * 
 * @export
 * @interface CreateSillWrappingOptionCommand
 */
export interface CreateSillWrappingOptionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSillWrappingOptionCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSillWrappingOptionCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSillWrappingOptionCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CreateSluitpotBeslagCommand
 */
export interface CreateSluitpotBeslagCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotBeslagCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSluitpotBeslagCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotBeslagCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotBeslagCommand
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotBeslagCommand
     */
    'svg'?: string | null;
}
        /**
 * 
 * @export
 * @interface CreateSluitpotBlockTypeCommand
 */
export interface CreateSluitpotBlockTypeCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotBlockTypeCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotBlockTypeCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CreateSluitpotCommand
 */
export interface CreateSluitpotCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSluitpotCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotCommand
     */
    'contour': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotCommand
     */
    'sluitpotBeslagId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotCommand
     */
    'sluitpotBlockTypeId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSluitpotCommand
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSluitpotCommand
     */
    'angle': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotCommand
     */
    'contourSvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSluitpotCommand
     */
    'operationName'?: string | null;
}
        /**
 * 
 * @export
 * @interface CreateStandardCompartmentProfileModel
 */
export interface CreateStandardCompartmentProfileModel extends CreateCompartmentProfileModel {
    /**
     * 
     * @type {string}
     * @memberof CreateStandardCompartmentProfileModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardCompartmentProfileModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardCompartmentProfileModel
     */
    'sillWidth': number;
    /**
     * 
     * @type {CompartmentProfileStandardRabbet}
     * @memberof CreateStandardCompartmentProfileModel
     */
    'rabbet'?: CompartmentProfileStandardRabbet;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardCompartmentProfileModel
     */
    'cornerProfileOutsideId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardCompartmentProfileModel
     */
    'cornerProfileInsideId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateStandardCompartmentProfileModel
     */
    'activate': boolean;
}
        /**
 * 
 * @export
 * @interface CreateStandardProductCommand
 */
export interface CreateStandardProductCommand extends CreateProductCommand {
}


        /**
 * 
 * @export
 * @interface CreateStandardWallProfileModel
 */
export interface CreateStandardWallProfileModel extends CreateWallProfileModel {
    /**
     * 
     * @type {string}
     * @memberof CreateStandardWallProfileModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardWallProfileModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardWallProfileModel
     */
    'width': number;
    /**
     * 
     * @type {CustomProfileWallConnection | AngledWallConnection | StandardWallConnection}
     * @memberof CreateStandardWallProfileModel
     */
    'wallConnection'?: CustomProfileWallConnection | AngledWallConnection | StandardWallConnection | null;
}
        /**
 * 
 * @export
 * @interface CreateStopProfileCommand
 */
export interface CreateStopProfileCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateStopProfileCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateStopProfileCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateStopProfileCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStopProfileCommand
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStopProfileCommand
     */
    'defaultWidth': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStopProfileCommand
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStopProfileCommand
     */
    'mountingSlotPosition': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStopProfileCommand
     */
    'stopPosition': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStopProfileCommand
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateStopProfileCommand
     */
    'compartmentLayoutTypeIds': Array<string>;
}
        /**
 * 
 * @export
 * @interface CreateStopProfileProductCommand
 */
export interface CreateStopProfileProductCommand extends CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateStopProfileProductCommand
     */
    'stopProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStopProfileProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface CreateTransportProductCommand
 */
export interface CreateTransportProductCommand extends CreateProductCommand {
}


        /**
 * 
 * @export
 * @interface CreateTurboholTypeCommand
 */
export interface CreateTurboholTypeCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateTurboholTypeCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTurboholTypeCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CreateWallProfileModel
 */
export interface CreateWallProfileModel extends CreateProfileModel {
}
        /**
 * 
 * @export
 * @interface CurveSegment
 */
export interface CurveSegment {
    /**
     * 
     * @type {Point}
     * @memberof CurveSegment
     */
    'centerPoint': Point;
    /**
     * 
     * @type {boolean}
     * @memberof CurveSegment
     */
    'isCcw': boolean;
    /**
     * 
     * @type {Point}
     * @memberof CurveSegment
     */
    'angleStartPoint': Point;
    /**
     * 
     * @type {Point}
     * @memberof CurveSegment
     */
    'angleEndPoint': Point;
    /**
     * 
     * @type {number}
     * @memberof CurveSegment
     */
    'radius': number;
    /**
     * 
     * @type {number}
     * @memberof CurveSegment
     */
    'sweep': number;
    /**
     * 
     * @type {number}
     * @memberof CurveSegment
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof CurveSegment
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof CurveSegment
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof CurveSegment
     */
    'minY': number;
    /**
     * 
     * @type {BoundingBox}
     * @memberof CurveSegment
     */
    'boundingBox': BoundingBox;
    /**
     * 
     * @type {Point}
     * @memberof CurveSegment
     */
    'startPoint': Point;
    /**
     * 
     * @type {Point}
     * @memberof CurveSegment
     */
    'endPoint': Point;
}
        /**
 * 
 * @export
 * @interface CustomProfileWallConnection
 */
export interface CustomProfileWallConnection extends WallConnection {
    /**
     * 
     * @type {number}
     * @memberof CustomProfileWallConnection
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof CustomProfileWallConnection
     */
    'profileId': string;
}


        /**
 * 
 * @export
 * @interface CustomerAddressRepresentation
 */
export interface CustomerAddressRepresentation {
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'attention'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'street': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAddressRepresentation
     */
    'houseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'houseNumberAddition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'extraAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressRepresentation
     */
    'country': string;
    /**
     * 
     * @type {ContactPersonRepresentation}
     * @memberof CustomerAddressRepresentation
     */
    'contactPerson'?: ContactPersonRepresentation;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAddressRepresentation
     */
    'isInvoicingAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAddressRepresentation
     */
    'isDeliveryAddress': boolean;
}
        /**
 * 
 * @export
 * @interface CustomerCatalog
 */
export interface CustomerCatalog {
    /**
     * 
     * @type {string}
     * @memberof CustomerCatalog
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCatalog
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCatalog
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CustomerIdReferenceWithIdCodeAndName
 */
export interface CustomerIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof CustomerIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CustomerListRepresentation
 */
export interface CustomerListRepresentation {
    /**
     * 
     * @type {string}
     * @memberof CustomerListRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListRepresentation
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListRepresentation
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerListRepresentation
     */
    'cocNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerListRepresentation
     */
    'cocDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerListRepresentation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerListRepresentation
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerListRepresentation
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerListRepresentation
     */
    'waiveTransportCosts': boolean;
    /**
     * 
     * @type {CustomerCatalog}
     * @memberof CustomerListRepresentation
     */
    'catalog': CustomerCatalog;
    /**
     * 
     * @type {CustomerPriceList}
     * @memberof CustomerListRepresentation
     */
    'priceList': CustomerPriceList;
}
        /**
 * 
 * @export
 * @interface CustomerListView
 */
export interface CustomerListView {
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerListView
     */
    'isArchived': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'created': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerListView
     */
    'waiveTransportCosts': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'catalogId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'catalogName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'priceListId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'priceListName': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerListView
     */
    'orderCount': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerListView
     */
    'latestOrderDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerListView
     */
    'sales': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerListView
     */
    'avgSalesLast4Months': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerListView
     */
    'avgSalesLast12Months': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerListView
     */
    'growthInSales'?: number | null;
}
        /**
 * 
 * @export
 * @interface CustomerListViewArrayODataValue
 */
export interface CustomerListViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof CustomerListViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerListViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerListViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<CustomerListView>}
     * @memberof CustomerListViewArrayODataValue
     */
    'value': Array<CustomerListView>;
}
        /**
 * 
 * @export
 * @interface CustomerMapItem
 */
export interface CustomerMapItem {
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerMapItem
     */
    'orderCount': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerMapItem
     */
    'revenue': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'houseNumberAddition': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMapItem
     */
    'country': string;
}
        /**
 * 
 * @export
 * @interface CustomerPriceList
 */
export interface CustomerPriceList {
    /**
     * 
     * @type {string}
     * @memberof CustomerPriceList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPriceList
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPriceList
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CustomerReference
 */
export interface CustomerReference {
    /**
     * 
     * @type {string}
     * @memberof CustomerReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface CustomerRepresentation
 */
export interface CustomerRepresentation {
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'cocNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'cocDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'email'?: string | null;
    /**
     * 
     * @type {PaymentConditions}
     * @memberof CustomerRepresentation
     */
    'paymentConditions': PaymentConditions;
    /**
     * 
     * @type {DeliveryConditions}
     * @memberof CustomerRepresentation
     */
    'deliveryConditions': DeliveryConditions;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'catalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerRepresentation
     */
    'priceListId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRepresentation
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRepresentation
     */
    'waiveTransportCosts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRepresentation
     */
    'gaImportEnabled': boolean;
    /**
     * 
     * @type {Array<CustomerAddressRepresentation>}
     * @memberof CustomerRepresentation
     */
    'addresses': Array<CustomerAddressRepresentation>;
}


        /**
 * 
 * @export
 * @interface CustomerSillStats
 */
export interface CustomerSillStats {
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'jaar'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSillStats
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSillStats
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'leveringen': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'orders': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'omzet': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'omzetDorpels': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'omzetProducten': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'omzetTransport': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'omzetOverig': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'avgOrderWaarde': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'avgLeveringWaarde': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'avgDorpelWaarde': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'dorpels': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'neuten': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'avgNeuten': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'lengte': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'avgLengte': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'maxLengte': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'latten': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'glazingBars': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'glazingProfiles': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'lProfiles': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'stopProfiles': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'sluitpotten': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'boorgaten': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'ingepakt': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'dorpelBU': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'lengteBU': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'dorpelHefSchuif': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'lengteHefSchuif': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'dorpelBI': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'lengteBI': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'dorpelZL': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSillStats
     */
    'lengteZL': number;
}
        /**
 * 
 * @export
 * @interface DailyProductionAnalyticsRow
 */
export interface DailyProductionAnalyticsRow {
    /**
     * 
     * @type {number}
     * @memberof DailyProductionAnalyticsRow
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof DailyProductionAnalyticsRow
     */
    'week': number;
    /**
     * 
     * @type {string}
     * @memberof DailyProductionAnalyticsRow
     */
    'date': string;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'dorpels': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'lattenMaken': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'neutenMaken': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'dorpelProfielZagen': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'dorpelBewerken': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'neutenMonteren': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'sluitpottenMonteren': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'kopisolatorenPlaatsen': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'manchettesPlaatsen': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'lattenMonteren': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'inpakken': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'hefschuifVouwwand': ProductionAnalysisMetrics;
    /**
     * 
     * @type {ProductionAnalysisMetrics}
     * @memberof DailyProductionAnalyticsRow
     */
    'specials': ProductionAnalysisMetrics;
}
        /**
 * 
 * @export
 * @interface DailyProductionGraphStatsRow
 */
export interface DailyProductionGraphStatsRow {
    /**
     * 
     * @type {string}
     * @memberof DailyProductionGraphStatsRow
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DailyProductionGraphStatsRow
     */
    'geproduceerdeDorpels': number;
    /**
     * 
     * @type {number}
     * @memberof DailyProductionGraphStatsRow
     */
    'geplandeDorpels': number;
    /**
     * 
     * @type {number}
     * @memberof DailyProductionGraphStatsRow
     */
    'runningTotal': number;
}
        /**
 * 
 * @export
 * @interface DecimalStat
 */
export interface DecimalStat {
    /**
     * 
     * @type {number}
     * @memberof DecimalStat
     */
    'sum': number;
    /**
     * 
     * @type {number}
     * @memberof DecimalStat
     */
    'ytd': number;
}
        /**
 * 
 * @export
 * @interface DefaultDrillHoleYPositions
 */
export interface DefaultDrillHoleYPositions {
    /**
     * 
     * @type {number}
     * @memberof DefaultDrillHoleYPositions
     */
    'drillHole1': number;
    /**
     * 
     * @type {number}
     * @memberof DefaultDrillHoleYPositions
     */
    'drillHole2': number;
}
        /**
 * 
 * @export
 * @interface DeletePriceCommand
 */
export interface DeletePriceCommand extends PriceCommand {
    /**
     * 
     * @type {string}
     * @memberof DeletePriceCommand
     */
    'priceListId'?: string | null;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const DeliveryConditions = {
    AfFabriek: 'afFabriek',
    Bezorging: 'bezorging'
} as const;

export type DeliveryConditions = typeof DeliveryConditions[keyof typeof DeliveryConditions];


        /**
 * 
 * @export
 * @interface DeliveryPhotoRepresentation
 */
export interface DeliveryPhotoRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPhotoRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPhotoRepresentation
     */
    'shipmentId': string;
    /**
     * 
     * @type {ExpeditionPhotoTransition}
     * @memberof DeliveryPhotoRepresentation
     */
    'taken': ExpeditionPhotoTransition;
}
        /**
 * 
 * @export
 * @interface DeliverySignatureRepresentation
 */
export interface DeliverySignatureRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DeliverySignatureRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySignatureRepresentation
     */
    'shipmentId': string;
    /**
     * 
     * @type {ExpeditionPhotoTransition}
     * @memberof DeliverySignatureRepresentation
     */
    'taken': ExpeditionPhotoTransition;
}
        /**
 * 
 * @export
 * @interface DiscountGroup
 */
export interface DiscountGroup {
    /**
     * 
     * @type {string}
     * @memberof DiscountGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountGroup
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountGroup
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface DiscountGroupDiscountRule
 */
export interface DiscountGroupDiscountRule extends DiscountRule {
    /**
     * 
     * @type {DiscountRuleDiscountGroup}
     * @memberof DiscountGroupDiscountRule
     */
    'discountGroup': DiscountRuleDiscountGroup;
}


        /**
 * 
 * @export
 * @interface DiscountRule
 */
export interface DiscountRule {
    /**
     * 
     * @type {DiscountRuleType}
     * @memberof DiscountRule
     */
    'discountRuleType': DiscountRuleType;
    /**
     * 
     * @type {string}
     * @memberof DiscountRule
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRule
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRule
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRule
     */
    'effectiveDate': string;
    /**
     * 
     * @type {number}
     * @memberof DiscountRule
     */
    'discount': number;
    /**
     * 
     * @type {string}
     * @memberof DiscountRule
     */
    'note'?: string | null;
}


        /**
 * 
 * @export
 * @interface DiscountRuleCatalogItem
 */
export interface DiscountRuleCatalogItem {
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleCatalogItem
     */
    'id': string;
    /**
     * 
     * @type {CatalogItemType}
     * @memberof DiscountRuleCatalogItem
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleCatalogItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleCatalogItem
     */
    'title': string;
}


        /**
 * 
 * @export
 * @interface DiscountRuleCommand
 */
export interface DiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DiscountRuleCommand
     */
    'discount': number;
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleCommand
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleCommand
     */
    'note'?: string | null;
}
        /**
 * 
 * @export
 * @interface DiscountRuleDiscountGroup
 */
export interface DiscountRuleDiscountGroup {
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleDiscountGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleDiscountGroup
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRuleDiscountGroup
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface DiscountRulePriceList
 */
export interface DiscountRulePriceList {
    /**
     * 
     * @type {string}
     * @memberof DiscountRulePriceList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRulePriceList
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountRulePriceList
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountRulePriceList
     */
    'isStandard': boolean;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const DiscountRuleType = {
    CatalogItem: 'catalogItem',
    PriceList: 'priceList',
    DiscountGroup: 'discountGroup',
    PriceListAndCatalogItem: 'priceListAndCatalogItem',
    PriceListAndDiscountGroup: 'priceListAndDiscountGroup'
} as const;

export type DiscountRuleType = typeof DiscountRuleType[keyof typeof DiscountRuleType];


        /**
 * 
 * @export
 * @interface DrillHole
 */
export interface DrillHole {
    /**
     * 
     * @type {Array<DrillHoleStep>}
     * @memberof DrillHole
     */
    'steps': Array<DrillHoleStep>;
}
        /**
 * 
 * @export
 * @interface DrillHoleIdReferenceWithIdCodeAndName
 */
export interface DrillHoleIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof DrillHoleIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DrillHoleIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DrillHoleIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface DrillHoleReference
 */
export interface DrillHoleReference {
    /**
     * 
     * @type {string}
     * @memberof DrillHoleReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DrillHoleReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DrillHoleReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface DrillHoleStep
 */
export interface DrillHoleStep {
    /**
     * 
     * @type {number}
     * @memberof DrillHoleStep
     */
    'diameter': number;
    /**
     * 
     * @type {number}
     * @memberof DrillHoleStep
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @interface EnqueueNeutDeduplicationCommand
 */
export interface EnqueueNeutDeduplicationCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof EnqueueNeutDeduplicationCommand
     */
    'ids'?: Array<string> | null;
}
        /**
 * 
 * @export
 * @interface EnqueueSillDeduplicationCommand
 */
export interface EnqueueSillDeduplicationCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof EnqueueSillDeduplicationCommand
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EnqueueSillDeduplicationCommand
     */
    'since'?: string | null;
}
        /**
 * 
 * @export
 * @interface ExpeditionForProductionItemRepresentation
 */
export interface ExpeditionForProductionItemRepresentation {
    /**
     * 
     * @type {ExpeditionRepresentation}
     * @memberof ExpeditionForProductionItemRepresentation
     */
    'expedition': ExpeditionRepresentation;
    /**
     * 
     * @type {{ [key: string]: ExpeditionsForPlannedDay; }}
     * @memberof ExpeditionForProductionItemRepresentation
     */
    'relatedExpeditions': { [key: string]: ExpeditionsForPlannedDay; };
}
        /**
 * 
 * @export
 * @interface ExpeditionItemsRepresentation
 */
export interface ExpeditionItemsRepresentation {
    /**
     * 
     * @type {OrderReference}
     * @memberof ExpeditionItemsRepresentation
     */
    'order': OrderReference;
    /**
     * 
     * @type {Array<ExpeditionOrderLineRepresentation>}
     * @memberof ExpeditionItemsRepresentation
     */
    'lines': Array<ExpeditionOrderLineRepresentation>;
    /**
     * 
     * @type {Array<ExpeditionMountingRepresentation>}
     * @memberof ExpeditionItemsRepresentation
     */
    'mountings': Array<ExpeditionMountingRepresentation>;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionItemsRepresentation
     */
    'totalNumberOfProducts': number;
}
        /**
 * 
 * @export
 * @interface ExpeditionListView
 */
export interface ExpeditionListView {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'id': string;
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof ExpeditionListView
     */
    'status': ExpeditionStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'preferredExpeditionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'deliveredDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'orderCode': string;
    /**
     * 
     * @type {OrderStatusCodes}
     * @memberof ExpeditionListView
     */
    'orderStatus': OrderStatusCodes;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionListView
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionListView
     */
    'totalProducts': number;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionListView
     */
    'orderArchived': boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'customerCode': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListView
     */
    'customerName': string;
}


        /**
 * 
 * @export
 * @interface ExpeditionListViewArrayODataValue
 */
export interface ExpeditionListViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionListViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionListViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<ExpeditionListView>}
     * @memberof ExpeditionListViewArrayODataValue
     */
    'value': Array<ExpeditionListView>;
}
        /**
 * 
 * @export
 * @interface ExpeditionMountingRepresentation
 */
export interface ExpeditionMountingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionMountingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionMountingRepresentation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionMountingRepresentation
     */
    'quantity': number;
}
        /**
 * 
 * @export
 * @interface ExpeditionOrderLineRepresentation
 */
export interface ExpeditionOrderLineRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'catalogItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'merk'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'quantity': number;
    /**
     * 
     * @type {OrderLineType}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'type': OrderLineType;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionOrderLineRepresentation
     */
    'title': string;
}


        /**
 * 
 * @export
 * @interface ExpeditionPhotoRepresentation
 */
export interface ExpeditionPhotoRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPhotoRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPhotoRepresentation
     */
    'shipmentId': string;
    /**
     * 
     * @type {ExpeditionPhotoTransition}
     * @memberof ExpeditionPhotoRepresentation
     */
    'taken': ExpeditionPhotoTransition;
}
        /**
 * 
 * @export
 * @interface ExpeditionPhotoTransition
 */
export interface ExpeditionPhotoTransition {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPhotoTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPhotoTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPhotoTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ExpeditionPhotoType = {
    DeliveryPhoto: 'deliveryPhoto',
    DeliverySignature: 'deliverySignature'
} as const;

export type ExpeditionPhotoType = typeof ExpeditionPhotoType[keyof typeof ExpeditionPhotoType];


        /**
 * 
 * @export
 * @interface ExpeditionPlanningByOrderView
 */
export interface ExpeditionPlanningByOrderView {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByOrderView
     */
    'plannedExpeditionWeek'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByOrderView
     */
    'plannedExpeditionYear'?: number | null;
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof ExpeditionPlanningByOrderView
     */
    'expeditionStatus': ExpeditionStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'orderCode': string;
    /**
     * 
     * @type {OrderStatusCodes}
     * @memberof ExpeditionPlanningByOrderView
     */
    'orderStatus': OrderStatusCodes;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'delivery': boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'customerCode': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'customerCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'orderDeliveryCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'productionItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'plannedProductionDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByOrderView
     */
    'plannedProductionWeek'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByOrderView
     */
    'plannedProductionYear'?: number | null;
    /**
     * 
     * @type {ProductionItemStatusCodes}
     * @memberof ExpeditionPlanningByOrderView
     */
    'productionItemStatus'?: ProductionItemStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'productionItemCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'productionItemSequence'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'productionItemMerk'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'catalogItemCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'catalogItemTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByOrderView
     */
    'sillLength'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'neutenGemaakt'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'lattenGemaakt'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'dorpelProfielGezaagd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'dorpelBewerkt'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'neutenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'sluitpottenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'lattenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'kopisolatorenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'manchettesGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'hefschuifVouwwand'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'ingepakt'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'custom'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionPlanningByOrderView
     */
    'onPallet'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderView
     */
    'catalogItemId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByOrderView
     */
    'maxSillLengthInOrder'?: number | null;
}


        /**
 * 
 * @export
 * @interface ExpeditionPlanningByOrderViewArrayODataValue
 */
export interface ExpeditionPlanningByOrderViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByOrderViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByOrderViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<ExpeditionPlanningByOrderView>}
     * @memberof ExpeditionPlanningByOrderViewArrayODataValue
     */
    'value': Array<ExpeditionPlanningByOrderView>;
}
        /**
 * 
 * @export
 * @interface ExpeditionPlanningByWeekView
 */
export interface ExpeditionPlanningByWeekView {
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'plannedExpeditionWeek': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'plannedExpeditionYear': number;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByWeekView
     */
    'weekStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByWeekView
     */
    'weekEndDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'totalSills': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'orderCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'sillLength': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'neutCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'lattenCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'compartmentCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekView
     */
    'salesTotal': number;
}
        /**
 * 
 * @export
 * @interface ExpeditionPlanningByWeekViewArrayODataValue
 */
export interface ExpeditionPlanningByWeekViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByWeekViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionPlanningByWeekViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionPlanningByWeekViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<ExpeditionPlanningByWeekView>}
     * @memberof ExpeditionPlanningByWeekViewArrayODataValue
     */
    'value': Array<ExpeditionPlanningByWeekView>;
}
        /**
 * 
 * @export
 * @interface ExpeditionRepresentation
 */
export interface ExpeditionRepresentation {
    /**
     * 
     * @type {OrderReference}
     * @memberof ExpeditionRepresentation
     */
    'order': OrderReference;
    /**
     * 
     * @type {CustomerReference}
     * @memberof ExpeditionRepresentation
     */
    'customer': CustomerReference;
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof ExpeditionRepresentation
     */
    'status': ExpeditionStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRepresentation
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {ExpeditionStatusTransition}
     * @memberof ExpeditionRepresentation
     */
    'notPlanned': ExpeditionStatusTransition;
    /**
     * 
     * @type {ExpeditionStatusTransition}
     * @memberof ExpeditionRepresentation
     */
    'planned'?: ExpeditionStatusTransition;
    /**
     * 
     * @type {ExpeditionStatusTransition}
     * @memberof ExpeditionRepresentation
     */
    'readyForShipment'?: ExpeditionStatusTransition;
    /**
     * 
     * @type {ExpeditionStatusTransition}
     * @memberof ExpeditionRepresentation
     */
    'inTransit'?: ExpeditionStatusTransition;
    /**
     * 
     * @type {ExpeditionStatusTransition}
     * @memberof ExpeditionRepresentation
     */
    'delivered'?: ExpeditionStatusTransition;
    /**
     * 
     * @type {ExpeditionStatusTransition}
     * @memberof ExpeditionRepresentation
     */
    'cancelled'?: ExpeditionStatusTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionRepresentation
     */
    'delivery'?: boolean | null;
    /**
     * 
     * @type {Array<ShipmentRepresentation>}
     * @memberof ExpeditionRepresentation
     */
    'shipments': Array<ShipmentRepresentation>;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ExpeditionStatusCodes = {
    NotPlanned: 'notPlanned',
    Planned: 'planned',
    ReadyForShipment: 'readyForShipment',
    InTransit: 'inTransit',
    Delivered: 'delivered',
    Cancelled: 'cancelled'
} as const;

export type ExpeditionStatusCodes = typeof ExpeditionStatusCodes[keyof typeof ExpeditionStatusCodes];


        /**
 * 
 * @export
 * @interface ExpeditionStatusTransition
 */
export interface ExpeditionStatusTransition {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionStatusTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionStatusTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionStatusTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @interface ExpeditionsForPlannedDay
 */
export interface ExpeditionsForPlannedDay {
    /**
     * 
     * @type {Array<ExpeditionsForProductionItemRow>}
     * @memberof ExpeditionsForPlannedDay
     */
    'expeditions': Array<ExpeditionsForProductionItemRow>;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionsForPlannedDay
     */
    'plannedExpeditionDate': string;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionsForPlannedDay
     */
    'maxSillLength': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionsForPlannedDay
     */
    'itemCount': number;
}
        /**
 * 
 * @export
 * @interface ExpeditionsForProductionItemRow
 */
export interface ExpeditionsForProductionItemRow {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionsForProductionItemRow
     */
    'plannedExpeditionDate': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionsForProductionItemRow
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionsForProductionItemRow
     */
    'companyName': string;
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof ExpeditionsForProductionItemRow
     */
    'expeditionStatus': ExpeditionStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionsForProductionItemRow
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionsForProductionItemRow
     */
    'orderCode': string;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionsForProductionItemRow
     */
    'itemCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionsForProductionItemRow
     */
    'maxSillLengthOrder': number;
}


        /**
 * 
 * @export
 * @interface FluentValidationProblemDetails
 */
export interface FluentValidationProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FluentValidationProblemDetails
     */
    'errors': { [key: string]: Array<string>; };
    /**
     * 
     * @type {string}
     * @memberof FluentValidationProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FluentValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FluentValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FluentValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FluentValidationProblemDetails
     */
    'instance'?: string | null;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const FrameType = {
    Undefined: 'undefined',
    Frame: 'frame',
    Window: 'window',
    Door: 'door',
    CavityFrame: 'cavityFrame',
    GlassBeading: 'glassBeading'
} as const;

export type FrameType = typeof FrameType[keyof typeof FrameType];


        /**
 * 
 * @export
 * @interface GetConfiguratorResponse
 */
export interface GetConfiguratorResponse {
    /**
     * 
     * @type {ConfiguratorData}
     * @memberof GetConfiguratorResponse
     */
    'configuratorData': ConfiguratorData;
    /**
     * 
     * @type {ValidateConfigurationRequest}
     * @memberof GetConfiguratorResponse
     */
    'configuration': ValidateConfigurationRequest;
}
        /**
 * @type GetProfile200Response
 * @export
 */
export type GetProfile200Response = CompartmentProfileInfo | ContraProfileInfo | WallProfileInfo;

        /**
 * @type GetSillProfiles200Response
 * @export
 */
export type GetSillProfiles200Response = SillProfiles | SillProfilesHefSchuif;

        /**
 * 
 * @export
 * @interface GlazingBar
 */
export interface GlazingBar {
    /**
     * 
     * @type {string}
     * @memberof GlazingBar
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBar
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBar
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GlazingBar
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof GlazingBar
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingBar
     */
    'height': number;
}
        /**
 * 
 * @export
 * @interface GlazingBarIdReferenceWithIdCodeAndName
 */
export interface GlazingBarIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof GlazingBarIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBarIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBarIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface GlazingBarProduct
 */
export interface GlazingBarProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof GlazingBarProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {GlazingBarProductGlazingBar}
     * @memberof GlazingBarProduct
     */
    'glazingBar': GlazingBarProductGlazingBar;
    /**
     * 
     * @type {CatalogItemColor}
     * @memberof GlazingBarProduct
     */
    'color': CatalogItemColor;
}


        /**
 * 
 * @export
 * @interface GlazingBarProductGlazingBar
 */
export interface GlazingBarProductGlazingBar {
    /**
     * 
     * @type {string}
     * @memberof GlazingBarProductGlazingBar
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBarProductGlazingBar
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBarProductGlazingBar
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GlazingBarProductGlazingBar
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingBarProductGlazingBar
     */
    'height': number;
}
        /**
 * 
 * @export
 * @interface GlazingBarReference
 */
export interface GlazingBarReference {
    /**
     * 
     * @type {number}
     * @memberof GlazingBarReference
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingBarReference
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof GlazingBarReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBarReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingBarReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface GlazingProfile
 */
export interface GlazingProfile {
    /**
     * 
     * @type {string}
     * @memberof GlazingProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfile
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GlazingProfile
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof GlazingProfile
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingProfile
     */
    'defaultWidth': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingProfile
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingProfile
     */
    'mountingSlotPosition': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingProfile
     */
    'stopPosition': number;
    /**
     * 
     * @type {number}
     * @memberof GlazingProfile
     */
    'rabbetWidth': number;
}
        /**
 * 
 * @export
 * @interface GlazingProfileIdReferenceWithIdCodeAndName
 */
export interface GlazingProfileIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface GlazingProfileProduct
 */
export interface GlazingProfileProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof GlazingProfileProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {GlazingProfileProductGlazingProfile}
     * @memberof GlazingProfileProduct
     */
    'glazingProfile': GlazingProfileProductGlazingProfile;
    /**
     * 
     * @type {CatalogItemColor}
     * @memberof GlazingProfileProduct
     */
    'color': CatalogItemColor;
}


        /**
 * 
 * @export
 * @interface GlazingProfileProductGlazingProfile
 */
export interface GlazingProfileProductGlazingProfile {
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileProductGlazingProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileProductGlazingProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileProductGlazingProfile
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface GlazingProfileReference
 */
export interface GlazingProfileReference {
    /**
     * 
     * @type {number}
     * @memberof GlazingProfileReference
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GlazingProfileReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface GroeneveldArticleRepresentation
 */
export interface GroeneveldArticleRepresentation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldArticleRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldArticleRepresentation
     */
    'articleCode': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldArticleRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldArticleRepresentation
     */
    'soort'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroeneveldArticleRepresentation
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroeneveldArticleRepresentation
     */
    'isSill': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroeneveldArticleRepresentation
     */
    'isGlazingBar': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroeneveldArticleRepresentation
     */
    'isGlazingProfile': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroeneveldArticleRepresentation
     */
    'isCornerPiece': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroeneveldArticleRepresentation
     */
    'isNeut': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroeneveldArticleRepresentation
     */
    'isStopProfile': boolean;
}
        /**
 * 
 * @export
 * @interface GroeneveldConfiguredSillCompartmentArticleRepresentation
 */
export interface GroeneveldConfiguredSillCompartmentArticleRepresentation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentArticleRepresentation
     */
    'articleCode': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentArticleRepresentation
     */
    'description'?: string | null;
}
        /**
 * 
 * @export
 * @interface GroeneveldConfiguredSillCompartmentRepresentation
 */
export interface GroeneveldConfiguredSillCompartmentRepresentation {
    /**
     * 
     * @type {number}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'groenveldConfiguredSillId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'groeneveldLayoutTypeCode': string;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'dagmaat': number;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsLayoutTypeCode'?: CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsGlazingProfileCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsGlazingProfileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsStopProfileCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsStopProfileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsGlazinBarCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsGlazingBarName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsGlazinBarCornerPieceLeftCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'edsGlazinBarCornerPieceRightCode'?: string | null;
    /**
     * 
     * @type {Array<GroeneveldConfiguredSillCompartmentArticleRepresentation>}
     * @memberof GroeneveldConfiguredSillCompartmentRepresentation
     */
    'articles': Array<GroeneveldConfiguredSillCompartmentArticleRepresentation>;
}


        /**
 * 
 * @export
 * @interface GroeneveldConfiguredSillNeutRepresentation
 */
export interface GroeneveldConfiguredSillNeutRepresentation {
    /**
     * 
     * @type {number}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'points': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'profileLeft'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'leftProfileGeometryAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'leftProfileGeometryAsStringSvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'profileRight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'rightProfileGeometryAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'rightProfileGeometryAsStringSvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldConfiguredSillNeutRepresentation
     */
    'drillHoles': string;
}
        /**
 * 
 * @export
 * @interface GroeneveldImportCompartmentLayoutMappingValidation
 */
export interface GroeneveldImportCompartmentLayoutMappingValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentLayoutMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentLayoutMappingValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportCompartmentLayoutMappingValidation
     */
    'compartmentId': number;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportCompartmentProfileMissingValidation
 */
export interface GroeneveldImportCompartmentProfileMissingValidation extends ImportCompartmentProfileMissingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileMissingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileMissingValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportCompartmentProfileMissingValidation
     */
    'compartmentId': number;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileMissingValidation
     */
    'groeneveldConfiguredProfileId': string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof GroeneveldImportCompartmentProfileMissingValidation
     */
    'groeneveldGeometry'?: Array<Point> | null;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportCompartmentProfileSuggestionValidation
 */
export interface GroeneveldImportCompartmentProfileSuggestionValidation extends ImportCompartmentProfileValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'compartmentId': number;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'groeneveldConfiguredProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'suggestedProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'suggestedProfileGeometry'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'edsGeometry'?: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof GroeneveldImportCompartmentProfileSuggestionValidation
     */
    'groeneveldGeometry'?: Array<Point> | null;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportCompartmentProfilesNotEqualValidation
 */
export interface GroeneveldImportCompartmentProfilesNotEqualValidation extends ImportCompartmentProfileValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'compartmentId': number;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'leftGroeneveldConfiguredProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'leftProfileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'leftEdsGeometry'?: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'leftGroeneveldGeometry'?: Array<Point> | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'rightGroeneveldConfiguredProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'rightEdsGeometry'?: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'rightGroeneveldGeometry'?: Array<Point> | null;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCompartmentProfilesNotEqualValidation
     */
    'rightProfileId'?: string | null;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportCornerPieceMappingValidation
 */
export interface GroeneveldImportCornerPieceMappingValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCornerPieceMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportCornerPieceMappingValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportCornerPieceMappingValidation
     */
    'compartmentId': number;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportGlazingBarMappingValidation
 */
export interface GroeneveldImportGlazingBarMappingValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportGlazingBarMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportGlazingBarMappingValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportGlazingBarMappingValidation
     */
    'compartmentId': number;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportGlazingProfileMappingValidation
 */
export interface GroeneveldImportGlazingProfileMappingValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportGlazingProfileMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportGlazingProfileMappingValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportGlazingProfileMappingValidation
     */
    'compartmentId': number;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportSillMappingValidation
 */
export interface GroeneveldImportSillMappingValidation extends ImportSillMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSillMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSillMappingValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportSluitpotBeslagMappingValidation
 */
export interface GroeneveldImportSluitpotBeslagMappingValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotBeslagMappingValidation
     */
    'groeneveldConfiguredSluitpotId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotBeslagMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotBeslagMappingValidation
     */
    'groeneveldConfiguredSillId': string;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportSluitpotCompartmentMappingValidation
 */
export interface GroeneveldImportSluitpotCompartmentMappingValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotCompartmentMappingValidation
     */
    'groeneveldConfiguredSluitpotId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotCompartmentMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotCompartmentMappingValidation
     */
    'groeneveldConfiguredSillId': string;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportSluitpotYPositionValidation
 */
export interface GroeneveldImportSluitpotYPositionValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotYPositionValidation
     */
    'groeneveldConfiguredSluitpotId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotYPositionValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportSluitpotYPositionValidation
     */
    'groeneveldConfiguredSillId': string;
}


        /**
 * 
 * @export
 * @interface GroeneveldImportStopProfileMappingValidation
 */
export interface GroeneveldImportStopProfileMappingValidation extends ImportMappingValidation {
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportStopProfileMappingValidation
     */
    'groeneveldOrderlineId': string;
    /**
     * 
     * @type {string}
     * @memberof GroeneveldImportStopProfileMappingValidation
     */
    'groeneveldConfiguredSillId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroeneveldImportStopProfileMappingValidation
     */
    'compartmentId': number;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const GroeneveldOrderLineType = {
    DorpelLine: 'dorpelLine',
    UnknownLine: 'unknownLine'
} as const;

export type GroeneveldOrderLineType = typeof GroeneveldOrderLineType[keyof typeof GroeneveldOrderLineType];


        /**
 * 
 * @export
 * @enum {string}
 */

export const GroeneveldValidationType = {
    ImportSluitpotBeslagMappingValidation: 'importSluitpotBeslagMappingValidation',
    ImportSluitpotCompartmentMappingValidation: 'importSluitpotCompartmentMappingValidation',
    ImportSillMappingValidation: 'importSillMappingValidation',
    ImportCornerPieceMappingValidation: 'importCornerPieceMappingValidation',
    ImportGlazingBarMappingValidation: 'importGlazingBarMappingValidation',
    ImportStopProfileMappingValidation: 'importStopProfileMappingValidation',
    ImportGlazingProfileMappingValidation: 'importGlazingProfileMappingValidation',
    ImportCompartmentLayoutMappingValidation: 'importCompartmentLayoutMappingValidation',
    ImportCompartmentProfileMissingValidation: 'importCompartmentProfileMissingValidation',
    ImportCompartmentProfilesNotEqualValidation: 'importCompartmentProfilesNotEqualValidation',
    ImportCompartmentProfileSuggestionValidation: 'importCompartmentProfileSuggestionValidation',
    ImportSluitpotYPositionValidation: 'importSluitpotYPositionValidation'
} as const;

export type GroeneveldValidationType = typeof GroeneveldValidationType[keyof typeof GroeneveldValidationType];


        /**
 * 
 * @export
 * @enum {string}
 */

export const HefSchuifBarAlignment = {
    Left: 'left',
    Right: 'right',
    Fill: 'fill'
} as const;

export type HefSchuifBarAlignment = typeof HefSchuifBarAlignment[keyof typeof HefSchuifBarAlignment];


        /**
 * 
 * @export
 * @interface HefSchuifGroove
 */
export interface HefSchuifGroove {
    /**
     * 
     * @type {number}
     * @memberof HefSchuifGroove
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof HefSchuifGroove
     */
    'depth': number;
    /**
     * 
     * @type {number}
     * @memberof HefSchuifGroove
     */
    'center': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const HefSchuifInnerDoorPosition = {
    Left: 'left',
    Right: 'right'
} as const;

export type HefSchuifInnerDoorPosition = typeof HefSchuifInnerDoorPosition[keyof typeof HefSchuifInnerDoorPosition];


        /**
 * 
 * @export
 * @interface HefSchuifProfiles
 */
export interface HefSchuifProfiles {
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof HefSchuifProfiles
     */
    'schemaCode': HefSchuifSchemaCode;
}


        /**
 * 
 * @export
 * @interface HefSchuifProfilesSchemaA
 */
export interface HefSchuifProfilesSchemaA extends HefSchuifProfiles {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof HefSchuifProfilesSchemaA
     */
    'slidingDirection': HefSchuifSlidingDirection;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaA
     */
    'inside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaA
     */
    'outside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaA
     */
    'left': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaA
     */
    'right': CompartmentProfileValue;
    /**
     * 
     * @type {boolean}
     * @memberof HefSchuifProfilesSchemaA
     */
    'isRtl': boolean;
}


        /**
 * 
 * @export
 * @interface HefSchuifProfilesSchemaC
 */
export interface HefSchuifProfilesSchemaC extends HefSchuifProfiles {
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaC
     */
    'outside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaC
     */
    'left': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaC
     */
    'right': CompartmentProfileValue;
}


        /**
 * 
 * @export
 * @interface HefSchuifProfilesSchemaD
 */
export interface HefSchuifProfilesSchemaD extends HefSchuifProfiles {
    /**
     * 
     * @type {HefSchuifInnerDoorPosition}
     * @memberof HefSchuifProfilesSchemaD
     */
    'innerDoorPosition': HefSchuifInnerDoorPosition;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaD
     */
    'inside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaD
     */
    'outside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaD
     */
    'left': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaD
     */
    'right': CompartmentProfileValue;
    /**
     * 
     * @type {boolean}
     * @memberof HefSchuifProfilesSchemaD
     */
    'isRtl': boolean;
}


        /**
 * 
 * @export
 * @interface HefSchuifProfilesSchemaE
 */
export interface HefSchuifProfilesSchemaE extends HefSchuifProfiles {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof HefSchuifProfilesSchemaE
     */
    'slidingDirection': HefSchuifSlidingDirection;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaE
     */
    'inside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaE
     */
    'outside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaE
     */
    'left': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaE
     */
    'right': CompartmentProfileValue;
    /**
     * 
     * @type {boolean}
     * @memberof HefSchuifProfilesSchemaE
     */
    'isRtl': boolean;
}


        /**
 * 
 * @export
 * @interface HefSchuifProfilesSchemaK
 */
export interface HefSchuifProfilesSchemaK extends HefSchuifProfiles {
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaK
     */
    'inside': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaK
     */
    'left': CompartmentProfileValue;
    /**
     * 
     * @type {CompartmentProfileValue}
     * @memberof HefSchuifProfilesSchemaK
     */
    'right': CompartmentProfileValue;
}


        /**
 * 
 * @export
 * @interface HefSchuifRabbetInfo
 */
export interface HefSchuifRabbetInfo {
    /**
     * 
     * @type {string}
     * @memberof HefSchuifRabbetInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HefSchuifRabbetInfo
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof HefSchuifRabbetInfo
     */
    'name': string;
    /**
     * 
     * @type {RabbetType}
     * @memberof HefSchuifRabbetInfo
     */
    'rabbetType': RabbetType;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof HefSchuifRabbetInfo
     */
    'rabbetPosition': RabbetPosition;
    /**
     * 
     * @type {number}
     * @memberof HefSchuifRabbetInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof HefSchuifRabbetInfo
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof HefSchuifRabbetInfo
     */
    'rabbetSize': string;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const HefSchuifSchemaCode = {
    A: 'a',
    C: 'c',
    D: 'd',
    E: 'e'
} as const;

export type HefSchuifSchemaCode = typeof HefSchuifSchemaCode[keyof typeof HefSchuifSchemaCode];


        /**
 * 
 * @export
 * @enum {string}
 */

export const HefSchuifSlidingDirection = {
    LeftSliding: 'leftSliding',
    RightSliding: 'rightSliding'
} as const;

export type HefSchuifSlidingDirection = typeof HefSchuifSlidingDirection[keyof typeof HefSchuifSlidingDirection];


        /**
 * 
 * @export
 * @enum {string}
 */

export const HefSchuifSlidingRailType = {
    Laag: 'laag',
    Hoog: 'hoog'
} as const;

export type HefSchuifSlidingRailType = typeof HefSchuifSlidingRailType[keyof typeof HefSchuifSlidingRailType];


        /**
 * 
 * @export
 * @interface IFacetResult
 */
export interface IFacetResult {
    /**
     * 
     * @type {string}
     * @memberof IFacetResult
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IFacetResult
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof IFacetResult
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof IFacetResult
     */
    'sortOrder': number;
    /**
     * 
     * @type {Array<IFacetResultLabel>}
     * @memberof IFacetResult
     */
    'labels': Array<IFacetResultLabel>;
}
        /**
 * 
 * @export
 * @interface IFacetResultLabel
 */
export interface IFacetResultLabel {
    /**
     * 
     * @type {string}
     * @memberof IFacetResultLabel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IFacetResultLabel
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof IFacetResultLabel
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof IFacetResultLabel
     */
    'sortOrder': number;
}
        /**
 * 
 * @export
 * @interface IProfileInfo
 */
export interface IProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof IProfileInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IProfileInfo
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof IProfileInfo
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof IProfileInfo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof IProfileInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof IProfileInfo
     */
    'sillWidth': number;
    /**
     * 
     * @type {string}
     * @memberof IProfileInfo
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof IProfileInfo
     */
    'svgPreview'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProfileInfo
     */
    'hasProgram': boolean;
}
        /**
 * 
 * @export
 * @interface IProfileInfoProfileSearchResult
 */
export interface IProfileInfoProfileSearchResult {
    /**
     * 
     * @type {number}
     * @memberof IProfileInfoProfileSearchResult
     */
    'totalHits': number;
    /**
     * 
     * @type {{ [key: string]: IFacetResult; }}
     * @memberof IProfileInfoProfileSearchResult
     */
    'facets': { [key: string]: IFacetResult; };
    /**
     * 
     * @type {Array<IProfileInfo>}
     * @memberof IProfileInfoProfileSearchResult
     */
    'profiles': Array<IProfileInfo>;
}
        /**
 * 
 * @export
 * @interface IgnoreImportValidationCommand
 */
export interface IgnoreImportValidationCommand extends ResolveImportValidationCommand {
}


        /**
 * 
 * @export
 * @interface ImportCompartmentLayoutMappingRepresentation
 */
export interface ImportCompartmentLayoutMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentLayoutMappingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentLayoutMappingRepresentation
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentLayoutMappingRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {CompartmentLayoutTypeReference}
     * @memberof ImportCompartmentLayoutMappingRepresentation
     */
    'compartmentLayoutTypeReference': CompartmentLayoutTypeReference;
}
        /**
 * 
 * @export
 * @interface ImportCompartmentProfileMissingValidation
 */
export interface ImportCompartmentProfileMissingValidation extends ImportCompartmentProfileValidation {
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentProfileMissingValidation
     */
    'edsGeometry'?: string;
}


        /**
 * 
 * @export
 * @interface ImportCompartmentProfileValidation
 */
export interface ImportCompartmentProfileValidation extends ImportValidation {
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentProfileValidation
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentProfileValidation
     */
    'sillCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentProfileValidation
     */
    'sillTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentProfileValidation
     */
    'neutPositionId'?: string | null;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ImportCompartmentProfileValidation
     */
    'compartmentLayoutTypeCode'?: CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof ImportCompartmentProfileValidation
     */
    'compartmentLayoutTypeId'?: string | null;
}


        /**
 * 
 * @export
 * @interface ImportCornerPieceMappingRepresentation
 */
export interface ImportCornerPieceMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportCornerPieceMappingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportCornerPieceMappingRepresentation
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportCornerPieceMappingRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {CornerPieceReference}
     * @memberof ImportCornerPieceMappingRepresentation
     */
    'cornerPiece': CornerPieceReference;
}
        /**
 * 
 * @export
 * @interface ImportGlazingBarMappingRepresentation
 */
export interface ImportGlazingBarMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportGlazingBarMappingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportGlazingBarMappingRepresentation
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportGlazingBarMappingRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {GlazingBarReference}
     * @memberof ImportGlazingBarMappingRepresentation
     */
    'glazingBar': GlazingBarReference;
}
        /**
 * 
 * @export
 * @interface ImportGlazingProfileMappingRepresentation
 */
export interface ImportGlazingProfileMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportGlazingProfileMappingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportGlazingProfileMappingRepresentation
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportGlazingProfileMappingRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {GlazingProfileReference}
     * @memberof ImportGlazingProfileMappingRepresentation
     */
    'glazingProfile': GlazingProfileReference;
}
        /**
 * 
 * @export
 * @interface ImportListView
 */
export interface ImportListView {
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof ImportListView
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'importDate': string;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'importUserId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'importUser': string;
    /**
     * 
     * @type {ImportType}
     * @memberof ImportListView
     */
    'importType': ImportType;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'customerCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportListView
     */
    'customerName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportListView
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportListView
     */
    'status': ImportStatus;
}


        /**
 * 
 * @export
 * @interface ImportListViewArrayODataValue
 */
export interface ImportListViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof ImportListViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImportListViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImportListViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<ImportListView>}
     * @memberof ImportListViewArrayODataValue
     */
    'value': Array<ImportListView>;
}
        /**
 * 
 * @export
 * @interface ImportMappingValidation
 */
export interface ImportMappingValidation extends ImportValidation {
    /**
     * 
     * @type {string}
     * @memberof ImportMappingValidation
     */
    'externalCode': string;
}


        /**
 * 
 * @export
 * @interface ImportOrderLineRepresentation
 */
export interface ImportOrderLineRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportOrderLineRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportOrderLineRepresentation
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof ImportOrderLineRepresentation
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof ImportOrderLineRepresentation
     */
    'merk'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportOrderLineRepresentation
     */
    'catalogItemId'?: string | null;
}
        /**
 * 
 * @export
 * @interface ImportOrderRepresentation
 */
export interface ImportOrderRepresentation {
    /**
     * 
     * @type {Array<ImportOrderLineRepresentation>}
     * @memberof ImportOrderRepresentation
     */
    'lines': Array<ImportOrderLineRepresentation>;
    /**
     * 
     * @type {string}
     * @memberof ImportOrderRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportOrderRepresentation
     */
    'importId': string;
    /**
     * 
     * @type {ImportOrderStatus}
     * @memberof ImportOrderRepresentation
     */
    'status': ImportOrderStatus;
    /**
     * 
     * @type {string}
     * @memberof ImportOrderRepresentation
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportOrderRepresentation
     */
    'preferredExpeditionDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImportOrderRepresentation
     */
    'urgent': boolean;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ImportOrderStatus = {
    NotStarted: 'notStarted',
    Started: 'started',
    Completed: 'completed',
    Failed: 'failed',
    TimeOutExpired: 'timeOutExpired'
} as const;

export type ImportOrderStatus = typeof ImportOrderStatus[keyof typeof ImportOrderStatus];


        /**
 * 
 * @export
 * @interface ImportRepresentation
 */
export interface ImportRepresentation {
    /**
     * 
     * @type {Array<OrderReference>}
     * @memberof ImportRepresentation
     */
    'edsOrders': Array<OrderReference>;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'id': string;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportRepresentation
     */
    'status': ImportStatus;
    /**
     * 
     * @type {ImportOrderStatus}
     * @memberof ImportRepresentation
     */
    'orderStatus': ImportOrderStatus;
    /**
     * 
     * @type {CustomerReference}
     * @memberof ImportRepresentation
     */
    'customer': CustomerReference;
    /**
     * 
     * @type {ImportType}
     * @memberof ImportRepresentation
     */
    'type': ImportType;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportRepresentation
     */
    'blobUri'?: string | null;
    /**
     * 
     * @type {ImportTransition}
     * @memberof ImportRepresentation
     */
    'created': ImportTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ImportRepresentation
     */
    'detectWallConnections': boolean;
    /**
     * 
     * @type {ImportOrderRepresentation}
     * @memberof ImportRepresentation
     */
    'importOrder'?: ImportOrderRepresentation;
}


        /**
 * 
 * @export
 * @interface ImportSillMappingRepresentation
 */
export interface ImportSillMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportSillMappingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportSillMappingRepresentation
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportSillMappingRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {SillReference}
     * @memberof ImportSillMappingRepresentation
     */
    'sill': SillReference;
}
        /**
 * 
 * @export
 * @interface ImportSillMappingValidation
 */
export interface ImportSillMappingValidation extends ImportMappingValidation {
}


        /**
 * 
 * @export
 * @interface ImportSluitpotMappingRepresentation
 */
export interface ImportSluitpotMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportSluitpotMappingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {CustomerReference}
     * @memberof ImportSluitpotMappingRepresentation
     */
    'customer': CustomerReference;
    /**
     * 
     * @type {string}
     * @memberof ImportSluitpotMappingRepresentation
     */
    'externalCode': string;
    /**
     * 
     * @type {SluitpotBeslagReference}
     * @memberof ImportSluitpotMappingRepresentation
     */
    'sluitpotBeslag': SluitpotBeslagReference;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ImportStatus = {
    Unknown: 'unknown',
    ValidatingFile: 'validatingFile',
    ValidatingEdsMapping: 'validatingEDSMapping',
    EdsMappingValidated: 'edsMappingValidated',
    ConfiguringSills: 'configuringSills',
    SillsConfigured: 'sillsConfigured',
    Completed: 'completed',
    InvalidFile: 'invalidFile',
    EdsMappingFailed: 'edsMappingFailed',
    SillConfigurationFailed: 'sillConfigurationFailed',
    Cancelled: 'cancelled',
    TimeoutExpired: 'timeoutExpired'
} as const;

export type ImportStatus = typeof ImportStatus[keyof typeof ImportStatus];


        /**
 * 
 * @export
 * @interface ImportStopProfileMappingRepresentation
 */
export interface ImportStopProfileMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportStopProfileMappingRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportStopProfileMappingRepresentation
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportStopProfileMappingRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {StopProfileReference}
     * @memberof ImportStopProfileMappingRepresentation
     */
    'stopProfile': StopProfileReference;
}
        /**
 * 
 * @export
 * @interface ImportTransition
 */
export interface ImportTransition {
    /**
     * 
     * @type {string}
     * @memberof ImportTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ImportTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ImportType = {
    Unknown: 'unknown',
    Groeneveld: 'groeneveld',
    Matrix: 'matrix'
} as const;

export type ImportType = typeof ImportType[keyof typeof ImportType];


        /**
 * 
 * @export
 * @interface ImportValidation
 */
export interface ImportValidation {
    /**
     * 
     * @type {ImportValidationType}
     * @memberof ImportValidation
     */
    'validationType': ImportValidationType;
    /**
     * 
     * @type {string}
     * @memberof ImportValidation
     */
    'merk'?: string | null;
    /**
     * 
     * @type {ImportValidationStatus}
     * @memberof ImportValidation
     */
    'status': ImportValidationStatus;
    /**
     * 
     * @type {string}
     * @memberof ImportValidation
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ImportValidation
     */
    'importId': string;
    /**
     * 
     * @type {ImportType}
     * @memberof ImportValidation
     */
    'type': ImportType;
    /**
     * 
     * @type {string}
     * @memberof ImportValidation
     */
    'id': string;
}


        /**
 * 
 * @export
 * @interface ImportValidationIdAggregateRoot
 */
export interface ImportValidationIdAggregateRoot {
    /**
     * 
     * @type {string}
     * @memberof ImportValidationIdAggregateRoot
     */
    'id': string;
}
        /**
 * 
 * @export
 * @interface ImportValidationResult
 */
export interface ImportValidationResult {
    /**
     * 
     * @type {string}
     * @memberof ImportValidationResult
     */
    'importId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportValidationResult
     */
    'customerId': string;
    /**
     * 
     * @type {Array<AclImportValidationImportValidationIdGet200Response>}
     * @memberof ImportValidationResult
     */
    'importValidations': Array<AclImportValidationImportValidationIdGet200Response>;
    /**
     * 
     * @type {ImportType}
     * @memberof ImportValidationResult
     */
    'type': ImportType;
    /**
     * 
     * @type {boolean}
     * @memberof ImportValidationResult
     */
    'importValidationCompleted': boolean;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ImportValidationStatus = {
    Pending: 'pending',
    Resolved: 'resolved',
    Ignored: 'ignored'
} as const;

export type ImportValidationStatus = typeof ImportValidationStatus[keyof typeof ImportValidationStatus];


        /**
 * 
 * @export
 * @enum {string}
 */

export const ImportValidationType = {
    ImportSillMappingValidation: 'importSillMappingValidation',
    ImportMappingValidation: 'importMappingValidation',
    GroeneveldImportSluitpotBeslagMappingValidation: 'groeneveldImportSluitpotBeslagMappingValidation',
    GroeneveldImportSluitpotCompartmentMappingValidation: 'groeneveldImportSluitpotCompartmentMappingValidation',
    GroeneveldImportSluitpotYPositionValidation: 'groeneveldImportSluitpotYPositionValidation',
    GroeneveldImportSillMappingValidation: 'groeneveldImportSillMappingValidation',
    GroeneveldImportCompartmentLayoutMappingValidation: 'groeneveldImportCompartmentLayoutMappingValidation',
    GroeneveldImportCornerPieceMappingValidation: 'groeneveldImportCornerPieceMappingValidation',
    GroeneveldImportGlazingBarMappingValidation: 'groeneveldImportGlazingBarMappingValidation',
    GroeneveldImportGlazingProfileMappingValidation: 'groeneveldImportGlazingProfileMappingValidation',
    GroeneveldImportStopProfileMappingValidation: 'groeneveldImportStopProfileMappingValidation',
    GroeneveldImportCompartmentProfileMissingValidation: 'groeneveldImportCompartmentProfileMissingValidation',
    GroeneveldImportCompartmentProfilesNotEqualValidation: 'groeneveldImportCompartmentProfilesNotEqualValidation',
    GroeneveldImportCompartmentProfileSuggestionValidation: 'groeneveldImportCompartmentProfileSuggestionValidation',
    ImportCompartmentProfileValidation: 'importCompartmentProfileValidation',
    ImportCompartmentProfileMissingValidation: 'importCompartmentProfileMissingValidation'
} as const;

export type ImportValidationType = typeof ImportValidationType[keyof typeof ImportValidationType];


        /**
 * 
 * @export
 * @interface ImportedProfileMappingRepresentation
 */
export interface ImportedProfileMappingRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'sillCode': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'leftProfileGeometry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'leftProfileGeometrySvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'rightProfileGeometry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'compartmentLayoutTypeId': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof ImportedProfileMappingRepresentation
     */
    'compartmentLayoutCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'compartmentLayoutName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'neutPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'importProfileMappingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'mappedProfileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'mappedProfileCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'mappedProfileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'leftMappedProfileGeometrySvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedProfileMappingRepresentation
     */
    'leftMappedProfileGeometryAsString'?: string | null;
    /**
     * 
     * @type {MappingKind}
     * @memberof ImportedProfileMappingRepresentation
     */
    'kind'?: MappingKind;
}


        /**
 * 
 * @export
 * @interface Int32Stat
 */
export interface Int32Stat {
    /**
     * 
     * @type {number}
     * @memberof Int32Stat
     */
    'sum': number;
    /**
     * 
     * @type {number}
     * @memberof Int32Stat
     */
    'ytd': number;
}
        /**
 * 
 * @export
 * @interface InvoiceListView
 */
export interface InvoiceListView {
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'id': string;
    /**
     * 
     * @type {InvoiceStatusCodes}
     * @memberof InvoiceListView
     */
    'status': InvoiceStatusCodes;
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof InvoiceListView
     */
    'expeditionStatus': ExpeditionStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'orderDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'realisedExpeditionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'orderCode': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'reference'?: string | null;
    /**
     * 
     * @type {OrderStatusCodes}
     * @memberof InvoiceListView
     */
    'orderStatus': OrderStatusCodes;
    /**
     * 
     * @type {number}
     * @memberof InvoiceListView
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceListView
     */
    'totalProducts': number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceListView
     */
    'orderArchived': boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'customerCode': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListView
     */
    'customerName': string;
}


        /**
 * 
 * @export
 * @interface InvoiceListViewArrayODataValue
 */
export interface InvoiceListViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof InvoiceListViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceListViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<InvoiceListView>}
     * @memberof InvoiceListViewArrayODataValue
     */
    'value': Array<InvoiceListView>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceStatusCodes = {
    NotInvoiced: 'notInvoiced',
    Invoiced: 'invoiced',
    Paid: 'paid'
} as const;

export type InvoiceStatusCodes = typeof InvoiceStatusCodes[keyof typeof InvoiceStatusCodes];


        /**
 * 
 * @export
 * @interface InvoiceStatusTransition
 */
export interface InvoiceStatusTransition {
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatusTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatusTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatusTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @interface ItemPrice
 */
export interface ItemPrice {
    /**
     * 
     * @type {PriceListReference}
     * @memberof ItemPrice
     */
    'priceList': PriceListReference;
    /**
     * 
     * @type {CatalogItemReference}
     * @memberof ItemPrice
     */
    'catalogItem': CatalogItemReference;
    /**
     * 
     * @type {string}
     * @memberof ItemPrice
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ItemPrice
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'price'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ItemPrice
     */
    'isCurrent': boolean;
}
        /**
 * 
 * @export
 * @interface KopIsolatorSetProduct
 */
export interface KopIsolatorSetProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof KopIsolatorSetProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {CatalogItemSillModel}
     * @memberof KopIsolatorSetProduct
     */
    'sill': CatalogItemSillModel;
}


        /**
 * 
 * @export
 * @interface LProfile
 */
export interface LProfile {
    /**
     * 
     * @type {string}
     * @memberof LProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LProfile
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof LProfile
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof LProfile
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof LProfile
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface LProfileProduct
 */
export interface LProfileProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof LProfileProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {LProfileProductLProfile}
     * @memberof LProfileProduct
     */
    'lProfile': LProfileProductLProfile;
}


        /**
 * 
 * @export
 * @interface LProfileProductLProfile
 */
export interface LProfileProductLProfile {
    /**
     * 
     * @type {string}
     * @memberof LProfileProductLProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LProfileProductLProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LProfileProductLProfile
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface LineSegment
 */
export interface LineSegment {
    /**
     * 
     * @type {number}
     * @memberof LineSegment
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof LineSegment
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof LineSegment
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof LineSegment
     */
    'minY': number;
    /**
     * 
     * @type {boolean}
     * @memberof LineSegment
     */
    'isVertical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LineSegment
     */
    'isHorizontal': boolean;
    /**
     * 
     * @type {BoundingBox}
     * @memberof LineSegment
     */
    'boundingBox': BoundingBox;
    /**
     * 
     * @type {Point}
     * @memberof LineSegment
     */
    'startPoint': Point;
    /**
     * 
     * @type {Point}
     * @memberof LineSegment
     */
    'endPoint': Point;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const MailType = {
    Transactional: 'transactional',
    Marketing: 'marketing'
} as const;

export type MailType = typeof MailType[keyof typeof MailType];


        /**
 * 
 * @export
 * @enum {string}
 */

export const MappingKind = {
    Suggestion: 'suggestion',
    Definitive: 'definitive'
} as const;

export type MappingKind = typeof MappingKind[keyof typeof MappingKind];


        /**
 * 
 * @export
 * @enum {string}
 */

export const MessagePriority = {
    Ok: 'ok',
    Message: 'message',
    Warning: 'warning',
    Error: 'error'
} as const;

export type MessagePriority = typeof MessagePriority[keyof typeof MessagePriority];


        /**
 * 
 * @export
 * @interface Metrics
 */
export interface Metrics {
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'planned': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'realised': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'currentQueue': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'dailyQueue': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'backlog': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'efficiency': number;
}
        /**
 * 
 * @export
 * @interface MonthlySalesStatsRow
 */
export interface MonthlySalesStatsRow {
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'jaar': number;
    /**
     * 
     * @type {string}
     * @memberof MonthlySalesStatsRow
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof MonthlySalesStatsRow
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'orders': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'avgOrderWaarde': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet1': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet2': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet3': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet4': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet5': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet6': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet7': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet8': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet9': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet10': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet11': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlySalesStatsRow
     */
    'omzet12': number;
}
        /**
 * 
 * @export
 * @interface MountingModel
 */
export interface MountingModel {
    /**
     * 
     * @type {string}
     * @memberof MountingModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MountingModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MountingModel
     */
    'quantity': number;
}
        /**
 * 
 * @export
 * @interface MountingOption
 */
export interface MountingOption {
    /**
     * 
     * @type {string}
     * @memberof MountingOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MountingOption
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof MountingOption
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof MountingOption
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface NeutDrillHole
 */
export interface NeutDrillHole {
    /**
     * 
     * @type {Point}
     * @memberof NeutDrillHole
     */
    'position': Point;
    /**
     * 
     * @type {number}
     * @memberof NeutDrillHole
     */
    'diameter': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const NeutDrillHolePositioningStrategyName = {
    Standard: 'standard',
    Centered: 'centered',
    Henderson: 'henderson'
} as const;

export type NeutDrillHolePositioningStrategyName = typeof NeutDrillHolePositioningStrategyName[keyof typeof NeutDrillHolePositioningStrategyName];


        /**
 * 
 * @export
 * @interface NeutListItem
 */
export interface NeutListItem {
    /**
     * 
     * @type {string}
     * @memberof NeutListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NeutListItem
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof NeutListItem
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof NeutListItem
     */
    'svg': string;
    /**
     * 
     * @type {string}
     * @memberof NeutListItem
     */
    'profileLeft': string;
    /**
     * 
     * @type {string}
     * @memberof NeutListItem
     */
    'profileRight': string;
    /**
     * 
     * @type {string}
     * @memberof NeutListItem
     */
    'profileContra': string;
    /**
     * 
     * @type {number}
     * @memberof NeutListItem
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof NeutListItem
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof NeutListItem
     */
    'sillModel': string;
    /**
     * 
     * @type {number}
     * @memberof NeutListItem
     */
    'sold': number;
    /**
     * 
     * @type {number}
     * @memberof NeutListItem
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof NeutListItem
     */
    'percentageOfTotal': number;
    /**
     * 
     * @type {number}
     * @memberof NeutListItem
     */
    'runningTotal': number;
}
        /**
 * 
 * @export
 * @interface NeutListItemArrayODataValue
 */
export interface NeutListItemArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof NeutListItemArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NeutListItemArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NeutListItemArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<NeutListItem>}
     * @memberof NeutListItemArrayODataValue
     */
    'value': Array<NeutListItem>;
}
        /**
 * 
 * @export
 * @interface NeutNbr
 */
export interface NeutNbr {
    /**
     * 
     * @type {number}
     * @memberof NeutNbr
     */
    'nbr'?: number;
    /**
     * 
     * @type {string}
     * @memberof NeutNbr
     */
    'code'?: string;
}
        /**
 * 
 * @export
 * @interface NeutProductionItemRepresentation
 */
export interface NeutProductionItemRepresentation {
    /**
     * 
     * @type {string}
     * @memberof NeutProductionItemRepresentation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof NeutProductionItemRepresentation
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof NeutProductionItemRepresentation
     */
    'remark'?: string | null;
}
        /**
 * 
 * @export
 * @interface NeutRepresentation
 */
export interface NeutRepresentation {
    /**
     * 
     * @type {string}
     * @memberof NeutRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NeutRepresentation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof NeutRepresentation
     */
    'svg': string;
    /**
     * 
     * @type {string}
     * @memberof NeutRepresentation
     */
    'profileLeft': string;
    /**
     * 
     * @type {string}
     * @memberof NeutRepresentation
     */
    'profileRight': string;
    /**
     * 
     * @type {string}
     * @memberof NeutRepresentation
     */
    'profileContra': string;
    /**
     * 
     * @type {number}
     * @memberof NeutRepresentation
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof NeutRepresentation
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof NeutRepresentation
     */
    'sillModel': string;
}
        /**
 * 
 * @export
 * @interface OrderActionLogsRepresentation
 */
export interface OrderActionLogsRepresentation {
    /**
     * 
     * @type {Array<ActionLogRepresentation>}
     * @memberof OrderActionLogsRepresentation
     */
    'actionLogs': Array<ActionLogRepresentation>;
}
        /**
 * 
 * @export
 * @interface OrderAddressModel
 */
export interface OrderAddressModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'attention'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'street': string;
    /**
     * 
     * @type {number}
     * @memberof OrderAddressModel
     */
    'houseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'houseNumberAddition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'extraAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressModel
     */
    'country': string;
}
        /**
 * 
 * @export
 * @interface OrderAddressRepresentation
 */
export interface OrderAddressRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'attention'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'street': string;
    /**
     * 
     * @type {number}
     * @memberof OrderAddressRepresentation
     */
    'houseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'houseNumberAddition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'extraAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressRepresentation
     */
    'country': string;
}
        /**
 * 
 * @export
 * @interface OrderCatalogModel
 */
export interface OrderCatalogModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCatalogModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCatalogModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCatalogModel
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface OrderContactPersonModel
 */
export interface OrderContactPersonModel {
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonModel
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonModel
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonModel
     */
    'telephone'?: string | null;
}
        /**
 * 
 * @export
 * @interface OrderContactPersonRepresentation
 */
export interface OrderContactPersonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonRepresentation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonRepresentation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonRepresentation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderContactPersonRepresentation
     */
    'telephone'?: string | null;
}
        /**
 * 
 * @export
 * @interface OrderDraftRepresentation
 */
export interface OrderDraftRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderDraftRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDraftRepresentation
     */
    'code': string;
    /**
     * 
     * @type {CustomerReference}
     * @memberof OrderDraftRepresentation
     */
    'customer': CustomerReference;
    /**
     * 
     * @type {OrderingPriceList}
     * @memberof OrderDraftRepresentation
     */
    'priceList': OrderingPriceList;
    /**
     * 
     * @type {PaymentConditions}
     * @memberof OrderDraftRepresentation
     */
    'paymentCondition'?: PaymentConditions;
    /**
     * 
     * @type {string}
     * @memberof OrderDraftRepresentation
     */
    'draftedDate': string;
}


        /**
 * 
 * @export
 * @interface OrderExpeditionModel
 */
export interface OrderExpeditionModel {
    /**
     * 
     * @type {string}
     * @memberof OrderExpeditionModel
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderExpeditionModel
     */
    'preferredExpeditionDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderExpeditionModel
     */
    'preferredExpeditionDateAsap'?: boolean | null;
    /**
     * 
     * @type {DeliveryConditions}
     * @memberof OrderExpeditionModel
     */
    'deliveryCondition': DeliveryConditions;
    /**
     * 
     * @type {OrderAddressModel}
     * @memberof OrderExpeditionModel
     */
    'deliveryAddress'?: OrderAddressModel;
    /**
     * 
     * @type {OrderContactPersonModel}
     * @memberof OrderExpeditionModel
     */
    'expeditionContact'?: OrderContactPersonModel;
}


        /**
 * 
 * @export
 * @interface OrderExpeditionRepresentation
 */
export interface OrderExpeditionRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderExpeditionRepresentation
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderExpeditionRepresentation
     */
    'preferredExpeditionDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderExpeditionRepresentation
     */
    'preferredExpeditionDateAsap'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderExpeditionRepresentation
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {DeliveryConditions}
     * @memberof OrderExpeditionRepresentation
     */
    'deliveryCondition'?: DeliveryConditions;
    /**
     * 
     * @type {OrderAddressRepresentation}
     * @memberof OrderExpeditionRepresentation
     */
    'deliveryAddress'?: OrderAddressRepresentation;
    /**
     * 
     * @type {OrderContactPersonRepresentation}
     * @memberof OrderExpeditionRepresentation
     */
    'contactPerson'?: OrderContactPersonRepresentation;
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof OrderExpeditionRepresentation
     */
    'status'?: ExpeditionStatusCodes;
    /**
     * 
     * @type {ExpeditionStatusTransition}
     * @memberof OrderExpeditionRepresentation
     */
    'delivered'?: ExpeditionStatusTransition;
}


        /**
 * 
 * @export
 * @interface OrderFinancialRepresentation
 */
export interface OrderFinancialRepresentation {
    /**
     * 
     * @type {OrderingPriceList}
     * @memberof OrderFinancialRepresentation
     */
    'priceList': OrderingPriceList;
    /**
     * 
     * @type {PaymentConditions}
     * @memberof OrderFinancialRepresentation
     */
    'paymentCondition'?: PaymentConditions;
    /**
     * 
     * @type {OrderAddressRepresentation}
     * @memberof OrderFinancialRepresentation
     */
    'invoiceAddress'?: OrderAddressRepresentation;
    /**
     * 
     * @type {OrderContactPersonRepresentation}
     * @memberof OrderFinancialRepresentation
     */
    'contactPerson'?: OrderContactPersonRepresentation;
    /**
     * 
     * @type {number}
     * @memberof OrderFinancialRepresentation
     */
    'projectDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderFinancialRepresentation
     */
    'totalPriceGross': number;
    /**
     * 
     * @type {number}
     * @memberof OrderFinancialRepresentation
     */
    'totalPriceNet': number;
    /**
     * 
     * @type {number}
     * @memberof OrderFinancialRepresentation
     */
    'totalProducts'?: number | null;
    /**
     * 
     * @type {InvoiceStatusCodes}
     * @memberof OrderFinancialRepresentation
     */
    'status'?: InvoiceStatusCodes;
    /**
     * 
     * @type {InvoiceStatusTransition}
     * @memberof OrderFinancialRepresentation
     */
    'notInvoiced'?: InvoiceStatusTransition;
    /**
     * 
     * @type {InvoiceStatusTransition}
     * @memberof OrderFinancialRepresentation
     */
    'invoiced'?: InvoiceStatusTransition;
    /**
     * 
     * @type {InvoiceStatusTransition}
     * @memberof OrderFinancialRepresentation
     */
    'paid'?: InvoiceStatusTransition;
}


        /**
 * 
 * @export
 * @interface OrderLineModel
 */
export interface OrderLineModel {
    /**
     * 
     * @type {string}
     * @memberof OrderLineModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineModel
     */
    'catalogItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineModel
     */
    'merk'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineModel
     */
    'quantity': number;
    /**
     * 
     * @type {OrderLineType}
     * @memberof OrderLineModel
     */
    'type': OrderLineType;
    /**
     * 
     * @type {string}
     * @memberof OrderLineModel
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineModel
     */
    'title': string;
}


        /**
 * 
 * @export
 * @interface OrderLineRepresentation
 */
export interface OrderLineRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'merk'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'catalogItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineRepresentation
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof OrderLineRepresentation
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderLineRepresentation
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof OrderLineRepresentation
     */
    'total': number;
    /**
     * 
     * @type {OrderLineType}
     * @memberof OrderLineRepresentation
     */
    'type': OrderLineType;
    /**
     * 
     * @type {SbomModel}
     * @memberof OrderLineRepresentation
     */
    'sbom': SbomModel;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const OrderLineType = {
    ShippingCostLine: 'shippingCostLine',
    TextLine: 'textLine',
    ProductLine: 'productLine',
    ConfigurationLine: 'configurationLine'
} as const;

export type OrderLineType = typeof OrderLineType[keyof typeof OrderLineType];


        /**
 * 
 * @export
 * @interface OrderListView
 */
export interface OrderListView {
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'code': string;
    /**
     * 
     * @type {OrderStatusCodes}
     * @memberof OrderListView
     */
    'status': OrderStatusCodes;
    /**
     * 
     * @type {number}
     * @memberof OrderListView
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListView
     */
    'totalProducts': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'orderDrafted': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'quoteIssued'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'quoteAccepted'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'placed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'accepted'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'completed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'cancelled'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'preferredExpeditionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'realisedExpeditionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'plannedProductionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'customerCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListView
     */
    'reference'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderListView
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderListView
     */
    'urgent': boolean;
}


        /**
 * 
 * @export
 * @interface OrderListViewArrayODataValue
 */
export interface OrderListViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof OrderListViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderListViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<OrderListView>}
     * @memberof OrderListViewArrayODataValue
     */
    'value': Array<OrderListView>;
}
        /**
 * 
 * @export
 * @interface OrderModel
 */
export interface OrderModel {
    /**
     * 
     * @type {string}
     * @memberof OrderModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderModel
     */
    'code': string;
    /**
     * 
     * @type {Array<OrderLineModel>}
     * @memberof OrderModel
     */
    'lines': Array<OrderLineModel>;
    /**
     * 
     * @type {OrderExpeditionModel}
     * @memberof OrderModel
     */
    'orderExpedition': OrderExpeditionModel;
}
        /**
 * 
 * @export
 * @interface OrderProductionRepresentation
 */
export interface OrderProductionRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderProductionRepresentation
     */
    'plannedProductionDate'?: string | null;
    /**
     * 
     * @type {ProductionStatusCodes}
     * @memberof OrderProductionRepresentation
     */
    'status': ProductionStatusCodes;
    /**
     * 
     * @type {number}
     * @memberof OrderProductionRepresentation
     */
    'productionItemCount': number;
}


        /**
 * 
 * @export
 * @interface OrderReference
 */
export interface OrderReference {
    /**
     * 
     * @type {string}
     * @memberof OrderReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReference
     */
    'reference'?: string | null;
}
        /**
 * 
 * @export
 * @interface OrderRemarkRepresentation
 */
export interface OrderRemarkRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderRemarkRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRemarkRepresentation
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRemarkRepresentation
     */
    'createdDate': string;
    /**
     * 
     * @type {OrderRemarkType}
     * @memberof OrderRemarkRepresentation
     */
    'type': OrderRemarkType;
    /**
     * 
     * @type {string}
     * @memberof OrderRemarkRepresentation
     */
    'remark': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRemarkRepresentation
     */
    'userDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRemarkRepresentation
     */
    'userId': string;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const OrderRemarkType = {
    Internal: 'internal',
    External: 'external',
    Wizard: 'wizard'
} as const;

export type OrderRemarkType = typeof OrderRemarkType[keyof typeof OrderRemarkType];


        /**
 * 
 * @export
 * @interface OrderRepresentation
 */
export interface OrderRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRepresentation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRepresentation
     */
    'orderDate'?: string | null;
    /**
     * 
     * @type {OrderStatusCodes}
     * @memberof OrderRepresentation
     */
    'status': OrderStatusCodes;
    /**
     * 
     * @type {CustomerReference}
     * @memberof OrderRepresentation
     */
    'customer': CustomerReference;
    /**
     * 
     * @type {OrderCatalogModel}
     * @memberof OrderRepresentation
     */
    'catalog': OrderCatalogModel;
    /**
     * 
     * @type {OrderContactPersonRepresentation}
     * @memberof OrderRepresentation
     */
    'orderContact'?: OrderContactPersonRepresentation;
    /**
     * 
     * @type {OrderFinancialRepresentation}
     * @memberof OrderRepresentation
     */
    'financial': OrderFinancialRepresentation;
    /**
     * 
     * @type {OrderExpeditionRepresentation}
     * @memberof OrderRepresentation
     */
    'expedition'?: OrderExpeditionRepresentation;
    /**
     * 
     * @type {OrderProductionRepresentation}
     * @memberof OrderRepresentation
     */
    'production': OrderProductionRepresentation;
    /**
     * 
     * @type {string}
     * @memberof OrderRepresentation
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {Array<OrderLineRepresentation>}
     * @memberof OrderRepresentation
     */
    'lines': Array<OrderLineRepresentation>;
    /**
     * 
     * @type {Array<OrderRemarkRepresentation>}
     * @memberof OrderRepresentation
     */
    'remarks': Array<OrderRemarkRepresentation>;
    /**
     * 
     * @type {OrderTransitionRepresentation}
     * @memberof OrderRepresentation
     */
    'transitions': OrderTransitionRepresentation;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRepresentation
     */
    'isArchived': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderRepresentation
     */
    'sillCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRepresentation
     */
    'urgent': boolean;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatusCodes = {
    Draft: 'draft',
    QuoteIssued: 'quoteIssued',
    Placed: 'placed',
    Accepted: 'accepted',
    Completed: 'completed',
    Cancelled: 'cancelled'
} as const;

export type OrderStatusCodes = typeof OrderStatusCodes[keyof typeof OrderStatusCodes];


        /**
 * 
 * @export
 * @interface OrderStatusTransitionRepresentation
 */
export interface OrderStatusTransitionRepresentation {
    /**
     * 
     * @type {string}
     * @memberof OrderStatusTransitionRepresentation
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusTransitionRepresentation
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusTransitionRepresentation
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @interface OrderTransitionRepresentation
 */
export interface OrderTransitionRepresentation {
    /**
     * 
     * @type {OrderStatusTransitionRepresentation}
     * @memberof OrderTransitionRepresentation
     */
    'drafted': OrderStatusTransitionRepresentation;
    /**
     * 
     * @type {OrderStatusTransitionRepresentation}
     * @memberof OrderTransitionRepresentation
     */
    'quoteIssued'?: OrderStatusTransitionRepresentation;
    /**
     * 
     * @type {OrderStatusTransitionRepresentation}
     * @memberof OrderTransitionRepresentation
     */
    'quoteAccepted'?: OrderStatusTransitionRepresentation;
    /**
     * 
     * @type {OrderStatusTransitionRepresentation}
     * @memberof OrderTransitionRepresentation
     */
    'placed'?: OrderStatusTransitionRepresentation;
    /**
     * 
     * @type {OrderStatusTransitionRepresentation}
     * @memberof OrderTransitionRepresentation
     */
    'accepted'?: OrderStatusTransitionRepresentation;
    /**
     * 
     * @type {OrderStatusTransitionRepresentation}
     * @memberof OrderTransitionRepresentation
     */
    'completed'?: OrderStatusTransitionRepresentation;
    /**
     * 
     * @type {OrderStatusTransitionRepresentation}
     * @memberof OrderTransitionRepresentation
     */
    'cancelled'?: OrderStatusTransitionRepresentation;
}
        /**
 * 
 * @export
 * @interface OrderingPriceList
 */
export interface OrderingPriceList {
    /**
     * 
     * @type {string}
     * @memberof OrderingPriceList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderingPriceList
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderingPriceList
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderingPriceList
     */
    'isStandard': boolean;
}
        /**
 * 
 * @export
 * @interface PackingSlipExpeditionModel
 */
export interface PackingSlipExpeditionModel {
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof PackingSlipExpeditionModel
     */
    'status': ExpeditionStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipExpeditionModel
     */
    'plannedExpeditionDate'?: string | null;
}


        /**
 * 
 * @export
 * @interface PackingSlipModel
 */
export interface PackingSlipModel {
    /**
     * 
     * @type {PackingSlipOrderModel}
     * @memberof PackingSlipModel
     */
    'packingSlipOrder': PackingSlipOrderModel;
    /**
     * 
     * @type {Array<MountingModel>}
     * @memberof PackingSlipModel
     */
    'mountings': Array<MountingModel>;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipModel
     */
    'totalNumberOfProducts': number;
}
        /**
 * 
 * @export
 * @interface PackingSlipOrderModel
 */
export interface PackingSlipOrderModel {
    /**
     * 
     * @type {CustomerReference}
     * @memberof PackingSlipOrderModel
     */
    'customer': CustomerReference;
    /**
     * 
     * @type {PackingSlipExpeditionModel}
     * @memberof PackingSlipOrderModel
     */
    'expedition': PackingSlipExpeditionModel;
    /**
     * 
     * @type {OrderModel}
     * @memberof PackingSlipOrderModel
     */
    'order': OrderModel;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const PartCorner = {
    StartLeftBottom: 'startLeftBottom',
    StartRightBottom: 'startRightBottom',
    EndRightBottom: 'endRightBottom',
    EndLeftBottom: 'endLeftBottom',
    StartLeftTop: 'startLeftTop',
    StartRightTop: 'startRightTop',
    EndRightTop: 'endRightTop',
    EndLeftTop: 'endLeftTop'
} as const;

export type PartCorner = typeof PartCorner[keyof typeof PartCorner];


        /**
 * 
 * @export
 * @enum {string}
 */

export const PartSide = {
    NotDefined: 'notDefined',
    Right: 'right',
    Left: 'left',
    Start: 'start',
    End: 'end',
    Bottom: 'bottom',
    Top: 'top'
} as const;

export type PartSide = typeof PartSide[keyof typeof PartSide];


        /**
 * 
 * @export
 * @enum {string}
 */

export const PartTurnOrientation = {
    KeepAsProcessed: 'keepAsProcessed',
    Turn: 'turn',
    PutFlat: 'putFlat'
} as const;

export type PartTurnOrientation = typeof PartTurnOrientation[keyof typeof PartTurnOrientation];


        /**
 * 
 * @export
 * @enum {string}
 */

export const PaymentConditions = {
    Vooraf: 'vooraf',
    OpFactuur30Dagen: 'opFactuur30Dagen',
    OpFactuur8Dagen: 'opFactuur8Dagen',
    AutomatischeIncasso: 'automatischeIncasso'
} as const;

export type PaymentConditions = typeof PaymentConditions[keyof typeof PaymentConditions];


        /**
 * @type PimCatalogItemsIdGet200Response
 * @export
 */
export type PimCatalogItemsIdGet200Response = CatalogItemProduct | ConfiguredNeutProduct | ConfiguredSillProduct | CornerPieceProduct | GlazingBarProduct | GlazingProfileProduct | KopIsolatorSetProduct | LProfileProduct | SillConfiguratorProduct | SillProduct | StandardProduct | StopProfileProduct | TransportProduct;

        /**
 * @type PimCatalogItemsIdPutRequest
 * @export
 */
export type PimCatalogItemsIdPutRequest = UpdateConfiguredSillProductCommand | UpdateCornerPieceProductCommand | UpdateGlazingBarProductCommand | UpdateGlazingProfileProductCommand | UpdateKopIsolatorSetProductCommand | UpdateLProfileProductCommand | UpdateNeutCommand | UpdateSillConfiguratorProductCommand | UpdateSillProductCommand | UpdateStandardProductCommand | UpdateStopProfileProductCommand | UpdateTransportProductCommand;

        /**
 * @type PimCatalogItemsPostRequest
 * @export
 */
export type PimCatalogItemsPostRequest = CreateConfiguredNeutProductCommand | CreateConfiguredSillProductCommand | CreateCornerPieceProductCommand | CreateGlazingBarProductCommand | CreateGlazingProfileProductCommand | CreateKopIsolatorSetProductCommand | CreateLProfileProductCommand | CreateProductCommand | CreateSillConfiguratorProductCommand | CreateSillProductCommand | CreateStandardProductCommand | CreateStopProfileProductCommand | CreateTransportProductCommand;

        /**
 * 
 * @export
 * @interface Pocket
 */
export interface Pocket {
    /**
     * 
     * @type {string}
     * @memberof Pocket
     */
    'contour': string;
    /**
     * 
     * @type {number}
     * @memberof Pocket
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    'y'?: number;
}
        /**
 * 
 * @export
 * @interface Point3D
 */
export interface Point3D {
    /**
     * 
     * @type {number}
     * @memberof Point3D
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Point3D
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof Point3D
     */
    'z'?: number;
}
        /**
 * 
 * @export
 * @interface PostConfiguratorOrderLineRepresentation
 */
export interface PostConfiguratorOrderLineRepresentation extends PostProductOrderLineRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostConfiguratorOrderLineRepresentation
     */
    'merk'?: string | null;
}


        /**
 * 
 * @export
 * @interface PostContactPersonRepresentation
 */
export interface PostContactPersonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostContactPersonRepresentation
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof PostContactPersonRepresentation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PostContactPersonRepresentation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PostContactPersonRepresentation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PostContactPersonRepresentation
     */
    'telephone'?: string | null;
}
        /**
 * 
 * @export
 * @interface PostCustomerAddressRepresentation
 */
export interface PostCustomerAddressRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'attention'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'street': string;
    /**
     * 
     * @type {number}
     * @memberof PostCustomerAddressRepresentation
     */
    'houseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'houseNumberAddition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'extraAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'country': string;
    /**
     * 
     * @type {PostCustomerAddressRepresentationContactPerson}
     * @memberof PostCustomerAddressRepresentation
     */
    'contactPerson': PostCustomerAddressRepresentationContactPerson;
    /**
     * 
     * @type {boolean}
     * @memberof PostCustomerAddressRepresentation
     */
    'isInvoicingAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostCustomerAddressRepresentation
     */
    'isDeliveryAddress': boolean;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerAddressRepresentation
     */
    'customerId': string;
}
        /**
 * @type PostCustomerAddressRepresentationContactPerson
 * @export
 */
export type PostCustomerAddressRepresentationContactPerson = ContactPersonRepresentation | PostContactPersonRepresentation;

        /**
 * 
 * @export
 * @interface PostCustomerEmbeddedAddressRepresentation
 */
export interface PostCustomerEmbeddedAddressRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'street': string;
    /**
     * 
     * @type {number}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'houseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'houseNumberAddition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'country': string;
    /**
     * 
     * @type {PostEmbeddedContactPersonRepresentation}
     * @memberof PostCustomerEmbeddedAddressRepresentation
     */
    'contactPerson': PostEmbeddedContactPersonRepresentation;
}
        /**
 * 
 * @export
 * @interface PostCustomerRepresentation
 */
export interface PostCustomerRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'cocNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'cocDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'vatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof PostCustomerRepresentation
     */
    'debtorNumber'?: number | null;
    /**
     * 
     * @type {PaymentConditions}
     * @memberof PostCustomerRepresentation
     */
    'paymentConditions': PaymentConditions;
    /**
     * 
     * @type {DeliveryConditions}
     * @memberof PostCustomerRepresentation
     */
    'deliveryConditions': DeliveryConditions;
    /**
     * 
     * @type {PostCustomerEmbeddedAddressRepresentation}
     * @memberof PostCustomerRepresentation
     */
    'address'?: PostCustomerEmbeddedAddressRepresentation;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'catalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCustomerRepresentation
     */
    'priceListId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostCustomerRepresentation
     */
    'waiveTransportCosts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostCustomerRepresentation
     */
    'gaImportEnabled': boolean;
}


        /**
 * 
 * @export
 * @interface PostEmbeddedContactPersonRepresentation
 */
export interface PostEmbeddedContactPersonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostEmbeddedContactPersonRepresentation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PostEmbeddedContactPersonRepresentation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PostEmbeddedContactPersonRepresentation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PostEmbeddedContactPersonRepresentation
     */
    'telephone'?: string | null;
}
        /**
 * 
 * @export
 * @interface PostOrderLineRepresentation
 */
export interface PostOrderLineRepresentation {
    /**
     * 
     * @type {OrderLineType}
     * @memberof PostOrderLineRepresentation
     */
    'orderlineType': OrderLineType;
    /**
     * 
     * @type {string}
     * @memberof PostOrderLineRepresentation
     */
    'remark'?: string | null;
}


        /**
 * 
 * @export
 * @interface PostOrderRemarkRepresentation
 */
export interface PostOrderRemarkRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostOrderRemarkRepresentation
     */
    'remark': string;
    /**
     * 
     * @type {OrderRemarkType}
     * @memberof PostOrderRemarkRepresentation
     */
    'type': OrderRemarkType;
}


        /**
 * 
 * @export
 * @interface PostPackingSlipsPdf
 */
export interface PostPackingSlipsPdf {
    /**
     * 
     * @type {Array<string>}
     * @memberof PostPackingSlipsPdf
     */
    'orderIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PostPackingSlipsPdf
     */
    'filename': string;
}
        /**
 * 
 * @export
 * @interface PostProductOrderLineRepresentation
 */
export interface PostProductOrderLineRepresentation extends PostQuantityOrderLineRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostProductOrderLineRepresentation
     */
    'catalogItemId': string;
}


        /**
 * 
 * @export
 * @interface PostProductionBatchForNeutenRepresentation
 */
export interface PostProductionBatchForNeutenRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostProductionBatchForNeutenRepresentation
     */
    'title': string;
    /**
     * 
     * @type {Array<NeutProductionItemRepresentation>}
     * @memberof PostProductionBatchForNeutenRepresentation
     */
    'items': Array<NeutProductionItemRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof PostProductionBatchForNeutenRepresentation
     */
    'urgent': boolean;
    /**
     * 
     * @type {string}
     * @memberof PostProductionBatchForNeutenRepresentation
     */
    'remark'?: string | null;
}
        /**
 * 
 * @export
 * @interface PostProductionBatchFromFilterRepresentation
 */
export interface PostProductionBatchFromFilterRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostProductionBatchFromFilterRepresentation
     */
    'title': string;
    /**
     * 
     * @type {ProductionBatchTypes}
     * @memberof PostProductionBatchFromFilterRepresentation
     */
    'type': ProductionBatchTypes;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostProductionBatchFromFilterRepresentation
     */
    'selectionFilters': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PostProductionBatchFromFilterRepresentation
     */
    'urgent': boolean;
    /**
     * 
     * @type {string}
     * @memberof PostProductionBatchFromFilterRepresentation
     */
    'remark'?: string | null;
}


        /**
 * 
 * @export
 * @interface PostProductionBatchRepresentation
 */
export interface PostProductionBatchRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostProductionBatchRepresentation
     */
    'title': string;
    /**
     * 
     * @type {ProductionBatchTypes}
     * @memberof PostProductionBatchRepresentation
     */
    'type': ProductionBatchTypes;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostProductionBatchRepresentation
     */
    'items': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PostProductionBatchRepresentation
     */
    'urgent': boolean;
    /**
     * 
     * @type {string}
     * @memberof PostProductionBatchRepresentation
     */
    'remark'?: string | null;
}


        /**
 * 
 * @export
 * @interface PostQuantityOrderLineRepresentation
 */
export interface PostQuantityOrderLineRepresentation extends PostOrderLineRepresentation {
    /**
     * 
     * @type {number}
     * @memberof PostQuantityOrderLineRepresentation
     */
    'quantity': number;
}


        /**
 * 
 * @export
 * @interface PostShippingCostOrderLineRepresentation
 */
export interface PostShippingCostOrderLineRepresentation extends PostQuantityOrderLineRepresentation {
}


        /**
 * 
 * @export
 * @interface PostTextOrderLineRepresentation
 */
export interface PostTextOrderLineRepresentation extends PostQuantityOrderLineRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PostTextOrderLineRepresentation
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof PostTextOrderLineRepresentation
     */
    'price': number;
}


        /**
 * 
 * @export
 * @interface PreviewCompartmentProfileGenerationCommand
 */
export interface PreviewCompartmentProfileGenerationCommand {
    /**
     * 
     * @type {string}
     * @memberof PreviewCompartmentProfileGenerationCommand
     */
    'rabbetId'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PreviewCompartmentProfileGenerationCommand
     */
    'sillWidths': Array<number>;
    /**
     * 
     * @type {Array<RabbetPosition>}
     * @memberof PreviewCompartmentProfileGenerationCommand
     */
    'positions': Array<RabbetPosition>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewCompartmentProfileGenerationCommand
     */
    'cornerProfilesInside': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewCompartmentProfileGenerationCommand
     */
    'cornerProfilesOutside': Array<string>;
}
        /**
 * 
 * @export
 * @interface PriceChange
 */
export interface PriceChange {
    /**
     * 
     * @type {string}
     * @memberof PriceChange
     */
    'id': string;
    /**
     * 
     * @type {PriceChangeType}
     * @memberof PriceChange
     */
    'priceChangeType': PriceChangeType;
    /**
     * 
     * @type {PriceListReference}
     * @memberof PriceChange
     */
    'priceList': PriceListReference;
    /**
     * 
     * @type {CatalogItemReference}
     * @memberof PriceChange
     */
    'catalogItem': CatalogItemReference;
    /**
     * 
     * @type {string}
     * @memberof PriceChange
     */
    'effectiveDay': string;
    /**
     * 
     * @type {number}
     * @memberof PriceChange
     */
    'newPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriceChange
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceChange
     */
    'changeTime': string;
    /**
     * 
     * @type {string}
     * @memberof PriceChange
     */
    'changedBy': string;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const PriceChangeType = {
    Change: 'change',
    Reset: 'reset',
    Delete: 'delete'
} as const;

export type PriceChangeType = typeof PriceChangeType[keyof typeof PriceChangeType];


        /**
 * 
 * @export
 * @interface PriceCommand
 */
export interface PriceCommand {
    /**
     * 
     * @type {PriceChangeType}
     * @memberof PriceCommand
     */
    'priceChangeType': PriceChangeType;
    /**
     * 
     * @type {string}
     * @memberof PriceCommand
     */
    'catalogItemId': string;
    /**
     * 
     * @type {string}
     * @memberof PriceCommand
     */
    'effectiveDay': string;
    /**
     * 
     * @type {string}
     * @memberof PriceCommand
     */
    'note'?: string | null;
}


        /**
 * 
 * @export
 * @interface PriceList
 */
export interface PriceList {
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    'parentPriceListId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceList
     */
    'isSystem': boolean;
}
        /**
 * 
 * @export
 * @interface PriceListAndCatalogItemDiscountRule
 */
export interface PriceListAndCatalogItemDiscountRule extends DiscountRule {
    /**
     * 
     * @type {DiscountRulePriceList}
     * @memberof PriceListAndCatalogItemDiscountRule
     */
    'priceList': DiscountRulePriceList;
    /**
     * 
     * @type {DiscountRuleCatalogItem}
     * @memberof PriceListAndCatalogItemDiscountRule
     */
    'catalogItem': DiscountRuleCatalogItem;
}


        /**
 * 
 * @export
 * @interface PriceListAndDiscountGroupDiscountRule
 */
export interface PriceListAndDiscountGroupDiscountRule extends DiscountRule {
    /**
     * 
     * @type {DiscountRulePriceList}
     * @memberof PriceListAndDiscountGroupDiscountRule
     */
    'priceList': DiscountRulePriceList;
    /**
     * 
     * @type {DiscountRuleDiscountGroup}
     * @memberof PriceListAndDiscountGroupDiscountRule
     */
    'discountGroup': DiscountRuleDiscountGroup;
}


        /**
 * 
 * @export
 * @interface PriceListDiscountRule
 */
export interface PriceListDiscountRule extends DiscountRule {
    /**
     * 
     * @type {DiscountRulePriceList}
     * @memberof PriceListDiscountRule
     */
    'priceList': DiscountRulePriceList;
}


        /**
 * 
 * @export
 * @interface PriceListIdReferenceWithIdCodeAndName
 */
export interface PriceListIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof PriceListIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface PriceListItemCategory
 */
export interface PriceListItemCategory {
    /**
     * 
     * @type {string}
     * @memberof PriceListItemCategory
     */
    'categoryId': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListItemCategory
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListItemCategory
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<PriceListLine>}
     * @memberof PriceListItemCategory
     */
    'lines': Array<PriceListLine>;
}
        /**
 * 
 * @export
 * @interface PriceListLine
 */
export interface PriceListLine {
    /**
     * 
     * @type {string}
     * @memberof PriceListLine
     */
    'catalogItemId': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListLine
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListLine
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListLine
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceListLine
     */
    'price': number;
}
        /**
 * 
 * @export
 * @interface PriceListPrice
 */
export interface PriceListPrice {
    /**
     * 
     * @type {number}
     * @memberof PriceListPrice
     */
    'value'?: number;
}
        /**
 * 
 * @export
 * @interface PriceListReference
 */
export interface PriceListReference {
    /**
     * 
     * @type {boolean}
     * @memberof PriceListReference
     */
    'isStandard': boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceListReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface PriceListRepresentation
 */
export interface PriceListRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PriceListRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListRepresentation
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof PriceListRepresentation
     */
    'paymentTerm': string;
    /**
     * 
     * @type {CustomerPriceList}
     * @memberof PriceListRepresentation
     */
    'priceList': CustomerPriceList;
    /**
     * 
     * @type {string}
     * @memberof PriceListRepresentation
     */
    'asOfDate': string;
    /**
     * 
     * @type {Array<PriceListItemCategory>}
     * @memberof PriceListRepresentation
     */
    'groups': Array<PriceListItemCategory>;
}
        /**
 * 
 * @export
 * @interface PriceSpecification
 */
export interface PriceSpecification {
    /**
     * 
     * @type {CalculationCatalogItem}
     * @memberof PriceSpecification
     */
    'product'?: CalculationCatalogItem;
    /**
     * 
     * @type {number}
     * @memberof PriceSpecification
     */
    'unitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PriceSpecification
     */
    'quantity': number;
    /**
     * 
     * @type {Array<PriceSpecification>}
     * @memberof PriceSpecification
     */
    'composition'?: Array<PriceSpecification> | null;
}
        /**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
        /**
 * 
 * @export
 * @interface ProductionAnalysisMetrics
 */
export interface ProductionAnalysisMetrics {
    /**
     * 
     * @type {number}
     * @memberof ProductionAnalysisMetrics
     */
    'planned': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionAnalysisMetrics
     */
    'realised': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionAnalysisMetrics
     */
    'efficiency': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionAnalysisMetrics
     */
    'backlog': number;
}
        /**
 * 
 * @export
 * @interface ProductionBatchItemRepresentation
 */
export interface ProductionBatchItemRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchItemRepresentation
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'bmhBatchCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'title': string;
    /**
     * 
     * @type {ProductionBatchTypes}
     * @memberof ProductionBatchItemRepresentation
     */
    'type': ProductionBatchTypes;
    /**
     * 
     * @type {ProductionBatchStatusCodes}
     * @memberof ProductionBatchItemRepresentation
     */
    'status': ProductionBatchStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'draftedDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'acceptedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'finishedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'failedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemRepresentation
     */
    'itemBatchSequence': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchItemRepresentation
     */
    'neutenGroupNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchItemRepresentation
     */
    'lattenGroupNumber'?: number | null;
}


        /**
 * 
 * @export
 * @interface ProductionBatchItemViewRepresentation
 */
export interface ProductionBatchItemViewRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'batchId': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'bmhBatchCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'title': string;
    /**
     * 
     * @type {ProductionBatchTypes}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'type': ProductionBatchTypes;
    /**
     * 
     * @type {ProductionBatchStatusCodes}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'status': ProductionBatchStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'notStartedDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'finishedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'failedDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'neutenGroupNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchItemViewRepresentation
     */
    'lattenGroupNumber'?: number | null;
}


        /**
 * 
 * @export
 * @interface ProductionBatchRepresentation
 */
export interface ProductionBatchRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchRepresentation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchRepresentation
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchRepresentation
     */
    'bmhBatchCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchRepresentation
     */
    'title': string;
    /**
     * 
     * @type {ProductionBatchTypes}
     * @memberof ProductionBatchRepresentation
     */
    'type': ProductionBatchTypes;
    /**
     * 
     * @type {ProductionBatchStatusCodes}
     * @memberof ProductionBatchRepresentation
     */
    'status': ProductionBatchStatusCodes;
    /**
     * 
     * @type {Array<ProductionItemBatchRepresentation>}
     * @memberof ProductionBatchRepresentation
     */
    'items': Array<ProductionItemBatchRepresentation>;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchRepresentation
     */
    'remark'?: string | null;
    /**
     * 
     * @type {ProductionBatchStatusCodesTransition}
     * @memberof ProductionBatchRepresentation
     */
    'drafted': ProductionBatchStatusCodesTransition;
    /**
     * 
     * @type {ProductionBatchStatusCodesTransition}
     * @memberof ProductionBatchRepresentation
     */
    'accepted'?: ProductionBatchStatusCodesTransition;
    /**
     * 
     * @type {ProductionBatchStatusCodesTransition}
     * @memberof ProductionBatchRepresentation
     */
    'started'?: ProductionBatchStatusCodesTransition;
    /**
     * 
     * @type {ProductionBatchStatusCodesTransition}
     * @memberof ProductionBatchRepresentation
     */
    'finished'?: ProductionBatchStatusCodesTransition;
    /**
     * 
     * @type {ProductionBatchStatusCodesTransition}
     * @memberof ProductionBatchRepresentation
     */
    'failed'?: ProductionBatchStatusCodesTransition;
    /**
     * 
     * @type {ProductionBatchStatusCodesTransition}
     * @memberof ProductionBatchRepresentation
     */
    'cancelled'?: ProductionBatchStatusCodesTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionBatchRepresentation
     */
    'urgent': boolean;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ProductionBatchStatusCodes = {
    Drafted: 'drafted',
    Accepted: 'accepted',
    Started: 'started',
    Finished: 'finished',
    Cancelled: 'cancelled',
    Failed: 'failed'
} as const;

export type ProductionBatchStatusCodes = typeof ProductionBatchStatusCodes[keyof typeof ProductionBatchStatusCodes];


        /**
 * 
 * @export
 * @interface ProductionBatchStatusCodesTransition
 */
export interface ProductionBatchStatusCodesTransition {
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchStatusCodesTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchStatusCodesTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchStatusCodesTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ProductionBatchTypes = {
    Neuten: 'neuten',
    Latten: 'latten',
    Dorpel: 'dorpel',
    NeutenLos: 'neutenLos'
} as const;

export type ProductionBatchTypes = typeof ProductionBatchTypes[keyof typeof ProductionBatchTypes];


        /**
 * 
 * @export
 * @interface ProductionBatchView
 */
export interface ProductionBatchView {
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchView
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'bmhBatchCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'title': string;
    /**
     * 
     * @type {ProductionBatchTypes}
     * @memberof ProductionBatchView
     */
    'type': ProductionBatchTypes;
    /**
     * 
     * @type {ProductionBatchStatusCodes}
     * @memberof ProductionBatchView
     */
    'status': ProductionBatchStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'draftedDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'acceptedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'startedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'failedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchView
     */
    'finishedDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchView
     */
    'itemsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchView
     */
    'compartmentCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchView
     */
    'neutCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchView
     */
    'lattenCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionBatchView
     */
    'urgent': boolean;
}


        /**
 * 
 * @export
 * @interface ProductionBatchViewArrayODataValue
 */
export interface ProductionBatchViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionBatchViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionBatchViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<ProductionBatchView>}
     * @memberof ProductionBatchViewArrayODataValue
     */
    'value': Array<ProductionBatchView>;
}
        /**
 * 
 * @export
 * @interface ProductionCatalogItem
 */
export interface ProductionCatalogItem {
    /**
     * 
     * @type {string}
     * @memberof ProductionCatalogItem
     */
    'id': string;
    /**
     * 
     * @type {CatalogItemType}
     * @memberof ProductionCatalogItem
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof ProductionCatalogItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionCatalogItem
     */
    'title': string;
}


        /**
 * 
 * @export
 * @interface ProductionDashboardStats
 */
export interface ProductionDashboardStats {
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'dorpels': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'sawMaster': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'wm50': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'neutMontage': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'sluitpot': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'hefschuif': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'specials': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'kopisolator': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'manchette': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'latten': Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStats
     */
    'inpak': Station;
    /**
     * 
     * @type {Array<ProductionDashboardStatsRow>}
     * @memberof ProductionDashboardStats
     */
    'rows': Array<ProductionDashboardStatsRow>;
}
        /**
 * 
 * @export
 * @interface ProductionDashboardStatsRow
 */
export interface ProductionDashboardStatsRow {
    /**
     * 
     * @type {string}
     * @memberof ProductionDashboardStatsRow
     */
    'date': string;
    /**
     * 
     * @type {ProductionDashboardStatsRowStations}
     * @memberof ProductionDashboardStatsRow
     */
    'stations': ProductionDashboardStatsRowStations;
}
        /**
 * 
 * @export
 * @interface ProductionDashboardStatsRowStations
 */
export interface ProductionDashboardStatsRowStations {
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'dorpels'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'sawMaster'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'wm50'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'neutMontage'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'specials'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'sluitpot'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'hefschuif'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'kopisolator'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'manchette'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'latten'?: Station;
    /**
     * 
     * @type {Station}
     * @memberof ProductionDashboardStatsRowStations
     */
    'inpak'?: Station;
}
        /**
 * 
 * @export
 * @interface ProductionItemBatchRepresentation
 */
export interface ProductionItemBatchRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'sequence': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'batchSequence': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemBatchRepresentation
     */
    'neutenGroupNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemBatchRepresentation
     */
    'lattenGroupNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'orderLineId'?: string | null;
    /**
     * 
     * @type {OrderReference}
     * @memberof ProductionItemBatchRepresentation
     */
    'order'?: OrderReference;
    /**
     * 
     * @type {CustomerReference}
     * @memberof ProductionItemBatchRepresentation
     */
    'customer'?: CustomerReference;
    /**
     * 
     * @type {ProductionCatalogItem}
     * @memberof ProductionItemBatchRepresentation
     */
    'catalogItem': ProductionCatalogItem;
    /**
     * 
     * @type {ProductionItemStatusCodes}
     * @memberof ProductionItemBatchRepresentation
     */
    'status': ProductionItemStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'plannedProductionDate': string;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemBatchRepresentation
     */
    'notStarted': ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemBatchRepresentation
     */
    'started'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemBatchRepresentation
     */
    'finished'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemBatchRepresentation
     */
    'failed'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemBatchRepresentation
     */
    'itemCancelled'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemOrderCancelledTransition}
     * @memberof ProductionItemBatchRepresentation
     */
    'orderCancelled'?: ProductionItemOrderCancelledTransition;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'merk'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemBatchRepresentation
     */
    'remark'?: string | null;
    /**
     * 
     * @type {ProductionItemStationStatus}
     * @memberof ProductionItemBatchRepresentation
     */
    'stationStatus': ProductionItemStationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemBatchRepresentation
     */
    'needsNeutenBatch'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemBatchRepresentation
     */
    'needsLattenBatch'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemBatchRepresentation
     */
    'needsDorpelBatch'?: boolean | null;
}


        /**
 * 
 * @export
 * @interface ProductionItemGroupRepresentation
 */
export interface ProductionItemGroupRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemGroupRepresentation
     */
    'code'?: string | null;
    /**
     * 
     * @type {Array<ProductionItemRepresentation>}
     * @memberof ProductionItemGroupRepresentation
     */
    'items': Array<ProductionItemRepresentation>;
}
        /**
 * 
 * @export
 * @interface ProductionItemNextOperationRepresentation
 */
export interface ProductionItemNextOperationRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemNextOperationRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemNextOperationRepresentation
     */
    'barcode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemNextOperationRepresentation
     */
    'sillTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemNextOperationRepresentation
     */
    'nextOperation': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ProductionItemOperations = {
    NeutenGemaakt: 'neutenGemaakt',
    DorpelProfielGezaagd: 'dorpelProfielGezaagd',
    LattenGemaakt: 'lattenGemaakt',
    DorpelBewerkt: 'dorpelBewerkt',
    NeutenGemonteerd: 'neutenGemonteerd',
    SluitpottenGemonteerd: 'sluitpottenGemonteerd',
    LattenGemonteerd: 'lattenGemonteerd',
    KopisolatorenGemonteerd: 'kopisolatorenGemonteerd',
    ManchettesGemonteerd: 'manchettesGemonteerd',
    HefschuifVouwwand: 'hefschuifVouwwand',
    Custom: 'custom',
    Ingepakt: 'ingepakt'
} as const;

export type ProductionItemOperations = typeof ProductionItemOperations[keyof typeof ProductionItemOperations];


        /**
 * 
 * @export
 * @interface ProductionItemOrderCancelledTransition
 */
export interface ProductionItemOrderCancelledTransition {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemOrderCancelledTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemOrderCancelledTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemOrderCancelledTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @interface ProductionItemRepresentation
 */
export interface ProductionItemRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'sequence': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'orderLineId'?: string | null;
    /**
     * 
     * @type {OrderReference}
     * @memberof ProductionItemRepresentation
     */
    'order'?: OrderReference;
    /**
     * 
     * @type {CustomerReference}
     * @memberof ProductionItemRepresentation
     */
    'customer'?: CustomerReference;
    /**
     * 
     * @type {ProductionCatalogItem}
     * @memberof ProductionItemRepresentation
     */
    'catalogItem': ProductionCatalogItem;
    /**
     * 
     * @type {ProductionItemStatusCodes}
     * @memberof ProductionItemRepresentation
     */
    'status': ProductionItemStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'plannedProductionDate': string;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemRepresentation
     */
    'notStarted': ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemRepresentation
     */
    'started'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemRepresentation
     */
    'finished'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemRepresentation
     */
    'failed'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemStatusCodesTransition}
     * @memberof ProductionItemRepresentation
     */
    'itemCancelled'?: ProductionItemStatusCodesTransition;
    /**
     * 
     * @type {ProductionItemOrderCancelledTransition}
     * @memberof ProductionItemRepresentation
     */
    'orderCancelled'?: ProductionItemOrderCancelledTransition;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'merk'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemRepresentation
     */
    'remark'?: string | null;
    /**
     * 
     * @type {ProductionItemStationStatus}
     * @memberof ProductionItemRepresentation
     */
    'stationStatus': ProductionItemStationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'needsNeutenBatch'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'needsLattenBatch'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'needsDorpelBatch'?: boolean | null;
    /**
     * 
     * @type {Array<ProductionBatchItemRepresentation>}
     * @memberof ProductionItemRepresentation
     */
    'batches': Array<ProductionBatchItemRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'komoCertified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'urgent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'onPallet': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'hasNeutenBatch': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'hasLattenBatch': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemRepresentation
     */
    'hasDorpelBatch': boolean;
}


        /**
 * 
 * @export
 * @interface ProductionItemRepresentationFacetedSearchResultList
 */
export interface ProductionItemRepresentationFacetedSearchResultList {
    /**
     * 
     * @type {Array<ProductionItemRepresentation>}
     * @memberof ProductionItemRepresentationFacetedSearchResultList
     */
    'hits': Array<ProductionItemRepresentation>;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemRepresentationFacetedSearchResultList
     */
    'totalHits': number;
    /**
     * 
     * @type {{ [key: string]: IFacetResult; }}
     * @memberof ProductionItemRepresentationFacetedSearchResultList
     */
    'facets': { [key: string]: IFacetResult; };
}
        /**
 * 
 * @export
 * @interface ProductionItemStationStatus
 */
export interface ProductionItemStationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'neutenGemaakt'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'neutenGemaaktTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'lattenGemaakt'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'lattenGemaaktTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'dorpelProfielGezaagd'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'dorpelProfielGezaagdTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'dorpelBewerkt'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'dorpelBewerktTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'neutenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'neutenGemonteerdTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'sluitpottenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'sluitpottenGemonteerdTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'lattenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'lattenGemonteerdTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'kopisolatorenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'kopisolatorenGemonteerdTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'manchettesGemonteerd'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'manchettesGemonteerdTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'hefschuifVouwwand'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'hefschuifVouwwandTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'ingepakt'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'ingepaktTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'custom'?: boolean | null;
    /**
     * 
     * @type {ProductionItemStationTransition}
     * @memberof ProductionItemStationStatus
     */
    'customTransition'?: ProductionItemStationTransition;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemStationStatus
     */
    'allDone': boolean;
}
        /**
 * 
 * @export
 * @interface ProductionItemStationTransition
 */
export interface ProductionItemStationTransition {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemStationTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemStationTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemStationTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ProductionItemStatusCodes = {
    NotStarted: 'notStarted',
    Started: 'started',
    Finished: 'finished',
    Cancelled: 'cancelled',
    Failed: 'failed'
} as const;

export type ProductionItemStatusCodes = typeof ProductionItemStatusCodes[keyof typeof ProductionItemStatusCodes];


        /**
 * 
 * @export
 * @interface ProductionItemStatusCodesTransition
 */
export interface ProductionItemStatusCodesTransition {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemStatusCodesTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemStatusCodesTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemStatusCodesTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @interface ProductionItemViewRepresentation
 */
export interface ProductionItemViewRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'sequence': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'orderLineId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'catalogItemId': string;
    /**
     * 
     * @type {CatalogItemType}
     * @memberof ProductionItemViewRepresentation
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'catalogItemCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'catalogItemTitle': string;
    /**
     * 
     * @type {ProductionItemStatusCodes}
     * @memberof ProductionItemViewRepresentation
     */
    'status': ProductionItemStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'plannedProductionDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemViewRepresentation
     */
    'plannedProductionWeek': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'isOrderCancelled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'merk'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'neutenGemaakt'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'dorpelProfielGezaagd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'dorpelBewerkt'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'neutenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'sluitpottenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'lattenGemonteerd'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'kopisolatorenGeplaatst'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'manchettesGeplaatst'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'hefschuifVouwwandGereed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'ingepakt'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'lattenGemaakt'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'orderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'customerCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentation
     */
    'customerCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'needsNeutenBatch'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'needsLattenBatch'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'needsDorpelBatch'?: boolean | null;
    /**
     * 
     * @type {Array<ProductionBatchItemViewRepresentation>}
     * @memberof ProductionItemViewRepresentation
     */
    'batches': Array<ProductionBatchItemViewRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'hasLattenBatch': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'hasNeutenBatch': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionItemViewRepresentation
     */
    'hasDorpelBatch': boolean;
}


        /**
 * 
 * @export
 * @interface ProductionItemViewRepresentationArrayODataValue
 */
export interface ProductionItemViewRepresentationArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentationArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemViewRepresentationArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemViewRepresentationArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<ProductionItemViewRepresentation>}
     * @memberof ProductionItemViewRepresentationArrayODataValue
     */
    'value': Array<ProductionItemViewRepresentation>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ProductionItemsOrderStatus = {
    Creating: 'creating',
    Created: 'created',
    Failed: 'failed'
} as const;

export type ProductionItemsOrderStatus = typeof ProductionItemsOrderStatus[keyof typeof ProductionItemsOrderStatus];


        /**
 * 
 * @export
 * @interface ProductionItemsSaga
 */
export interface ProductionItemsSaga {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'orderNo': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'currentState': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsSaga
     */
    'numberOfSillsInOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsSaga
     */
    'numberOfProductionItems': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsSaga
     */
    'diff': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'creating'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'deleting'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'failed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsSaga
     */
    'timeoutExpired'?: string | null;
}
        /**
 * 
 * @export
 * @interface ProductionItemsToBeManufacturedByWeekView
 */
export interface ProductionItemsToBeManufacturedByWeekView {
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'plannedProductionWeek': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'weekStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'weekEndDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'productionItemCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'sillLength': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'neutCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'lattenCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'compartmentCount': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'sillBrand': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekView
     */
    'salesTotal': number;
}
        /**
 * 
 * @export
 * @interface ProductionItemsToBeManufacturedByWeekViewArrayODataValue
 */
export interface ProductionItemsToBeManufacturedByWeekViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsToBeManufacturedByWeekViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionItemsToBeManufacturedByWeekViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionItemsToBeManufacturedByWeekViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<ProductionItemsToBeManufacturedByWeekView>}
     * @memberof ProductionItemsToBeManufacturedByWeekViewArrayODataValue
     */
    'value': Array<ProductionItemsToBeManufacturedByWeekView>;
}
        /**
 * 
 * @export
 * @interface ProductionNeutenRepresentation
 */
export interface ProductionNeutenRepresentation {
    /**
     * 
     * @type {number}
     * @memberof ProductionNeutenRepresentation
     */
    'count': number;
}
        /**
 * 
 * @export
 * @interface ProductionOrderBatchSummaryRepresentation
 */
export interface ProductionOrderBatchSummaryRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionOrderBatchSummaryRepresentation
     */
    'productionBatchId': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderBatchSummaryRepresentation
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionOrderBatchSummaryRepresentation
     */
    'bmhBatchCode': string;
    /**
     * 
     * @type {ProductionBatchStatusCodes}
     * @memberof ProductionOrderBatchSummaryRepresentation
     */
    'status': ProductionBatchStatusCodes;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderBatchSummaryRepresentation
     */
    'count': number;
}


        /**
 * 
 * @export
 * @interface ProductionOrderRepresentation
 */
export interface ProductionOrderRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionOrderRepresentation
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderRepresentation
     */
    'itemsNotStartedCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderRepresentation
     */
    'itemsStartedCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderRepresentation
     */
    'itemsFinishedCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderRepresentation
     */
    'itemsCancelledCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderRepresentation
     */
    'itemsFailedCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionOrderRepresentation
     */
    'batchesCount': number;
    /**
     * 
     * @type {Array<ProductionOrderBatchSummaryRepresentation>}
     * @memberof ProductionOrderRepresentation
     */
    'batches': Array<ProductionOrderBatchSummaryRepresentation>;
}
        /**
 * 
 * @export
 * @interface ProductionSillRepresentation
 */
export interface ProductionSillRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProductionSillRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSillRepresentation
     */
    'sillCode': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSillRepresentation
     */
    'totalLength': number;
    /**
     * 
     * @type {ProductionNeutenRepresentation}
     * @memberof ProductionSillRepresentation
     */
    'neuten': ProductionNeutenRepresentation;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ProductionStations = {
    Dorpels: 'dorpels',
    SawMaster: 'sawMaster',
    Wm50: 'wm50',
    NeutMontage: 'neutMontage',
    Specials: 'specials',
    Sluitpot: 'sluitpot',
    Hefschuif: 'hefschuif',
    Kopisolator: 'kopisolator',
    Manchette: 'manchette',
    Latten: 'latten',
    Inpak: 'inpak'
} as const;

export type ProductionStations = typeof ProductionStations[keyof typeof ProductionStations];


        /**
 * 
 * @export
 * @enum {string}
 */

export const ProductionStatusCodes = {
    NotStarted: 'notStarted',
    Started: 'started',
    Finished: 'finished',
    Cancelled: 'cancelled',
    Failed: 'failed'
} as const;

export type ProductionStatusCodes = typeof ProductionStatusCodes[keyof typeof ProductionStatusCodes];


        /**
 * 
 * @export
 * @interface ProfileInfo
 */
export interface ProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileInfo
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProfileInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ProfileInfo
     */
    'sillWidth': number;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'svgPreview'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileInfo
     */
    'hasProgram': boolean;
}
        /**
 * 
 * @export
 * @interface ProfileSearchResult
 */
export interface ProfileSearchResult {
    /**
     * 
     * @type {number}
     * @memberof ProfileSearchResult
     */
    'totalHits': number;
    /**
     * 
     * @type {{ [key: string]: IFacetResult; }}
     * @memberof ProfileSearchResult
     */
    'facets': { [key: string]: IFacetResult; };
    /**
     * 
     * @type {Array<IProfileInfo>}
     * @memberof ProfileSearchResult
     */
    'profiles': Array<IProfileInfo>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ProfileType = {
    Contra: 'contra',
    Compartment: 'compartment',
    Wall: 'wall'
} as const;

export type ProfileType = typeof ProfileType[keyof typeof ProfileType];


        /**
 * 
 * @export
 * @interface ProfileValue
 */
export interface ProfileValue {
    /**
     * 
     * @type {number}
     * @memberof ProfileValue
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface PutAcceptOrderRepresentation
 */
export interface PutAcceptOrderRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutAcceptOrderRepresentation
     */
    'plannedProductionDate': string;
}
        /**
 * 
 * @export
 * @interface PutConfigurationOrderLineRepresentation
 */
export interface PutConfigurationOrderLineRepresentation extends PutQuantityOrderLineRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutConfigurationOrderLineRepresentation
     */
    'catalogItemId': string;
    /**
     * 
     * @type {string}
     * @memberof PutConfigurationOrderLineRepresentation
     */
    'merk'?: string | null;
}


        /**
 * 
 * @export
 * @interface PutContactPersonRepresentation
 */
export interface PutContactPersonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutContactPersonRepresentation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PutContactPersonRepresentation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PutContactPersonRepresentation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PutContactPersonRepresentation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutContactPersonRepresentation
     */
    'updateExpedition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutContactPersonRepresentation
     */
    'updateFinancial': boolean;
}
        /**
 * 
 * @export
 * @interface PutCustomerAddressRepresentation
 */
export interface PutCustomerAddressRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'attention'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'street': string;
    /**
     * 
     * @type {number}
     * @memberof PutCustomerAddressRepresentation
     */
    'houseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'houseNumberAddition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'extraAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'country': string;
    /**
     * 
     * @type {ContactPersonRepresentation}
     * @memberof PutCustomerAddressRepresentation
     */
    'contactPerson': ContactPersonRepresentation;
    /**
     * 
     * @type {boolean}
     * @memberof PutCustomerAddressRepresentation
     */
    'isInvoicingAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutCustomerAddressRepresentation
     */
    'isDeliveryAddress': boolean;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerAddressRepresentation
     */
    'customerId': string;
}
        /**
 * 
 * @export
 * @interface PutCustomerRepresentation
 */
export interface PutCustomerRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'cocNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'cocDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'vatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof PutCustomerRepresentation
     */
    'debtorNumber'?: number | null;
    /**
     * 
     * @type {PaymentConditions}
     * @memberof PutCustomerRepresentation
     */
    'paymentConditions': PaymentConditions;
    /**
     * 
     * @type {DeliveryConditions}
     * @memberof PutCustomerRepresentation
     */
    'deliveryConditions': DeliveryConditions;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'catalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'priceListId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutCustomerRepresentation
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutCustomerRepresentation
     */
    'waiveTransportCosts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutCustomerRepresentation
     */
    'gaImportEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof PutCustomerRepresentation
     */
    'id': string;
}


        /**
 * 
 * @export
 * @interface PutExpeditionConditionRepresentation
 */
export interface PutExpeditionConditionRepresentation {
    /**
     * 
     * @type {DeliveryConditions}
     * @memberof PutExpeditionConditionRepresentation
     */
    'expeditionCondition': DeliveryConditions;
}


        /**
 * 
 * @export
 * @interface PutExpeditionDateRepresentation
 */
export interface PutExpeditionDateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutExpeditionDateRepresentation
     */
    'expeditionDate'?: string | null;
}
        /**
 * 
 * @export
 * @interface PutExpeditionDeliveredRepresentation
 */
export interface PutExpeditionDeliveredRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutExpeditionDeliveredRepresentation
     */
    'deliveredDate': string;
}
        /**
 * 
 * @export
 * @interface PutOrderDateRepresentation
 */
export interface PutOrderDateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutOrderDateRepresentation
     */
    'orderDate'?: string | null;
}
        /**
 * 
 * @export
 * @interface PutOrderExpeditionRepresentation
 */
export interface PutOrderExpeditionRepresentation {
    /**
     * 
     * @type {OrderAddressRepresentation}
     * @memberof PutOrderExpeditionRepresentation
     */
    'deliveryAddress'?: OrderAddressRepresentation;
    /**
     * 
     * @type {OrderContactPersonRepresentation}
     * @memberof PutOrderExpeditionRepresentation
     */
    'contactPerson'?: OrderContactPersonRepresentation;
    /**
     * 
     * @type {PutOrderReferenceRepresentation}
     * @memberof PutOrderExpeditionRepresentation
     */
    'reference'?: PutOrderReferenceRepresentation;
    /**
     * 
     * @type {string}
     * @memberof PutOrderExpeditionRepresentation
     */
    'preferredExpeditionDate'?: string | null;
    /**
     * 
     * @type {DeliveryConditions}
     * @memberof PutOrderExpeditionRepresentation
     */
    'deliveryCondition': DeliveryConditions;
}


        /**
 * 
 * @export
 * @interface PutOrderFinancialRepresentation
 */
export interface PutOrderFinancialRepresentation {
    /**
     * 
     * @type {OrderAddressRepresentation}
     * @memberof PutOrderFinancialRepresentation
     */
    'invoiceAddress': OrderAddressRepresentation;
    /**
     * 
     * @type {OrderContactPersonRepresentation}
     * @memberof PutOrderFinancialRepresentation
     */
    'contactPerson': OrderContactPersonRepresentation;
}
        /**
 * 
 * @export
 * @interface PutOrderLineRepresentation
 */
export interface PutOrderLineRepresentation {
    /**
     * 
     * @type {OrderLineType}
     * @memberof PutOrderLineRepresentation
     */
    'orderlineType': OrderLineType;
    /**
     * 
     * @type {string}
     * @memberof PutOrderLineRepresentation
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutOrderLineRepresentation
     */
    'title'?: string | null;
}


        /**
 * 
 * @export
 * @interface PutOrderReferenceRepresentation
 */
export interface PutOrderReferenceRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutOrderReferenceRepresentation
     */
    'reference': string;
}
        /**
 * 
 * @export
 * @interface PutOrderUrgentRepresentation
 */
export interface PutOrderUrgentRepresentation {
    /**
     * 
     * @type {boolean}
     * @memberof PutOrderUrgentRepresentation
     */
    'urgent': boolean;
}
        /**
 * 
 * @export
 * @interface PutPaymentConditionRepresentation
 */
export interface PutPaymentConditionRepresentation {
    /**
     * 
     * @type {PaymentConditions}
     * @memberof PutPaymentConditionRepresentation
     */
    'paymentCondition': PaymentConditions;
}


        /**
 * 
 * @export
 * @interface PutPlannedExpeditionDateRepresentation
 */
export interface PutPlannedExpeditionDateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutPlannedExpeditionDateRepresentation
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutPlannedExpeditionDateRepresentation
     */
    'updateStateToPlanned': boolean;
}
        /**
 * 
 * @export
 * @interface PutPlannedProductionDateRepresentation
 */
export interface PutPlannedProductionDateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutPlannedProductionDateRepresentation
     */
    'plannedProductionDate'?: string | null;
}
        /**
 * 
 * @export
 * @interface PutPriceableOrderLineRepresentation
 */
export interface PutPriceableOrderLineRepresentation extends PutQuantityOrderLineRepresentation {
    /**
     * 
     * @type {number}
     * @memberof PutPriceableOrderLineRepresentation
     */
    'price'?: number | null;
}


        /**
 * 
 * @export
 * @interface PutPricelistRepresentation
 */
export interface PutPricelistRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutPricelistRepresentation
     */
    'priceListId': string;
}
        /**
 * 
 * @export
 * @interface PutProductOrderLineRepresentation
 */
export interface PutProductOrderLineRepresentation extends PutPriceableOrderLineRepresentation {
}


        /**
 * 
 * @export
 * @interface PutProductionBatchRemarkRepresentation
 */
export interface PutProductionBatchRemarkRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutProductionBatchRemarkRepresentation
     */
    'remark': string;
}
        /**
 * 
 * @export
 * @interface PutProductionBatchTitleRepresentation
 */
export interface PutProductionBatchTitleRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutProductionBatchTitleRepresentation
     */
    'title': string;
}
        /**
 * 
 * @export
 * @interface PutProductionBatchTypeRepresentation
 */
export interface PutProductionBatchTypeRepresentation {
    /**
     * 
     * @type {ProductionBatchTypes}
     * @memberof PutProductionBatchTypeRepresentation
     */
    'type': ProductionBatchTypes;
}


        /**
 * 
 * @export
 * @interface PutProductionItemDateRepresentation
 */
export interface PutProductionItemDateRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemDateRepresentation
     */
    'productionItemIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PutProductionItemDateRepresentation
     */
    'date': string;
}
        /**
 * 
 * @export
 * @interface PutProductionItemIndexRepresentation
 */
export interface PutProductionItemIndexRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemIndexRepresentation
     */
    'productionItemIds': Array<string>;
}
        /**
 * 
 * @export
 * @interface PutProductionItemInstructionsRepresentation
 */
export interface PutProductionItemInstructionsRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemInstructionsRepresentation
     */
    'productionItemIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PutProductionItemInstructionsRepresentation
     */
    'instructions': string;
}
        /**
 * 
 * @export
 * @interface PutProductionItemKomoCertifiedRepresentation
 */
export interface PutProductionItemKomoCertifiedRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemKomoCertifiedRepresentation
     */
    'productionItemIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductionItemKomoCertifiedRepresentation
     */
    'komoCertified': boolean;
}
        /**
 * 
 * @export
 * @interface PutProductionItemOnPalletRepresentation
 */
export interface PutProductionItemOnPalletRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemOnPalletRepresentation
     */
    'productionItemIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductionItemOnPalletRepresentation
     */
    'onPallet': boolean;
}
        /**
 * 
 * @export
 * @interface PutProductionItemOperationBmhBarCodeRepresentation
 */
export interface PutProductionItemOperationBmhBarCodeRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutProductionItemOperationBmhBarCodeRepresentation
     */
    'bmhBarCode': string;
    /**
     * 
     * @type {ProductionItemOperations}
     * @memberof PutProductionItemOperationBmhBarCodeRepresentation
     */
    'operation': ProductionItemOperations;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductionItemOperationBmhBarCodeRepresentation
     */
    'value': boolean;
}


        /**
 * 
 * @export
 * @interface PutProductionItemOperationCodeRepresentation
 */
export interface PutProductionItemOperationCodeRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutProductionItemOperationCodeRepresentation
     */
    'code': string;
    /**
     * 
     * @type {ProductionItemOperations}
     * @memberof PutProductionItemOperationCodeRepresentation
     */
    'operation': ProductionItemOperations;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductionItemOperationCodeRepresentation
     */
    'value': boolean;
}


        /**
 * 
 * @export
 * @interface PutProductionItemOperationCodeSequenceRepresentation
 */
export interface PutProductionItemOperationCodeSequenceRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutProductionItemOperationCodeSequenceRepresentation
     */
    'codeSequence': string;
    /**
     * 
     * @type {ProductionItemOperations}
     * @memberof PutProductionItemOperationCodeSequenceRepresentation
     */
    'operation': ProductionItemOperations;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductionItemOperationCodeSequenceRepresentation
     */
    'value': boolean;
}


        /**
 * 
 * @export
 * @interface PutProductionItemOperationRepresentation
 */
export interface PutProductionItemOperationRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemOperationRepresentation
     */
    'productionItemIds': Array<string>;
    /**
     * 
     * @type {ProductionItemOperations}
     * @memberof PutProductionItemOperationRepresentation
     */
    'operation': ProductionItemOperations;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductionItemOperationRepresentation
     */
    'value'?: boolean | null;
}


        /**
 * 
 * @export
 * @interface PutProductionItemRemarkRepresentation
 */
export interface PutProductionItemRemarkRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemRemarkRepresentation
     */
    'productionItemIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PutProductionItemRemarkRepresentation
     */
    'remark': string;
}
        /**
 * 
 * @export
 * @interface PutProductionItemStatusRepresentation
 */
export interface PutProductionItemStatusRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemStatusRepresentation
     */
    'productionItemIds': Array<string>;
}
        /**
 * 
 * @export
 * @interface PutProductionItemUrgentRepresentation
 */
export interface PutProductionItemUrgentRepresentation {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductionItemUrgentRepresentation
     */
    'productionItemIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductionItemUrgentRepresentation
     */
    'urgent': boolean;
}
        /**
 * 
 * @export
 * @interface PutProjectDiscountRepresentation
 */
export interface PutProjectDiscountRepresentation {
    /**
     * 
     * @type {number}
     * @memberof PutProjectDiscountRepresentation
     */
    'projectDiscount': number;
}
        /**
 * 
 * @export
 * @interface PutQuantityOrderLineRepresentation
 */
export interface PutQuantityOrderLineRepresentation extends PutOrderLineRepresentation {
    /**
     * 
     * @type {number}
     * @memberof PutQuantityOrderLineRepresentation
     */
    'quantity'?: number | null;
}


        /**
 * 
 * @export
 * @interface PutQuoteExpiryDateRepresentation
 */
export interface PutQuoteExpiryDateRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutQuoteExpiryDateRepresentation
     */
    'expiryDate'?: string | null;
}
        /**
 * 
 * @export
 * @interface PutShippingCostOrderLineRepresentation
 */
export interface PutShippingCostOrderLineRepresentation extends PutPriceableOrderLineRepresentation {
}


        /**
 * 
 * @export
 * @interface PutTextOrderLineRepresentation
 */
export interface PutTextOrderLineRepresentation extends PutPriceableOrderLineRepresentation {
}


        /**
 * 
 * @export
 * @interface PutWizardOrderRemarkRepresentation
 */
export interface PutWizardOrderRemarkRepresentation {
    /**
     * 
     * @type {string}
     * @memberof PutWizardOrderRemarkRepresentation
     */
    'remark'?: string | null;
}
        /**
 * 
 * @export
 * @interface Rabbet
 */
export interface Rabbet {
    /**
     * 
     * @type {string}
     * @memberof Rabbet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Rabbet
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof Rabbet
     */
    'active': boolean;
    /**
     * 
     * @type {RabbetType}
     * @memberof Rabbet
     */
    'type': RabbetType;
    /**
     * 
     * @type {RabbetApplication}
     * @memberof Rabbet
     */
    'application'?: RabbetApplication;
    /**
     * 
     * @type {string}
     * @memberof Rabbet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Rabbet
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof Rabbet
     */
    'svgPreview': string;
    /**
     * 
     * @type {number}
     * @memberof Rabbet
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Rabbet
     */
    'depth': number;
    /**
     * 
     * @type {Array<CompartmentLayoutTypeReference>}
     * @memberof Rabbet
     */
    'compartmentLayoutTypes': Array<CompartmentLayoutTypeReference>;
    /**
     * 
     * @type {TurboholRabbetReference}
     * @memberof Rabbet
     */
    'turboholRabbet'?: TurboholRabbetReference;
    /**
     * 
     * @type {HefSchuifGroove}
     * @memberof Rabbet
     */
    'groove'?: HefSchuifGroove;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const RabbetApplication = {
    Inside: 'inside',
    Outside: 'outside',
    InsideAndOutside: 'insideAndOutside'
} as const;

export type RabbetApplication = typeof RabbetApplication[keyof typeof RabbetApplication];


        /**
 * 
 * @export
 * @interface RabbetInfo
 */
export interface RabbetInfo {
    /**
     * 
     * @type {string}
     * @memberof RabbetInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RabbetInfo
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof RabbetInfo
     */
    'name': string;
    /**
     * 
     * @type {RabbetType}
     * @memberof RabbetInfo
     */
    'rabbetType': RabbetType;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof RabbetInfo
     */
    'rabbetPosition': RabbetPosition;
    /**
     * 
     * @type {number}
     * @memberof RabbetInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof RabbetInfo
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof RabbetInfo
     */
    'rabbetSize': string;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const RabbetPosition = {
    Outside: 'outside',
    Inside: 'inside'
} as const;

export type RabbetPosition = typeof RabbetPosition[keyof typeof RabbetPosition];


        /**
 * 
 * @export
 * @enum {string}
 */

export const RabbetType = {
    Stomp: 'stomp',
    DraaiKiep: 'draaiKiep',
    HefSchuif: 'hefSchuif',
    Groef: 'groef',
    HoekProfile: 'hoekProfile',
    DubbeleSponning: 'dubbeleSponning',
    BinnendraaiendStomp: 'binnendraaiendStomp',
    VlakkedeurSponning: 'vlakkedeurSponning'
} as const;

export type RabbetType = typeof RabbetType[keyof typeof RabbetType];


        /**
 * 
 * @export
 * @interface RabbetUpdateModel
 */
export interface RabbetUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof RabbetUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof RabbetUpdateModel
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof RabbetUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {RabbetApplication}
     * @memberof RabbetUpdateModel
     */
    'application'?: RabbetApplication;
    /**
     * 
     * @type {string}
     * @memberof RabbetUpdateModel
     */
    'geometry': string;
    /**
     * 
     * @type {number}
     * @memberof RabbetUpdateModel
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof RabbetUpdateModel
     */
    'depth': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RabbetUpdateModel
     */
    'compartmentLayoutTypeIds': Array<string>;
    /**
     * 
     * @type {TurboholRabbet}
     * @memberof RabbetUpdateModel
     */
    'turboholRabbet'?: TurboholRabbet;
    /**
     * 
     * @type {HefSchuifGroove}
     * @memberof RabbetUpdateModel
     */
    'groove'?: HefSchuifGroove;
}


        /**
 * 
 * @export
 * @interface RabbetWidthBreakpoint
 */
export interface RabbetWidthBreakpoint {
    /**
     * 
     * @type {number}
     * @memberof RabbetWidthBreakpoint
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof RabbetWidthBreakpoint
     */
    'to'?: number;
    /**
     * 
     * @type {number}
     * @memberof RabbetWidthBreakpoint
     */
    'x'?: number;
}
        /**
 * 
 * @export
 * @interface RecalculateConfiguredSillResponse
 */
export interface RecalculateConfiguredSillResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RecalculateConfiguredSillResponse
     */
    'isDifferent': boolean;
    /**
     * 
     * @type {ConfiguredSill}
     * @memberof RecalculateConfiguredSillResponse
     */
    'sill': ConfiguredSill;
    /**
     * 
     * @type {string}
     * @memberof RecalculateConfiguredSillResponse
     */
    'svg': string;
    /**
     * 
     * @type {string}
     * @memberof RecalculateConfiguredSillResponse
     */
    'hash': string;
    /**
     * 
     * @type {ConfiguredSill}
     * @memberof RecalculateConfiguredSillResponse
     */
    'recalculatedSill': ConfiguredSill;
    /**
     * 
     * @type {string}
     * @memberof RecalculateConfiguredSillResponse
     */
    'recalculatedSvg': string;
    /**
     * 
     * @type {string}
     * @memberof RecalculateConfiguredSillResponse
     */
    'recalculatedHash': string;
    /**
     * 
     * @type {string}
     * @memberof RecalculateConfiguredSillResponse
     */
    'diff'?: string | null;
}
        /**
 * @type RenderPreviewWallConnectionPostRequest
 * @export
 */
export type RenderPreviewWallConnectionPostRequest = AngledWallConnection | CustomProfileWallConnection | StandardWallConnection;

        /**
 * 
 * @export
 * @interface RenovationRabbet
 */
export interface RenovationRabbet {
    /**
     * 
     * @type {Array<RenovationRabbetStep>}
     * @memberof RenovationRabbet
     */
    'steps': Array<RenovationRabbetStep>;
    /**
     * 
     * @type {number}
     * @memberof RenovationRabbet
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof RenovationRabbet
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const RenovationRabbetSide = {
    Inside: 'inside',
    Outside: 'outside'
} as const;

export type RenovationRabbetSide = typeof RenovationRabbetSide[keyof typeof RenovationRabbetSide];


        /**
 * 
 * @export
 * @interface RenovationRabbetStep
 */
export interface RenovationRabbetStep {
    /**
     * 
     * @type {number}
     * @memberof RenovationRabbetStep
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof RenovationRabbetStep
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @interface ResetImportValidationCommand
 */
export interface ResetImportValidationCommand extends ResolveImportValidationCommand {
}


        /**
 * 
 * @export
 * @interface ResetPriceCommand
 */
export interface ResetPriceCommand extends PriceCommand {
    /**
     * 
     * @type {string}
     * @memberof ResetPriceCommand
     */
    'priceListId': string;
}


        /**
 * 
 * @export
 * @interface ResolveCompartmentProfileMissingImportValidationCommand
 */
export interface ResolveCompartmentProfileMissingImportValidationCommand extends ResolveImportValidationWithApplyToAllCommand {
    /**
     * 
     * @type {string}
     * @memberof ResolveCompartmentProfileMissingImportValidationCommand
     */
    'profileId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResolveCompartmentProfileMissingImportValidationCommand
     */
    'saveForCustomer': boolean;
    /**
     * 
     * @type {MappingKind}
     * @memberof ResolveCompartmentProfileMissingImportValidationCommand
     */
    'saveForCustomerMappingKind'?: MappingKind;
}


        /**
 * 
 * @export
 * @interface ResolveCompartmentProfileSuggestionValidationCommand
 */
export interface ResolveCompartmentProfileSuggestionValidationCommand extends ResolveImportValidationCommand {
    /**
     * 
     * @type {string}
     * @memberof ResolveCompartmentProfileSuggestionValidationCommand
     */
    'profileId': string;
}


        /**
 * 
 * @export
 * @interface ResolveCompartmentProfilesNotEqualImportValidationCommand
 */
export interface ResolveCompartmentProfilesNotEqualImportValidationCommand extends ResolveImportValidationCommand {
    /**
     * 
     * @type {string}
     * @memberof ResolveCompartmentProfilesNotEqualImportValidationCommand
     */
    'profileId': string;
}


        /**
 * 
 * @export
 * @interface ResolveImportValidationCommand
 */
export interface ResolveImportValidationCommand {
    /**
     * 
     * @type {ResolveImportValidationCommandType}
     * @memberof ResolveImportValidationCommand
     */
    'commandType': ResolveImportValidationCommandType;
    /**
     * 
     * @type {string}
     * @memberof ResolveImportValidationCommand
     */
    'id': string;
    /**
     * 
     * @type {ImportType}
     * @memberof ResolveImportValidationCommand
     */
    'importType': ImportType;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ResolveImportValidationCommandType = {
    IgnoreImportValidationCommand: 'ignoreImportValidationCommand',
    ResolveSillImportValidationMappingCommand: 'resolveSillImportValidationMappingCommand',
    ResolveSluitpotImportValidationMappingCommand: 'resolveSluitpotImportValidationMappingCommand',
    ResetImportValidationCommand: 'resetImportValidationCommand',
    ResolveCompartmentProfileMissingImportValidationCommand: 'resolveCompartmentProfileMissingImportValidationCommand',
    ResolveCompartmentProfilesNotEqualImportValidationCommand: 'resolveCompartmentProfilesNotEqualImportValidationCommand',
    ResolveCompartmentProfileSuggestionValidationCommand: 'resolveCompartmentProfileSuggestionValidationCommand'
} as const;

export type ResolveImportValidationCommandType = typeof ResolveImportValidationCommandType[keyof typeof ResolveImportValidationCommandType];


        /**
 * 
 * @export
 * @interface ResolveImportValidationWithApplyToAllCommand
 */
export interface ResolveImportValidationWithApplyToAllCommand extends ResolveImportValidationCommand {
    /**
     * 
     * @type {boolean}
     * @memberof ResolveImportValidationWithApplyToAllCommand
     */
    'applyToAll': boolean;
}


        /**
 * 
 * @export
 * @interface ResolveSillImportValidationMappingCommand
 */
export interface ResolveSillImportValidationMappingCommand extends ResolveImportValidationWithApplyToAllCommand {
    /**
     * 
     * @type {string}
     * @memberof ResolveSillImportValidationMappingCommand
     */
    'sillId': string;
}


        /**
 * 
 * @export
 * @interface ResolveSluitpotImportValidationMappingCommand
 */
export interface ResolveSluitpotImportValidationMappingCommand extends ResolveImportValidationWithApplyToAllCommand {
    /**
     * 
     * @type {string}
     * @memberof ResolveSluitpotImportValidationMappingCommand
     */
    'externalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ResolveSluitpotImportValidationMappingCommand
     */
    'sluitpotBeslagId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResolveSluitpotImportValidationMappingCommand
     */
    'saveForCustomer': boolean;
}


        /**
 * 
 * @export
 * @interface SDefaultProfileHefSchuifsAde
 */
export interface SDefaultProfileHefSchuifsAde {
    /**
     * 
     * @type {string}
     * @memberof SDefaultProfileHefSchuifsAde
     */
    'inside': string;
    /**
     * 
     * @type {string}
     * @memberof SDefaultProfileHefSchuifsAde
     */
    'outside': string;
}
        /**
 * 
 * @export
 * @interface SaveConfiguratorResponse
 */
export interface SaveConfiguratorResponse {
    /**
     * 
     * @type {string}
     * @memberof SaveConfiguratorResponse
     */
    'catalogItemId': string;
    /**
     * 
     * @type {string}
     * @memberof SaveConfiguratorResponse
     */
    'title': string;
}
        /**
 * 
 * @export
 * @interface SbomLineModel
 */
export interface SbomLineModel {
    /**
     * 
     * @type {string}
     * @memberof SbomLineModel
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof SbomLineModel
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof SbomLineModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof SbomLineModel
     */
    'total': number;
    /**
     * 
     * @type {CatalogItemSbomReference}
     * @memberof SbomLineModel
     */
    'catalogItem'?: CatalogItemSbomReference;
}
        /**
 * 
 * @export
 * @interface SbomModel
 */
export interface SbomModel {
    /**
     * 
     * @type {Array<SbomLineModel>}
     * @memberof SbomModel
     */
    'lines': Array<SbomLineModel>;
}
        /**
 * 
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {Point}
     * @memberof Segment
     */
    'startPoint': Point;
    /**
     * 
     * @type {Point}
     * @memberof Segment
     */
    'endPoint': Point;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    'minY': number;
    /**
     * 
     * @type {BoundingBox}
     * @memberof Segment
     */
    'boundingBox': BoundingBox;
}
        /**
 * 
 * @export
 * @interface SetDefaultCompartmentProfileHefSchuifAde
 */
export interface SetDefaultCompartmentProfileHefSchuifAde {
    /**
     * 
     * @type {SDefaultProfileHefSchuifsAde}
     * @memberof SetDefaultCompartmentProfileHefSchuifAde
     */
    'profileIds': SDefaultProfileHefSchuifsAde;
}
        /**
 * 
 * @export
 * @interface SetDefaultCompartmentProfileHefSchuifC
 */
export interface SetDefaultCompartmentProfileHefSchuifC {
    /**
     * 
     * @type {string}
     * @memberof SetDefaultCompartmentProfileHefSchuifC
     */
    'outsideProfileId': string;
}
        /**
 * @type SetDefaultCompartmentProfileRequest
 * @export
 */
export type SetDefaultCompartmentProfileRequest = SetDefaultCompartmentProfileHefSchuifAde | SetDefaultCompartmentProfileHefSchuifC | SetDefaultCompartmentProfileStandard;

        /**
 * 
 * @export
 * @interface SetDefaultCompartmentProfileStandard
 */
export interface SetDefaultCompartmentProfileStandard {
    /**
     * 
     * @type {string}
     * @memberof SetDefaultCompartmentProfileStandard
     */
    'profileId': string;
}
        /**
 * 
 * @export
 * @interface SetDefaultWallConnection
 */
export interface SetDefaultWallConnection {
    /**
     * 
     * @type {RenderPreviewWallConnectionPostRequest}
     * @memberof SetDefaultWallConnection
     */
    'wallConnection': RenderPreviewWallConnectionPostRequest;
}
        /**
 * 
 * @export
 * @interface ShipmentAddressRepresentation
 */
export interface ShipmentAddressRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'attention'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'street': string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentAddressRepresentation
     */
    'houseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'houseNumberAddition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'extraAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentAddressRepresentation
     */
    'country': string;
}
        /**
 * 
 * @export
 * @interface ShipmentContactPersonRepresentation
 */
export interface ShipmentContactPersonRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ShipmentContactPersonRepresentation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentContactPersonRepresentation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentContactPersonRepresentation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentContactPersonRepresentation
     */
    'telephone'?: string | null;
}
        /**
 * 
 * @export
 * @interface ShipmentDeliveryDetailsRepresentation
 */
export interface ShipmentDeliveryDetailsRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ShipmentDeliveryDetailsRepresentation
     */
    'orderId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentDeliveryDetailsRepresentation
     */
    'delivery'?: boolean | null;
    /**
     * 
     * @type {ShipmentAddressRepresentation}
     * @memberof ShipmentDeliveryDetailsRepresentation
     */
    'address'?: ShipmentAddressRepresentation;
    /**
     * 
     * @type {ShipmentContactPersonRepresentation}
     * @memberof ShipmentDeliveryDetailsRepresentation
     */
    'contactPerson'?: ShipmentContactPersonRepresentation;
}
        /**
 * 
 * @export
 * @interface ShipmentRepresentation
 */
export interface ShipmentRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ShipmentRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentRepresentation
     */
    'expeditionId': string;
    /**
     * 
     * @type {OrderReference}
     * @memberof ShipmentRepresentation
     */
    'order': OrderReference;
    /**
     * 
     * @type {CustomerReference}
     * @memberof ShipmentRepresentation
     */
    'customer': CustomerReference;
    /**
     * 
     * @type {ShipmentStatusCodes}
     * @memberof ShipmentRepresentation
     */
    'status': ShipmentStatusCodes;
    /**
     * 
     * @type {ShipmentStatusTransition}
     * @memberof ShipmentRepresentation
     */
    'notPlanned': ShipmentStatusTransition;
    /**
     * 
     * @type {ShipmentStatusTransition}
     * @memberof ShipmentRepresentation
     */
    'planned'?: ShipmentStatusTransition;
    /**
     * 
     * @type {ShipmentStatusTransition}
     * @memberof ShipmentRepresentation
     */
    'inTransit'?: ShipmentStatusTransition;
    /**
     * 
     * @type {ShipmentStatusTransition}
     * @memberof ShipmentRepresentation
     */
    'delivered'?: ShipmentStatusTransition;
    /**
     * 
     * @type {ShipmentStatusTransition}
     * @memberof ShipmentRepresentation
     */
    'cancelled'?: ShipmentStatusTransition;
    /**
     * 
     * @type {DeliverySignatureRepresentation}
     * @memberof ShipmentRepresentation
     */
    'signature'?: DeliverySignatureRepresentation;
    /**
     * 
     * @type {Array<DeliveryPhotoRepresentation>}
     * @memberof ShipmentRepresentation
     */
    'photos': Array<DeliveryPhotoRepresentation>;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ShipmentStatusCodes = {
    NotPlanned: 'notPlanned',
    Planned: 'planned',
    InTransit: 'inTransit',
    Delivered: 'delivered',
    Cancelled: 'cancelled'
} as const;

export type ShipmentStatusCodes = typeof ShipmentStatusCodes[keyof typeof ShipmentStatusCodes];


        /**
 * 
 * @export
 * @interface ShipmentStatusTransition
 */
export interface ShipmentStatusTransition {
    /**
     * 
     * @type {string}
     * @memberof ShipmentStatusTransition
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentStatusTransition
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentStatusTransition
     */
    'userDisplayName': string;
}
        /**
 * 
 * @export
 * @interface SillAerationAndDrainageHole
 */
export interface SillAerationAndDrainageHole {
    /**
     * 
     * @type {number}
     * @memberof SillAerationAndDrainageHole
     */
    'diameter': number;
    /**
     * 
     * @type {number}
     * @memberof SillAerationAndDrainageHole
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof SillAerationAndDrainageHole
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @interface SillAerationAndDrainageOptions
 */
export interface SillAerationAndDrainageOptions {
    /**
     * 
     * @type {number}
     * @memberof SillAerationAndDrainageOptions
     */
    'maxSpacing': number;
    /**
     * 
     * @type {boolean}
     * @memberof SillAerationAndDrainageOptions
     */
    'drainageHolesMandatory'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SillAerationAndDrainageOptions
     */
    'aerationHolesMandatory'?: boolean | null;
    /**
     * 
     * @type {SillAerationAndDrainageHole}
     * @memberof SillAerationAndDrainageOptions
     */
    'holeTop'?: SillAerationAndDrainageHole;
    /**
     * 
     * @type {SillAerationAndDrainageHole}
     * @memberof SillAerationAndDrainageOptions
     */
    'holeFront': SillAerationAndDrainageHole;
}
        /**
 * 
 * @export
 * @interface SillAssemblyOption
 */
export interface SillAssemblyOption {
    /**
     * 
     * @type {SillAssemblyWrapOption}
     * @memberof SillAssemblyOption
     */
    'wrap': SillAssemblyWrapOption;
    /**
     * 
     * @type {Array<SillAssemblyOptionMountingOption>}
     * @memberof SillAssemblyOption
     */
    'mountingOptions': Array<SillAssemblyOptionMountingOption>;
}


        /**
 * 
 * @export
 * @interface SillAssemblyOptionDrillHole
 */
export interface SillAssemblyOptionDrillHole {
    /**
     * 
     * @type {string}
     * @memberof SillAssemblyOptionDrillHole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillAssemblyOptionDrillHole
     */
    'code': string;
}
        /**
 * 
 * @export
 * @interface SillAssemblyOptionMountingOption
 */
export interface SillAssemblyOptionMountingOption {
    /**
     * 
     * @type {string}
     * @memberof SillAssemblyOptionMountingOption
     */
    'mountingOptionId': string;
    /**
     * 
     * @type {SillAssemblyOptionDrillHole}
     * @memberof SillAssemblyOptionMountingOption
     */
    'drillHole'?: SillAssemblyOptionDrillHole;
}
        /**
 * 
 * @export
 * @interface SillAssemblyOptions
 */
export interface SillAssemblyOptions {
    /**
     * 
     * @type {SillAssemblyOption}
     * @memberof SillAssemblyOptions
     */
    'preAssembly'?: SillAssemblyOption;
    /**
     * 
     * @type {SillAssemblyOption}
     * @memberof SillAssemblyOptions
     */
    'postAssembly'?: SillAssemblyOption;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const SillAssemblyWrapOption = {
    Yes: 'yes',
    No: 'no',
    Optional: 'optional'
} as const;

export type SillAssemblyWrapOption = typeof SillAssemblyWrapOption[keyof typeof SillAssemblyWrapOption];


        /**
 * 
 * @export
 * @interface SillCompartmentLayoutHefSchuifAEInput
 */
export interface SillCompartmentLayoutHefSchuifAEInput extends SillCompartmentLayoutInput {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof SillCompartmentLayoutHefSchuifAEInput
     */
    'defaultSlidingDirection': HefSchuifSlidingDirection;
}
        /**
 * 
 * @export
 * @interface SillCompartmentLayoutHefSchuifDInput
 */
export interface SillCompartmentLayoutHefSchuifDInput extends SillCompartmentLayoutInput {
    /**
     * 
     * @type {HefSchuifInnerDoorPosition}
     * @memberof SillCompartmentLayoutHefSchuifDInput
     */
    'defaultInnerDoorPosition': HefSchuifInnerDoorPosition;
}
        /**
 * 
 * @export
 * @interface SillCompartmentLayoutInput
 */
export interface SillCompartmentLayoutInput {
    /**
     * 
     * @type {string}
     * @memberof SillCompartmentLayoutInput
     */
    '$type': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const SillCompartmentLayoutKind = {
    Standard: 'standard',
    HefSchuif: 'hefSchuif'
} as const;

export type SillCompartmentLayoutKind = typeof SillCompartmentLayoutKind[keyof typeof SillCompartmentLayoutKind];


        /**
 * 
 * @export
 * @interface SillCompartmentLayoutTypeOption
 */
export interface SillCompartmentLayoutTypeOption {
    /**
     * 
     * @type {string}
     * @memberof SillCompartmentLayoutTypeOption
     */
    'id': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof SillCompartmentLayoutTypeOption
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof SillCompartmentLayoutTypeOption
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof SillCompartmentLayoutTypeOption
     */
    'isInUse': boolean;
}


        /**
 * 
 * @export
 * @interface SillConfiguration
 */
export interface SillConfiguration {
    /**
     * 
     * @type {string}
     * @memberof SillConfiguration
     */
    'neutPositionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SillConfiguration
     */
    'kopIsolator': boolean;
    /**
     * 
     * @type {Array<SillConfigurationCompartment>}
     * @memberof SillConfiguration
     */
    'compartments': Array<SillConfigurationCompartment>;
    /**
     * 
     * @type {Array<SillConfigurationNeut>}
     * @memberof SillConfiguration
     */
    'neuten': Array<SillConfigurationNeut>;
    /**
     * 
     * @type {string}
     * @memberof SillConfiguration
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof SillConfiguration
     */
    'colorId': string;
    /**
     * 
     * @type {AssemblyMethod}
     * @memberof SillConfiguration
     */
    'assemblyMethod'?: AssemblyMethod;
    /**
     * 
     * @type {string}
     * @memberof SillConfiguration
     */
    'mountingOptionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SillConfiguration
     */
    'wrap'?: boolean | null;
    /**
     * 
     * @type {ConfigurationWallConnectionLeft}
     * @memberof SillConfiguration
     */
    'wallConnectionLeft'?: ConfigurationWallConnectionLeft | null;
    /**
     * 
     * @type {ConfigurationWallConnectionLeft}
     * @memberof SillConfiguration
     */
    'wallConnectionRight'?: ConfigurationWallConnectionLeft | null;
}


        /**
 * 
 * @export
 * @interface SillConfigurationAngledWallConnection
 */
export interface SillConfigurationAngledWallConnection extends SillConfigurationWallConnection {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationAngledWallConnection
     */
    'angle': number;
}


        /**
 * 
 * @export
 * @interface SillConfigurationCompartment
 */
export interface SillConfigurationCompartment {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof SillConfigurationCompartment
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationCompartment
     */
    'dagmaat': number;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationCompartment
     */
    'profileId'?: string | null;
    /**
     * 
     * @type {SillConfigurationGlazingBar}
     * @memberof SillConfigurationCompartment
     */
    'glazingBar'?: SillConfigurationGlazingBar;
    /**
     * 
     * @type {SillConfigurationGlazingProfile}
     * @memberof SillConfigurationCompartment
     */
    'glazingProfile'?: SillConfigurationGlazingProfile;
    /**
     * 
     * @type {SillConfigurationStopProfile}
     * @memberof SillConfigurationCompartment
     */
    'stopProfile'?: SillConfigurationStopProfile;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationCompartment
     */
    'stopRabbetDepth'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SillConfigurationCompartment
     */
    'drainageHoles'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SillConfigurationCompartment
     */
    'aerationHoles'?: boolean | null;
    /**
     * 
     * @type {Array<SillConfigurationSluitpot>}
     * @memberof SillConfigurationCompartment
     */
    'sluitpotten'?: Array<SillConfigurationSluitpot> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SillConfigurationCompartment
     */
    'protector': boolean;
    /**
     * 
     * @type {SillConfigurationCompartmentHefSchuifLayout}
     * @memberof SillConfigurationCompartment
     */
    'hefSchuifLayout'?: SillConfigurationCompartmentHefSchuifLayout | null;
}


        /**
 * @type SillConfigurationCompartmentHefSchuifLayout
 * @export
 */
export type SillConfigurationCompartmentHefSchuifLayout = SillConfigurationHefSchuifLayoutSchemaA | SillConfigurationHefSchuifLayoutSchemaC | SillConfigurationHefSchuifLayoutSchemaD | SillConfigurationHefSchuifLayoutSchemaE;

        /**
 * 
 * @export
 * @interface SillConfigurationCornerPiece
 */
export interface SillConfigurationCornerPiece {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationCornerPiece
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationCornerPiece
     */
    'height': number;
}
        /**
 * 
 * @export
 * @interface SillConfigurationDoubleFixedDoorBar
 */
export interface SillConfigurationDoubleFixedDoorBar {
    /**
     * 
     * @type {ConfiguredSillFixedDoorBarRaisedRim}
     * @memberof SillConfigurationDoubleFixedDoorBar
     */
    'raisedRim'?: ConfiguredSillFixedDoorBarRaisedRim;
    /**
     * 
     * @type {SillConfigurationFixedDoorBarSegment}
     * @memberof SillConfigurationDoubleFixedDoorBar
     */
    'left': SillConfigurationFixedDoorBarSegment;
    /**
     * 
     * @type {SillConfigurationFixedDoorBarSegment}
     * @memberof SillConfigurationDoubleFixedDoorBar
     */
    'right': SillConfigurationFixedDoorBarSegment;
}
        /**
 * 
 * @export
 * @interface SillConfigurationFixedDoorBarSegment
 */
export interface SillConfigurationFixedDoorBarSegment {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationFixedDoorBarSegment
     */
    'length': number;
    /**
     * 
     * @type {ConfiguredSillHefSchuifBarCutout}
     * @memberof SillConfigurationFixedDoorBarSegment
     */
    'cutout'?: ConfiguredSillHefSchuifBarCutout;
}
        /**
 * 
 * @export
 * @interface SillConfigurationGlazingBar
 */
export interface SillConfigurationGlazingBar {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationGlazingBar
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationGlazingBar
     */
    'height': number;
    /**
     * 
     * @type {SillConfigurationCornerPiece}
     * @memberof SillConfigurationGlazingBar
     */
    'cornerPieceLeft'?: SillConfigurationCornerPiece;
    /**
     * 
     * @type {SillConfigurationCornerPiece}
     * @memberof SillConfigurationGlazingBar
     */
    'cornerPieceRight'?: SillConfigurationCornerPiece;
}
        /**
 * 
 * @export
 * @interface SillConfigurationGlazingProfile
 */
export interface SillConfigurationGlazingProfile {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationGlazingProfile
     */
    'rabbetWidth': number;
}
        /**
 * 
 * @export
 * @interface SillConfigurationGroove
 */
export interface SillConfigurationGroove {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationGroove
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationGroove
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationGroove
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @interface SillConfigurationHefSchuifLayout
 */
export interface SillConfigurationHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof SillConfigurationHefSchuifLayout
     */
    'schemaCode': HefSchuifSchemaCode;
    /**
     * 
     * @type {HefSchuifSlidingRailType}
     * @memberof SillConfigurationHefSchuifLayout
     */
    'railType'?: HefSchuifSlidingRailType;
    /**
     * 
     * @type {SillConfigurationHefSchuifSlidingRail}
     * @memberof SillConfigurationHefSchuifLayout
     */
    'slidingRail'?: SillConfigurationHefSchuifSlidingRail;
}


        /**
 * 
 * @export
 * @interface SillConfigurationHefSchuifLayoutSchemaA
 */
export interface SillConfigurationHefSchuifLayoutSchemaA extends SillConfigurationHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof SillConfigurationHefSchuifLayoutSchemaA
     */
    'slidingDirection': HefSchuifSlidingDirection;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationHefSchuifLayoutSchemaA
     */
    'profileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationHefSchuifLayoutSchemaA
     */
    'profileIdOutside': string;
    /**
     * 
     * @type {SillConfigurationSingleFixedDoorBar}
     * @memberof SillConfigurationHefSchuifLayoutSchemaA
     */
    'fixedDoorBar': SillConfigurationSingleFixedDoorBar;
}


        /**
 * 
 * @export
 * @interface SillConfigurationHefSchuifLayoutSchemaC
 */
export interface SillConfigurationHefSchuifLayoutSchemaC extends SillConfigurationHefSchuifLayout {
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationHefSchuifLayoutSchemaC
     */
    'profileIdOutside': string;
    /**
     * 
     * @type {SillConfigurationDoubleFixedDoorBar}
     * @memberof SillConfigurationHefSchuifLayoutSchemaC
     */
    'fixedDoorBar': SillConfigurationDoubleFixedDoorBar;
}


        /**
 * 
 * @export
 * @interface SillConfigurationHefSchuifLayoutSchemaD
 */
export interface SillConfigurationHefSchuifLayoutSchemaD extends SillConfigurationHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifInnerDoorPosition}
     * @memberof SillConfigurationHefSchuifLayoutSchemaD
     */
    'innerDoorPosition': HefSchuifInnerDoorPosition;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationHefSchuifLayoutSchemaD
     */
    'profileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationHefSchuifLayoutSchemaD
     */
    'profileIdOutside': string;
    /**
     * 
     * @type {SillConfigurationHefSchuifSlidingRail}
     * @memberof SillConfigurationHefSchuifLayoutSchemaD
     */
    'secondarySlidingRail'?: SillConfigurationHefSchuifSlidingRail;
}


        /**
 * 
 * @export
 * @interface SillConfigurationHefSchuifLayoutSchemaE
 */
export interface SillConfigurationHefSchuifLayoutSchemaE extends SillConfigurationHefSchuifLayout {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof SillConfigurationHefSchuifLayoutSchemaE
     */
    'slidingDirection': HefSchuifSlidingDirection;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationHefSchuifLayoutSchemaE
     */
    'profileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationHefSchuifLayoutSchemaE
     */
    'profileIdOutside': string;
    /**
     * 
     * @type {SillConfigurationHefSchuifSlidingRail}
     * @memberof SillConfigurationHefSchuifLayoutSchemaE
     */
    'secondarySlidingRail'?: SillConfigurationHefSchuifSlidingRail;
    /**
     * 
     * @type {SillConfigurationSlidingDoorBar}
     * @memberof SillConfigurationHefSchuifLayoutSchemaE
     */
    'slidingDoorBar': SillConfigurationSlidingDoorBar;
    /**
     * 
     * @type {SillConfigurationSingleFixedDoorBar}
     * @memberof SillConfigurationHefSchuifLayoutSchemaE
     */
    'fixedDoorBar': SillConfigurationSingleFixedDoorBar;
}


        /**
 * 
 * @export
 * @interface SillConfigurationHefSchuifSlidingRail
 */
export interface SillConfigurationHefSchuifSlidingRail {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationHefSchuifSlidingRail
     */
    'center'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationHefSchuifSlidingRail
     */
    'length'?: number | null;
}
        /**
 * 
 * @export
 * @interface SillConfigurationNeut
 */
export interface SillConfigurationNeut {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationNeut
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationNeut
     */
    'height': number;
    /**
     * 
     * @type {Array<SillConfigurationNeutDrillHole>}
     * @memberof SillConfigurationNeut
     */
    'drillHoles'?: Array<SillConfigurationNeutDrillHole> | null;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationNeut
     */
    'customProfileLeft'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationNeut
     */
    'customProfileRight'?: string | null;
}
        /**
 * 
 * @export
 * @interface SillConfigurationNeutDrillHole
 */
export interface SillConfigurationNeutDrillHole {
    /**
     * 
     * @type {Point}
     * @memberof SillConfigurationNeutDrillHole
     */
    'position': Point;
}
        /**
 * 
 * @export
 * @interface SillConfigurationRenovationRabbet
 */
export interface SillConfigurationRenovationRabbet {
    /**
     * 
     * @type {Array<SillConfigurationRenovationRabbetStep>}
     * @memberof SillConfigurationRenovationRabbet
     */
    'steps': Array<SillConfigurationRenovationRabbetStep>;
}
        /**
 * 
 * @export
 * @interface SillConfigurationRenovationRabbetStep
 */
export interface SillConfigurationRenovationRabbetStep {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationRenovationRabbetStep
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationRenovationRabbetStep
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @interface SillConfigurationSingleFixedDoorBar
 */
export interface SillConfigurationSingleFixedDoorBar {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationSingleFixedDoorBar
     */
    'length'?: number | null;
    /**
     * 
     * @type {ConfiguredSillHefSchuifBarCutout}
     * @memberof SillConfigurationSingleFixedDoorBar
     */
    'cutout'?: ConfiguredSillHefSchuifBarCutout;
    /**
     * 
     * @type {ConfiguredSillFixedDoorBarRaisedRim}
     * @memberof SillConfigurationSingleFixedDoorBar
     */
    'raisedRim'?: ConfiguredSillFixedDoorBarRaisedRim;
}
        /**
 * 
 * @export
 * @interface SillConfigurationSlidingDoorBar
 */
export interface SillConfigurationSlidingDoorBar {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationSlidingDoorBar
     */
    'length'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationSlidingDoorBar
     */
    'width'?: number | null;
    /**
     * 
     * @type {ConfiguredSillHefSchuifBarCutout}
     * @memberof SillConfigurationSlidingDoorBar
     */
    'cutout'?: ConfiguredSillHefSchuifBarCutout;
}
        /**
 * 
 * @export
 * @interface SillConfigurationSluitpot
 */
export interface SillConfigurationSluitpot {
    /**
     * 
     * @type {Point}
     * @memberof SillConfigurationSluitpot
     */
    'position': Point;
    /**
     * 
     * @type {boolean}
     * @memberof SillConfigurationSluitpot
     */
    'includeHardware': boolean;
    /**
     * 
     * @type {string}
     * @memberof SillConfigurationSluitpot
     */
    'sluitpotBeslagId': string;
}
        /**
 * 
 * @export
 * @interface SillConfigurationStandardWallConnection
 */
export interface SillConfigurationStandardWallConnection extends SillConfigurationWallConnection {
    /**
     * 
     * @type {SillConfigurationRenovationRabbet}
     * @memberof SillConfigurationStandardWallConnection
     */
    'renovationRabbetInside'?: SillConfigurationRenovationRabbet;
    /**
     * 
     * @type {SillConfigurationRenovationRabbet}
     * @memberof SillConfigurationStandardWallConnection
     */
    'renovationRabbetOutside'?: SillConfigurationRenovationRabbet;
    /**
     * 
     * @type {Array<SillConfigurationGroove>}
     * @memberof SillConfigurationStandardWallConnection
     */
    'grooves'?: Array<SillConfigurationGroove> | null;
}


        /**
 * 
 * @export
 * @interface SillConfigurationStopProfile
 */
export interface SillConfigurationStopProfile {
    /**
     * 
     * @type {number}
     * @memberof SillConfigurationStopProfile
     */
    'rabbetWidth': number;
}
        /**
 * 
 * @export
 * @interface SillConfigurationWallConnection
 */
export interface SillConfigurationWallConnection {
    /**
     * 
     * @type {WallConnectionType}
     * @memberof SillConfigurationWallConnection
     */
    'wallConnectionType': WallConnectionType;
}


        /**
 * 
 * @export
 * @interface SillConfiguratorProduct
 */
export interface SillConfiguratorProduct extends CatalogItem {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof SillConfiguratorProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {CatalogItemSillModel}
     * @memberof SillConfiguratorProduct
     */
    'sill': CatalogItemSillModel;
}


        /**
 * 
 * @export
 * @interface SillDetails
 */
export interface SillDetails {
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof SillDetails
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'name': string;
    /**
     * 
     * @type {SillType}
     * @memberof SillDetails
     */
    'type': SillType;
    /**
     * 
     * @type {number}
     * @memberof SillDetails
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SillDetails
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {number}
     * @memberof SillDetails
     */
    'rand'?: number | null;
    /**
     * 
     * @type {SillNeutOptions}
     * @memberof SillDetails
     */
    'neutOptions': SillNeutOptions;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'contour'?: string;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'contourSvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'contraProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'contraProfileSvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillDetails
     */
    'thumb'?: string | null;
    /**
     * 
     * @type {Array<SillCompartmentLayoutTypeOption>}
     * @memberof SillDetails
     */
    'compartmentLayoutTypeOptions': Array<SillCompartmentLayoutTypeOption>;
    /**
     * 
     * @type {Array<SillDetailsCompartmentLayoutsInner>}
     * @memberof SillDetails
     */
    'compartmentLayouts': Array<SillDetailsCompartmentLayoutsInner>;
    /**
     * 
     * @type {SillAerationAndDrainageOptions}
     * @memberof SillDetails
     */
    'aerationAndDrainageOptions'?: SillAerationAndDrainageOptions;
    /**
     * 
     * @type {Array<SillSluitpotArea>}
     * @memberof SillDetails
     */
    'areas'?: Array<SillSluitpotArea> | null;
    /**
     * 
     * @type {SillAssemblyOptions}
     * @memberof SillDetails
     */
    'assemblyOptions': SillAssemblyOptions;
    /**
     * 
     * @type {SillPriceComponentReference}
     * @memberof SillDetails
     */
    'priceComponents': SillPriceComponentReference;
    /**
     * 
     * @type {SillDetailsCompartmentOptions}
     * @memberof SillDetails
     */
    'compartmentOptions': SillDetailsCompartmentOptions;
}


        /**
 * 
 * @export
 * @interface SillDetailsCompartmentLayout
 */
export interface SillDetailsCompartmentLayout {
    /**
     * 
     * @type {string}
     * @memberof SillDetailsCompartmentLayout
     */
    '$type': string;
    /**
     * 
     * @type {string}
     * @memberof SillDetailsCompartmentLayout
     */
    'id': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof SillDetailsCompartmentLayout
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentLayoutTypeCategory}
     * @memberof SillDetailsCompartmentLayout
     */
    'category': CompartmentLayoutTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof SillDetailsCompartmentLayout
     */
    'name': string;
}


        /**
 * 
 * @export
 * @interface SillDetailsCompartmentLayoutHefSchuif
 */
export interface SillDetailsCompartmentLayoutHefSchuif extends SillDetailsCompartmentLayout {
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof SillDetailsCompartmentLayoutHefSchuif
     */
    'schemaCode': HefSchuifSchemaCode;
}


        /**
 * 
 * @export
 * @interface SillDetailsCompartmentLayoutHefSchuifA
 */
export interface SillDetailsCompartmentLayoutHefSchuifA extends SillDetailsCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof SillDetailsCompartmentLayoutHefSchuifA
     */
    'defaultSlidingDirection': HefSchuifSlidingDirection;
}


        /**
 * 
 * @export
 * @interface SillDetailsCompartmentLayoutHefSchuifC
 */
export interface SillDetailsCompartmentLayoutHefSchuifC extends SillDetailsCompartmentLayoutHefSchuif {
}


        /**
 * 
 * @export
 * @interface SillDetailsCompartmentLayoutHefSchuifD
 */
export interface SillDetailsCompartmentLayoutHefSchuifD extends SillDetailsCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {HefSchuifInnerDoorPosition}
     * @memberof SillDetailsCompartmentLayoutHefSchuifD
     */
    'defaultInnerDoorPosition': HefSchuifInnerDoorPosition;
}


        /**
 * 
 * @export
 * @interface SillDetailsCompartmentLayoutHefSchuifE
 */
export interface SillDetailsCompartmentLayoutHefSchuifE extends SillDetailsCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {HefSchuifSlidingDirection}
     * @memberof SillDetailsCompartmentLayoutHefSchuifE
     */
    'defaultSlidingDirection': HefSchuifSlidingDirection;
}


        /**
 * 
 * @export
 * @interface SillDetailsCompartmentLayoutStandard
 */
export interface SillDetailsCompartmentLayoutStandard extends SillDetailsCompartmentLayout {
    /**
     * 
     * @type {RabbetPosition}
     * @memberof SillDetailsCompartmentLayoutStandard
     */
    'rabbetPosition': RabbetPosition;
}


        /**
 * @type SillDetailsCompartmentLayoutsInner
 * @export
 */
export type SillDetailsCompartmentLayoutsInner = SillDetailsCompartmentLayoutHefSchuif | SillDetailsCompartmentLayoutHefSchuifA | SillDetailsCompartmentLayoutHefSchuifC | SillDetailsCompartmentLayoutHefSchuifD | SillDetailsCompartmentLayoutHefSchuifE | SillDetailsCompartmentLayoutStandard;

        /**
 * 
 * @export
 * @interface SillDetailsCompartmentOptions
 */
export interface SillDetailsCompartmentOptions {
    /**
     * 
     * @type {number}
     * @memberof SillDetailsCompartmentOptions
     */
    'maxCompartmentCount': number;
    /**
     * 
     * @type {Array<SillDetailsNeutPosition>}
     * @memberof SillDetailsCompartmentOptions
     */
    'neutPositions': Array<SillDetailsNeutPosition>;
}
        /**
 * 
 * @export
 * @interface SillDetailsNeutPosition
 */
export interface SillDetailsNeutPosition {
    /**
     * 
     * @type {string}
     * @memberof SillDetailsNeutPosition
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillDetailsNeutPosition
     */
    'name': string;
    /**
     * 
     * @type {SillNeutIndention}
     * @memberof SillDetailsNeutPosition
     */
    'indention': SillNeutIndention;
    /**
     * 
     * @type {number}
     * @memberof SillDetailsNeutPosition
     */
    'profileWidth': number;
    /**
     * 
     * @type {Array<SillDetailsNeutPositionCompartmentLayoutsInner>}
     * @memberof SillDetailsNeutPosition
     */
    'compartmentLayouts': Array<SillDetailsNeutPositionCompartmentLayoutsInner>;
}
        /**
 * 
 * @export
 * @interface SillDetailsNeutPositionCompartmentLayout
 */
export interface SillDetailsNeutPositionCompartmentLayout {
    /**
     * 
     * @type {string}
     * @memberof SillDetailsNeutPositionCompartmentLayout
     */
    '$type': string;
    /**
     * 
     * @type {string}
     * @memberof SillDetailsNeutPositionCompartmentLayout
     */
    'compartmentLayoutTypeId': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof SillDetailsNeutPositionCompartmentLayout
     */
    'compartmentLayoutTypeCode': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {CompartmentLayoutTypeCategory}
     * @memberof SillDetailsNeutPositionCompartmentLayout
     */
    'compartmentLayoutTypeCategory': CompartmentLayoutTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof SillDetailsNeutPositionCompartmentLayout
     */
    'compartmentLayoutTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof SillDetailsNeutPositionCompartmentLayout
     */
    'profileCount': number;
}


        /**
 * 
 * @export
 * @interface SillDetailsNeutPositionCompartmentLayoutHefSchuif
 */
export interface SillDetailsNeutPositionCompartmentLayoutHefSchuif extends SillDetailsNeutPositionCompartmentLayout {
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuif
     */
    'schemaCode': HefSchuifSchemaCode;
}


        /**
 * 
 * @export
 * @interface SillDetailsNeutPositionCompartmentLayoutHefSchuifA
 */
export interface SillDetailsNeutPositionCompartmentLayoutHefSchuifA extends SillDetailsNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuifA
     */
    'defaultProfileInside': CompartmentProfileInfo;
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuifA
     */
    'defaultProfileOutside': CompartmentProfileInfo;
}


        /**
 * 
 * @export
 * @interface SillDetailsNeutPositionCompartmentLayoutHefSchuifC
 */
export interface SillDetailsNeutPositionCompartmentLayoutHefSchuifC extends SillDetailsNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuifC
     */
    'defaultProfileOutside': CompartmentProfileInfo;
}


        /**
 * 
 * @export
 * @interface SillDetailsNeutPositionCompartmentLayoutHefSchuifD
 */
export interface SillDetailsNeutPositionCompartmentLayoutHefSchuifD extends SillDetailsNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuifD
     */
    'defaultProfileInside': CompartmentProfileInfo;
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuifD
     */
    'defaultProfileOutside': CompartmentProfileInfo;
}


        /**
 * 
 * @export
 * @interface SillDetailsNeutPositionCompartmentLayoutHefSchuifE
 */
export interface SillDetailsNeutPositionCompartmentLayoutHefSchuifE extends SillDetailsNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuifE
     */
    'defaultProfileInside': CompartmentProfileInfo;
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutHefSchuifE
     */
    'defaultProfileOutside': CompartmentProfileInfo;
}


        /**
 * 
 * @export
 * @interface SillDetailsNeutPositionCompartmentLayoutStandard
 */
export interface SillDetailsNeutPositionCompartmentLayoutStandard extends SillDetailsNeutPositionCompartmentLayout {
    /**
     * 
     * @type {CompartmentProfileInfo}
     * @memberof SillDetailsNeutPositionCompartmentLayoutStandard
     */
    'defaultProfile': CompartmentProfileInfo;
}


        /**
 * @type SillDetailsNeutPositionCompartmentLayoutsInner
 * @export
 */
export type SillDetailsNeutPositionCompartmentLayoutsInner = SillDetailsNeutPositionCompartmentLayoutHefSchuif | SillDetailsNeutPositionCompartmentLayoutHefSchuifA | SillDetailsNeutPositionCompartmentLayoutHefSchuifC | SillDetailsNeutPositionCompartmentLayoutHefSchuifD | SillDetailsNeutPositionCompartmentLayoutHefSchuifE | SillDetailsNeutPositionCompartmentLayoutStandard;

        /**
 * 
 * @export
 * @interface SillDrainageOption
 */
export interface SillDrainageOption {
    /**
     * 
     * @type {string}
     * @memberof SillDrainageOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillDrainageOption
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof SillDrainageOption
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof SillDrainageOption
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SillIdReferenceWithIdCodeAndName
 */
export interface SillIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof SillIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SillIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SillModel
 */
export interface SillModel {
    /**
     * 
     * @type {string}
     * @memberof SillModel
     */
    'sillId': string;
    /**
     * 
     * @type {number}
     * @memberof SillModel
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SillModel
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {string}
     * @memberof SillModel
     */
    'contour': string;
    /**
     * 
     * @type {SillModelContra}
     * @memberof SillModel
     */
    'contra': SillModelContra;
    /**
     * 
     * @type {string}
     * @memberof SillModel
     */
    'code': string;
    /**
     * 
     * @type {SillType}
     * @memberof SillModel
     */
    'type': SillType;
    /**
     * 
     * @type {boolean}
     * @memberof SillModel
     */
    'hasNeuten': boolean;
}


        /**
 * 
 * @export
 * @interface SillModelContra
 */
export interface SillModelContra {
    /**
     * 
     * @type {string}
     * @memberof SillModelContra
     */
    'geometry': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const SillNeutDrillHoleMode = {
    Required: 'required',
    Optional: 'optional',
    None: 'none'
} as const;

export type SillNeutDrillHoleMode = typeof SillNeutDrillHoleMode[keyof typeof SillNeutDrillHoleMode];


        /**
 * 
 * @export
 * @interface SillNeutDrillHoleOptions
 */
export interface SillNeutDrillHoleOptions {
    /**
     * 
     * @type {boolean}
     * @memberof SillNeutDrillHoleOptions
     */
    'fixedPositions': boolean;
    /**
     * 
     * @type {NeutDrillHolePositioningStrategyName}
     * @memberof SillNeutDrillHoleOptions
     */
    'positioningStrategyName': NeutDrillHolePositioningStrategyName;
    /**
     * 
     * @type {DefaultDrillHoleYPositions}
     * @memberof SillNeutDrillHoleOptions
     */
    'defaultYPositions'?: DefaultDrillHoleYPositions;
}


        /**
 * 
 * @export
 * @interface SillNeutIndention
 */
export interface SillNeutIndention {
    /**
     * 
     * @type {number}
     * @memberof SillNeutIndention
     */
    'inside': number;
    /**
     * 
     * @type {number}
     * @memberof SillNeutIndention
     */
    'outside': number;
}
        /**
 * 
 * @export
 * @interface SillNeutOptions
 */
export interface SillNeutOptions {
    /**
     * 
     * @type {boolean}
     * @memberof SillNeutOptions
     */
    'hasNeuten': boolean;
    /**
     * 
     * @type {number}
     * @memberof SillNeutOptions
     */
    'defaultNeutHeight': number;
    /**
     * 
     * @type {boolean}
     * @memberof SillNeutOptions
     */
    'customHeight': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof SillNeutOptions
     */
    'standardWidths'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SillNeutOptions
     */
    'customWidth': boolean;
    /**
     * 
     * @type {number}
     * @memberof SillNeutOptions
     */
    'minWidth': number;
    /**
     * 
     * @type {SillNeutDrillHoleMode}
     * @memberof SillNeutOptions
     */
    'drillHoleMode'?: SillNeutDrillHoleMode;
    /**
     * 
     * @type {SillNeutDrillHoleOptions}
     * @memberof SillNeutOptions
     */
    'drillHoleOptions'?: SillNeutDrillHoleOptions;
}


        /**
 * 
 * @export
 * @interface SillNeutPositionCompartmentLayout
 */
export interface SillNeutPositionCompartmentLayout {
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayout
     */
    '$type': string;
}
        /**
 * 
 * @export
 * @interface SillNeutPositionCompartmentLayoutHefSchuif
 */
export interface SillNeutPositionCompartmentLayoutHefSchuif extends SillNeutPositionCompartmentLayout {
}
        /**
 * 
 * @export
 * @interface SillNeutPositionCompartmentLayoutHefSchuifA
 */
export interface SillNeutPositionCompartmentLayoutHefSchuifA extends SillNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutHefSchuifA
     */
    'defaultProfileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutHefSchuifA
     */
    'defaultProfileIdOutside': string;
}
        /**
 * 
 * @export
 * @interface SillNeutPositionCompartmentLayoutHefSchuifC
 */
export interface SillNeutPositionCompartmentLayoutHefSchuifC extends SillNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutHefSchuifC
     */
    'defaultProfileIdOutside': string;
}
        /**
 * 
 * @export
 * @interface SillNeutPositionCompartmentLayoutHefSchuifD
 */
export interface SillNeutPositionCompartmentLayoutHefSchuifD extends SillNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutHefSchuifD
     */
    'defaultProfileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutHefSchuifD
     */
    'defaultProfileIdOutside': string;
}
        /**
 * 
 * @export
 * @interface SillNeutPositionCompartmentLayoutHefSchuifE
 */
export interface SillNeutPositionCompartmentLayoutHefSchuifE extends SillNeutPositionCompartmentLayoutHefSchuif {
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutHefSchuifE
     */
    'defaultProfileIdInside': string;
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutHefSchuifE
     */
    'defaultProfileIdOutside': string;
}
        /**
 * 
 * @export
 * @interface SillNeutPositionCompartmentLayoutStandard
 */
export interface SillNeutPositionCompartmentLayoutStandard extends SillNeutPositionCompartmentLayout {
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionCompartmentLayoutStandard
     */
    'defaultProfileId': string;
}
        /**
 * 
 * @export
 * @interface SillNeutPositionUpdate
 */
export interface SillNeutPositionUpdate {
    /**
     * 
     * @type {string}
     * @memberof SillNeutPositionUpdate
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SillPriceComponentReference
 */
export interface SillPriceComponentReference {
    /**
     * 
     * @type {string}
     * @memberof SillPriceComponentReference
     */
    'baseSillId': string;
    /**
     * 
     * @type {string}
     * @memberof SillPriceComponentReference
     */
    'baseSill': string;
    /**
     * 
     * @type {string}
     * @memberof SillPriceComponentReference
     */
    'extenderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillPriceComponentReference
     */
    'extender'?: string | null;
}
        /**
 * 
 * @export
 * @interface SillProduct
 */
export interface SillProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof SillProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {CatalogItemSillModel}
     * @memberof SillProduct
     */
    'sill': CatalogItemSillModel;
    /**
     * 
     * @type {CatalogItemColor}
     * @memberof SillProduct
     */
    'color': CatalogItemColor;
    /**
     * 
     * @type {number}
     * @memberof SillProduct
     */
    'length': number;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const SillProductionTasks = {
    DorpelMaken: 'dorpelMaken',
    LatMaken: 'latMaken',
    DorpelProfielZagen: 'dorpelProfielZagen',
    NeutenMaken: 'neutenMaken',
    DorpelBewerken: 'dorpelBewerken',
    NeutMontage: 'neutMontage',
    SluitpotMontage: 'sluitpotMontage',
    HefschuifVouwwand: 'hefschuifVouwwand',
    Specials: 'specials',
    KopisolatorPlaatsen: 'kopisolatorPlaatsen',
    ManchettePlakken: 'manchettePlakken',
    GlaslattenBijvoegen: 'glaslattenBijvoegen',
    BeglazingsprofielPlaatsen: 'beglazingsprofielPlaatsen',
    AanslagprofielPlaatsen: 'aanslagprofielPlaatsen',
    LProfielPlaatsen: 'lProfielPlaatsen',
    Inpakken: 'inpakken'
} as const;

export type SillProductionTasks = typeof SillProductionTasks[keyof typeof SillProductionTasks];


        /**
 * 
 * @export
 * @interface SillProfile
 */
export interface SillProfile {
    /**
     * 
     * @type {string}
     * @memberof SillProfile
     */
    'profileId': string;
    /**
     * 
     * @type {string}
     * @memberof SillProfile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SillProfile
     */
    'geometry': string;
    /**
     * 
     * @type {boolean}
     * @memberof SillProfile
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof SillProfile
     */
    'rabbetName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillProfile
     */
    'cornerProfileInsideName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillProfile
     */
    'cornerProfileOutsideName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillProfile
     */
    'svgPreview': string;
}
        /**
 * 
 * @export
 * @interface SillProfiles
 */
export interface SillProfiles {
    /**
     * 
     * @type {SillProfilesSill}
     * @memberof SillProfiles
     */
    'sill': SillProfilesSill;
    /**
     * 
     * @type {SillProfilesNeutPosition}
     * @memberof SillProfiles
     */
    'neutPosition': SillProfilesNeutPosition;
    /**
     * 
     * @type {SillProfilesCompartmentLayoutType}
     * @memberof SillProfiles
     */
    'compartmentLayoutType': SillProfilesCompartmentLayoutType;
    /**
     * 
     * @type {string}
     * @memberof SillProfiles
     */
    'defaultProfileId'?: string | null;
    /**
     * 
     * @type {Array<SillProfile>}
     * @memberof SillProfiles
     */
    'profiles': Array<SillProfile>;
    /**
     * 
     * @type {number}
     * @memberof SillProfiles
     */
    'profileWidth': number;
}
        /**
 * 
 * @export
 * @interface SillProfilesCompartmentLayoutType
 */
export interface SillProfilesCompartmentLayoutType {
    /**
     * 
     * @type {string}
     * @memberof SillProfilesCompartmentLayoutType
     */
    'compartmentLayoutTypeId': string;
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof SillProfilesCompartmentLayoutType
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof SillProfilesCompartmentLayoutType
     */
    'name': string;
}


        /**
 * 
 * @export
 * @interface SillProfilesHefSchuif
 */
export interface SillProfilesHefSchuif {
    /**
     * 
     * @type {HefSchuifSchemaCode}
     * @memberof SillProfilesHefSchuif
     */
    'schemaCode': HefSchuifSchemaCode;
    /**
     * 
     * @type {SillProfilesSill}
     * @memberof SillProfilesHefSchuif
     */
    'sill': SillProfilesSill;
    /**
     * 
     * @type {SillProfilesNeutPosition}
     * @memberof SillProfilesHefSchuif
     */
    'neutPosition': SillProfilesNeutPosition;
    /**
     * 
     * @type {SillProfilesCompartmentLayoutType}
     * @memberof SillProfilesHefSchuif
     */
    'compartmentLayoutType': SillProfilesCompartmentLayoutType;
    /**
     * 
     * @type {string}
     * @memberof SillProfilesHefSchuif
     */
    'defaultProfileId'?: string | null;
    /**
     * 
     * @type {Array<SillProfile>}
     * @memberof SillProfilesHefSchuif
     */
    'profiles': Array<SillProfile>;
    /**
     * 
     * @type {number}
     * @memberof SillProfilesHefSchuif
     */
    'profileWidth': number;
}


        /**
 * 
 * @export
 * @interface SillProfilesNeutPosition
 */
export interface SillProfilesNeutPosition {
    /**
     * 
     * @type {string}
     * @memberof SillProfilesNeutPosition
     */
    'neutPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof SillProfilesNeutPosition
     */
    'name': string;
    /**
     * 
     * @type {SillNeutIndention}
     * @memberof SillProfilesNeutPosition
     */
    'indention': SillNeutIndention;
    /**
     * 
     * @type {Array<SillProfilesCompartmentLayoutType>}
     * @memberof SillProfilesNeutPosition
     */
    'compartmentLayoutTypes': Array<SillProfilesCompartmentLayoutType>;
}
        /**
 * 
 * @export
 * @interface SillProfilesSill
 */
export interface SillProfilesSill {
    /**
     * 
     * @type {string}
     * @memberof SillProfilesSill
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillProfilesSill
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SillProfilesSill
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SillProfilesSill
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface SillReference
 */
export interface SillReference {
    /**
     * 
     * @type {number}
     * @memberof SillReference
     */
    'width': number;
    /**
     * 
     * @type {SillType}
     * @memberof SillReference
     */
    'type': SillType;
    /**
     * 
     * @type {string}
     * @memberof SillReference
     */
    'defaultNeutPositionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SillReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SillReference
     */
    'name': string;
}


        /**
 * 
 * @export
 * @interface SillSluitpotArea
 */
export interface SillSluitpotArea {
    /**
     * 
     * @type {string}
     * @memberof SillSluitpotArea
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillSluitpotArea
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SillSluitpotArea
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SillSluitpotArea
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof SillSluitpotArea
     */
    'to': number;
    /**
     * 
     * @type {string}
     * @memberof SillSluitpotArea
     */
    'sluitpotBlockTypeId': string;
    /**
     * 
     * @type {number}
     * @memberof SillSluitpotArea
     */
    'angle': number;
    /**
     * 
     * @type {number}
     * @memberof SillSluitpotArea
     */
    'originY': number;
    /**
     * 
     * @type {number}
     * @memberof SillSluitpotArea
     */
    'originZ': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const SillType = {
    Binnensponning: 'binnensponning',
    Buitensponning: 'buitensponning',
    HefSchuif: 'hefSchuif',
    Vouwwand: 'vouwwand',
    Zijlicht: 'zijlicht'
} as const;

export type SillType = typeof SillType[keyof typeof SillType];


        /**
 * 
 * @export
 * @interface SillWallConnectionOptions
 */
export interface SillWallConnectionOptions {
    /**
     * 
     * @type {boolean}
     * @memberof SillWallConnectionOptions
     */
    'hasWallConnection': boolean;
}
        /**
 * 
 * @export
 * @interface SillWrappingOption
 */
export interface SillWrappingOption {
    /**
     * 
     * @type {string}
     * @memberof SillWrappingOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SillWrappingOption
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof SillWrappingOption
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof SillWrappingOption
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface Sluitpot
 */
export interface Sluitpot {
    /**
     * 
     * @type {string}
     * @memberof Sluitpot
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Sluitpot
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof Sluitpot
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Sluitpot
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Sluitpot
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sluitpot
     */
    'contour': string;
    /**
     * 
     * @type {SluitpotBeslagReference}
     * @memberof Sluitpot
     */
    'sluitpotBeslag': SluitpotBeslagReference;
    /**
     * 
     * @type {SluitpotBlockTypeReference}
     * @memberof Sluitpot
     */
    'sluitpotBlockType': SluitpotBlockTypeReference;
    /**
     * 
     * @type {string}
     * @memberof Sluitpot
     */
    'contourSvg'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Sluitpot
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Sluitpot
     */
    'angle'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Sluitpot
     */
    'operationName'?: string | null;
}
        /**
 * 
 * @export
 * @interface SluitpotBeslag
 */
export interface SluitpotBeslag {
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslag
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslag
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof SluitpotBeslag
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslag
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslag
     */
    'svg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslag
     */
    'image'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SluitpotBeslag
     */
    'imageSize'?: number | null;
}
        /**
 * 
 * @export
 * @interface SluitpotBeslagIdReferenceWithIdCodeAndName
 */
export interface SluitpotBeslagIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslagIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslagIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslagIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SluitpotBeslagReference
 */
export interface SluitpotBeslagReference {
    /**
     * 
     * @type {boolean}
     * @memberof SluitpotBeslagReference
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslagReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslagReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBeslagReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SluitpotBlockType
 */
export interface SluitpotBlockType {
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockType
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockType
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SluitpotBlockTypeIdReferenceWithIdCodeAndName
 */
export interface SluitpotBlockTypeIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockTypeIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockTypeIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockTypeIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SluitpotBlockTypeReference
 */
export interface SluitpotBlockTypeReference {
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockTypeReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockTypeReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotBlockTypeReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SluitpotIdReferenceWithIdCodeAndName
 */
export interface SluitpotIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof SluitpotIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface SluitpotReference
 */
export interface SluitpotReference {
    /**
     * 
     * @type {boolean}
     * @memberof SluitpotReference
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof SluitpotReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SluitpotReference
     */
    'name': string;
}
        /**
 * @type SmDiscountRulesIdGet200Response
 * @export
 */
export type SmDiscountRulesIdGet200Response = CatalogItemDiscountRule | DiscountGroupDiscountRule | PriceListAndCatalogItemDiscountRule | PriceListAndDiscountGroupDiscountRule | PriceListDiscountRule;

        /**
 * @type SmDiscountRulesIdPutRequest
 * @export
 */
export type SmDiscountRulesIdPutRequest = UpdateCatalogItemDiscountRuleCommand | UpdateDiscountGroupDiscountRuleCommand | UpdatePriceListAndCatalogItemDiscountRuleCommand | UpdatePriceListAndDiscountGroupDiscountRuleCommand | UpdatePriceListDiscountRuleCommand;

        /**
 * @type SmDiscountRulesPostRequest
 * @export
 */
export type SmDiscountRulesPostRequest = CreateCatalogItemDiscountRuleCommand | CreateDiscountGroupDiscountRuleCommand | CreatePriceListAndCatalogItemDiscountRuleCommand | CreatePriceListAndDiscountGroupDiscountRuleCommand | CreatePriceListDiscountRuleCommand;

        /**
 * @type SmOrderlineIdPostRequest
 * @export
 */
export type SmOrderlineIdPostRequest = PostConfiguratorOrderLineRepresentation | PostProductOrderLineRepresentation | PostQuantityOrderLineRepresentation | PostShippingCostOrderLineRepresentation | PostTextOrderLineRepresentation;

        /**
 * @type SmOrderlineIdPutRequest
 * @export
 */
export type SmOrderlineIdPutRequest = PutConfigurationOrderLineRepresentation | PutPriceableOrderLineRepresentation | PutProductOrderLineRepresentation | PutQuantityOrderLineRepresentation | PutShippingCostOrderLineRepresentation | PutTextOrderLineRepresentation;

        /**
 * @type SmPricesPost404Response
 * @export
 */
export type SmPricesPost404Response = FluentValidationProblemDetails | ProblemDetails;

        /**
 * @type SmPricesPostRequest
 * @export
 */
export type SmPricesPostRequest = ChangePriceCommand | DeletePriceCommand | ResetPriceCommand;

        /**
 * 
 * @export
 * @interface StandardProduct
 */
export interface StandardProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof StandardProduct
     */
    'catalogItemType': CatalogItemType;
}


        /**
 * 
 * @export
 * @interface StandardRabbetInfo
 */
export interface StandardRabbetInfo {
    /**
     * 
     * @type {TurboholRabbetInfo}
     * @memberof StandardRabbetInfo
     */
    'turboholRabbet'?: TurboholRabbetInfo;
    /**
     * 
     * @type {string}
     * @memberof StandardRabbetInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StandardRabbetInfo
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StandardRabbetInfo
     */
    'name': string;
    /**
     * 
     * @type {RabbetType}
     * @memberof StandardRabbetInfo
     */
    'rabbetType': RabbetType;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof StandardRabbetInfo
     */
    'rabbetPosition': RabbetPosition;
    /**
     * 
     * @type {number}
     * @memberof StandardRabbetInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof StandardRabbetInfo
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof StandardRabbetInfo
     */
    'rabbetSize': string;
}


        /**
 * 
 * @export
 * @interface StandardWallConnection
 */
export interface StandardWallConnection extends WallConnection {
    /**
     * 
     * @type {number}
     * @memberof StandardWallConnection
     */
    'width': number;
    /**
     * 
     * @type {RenovationRabbet}
     * @memberof StandardWallConnection
     */
    'renovationRabbetInside'?: RenovationRabbet;
    /**
     * 
     * @type {RenovationRabbet}
     * @memberof StandardWallConnection
     */
    'renovationRabbetOutside'?: RenovationRabbet;
    /**
     * 
     * @type {Array<WallConnectionGroove>}
     * @memberof StandardWallConnection
     */
    'grooves': Array<WallConnectionGroove>;
    /**
     * 
     * @type {boolean}
     * @memberof StandardWallConnection
     */
    'isStraight': boolean;
    /**
     * 
     * @type {number}
     * @memberof StandardWallConnection
     */
    'depth': number;
}


        /**
 * 
 * @export
 * @interface Station
 */
export interface Station {
    /**
     * 
     * @type {ProductionStations}
     * @memberof Station
     */
    'key': ProductionStations;
    /**
     * 
     * @type {string}
     * @memberof Station
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'dailyOperatingHours': number;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'totalOperatingHours': number;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'plannedDuration': number;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'backlogDuration': number;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'utilization': number;
    /**
     * 
     * @type {StationTasks}
     * @memberof Station
     */
    'tasks': StationTasks;
    /**
     * 
     * @type {Metrics}
     * @memberof Station
     */
    'metrics': Metrics;
    /**
     * 
     * @type {Metrics}
     * @memberof Station
     */
    'sillMetrics': Metrics;
}


        /**
 * 
 * @export
 * @interface StationTasks
 */
export interface StationTasks {
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'dorpelMaken'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'latMaken'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'dorpelProfielZagen'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'neutenMaken'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'dorpelBewerken'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'neutMontage'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'sluitpotMontage'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'hefschuifVouwwand'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'specials'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'kopisolatorPlaatsen'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'manchettePlakken'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'glaslattenBijvoegen'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'beglazingsprofielPlaatsen'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'aanslagprofielPlaatsen'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'lProfielPlaatsen'?: Task;
    /**
     * 
     * @type {Task}
     * @memberof StationTasks
     */
    'inpakken'?: Task;
}
        /**
 * 
 * @export
 * @interface StopProfile
 */
export interface StopProfile {
    /**
     * 
     * @type {string}
     * @memberof StopProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfile
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof StopProfile
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof StopProfile
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof StopProfile
     */
    'defaultWidth': number;
    /**
     * 
     * @type {number}
     * @memberof StopProfile
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof StopProfile
     */
    'mountingSlotPosition': number;
    /**
     * 
     * @type {number}
     * @memberof StopProfile
     */
    'stopPosition': number;
    /**
     * 
     * @type {number}
     * @memberof StopProfile
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {Array<CompartmentLayoutTypeReference>}
     * @memberof StopProfile
     */
    'compartmentLayoutTypes': Array<CompartmentLayoutTypeReference>;
}
        /**
 * 
 * @export
 * @interface StopProfileIdReferenceWithIdCodeAndName
 */
export interface StopProfileIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof StopProfileIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfileIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfileIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface StopProfileProduct
 */
export interface StopProfileProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof StopProfileProduct
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {StopProfileProductStopProfile}
     * @memberof StopProfileProduct
     */
    'stopProfile': StopProfileProductStopProfile;
    /**
     * 
     * @type {CatalogItemColor}
     * @memberof StopProfileProduct
     */
    'color': CatalogItemColor;
}


        /**
 * 
 * @export
 * @interface StopProfileProductStopProfile
 */
export interface StopProfileProductStopProfile {
    /**
     * 
     * @type {string}
     * @memberof StopProfileProductStopProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfileProductStopProfile
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfileProductStopProfile
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface StopProfileReference
 */
export interface StopProfileReference {
    /**
     * 
     * @type {number}
     * @memberof StopProfileReference
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {string}
     * @memberof StopProfileReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfileReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StopProfileReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const StructureType = {
    Neut: 'neut',
    Compartment: 'compartment'
} as const;

export type StructureType = typeof StructureType[keyof typeof StructureType];


        /**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'averageDuration': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'plannedDuration': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'backlogDuration': string;
    /**
     * 
     * @type {Metrics}
     * @memberof Task
     */
    'metrics': Metrics;
    /**
     * 
     * @type {Metrics}
     * @memberof Task
     */
    'sillMetrics': Metrics;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ToeslagCode = {
    StandaardNeut67: 'standaardNeut67',
    StandaardNeut90: 'standaardNeut90',
    CustomNeut: 'customNeut',
    HogeNeut: 'hogeNeut',
    DiepeNeut115140: 'diepeNeut115140',
    DiepeNeut140Plus: 'diepeNeut140Plus',
    IngesprongenNeut: 'ingesprongenNeut',
    ExtraDrillHole: 'extraDrillHole',
    ProfielVersmallen: 'profielVersmallen',
    SluitpotSparingFrezen: 'sluitpotSparingFrezen',
    SluitpotInstalleren: 'sluitpotInstalleren',
    RenovatieSponningFrezen: 'renovatieSponningFrezen',
    KalkSponningFrezen: 'kalkSponningFrezen',
    GroefFrezen: 'groefFrezen',
    AfwateringsgatenEnOfBeluchtingsgatenAanbrengen: 'afwateringsgatenEnOfBeluchtingsgatenAanbrengen',
    LProfielVersmallen: 'lProfielVersmallen',
    LProfielVerlagen: 'lProfielVerlagen',
    KopisolatorenAanbrengen: 'kopisolatorenAanbrengen'
} as const;

export type ToeslagCode = typeof ToeslagCode[keyof typeof ToeslagCode];


        /**
 * 
 * @export
 * @interface TransportPlanningByOrderView
 */
export interface TransportPlanningByOrderView {
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'plannedExpeditionDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByOrderView
     */
    'plannedExpeditionWeek'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByOrderView
     */
    'plannedExpeditionYear'?: number | null;
    /**
     * 
     * @type {ExpeditionStatusCodes}
     * @memberof TransportPlanningByOrderView
     */
    'expeditionStatus': ExpeditionStatusCodes;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'orderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'customerCode': string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'customerCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'orderDeliveryCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'expeditionId': string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderView
     */
    'shipmentId': string;
    /**
     * 
     * @type {ShipmentStatusCodes}
     * @memberof TransportPlanningByOrderView
     */
    'shipmentStatus': ShipmentStatusCodes;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByOrderView
     */
    'maxSillLengthInOrder'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByOrderView
     */
    'sillsInOrder'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByOrderView
     */
    'orderAddressCount'?: number | null;
}


        /**
 * 
 * @export
 * @interface TransportPlanningByOrderViewArrayODataValue
 */
export interface TransportPlanningByOrderViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByOrderViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByOrderViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<TransportPlanningByOrderView>}
     * @memberof TransportPlanningByOrderViewArrayODataValue
     */
    'value': Array<TransportPlanningByOrderView>;
}
        /**
 * 
 * @export
 * @interface TransportPlanningByWeekView
 */
export interface TransportPlanningByWeekView {
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByWeekView
     */
    'plannedExpeditionWeek': number;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByWeekView
     */
    'plannedExpeditionYear': number;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByWeekView
     */
    'weekStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByWeekView
     */
    'weekEndDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByWeekView
     */
    'orderCount': number;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByWeekView
     */
    'addressCount': number;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByWeekView
     */
    'sillLength': number;
}
        /**
 * 
 * @export
 * @interface TransportPlanningByWeekViewArrayODataValue
 */
export interface TransportPlanningByWeekViewArrayODataValue {
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByWeekViewArrayODataValue
     */
    '@odata.context'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransportPlanningByWeekViewArrayODataValue
     */
    '@odata.count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransportPlanningByWeekViewArrayODataValue
     */
    '@odata.nextLink'?: string | null;
    /**
     * 
     * @type {Array<TransportPlanningByWeekView>}
     * @memberof TransportPlanningByWeekViewArrayODataValue
     */
    'value': Array<TransportPlanningByWeekView>;
}
        /**
 * 
 * @export
 * @interface TransportProduct
 */
export interface TransportProduct extends CatalogItemProduct {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof TransportProduct
     */
    'catalogItemType': CatalogItemType;
}


        /**
 * 
 * @export
 * @interface TurboholRabbet
 */
export interface TurboholRabbet {
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbet
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbet
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof TurboholRabbet
     */
    'turboholTypeId': string;
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbet
     */
    'beslagSponningDepth': number;
    /**
     * 
     * @type {AanslagNok}
     * @memberof TurboholRabbet
     */
    'aanslagNok'?: AanslagNok;
}
        /**
 * 
 * @export
 * @interface TurboholRabbetInfo
 */
export interface TurboholRabbetInfo {
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbetInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbetInfo
     */
    'depth': number;
    /**
     * 
     * @type {TurboholTypeInfo}
     * @memberof TurboholRabbetInfo
     */
    'turboholType': TurboholTypeInfo;
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbetInfo
     */
    'beslagSponningDepth': number;
    /**
     * 
     * @type {AanslagNokInfo}
     * @memberof TurboholRabbetInfo
     */
    'aanslagNok'?: AanslagNokInfo;
    /**
     * 
     * @type {string}
     * @memberof TurboholRabbetInfo
     */
    'displayName': string;
}
        /**
 * 
 * @export
 * @interface TurboholRabbetReference
 */
export interface TurboholRabbetReference {
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbetReference
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbetReference
     */
    'depth': number;
    /**
     * 
     * @type {TurboholTypeReference}
     * @memberof TurboholRabbetReference
     */
    'turboholType': TurboholTypeReference;
    /**
     * 
     * @type {number}
     * @memberof TurboholRabbetReference
     */
    'beslagSponningDepth': number;
    /**
     * 
     * @type {AanslagNok}
     * @memberof TurboholRabbetReference
     */
    'aanslagNok'?: AanslagNok;
}
        /**
 * 
 * @export
 * @interface TurboholType
 */
export interface TurboholType {
    /**
     * 
     * @type {string}
     * @memberof TurboholType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholType
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholType
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface TurboholTypeIdReferenceWithIdCodeAndName
 */
export interface TurboholTypeIdReferenceWithIdCodeAndName {
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeIdReferenceWithIdCodeAndName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeIdReferenceWithIdCodeAndName
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeIdReferenceWithIdCodeAndName
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface TurboholTypeInfo
 */
export interface TurboholTypeInfo {
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeInfo
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeInfo
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface TurboholTypeReference
 */
export interface TurboholTypeReference {
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeReference
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof TurboholTypeReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface UpdateCatalogItemCommand
 */
export interface UpdateCatalogItemCommand {
    /**
     * 
     * @type {CatalogItemType}
     * @memberof UpdateCatalogItemCommand
     */
    'catalogItemType': CatalogItemType;
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogItemCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCatalogItemCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogItemCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogItemCommand
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogItemCommand
     */
    'discountGroupId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCatalogItemCommand
     */
    'catalogIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCatalogItemCommand
     */
    'categoryIds'?: Array<string> | null;
}


        /**
 * 
 * @export
 * @interface UpdateCatalogItemDiscountRuleCommand
 */
export interface UpdateCatalogItemDiscountRuleCommand extends UpdateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogItemDiscountRuleCommand
     */
    'catalogItemId': string;
}


        /**
 * 
 * @export
 * @interface UpdateCatalogRequest
 */
export interface UpdateCatalogRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogRequest
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface UpdateCategoryRequest
 */
export interface UpdateCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCategoryRequest
     */
    'weight': number;
}
        /**
 * 
 * @export
 * @interface UpdateColorRequest
 */
export interface UpdateColorRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateColorRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateColorRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateColorRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateColorRequest
     */
    'hex': string;
}
        /**
 * 
 * @export
 * @interface UpdateCompartmentLayoutTypeCommand
 */
export interface UpdateCompartmentLayoutTypeCommand {
    /**
     * 
     * @type {CompartmentLayoutTypeCode}
     * @memberof UpdateCompartmentLayoutTypeCommand
     */
    'code': CompartmentLayoutTypeCode;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompartmentLayoutTypeCommand
     */
    'name': string;
    /**
     * 
     * @type {RabbetPosition}
     * @memberof UpdateCompartmentLayoutTypeCommand
     */
    'rabbetPosition': RabbetPosition;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCompartmentLayoutTypeCommand
     */
    'sluitpotBeslagIds'?: Array<string> | null;
}


        /**
 * 
 * @export
 * @interface UpdateConfiguredSillProductCommand
 */
export interface UpdateConfiguredSillProductCommand extends UpdateCatalogItemCommand {
}


        /**
 * 
 * @export
 * @interface UpdateCornerPieceCommand
 */
export interface UpdateCornerPieceCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCornerPieceCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCornerPieceCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCornerPieceCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCornerPieceCommand
     */
    'glazingBarId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCornerPieceCommand
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCornerPieceCommand
     */
    'contour'?: string;
}
        /**
 * 
 * @export
 * @interface UpdateCornerPieceProductCommand
 */
export interface UpdateCornerPieceProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCornerPieceProductCommand
     */
    'cornerPieceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCornerPieceProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface UpdateDiscountGroupDiscountRuleCommand
 */
export interface UpdateDiscountGroupDiscountRuleCommand extends UpdateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountGroupDiscountRuleCommand
     */
    'discountGroupId': string;
}


        /**
 * 
 * @export
 * @interface UpdateDiscountGroupModel
 */
export interface UpdateDiscountGroupModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountGroupModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountGroupModel
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface UpdateDiscountRuleCommand
 */
export interface UpdateDiscountRuleCommand {
    /**
     * 
     * @type {DiscountRuleType}
     * @memberof UpdateDiscountRuleCommand
     */
    'discountRuleType': DiscountRuleType;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountRuleCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountRuleCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountRuleCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDiscountRuleCommand
     */
    'discount': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountRuleCommand
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountRuleCommand
     */
    'note'?: string | null;
}


        /**
 * 
 * @export
 * @interface UpdateGlazingBarProductCommand
 */
export interface UpdateGlazingBarProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingBarProductCommand
     */
    'glazingBarId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingBarProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface UpdateGlazingBarRequest
 */
export interface UpdateGlazingBarRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingBarRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGlazingBarRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingBarRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingBarRequest
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingBarRequest
     */
    'height': number;
}
        /**
 * 
 * @export
 * @interface UpdateGlazingProfileProductCommand
 */
export interface UpdateGlazingProfileProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingProfileProductCommand
     */
    'glazingProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingProfileProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface UpdateGlazingProfileRequest
 */
export interface UpdateGlazingProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingProfileRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGlazingProfileRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlazingProfileRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingProfileRequest
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingProfileRequest
     */
    'defaultWidth': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingProfileRequest
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingProfileRequest
     */
    'mountingSlotPosition': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingProfileRequest
     */
    'stopPosition': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlazingProfileRequest
     */
    'rabbetWidth': number;
}
        /**
 * 
 * @export
 * @interface UpdateKopIsolatorSetProductCommand
 */
export interface UpdateKopIsolatorSetProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateKopIsolatorSetProductCommand
     */
    'sillId': string;
}


        /**
 * 
 * @export
 * @interface UpdateLProfileCommand
 */
export interface UpdateLProfileCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateLProfileCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLProfileCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLProfileCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLProfileCommand
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLProfileCommand
     */
    'width': number;
}
        /**
 * 
 * @export
 * @interface UpdateLProfileProductCommand
 */
export interface UpdateLProfileProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateLProfileProductCommand
     */
    'lProfileId': string;
}


        /**
 * 
 * @export
 * @interface UpdateMountingOptionRequest
 */
export interface UpdateMountingOptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMountingOptionRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMountingOptionRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateMountingOptionRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMountingOptionRequest
     */
    'drillHoleId'?: string | null;
}
        /**
 * 
 * @export
 * @interface UpdateNeutCommand
 */
export interface UpdateNeutCommand extends UpdateCatalogItemCommand {
}


        /**
 * 
 * @export
 * @interface UpdatePriceListAndCatalogItemDiscountRuleCommand
 */
export interface UpdatePriceListAndCatalogItemDiscountRuleCommand extends UpdateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListAndCatalogItemDiscountRuleCommand
     */
    'priceListId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListAndCatalogItemDiscountRuleCommand
     */
    'catalogItemId': string;
}


        /**
 * 
 * @export
 * @interface UpdatePriceListAndDiscountGroupDiscountRuleCommand
 */
export interface UpdatePriceListAndDiscountGroupDiscountRuleCommand extends UpdateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListAndDiscountGroupDiscountRuleCommand
     */
    'priceListId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListAndDiscountGroupDiscountRuleCommand
     */
    'discountGroupId': string;
}


        /**
 * 
 * @export
 * @interface UpdatePriceListDiscountRuleCommand
 */
export interface UpdatePriceListDiscountRuleCommand extends UpdateDiscountRuleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListDiscountRuleCommand
     */
    'priceListId': string;
}


        /**
 * 
 * @export
 * @interface UpdatePriceListRequest
 */
export interface UpdatePriceListRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceListRequest
     */
    'parentPriceListId'?: string | null;
}
        /**
 * 
 * @export
 * @interface UpdateProfileBulkActiveRequest
 */
export interface UpdateProfileBulkActiveRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProfileBulkActiveRequest
     */
    'profileIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProfileBulkActiveRequest
     */
    'active': boolean;
}
        /**
 * 
 * @export
 * @interface UpdateProfileRequest
 */
export interface UpdateProfileRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProfileRequest
     */
    'hasProgram': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProfileRequest
     */
    'active': boolean;
}
        /**
 * 
 * @export
 * @interface UpdateSillConfiguratorProductCommand
 */
export interface UpdateSillConfiguratorProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillConfiguratorProductCommand
     */
    'sillId': string;
}


        /**
 * 
 * @export
 * @interface UpdateSillContourProfileRequest
 */
export interface UpdateSillContourProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillContourProfileRequest
     */
    'contour'?: string;
}
        /**
 * 
 * @export
 * @interface UpdateSillContraProfileRequest
 */
export interface UpdateSillContraProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillContraProfileRequest
     */
    'contraProfile'?: string;
}
        /**
 * 
 * @export
 * @interface UpdateSillDrainageOptionRequest
 */
export interface UpdateSillDrainageOptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillDrainageOptionRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSillDrainageOptionRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillDrainageOptionRequest
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface UpdateSillPriceComponentsRequest
 */
export interface UpdateSillPriceComponentsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillPriceComponentsRequest
     */
    'baseSill': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillPriceComponentsRequest
     */
    'extender'?: string | null;
}
        /**
 * 
 * @export
 * @interface UpdateSillProductCommand
 */
export interface UpdateSillProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillProductCommand
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillProductCommand
     */
    'colorId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSillProductCommand
     */
    'length': number;
}


        /**
 * 
 * @export
 * @interface UpdateSillRequest
 */
export interface UpdateSillRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSillRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillRequest
     */
    'name': string;
    /**
     * 
     * @type {SillType}
     * @memberof UpdateSillRequest
     */
    'type': SillType;
    /**
     * 
     * @type {number}
     * @memberof UpdateSillRequest
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSillRequest
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSillRequest
     */
    'rand'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {SillAssemblyOptions}
     * @memberof UpdateSillRequest
     */
    'assemblyOptions': SillAssemblyOptions;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillRequest
     */
    'baseSill': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillRequest
     */
    'extender'?: string | null;
}


        /**
 * 
 * @export
 * @interface UpdateSillSluitpotAreasRequest
 */
export interface UpdateSillSluitpotAreasRequest {
    /**
     * 
     * @type {Array<SillSluitpotArea>}
     * @memberof UpdateSillSluitpotAreasRequest
     */
    'sluitpotAreas': Array<SillSluitpotArea>;
}
        /**
 * 
 * @export
 * @interface UpdateSillWrappingOptionCommand
 */
export interface UpdateSillWrappingOptionCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSillWrappingOptionCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSillWrappingOptionCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSillWrappingOptionCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface UpdateSluitpotBeslagCommand
 */
export interface UpdateSluitpotBeslagCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotBeslagCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSluitpotBeslagCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotBeslagCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotBeslagCommand
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotBeslagCommand
     */
    'svg'?: string | null;
}
        /**
 * 
 * @export
 * @interface UpdateSluitpotBlockTypeCommand
 */
export interface UpdateSluitpotBlockTypeCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotBlockTypeCommand
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotBlockTypeCommand
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface UpdateSluitpotCommand
 */
export interface UpdateSluitpotCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSluitpotCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotCommand
     */
    'contour': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotCommand
     */
    'sluitpotBeslagId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotCommand
     */
    'sluitpotBlockTypeId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSluitpotCommand
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSluitpotCommand
     */
    'angle': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotCommand
     */
    'contourSvg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotCommand
     */
    'operationName'?: string | null;
}
        /**
 * 
 * @export
 * @interface UpdateSluitpotContourCommand
 */
export interface UpdateSluitpotContourCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSluitpotContourCommand
     */
    'contour': string;
}
        /**
 * 
 * @export
 * @interface UpdateStandardProductCommand
 */
export interface UpdateStandardProductCommand extends UpdateCatalogItemCommand {
}


        /**
 * 
 * @export
 * @interface UpdateStopProfileCommand
 */
export interface UpdateStopProfileCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateStopProfileCommand
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateStopProfileCommand
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateStopProfileCommand
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateStopProfileCommand
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStopProfileCommand
     */
    'defaultWidth': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStopProfileCommand
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStopProfileCommand
     */
    'mountingSlotPosition': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStopProfileCommand
     */
    'stopPosition': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStopProfileCommand
     */
    'rabbetWidth': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateStopProfileCommand
     */
    'compartmentLayoutTypeIds': Array<string>;
}
        /**
 * 
 * @export
 * @interface UpdateStopProfileProductCommand
 */
export interface UpdateStopProfileProductCommand extends UpdateCatalogItemCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateStopProfileProductCommand
     */
    'stopProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStopProfileProductCommand
     */
    'colorId': string;
}


        /**
 * 
 * @export
 * @interface UpdateTransportProductCommand
 */
export interface UpdateTransportProductCommand extends UpdateCatalogItemCommand {
}


        /**
 * 
 * @export
 * @interface UpdateTurboholTypeRequest
 */
export interface UpdateTurboholTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTurboholTypeRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTurboholTypeRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTurboholTypeRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTurboholTypeRequest
     */
    'diameter': number;
}
        /**
 * 
 * @export
 * @interface UpsertAerationAndDrainageOptionsRequest
 */
export interface UpsertAerationAndDrainageOptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof UpsertAerationAndDrainageOptionsRequest
     */
    'maxSpacing': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertAerationAndDrainageOptionsRequest
     */
    'drainageHolesMandatory'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertAerationAndDrainageOptionsRequest
     */
    'aerationHolesMandatory'?: boolean | null;
    /**
     * 
     * @type {SillAerationAndDrainageHole}
     * @memberof UpsertAerationAndDrainageOptionsRequest
     */
    'holeTop'?: SillAerationAndDrainageHole;
    /**
     * 
     * @type {SillAerationAndDrainageHole}
     * @memberof UpsertAerationAndDrainageOptionsRequest
     */
    'holeFront': SillAerationAndDrainageHole;
}
        /**
 * @type UpsertCompartmentLayoutRequest
 * @export
 */
export type UpsertCompartmentLayoutRequest = SillCompartmentLayoutHefSchuifAEInput | SillCompartmentLayoutHefSchuifDInput | SillCompartmentLayoutInput;

        /**
 * @type UpsertNeutPositionCompartmentLayoutRequest
 * @export
 */
export type UpsertNeutPositionCompartmentLayoutRequest = SillNeutPositionCompartmentLayoutHefSchuif | SillNeutPositionCompartmentLayoutHefSchuifA | SillNeutPositionCompartmentLayoutHefSchuifC | SillNeutPositionCompartmentLayoutHefSchuifD | SillNeutPositionCompartmentLayoutHefSchuifE | SillNeutPositionCompartmentLayoutStandard;

        /**
 * @type ValidateConfigurationRequest
 * @export
 */
export type ValidateConfigurationRequest = SillConfiguration | ZijlichtConfiguration;

        /**
 * 
 * @export
 * @interface ValidateConfigurationResponse
 */
export interface ValidateConfigurationResponse {
    /**
     * 
     * @type {PriceSpecification}
     * @memberof ValidateConfigurationResponse
     */
    'price'?: PriceSpecification;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidateConfigurationResponse
     */
    'problems'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidateConfigurationResponse
     */
    'previewSvg': string;
}
        /**
 * @type ValidateWallConnectionRequest
 * @export
 */
export type ValidateWallConnectionRequest = SillConfigurationAngledWallConnection | SillConfigurationStandardWallConnection;

        /**
 * 
 * @export
 * @interface ValidateWallConnectionResult
 */
export interface ValidateWallConnectionResult {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateWallConnectionResult
     */
    'isValid': boolean;
    /**
     * 
     * @type {ConfigurationWallConnectionLeft}
     * @memberof ValidateWallConnectionResult
     */
    'wallConnection'?: ConfigurationWallConnectionLeft | null;
    /**
     * 
     * @type {string}
     * @memberof ValidateWallConnectionResult
     */
    'svg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidateWallConnectionResult
     */
    'error'?: string | null;
}
        /**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors': { [key: string]: Array<string>; };
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
}
        /**
 * 
 * @export
 * @interface Vector
 */
export interface Vector {
    /**
     * 
     * @type {number}
     * @memberof Vector
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Vector
     */
    'y'?: number;
}
        /**
 * 
 * @export
 * @interface WallConnection
 */
export interface WallConnection {
    /**
     * 
     * @type {WallConnectionType}
     * @memberof WallConnection
     */
    'wallConnectionType': WallConnectionType;
    /**
     * 
     * @type {number}
     * @memberof WallConnection
     */
    'width': number;
}


        /**
 * 
 * @export
 * @interface WallConnectionGroove
 */
export interface WallConnectionGroove {
    /**
     * 
     * @type {number}
     * @memberof WallConnectionGroove
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof WallConnectionGroove
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof WallConnectionGroove
     */
    'depth': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const WallConnectionType = {
    Standard: 'standard',
    Angled: 'angled',
    CustomProfile: 'customProfile'
} as const;

export type WallConnectionType = typeof WallConnectionType[keyof typeof WallConnectionType];


        /**
 * 
 * @export
 * @interface WallProfileInfo
 */
export interface WallProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof WallProfileInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WallProfileInfo
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof WallProfileInfo
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof WallProfileInfo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof WallProfileInfo
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof WallProfileInfo
     */
    'sillWidth': number;
    /**
     * 
     * @type {string}
     * @memberof WallProfileInfo
     */
    'geometry': string;
    /**
     * 
     * @type {string}
     * @memberof WallProfileInfo
     */
    'svgPreview'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WallProfileInfo
     */
    'hasProgram': boolean;
}
        /**
 * 
 * @export
 * @interface WeeklyExpeditionStatsRow
 */
export interface WeeklyExpeditionStatsRow {
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'jaar': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'orders': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'omzet': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'dorpels': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'neuten': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'meter': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'avgLengte': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'avgNeuten': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'ordersYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'neutenYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'omzetYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'dorpelsYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'meterYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'avgLengteYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyExpeditionStatsRow
     */
    'avgNeutenYTD': number;
}
        /**
 * 
 * @export
 * @interface WeeklyLevertijdStatsRow
 */
export interface WeeklyLevertijdStatsRow {
    /**
     * 
     * @type {number}
     * @memberof WeeklyLevertijdStatsRow
     */
    'jaar': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyLevertijdStatsRow
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyLevertijdStatsRow
     */
    'orders': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyLevertijdStatsRow
     */
    'levertijdMin': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyLevertijdStatsRow
     */
    'levertijdAvg': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyLevertijdStatsRow
     */
    'levertijdMax': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyLevertijdStatsRow
     */
    'levertijdAvgYTD': number;
}
        /**
 * 
 * @export
 * @interface WeeklyOrdersPlacedByStatsRow
 */
export interface WeeklyOrdersPlacedByStatsRow {
    /**
     * 
     * @type {number}
     * @memberof WeeklyOrdersPlacedByStatsRow
     */
    'jaar': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyOrdersPlacedByStatsRow
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyOrdersPlacedByStatsRow
     */
    'orders': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyOrdersPlacedByStatsRow
     */
    'ordersEkosiet': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyOrdersPlacedByStatsRow
     */
    'ordersKlanten': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyOrdersPlacedByStatsRow
     */
    'percentageKlanten': number;
}
        /**
 * 
 * @export
 * @interface WeeklyProductionStatsRow
 */
export interface WeeklyProductionStatsRow {
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'jaar': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'geproduceerdeDorpels': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'geplandeDorpels': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'omzet': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'geplandeOmzet': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'doorlooptijd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'timing'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'geplandeBuffertijd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'gerealiseerdeBuffertijd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'neuten': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'meter': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'geproduceerdeDorpelsYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'geplandeDorpelsYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'omzetYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'geplandeOmzetYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'neutenYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyProductionStatsRow
     */
    'meterYTD': number;
}
        /**
 * 
 * @export
 * @interface WeeklySalesSillStatsRow
 */
export interface WeeklySalesSillStatsRow {
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesSillStatsRow
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesSillStatsRow
     */
    'week': number;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'ordersWithSills': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'sillSales': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'dorpels': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'neuten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'dorpelsMetLatten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'dorpelsMetNeuten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'binnensponning': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'binnensponningLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'buitensponning': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'buitensponningLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'hefSchuif': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'hefSchuifLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'vouwwand': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'vouwwandLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'zijlicht': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'zijlichtLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'dorpelLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'voormontage': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'namontage': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'inpakken': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'kopisolatoren': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'manchettes': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'latten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'glaslatten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'beglazingsprofielen': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'lProfielen': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'aanslagprofielen': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'sluitpotten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'boorgaten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'kalksponning': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'groeven': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'renovatiesponningen': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'aantalVakken': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'hefSchuifA': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'hefschuifE': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'hefSchuifD': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'hefSchuifC': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'vouwwandBuiten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'vouwwandBinnen': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'draaiKiep': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'deurBuitendraaiend': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'deurBinnendraaiend': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'vastglasBinnen': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'vastglasBuiten': Int32Stat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'aantalHoekstukjes': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'glasLatLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'beglazingsprofielLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'aanslagprofielLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'lProfielLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'slidingRailsLaagAantal': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'slidingRailsLaagLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'slidingRailsHoogAantal': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'slidingRailsHoogLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'slidingDoorBarLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'fixedDoorBarLeftLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'fixedDoorBarRightLengte': DecimalStat;
    /**
     * 
     * @type {Int32Stat}
     * @memberof WeeklySalesSillStatsRow
     */
    'fixedDoorBars': Int32Stat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'fixedDoorBarLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemDorpelPrijs': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemAantalNeutPerDorpel': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemDorpelLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percVoormontage': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percNamontage': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percInpakken': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percDorpelsMetLatten': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percDorpelsMetNeuten': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percBinnensponning': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemBinnensponningLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percBuitensponning': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemBuitensponningLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percHefSchuif': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemHefSchuifLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percVouwwand': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemVouwwandLengte': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'percZijlicht': DecimalStat;
    /**
     * 
     * @type {DecimalStat}
     * @memberof WeeklySalesSillStatsRow
     */
    'gemZijlichtLengte': DecimalStat;
}
        /**
 * 
 * @export
 * @interface WeeklySalesStatsRow
 */
export interface WeeklySalesStatsRow {
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'jaar': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'orders': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzet': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetDorpels': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetProducten': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetTransport': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetOverig': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'dorpels': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'neuten': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'meter': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'avgLengte': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'avgNeuten': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'ordersYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'neutenYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetDorpelsYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetProductenYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetTransportYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'omzetOverigYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'dorpelsYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'meterYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'avgLengteYTD': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySalesStatsRow
     */
    'avgNeutenYTD': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const WellKnownMountingOptionCode = {
    FastClick: 'fastClick',
    FastTwist: 'fastTwist',
    SchroevenPluggen8Mm: 'schroevenPluggen8Mm'
} as const;

export type WellKnownMountingOptionCode = typeof WellKnownMountingOptionCode[keyof typeof WellKnownMountingOptionCode];


        /**
 * 
 * @export
 * @interface ZijlichtConfiguration
 */
export interface ZijlichtConfiguration {
    /**
     * 
     * @type {number}
     * @memberof ZijlichtConfiguration
     */
    'length': number;
    /**
     * 
     * @type {string}
     * @memberof ZijlichtConfiguration
     */
    'sillId': string;
    /**
     * 
     * @type {string}
     * @memberof ZijlichtConfiguration
     */
    'colorId': string;
    /**
     * 
     * @type {AssemblyMethod}
     * @memberof ZijlichtConfiguration
     */
    'assemblyMethod'?: AssemblyMethod;
    /**
     * 
     * @type {string}
     * @memberof ZijlichtConfiguration
     */
    'mountingOptionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ZijlichtConfiguration
     */
    'wrap'?: boolean | null;
    /**
     * 
     * @type {ConfigurationWallConnectionLeft}
     * @memberof ZijlichtConfiguration
     */
    'wallConnectionLeft'?: ConfigurationWallConnectionLeft | null;
    /**
     * 
     * @type {ConfigurationWallConnectionLeft}
     * @memberof ZijlichtConfiguration
     */
    'wallConnectionRight'?: ConfigurationWallConnectionLeft | null;
}


    
            /**
    * CatalogApi - axios parameter creator
    * @export
    */
    export const CatalogApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/catalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCatalogsIdDelete', 'id', id)
            const localVarPath = `/pim/catalogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCatalogsIdGet', 'id', id)
            const localVarPath = `/pim/catalogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateCatalogRequest} [updateCatalogRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogsIdPut: async (id: string, apiVersion?: string, updateCatalogRequest?: UpdateCatalogRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCatalogsIdPut', 'id', id)
            const localVarPath = `/pim/catalogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateCatalogRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateCatalogCommand} [createCatalogCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogsPost: async (apiVersion?: string, createCatalogCommand?: CreateCatalogCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/catalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createCatalogCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * CatalogApi - functional programming interface
        * @export
        */
        export const CatalogApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = CatalogApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Catalog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogApi.pimCatalogsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogApi.pimCatalogsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogApi.pimCatalogsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateCatalogRequest} [updateCatalogRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogsIdPut(id: string, apiVersion?: string, updateCatalogRequest?: UpdateCatalogRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogsIdPut(id, apiVersion, updateCatalogRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogApi.pimCatalogsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateCatalogCommand} [createCatalogCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogsPost(apiVersion?: string, createCatalogCommand?: CreateCatalogCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogsPost(apiVersion, createCatalogCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogApi.pimCatalogsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * CatalogApi - factory interface
        * @export
        */
        export const CatalogApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = CatalogApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogsGet(apiVersion?: string, options?: any): AxiosPromise<Array<Catalog>> {
                return localVarFp.pimCatalogsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimCatalogsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<Catalog> {
                return localVarFp.pimCatalogsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCatalogRequest} [updateCatalogRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogsIdPut(id: string, apiVersion?: string, updateCatalogRequest?: UpdateCatalogRequest, options?: any): AxiosPromise<Catalog> {
                return localVarFp.pimCatalogsIdPut(id, apiVersion, updateCatalogRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCatalogCommand} [createCatalogCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogsPost(apiVersion?: string, createCatalogCommand?: CreateCatalogCommand, options?: any): AxiosPromise<Catalog> {
                return localVarFp.pimCatalogsPost(apiVersion, createCatalogCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * CatalogApi - interface
            * @export
            * @interface CatalogApi
            */
            export interface CatalogApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogApiInterface
                */
            pimCatalogsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Catalog>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogApiInterface
                */
            pimCatalogsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogApiInterface
                */
            pimCatalogsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Catalog>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCatalogRequest} [updateCatalogRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogApiInterface
                */
            pimCatalogsIdPut(id: string, apiVersion?: string, updateCatalogRequest?: UpdateCatalogRequest, options?: RawAxiosRequestConfig): AxiosPromise<Catalog>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCatalogCommand} [createCatalogCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogApiInterface
                */
            pimCatalogsPost(apiVersion?: string, createCatalogCommand?: CreateCatalogCommand, options?: RawAxiosRequestConfig): AxiosPromise<Catalog>;

            }

        /**
        * CatalogApi - object-oriented interface
        * @export
        * @class CatalogApi
        * @extends {BaseAPI}
        */
            export class CatalogApi extends BaseAPI implements CatalogApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogApi
            */
                public pimCatalogsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogApiFp(this.configuration).pimCatalogsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogApi
            */
                public pimCatalogsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogApiFp(this.configuration).pimCatalogsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogApi
            */
                public pimCatalogsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogApiFp(this.configuration).pimCatalogsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCatalogRequest} [updateCatalogRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogApi
            */
                public pimCatalogsIdPut(id: string, apiVersion?: string, updateCatalogRequest?: UpdateCatalogRequest, options?: RawAxiosRequestConfig) {
                return CatalogApiFp(this.configuration).pimCatalogsIdPut(id, apiVersion, updateCatalogRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCatalogCommand} [createCatalogCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogApi
            */
                public pimCatalogsPost(apiVersion?: string, createCatalogCommand?: CreateCatalogCommand, options?: RawAxiosRequestConfig) {
                return CatalogApiFp(this.configuration).pimCatalogsPost(apiVersion, createCatalogCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * CatalogItemApi - axios parameter creator
    * @export
    */
    export const CatalogItemApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} catalogId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsCatalogCatalogIdGet: async (catalogId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'catalogId' is not null or undefined
                    assertParamExists('pimCatalogItemsCatalogCatalogIdGet', 'catalogId', catalogId)
            const localVarPath = `/pim/catalog-items/catalog/{catalogId}`
                .replace(`{${"catalogId"}}`, encodeURIComponent(String(catalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [category] 
            * @param {string} [catalogId] 
            * @param {string} [priceListId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsCategoryGet: async (category?: string, catalogId?: string, priceListId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/catalog-items/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (category !== undefined) {
                                    localVarQueryParameter['category'] = category;
                    }

                    if (catalogId !== undefined) {
                                    localVarQueryParameter['catalogId'] = catalogId;
                    }

                    if (priceListId !== undefined) {
                                    localVarQueryParameter['priceListId'] = priceListId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/catalog-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCatalogItemsIdDelete', 'id', id)
            const localVarPath = `/pim/catalog-items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCatalogItemsIdGet', 'id', id)
            const localVarPath = `/pim/catalog-items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PimCatalogItemsIdPutRequest} [pimCatalogItemsIdPutRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsIdPut: async (id: string, apiVersion?: string, pimCatalogItemsIdPutRequest?: PimCatalogItemsIdPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCatalogItemsIdPut', 'id', id)
            const localVarPath = `/pim/catalog-items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(pimCatalogItemsIdPutRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PimCatalogItemsPostRequest} [pimCatalogItemsPostRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsPost: async (apiVersion?: string, pimCatalogItemsPostRequest?: PimCatalogItemsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/catalog-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(pimCatalogItemsPostRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsSillsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/catalog-items/sills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [catalogId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCatalogItemsStandardProductsGet: async (catalogId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/catalog-items/standard-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (catalogId !== undefined) {
                                    localVarQueryParameter['catalogId'] = catalogId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * CatalogItemApi - functional programming interface
        * @export
        */
        export const CatalogItemApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = CatalogItemApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} catalogId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsCatalogCatalogIdGet(catalogId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PimCatalogItemsIdGet200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsCatalogCatalogIdGet(catalogId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsCatalogCatalogIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [category] 
                * @param {string} [catalogId] 
                * @param {string} [priceListId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsCategoryGet(category?: string, catalogId?: string, priceListId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StandardProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsCategoryGet(category, catalogId, priceListId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PimCatalogItemsIdGet200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PimCatalogItemsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PimCatalogItemsIdPutRequest} [pimCatalogItemsIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsIdPut(id: string, apiVersion?: string, pimCatalogItemsIdPutRequest?: PimCatalogItemsIdPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PimCatalogItemsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsIdPut(id, apiVersion, pimCatalogItemsIdPutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PimCatalogItemsPostRequest} [pimCatalogItemsPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsPost(apiVersion?: string, pimCatalogItemsPostRequest?: PimCatalogItemsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PimCatalogItemsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsPost(apiVersion, pimCatalogItemsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsSillsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SillProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsSillsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsSillsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [catalogId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCatalogItemsStandardProductsGet(catalogId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StandardProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCatalogItemsStandardProductsGet(catalogId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogItemApi.pimCatalogItemsStandardProductsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * CatalogItemApi - factory interface
        * @export
        */
        export const CatalogItemApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = CatalogItemApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} catalogId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsCatalogCatalogIdGet(catalogId: string, apiVersion?: string, options?: any): AxiosPromise<Array<PimCatalogItemsIdGet200Response>> {
                return localVarFp.pimCatalogItemsCatalogCatalogIdGet(catalogId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [category] 
                    * @param {string} [catalogId] 
                    * @param {string} [priceListId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsCategoryGet(category?: string, catalogId?: string, priceListId?: string, apiVersion?: string, options?: any): AxiosPromise<Array<StandardProduct>> {
                return localVarFp.pimCatalogItemsCategoryGet(category, catalogId, priceListId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsGet(apiVersion?: string, options?: any): AxiosPromise<Array<PimCatalogItemsIdGet200Response>> {
                return localVarFp.pimCatalogItemsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimCatalogItemsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<PimCatalogItemsIdGet200Response> {
                return localVarFp.pimCatalogItemsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PimCatalogItemsIdPutRequest} [pimCatalogItemsIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsIdPut(id: string, apiVersion?: string, pimCatalogItemsIdPutRequest?: PimCatalogItemsIdPutRequest, options?: any): AxiosPromise<PimCatalogItemsIdGet200Response> {
                return localVarFp.pimCatalogItemsIdPut(id, apiVersion, pimCatalogItemsIdPutRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PimCatalogItemsPostRequest} [pimCatalogItemsPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsPost(apiVersion?: string, pimCatalogItemsPostRequest?: PimCatalogItemsPostRequest, options?: any): AxiosPromise<PimCatalogItemsIdGet200Response> {
                return localVarFp.pimCatalogItemsPost(apiVersion, pimCatalogItemsPostRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsSillsGet(apiVersion?: string, options?: any): AxiosPromise<Array<SillProduct>> {
                return localVarFp.pimCatalogItemsSillsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [catalogId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCatalogItemsStandardProductsGet(catalogId?: string, apiVersion?: string, options?: any): AxiosPromise<Array<StandardProduct>> {
                return localVarFp.pimCatalogItemsStandardProductsGet(catalogId, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * CatalogItemApi - interface
            * @export
            * @interface CatalogItemApi
            */
            export interface CatalogItemApiInterface {
                /**
                * 
                    * @param {string} catalogId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsCatalogCatalogIdGet(catalogId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PimCatalogItemsIdGet200Response>>;

                /**
                * 
                    * @param {string} [category] 
                    * @param {string} [catalogId] 
                    * @param {string} [priceListId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsCategoryGet(category?: string, catalogId?: string, priceListId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StandardProduct>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PimCatalogItemsIdGet200Response>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<PimCatalogItemsIdGet200Response>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PimCatalogItemsIdPutRequest} [pimCatalogItemsIdPutRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsIdPut(id: string, apiVersion?: string, pimCatalogItemsIdPutRequest?: PimCatalogItemsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PimCatalogItemsIdGet200Response>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PimCatalogItemsPostRequest} [pimCatalogItemsPostRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsPost(apiVersion?: string, pimCatalogItemsPostRequest?: PimCatalogItemsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PimCatalogItemsIdGet200Response>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsSillsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SillProduct>>;

                /**
                * 
                    * @param {string} [catalogId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CatalogItemApiInterface
                */
            pimCatalogItemsStandardProductsGet(catalogId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StandardProduct>>;

            }

        /**
        * CatalogItemApi - object-oriented interface
        * @export
        * @class CatalogItemApi
        * @extends {BaseAPI}
        */
            export class CatalogItemApi extends BaseAPI implements CatalogItemApiInterface {
            /**
            * 
                    * @param {string} catalogId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsCatalogCatalogIdGet(catalogId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsCatalogCatalogIdGet(catalogId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [category] 
                    * @param {string} [catalogId] 
                    * @param {string} [priceListId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsCategoryGet(category?: string, catalogId?: string, priceListId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsCategoryGet(category, catalogId, priceListId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PimCatalogItemsIdPutRequest} [pimCatalogItemsIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsIdPut(id: string, apiVersion?: string, pimCatalogItemsIdPutRequest?: PimCatalogItemsIdPutRequest, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsIdPut(id, apiVersion, pimCatalogItemsIdPutRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PimCatalogItemsPostRequest} [pimCatalogItemsPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsPost(apiVersion?: string, pimCatalogItemsPostRequest?: PimCatalogItemsPostRequest, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsPost(apiVersion, pimCatalogItemsPostRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsSillsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsSillsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [catalogId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CatalogItemApi
            */
                public pimCatalogItemsStandardProductsGet(catalogId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CatalogItemApiFp(this.configuration).pimCatalogItemsStandardProductsGet(catalogId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * CategoryApi - axios parameter creator
    * @export
    */
    export const CategoryApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} code 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCategoriesCodeCodeGet: async (code: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'code' is not null or undefined
                    assertParamExists('pimCategoriesCodeCodeGet', 'code', code)
            const localVarPath = `/pim/categories/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCategoriesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCategoriesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCategoriesIdDelete', 'id', id)
            const localVarPath = `/pim/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCategoriesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCategoriesIdGet', 'id', id)
            const localVarPath = `/pim/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCategoriesIdImagePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCategoriesIdImagePut', 'id', id)
            const localVarPath = `/pim/categories/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateCategoryRequest} [updateCategoryRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCategoriesIdPut: async (id: string, apiVersion?: string, updateCategoryRequest?: UpdateCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCategoriesIdPut', 'id', id)
            const localVarPath = `/pim/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateCategoryRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateCategoryCommand} [createCategoryCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCategoriesPost: async (apiVersion?: string, createCategoryCommand?: CreateCategoryCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createCategoryCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * CategoryApi - functional programming interface
        * @export
        */
        export const CategoryApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} code 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCategoriesCodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCategoriesCodeCodeGet(code, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoryApi.pimCategoriesCodeCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCategoriesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCategoriesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoryApi.pimCategoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCategoriesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCategoriesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoryApi.pimCategoriesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCategoriesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCategoriesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoryApi.pimCategoriesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCategoriesIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCategoriesIdImagePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoryApi.pimCategoriesIdImagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateCategoryRequest} [updateCategoryRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCategoriesIdPut(id: string, apiVersion?: string, updateCategoryRequest?: UpdateCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCategoriesIdPut(id, apiVersion, updateCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoryApi.pimCategoriesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateCategoryCommand} [createCategoryCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCategoriesPost(apiVersion?: string, createCategoryCommand?: CreateCategoryCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCategoriesPost(apiVersion, createCategoryCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoryApi.pimCategoriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * CategoryApi - factory interface
        * @export
        */
        export const CategoryApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = CategoryApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCategoriesCodeCodeGet(code: string, apiVersion?: string, options?: any): AxiosPromise<Category> {
                return localVarFp.pimCategoriesCodeCodeGet(code, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCategoriesGet(apiVersion?: string, options?: any): AxiosPromise<Array<Category>> {
                return localVarFp.pimCategoriesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCategoriesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimCategoriesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCategoriesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<Category> {
                return localVarFp.pimCategoriesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCategoriesIdImagePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimCategoriesIdImagePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCategoryRequest} [updateCategoryRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCategoriesIdPut(id: string, apiVersion?: string, updateCategoryRequest?: UpdateCategoryRequest, options?: any): AxiosPromise<Category> {
                return localVarFp.pimCategoriesIdPut(id, apiVersion, updateCategoryRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCategoryCommand} [createCategoryCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCategoriesPost(apiVersion?: string, createCategoryCommand?: CreateCategoryCommand, options?: any): AxiosPromise<Category> {
                return localVarFp.pimCategoriesPost(apiVersion, createCategoryCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * CategoryApi - interface
            * @export
            * @interface CategoryApi
            */
            export interface CategoryApiInterface {
                /**
                * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CategoryApiInterface
                */
            pimCategoriesCodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Category>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CategoryApiInterface
                */
            pimCategoriesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Category>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CategoryApiInterface
                */
            pimCategoriesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CategoryApiInterface
                */
            pimCategoriesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Category>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CategoryApiInterface
                */
            pimCategoriesIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCategoryRequest} [updateCategoryRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CategoryApiInterface
                */
            pimCategoriesIdPut(id: string, apiVersion?: string, updateCategoryRequest?: UpdateCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCategoryCommand} [createCategoryCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CategoryApiInterface
                */
            pimCategoriesPost(apiVersion?: string, createCategoryCommand?: CreateCategoryCommand, options?: RawAxiosRequestConfig): AxiosPromise<Category>;

            }

        /**
        * CategoryApi - object-oriented interface
        * @export
        * @class CategoryApi
        * @extends {BaseAPI}
        */
            export class CategoryApi extends BaseAPI implements CategoryApiInterface {
            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CategoryApi
            */
                public pimCategoriesCodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CategoryApiFp(this.configuration).pimCategoriesCodeCodeGet(code, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CategoryApi
            */
                public pimCategoriesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CategoryApiFp(this.configuration).pimCategoriesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CategoryApi
            */
                public pimCategoriesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CategoryApiFp(this.configuration).pimCategoriesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CategoryApi
            */
                public pimCategoriesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CategoryApiFp(this.configuration).pimCategoriesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CategoryApi
            */
                public pimCategoriesIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CategoryApiFp(this.configuration).pimCategoriesIdImagePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCategoryRequest} [updateCategoryRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CategoryApi
            */
                public pimCategoriesIdPut(id: string, apiVersion?: string, updateCategoryRequest?: UpdateCategoryRequest, options?: RawAxiosRequestConfig) {
                return CategoryApiFp(this.configuration).pimCategoriesIdPut(id, apiVersion, updateCategoryRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCategoryCommand} [createCategoryCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CategoryApi
            */
                public pimCategoriesPost(apiVersion?: string, createCategoryCommand?: CreateCategoryCommand, options?: RawAxiosRequestConfig) {
                return CategoryApiFp(this.configuration).pimCategoriesPost(apiVersion, createCategoryCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ColorApi - axios parameter creator
    * @export
    */
    export const ColorApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimColorsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/colors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimColorsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimColorsIdDelete', 'id', id)
            const localVarPath = `/pim/colors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimColorsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimColorsIdGet', 'id', id)
            const localVarPath = `/pim/colors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateColorRequest} [updateColorRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimColorsIdPut: async (id: string, apiVersion?: string, updateColorRequest?: UpdateColorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimColorsIdPut', 'id', id)
            const localVarPath = `/pim/colors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateColorRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateColorCommand} [createColorCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimColorsPost: async (apiVersion?: string, createColorCommand?: CreateColorCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/colors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createColorCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ColorApi - functional programming interface
        * @export
        */
        export const ColorApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ColorApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimColorsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Color>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimColorsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColorApi.pimColorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimColorsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimColorsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColorApi.pimColorsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimColorsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Color>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimColorsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColorApi.pimColorsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateColorRequest} [updateColorRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimColorsIdPut(id: string, apiVersion?: string, updateColorRequest?: UpdateColorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Color>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimColorsIdPut(id, apiVersion, updateColorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColorApi.pimColorsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateColorCommand} [createColorCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimColorsPost(apiVersion?: string, createColorCommand?: CreateColorCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Color>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimColorsPost(apiVersion, createColorCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColorApi.pimColorsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ColorApi - factory interface
        * @export
        */
        export const ColorApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ColorApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimColorsGet(apiVersion?: string, options?: any): AxiosPromise<Array<Color>> {
                return localVarFp.pimColorsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimColorsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimColorsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimColorsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<Color> {
                return localVarFp.pimColorsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateColorRequest} [updateColorRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimColorsIdPut(id: string, apiVersion?: string, updateColorRequest?: UpdateColorRequest, options?: any): AxiosPromise<Color> {
                return localVarFp.pimColorsIdPut(id, apiVersion, updateColorRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateColorCommand} [createColorCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimColorsPost(apiVersion?: string, createColorCommand?: CreateColorCommand, options?: any): AxiosPromise<Color> {
                return localVarFp.pimColorsPost(apiVersion, createColorCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ColorApi - interface
            * @export
            * @interface ColorApi
            */
            export interface ColorApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ColorApiInterface
                */
            pimColorsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Color>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ColorApiInterface
                */
            pimColorsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ColorApiInterface
                */
            pimColorsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Color>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateColorRequest} [updateColorRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ColorApiInterface
                */
            pimColorsIdPut(id: string, apiVersion?: string, updateColorRequest?: UpdateColorRequest, options?: RawAxiosRequestConfig): AxiosPromise<Color>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateColorCommand} [createColorCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ColorApiInterface
                */
            pimColorsPost(apiVersion?: string, createColorCommand?: CreateColorCommand, options?: RawAxiosRequestConfig): AxiosPromise<Color>;

            }

        /**
        * ColorApi - object-oriented interface
        * @export
        * @class ColorApi
        * @extends {BaseAPI}
        */
            export class ColorApi extends BaseAPI implements ColorApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ColorApi
            */
                public pimColorsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ColorApiFp(this.configuration).pimColorsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ColorApi
            */
                public pimColorsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ColorApiFp(this.configuration).pimColorsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ColorApi
            */
                public pimColorsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ColorApiFp(this.configuration).pimColorsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateColorRequest} [updateColorRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ColorApi
            */
                public pimColorsIdPut(id: string, apiVersion?: string, updateColorRequest?: UpdateColorRequest, options?: RawAxiosRequestConfig) {
                return ColorApiFp(this.configuration).pimColorsIdPut(id, apiVersion, updateColorRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateColorCommand} [createColorCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ColorApi
            */
                public pimColorsPost(apiVersion?: string, createColorCommand?: CreateColorCommand, options?: RawAxiosRequestConfig) {
                return ColorApiFp(this.configuration).pimColorsPost(apiVersion, createColorCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * CompartmentLayoutTypeApi - axios parameter creator
    * @export
    */
    export const CompartmentLayoutTypeApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCompartmentLayoutTypesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/compartment-layout-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCompartmentLayoutTypesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCompartmentLayoutTypesIdDelete', 'id', id)
            const localVarPath = `/pim/compartment-layout-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCompartmentLayoutTypesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCompartmentLayoutTypesIdGet', 'id', id)
            const localVarPath = `/pim/compartment-layout-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateCompartmentLayoutTypeCommand} [updateCompartmentLayoutTypeCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCompartmentLayoutTypesIdPut: async (id: string, apiVersion?: string, updateCompartmentLayoutTypeCommand?: UpdateCompartmentLayoutTypeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCompartmentLayoutTypesIdPut', 'id', id)
            const localVarPath = `/pim/compartment-layout-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateCompartmentLayoutTypeCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateCompartmentLayoutTypeCommand} [createCompartmentLayoutTypeCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCompartmentLayoutTypesPost: async (apiVersion?: string, createCompartmentLayoutTypeCommand?: CreateCompartmentLayoutTypeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/compartment-layout-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createCompartmentLayoutTypeCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * CompartmentLayoutTypeApi - functional programming interface
        * @export
        */
        export const CompartmentLayoutTypeApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = CompartmentLayoutTypeApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCompartmentLayoutTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompartmentLayoutType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCompartmentLayoutTypesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompartmentLayoutTypeApi.pimCompartmentLayoutTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCompartmentLayoutTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCompartmentLayoutTypesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompartmentLayoutTypeApi.pimCompartmentLayoutTypesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCompartmentLayoutTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompartmentLayoutType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCompartmentLayoutTypesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompartmentLayoutTypeApi.pimCompartmentLayoutTypesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateCompartmentLayoutTypeCommand} [updateCompartmentLayoutTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCompartmentLayoutTypesIdPut(id: string, apiVersion?: string, updateCompartmentLayoutTypeCommand?: UpdateCompartmentLayoutTypeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompartmentLayoutType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCompartmentLayoutTypesIdPut(id, apiVersion, updateCompartmentLayoutTypeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompartmentLayoutTypeApi.pimCompartmentLayoutTypesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateCompartmentLayoutTypeCommand} [createCompartmentLayoutTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCompartmentLayoutTypesPost(apiVersion?: string, createCompartmentLayoutTypeCommand?: CreateCompartmentLayoutTypeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompartmentLayoutType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCompartmentLayoutTypesPost(apiVersion, createCompartmentLayoutTypeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompartmentLayoutTypeApi.pimCompartmentLayoutTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * CompartmentLayoutTypeApi - factory interface
        * @export
        */
        export const CompartmentLayoutTypeApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = CompartmentLayoutTypeApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCompartmentLayoutTypesGet(apiVersion?: string, options?: any): AxiosPromise<Array<CompartmentLayoutType>> {
                return localVarFp.pimCompartmentLayoutTypesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCompartmentLayoutTypesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimCompartmentLayoutTypesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCompartmentLayoutTypesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<CompartmentLayoutType> {
                return localVarFp.pimCompartmentLayoutTypesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCompartmentLayoutTypeCommand} [updateCompartmentLayoutTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCompartmentLayoutTypesIdPut(id: string, apiVersion?: string, updateCompartmentLayoutTypeCommand?: UpdateCompartmentLayoutTypeCommand, options?: any): AxiosPromise<CompartmentLayoutType> {
                return localVarFp.pimCompartmentLayoutTypesIdPut(id, apiVersion, updateCompartmentLayoutTypeCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCompartmentLayoutTypeCommand} [createCompartmentLayoutTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCompartmentLayoutTypesPost(apiVersion?: string, createCompartmentLayoutTypeCommand?: CreateCompartmentLayoutTypeCommand, options?: any): AxiosPromise<CompartmentLayoutType> {
                return localVarFp.pimCompartmentLayoutTypesPost(apiVersion, createCompartmentLayoutTypeCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * CompartmentLayoutTypeApi - interface
            * @export
            * @interface CompartmentLayoutTypeApi
            */
            export interface CompartmentLayoutTypeApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CompartmentLayoutTypeApiInterface
                */
            pimCompartmentLayoutTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CompartmentLayoutType>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CompartmentLayoutTypeApiInterface
                */
            pimCompartmentLayoutTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CompartmentLayoutTypeApiInterface
                */
            pimCompartmentLayoutTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CompartmentLayoutType>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCompartmentLayoutTypeCommand} [updateCompartmentLayoutTypeCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CompartmentLayoutTypeApiInterface
                */
            pimCompartmentLayoutTypesIdPut(id: string, apiVersion?: string, updateCompartmentLayoutTypeCommand?: UpdateCompartmentLayoutTypeCommand, options?: RawAxiosRequestConfig): AxiosPromise<CompartmentLayoutType>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCompartmentLayoutTypeCommand} [createCompartmentLayoutTypeCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CompartmentLayoutTypeApiInterface
                */
            pimCompartmentLayoutTypesPost(apiVersion?: string, createCompartmentLayoutTypeCommand?: CreateCompartmentLayoutTypeCommand, options?: RawAxiosRequestConfig): AxiosPromise<CompartmentLayoutType>;

            }

        /**
        * CompartmentLayoutTypeApi - object-oriented interface
        * @export
        * @class CompartmentLayoutTypeApi
        * @extends {BaseAPI}
        */
            export class CompartmentLayoutTypeApi extends BaseAPI implements CompartmentLayoutTypeApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CompartmentLayoutTypeApi
            */
                public pimCompartmentLayoutTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CompartmentLayoutTypeApiFp(this.configuration).pimCompartmentLayoutTypesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CompartmentLayoutTypeApi
            */
                public pimCompartmentLayoutTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CompartmentLayoutTypeApiFp(this.configuration).pimCompartmentLayoutTypesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CompartmentLayoutTypeApi
            */
                public pimCompartmentLayoutTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CompartmentLayoutTypeApiFp(this.configuration).pimCompartmentLayoutTypesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCompartmentLayoutTypeCommand} [updateCompartmentLayoutTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CompartmentLayoutTypeApi
            */
                public pimCompartmentLayoutTypesIdPut(id: string, apiVersion?: string, updateCompartmentLayoutTypeCommand?: UpdateCompartmentLayoutTypeCommand, options?: RawAxiosRequestConfig) {
                return CompartmentLayoutTypeApiFp(this.configuration).pimCompartmentLayoutTypesIdPut(id, apiVersion, updateCompartmentLayoutTypeCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCompartmentLayoutTypeCommand} [createCompartmentLayoutTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CompartmentLayoutTypeApi
            */
                public pimCompartmentLayoutTypesPost(apiVersion?: string, createCompartmentLayoutTypeCommand?: CreateCompartmentLayoutTypeCommand, options?: RawAxiosRequestConfig) {
                return CompartmentLayoutTypeApiFp(this.configuration).pimCompartmentLayoutTypesPost(apiVersion, createCompartmentLayoutTypeCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ConfiguratorApi - axios parameter creator
    * @export
    */
    export const ConfiguratorApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} sillId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} profileId 
            * @param {string} [name] 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        addFavoriteProfile: async (sillId: string, compartmentLayoutTypeId: string, profileId: string, name?: string, customerId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('addFavoriteProfile', 'sillId', sillId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('addFavoriteProfile', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
                    // verify required parameter 'profileId' is not null or undefined
                    assertParamExists('addFavoriteProfile', 'profileId', profileId)
            const localVarPath = `/configurator/preferences/favorite-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (sillId !== undefined) {
                                    localVarQueryParameter['sillId'] = sillId;
                    }

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (profileId !== undefined) {
                                    localVarQueryParameter['profileId'] = profileId;
                    }

                    if (name !== undefined) {
                                    localVarQueryParameter['name'] = name;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {NeutDrillHolePositioningStrategyName} [positioningStrategyName] 
            * @param {string} [apiVersion] The requested API version
            * @param {SillConfiguration} [sillConfiguration] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        calculateDrillHoles: async (positioningStrategyName?: NeutDrillHolePositioningStrategyName, apiVersion?: string, sillConfiguration?: SillConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configurator/calculate-drill-holes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (positioningStrategyName !== undefined) {
                                        localVarQueryParameter['positioningStrategyName'] = positioningStrategyName;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(sillConfiguration, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        clearDefaultCompartmentProfile: async (sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('clearDefaultCompartmentProfile', 'sillId', sillId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('clearDefaultCompartmentProfile', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/configurator/preferences/{sillId}/default-compartment-profile`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} profileId 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteFavoriteProfile: async (sillId: string, compartmentLayoutTypeId: string, profileId: string, customerId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('deleteFavoriteProfile', 'sillId', sillId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('deleteFavoriteProfile', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
                    // verify required parameter 'profileId' is not null or undefined
                    assertParamExists('deleteFavoriteProfile', 'profileId', profileId)
            const localVarPath = `/configurator/preferences/favorite-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (sillId !== undefined) {
                                    localVarQueryParameter['sillId'] = sillId;
                    }

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (profileId !== undefined) {
                                    localVarQueryParameter['profileId'] = profileId;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [left] 
            * @param {string} [right] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getCompartmentProfiles: async (left?: string, right?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configurator/compartment-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (left !== undefined) {
                                    localVarQueryParameter['left'] = left;
                    }

                    if (right !== undefined) {
                                    localVarQueryParameter['right'] = right;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [sillId] 
            * @param {string} [configurationId] 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getConfigurator: async (sillId?: string, configurationId?: string, customerId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configurator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (sillId !== undefined) {
                                    localVarQueryParameter['sillId'] = sillId;
                    }

                    if (configurationId !== undefined) {
                                    localVarQueryParameter['configurationId'] = configurationId;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} neutPositionId 
            * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
            * @param {string} [customerId] 
            * @param {number} [width] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getDefaultCompartment: async (sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, width?: number, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('getDefaultCompartment', 'sillId', sillId)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('getDefaultCompartment', 'neutPositionId', neutPositionId)
                    // verify required parameter 'compartmentLayoutTypeCode' is not null or undefined
                    assertParamExists('getDefaultCompartment', 'compartmentLayoutTypeCode', compartmentLayoutTypeCode)
            const localVarPath = `/configurator/default-compartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (sillId !== undefined) {
                                    localVarQueryParameter['sillId'] = sillId;
                    }

                    if (neutPositionId !== undefined) {
                                    localVarQueryParameter['neutPositionId'] = neutPositionId;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (compartmentLayoutTypeCode !== undefined) {
                                        localVarQueryParameter['compartmentLayoutTypeCode'] = compartmentLayoutTypeCode;
                    }

                    if (width !== undefined) {
                                    localVarQueryParameter['width'] = width;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getSluitpotHardware: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configurator/sluitpot-hardware`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getSluitpotHardwareImage: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getSluitpotHardwareImage', 'id', id)
            const localVarPath = `/configurator/sluitpot-hardware/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} profileId 
            * @param {string} body 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renameFavoriteProfile: async (sillId: string, compartmentLayoutTypeId: string, profileId: string, body: string, customerId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('renameFavoriteProfile', 'sillId', sillId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('renameFavoriteProfile', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
                    // verify required parameter 'profileId' is not null or undefined
                    assertParamExists('renameFavoriteProfile', 'profileId', profileId)
                    // verify required parameter 'body' is not null or undefined
                    assertParamExists('renameFavoriteProfile', 'body', body)
            const localVarPath = `/configurator/preferences/favorite-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (sillId !== undefined) {
                                    localVarQueryParameter['sillId'] = sillId;
                    }

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (profileId !== undefined) {
                                    localVarQueryParameter['profileId'] = profileId;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        saveConfiguration: async (apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configurator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(validateConfigurationRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} neutPositionId 
            * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
            * @param {string} [customerId] 
            * @param {RabbetPosition} [rabbetPosition] 
            * @param {Array<string>} [filters] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        searchProfiles: async (sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, rabbetPosition?: RabbetPosition, filters?: Array<string>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('searchProfiles', 'sillId', sillId)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('searchProfiles', 'neutPositionId', neutPositionId)
                    // verify required parameter 'compartmentLayoutTypeCode' is not null or undefined
                    assertParamExists('searchProfiles', 'compartmentLayoutTypeCode', compartmentLayoutTypeCode)
            const localVarPath = `/configurator/compartment-profiles/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (sillId !== undefined) {
                                    localVarQueryParameter['sillId'] = sillId;
                    }

                    if (neutPositionId !== undefined) {
                                    localVarQueryParameter['neutPositionId'] = neutPositionId;
                    }

                    if (compartmentLayoutTypeCode !== undefined) {
                                        localVarQueryParameter['compartmentLayoutTypeCode'] = compartmentLayoutTypeCode;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (rabbetPosition !== undefined) {
                                        localVarQueryParameter['rabbetPosition'] = rabbetPosition;
                    }

                    if (filters) {
                            localVarQueryParameter['filters'] = filters;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
            * @param {SetDefaultCompartmentProfileRequest} [setDefaultCompartmentProfileRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        setDefaultCompartmentProfile: async (sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, setDefaultCompartmentProfileRequest?: SetDefaultCompartmentProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('setDefaultCompartmentProfile', 'sillId', sillId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('setDefaultCompartmentProfile', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/configurator/preferences/{sillId}/default-compartment-profile`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(setDefaultCompartmentProfileRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
            * @param {SetDefaultWallConnection} [setDefaultWallConnection] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        setDefaultWallConnection: async (sillId: string, customerId?: string, apiVersion?: string, setDefaultWallConnection?: SetDefaultWallConnection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('setDefaultWallConnection', 'sillId', sillId)
            const localVarPath = `/configurator/preferences/{sillId}/default-wall-connection`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(setDefaultWallConnection, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [priceListId] 
            * @param {string} [apiVersion] The requested API version
            * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        validateConfiguration: async (priceListId?: string, apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configurator/validate-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (priceListId !== undefined) {
                                    localVarQueryParameter['priceListId'] = priceListId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(validateConfigurationRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [width] 
            * @param {boolean} [right] 
            * @param {string} [apiVersion] The requested API version
            * @param {ValidateWallConnectionRequest} [validateWallConnectionRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        validateWallConnection: async (width?: number, right?: boolean, apiVersion?: string, validateWallConnectionRequest?: ValidateWallConnectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configurator/validate-wall-connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (width !== undefined) {
                                    localVarQueryParameter['width'] = width;
                    }

                    if (right !== undefined) {
                                    localVarQueryParameter['right'] = right;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(validateWallConnectionRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ConfiguratorApi - functional programming interface
        * @export
        */
        export const ConfiguratorApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ConfiguratorApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} sillId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} profileId 
                * @param {string} [name] 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async addFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, name?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, name, customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.addFavoriteProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {NeutDrillHolePositioningStrategyName} [positioningStrategyName] 
                * @param {string} [apiVersion] The requested API version
                * @param {SillConfiguration} [sillConfiguration] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async calculateDrillHoles(positioningStrategyName?: NeutDrillHolePositioningStrategyName, apiVersion?: string, sillConfiguration?: SillConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<Point>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateDrillHoles(positioningStrategyName, apiVersion, sillConfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.calculateDrillHoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async clearDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearDefaultCompartmentProfile(sillId, compartmentLayoutTypeId, customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.clearDefaultCompartmentProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} profileId 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.deleteFavoriteProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [left] 
                * @param {string} [right] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getCompartmentProfiles(left?: string, right?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfiguratorCompartmentProfiles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompartmentProfiles(left, right, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.getCompartmentProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [sillId] 
                * @param {string} [configurationId] 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getConfigurator(sillId?: string, configurationId?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfiguratorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigurator(sillId, configurationId, customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.getConfigurator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} neutPositionId 
                * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                * @param {string} [customerId] 
                * @param {number} [width] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getDefaultCompartment(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, width?: number, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillConfigurationCompartment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultCompartment(sillId, neutPositionId, compartmentLayoutTypeCode, customerId, width, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.getDefaultCompartment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getSluitpotHardware(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfiguratorSluitpotBeslag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSluitpotHardware(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.getSluitpotHardware']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getSluitpotHardwareImage(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSluitpotHardwareImage(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.getSluitpotHardwareImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} profileId 
                * @param {string} body 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renameFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, body: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, body, customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.renameFavoriteProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async saveConfiguration(apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveConfiguratorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveConfiguration(apiVersion, validateConfigurationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.saveConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} neutPositionId 
                * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                * @param {string} [customerId] 
                * @param {RabbetPosition} [rabbetPosition] 
                * @param {Array<string>} [filters] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async searchProfiles(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, rabbetPosition?: RabbetPosition, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfiguratorProfilePickerProfileProfileSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProfiles(sillId, neutPositionId, compartmentLayoutTypeCode, customerId, rabbetPosition, filters, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.searchProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
                * @param {SetDefaultCompartmentProfileRequest} [setDefaultCompartmentProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async setDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, setDefaultCompartmentProfileRequest?: SetDefaultCompartmentProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDefaultCompartmentProfile(sillId, compartmentLayoutTypeId, customerId, apiVersion, setDefaultCompartmentProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.setDefaultCompartmentProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
                * @param {SetDefaultWallConnection} [setDefaultWallConnection] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async setDefaultWallConnection(sillId: string, customerId?: string, apiVersion?: string, setDefaultWallConnection?: SetDefaultWallConnection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDefaultWallConnection(sillId, customerId, apiVersion, setDefaultWallConnection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.setDefaultWallConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [priceListId] 
                * @param {string} [apiVersion] The requested API version
                * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async validateConfiguration(priceListId?: string, apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateConfiguration(priceListId, apiVersion, validateConfigurationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.validateConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [width] 
                * @param {boolean} [right] 
                * @param {string} [apiVersion] The requested API version
                * @param {ValidateWallConnectionRequest} [validateWallConnectionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async validateWallConnection(width?: number, right?: boolean, apiVersion?: string, validateWallConnectionRequest?: ValidateWallConnectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateWallConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateWallConnection(width, right, apiVersion, validateWallConnectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguratorApi.validateWallConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ConfiguratorApi - factory interface
        * @export
        */
        export const ConfiguratorApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ConfiguratorApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} [name] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            addFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, name?: string, customerId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.addFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, name, customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {NeutDrillHolePositioningStrategyName} [positioningStrategyName] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SillConfiguration} [sillConfiguration] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            calculateDrillHoles(positioningStrategyName?: NeutDrillHolePositioningStrategyName, apiVersion?: string, sillConfiguration?: SillConfiguration, options?: any): AxiosPromise<Array<Array<Point>>> {
                return localVarFp.calculateDrillHoles(positioningStrategyName, apiVersion, sillConfiguration, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            clearDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.clearDefaultCompartmentProfile(sillId, compartmentLayoutTypeId, customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, customerId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deleteFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [left] 
                    * @param {string} [right] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getCompartmentProfiles(left?: string, right?: string, apiVersion?: string, options?: any): AxiosPromise<ConfiguratorCompartmentProfiles> {
                return localVarFp.getCompartmentProfiles(left, right, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [sillId] 
                    * @param {string} [configurationId] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getConfigurator(sillId?: string, configurationId?: string, customerId?: string, apiVersion?: string, options?: any): AxiosPromise<GetConfiguratorResponse> {
                return localVarFp.getConfigurator(sillId, configurationId, customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                    * @param {string} [customerId] 
                    * @param {number} [width] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getDefaultCompartment(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, width?: number, apiVersion?: string, options?: any): AxiosPromise<SillConfigurationCompartment> {
                return localVarFp.getDefaultCompartment(sillId, neutPositionId, compartmentLayoutTypeCode, customerId, width, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getSluitpotHardware(apiVersion?: string, options?: any): AxiosPromise<Array<ConfiguratorSluitpotBeslag>> {
                return localVarFp.getSluitpotHardware(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getSluitpotHardwareImage(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.getSluitpotHardwareImage(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} body 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renameFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, body: string, customerId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.renameFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, body, customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            saveConfiguration(apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: any): AxiosPromise<SaveConfiguratorResponse> {
                return localVarFp.saveConfiguration(apiVersion, validateConfigurationRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                    * @param {string} [customerId] 
                    * @param {RabbetPosition} [rabbetPosition] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            searchProfiles(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, rabbetPosition?: RabbetPosition, filters?: Array<string>, apiVersion?: string, options?: any): AxiosPromise<ConfiguratorProfilePickerProfileProfileSearchResult> {
                return localVarFp.searchProfiles(sillId, neutPositionId, compartmentLayoutTypeCode, customerId, rabbetPosition, filters, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SetDefaultCompartmentProfileRequest} [setDefaultCompartmentProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            setDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, setDefaultCompartmentProfileRequest?: SetDefaultCompartmentProfileRequest, options?: any): AxiosPromise<void> {
                return localVarFp.setDefaultCompartmentProfile(sillId, compartmentLayoutTypeId, customerId, apiVersion, setDefaultCompartmentProfileRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SetDefaultWallConnection} [setDefaultWallConnection] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            setDefaultWallConnection(sillId: string, customerId?: string, apiVersion?: string, setDefaultWallConnection?: SetDefaultWallConnection, options?: any): AxiosPromise<void> {
                return localVarFp.setDefaultWallConnection(sillId, customerId, apiVersion, setDefaultWallConnection, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [priceListId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            validateConfiguration(priceListId?: string, apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: any): AxiosPromise<ValidateConfigurationResponse> {
                return localVarFp.validateConfiguration(priceListId, apiVersion, validateConfigurationRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [width] 
                    * @param {boolean} [right] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateWallConnectionRequest} [validateWallConnectionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            validateWallConnection(width?: number, right?: boolean, apiVersion?: string, validateWallConnectionRequest?: ValidateWallConnectionRequest, options?: any): AxiosPromise<ValidateWallConnectionResult> {
                return localVarFp.validateWallConnection(width, right, apiVersion, validateWallConnectionRequest, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ConfiguratorApi - interface
            * @export
            * @interface ConfiguratorApi
            */
            export interface ConfiguratorApiInterface {
                /**
                * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} [name] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            addFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, name?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {NeutDrillHolePositioningStrategyName} [positioningStrategyName] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SillConfiguration} [sillConfiguration] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            calculateDrillHoles(positioningStrategyName?: NeutDrillHolePositioningStrategyName, apiVersion?: string, sillConfiguration?: SillConfiguration, options?: RawAxiosRequestConfig): AxiosPromise<Array<Array<Point>>>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            clearDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            deleteFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [left] 
                    * @param {string} [right] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            getCompartmentProfiles(left?: string, right?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ConfiguratorCompartmentProfiles>;

                /**
                * 
                    * @param {string} [sillId] 
                    * @param {string} [configurationId] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            getConfigurator(sillId?: string, configurationId?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetConfiguratorResponse>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                    * @param {string} [customerId] 
                    * @param {number} [width] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            getDefaultCompartment(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, width?: number, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SillConfigurationCompartment>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            getSluitpotHardware(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ConfiguratorSluitpotBeslag>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            getSluitpotHardwareImage(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} body 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            renameFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, body: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            saveConfiguration(apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<SaveConfiguratorResponse>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                    * @param {string} [customerId] 
                    * @param {RabbetPosition} [rabbetPosition] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            searchProfiles(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, rabbetPosition?: RabbetPosition, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ConfiguratorProfilePickerProfileProfileSearchResult>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SetDefaultCompartmentProfileRequest} [setDefaultCompartmentProfileRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            setDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, setDefaultCompartmentProfileRequest?: SetDefaultCompartmentProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SetDefaultWallConnection} [setDefaultWallConnection] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            setDefaultWallConnection(sillId: string, customerId?: string, apiVersion?: string, setDefaultWallConnection?: SetDefaultWallConnection, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [priceListId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            validateConfiguration(priceListId?: string, apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ValidateConfigurationResponse>;

                /**
                * 
                    * @param {number} [width] 
                    * @param {boolean} [right] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateWallConnectionRequest} [validateWallConnectionRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguratorApiInterface
                */
            validateWallConnection(width?: number, right?: boolean, apiVersion?: string, validateWallConnectionRequest?: ValidateWallConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ValidateWallConnectionResult>;

            }

        /**
        * ConfiguratorApi - object-oriented interface
        * @export
        * @class ConfiguratorApi
        * @extends {BaseAPI}
        */
            export class ConfiguratorApi extends BaseAPI implements ConfiguratorApiInterface {
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} [name] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public addFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, name?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).addFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, name, customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {NeutDrillHolePositioningStrategyName} [positioningStrategyName] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SillConfiguration} [sillConfiguration] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public calculateDrillHoles(positioningStrategyName?: NeutDrillHolePositioningStrategyName, apiVersion?: string, sillConfiguration?: SillConfiguration, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).calculateDrillHoles(positioningStrategyName, apiVersion, sillConfiguration, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public clearDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).clearDefaultCompartmentProfile(sillId, compartmentLayoutTypeId, customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public deleteFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).deleteFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [left] 
                    * @param {string} [right] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public getCompartmentProfiles(left?: string, right?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).getCompartmentProfiles(left, right, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [sillId] 
                    * @param {string} [configurationId] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public getConfigurator(sillId?: string, configurationId?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).getConfigurator(sillId, configurationId, customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                    * @param {string} [customerId] 
                    * @param {number} [width] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public getDefaultCompartment(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, width?: number, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).getDefaultCompartment(sillId, neutPositionId, compartmentLayoutTypeCode, customerId, width, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public getSluitpotHardware(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).getSluitpotHardware(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public getSluitpotHardwareImage(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).getSluitpotHardwareImage(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} profileId 
                    * @param {string} body 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public renameFavoriteProfile(sillId: string, compartmentLayoutTypeId: string, profileId: string, body: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).renameFavoriteProfile(sillId, compartmentLayoutTypeId, profileId, body, customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public saveConfiguration(apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).saveConfiguration(apiVersion, validateConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {CompartmentLayoutTypeCode} compartmentLayoutTypeCode 
                    * @param {string} [customerId] 
                    * @param {RabbetPosition} [rabbetPosition] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public searchProfiles(sillId: string, neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, customerId?: string, rabbetPosition?: RabbetPosition, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).searchProfiles(sillId, neutPositionId, compartmentLayoutTypeCode, customerId, rabbetPosition, filters, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SetDefaultCompartmentProfileRequest} [setDefaultCompartmentProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public setDefaultCompartmentProfile(sillId: string, compartmentLayoutTypeId: string, customerId?: string, apiVersion?: string, setDefaultCompartmentProfileRequest?: SetDefaultCompartmentProfileRequest, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).setDefaultCompartmentProfile(sillId, compartmentLayoutTypeId, customerId, apiVersion, setDefaultCompartmentProfileRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SetDefaultWallConnection} [setDefaultWallConnection] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public setDefaultWallConnection(sillId: string, customerId?: string, apiVersion?: string, setDefaultWallConnection?: SetDefaultWallConnection, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).setDefaultWallConnection(sillId, customerId, apiVersion, setDefaultWallConnection, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [priceListId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateConfigurationRequest} [validateConfigurationRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public validateConfiguration(priceListId?: string, apiVersion?: string, validateConfigurationRequest?: ValidateConfigurationRequest, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).validateConfiguration(priceListId, apiVersion, validateConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [width] 
                    * @param {boolean} [right] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {ValidateWallConnectionRequest} [validateWallConnectionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguratorApi
            */
                public validateWallConnection(width?: number, right?: boolean, apiVersion?: string, validateWallConnectionRequest?: ValidateWallConnectionRequest, options?: RawAxiosRequestConfig) {
                return ConfiguratorApiFp(this.configuration).validateWallConnection(width, right, apiVersion, validateWallConnectionRequest, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ConfiguredNeutApi - axios parameter creator
    * @export
    */
    export const ConfiguredNeutApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getById: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getById', 'id', id)
            const localVarPath = `/core/configured-neut/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [filter] 
            * @param {string} [searchTerm] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        neutenList: async ($top?: number, $skip?: number, $orderby?: string, filter?: string, searchTerm?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/configured-neut/neuten-list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if (filter !== undefined) {
                                    localVarQueryParameter['filter'] = filter;
                    }

                    if (searchTerm !== undefined) {
                                    localVarQueryParameter['searchTerm'] = searchTerm;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        rehash: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('rehash', 'id', id)
            const localVarPath = `/core/configured-neut/{id}/rehash`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ConfiguredNeutApi - functional programming interface
        * @export
        */
        export const ConfiguredNeutApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ConfiguredNeutApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getById(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeutRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredNeutApi.getById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [filter] 
                * @param {string} [searchTerm] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async neutenList($top?: number, $skip?: number, $orderby?: string, filter?: string, searchTerm?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeutListItemArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neutenList($top, $skip, $orderby, filter, searchTerm, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredNeutApi.neutenList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async rehash(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfiguredNeutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rehash(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredNeutApi.rehash']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ConfiguredNeutApi - factory interface
        * @export
        */
        export const ConfiguredNeutApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ConfiguredNeutApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getById(id: string, apiVersion?: string, options?: any): AxiosPromise<NeutRepresentation> {
                return localVarFp.getById(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [filter] 
                    * @param {string} [searchTerm] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            neutenList($top?: number, $skip?: number, $orderby?: string, filter?: string, searchTerm?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<NeutListItemArrayODataValue> {
                return localVarFp.neutenList($top, $skip, $orderby, filter, searchTerm, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            rehash(id: string, apiVersion?: string, options?: any): AxiosPromise<ConfiguredNeutResponse> {
                return localVarFp.rehash(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ConfiguredNeutApi - interface
            * @export
            * @interface ConfiguredNeutApi
            */
            export interface ConfiguredNeutApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredNeutApiInterface
                */
            getById(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<NeutRepresentation>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [filter] 
                    * @param {string} [searchTerm] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredNeutApiInterface
                */
            neutenList($top?: number, $skip?: number, $orderby?: string, filter?: string, searchTerm?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<NeutListItemArrayODataValue>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredNeutApiInterface
                */
            rehash(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ConfiguredNeutResponse>;

            }

        /**
        * ConfiguredNeutApi - object-oriented interface
        * @export
        * @class ConfiguredNeutApi
        * @extends {BaseAPI}
        */
            export class ConfiguredNeutApi extends BaseAPI implements ConfiguredNeutApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredNeutApi
            */
                public getById(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredNeutApiFp(this.configuration).getById(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [filter] 
                    * @param {string} [searchTerm] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredNeutApi
            */
                public neutenList($top?: number, $skip?: number, $orderby?: string, filter?: string, searchTerm?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredNeutApiFp(this.configuration).neutenList($top, $skip, $orderby, filter, searchTerm, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredNeutApi
            */
                public rehash(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredNeutApiFp(this.configuration).rehash(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ConfiguredSillApi - axios parameter creator
    * @export
    */
    export const ConfiguredSillApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        configuredSillList: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/configured-sill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [leftId] 
            * @param {string} [rightId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        diff: async (leftId?: string, rightId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/configured-sill/diff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (leftId !== undefined) {
                                    localVarQueryParameter['leftId'] = leftId;
                    }

                    if (rightId !== undefined) {
                                    localVarQueryParameter['rightId'] = rightId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getForCatalogItem: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getForCatalogItem', 'id', id)
            const localVarPath = `/core/configured-sill/catalog-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        recalculate: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('recalculate', 'id', id)
            const localVarPath = `/core/configured-sill/{id}/recalculated`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        sillGetById: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('sillGetById', 'id', id)
            const localVarPath = `/core/configured-sill/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ConfiguredSillApi - functional programming interface
        * @export
        */
        export const ConfiguredSillApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ConfiguredSillApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async configuredSillList(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfiguredSillsListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configuredSillList(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredSillApi.configuredSillList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [leftId] 
                * @param {string} [rightId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async diff(leftId?: string, rightId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfiguredSillDiffResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diff(leftId, rightId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredSillApi.diff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getForCatalogItem(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfiguredSillResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForCatalogItem(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredSillApi.getForCatalogItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async recalculate(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecalculateConfiguredSillResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recalculate(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredSillApi.recalculate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async sillGetById(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfiguredSillResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sillGetById(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfiguredSillApi.sillGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ConfiguredSillApi - factory interface
        * @export
        */
        export const ConfiguredSillApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ConfiguredSillApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            configuredSillList(apiVersion?: string, options?: any): AxiosPromise<Array<ConfiguredSillsListItem>> {
                return localVarFp.configuredSillList(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [leftId] 
                    * @param {string} [rightId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            diff(leftId?: string, rightId?: string, apiVersion?: string, options?: any): AxiosPromise<ConfiguredSillDiffResult> {
                return localVarFp.diff(leftId, rightId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getForCatalogItem(id: string, apiVersion?: string, options?: any): AxiosPromise<ConfiguredSillResponse> {
                return localVarFp.getForCatalogItem(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            recalculate(id: string, apiVersion?: string, options?: any): AxiosPromise<RecalculateConfiguredSillResponse> {
                return localVarFp.recalculate(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            sillGetById(id: string, apiVersion?: string, options?: any): AxiosPromise<ConfiguredSillResponse> {
                return localVarFp.sillGetById(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ConfiguredSillApi - interface
            * @export
            * @interface ConfiguredSillApi
            */
            export interface ConfiguredSillApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredSillApiInterface
                */
            configuredSillList(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ConfiguredSillsListItem>>;

                /**
                * 
                    * @param {string} [leftId] 
                    * @param {string} [rightId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredSillApiInterface
                */
            diff(leftId?: string, rightId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ConfiguredSillDiffResult>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredSillApiInterface
                */
            getForCatalogItem(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ConfiguredSillResponse>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredSillApiInterface
                */
            recalculate(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<RecalculateConfiguredSillResponse>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ConfiguredSillApiInterface
                */
            sillGetById(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ConfiguredSillResponse>;

            }

        /**
        * ConfiguredSillApi - object-oriented interface
        * @export
        * @class ConfiguredSillApi
        * @extends {BaseAPI}
        */
            export class ConfiguredSillApi extends BaseAPI implements ConfiguredSillApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredSillApi
            */
                public configuredSillList(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredSillApiFp(this.configuration).configuredSillList(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [leftId] 
                    * @param {string} [rightId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredSillApi
            */
                public diff(leftId?: string, rightId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredSillApiFp(this.configuration).diff(leftId, rightId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredSillApi
            */
                public getForCatalogItem(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredSillApiFp(this.configuration).getForCatalogItem(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredSillApi
            */
                public recalculate(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredSillApiFp(this.configuration).recalculate(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ConfiguredSillApi
            */
                public sillGetById(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ConfiguredSillApiFp(this.configuration).sillGetById(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * CornerPieceApi - axios parameter creator
    * @export
    */
    export const CornerPieceApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCornerPiecesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/corner-pieces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCornerPiecesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCornerPiecesIdDelete', 'id', id)
            const localVarPath = `/pim/corner-pieces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCornerPiecesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCornerPiecesIdGet', 'id', id)
            const localVarPath = `/pim/corner-pieces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateCornerPieceCommand} [updateCornerPieceCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCornerPiecesIdPut: async (id: string, apiVersion?: string, updateCornerPieceCommand?: UpdateCornerPieceCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimCornerPiecesIdPut', 'id', id)
            const localVarPath = `/pim/corner-pieces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateCornerPieceCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateCornerPieceCommand} [createCornerPieceCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimCornerPiecesPost: async (apiVersion?: string, createCornerPieceCommand?: CreateCornerPieceCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/corner-pieces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createCornerPieceCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * CornerPieceApi - functional programming interface
        * @export
        */
        export const CornerPieceApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = CornerPieceApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCornerPiecesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CornerPiece>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCornerPiecesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CornerPieceApi.pimCornerPiecesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCornerPiecesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCornerPiecesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CornerPieceApi.pimCornerPiecesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCornerPiecesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CornerPiece>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCornerPiecesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CornerPieceApi.pimCornerPiecesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateCornerPieceCommand} [updateCornerPieceCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCornerPiecesIdPut(id: string, apiVersion?: string, updateCornerPieceCommand?: UpdateCornerPieceCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CornerPiece>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCornerPiecesIdPut(id, apiVersion, updateCornerPieceCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CornerPieceApi.pimCornerPiecesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateCornerPieceCommand} [createCornerPieceCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimCornerPiecesPost(apiVersion?: string, createCornerPieceCommand?: CreateCornerPieceCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CornerPiece>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimCornerPiecesPost(apiVersion, createCornerPieceCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CornerPieceApi.pimCornerPiecesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * CornerPieceApi - factory interface
        * @export
        */
        export const CornerPieceApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = CornerPieceApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCornerPiecesGet(apiVersion?: string, options?: any): AxiosPromise<Array<CornerPiece>> {
                return localVarFp.pimCornerPiecesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCornerPiecesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimCornerPiecesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCornerPiecesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<CornerPiece> {
                return localVarFp.pimCornerPiecesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCornerPieceCommand} [updateCornerPieceCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCornerPiecesIdPut(id: string, apiVersion?: string, updateCornerPieceCommand?: UpdateCornerPieceCommand, options?: any): AxiosPromise<CornerPiece> {
                return localVarFp.pimCornerPiecesIdPut(id, apiVersion, updateCornerPieceCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCornerPieceCommand} [createCornerPieceCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimCornerPiecesPost(apiVersion?: string, createCornerPieceCommand?: CreateCornerPieceCommand, options?: any): AxiosPromise<CornerPiece> {
                return localVarFp.pimCornerPiecesPost(apiVersion, createCornerPieceCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * CornerPieceApi - interface
            * @export
            * @interface CornerPieceApi
            */
            export interface CornerPieceApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CornerPieceApiInterface
                */
            pimCornerPiecesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CornerPiece>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CornerPieceApiInterface
                */
            pimCornerPiecesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CornerPieceApiInterface
                */
            pimCornerPiecesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CornerPiece>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCornerPieceCommand} [updateCornerPieceCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CornerPieceApiInterface
                */
            pimCornerPiecesIdPut(id: string, apiVersion?: string, updateCornerPieceCommand?: UpdateCornerPieceCommand, options?: RawAxiosRequestConfig): AxiosPromise<CornerPiece>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCornerPieceCommand} [createCornerPieceCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CornerPieceApiInterface
                */
            pimCornerPiecesPost(apiVersion?: string, createCornerPieceCommand?: CreateCornerPieceCommand, options?: RawAxiosRequestConfig): AxiosPromise<CornerPiece>;

            }

        /**
        * CornerPieceApi - object-oriented interface
        * @export
        * @class CornerPieceApi
        * @extends {BaseAPI}
        */
            export class CornerPieceApi extends BaseAPI implements CornerPieceApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CornerPieceApi
            */
                public pimCornerPiecesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CornerPieceApiFp(this.configuration).pimCornerPiecesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CornerPieceApi
            */
                public pimCornerPiecesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CornerPieceApiFp(this.configuration).pimCornerPiecesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CornerPieceApi
            */
                public pimCornerPiecesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CornerPieceApiFp(this.configuration).pimCornerPiecesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateCornerPieceCommand} [updateCornerPieceCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CornerPieceApi
            */
                public pimCornerPiecesIdPut(id: string, apiVersion?: string, updateCornerPieceCommand?: UpdateCornerPieceCommand, options?: RawAxiosRequestConfig) {
                return CornerPieceApiFp(this.configuration).pimCornerPiecesIdPut(id, apiVersion, updateCornerPieceCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateCornerPieceCommand} [createCornerPieceCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CornerPieceApi
            */
                public pimCornerPiecesPost(apiVersion?: string, createCornerPieceCommand?: CreateCornerPieceCommand, options?: RawAxiosRequestConfig) {
                return CornerPieceApiFp(this.configuration).pimCornerPiecesPost(apiVersion, createCornerPieceCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * CustomerApi - axios parameter creator
    * @export
    */
    export const CustomerApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [cocNumber] 
            * @param {string} [cocDepartment] 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCocExistsGet: async (cocNumber?: string, cocDepartment?: string, customerId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/customer/coc-exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (cocNumber !== undefined) {
                                    localVarQueryParameter['cocNumber'] = cocNumber;
                    }

                    if (cocDepartment !== undefined) {
                                    localVarQueryParameter['cocDepartment'] = cocDepartment;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} addressId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdAddressAddressIdDelete: async (customerId: string, addressId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressAddressIdDelete', 'customerId', customerId)
                    // verify required parameter 'addressId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressAddressIdDelete', 'addressId', addressId)
            const localVarPath = `/crm/customer/{customerId}/address/{addressId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} addressId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdAddressAddressIdGet: async (customerId: string, addressId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressAddressIdGet', 'customerId', customerId)
                    // verify required parameter 'addressId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressAddressIdGet', 'addressId', addressId)
            const localVarPath = `/crm/customer/{customerId}/address/{addressId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} addressId 
            * @param {string} [apiVersion] The requested API version
            * @param {PutCustomerAddressRepresentation} [putCustomerAddressRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdAddressAddressIdPut: async (customerId: string, addressId: string, apiVersion?: string, putCustomerAddressRepresentation?: PutCustomerAddressRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressAddressIdPut', 'customerId', customerId)
                    // verify required parameter 'addressId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressAddressIdPut', 'addressId', addressId)
            const localVarPath = `/crm/customer/{customerId}/address/{addressId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putCustomerAddressRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdAddressListGet: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressListGet', 'customerId', customerId)
            const localVarPath = `/crm/customer/{customerId}/address/list`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
            * @param {PostCustomerAddressRepresentation} [postCustomerAddressRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdAddressPost: async (customerId: string, apiVersion?: string, postCustomerAddressRepresentation?: PostCustomerAddressRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdAddressPost', 'customerId', customerId)
            const localVarPath = `/crm/customer/{customerId}/address`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(postCustomerAddressRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdContactpersonListGet: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdContactpersonListGet', 'customerId', customerId)
            const localVarPath = `/crm/customer/{customerId}/contactperson/list`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdDefaultPricelistGet: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdDefaultPricelistGet', 'customerId', customerId)
            const localVarPath = `/crm/customer/{customerId}/default-pricelist`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdPricelistGet: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdPricelistGet', 'customerId', customerId)
            const localVarPath = `/crm/customer/{customerId}/pricelist`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerCustomerIdPricelistPdfGet: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('crmCustomerCustomerIdPricelistPdfGet', 'customerId', customerId)
            const localVarPath = `/crm/customer/{customerId}/pricelist/pdf`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerIdCardGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('crmCustomerIdCardGet', 'id', id)
            const localVarPath = `/crm/customer/{id}/card`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('crmCustomerIdDelete', 'id', id)
            const localVarPath = `/crm/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('crmCustomerIdGet', 'id', id)
            const localVarPath = `/crm/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutCustomerRepresentation} [putCustomerRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerIdPut: async (id: string, apiVersion?: string, putCustomerRepresentation?: PutCustomerRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('crmCustomerIdPut', 'id', id)
            const localVarPath = `/crm/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putCustomerRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [searchTerm] 
            * @param {boolean} [archived] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerListGet: async (searchTerm?: string, archived?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/customer/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (searchTerm !== undefined) {
                                    localVarQueryParameter['searchTerm'] = searchTerm;
                    }

                    if (archived !== undefined) {
                                    localVarQueryParameter['archived'] = archived;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerListOdataGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/customer/list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [companyName] 
            * @param {string} [customerId] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerNameExistsGet: async (companyName?: string, customerId?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/customer/name-exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (companyName !== undefined) {
                                    localVarQueryParameter['companyName'] = companyName;
                    }

                    if (customerId !== undefined) {
                                    localVarQueryParameter['customerId'] = customerId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PostCustomerRepresentation} [postCustomerRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        crmCustomerPost: async (apiVersion?: string, postCustomerRepresentation?: PostCustomerRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(postCustomerRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * CustomerApi - functional programming interface
        * @export
        */
        export const CustomerApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [cocNumber] 
                * @param {string} [cocDepartment] 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCocExistsGet(cocNumber?: string, cocDepartment?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCocExistsGet(cocNumber, cocDepartment, customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCocExistsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} addressId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdAddressAddressIdDelete(customerId: string, addressId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdAddressAddressIdDelete(customerId, addressId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdAddressAddressIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} addressId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdAddressAddressIdGet(customerId: string, addressId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAddressRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdAddressAddressIdGet(customerId, addressId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdAddressAddressIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} addressId 
                * @param {string} [apiVersion] The requested API version
                * @param {PutCustomerAddressRepresentation} [putCustomerAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdAddressAddressIdPut(customerId: string, addressId: string, apiVersion?: string, putCustomerAddressRepresentation?: PutCustomerAddressRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAddressRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdAddressAddressIdPut(customerId, addressId, apiVersion, putCustomerAddressRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdAddressAddressIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdAddressListGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerAddressRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdAddressListGet(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdAddressListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
                * @param {PostCustomerAddressRepresentation} [postCustomerAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdAddressPost(customerId: string, apiVersion?: string, postCustomerAddressRepresentation?: PostCustomerAddressRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAddressRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdAddressPost(customerId, apiVersion, postCustomerAddressRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdAddressPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdContactpersonListGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactPersonRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdContactpersonListGet(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdContactpersonListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdDefaultPricelistGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceListReference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdDefaultPricelistGet(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdDefaultPricelistGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdPricelistGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceListRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdPricelistGet(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdPricelistGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerCustomerIdPricelistPdfGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerCustomerIdPricelistPdfGet(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerCustomerIdPricelistPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerIdCardGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerIdCardGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerIdCardGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutCustomerRepresentation} [putCustomerRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerIdPut(id: string, apiVersion?: string, putCustomerRepresentation?: PutCustomerRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerIdPut(id, apiVersion, putCustomerRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [searchTerm] 
                * @param {boolean} [archived] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerListGet(searchTerm?: string, archived?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerListRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerListGet(searchTerm, archived, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerListViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerListOdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [companyName] 
                * @param {string} [customerId] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerNameExistsGet(companyName?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerNameExistsGet(companyName, customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerNameExistsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PostCustomerRepresentation} [postCustomerRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async crmCustomerPost(apiVersion?: string, postCustomerRepresentation?: PostCustomerRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmCustomerPost(apiVersion, postCustomerRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.crmCustomerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * CustomerApi - factory interface
        * @export
        */
        export const CustomerApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = CustomerApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [cocNumber] 
                    * @param {string} [cocDepartment] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCocExistsGet(cocNumber?: string, cocDepartment?: string, customerId?: string, apiVersion?: string, options?: any): AxiosPromise<Array<CustomerRepresentation>> {
                return localVarFp.crmCustomerCocExistsGet(cocNumber, cocDepartment, customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdAddressAddressIdDelete(customerId: string, addressId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.crmCustomerCustomerIdAddressAddressIdDelete(customerId, addressId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdAddressAddressIdGet(customerId: string, addressId: string, apiVersion?: string, options?: any): AxiosPromise<CustomerAddressRepresentation> {
                return localVarFp.crmCustomerCustomerIdAddressAddressIdGet(customerId, addressId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutCustomerAddressRepresentation} [putCustomerAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdAddressAddressIdPut(customerId: string, addressId: string, apiVersion?: string, putCustomerAddressRepresentation?: PutCustomerAddressRepresentation, options?: any): AxiosPromise<CustomerAddressRepresentation> {
                return localVarFp.crmCustomerCustomerIdAddressAddressIdPut(customerId, addressId, apiVersion, putCustomerAddressRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdAddressListGet(customerId: string, apiVersion?: string, options?: any): AxiosPromise<Array<CustomerAddressRepresentation>> {
                return localVarFp.crmCustomerCustomerIdAddressListGet(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostCustomerAddressRepresentation} [postCustomerAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdAddressPost(customerId: string, apiVersion?: string, postCustomerAddressRepresentation?: PostCustomerAddressRepresentation, options?: any): AxiosPromise<CustomerAddressRepresentation> {
                return localVarFp.crmCustomerCustomerIdAddressPost(customerId, apiVersion, postCustomerAddressRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdContactpersonListGet(customerId: string, apiVersion?: string, options?: any): AxiosPromise<Array<ContactPersonRepresentation>> {
                return localVarFp.crmCustomerCustomerIdContactpersonListGet(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdDefaultPricelistGet(customerId: string, apiVersion?: string, options?: any): AxiosPromise<PriceListReference> {
                return localVarFp.crmCustomerCustomerIdDefaultPricelistGet(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdPricelistGet(customerId: string, apiVersion?: string, options?: any): AxiosPromise<PriceListRepresentation> {
                return localVarFp.crmCustomerCustomerIdPricelistGet(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerCustomerIdPricelistPdfGet(customerId: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.crmCustomerCustomerIdPricelistPdfGet(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerIdCardGet(id: string, apiVersion?: string, options?: any): AxiosPromise<CustomerListView> {
                return localVarFp.crmCustomerIdCardGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.crmCustomerIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<CustomerRepresentation> {
                return localVarFp.crmCustomerIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutCustomerRepresentation} [putCustomerRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerIdPut(id: string, apiVersion?: string, putCustomerRepresentation?: PutCustomerRepresentation, options?: any): AxiosPromise<CustomerRepresentation> {
                return localVarFp.crmCustomerIdPut(id, apiVersion, putCustomerRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [searchTerm] 
                    * @param {boolean} [archived] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerListGet(searchTerm?: string, archived?: boolean, apiVersion?: string, options?: any): AxiosPromise<Array<CustomerListRepresentation>> {
                return localVarFp.crmCustomerListGet(searchTerm, archived, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<CustomerListViewArrayODataValue> {
                return localVarFp.crmCustomerListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [companyName] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerNameExistsGet(companyName?: string, customerId?: string, apiVersion?: string, options?: any): AxiosPromise<Array<CustomerRepresentation>> {
                return localVarFp.crmCustomerNameExistsGet(companyName, customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostCustomerRepresentation} [postCustomerRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            crmCustomerPost(apiVersion?: string, postCustomerRepresentation?: PostCustomerRepresentation, options?: any): AxiosPromise<CustomerRepresentation> {
                return localVarFp.crmCustomerPost(apiVersion, postCustomerRepresentation, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * CustomerApi - interface
            * @export
            * @interface CustomerApi
            */
            export interface CustomerApiInterface {
                /**
                * 
                    * @param {string} [cocNumber] 
                    * @param {string} [cocDepartment] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCocExistsGet(cocNumber?: string, cocDepartment?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerRepresentation>>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdAddressAddressIdDelete(customerId: string, addressId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdAddressAddressIdGet(customerId: string, addressId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CustomerAddressRepresentation>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutCustomerAddressRepresentation} [putCustomerAddressRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdAddressAddressIdPut(customerId: string, addressId: string, apiVersion?: string, putCustomerAddressRepresentation?: PutCustomerAddressRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<CustomerAddressRepresentation>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdAddressListGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerAddressRepresentation>>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostCustomerAddressRepresentation} [postCustomerAddressRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdAddressPost(customerId: string, apiVersion?: string, postCustomerAddressRepresentation?: PostCustomerAddressRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<CustomerAddressRepresentation>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdContactpersonListGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ContactPersonRepresentation>>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdDefaultPricelistGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<PriceListReference>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdPricelistGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<PriceListRepresentation>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerCustomerIdPricelistPdfGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerIdCardGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CustomerListView>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CustomerRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutCustomerRepresentation} [putCustomerRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerIdPut(id: string, apiVersion?: string, putCustomerRepresentation?: PutCustomerRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<CustomerRepresentation>;

                /**
                * 
                    * @param {string} [searchTerm] 
                    * @param {boolean} [archived] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerListGet(searchTerm?: string, archived?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerListRepresentation>>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CustomerListViewArrayODataValue>;

                /**
                * 
                    * @param {string} [companyName] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerNameExistsGet(companyName?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerRepresentation>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostCustomerRepresentation} [postCustomerRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof CustomerApiInterface
                */
            crmCustomerPost(apiVersion?: string, postCustomerRepresentation?: PostCustomerRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<CustomerRepresentation>;

            }

        /**
        * CustomerApi - object-oriented interface
        * @export
        * @class CustomerApi
        * @extends {BaseAPI}
        */
            export class CustomerApi extends BaseAPI implements CustomerApiInterface {
            /**
            * 
                    * @param {string} [cocNumber] 
                    * @param {string} [cocDepartment] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCocExistsGet(cocNumber?: string, cocDepartment?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCocExistsGet(cocNumber, cocDepartment, customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdAddressAddressIdDelete(customerId: string, addressId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdAddressAddressIdDelete(customerId, addressId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdAddressAddressIdGet(customerId: string, addressId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdAddressAddressIdGet(customerId, addressId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} addressId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutCustomerAddressRepresentation} [putCustomerAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdAddressAddressIdPut(customerId: string, addressId: string, apiVersion?: string, putCustomerAddressRepresentation?: PutCustomerAddressRepresentation, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdAddressAddressIdPut(customerId, addressId, apiVersion, putCustomerAddressRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdAddressListGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdAddressListGet(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostCustomerAddressRepresentation} [postCustomerAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdAddressPost(customerId: string, apiVersion?: string, postCustomerAddressRepresentation?: PostCustomerAddressRepresentation, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdAddressPost(customerId, apiVersion, postCustomerAddressRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdContactpersonListGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdContactpersonListGet(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdDefaultPricelistGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdDefaultPricelistGet(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdPricelistGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdPricelistGet(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerCustomerIdPricelistPdfGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerCustomerIdPricelistPdfGet(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerIdCardGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerIdCardGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutCustomerRepresentation} [putCustomerRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerIdPut(id: string, apiVersion?: string, putCustomerRepresentation?: PutCustomerRepresentation, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerIdPut(id, apiVersion, putCustomerRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [searchTerm] 
                    * @param {boolean} [archived] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerListGet(searchTerm?: string, archived?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerListGet(searchTerm, archived, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [companyName] 
                    * @param {string} [customerId] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerNameExistsGet(companyName?: string, customerId?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerNameExistsGet(companyName, customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostCustomerRepresentation} [postCustomerRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof CustomerApi
            */
                public crmCustomerPost(apiVersion?: string, postCustomerRepresentation?: PostCustomerRepresentation, options?: RawAxiosRequestConfig) {
                return CustomerApiFp(this.configuration).crmCustomerPost(apiVersion, postCustomerRepresentation, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * DiscountGroupApi - axios parameter creator
    * @export
    */
    export const DiscountGroupApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountGroupsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/discount-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountGroupsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smDiscountGroupsIdDelete', 'id', id)
            const localVarPath = `/sm/discount-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountGroupsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smDiscountGroupsIdGet', 'id', id)
            const localVarPath = `/sm/discount-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateDiscountGroupModel} [updateDiscountGroupModel] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountGroupsIdPut: async (id: string, apiVersion?: string, updateDiscountGroupModel?: UpdateDiscountGroupModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smDiscountGroupsIdPut', 'id', id)
            const localVarPath = `/sm/discount-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateDiscountGroupModel, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateDiscountGroupCommand} [createDiscountGroupCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountGroupsPost: async (apiVersion?: string, createDiscountGroupCommand?: CreateDiscountGroupCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/discount-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createDiscountGroupCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * DiscountGroupApi - functional programming interface
        * @export
        */
        export const DiscountGroupApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = DiscountGroupApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountGroupsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscountGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountGroupsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountGroupApi.smDiscountGroupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountGroupsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountGroupsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountGroupApi.smDiscountGroupsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountGroupsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountGroupsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountGroupApi.smDiscountGroupsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateDiscountGroupModel} [updateDiscountGroupModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountGroupsIdPut(id: string, apiVersion?: string, updateDiscountGroupModel?: UpdateDiscountGroupModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountGroupsIdPut(id, apiVersion, updateDiscountGroupModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountGroupApi.smDiscountGroupsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateDiscountGroupCommand} [createDiscountGroupCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountGroupsPost(apiVersion?: string, createDiscountGroupCommand?: CreateDiscountGroupCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountGroupsPost(apiVersion, createDiscountGroupCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountGroupApi.smDiscountGroupsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * DiscountGroupApi - factory interface
        * @export
        */
        export const DiscountGroupApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = DiscountGroupApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountGroupsGet(apiVersion?: string, options?: any): AxiosPromise<Array<DiscountGroup>> {
                return localVarFp.smDiscountGroupsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountGroupsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smDiscountGroupsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountGroupsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<DiscountGroup> {
                return localVarFp.smDiscountGroupsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateDiscountGroupModel} [updateDiscountGroupModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountGroupsIdPut(id: string, apiVersion?: string, updateDiscountGroupModel?: UpdateDiscountGroupModel, options?: any): AxiosPromise<DiscountGroup> {
                return localVarFp.smDiscountGroupsIdPut(id, apiVersion, updateDiscountGroupModel, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateDiscountGroupCommand} [createDiscountGroupCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountGroupsPost(apiVersion?: string, createDiscountGroupCommand?: CreateDiscountGroupCommand, options?: any): AxiosPromise<DiscountGroup> {
                return localVarFp.smDiscountGroupsPost(apiVersion, createDiscountGroupCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * DiscountGroupApi - interface
            * @export
            * @interface DiscountGroupApi
            */
            export interface DiscountGroupApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountGroupApiInterface
                */
            smDiscountGroupsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DiscountGroup>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountGroupApiInterface
                */
            smDiscountGroupsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountGroupApiInterface
                */
            smDiscountGroupsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<DiscountGroup>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateDiscountGroupModel} [updateDiscountGroupModel] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountGroupApiInterface
                */
            smDiscountGroupsIdPut(id: string, apiVersion?: string, updateDiscountGroupModel?: UpdateDiscountGroupModel, options?: RawAxiosRequestConfig): AxiosPromise<DiscountGroup>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateDiscountGroupCommand} [createDiscountGroupCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountGroupApiInterface
                */
            smDiscountGroupsPost(apiVersion?: string, createDiscountGroupCommand?: CreateDiscountGroupCommand, options?: RawAxiosRequestConfig): AxiosPromise<DiscountGroup>;

            }

        /**
        * DiscountGroupApi - object-oriented interface
        * @export
        * @class DiscountGroupApi
        * @extends {BaseAPI}
        */
            export class DiscountGroupApi extends BaseAPI implements DiscountGroupApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountGroupApi
            */
                public smDiscountGroupsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return DiscountGroupApiFp(this.configuration).smDiscountGroupsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountGroupApi
            */
                public smDiscountGroupsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return DiscountGroupApiFp(this.configuration).smDiscountGroupsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountGroupApi
            */
                public smDiscountGroupsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return DiscountGroupApiFp(this.configuration).smDiscountGroupsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateDiscountGroupModel} [updateDiscountGroupModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountGroupApi
            */
                public smDiscountGroupsIdPut(id: string, apiVersion?: string, updateDiscountGroupModel?: UpdateDiscountGroupModel, options?: RawAxiosRequestConfig) {
                return DiscountGroupApiFp(this.configuration).smDiscountGroupsIdPut(id, apiVersion, updateDiscountGroupModel, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateDiscountGroupCommand} [createDiscountGroupCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountGroupApi
            */
                public smDiscountGroupsPost(apiVersion?: string, createDiscountGroupCommand?: CreateDiscountGroupCommand, options?: RawAxiosRequestConfig) {
                return DiscountGroupApiFp(this.configuration).smDiscountGroupsPost(apiVersion, createDiscountGroupCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * DiscountRuleApi - axios parameter creator
    * @export
    */
    export const DiscountRuleApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountRulesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/discount-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountRulesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smDiscountRulesIdDelete', 'id', id)
            const localVarPath = `/sm/discount-rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountRulesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smDiscountRulesIdGet', 'id', id)
            const localVarPath = `/sm/discount-rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {SmDiscountRulesIdPutRequest} [smDiscountRulesIdPutRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountRulesIdPut: async (id: string, apiVersion?: string, smDiscountRulesIdPutRequest?: SmDiscountRulesIdPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smDiscountRulesIdPut', 'id', id)
            const localVarPath = `/sm/discount-rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(smDiscountRulesIdPutRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {SmDiscountRulesPostRequest} [smDiscountRulesPostRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smDiscountRulesPost: async (apiVersion?: string, smDiscountRulesPostRequest?: SmDiscountRulesPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/discount-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(smDiscountRulesPostRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * DiscountRuleApi - functional programming interface
        * @export
        */
        export const DiscountRuleApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = DiscountRuleApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountRulesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SmDiscountRulesIdGet200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountRulesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountRuleApi.smDiscountRulesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountRulesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountRulesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountRuleApi.smDiscountRulesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountRulesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmDiscountRulesIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountRulesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountRuleApi.smDiscountRulesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {SmDiscountRulesIdPutRequest} [smDiscountRulesIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountRulesIdPut(id: string, apiVersion?: string, smDiscountRulesIdPutRequest?: SmDiscountRulesIdPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmDiscountRulesIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountRulesIdPut(id, apiVersion, smDiscountRulesIdPutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountRuleApi.smDiscountRulesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {SmDiscountRulesPostRequest} [smDiscountRulesPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smDiscountRulesPost(apiVersion?: string, smDiscountRulesPostRequest?: SmDiscountRulesPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmDiscountRulesIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smDiscountRulesPost(apiVersion, smDiscountRulesPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountRuleApi.smDiscountRulesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * DiscountRuleApi - factory interface
        * @export
        */
        export const DiscountRuleApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = DiscountRuleApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountRulesGet(apiVersion?: string, options?: any): AxiosPromise<Array<SmDiscountRulesIdGet200Response>> {
                return localVarFp.smDiscountRulesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountRulesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smDiscountRulesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountRulesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SmDiscountRulesIdGet200Response> {
                return localVarFp.smDiscountRulesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmDiscountRulesIdPutRequest} [smDiscountRulesIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountRulesIdPut(id: string, apiVersion?: string, smDiscountRulesIdPutRequest?: SmDiscountRulesIdPutRequest, options?: any): AxiosPromise<SmDiscountRulesIdGet200Response> {
                return localVarFp.smDiscountRulesIdPut(id, apiVersion, smDiscountRulesIdPutRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmDiscountRulesPostRequest} [smDiscountRulesPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smDiscountRulesPost(apiVersion?: string, smDiscountRulesPostRequest?: SmDiscountRulesPostRequest, options?: any): AxiosPromise<SmDiscountRulesIdGet200Response> {
                return localVarFp.smDiscountRulesPost(apiVersion, smDiscountRulesPostRequest, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * DiscountRuleApi - interface
            * @export
            * @interface DiscountRuleApi
            */
            export interface DiscountRuleApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountRuleApiInterface
                */
            smDiscountRulesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SmDiscountRulesIdGet200Response>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountRuleApiInterface
                */
            smDiscountRulesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountRuleApiInterface
                */
            smDiscountRulesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SmDiscountRulesIdGet200Response>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmDiscountRulesIdPutRequest} [smDiscountRulesIdPutRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountRuleApiInterface
                */
            smDiscountRulesIdPut(id: string, apiVersion?: string, smDiscountRulesIdPutRequest?: SmDiscountRulesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<SmDiscountRulesIdGet200Response>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmDiscountRulesPostRequest} [smDiscountRulesPostRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DiscountRuleApiInterface
                */
            smDiscountRulesPost(apiVersion?: string, smDiscountRulesPostRequest?: SmDiscountRulesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<SmDiscountRulesIdGet200Response>;

            }

        /**
        * DiscountRuleApi - object-oriented interface
        * @export
        * @class DiscountRuleApi
        * @extends {BaseAPI}
        */
            export class DiscountRuleApi extends BaseAPI implements DiscountRuleApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountRuleApi
            */
                public smDiscountRulesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return DiscountRuleApiFp(this.configuration).smDiscountRulesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountRuleApi
            */
                public smDiscountRulesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return DiscountRuleApiFp(this.configuration).smDiscountRulesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountRuleApi
            */
                public smDiscountRulesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return DiscountRuleApiFp(this.configuration).smDiscountRulesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmDiscountRulesIdPutRequest} [smDiscountRulesIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountRuleApi
            */
                public smDiscountRulesIdPut(id: string, apiVersion?: string, smDiscountRulesIdPutRequest?: SmDiscountRulesIdPutRequest, options?: RawAxiosRequestConfig) {
                return DiscountRuleApiFp(this.configuration).smDiscountRulesIdPut(id, apiVersion, smDiscountRulesIdPutRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmDiscountRulesPostRequest} [smDiscountRulesPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DiscountRuleApi
            */
                public smDiscountRulesPost(apiVersion?: string, smDiscountRulesPostRequest?: SmDiscountRulesPostRequest, options?: RawAxiosRequestConfig) {
                return DiscountRuleApiFp(this.configuration).smDiscountRulesPost(apiVersion, smDiscountRulesPostRequest, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * DxfApi - axios parameter creator
    * @export
    */
    export const DxfApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<File>} [files] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        dxfScanPost: async (apiVersion?: string, files?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dxf/scan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
                const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }

                        if (files) {
                        files.forEach((element) => {
                            localVarFormParams.append('files', element as any);
                            })
                        }

            
        
                localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = localVarFormParams;

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * DxfApi - functional programming interface
        * @export
        */
        export const DxfApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = DxfApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<File>} [files] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async dxfScanPost(apiVersion?: string, files?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dxfScanPost(apiVersion, files, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DxfApi.dxfScanPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * DxfApi - factory interface
        * @export
        */
        export const DxfApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = DxfApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<File>} [files] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            dxfScanPost(apiVersion?: string, files?: Array<File>, options?: any): AxiosPromise<void> {
                return localVarFp.dxfScanPost(apiVersion, files, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * DxfApi - interface
            * @export
            * @interface DxfApi
            */
            export interface DxfApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<File>} [files] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof DxfApiInterface
                */
            dxfScanPost(apiVersion?: string, files?: Array<File>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * DxfApi - object-oriented interface
        * @export
        * @class DxfApi
        * @extends {BaseAPI}
        */
            export class DxfApi extends BaseAPI implements DxfApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<File>} [files] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof DxfApi
            */
                public dxfScanPost(apiVersion?: string, files?: Array<File>, options?: RawAxiosRequestConfig) {
                return DxfApiFp(this.configuration).dxfScanPost(apiVersion, files, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * EmailApi - axios parameter creator
    * @export
    */
    export const EmailApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdEmailOrderConfirmationPost: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdEmailOrderConfirmationPost', 'id', id)
            const localVarPath = `/sm/order/{id}/email/order-confirmation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdEmailQuoteIssuedPost: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdEmailQuoteIssuedPost', 'id', id)
            const localVarPath = `/sm/order/{id}/email/quote-issued`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * EmailApi - functional programming interface
        * @export
        */
        export const EmailApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdEmailOrderConfirmationPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdEmailOrderConfirmationPost(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmailApi.smOrderIdEmailOrderConfirmationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdEmailQuoteIssuedPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdEmailQuoteIssuedPost(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmailApi.smOrderIdEmailQuoteIssuedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * EmailApi - factory interface
        * @export
        */
        export const EmailApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = EmailApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdEmailOrderConfirmationPost(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdEmailOrderConfirmationPost(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdEmailQuoteIssuedPost(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdEmailQuoteIssuedPost(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * EmailApi - interface
            * @export
            * @interface EmailApi
            */
            export interface EmailApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof EmailApiInterface
                */
            smOrderIdEmailOrderConfirmationPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof EmailApiInterface
                */
            smOrderIdEmailQuoteIssuedPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * EmailApi - object-oriented interface
        * @export
        * @class EmailApi
        * @extends {BaseAPI}
        */
            export class EmailApi extends BaseAPI implements EmailApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof EmailApi
            */
                public smOrderIdEmailOrderConfirmationPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return EmailApiFp(this.configuration).smOrderIdEmailOrderConfirmationPost(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof EmailApi
            */
                public smOrderIdEmailQuoteIssuedPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return EmailApiFp(this.configuration).smOrderIdEmailQuoteIssuedPost(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ExpeditionApi - axios parameter creator
    * @export
    */
    export const ExpeditionApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionByWeekGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/by-week`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionExpeditionItemsOrderIdGet: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionExpeditionItemsOrderIdGet', 'orderId', orderId)
            const localVarPath = `/em/expedition/expedition-items/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} productionItemCode 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionExpeditionsForProductionItemProductionItemCodeGet: async (productionItemCode: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'productionItemCode' is not null or undefined
                    assertParamExists('emExpeditionExpeditionsForProductionItemProductionItemCodeGet', 'productionItemCode', productionItemCode)
            const localVarPath = `/em/expedition/expeditions-for-production-item/{productionItemCode}`
                .replace(`{${"productionItemCode"}}`, encodeURIComponent(String(productionItemCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionListOdataGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderExpeditionByWeekGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/order-expedition-by-week`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdCancelledPut: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdCancelledPut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/cancelled`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
            * @param {PutExpeditionDeliveredRepresentation} [putExpeditionDeliveredRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdDeliveredDataPut: async (orderId: string, apiVersion?: string, putExpeditionDeliveredRepresentation?: PutExpeditionDeliveredRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdDeliveredDataPut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/delivered-data`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putExpeditionDeliveredRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdDeliveredPut: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdDeliveredPut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/delivered`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdGet: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdGet', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdInTransitPut: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdInTransitPut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/in-transit`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdNotPlannedPut: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdNotPlannedPut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/not-planned`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
            * @param {PutPlannedExpeditionDateRepresentation} [putPlannedExpeditionDateRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdPlannedExpeditionDatePut: async (orderId: string, apiVersion?: string, putPlannedExpeditionDateRepresentation?: PutPlannedExpeditionDateRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdPlannedExpeditionDatePut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/planned-expedition-date`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putPlannedExpeditionDateRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdPlannedPut: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdPlannedPut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/planned`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionOrderIdReadyForShipmentPut: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionOrderIdReadyForShipmentPut', 'orderId', orderId)
            const localVarPath = `/em/expedition/{orderId}/ready-for-shipment`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionPackingSlipsPost: async (apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/packing-slips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionShipmentDeliveryDetailsOrderIdGet: async (orderId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderId' is not null or undefined
                    assertParamExists('emExpeditionShipmentDeliveryDetailsOrderIdGet', 'orderId', orderId)
            const localVarPath = `/em/expedition/shipment-delivery-details/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionTransportPlanningByOrderGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/transport-planning-by-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionTransportPlanningByWeekGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/transport-planning-by-week`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ExpeditionApi - functional programming interface
        * @export
        */
        export const ExpeditionApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ExpeditionApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionPlanningByWeekViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionByWeekGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionExpeditionItemsOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionItemsRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionExpeditionItemsOrderIdGet(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionExpeditionItemsOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} productionItemCode 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionExpeditionsForProductionItemProductionItemCodeGet(productionItemCode: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionForProductionItemRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionExpeditionsForProductionItemProductionItemCodeGet(productionItemCode, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionExpeditionsForProductionItemProductionItemCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionListViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionListOdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionPlanningByOrderViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderExpeditionByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderExpeditionByWeekGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdCancelledPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdCancelledPut(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdCancelledPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
                * @param {PutExpeditionDeliveredRepresentation} [putExpeditionDeliveredRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdDeliveredDataPut(orderId: string, apiVersion?: string, putExpeditionDeliveredRepresentation?: PutExpeditionDeliveredRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdDeliveredDataPut(orderId, apiVersion, putExpeditionDeliveredRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdDeliveredDataPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdDeliveredPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdDeliveredPut(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdDeliveredPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionForProductionItemRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdGet(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdInTransitPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdInTransitPut(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdInTransitPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdNotPlannedPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdNotPlannedPut(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdNotPlannedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
                * @param {PutPlannedExpeditionDateRepresentation} [putPlannedExpeditionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdPlannedExpeditionDatePut(orderId: string, apiVersion?: string, putPlannedExpeditionDateRepresentation?: PutPlannedExpeditionDateRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdPlannedExpeditionDatePut(orderId, apiVersion, putPlannedExpeditionDateRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdPlannedExpeditionDatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdPlannedPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdPlannedPut(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdPlannedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionOrderIdReadyForShipmentPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionOrderIdReadyForShipmentPut(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionOrderIdReadyForShipmentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionPackingSlipsPost(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackingSlipModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionPackingSlipsPost(apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionPackingSlipsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentDeliveryDetailsRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionShipmentDeliveryDetailsOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionTransportPlanningByOrderGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransportPlanningByOrderViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionTransportPlanningByOrderGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionTransportPlanningByOrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionTransportPlanningByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransportPlanningByWeekViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionTransportPlanningByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpeditionApi.emExpeditionTransportPlanningByWeekGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ExpeditionApi - factory interface
        * @export
        */
        export const ExpeditionApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ExpeditionApiFp(configuration)
        return {
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<ExpeditionPlanningByWeekViewArrayODataValue> {
                return localVarFp.emExpeditionByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionExpeditionItemsOrderIdGet(orderId: string, apiVersion?: string, options?: any): AxiosPromise<ExpeditionItemsRepresentation> {
                return localVarFp.emExpeditionExpeditionItemsOrderIdGet(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} productionItemCode 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionExpeditionsForProductionItemProductionItemCodeGet(productionItemCode: string, apiVersion?: string, options?: any): AxiosPromise<ExpeditionForProductionItemRepresentation> {
                return localVarFp.emExpeditionExpeditionsForProductionItemProductionItemCodeGet(productionItemCode, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<ExpeditionListViewArrayODataValue> {
                return localVarFp.emExpeditionListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<ExpeditionPlanningByOrderViewArrayODataValue> {
                return localVarFp.emExpeditionOrderExpeditionByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdCancelledPut(orderId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdCancelledPut(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionDeliveredRepresentation} [putExpeditionDeliveredRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdDeliveredDataPut(orderId: string, apiVersion?: string, putExpeditionDeliveredRepresentation?: PutExpeditionDeliveredRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdDeliveredDataPut(orderId, apiVersion, putExpeditionDeliveredRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdDeliveredPut(orderId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdDeliveredPut(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdGet(orderId: string, apiVersion?: string, options?: any): AxiosPromise<ExpeditionForProductionItemRepresentation> {
                return localVarFp.emExpeditionOrderIdGet(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdInTransitPut(orderId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdInTransitPut(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdNotPlannedPut(orderId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdNotPlannedPut(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPlannedExpeditionDateRepresentation} [putPlannedExpeditionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdPlannedExpeditionDatePut(orderId: string, apiVersion?: string, putPlannedExpeditionDateRepresentation?: PutPlannedExpeditionDateRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdPlannedExpeditionDatePut(orderId, apiVersion, putPlannedExpeditionDateRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdPlannedPut(orderId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdPlannedPut(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionOrderIdReadyForShipmentPut(orderId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.emExpeditionOrderIdReadyForShipmentPut(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionPackingSlipsPost(apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<Array<PackingSlipModel>> {
                return localVarFp.emExpeditionPackingSlipsPost(apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId: string, apiVersion?: string, options?: any): AxiosPromise<ShipmentDeliveryDetailsRepresentation> {
                return localVarFp.emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionTransportPlanningByOrderGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<TransportPlanningByOrderViewArrayODataValue> {
                return localVarFp.emExpeditionTransportPlanningByOrderGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionTransportPlanningByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<TransportPlanningByWeekViewArrayODataValue> {
                return localVarFp.emExpeditionTransportPlanningByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ExpeditionApi - interface
            * @export
            * @interface ExpeditionApi
            */
            export interface ExpeditionApiInterface {
                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionPlanningByWeekViewArrayODataValue>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionExpeditionItemsOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionItemsRepresentation>;

                /**
                * 
                    * @param {string} productionItemCode 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionExpeditionsForProductionItemProductionItemCodeGet(productionItemCode: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionForProductionItemRepresentation>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionListViewArrayODataValue>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionPlanningByOrderViewArrayODataValue>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdCancelledPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionDeliveredRepresentation} [putExpeditionDeliveredRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdDeliveredDataPut(orderId: string, apiVersion?: string, putExpeditionDeliveredRepresentation?: PutExpeditionDeliveredRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdDeliveredPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionForProductionItemRepresentation>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdInTransitPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdNotPlannedPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPlannedExpeditionDateRepresentation} [putPlannedExpeditionDateRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdPlannedExpeditionDatePut(orderId: string, apiVersion?: string, putPlannedExpeditionDateRepresentation?: PutPlannedExpeditionDateRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdPlannedPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionOrderIdReadyForShipmentPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionPackingSlipsPost(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<PackingSlipModel>>;

                /**
                * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ShipmentDeliveryDetailsRepresentation>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionTransportPlanningByOrderGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<TransportPlanningByOrderViewArrayODataValue>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ExpeditionApiInterface
                */
            emExpeditionTransportPlanningByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<TransportPlanningByWeekViewArrayODataValue>;

            }

        /**
        * ExpeditionApi - object-oriented interface
        * @export
        * @class ExpeditionApi
        * @extends {BaseAPI}
        */
            export class ExpeditionApi extends BaseAPI implements ExpeditionApiInterface {
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionExpeditionItemsOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionExpeditionItemsOrderIdGet(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} productionItemCode 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionExpeditionsForProductionItemProductionItemCodeGet(productionItemCode: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionExpeditionsForProductionItemProductionItemCodeGet(productionItemCode, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderExpeditionByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderExpeditionByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdCancelledPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdCancelledPut(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionDeliveredRepresentation} [putExpeditionDeliveredRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdDeliveredDataPut(orderId: string, apiVersion?: string, putExpeditionDeliveredRepresentation?: PutExpeditionDeliveredRepresentation, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdDeliveredDataPut(orderId, apiVersion, putExpeditionDeliveredRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdDeliveredPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdDeliveredPut(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdGet(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdInTransitPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdInTransitPut(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdNotPlannedPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdNotPlannedPut(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPlannedExpeditionDateRepresentation} [putPlannedExpeditionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdPlannedExpeditionDatePut(orderId: string, apiVersion?: string, putPlannedExpeditionDateRepresentation?: PutPlannedExpeditionDateRepresentation, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdPlannedExpeditionDatePut(orderId, apiVersion, putPlannedExpeditionDateRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdPlannedPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdPlannedPut(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionOrderIdReadyForShipmentPut(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionOrderIdReadyForShipmentPut(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionPackingSlipsPost(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionPackingSlipsPost(apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionTransportPlanningByOrderGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionTransportPlanningByOrderGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ExpeditionApi
            */
                public emExpeditionTransportPlanningByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ExpeditionApiFp(this.configuration).emExpeditionTransportPlanningByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * GlazingBarApi - axios parameter creator
    * @export
    */
    export const GlazingBarApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingBarsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/glazing-bars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingBarsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimGlazingBarsIdDelete', 'id', id)
            const localVarPath = `/pim/glazing-bars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingBarsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimGlazingBarsIdGet', 'id', id)
            const localVarPath = `/pim/glazing-bars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateGlazingBarRequest} [updateGlazingBarRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingBarsIdPut: async (id: string, apiVersion?: string, updateGlazingBarRequest?: UpdateGlazingBarRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimGlazingBarsIdPut', 'id', id)
            const localVarPath = `/pim/glazing-bars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateGlazingBarRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateGlazingBarCommand} [createGlazingBarCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingBarsPost: async (apiVersion?: string, createGlazingBarCommand?: CreateGlazingBarCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/glazing-bars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createGlazingBarCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * GlazingBarApi - functional programming interface
        * @export
        */
        export const GlazingBarApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = GlazingBarApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingBarsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlazingBar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingBarsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingBarApi.pimGlazingBarsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingBarsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingBarsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingBarApi.pimGlazingBarsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingBarsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlazingBar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingBarsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingBarApi.pimGlazingBarsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateGlazingBarRequest} [updateGlazingBarRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingBarsIdPut(id: string, apiVersion?: string, updateGlazingBarRequest?: UpdateGlazingBarRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlazingBar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingBarsIdPut(id, apiVersion, updateGlazingBarRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingBarApi.pimGlazingBarsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateGlazingBarCommand} [createGlazingBarCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingBarsPost(apiVersion?: string, createGlazingBarCommand?: CreateGlazingBarCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlazingBar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingBarsPost(apiVersion, createGlazingBarCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingBarApi.pimGlazingBarsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * GlazingBarApi - factory interface
        * @export
        */
        export const GlazingBarApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = GlazingBarApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingBarsGet(apiVersion?: string, options?: any): AxiosPromise<Array<GlazingBar>> {
                return localVarFp.pimGlazingBarsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingBarsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimGlazingBarsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingBarsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<GlazingBar> {
                return localVarFp.pimGlazingBarsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateGlazingBarRequest} [updateGlazingBarRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingBarsIdPut(id: string, apiVersion?: string, updateGlazingBarRequest?: UpdateGlazingBarRequest, options?: any): AxiosPromise<GlazingBar> {
                return localVarFp.pimGlazingBarsIdPut(id, apiVersion, updateGlazingBarRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateGlazingBarCommand} [createGlazingBarCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingBarsPost(apiVersion?: string, createGlazingBarCommand?: CreateGlazingBarCommand, options?: any): AxiosPromise<GlazingBar> {
                return localVarFp.pimGlazingBarsPost(apiVersion, createGlazingBarCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * GlazingBarApi - interface
            * @export
            * @interface GlazingBarApi
            */
            export interface GlazingBarApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingBarApiInterface
                */
            pimGlazingBarsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GlazingBar>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingBarApiInterface
                */
            pimGlazingBarsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingBarApiInterface
                */
            pimGlazingBarsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<GlazingBar>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateGlazingBarRequest} [updateGlazingBarRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingBarApiInterface
                */
            pimGlazingBarsIdPut(id: string, apiVersion?: string, updateGlazingBarRequest?: UpdateGlazingBarRequest, options?: RawAxiosRequestConfig): AxiosPromise<GlazingBar>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateGlazingBarCommand} [createGlazingBarCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingBarApiInterface
                */
            pimGlazingBarsPost(apiVersion?: string, createGlazingBarCommand?: CreateGlazingBarCommand, options?: RawAxiosRequestConfig): AxiosPromise<GlazingBar>;

            }

        /**
        * GlazingBarApi - object-oriented interface
        * @export
        * @class GlazingBarApi
        * @extends {BaseAPI}
        */
            export class GlazingBarApi extends BaseAPI implements GlazingBarApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingBarApi
            */
                public pimGlazingBarsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GlazingBarApiFp(this.configuration).pimGlazingBarsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingBarApi
            */
                public pimGlazingBarsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GlazingBarApiFp(this.configuration).pimGlazingBarsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingBarApi
            */
                public pimGlazingBarsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GlazingBarApiFp(this.configuration).pimGlazingBarsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateGlazingBarRequest} [updateGlazingBarRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingBarApi
            */
                public pimGlazingBarsIdPut(id: string, apiVersion?: string, updateGlazingBarRequest?: UpdateGlazingBarRequest, options?: RawAxiosRequestConfig) {
                return GlazingBarApiFp(this.configuration).pimGlazingBarsIdPut(id, apiVersion, updateGlazingBarRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateGlazingBarCommand} [createGlazingBarCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingBarApi
            */
                public pimGlazingBarsPost(apiVersion?: string, createGlazingBarCommand?: CreateGlazingBarCommand, options?: RawAxiosRequestConfig) {
                return GlazingBarApiFp(this.configuration).pimGlazingBarsPost(apiVersion, createGlazingBarCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * GlazingProfileApi - axios parameter creator
    * @export
    */
    export const GlazingProfileApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingProfilesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/glazing-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingProfilesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimGlazingProfilesIdDelete', 'id', id)
            const localVarPath = `/pim/glazing-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingProfilesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimGlazingProfilesIdGet', 'id', id)
            const localVarPath = `/pim/glazing-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateGlazingProfileRequest} [updateGlazingProfileRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingProfilesIdPut: async (id: string, apiVersion?: string, updateGlazingProfileRequest?: UpdateGlazingProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimGlazingProfilesIdPut', 'id', id)
            const localVarPath = `/pim/glazing-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateGlazingProfileRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateGlazingProfileCommand} [createGlazingProfileCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimGlazingProfilesPost: async (apiVersion?: string, createGlazingProfileCommand?: CreateGlazingProfileCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/glazing-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createGlazingProfileCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * GlazingProfileApi - functional programming interface
        * @export
        */
        export const GlazingProfileApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = GlazingProfileApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlazingProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingProfilesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingProfileApi.pimGlazingProfilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingProfilesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingProfileApi.pimGlazingProfilesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlazingProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingProfilesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingProfileApi.pimGlazingProfilesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateGlazingProfileRequest} [updateGlazingProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingProfilesIdPut(id: string, apiVersion?: string, updateGlazingProfileRequest?: UpdateGlazingProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlazingProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingProfilesIdPut(id, apiVersion, updateGlazingProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingProfileApi.pimGlazingProfilesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateGlazingProfileCommand} [createGlazingProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimGlazingProfilesPost(apiVersion?: string, createGlazingProfileCommand?: CreateGlazingProfileCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlazingProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimGlazingProfilesPost(apiVersion, createGlazingProfileCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlazingProfileApi.pimGlazingProfilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * GlazingProfileApi - factory interface
        * @export
        */
        export const GlazingProfileApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = GlazingProfileApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingProfilesGet(apiVersion?: string, options?: any): AxiosPromise<Array<GlazingProfile>> {
                return localVarFp.pimGlazingProfilesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingProfilesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimGlazingProfilesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingProfilesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<GlazingProfile> {
                return localVarFp.pimGlazingProfilesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateGlazingProfileRequest} [updateGlazingProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingProfilesIdPut(id: string, apiVersion?: string, updateGlazingProfileRequest?: UpdateGlazingProfileRequest, options?: any): AxiosPromise<GlazingProfile> {
                return localVarFp.pimGlazingProfilesIdPut(id, apiVersion, updateGlazingProfileRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateGlazingProfileCommand} [createGlazingProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimGlazingProfilesPost(apiVersion?: string, createGlazingProfileCommand?: CreateGlazingProfileCommand, options?: any): AxiosPromise<GlazingProfile> {
                return localVarFp.pimGlazingProfilesPost(apiVersion, createGlazingProfileCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * GlazingProfileApi - interface
            * @export
            * @interface GlazingProfileApi
            */
            export interface GlazingProfileApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingProfileApiInterface
                */
            pimGlazingProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GlazingProfile>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingProfileApiInterface
                */
            pimGlazingProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingProfileApiInterface
                */
            pimGlazingProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<GlazingProfile>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateGlazingProfileRequest} [updateGlazingProfileRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingProfileApiInterface
                */
            pimGlazingProfilesIdPut(id: string, apiVersion?: string, updateGlazingProfileRequest?: UpdateGlazingProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<GlazingProfile>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateGlazingProfileCommand} [createGlazingProfileCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GlazingProfileApiInterface
                */
            pimGlazingProfilesPost(apiVersion?: string, createGlazingProfileCommand?: CreateGlazingProfileCommand, options?: RawAxiosRequestConfig): AxiosPromise<GlazingProfile>;

            }

        /**
        * GlazingProfileApi - object-oriented interface
        * @export
        * @class GlazingProfileApi
        * @extends {BaseAPI}
        */
            export class GlazingProfileApi extends BaseAPI implements GlazingProfileApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingProfileApi
            */
                public pimGlazingProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GlazingProfileApiFp(this.configuration).pimGlazingProfilesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingProfileApi
            */
                public pimGlazingProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GlazingProfileApiFp(this.configuration).pimGlazingProfilesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingProfileApi
            */
                public pimGlazingProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GlazingProfileApiFp(this.configuration).pimGlazingProfilesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateGlazingProfileRequest} [updateGlazingProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingProfileApi
            */
                public pimGlazingProfilesIdPut(id: string, apiVersion?: string, updateGlazingProfileRequest?: UpdateGlazingProfileRequest, options?: RawAxiosRequestConfig) {
                return GlazingProfileApiFp(this.configuration).pimGlazingProfilesIdPut(id, apiVersion, updateGlazingProfileRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateGlazingProfileCommand} [createGlazingProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GlazingProfileApi
            */
                public pimGlazingProfilesPost(apiVersion?: string, createGlazingProfileCommand?: CreateGlazingProfileCommand, options?: RawAxiosRequestConfig) {
                return GlazingProfileApiFp(this.configuration).pimGlazingProfilesPost(apiVersion, createGlazingProfileCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * GroeneveldApi - axios parameter creator
    * @export
    */
    export const GroeneveldApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} groeneveldArticleId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldArticleGroeneveldArticleIdGet: async (groeneveldArticleId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'groeneveldArticleId' is not null or undefined
                    assertParamExists('aclGroeneveldArticleGroeneveldArticleIdGet', 'groeneveldArticleId', groeneveldArticleId)
            const localVarPath = `/acl/groeneveld/article/{groeneveldArticleId}`
                .replace(`{${"groeneveldArticleId"}}`, encodeURIComponent(String(groeneveldArticleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldArticlesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/groeneveld/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} groeneveldConfiguredSillId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet: async (groeneveldConfiguredSillId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'groeneveldConfiguredSillId' is not null or undefined
                    assertParamExists('aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet', 'groeneveldConfiguredSillId', groeneveldConfiguredSillId)
            const localVarPath = `/acl/groeneveld/configured-sill/{groeneveldConfiguredSillId}/compartments`
                .replace(`{${"groeneveldConfiguredSillId"}}`, encodeURIComponent(String(groeneveldConfiguredSillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} groeneveldConfiguredSillId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut: async (groeneveldConfiguredSillId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'groeneveldConfiguredSillId' is not null or undefined
                    assertParamExists('aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut', 'groeneveldConfiguredSillId', groeneveldConfiguredSillId)
            const localVarPath = `/acl/groeneveld/configured-sill/{groeneveldConfiguredSillId}/mark-user-checked`
                .replace(`{${"groeneveldConfiguredSillId"}}`, encodeURIComponent(String(groeneveldConfiguredSillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} groeneveldConfiguredSillId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet: async (groeneveldConfiguredSillId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'groeneveldConfiguredSillId' is not null or undefined
                    assertParamExists('aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet', 'groeneveldConfiguredSillId', groeneveldConfiguredSillId)
            const localVarPath = `/acl/groeneveld/configured-sill/{groeneveldConfiguredSillId}/neuten`
                .replace(`{${"groeneveldConfiguredSillId"}}`, encodeURIComponent(String(groeneveldConfiguredSillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldCopyImportIdPost: async (importId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importId' is not null or undefined
                    assertParamExists('aclGroeneveldCopyImportIdPost', 'importId', importId)
            const localVarPath = `/acl/groeneveld/copy/{importId}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderLineId 
            * @param {number} compartmentId 
            * @param {string} catalogItemId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut: async (orderLineId: string, compartmentId: number, catalogItemId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderLineId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut', 'orderLineId', orderLineId)
                    // verify required parameter 'compartmentId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut', 'compartmentId', compartmentId)
                    // verify required parameter 'catalogItemId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut', 'catalogItemId', catalogItemId)
            const localVarPath = `/acl/groeneveld/order-line/{orderLineId}/compartment/{compartmentId}/catalog-item/{catalogItemId}`
                .replace(`{${"orderLineId"}}`, encodeURIComponent(String(orderLineId)))
                .replace(`{${"compartmentId"}}`, encodeURIComponent(String(compartmentId)))
                .replace(`{${"catalogItemId"}}`, encodeURIComponent(String(catalogItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderLineId 
            * @param {number} compartmentId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut: async (orderLineId: string, compartmentId: number, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderLineId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut', 'orderLineId', orderLineId)
                    // verify required parameter 'compartmentId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut', 'compartmentId', compartmentId)
            const localVarPath = `/acl/groeneveld/order-line/{orderLineId}/compartment/{compartmentId}/catalog-item/revert`
                .replace(`{${"orderLineId"}}`, encodeURIComponent(String(orderLineId)))
                .replace(`{${"compartmentId"}}`, encodeURIComponent(String(compartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderLineId 
            * @param {string} catalogItemId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut: async (orderLineId: string, catalogItemId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderLineId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut', 'orderLineId', orderLineId)
                    // verify required parameter 'catalogItemId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut', 'catalogItemId', catalogItemId)
            const localVarPath = `/acl/groeneveld/order-line/{orderLineId}/configured-sill/catalog-item/{catalogItemId}`
                .replace(`{${"orderLineId"}}`, encodeURIComponent(String(orderLineId)))
                .replace(`{${"catalogItemId"}}`, encodeURIComponent(String(catalogItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} orderLineId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut: async (orderLineId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'orderLineId' is not null or undefined
                    assertParamExists('aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut', 'orderLineId', orderLineId)
            const localVarPath = `/acl/groeneveld/order-line/{orderLineId}/configured-sill/catalog-item/revert`
                .replace(`{${"orderLineId"}}`, encodeURIComponent(String(orderLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * GroeneveldApi - functional programming interface
        * @export
        */
        export const GroeneveldApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = GroeneveldApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} groeneveldArticleId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldArticleGroeneveldArticleIdGet(groeneveldArticleId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroeneveldArticleRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldArticleGroeneveldArticleIdGet(groeneveldArticleId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldArticleGroeneveldArticleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldArticlesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroeneveldArticleRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldArticlesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldArticlesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} groeneveldConfiguredSillId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroeneveldConfiguredSillCompartmentRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet(groeneveldConfiguredSillId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} groeneveldConfiguredSillId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut(groeneveldConfiguredSillId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} groeneveldConfiguredSillId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroeneveldConfiguredSillNeutRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet(groeneveldConfiguredSillId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldCopyImportIdPost(importId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldCopyImportIdPost(importId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldCopyImportIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderLineId 
                * @param {number} compartmentId 
                * @param {string} catalogItemId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut(orderLineId: string, compartmentId: number, catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut(orderLineId, compartmentId, catalogItemId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderLineId 
                * @param {number} compartmentId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut(orderLineId: string, compartmentId: number, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut(orderLineId, compartmentId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderLineId 
                * @param {string} catalogItemId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut(orderLineId: string, catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut(orderLineId, catalogItemId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} orderLineId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut(orderLineId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut(orderLineId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroeneveldApi.aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * GroeneveldApi - factory interface
        * @export
        */
        export const GroeneveldApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = GroeneveldApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} groeneveldArticleId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldArticleGroeneveldArticleIdGet(groeneveldArticleId: string, apiVersion?: string, options?: any): AxiosPromise<GroeneveldArticleRepresentation> {
                return localVarFp.aclGroeneveldArticleGroeneveldArticleIdGet(groeneveldArticleId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldArticlesGet(apiVersion?: string, options?: any): AxiosPromise<Array<GroeneveldArticleRepresentation>> {
                return localVarFp.aclGroeneveldArticlesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: any): AxiosPromise<Array<GroeneveldConfiguredSillCompartmentRepresentation>> {
                return localVarFp.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet(groeneveldConfiguredSillId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut(groeneveldConfiguredSillId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut(groeneveldConfiguredSillId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: any): AxiosPromise<Array<GroeneveldConfiguredSillNeutRepresentation>> {
                return localVarFp.aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet(groeneveldConfiguredSillId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldCopyImportIdPost(importId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclGroeneveldCopyImportIdPost(importId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderLineId 
                    * @param {number} compartmentId 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut(orderLineId: string, compartmentId: number, catalogItemId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut(orderLineId, compartmentId, catalogItemId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderLineId 
                    * @param {number} compartmentId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut(orderLineId: string, compartmentId: number, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut(orderLineId, compartmentId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderLineId 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut(orderLineId: string, catalogItemId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut(orderLineId, catalogItemId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} orderLineId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut(orderLineId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut(orderLineId, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * GroeneveldApi - interface
            * @export
            * @interface GroeneveldApi
            */
            export interface GroeneveldApiInterface {
                /**
                * 
                    * @param {string} groeneveldArticleId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldArticleGroeneveldArticleIdGet(groeneveldArticleId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<GroeneveldArticleRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldArticlesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GroeneveldArticleRepresentation>>;

                /**
                * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GroeneveldConfiguredSillCompartmentRepresentation>>;

                /**
                * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GroeneveldConfiguredSillNeutRepresentation>>;

                /**
                * 
                    * @param {string} importId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldCopyImportIdPost(importId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderLineId 
                    * @param {number} compartmentId 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut(orderLineId: string, compartmentId: number, catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderLineId 
                    * @param {number} compartmentId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut(orderLineId: string, compartmentId: number, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderLineId 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut(orderLineId: string, catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} orderLineId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof GroeneveldApiInterface
                */
            aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut(orderLineId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * GroeneveldApi - object-oriented interface
        * @export
        * @class GroeneveldApi
        * @extends {BaseAPI}
        */
            export class GroeneveldApi extends BaseAPI implements GroeneveldApiInterface {
            /**
            * 
                    * @param {string} groeneveldArticleId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldArticleGroeneveldArticleIdGet(groeneveldArticleId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldArticleGroeneveldArticleIdGet(groeneveldArticleId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldArticlesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldArticlesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdCompartmentsGet(groeneveldConfiguredSillId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdMarkUserCheckedPut(groeneveldConfiguredSillId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} groeneveldConfiguredSillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet(groeneveldConfiguredSillId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldConfiguredSillGroeneveldConfiguredSillIdNeutenGet(groeneveldConfiguredSillId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldCopyImportIdPost(importId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldCopyImportIdPost(importId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderLineId 
                    * @param {number} compartmentId 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut(orderLineId: string, compartmentId: number, catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemCatalogItemIdPut(orderLineId, compartmentId, catalogItemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderLineId 
                    * @param {number} compartmentId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut(orderLineId: string, compartmentId: number, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldOrderLineOrderLineIdCompartmentCompartmentIdCatalogItemRevertPut(orderLineId, compartmentId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderLineId 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut(orderLineId: string, catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemCatalogItemIdPut(orderLineId, catalogItemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} orderLineId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof GroeneveldApi
            */
                public aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut(orderLineId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return GroeneveldApiFp(this.configuration).aclGroeneveldOrderLineOrderLineIdConfiguredSillCatalogItemRevertPut(orderLineId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ImportApi - axios parameter creator
    * @export
    */
    export const ImportApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {string} [body] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportCreateEdsOrderPost: async (apiVersion?: string, body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/createEdsOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {string} [body] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportCreateOrderPost: async (apiVersion?: string, body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/createOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportDownloadIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('aclImportDownloadIdGet', 'id', id)
            const localVarPath = `/acl/import/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('aclImportIdGet', 'id', id)
            const localVarPath = `/acl/import/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportIdOrderGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('aclImportIdOrderGet', 'id', id)
            const localVarPath = `/acl/import/{id}/order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportListOdataGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {string} [customerId] 
            * @param {string} [title] 
            * @param {boolean} [detectWallConnections] 
            * @param {File} [file] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportPost: async (apiVersion?: string, customerId?: string, title?: string, detectWallConnections?: boolean, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
                const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


                        if (customerId !== undefined) { 
                            localVarFormParams.append('customerId', customerId as any);
                        }
            
                        if (title !== undefined) { 
                            localVarFormParams.append('title', title as any);
                        }
            
                        if (detectWallConnections !== undefined) { 
                            localVarFormParams.append('detectWallConnections', String(detectWallConnections) as any);
                        }
            
                        if (file !== undefined) { 
                            localVarFormParams.append('file', file as any);
                        }
            
        
                localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = localVarFormParams;

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ImportApi - functional programming interface
        * @export
        */
        export const ImportApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportCreateEdsOrderPost(apiVersion?: string, body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportCreateEdsOrderPost(apiVersion, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportApi.aclImportCreateEdsOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportCreateOrderPost(apiVersion?: string, body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportCreateOrderPost(apiVersion, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportApi.aclImportCreateOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportDownloadIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportDownloadIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportApi.aclImportDownloadIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportApi.aclImportIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportIdOrderGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportOrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportIdOrderGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportApi.aclImportIdOrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportListViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportApi.aclImportListOdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {string} [customerId] 
                * @param {string} [title] 
                * @param {boolean} [detectWallConnections] 
                * @param {File} [file] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportPost(apiVersion?: string, customerId?: string, title?: string, detectWallConnections?: boolean, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportPost(apiVersion, customerId, title, detectWallConnections, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportApi.aclImportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ImportApi - factory interface
        * @export
        */
        export const ImportApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ImportApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportCreateEdsOrderPost(apiVersion?: string, body?: string, options?: any): AxiosPromise<string> {
                return localVarFp.aclImportCreateEdsOrderPost(apiVersion, body, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportCreateOrderPost(apiVersion?: string, body?: string, options?: any): AxiosPromise<string> {
                return localVarFp.aclImportCreateOrderPost(apiVersion, body, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportDownloadIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.aclImportDownloadIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ImportRepresentation> {
                return localVarFp.aclImportIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportIdOrderGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ImportOrderRepresentation> {
                return localVarFp.aclImportIdOrderGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<ImportListViewArrayODataValue> {
                return localVarFp.aclImportListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [customerId] 
                    * @param {string} [title] 
                    * @param {boolean} [detectWallConnections] 
                    * @param {File} [file] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportPost(apiVersion?: string, customerId?: string, title?: string, detectWallConnections?: boolean, file?: File, options?: any): AxiosPromise<string> {
                return localVarFp.aclImportPost(apiVersion, customerId, title, detectWallConnections, file, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ImportApi - interface
            * @export
            * @interface ImportApi
            */
            export interface ImportApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [body] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportApiInterface
                */
            aclImportCreateEdsOrderPost(apiVersion?: string, body?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [body] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportApiInterface
                */
            aclImportCreateOrderPost(apiVersion?: string, body?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportApiInterface
                */
            aclImportDownloadIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportApiInterface
                */
            aclImportIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportApiInterface
                */
            aclImportIdOrderGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportOrderRepresentation>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportApiInterface
                */
            aclImportListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportListViewArrayODataValue>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [customerId] 
                    * @param {string} [title] 
                    * @param {boolean} [detectWallConnections] 
                    * @param {File} [file] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportApiInterface
                */
            aclImportPost(apiVersion?: string, customerId?: string, title?: string, detectWallConnections?: boolean, file?: File, options?: RawAxiosRequestConfig): AxiosPromise<string>;

            }

        /**
        * ImportApi - object-oriented interface
        * @export
        * @class ImportApi
        * @extends {BaseAPI}
        */
            export class ImportApi extends BaseAPI implements ImportApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportApi
            */
                public aclImportCreateEdsOrderPost(apiVersion?: string, body?: string, options?: RawAxiosRequestConfig) {
                return ImportApiFp(this.configuration).aclImportCreateEdsOrderPost(apiVersion, body, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportApi
            */
                public aclImportCreateOrderPost(apiVersion?: string, body?: string, options?: RawAxiosRequestConfig) {
                return ImportApiFp(this.configuration).aclImportCreateOrderPost(apiVersion, body, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportApi
            */
                public aclImportDownloadIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportApiFp(this.configuration).aclImportDownloadIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportApi
            */
                public aclImportIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportApiFp(this.configuration).aclImportIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportApi
            */
                public aclImportIdOrderGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportApiFp(this.configuration).aclImportIdOrderGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportApi
            */
                public aclImportListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportApiFp(this.configuration).aclImportListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {string} [customerId] 
                    * @param {string} [title] 
                    * @param {boolean} [detectWallConnections] 
                    * @param {File} [file] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportApi
            */
                public aclImportPost(apiVersion?: string, customerId?: string, title?: string, detectWallConnections?: boolean, file?: File, options?: RawAxiosRequestConfig) {
                return ImportApiFp(this.configuration).aclImportPost(apiVersion, customerId, title, detectWallConnections, file, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ImportMappingApi - axios parameter creator
    * @export
    */
    export const ImportMappingApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} importCompartmentLayoutMappingId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet: async (importCompartmentLayoutMappingId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importCompartmentLayoutMappingId' is not null or undefined
                    assertParamExists('aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet', 'importCompartmentLayoutMappingId', importCompartmentLayoutMappingId)
            const localVarPath = `/acl/import/mapping/compartment-layout/{importCompartmentLayoutMappingId}`
                .replace(`{${"importCompartmentLayoutMappingId"}}`, encodeURIComponent(String(importCompartmentLayoutMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingCompartmentLayoutsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/compartment-layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importCornerPieceMappingId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingCornerpieceImportCornerPieceMappingIdGet: async (importCornerPieceMappingId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importCornerPieceMappingId' is not null or undefined
                    assertParamExists('aclImportMappingCornerpieceImportCornerPieceMappingIdGet', 'importCornerPieceMappingId', importCornerPieceMappingId)
            const localVarPath = `/acl/import/mapping/cornerpiece/{importCornerPieceMappingId}`
                .replace(`{${"importCornerPieceMappingId"}}`, encodeURIComponent(String(importCornerPieceMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingCornerpiecesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/cornerpieces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importGlazingBarMappingId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingGlazingbarImportGlazingBarMappingIdGet: async (importGlazingBarMappingId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importGlazingBarMappingId' is not null or undefined
                    assertParamExists('aclImportMappingGlazingbarImportGlazingBarMappingIdGet', 'importGlazingBarMappingId', importGlazingBarMappingId)
            const localVarPath = `/acl/import/mapping/glazingbar/{importGlazingBarMappingId}`
                .replace(`{${"importGlazingBarMappingId"}}`, encodeURIComponent(String(importGlazingBarMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingGlazingbarsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/glazingbars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importGlazingProfileMappingId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet: async (importGlazingProfileMappingId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importGlazingProfileMappingId' is not null or undefined
                    assertParamExists('aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet', 'importGlazingProfileMappingId', importGlazingProfileMappingId)
            const localVarPath = `/acl/import/mapping/glazingprofile/{importGlazingProfileMappingId}`
                .replace(`{${"importGlazingProfileMappingId"}}`, encodeURIComponent(String(importGlazingProfileMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingGlazingprofilesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/glazingprofiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingImportedProfileMapsCustomerIdGet: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('aclImportMappingImportedProfileMapsCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/acl/import/mapping/imported-profile-maps/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingProfileIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('aclImportMappingProfileIdDelete', 'id', id)
            const localVarPath = `/acl/import/mapping/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingProfileIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('aclImportMappingProfileIdGet', 'id', id)
            const localVarPath = `/acl/import/mapping/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateImportProfileMappingCommand} [createImportProfileMappingCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingProfilePost: async (apiVersion?: string, createImportProfileMappingCommand?: CreateImportProfileMappingCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createImportProfileMappingCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importSillMappingId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingSillImportSillMappingIdGet: async (importSillMappingId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importSillMappingId' is not null or undefined
                    assertParamExists('aclImportMappingSillImportSillMappingIdGet', 'importSillMappingId', importSillMappingId)
            const localVarPath = `/acl/import/mapping/sill/{importSillMappingId}`
                .replace(`{${"importSillMappingId"}}`, encodeURIComponent(String(importSillMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingSillsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/sills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingSluitpotCustomerCustomerIdGet: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('aclImportMappingSluitpotCustomerCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/acl/import/mapping/sluitpot/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingSluitpotIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('aclImportMappingSluitpotIdDelete', 'id', id)
            const localVarPath = `/acl/import/mapping/sluitpot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingSluitpotIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('aclImportMappingSluitpotIdGet', 'id', id)
            const localVarPath = `/acl/import/mapping/sluitpot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateImportSluitpotMappingCommand} [createImportSluitpotMappingCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingSluitpotPost: async (apiVersion?: string, createImportSluitpotMappingCommand?: CreateImportSluitpotMappingCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/sluitpot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createImportSluitpotMappingCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importStopProfileMappingId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingStopprofileImportStopProfileMappingIdGet: async (importStopProfileMappingId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importStopProfileMappingId' is not null or undefined
                    assertParamExists('aclImportMappingStopprofileImportStopProfileMappingIdGet', 'importStopProfileMappingId', importStopProfileMappingId)
            const localVarPath = `/acl/import/mapping/stopprofile/{importStopProfileMappingId}`
                .replace(`{${"importStopProfileMappingId"}}`, encodeURIComponent(String(importStopProfileMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportMappingStopprofilesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/mapping/stopprofiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ImportMappingApi - functional programming interface
        * @export
        */
        export const ImportMappingApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ImportMappingApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} importCompartmentLayoutMappingId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(importCompartmentLayoutMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportCompartmentLayoutMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(importCompartmentLayoutMappingId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingCompartmentLayoutsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportCompartmentLayoutMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingCompartmentLayoutsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingCompartmentLayoutsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importCornerPieceMappingId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingCornerpieceImportCornerPieceMappingIdGet(importCornerPieceMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportCornerPieceMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingCornerpieceImportCornerPieceMappingIdGet(importCornerPieceMappingId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingCornerpieceImportCornerPieceMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingCornerpiecesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportCornerPieceMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingCornerpiecesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingCornerpiecesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importGlazingBarMappingId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingGlazingbarImportGlazingBarMappingIdGet(importGlazingBarMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportGlazingBarMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingGlazingbarImportGlazingBarMappingIdGet(importGlazingBarMappingId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingGlazingbarImportGlazingBarMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingGlazingbarsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportGlazingBarMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingGlazingbarsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingGlazingbarsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importGlazingProfileMappingId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(importGlazingProfileMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportGlazingProfileMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(importGlazingProfileMappingId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingGlazingprofilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportGlazingProfileMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingGlazingprofilesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingGlazingprofilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingImportedProfileMapsCustomerIdGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportedProfileMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingImportedProfileMapsCustomerIdGet(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingImportedProfileMapsCustomerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingProfileIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingProfileIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingProfileIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingProfileIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportedProfileMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingProfileIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingProfileIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateImportProfileMappingCommand} [createImportProfileMappingCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingProfilePost(apiVersion?: string, createImportProfileMappingCommand?: CreateImportProfileMappingCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingProfilePost(apiVersion, createImportProfileMappingCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingProfilePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importSillMappingId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingSillImportSillMappingIdGet(importSillMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportSillMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingSillImportSillMappingIdGet(importSillMappingId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingSillImportSillMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingSillsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportSillMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingSillsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingSillsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingSluitpotCustomerCustomerIdGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportSluitpotMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingSluitpotCustomerCustomerIdGet(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingSluitpotCustomerCustomerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingSluitpotIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingSluitpotIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingSluitpotIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingSluitpotIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportSluitpotMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingSluitpotIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingSluitpotIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateImportSluitpotMappingCommand} [createImportSluitpotMappingCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingSluitpotPost(apiVersion?: string, createImportSluitpotMappingCommand?: CreateImportSluitpotMappingCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingSluitpotPost(apiVersion, createImportSluitpotMappingCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingSluitpotPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importStopProfileMappingId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingStopprofileImportStopProfileMappingIdGet(importStopProfileMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportStopProfileMappingRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingStopprofileImportStopProfileMappingIdGet(importStopProfileMappingId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingStopprofileImportStopProfileMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportMappingStopprofilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportStopProfileMappingRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportMappingStopprofilesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportMappingApi.aclImportMappingStopprofilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ImportMappingApi - factory interface
        * @export
        */
        export const ImportMappingApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ImportMappingApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} importCompartmentLayoutMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(importCompartmentLayoutMappingId: string, apiVersion?: string, options?: any): AxiosPromise<ImportCompartmentLayoutMappingRepresentation> {
                return localVarFp.aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(importCompartmentLayoutMappingId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingCompartmentLayoutsGet(apiVersion?: string, options?: any): AxiosPromise<Array<ImportCompartmentLayoutMappingRepresentation>> {
                return localVarFp.aclImportMappingCompartmentLayoutsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importCornerPieceMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingCornerpieceImportCornerPieceMappingIdGet(importCornerPieceMappingId: string, apiVersion?: string, options?: any): AxiosPromise<ImportCornerPieceMappingRepresentation> {
                return localVarFp.aclImportMappingCornerpieceImportCornerPieceMappingIdGet(importCornerPieceMappingId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingCornerpiecesGet(apiVersion?: string, options?: any): AxiosPromise<Array<ImportCornerPieceMappingRepresentation>> {
                return localVarFp.aclImportMappingCornerpiecesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importGlazingBarMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingGlazingbarImportGlazingBarMappingIdGet(importGlazingBarMappingId: string, apiVersion?: string, options?: any): AxiosPromise<ImportGlazingBarMappingRepresentation> {
                return localVarFp.aclImportMappingGlazingbarImportGlazingBarMappingIdGet(importGlazingBarMappingId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingGlazingbarsGet(apiVersion?: string, options?: any): AxiosPromise<Array<ImportGlazingBarMappingRepresentation>> {
                return localVarFp.aclImportMappingGlazingbarsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importGlazingProfileMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(importGlazingProfileMappingId: string, apiVersion?: string, options?: any): AxiosPromise<ImportGlazingProfileMappingRepresentation> {
                return localVarFp.aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(importGlazingProfileMappingId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingGlazingprofilesGet(apiVersion?: string, options?: any): AxiosPromise<Array<ImportGlazingProfileMappingRepresentation>> {
                return localVarFp.aclImportMappingGlazingprofilesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingImportedProfileMapsCustomerIdGet(customerId: string, apiVersion?: string, options?: any): AxiosPromise<Array<ImportedProfileMappingRepresentation>> {
                return localVarFp.aclImportMappingImportedProfileMapsCustomerIdGet(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingProfileIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclImportMappingProfileIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingProfileIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ImportedProfileMappingRepresentation> {
                return localVarFp.aclImportMappingProfileIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateImportProfileMappingCommand} [createImportProfileMappingCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingProfilePost(apiVersion?: string, createImportProfileMappingCommand?: CreateImportProfileMappingCommand, options?: any): AxiosPromise<string> {
                return localVarFp.aclImportMappingProfilePost(apiVersion, createImportProfileMappingCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importSillMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingSillImportSillMappingIdGet(importSillMappingId: string, apiVersion?: string, options?: any): AxiosPromise<ImportSillMappingRepresentation> {
                return localVarFp.aclImportMappingSillImportSillMappingIdGet(importSillMappingId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingSillsGet(apiVersion?: string, options?: any): AxiosPromise<Array<ImportSillMappingRepresentation>> {
                return localVarFp.aclImportMappingSillsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingSluitpotCustomerCustomerIdGet(customerId: string, apiVersion?: string, options?: any): AxiosPromise<Array<ImportSluitpotMappingRepresentation>> {
                return localVarFp.aclImportMappingSluitpotCustomerCustomerIdGet(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingSluitpotIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.aclImportMappingSluitpotIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingSluitpotIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ImportSluitpotMappingRepresentation> {
                return localVarFp.aclImportMappingSluitpotIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateImportSluitpotMappingCommand} [createImportSluitpotMappingCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingSluitpotPost(apiVersion?: string, createImportSluitpotMappingCommand?: CreateImportSluitpotMappingCommand, options?: any): AxiosPromise<string> {
                return localVarFp.aclImportMappingSluitpotPost(apiVersion, createImportSluitpotMappingCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importStopProfileMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingStopprofileImportStopProfileMappingIdGet(importStopProfileMappingId: string, apiVersion?: string, options?: any): AxiosPromise<ImportStopProfileMappingRepresentation> {
                return localVarFp.aclImportMappingStopprofileImportStopProfileMappingIdGet(importStopProfileMappingId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportMappingStopprofilesGet(apiVersion?: string, options?: any): AxiosPromise<Array<ImportStopProfileMappingRepresentation>> {
                return localVarFp.aclImportMappingStopprofilesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ImportMappingApi - interface
            * @export
            * @interface ImportMappingApi
            */
            export interface ImportMappingApiInterface {
                /**
                * 
                    * @param {string} importCompartmentLayoutMappingId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(importCompartmentLayoutMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportCompartmentLayoutMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingCompartmentLayoutsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportCompartmentLayoutMappingRepresentation>>;

                /**
                * 
                    * @param {string} importCornerPieceMappingId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingCornerpieceImportCornerPieceMappingIdGet(importCornerPieceMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportCornerPieceMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingCornerpiecesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportCornerPieceMappingRepresentation>>;

                /**
                * 
                    * @param {string} importGlazingBarMappingId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingGlazingbarImportGlazingBarMappingIdGet(importGlazingBarMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportGlazingBarMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingGlazingbarsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportGlazingBarMappingRepresentation>>;

                /**
                * 
                    * @param {string} importGlazingProfileMappingId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(importGlazingProfileMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportGlazingProfileMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingGlazingprofilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportGlazingProfileMappingRepresentation>>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingImportedProfileMapsCustomerIdGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportedProfileMappingRepresentation>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingProfileIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingProfileIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportedProfileMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateImportProfileMappingCommand} [createImportProfileMappingCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingProfilePost(apiVersion?: string, createImportProfileMappingCommand?: CreateImportProfileMappingCommand, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} importSillMappingId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingSillImportSillMappingIdGet(importSillMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportSillMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingSillsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportSillMappingRepresentation>>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingSluitpotCustomerCustomerIdGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportSluitpotMappingRepresentation>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingSluitpotIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingSluitpotIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportSluitpotMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateImportSluitpotMappingCommand} [createImportSluitpotMappingCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingSluitpotPost(apiVersion?: string, createImportSluitpotMappingCommand?: CreateImportSluitpotMappingCommand, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} importStopProfileMappingId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingStopprofileImportStopProfileMappingIdGet(importStopProfileMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportStopProfileMappingRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportMappingApiInterface
                */
            aclImportMappingStopprofilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportStopProfileMappingRepresentation>>;

            }

        /**
        * ImportMappingApi - object-oriented interface
        * @export
        * @class ImportMappingApi
        * @extends {BaseAPI}
        */
            export class ImportMappingApi extends BaseAPI implements ImportMappingApiInterface {
            /**
            * 
                    * @param {string} importCompartmentLayoutMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(importCompartmentLayoutMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(importCompartmentLayoutMappingId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingCompartmentLayoutsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingCompartmentLayoutsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importCornerPieceMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingCornerpieceImportCornerPieceMappingIdGet(importCornerPieceMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingCornerpieceImportCornerPieceMappingIdGet(importCornerPieceMappingId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingCornerpiecesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingCornerpiecesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importGlazingBarMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingGlazingbarImportGlazingBarMappingIdGet(importGlazingBarMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingGlazingbarImportGlazingBarMappingIdGet(importGlazingBarMappingId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingGlazingbarsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingGlazingbarsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importGlazingProfileMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(importGlazingProfileMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(importGlazingProfileMappingId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingGlazingprofilesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingGlazingprofilesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingImportedProfileMapsCustomerIdGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingImportedProfileMapsCustomerIdGet(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingProfileIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingProfileIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingProfileIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingProfileIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateImportProfileMappingCommand} [createImportProfileMappingCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingProfilePost(apiVersion?: string, createImportProfileMappingCommand?: CreateImportProfileMappingCommand, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingProfilePost(apiVersion, createImportProfileMappingCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importSillMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingSillImportSillMappingIdGet(importSillMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingSillImportSillMappingIdGet(importSillMappingId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingSillsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingSillsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingSluitpotCustomerCustomerIdGet(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingSluitpotCustomerCustomerIdGet(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingSluitpotIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingSluitpotIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingSluitpotIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingSluitpotIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateImportSluitpotMappingCommand} [createImportSluitpotMappingCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingSluitpotPost(apiVersion?: string, createImportSluitpotMappingCommand?: CreateImportSluitpotMappingCommand, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingSluitpotPost(apiVersion, createImportSluitpotMappingCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importStopProfileMappingId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingStopprofileImportStopProfileMappingIdGet(importStopProfileMappingId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingStopprofileImportStopProfileMappingIdGet(importStopProfileMappingId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportMappingApi
            */
                public aclImportMappingStopprofilesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportMappingApiFp(this.configuration).aclImportMappingStopprofilesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ImportValidationApi - axios parameter creator
    * @export
    */
    export const ImportValidationApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} importValidationId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportValidationImportValidationIdGet: async (importValidationId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importValidationId' is not null or undefined
                    assertParamExists('aclImportValidationImportValidationIdGet', 'importValidationId', importValidationId)
            const localVarPath = `/acl/import/validation/{importValidationId}`
                .replace(`{${"importValidationId"}}`, encodeURIComponent(String(importValidationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportValidationListImportIdGet: async (importId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importId' is not null or undefined
                    assertParamExists('aclImportValidationListImportIdGet', 'importId', importId)
            const localVarPath = `/acl/import/validation/list/{importId}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {AclImportValidationResolvePutRequest} [aclImportValidationResolvePutRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportValidationResolvePut: async (apiVersion?: string, aclImportValidationResolvePutRequest?: AclImportValidationResolvePutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acl/import/validation/resolve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(aclImportValidationResolvePutRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} importId 
            * @param {ImportType} [importType] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        aclImportValidationRevalidateImportIdPost: async (importId: string, importType?: ImportType, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'importId' is not null or undefined
                    assertParamExists('aclImportValidationRevalidateImportIdPost', 'importId', importId)
            const localVarPath = `/acl/import/validation/revalidate/{importId}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (importType !== undefined) {
                                        localVarQueryParameter['importType'] = importType;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ImportValidationApi - functional programming interface
        * @export
        */
        export const ImportValidationApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ImportValidationApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} importValidationId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportValidationImportValidationIdGet(importValidationId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclImportValidationImportValidationIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportValidationImportValidationIdGet(importValidationId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportValidationApi.aclImportValidationImportValidationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportValidationListImportIdGet(importId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportValidationListImportIdGet(importId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportValidationApi.aclImportValidationListImportIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {AclImportValidationResolvePutRequest} [aclImportValidationResolvePutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportValidationResolvePut(apiVersion?: string, aclImportValidationResolvePutRequest?: AclImportValidationResolvePutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportValidationResolvePut(apiVersion, aclImportValidationResolvePutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportValidationApi.aclImportValidationResolvePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} importId 
                * @param {ImportType} [importType] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async aclImportValidationRevalidateImportIdPost(importId: string, importType?: ImportType, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aclImportValidationRevalidateImportIdPost(importId, importType, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImportValidationApi.aclImportValidationRevalidateImportIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ImportValidationApi - factory interface
        * @export
        */
        export const ImportValidationApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ImportValidationApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} importValidationId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportValidationImportValidationIdGet(importValidationId: string, apiVersion?: string, options?: any): AxiosPromise<AclImportValidationImportValidationIdGet200Response> {
                return localVarFp.aclImportValidationImportValidationIdGet(importValidationId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportValidationListImportIdGet(importId: string, apiVersion?: string, options?: any): AxiosPromise<ImportValidationResult> {
                return localVarFp.aclImportValidationListImportIdGet(importId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {AclImportValidationResolvePutRequest} [aclImportValidationResolvePutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportValidationResolvePut(apiVersion?: string, aclImportValidationResolvePutRequest?: AclImportValidationResolvePutRequest, options?: any): AxiosPromise<void> {
                return localVarFp.aclImportValidationResolvePut(apiVersion, aclImportValidationResolvePutRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} importId 
                    * @param {ImportType} [importType] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            aclImportValidationRevalidateImportIdPost(importId: string, importType?: ImportType, apiVersion?: string, options?: any): AxiosPromise<ImportValidationResult> {
                return localVarFp.aclImportValidationRevalidateImportIdPost(importId, importType, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ImportValidationApi - interface
            * @export
            * @interface ImportValidationApi
            */
            export interface ImportValidationApiInterface {
                /**
                * 
                    * @param {string} importValidationId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportValidationApiInterface
                */
            aclImportValidationImportValidationIdGet(importValidationId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<AclImportValidationImportValidationIdGet200Response>;

                /**
                * 
                    * @param {string} importId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportValidationApiInterface
                */
            aclImportValidationListImportIdGet(importId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportValidationResult>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {AclImportValidationResolvePutRequest} [aclImportValidationResolvePutRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportValidationApiInterface
                */
            aclImportValidationResolvePut(apiVersion?: string, aclImportValidationResolvePutRequest?: AclImportValidationResolvePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} importId 
                    * @param {ImportType} [importType] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ImportValidationApiInterface
                */
            aclImportValidationRevalidateImportIdPost(importId: string, importType?: ImportType, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ImportValidationResult>;

            }

        /**
        * ImportValidationApi - object-oriented interface
        * @export
        * @class ImportValidationApi
        * @extends {BaseAPI}
        */
            export class ImportValidationApi extends BaseAPI implements ImportValidationApiInterface {
            /**
            * 
                    * @param {string} importValidationId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportValidationApi
            */
                public aclImportValidationImportValidationIdGet(importValidationId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportValidationApiFp(this.configuration).aclImportValidationImportValidationIdGet(importValidationId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportValidationApi
            */
                public aclImportValidationListImportIdGet(importId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportValidationApiFp(this.configuration).aclImportValidationListImportIdGet(importId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {AclImportValidationResolvePutRequest} [aclImportValidationResolvePutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportValidationApi
            */
                public aclImportValidationResolvePut(apiVersion?: string, aclImportValidationResolvePutRequest?: AclImportValidationResolvePutRequest, options?: RawAxiosRequestConfig) {
                return ImportValidationApiFp(this.configuration).aclImportValidationResolvePut(apiVersion, aclImportValidationResolvePutRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} importId 
                    * @param {ImportType} [importType] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ImportValidationApi
            */
                public aclImportValidationRevalidateImportIdPost(importId: string, importType?: ImportType, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ImportValidationApiFp(this.configuration).aclImportValidationRevalidateImportIdPost(importId, importType, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * InvoiceApi - axios parameter creator
    * @export
    */
    export const InvoiceApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmInvoicesIdInvoicedPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmInvoicesIdInvoicedPut', 'id', id)
            const localVarPath = `/pm/invoices/{id}/invoiced`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmInvoicesIdNotInvoicedPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmInvoicesIdNotInvoicedPut', 'id', id)
            const localVarPath = `/pm/invoices/{id}/not-invoiced`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmInvoicesIdPaidPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmInvoicesIdPaidPut', 'id', id)
            const localVarPath = `/pm/invoices/{id}/paid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmInvoicesListOdataGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/invoices/list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * InvoiceApi - functional programming interface
        * @export
        */
        export const InvoiceApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmInvoicesIdInvoicedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmInvoicesIdInvoicedPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.pmInvoicesIdInvoicedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmInvoicesIdNotInvoicedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmInvoicesIdNotInvoicedPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.pmInvoicesIdNotInvoicedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmInvoicesIdPaidPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmInvoicesIdPaidPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.pmInvoicesIdPaidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmInvoicesListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceListViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmInvoicesListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.pmInvoicesListOdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * InvoiceApi - factory interface
        * @export
        */
        export const InvoiceApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = InvoiceApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmInvoicesIdInvoicedPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmInvoicesIdInvoicedPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmInvoicesIdNotInvoicedPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmInvoicesIdNotInvoicedPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmInvoicesIdPaidPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmInvoicesIdPaidPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmInvoicesListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<InvoiceListViewArrayODataValue> {
                return localVarFp.pmInvoicesListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * InvoiceApi - interface
            * @export
            * @interface InvoiceApi
            */
            export interface InvoiceApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof InvoiceApiInterface
                */
            pmInvoicesIdInvoicedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof InvoiceApiInterface
                */
            pmInvoicesIdNotInvoicedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof InvoiceApiInterface
                */
            pmInvoicesIdPaidPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof InvoiceApiInterface
                */
            pmInvoicesListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<InvoiceListViewArrayODataValue>;

            }

        /**
        * InvoiceApi - object-oriented interface
        * @export
        * @class InvoiceApi
        * @extends {BaseAPI}
        */
            export class InvoiceApi extends BaseAPI implements InvoiceApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof InvoiceApi
            */
                public pmInvoicesIdInvoicedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return InvoiceApiFp(this.configuration).pmInvoicesIdInvoicedPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof InvoiceApi
            */
                public pmInvoicesIdNotInvoicedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return InvoiceApiFp(this.configuration).pmInvoicesIdNotInvoicedPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof InvoiceApi
            */
                public pmInvoicesIdPaidPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return InvoiceApiFp(this.configuration).pmInvoicesIdPaidPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof InvoiceApi
            */
                public pmInvoicesListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return InvoiceApiFp(this.configuration).pmInvoicesListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * LProfileApi - axios parameter creator
    * @export
    */
    export const LProfileApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimLProfilesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/l-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimLProfilesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimLProfilesIdDelete', 'id', id)
            const localVarPath = `/pim/l-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimLProfilesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimLProfilesIdGet', 'id', id)
            const localVarPath = `/pim/l-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateLProfileCommand} [updateLProfileCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimLProfilesIdPut: async (id: string, apiVersion?: string, updateLProfileCommand?: UpdateLProfileCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimLProfilesIdPut', 'id', id)
            const localVarPath = `/pim/l-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateLProfileCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateLProfileCommand} [createLProfileCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimLProfilesPost: async (apiVersion?: string, createLProfileCommand?: CreateLProfileCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/l-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createLProfileCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * LProfileApi - functional programming interface
        * @export
        */
        export const LProfileApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = LProfileApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimLProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimLProfilesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LProfileApi.pimLProfilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimLProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimLProfilesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LProfileApi.pimLProfilesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimLProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimLProfilesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LProfileApi.pimLProfilesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateLProfileCommand} [updateLProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimLProfilesIdPut(id: string, apiVersion?: string, updateLProfileCommand?: UpdateLProfileCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimLProfilesIdPut(id, apiVersion, updateLProfileCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LProfileApi.pimLProfilesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateLProfileCommand} [createLProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimLProfilesPost(apiVersion?: string, createLProfileCommand?: CreateLProfileCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimLProfilesPost(apiVersion, createLProfileCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LProfileApi.pimLProfilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * LProfileApi - factory interface
        * @export
        */
        export const LProfileApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = LProfileApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimLProfilesGet(apiVersion?: string, options?: any): AxiosPromise<Array<LProfile>> {
                return localVarFp.pimLProfilesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimLProfilesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimLProfilesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimLProfilesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<LProfile> {
                return localVarFp.pimLProfilesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateLProfileCommand} [updateLProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimLProfilesIdPut(id: string, apiVersion?: string, updateLProfileCommand?: UpdateLProfileCommand, options?: any): AxiosPromise<LProfile> {
                return localVarFp.pimLProfilesIdPut(id, apiVersion, updateLProfileCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateLProfileCommand} [createLProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimLProfilesPost(apiVersion?: string, createLProfileCommand?: CreateLProfileCommand, options?: any): AxiosPromise<LProfile> {
                return localVarFp.pimLProfilesPost(apiVersion, createLProfileCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * LProfileApi - interface
            * @export
            * @interface LProfileApi
            */
            export interface LProfileApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof LProfileApiInterface
                */
            pimLProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<LProfile>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof LProfileApiInterface
                */
            pimLProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof LProfileApiInterface
                */
            pimLProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<LProfile>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateLProfileCommand} [updateLProfileCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof LProfileApiInterface
                */
            pimLProfilesIdPut(id: string, apiVersion?: string, updateLProfileCommand?: UpdateLProfileCommand, options?: RawAxiosRequestConfig): AxiosPromise<LProfile>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateLProfileCommand} [createLProfileCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof LProfileApiInterface
                */
            pimLProfilesPost(apiVersion?: string, createLProfileCommand?: CreateLProfileCommand, options?: RawAxiosRequestConfig): AxiosPromise<LProfile>;

            }

        /**
        * LProfileApi - object-oriented interface
        * @export
        * @class LProfileApi
        * @extends {BaseAPI}
        */
            export class LProfileApi extends BaseAPI implements LProfileApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof LProfileApi
            */
                public pimLProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return LProfileApiFp(this.configuration).pimLProfilesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof LProfileApi
            */
                public pimLProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return LProfileApiFp(this.configuration).pimLProfilesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof LProfileApi
            */
                public pimLProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return LProfileApiFp(this.configuration).pimLProfilesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateLProfileCommand} [updateLProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof LProfileApi
            */
                public pimLProfilesIdPut(id: string, apiVersion?: string, updateLProfileCommand?: UpdateLProfileCommand, options?: RawAxiosRequestConfig) {
                return LProfileApiFp(this.configuration).pimLProfilesIdPut(id, apiVersion, updateLProfileCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateLProfileCommand} [createLProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof LProfileApi
            */
                public pimLProfilesPost(apiVersion?: string, createLProfileCommand?: CreateLProfileCommand, options?: RawAxiosRequestConfig) {
                return LProfileApiFp(this.configuration).pimLProfilesPost(apiVersion, createLProfileCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * MountingOptionApi - axios parameter creator
    * @export
    */
    export const MountingOptionApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimMountingOptionsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/mounting-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimMountingOptionsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimMountingOptionsIdDelete', 'id', id)
            const localVarPath = `/pim/mounting-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimMountingOptionsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimMountingOptionsIdGet', 'id', id)
            const localVarPath = `/pim/mounting-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateMountingOptionRequest} [updateMountingOptionRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimMountingOptionsIdPut: async (id: string, apiVersion?: string, updateMountingOptionRequest?: UpdateMountingOptionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimMountingOptionsIdPut', 'id', id)
            const localVarPath = `/pim/mounting-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateMountingOptionRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateMountingOptionCommand} [createMountingOptionCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimMountingOptionsPost: async (apiVersion?: string, createMountingOptionCommand?: CreateMountingOptionCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/mounting-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createMountingOptionCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * MountingOptionApi - functional programming interface
        * @export
        */
        export const MountingOptionApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = MountingOptionApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimMountingOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MountingOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimMountingOptionsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MountingOptionApi.pimMountingOptionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimMountingOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimMountingOptionsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MountingOptionApi.pimMountingOptionsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimMountingOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MountingOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimMountingOptionsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MountingOptionApi.pimMountingOptionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateMountingOptionRequest} [updateMountingOptionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimMountingOptionsIdPut(id: string, apiVersion?: string, updateMountingOptionRequest?: UpdateMountingOptionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MountingOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimMountingOptionsIdPut(id, apiVersion, updateMountingOptionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MountingOptionApi.pimMountingOptionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateMountingOptionCommand} [createMountingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimMountingOptionsPost(apiVersion?: string, createMountingOptionCommand?: CreateMountingOptionCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MountingOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimMountingOptionsPost(apiVersion, createMountingOptionCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MountingOptionApi.pimMountingOptionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * MountingOptionApi - factory interface
        * @export
        */
        export const MountingOptionApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = MountingOptionApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimMountingOptionsGet(apiVersion?: string, options?: any): AxiosPromise<Array<MountingOption>> {
                return localVarFp.pimMountingOptionsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimMountingOptionsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimMountingOptionsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimMountingOptionsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<MountingOption> {
                return localVarFp.pimMountingOptionsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateMountingOptionRequest} [updateMountingOptionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimMountingOptionsIdPut(id: string, apiVersion?: string, updateMountingOptionRequest?: UpdateMountingOptionRequest, options?: any): AxiosPromise<MountingOption> {
                return localVarFp.pimMountingOptionsIdPut(id, apiVersion, updateMountingOptionRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateMountingOptionCommand} [createMountingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimMountingOptionsPost(apiVersion?: string, createMountingOptionCommand?: CreateMountingOptionCommand, options?: any): AxiosPromise<MountingOption> {
                return localVarFp.pimMountingOptionsPost(apiVersion, createMountingOptionCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * MountingOptionApi - interface
            * @export
            * @interface MountingOptionApi
            */
            export interface MountingOptionApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof MountingOptionApiInterface
                */
            pimMountingOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MountingOption>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof MountingOptionApiInterface
                */
            pimMountingOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof MountingOptionApiInterface
                */
            pimMountingOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<MountingOption>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateMountingOptionRequest} [updateMountingOptionRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof MountingOptionApiInterface
                */
            pimMountingOptionsIdPut(id: string, apiVersion?: string, updateMountingOptionRequest?: UpdateMountingOptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<MountingOption>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateMountingOptionCommand} [createMountingOptionCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof MountingOptionApiInterface
                */
            pimMountingOptionsPost(apiVersion?: string, createMountingOptionCommand?: CreateMountingOptionCommand, options?: RawAxiosRequestConfig): AxiosPromise<MountingOption>;

            }

        /**
        * MountingOptionApi - object-oriented interface
        * @export
        * @class MountingOptionApi
        * @extends {BaseAPI}
        */
            export class MountingOptionApi extends BaseAPI implements MountingOptionApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof MountingOptionApi
            */
                public pimMountingOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return MountingOptionApiFp(this.configuration).pimMountingOptionsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof MountingOptionApi
            */
                public pimMountingOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return MountingOptionApiFp(this.configuration).pimMountingOptionsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof MountingOptionApi
            */
                public pimMountingOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return MountingOptionApiFp(this.configuration).pimMountingOptionsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateMountingOptionRequest} [updateMountingOptionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof MountingOptionApi
            */
                public pimMountingOptionsIdPut(id: string, apiVersion?: string, updateMountingOptionRequest?: UpdateMountingOptionRequest, options?: RawAxiosRequestConfig) {
                return MountingOptionApiFp(this.configuration).pimMountingOptionsIdPut(id, apiVersion, updateMountingOptionRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateMountingOptionCommand} [createMountingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof MountingOptionApi
            */
                public pimMountingOptionsPost(apiVersion?: string, createMountingOptionCommand?: CreateMountingOptionCommand, options?: RawAxiosRequestConfig) {
                return MountingOptionApiFp(this.configuration).pimMountingOptionsPost(apiVersion, createMountingOptionCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * OrderApi - axios parameter creator
    * @export
    */
    export const OrderApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getOrderActionLog: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getOrderActionLog', 'id', id)
            const localVarPath = `/sm/order/{id}/actionLog`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} customerId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderCustomerIdDraftPost: async (customerId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'customerId' is not null or undefined
                    assertParamExists('smOrderCustomerIdDraftPost', 'customerId', customerId)
            const localVarPath = `/sm/order/{customerId}/draft`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutAcceptOrderRepresentation} [putAcceptOrderRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdAcceptPut: async (id: string, apiVersion?: string, putAcceptOrderRepresentation?: PutAcceptOrderRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdAcceptPut', 'id', id)
            const localVarPath = `/sm/order/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putAcceptOrderRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdAcceptQuotePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdAcceptQuotePut', 'id', id)
            const localVarPath = `/sm/order/{id}/acceptQuote`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdBmhxmlGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdBmhxmlGet', 'id', id)
            const localVarPath = `/sm/order/{id}/bmhxml`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdCancelPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdCancelPut', 'id', id)
            const localVarPath = `/sm/order/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdCompletePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdCompletePut', 'id', id)
            const localVarPath = `/sm/order/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutContactPersonRepresentation} [putContactPersonRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdContactPut: async (id: string, apiVersion?: string, putContactPersonRepresentation?: PutContactPersonRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdContactPut', 'id', id)
            const localVarPath = `/sm/order/{id}/contact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putContactPersonRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {CopyOrderRepresentation} [copyOrderRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdCopyPost: async (id: string, apiVersion?: string, copyOrderRepresentation?: CopyOrderRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdCopyPost', 'id', id)
            const localVarPath = `/sm/order/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(copyOrderRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdDelete', 'id', id)
            const localVarPath = `/sm/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdExpeditionAddressPut: async (id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdExpeditionAddressPut', 'id', id)
            const localVarPath = `/sm/order/{id}/expedition/address`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(orderAddressRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutExpeditionConditionRepresentation} [putExpeditionConditionRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdExpeditionConditionPut: async (id: string, apiVersion?: string, putExpeditionConditionRepresentation?: PutExpeditionConditionRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdExpeditionConditionPut', 'id', id)
            const localVarPath = `/sm/order/{id}/expedition/condition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putExpeditionConditionRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdExpeditionContactPut: async (id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdExpeditionContactPut', 'id', id)
            const localVarPath = `/sm/order/{id}/expedition/contact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(orderContactPersonRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutExpeditionDateRepresentation} [putExpeditionDateRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdExpeditionPreferredDatePut: async (id: string, apiVersion?: string, putExpeditionDateRepresentation?: PutExpeditionDateRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdExpeditionPreferredDatePut', 'id', id)
            const localVarPath = `/sm/order/{id}/expedition/preferred-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putExpeditionDateRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutOrderExpeditionRepresentation} [putOrderExpeditionRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdExpeditionPut: async (id: string, apiVersion?: string, putOrderExpeditionRepresentation?: PutOrderExpeditionRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdExpeditionPut', 'id', id)
            const localVarPath = `/sm/order/{id}/expedition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putOrderExpeditionRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutOrderReferenceRepresentation} [putOrderReferenceRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdExpeditionReferencePut: async (id: string, apiVersion?: string, putOrderReferenceRepresentation?: PutOrderReferenceRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdExpeditionReferencePut', 'id', id)
            const localVarPath = `/sm/order/{id}/expedition/reference`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putOrderReferenceRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {boolean} [body] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdFastCopyPost: async (id: string, apiVersion?: string, body?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdFastCopyPost', 'id', id)
            const localVarPath = `/sm/order/{id}/fast-copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdFinancialAddressPut: async (id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdFinancialAddressPut', 'id', id)
            const localVarPath = `/sm/order/{id}/financial/address`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(orderAddressRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdFinancialContactPut: async (id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdFinancialContactPut', 'id', id)
            const localVarPath = `/sm/order/{id}/financial/contact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(orderContactPersonRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutPaymentConditionRepresentation} [putPaymentConditionRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdFinancialPaymentConditionPut: async (id: string, apiVersion?: string, putPaymentConditionRepresentation?: PutPaymentConditionRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdFinancialPaymentConditionPut', 'id', id)
            const localVarPath = `/sm/order/{id}/financial/payment-condition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putPaymentConditionRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutPricelistRepresentation} [putPricelistRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdFinancialPricelistPut: async (id: string, apiVersion?: string, putPricelistRepresentation?: PutPricelistRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdFinancialPricelistPut', 'id', id)
            const localVarPath = `/sm/order/{id}/financial/pricelist`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putPricelistRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProjectDiscountRepresentation} [putProjectDiscountRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdFinancialProjectDiscountPut: async (id: string, apiVersion?: string, putProjectDiscountRepresentation?: PutProjectDiscountRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdFinancialProjectDiscountPut', 'id', id)
            const localVarPath = `/sm/order/{id}/financial/project-discount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProjectDiscountRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutOrderFinancialRepresentation} [putOrderFinancialRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdFinancialPut: async (id: string, apiVersion?: string, putOrderFinancialRepresentation?: PutOrderFinancialRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdFinancialPut', 'id', id)
            const localVarPath = `/sm/order/{id}/financial`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putOrderFinancialRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdGet', 'id', id)
            const localVarPath = `/sm/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdIssueQuotePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdIssueQuotePut', 'id', id)
            const localVarPath = `/sm/order/{id}/issue-quote`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdIssueQuoteWithRemarkPut: async (id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdIssueQuoteWithRemarkPut', 'id', id)
            const localVarPath = `/sm/order/{id}/issue-quote-with-remark`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putWizardOrderRemarkRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutOrderDateRepresentation} [putOrderDateRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdOrderDatePut: async (id: string, apiVersion?: string, putOrderDateRepresentation?: PutOrderDateRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdOrderDatePut', 'id', id)
            const localVarPath = `/sm/order/{id}/orderDate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putOrderDateRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdPlacePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdPlacePut', 'id', id)
            const localVarPath = `/sm/order/{id}/place`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdPlaceWithRemarkPut: async (id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdPlaceWithRemarkPut', 'id', id)
            const localVarPath = `/sm/order/{id}/place-with-remark`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putWizardOrderRemarkRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutPlannedProductionDateRepresentation} [putPlannedProductionDateRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdProductionPlannedProductionDatePut: async (id: string, apiVersion?: string, putPlannedProductionDateRepresentation?: PutPlannedProductionDateRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdProductionPlannedProductionDatePut', 'id', id)
            const localVarPath = `/sm/order/{id}/production/planned-production-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putPlannedProductionDateRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutQuoteExpiryDateRepresentation} [putQuoteExpiryDateRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdQuoteExpiryDatePut: async (id: string, apiVersion?: string, putQuoteExpiryDateRepresentation?: PutQuoteExpiryDateRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdQuoteExpiryDatePut', 'id', id)
            const localVarPath = `/sm/order/{id}/quoteExpiryDate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putQuoteExpiryDateRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdRejectQuotePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdRejectQuotePut', 'id', id)
            const localVarPath = `/sm/order/{id}/rejectQuote`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutOrderUrgentRepresentation} [putOrderUrgentRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdUrgentPut: async (id: string, apiVersion?: string, putOrderUrgentRepresentation?: PutOrderUrgentRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdUrgentPut', 'id', id)
            const localVarPath = `/sm/order/{id}/urgent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putOrderUrgentRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderListOdataGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/order/list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * OrderApi - functional programming interface
        * @export
        */
        export const OrderApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getOrderActionLog(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderActionLogsRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderActionLog(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrderActionLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} customerId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderCustomerIdDraftPost(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDraftRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderCustomerIdDraftPost(customerId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderCustomerIdDraftPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutAcceptOrderRepresentation} [putAcceptOrderRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdAcceptPut(id: string, apiVersion?: string, putAcceptOrderRepresentation?: PutAcceptOrderRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdAcceptPut(id, apiVersion, putAcceptOrderRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdAcceptPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdAcceptQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdAcceptQuotePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdAcceptQuotePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdBmhxmlGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdBmhxmlGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdBmhxmlGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdCancelPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdCancelPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdCancelPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdCompletePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdCompletePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdCompletePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutContactPersonRepresentation} [putContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdContactPut(id: string, apiVersion?: string, putContactPersonRepresentation?: PutContactPersonRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdContactPut(id, apiVersion, putContactPersonRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdContactPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {CopyOrderRepresentation} [copyOrderRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdCopyPost(id: string, apiVersion?: string, copyOrderRepresentation?: CopyOrderRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdCopyPost(id, apiVersion, copyOrderRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdCopyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdExpeditionAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdExpeditionAddressPut(id, apiVersion, orderAddressRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdExpeditionAddressPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutExpeditionConditionRepresentation} [putExpeditionConditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdExpeditionConditionPut(id: string, apiVersion?: string, putExpeditionConditionRepresentation?: PutExpeditionConditionRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdExpeditionConditionPut(id, apiVersion, putExpeditionConditionRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdExpeditionConditionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdExpeditionContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdExpeditionContactPut(id, apiVersion, orderContactPersonRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdExpeditionContactPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutExpeditionDateRepresentation} [putExpeditionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdExpeditionPreferredDatePut(id: string, apiVersion?: string, putExpeditionDateRepresentation?: PutExpeditionDateRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdExpeditionPreferredDatePut(id, apiVersion, putExpeditionDateRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdExpeditionPreferredDatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutOrderExpeditionRepresentation} [putOrderExpeditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdExpeditionPut(id: string, apiVersion?: string, putOrderExpeditionRepresentation?: PutOrderExpeditionRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdExpeditionPut(id, apiVersion, putOrderExpeditionRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdExpeditionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutOrderReferenceRepresentation} [putOrderReferenceRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdExpeditionReferencePut(id: string, apiVersion?: string, putOrderReferenceRepresentation?: PutOrderReferenceRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdExpeditionReferencePut(id, apiVersion, putOrderReferenceRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdExpeditionReferencePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {boolean} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdFastCopyPost(id: string, apiVersion?: string, body?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdFastCopyPost(id, apiVersion, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdFastCopyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdFinancialAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdFinancialAddressPut(id, apiVersion, orderAddressRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdFinancialAddressPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdFinancialContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdFinancialContactPut(id, apiVersion, orderContactPersonRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdFinancialContactPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutPaymentConditionRepresentation} [putPaymentConditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdFinancialPaymentConditionPut(id: string, apiVersion?: string, putPaymentConditionRepresentation?: PutPaymentConditionRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdFinancialPaymentConditionPut(id, apiVersion, putPaymentConditionRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdFinancialPaymentConditionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutPricelistRepresentation} [putPricelistRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdFinancialPricelistPut(id: string, apiVersion?: string, putPricelistRepresentation?: PutPricelistRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdFinancialPricelistPut(id, apiVersion, putPricelistRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdFinancialPricelistPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProjectDiscountRepresentation} [putProjectDiscountRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdFinancialProjectDiscountPut(id: string, apiVersion?: string, putProjectDiscountRepresentation?: PutProjectDiscountRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdFinancialProjectDiscountPut(id, apiVersion, putProjectDiscountRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdFinancialProjectDiscountPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutOrderFinancialRepresentation} [putOrderFinancialRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdFinancialPut(id: string, apiVersion?: string, putOrderFinancialRepresentation?: PutOrderFinancialRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdFinancialPut(id, apiVersion, putOrderFinancialRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdFinancialPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdIssueQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdIssueQuotePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdIssueQuotePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdIssueQuoteWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdIssueQuoteWithRemarkPut(id, apiVersion, putWizardOrderRemarkRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdIssueQuoteWithRemarkPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutOrderDateRepresentation} [putOrderDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdOrderDatePut(id: string, apiVersion?: string, putOrderDateRepresentation?: PutOrderDateRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdOrderDatePut(id, apiVersion, putOrderDateRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdOrderDatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdPlacePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdPlacePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdPlacePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdPlaceWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdPlaceWithRemarkPut(id, apiVersion, putWizardOrderRemarkRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdPlaceWithRemarkPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutPlannedProductionDateRepresentation} [putPlannedProductionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdProductionPlannedProductionDatePut(id: string, apiVersion?: string, putPlannedProductionDateRepresentation?: PutPlannedProductionDateRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdProductionPlannedProductionDatePut(id, apiVersion, putPlannedProductionDateRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdProductionPlannedProductionDatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutQuoteExpiryDateRepresentation} [putQuoteExpiryDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdQuoteExpiryDatePut(id: string, apiVersion?: string, putQuoteExpiryDateRepresentation?: PutQuoteExpiryDateRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdQuoteExpiryDatePut(id, apiVersion, putQuoteExpiryDateRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdQuoteExpiryDatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdRejectQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdRejectQuotePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdRejectQuotePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutOrderUrgentRepresentation} [putOrderUrgentRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdUrgentPut(id: string, apiVersion?: string, putOrderUrgentRepresentation?: PutOrderUrgentRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdUrgentPut(id, apiVersion, putOrderUrgentRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderIdUrgentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderListViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.smOrderListOdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * OrderApi - factory interface
        * @export
        */
        export const OrderApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = OrderApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getOrderActionLog(id: string, apiVersion?: string, options?: any): AxiosPromise<OrderActionLogsRepresentation> {
                return localVarFp.getOrderActionLog(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderCustomerIdDraftPost(customerId: string, apiVersion?: string, options?: any): AxiosPromise<OrderDraftRepresentation> {
                return localVarFp.smOrderCustomerIdDraftPost(customerId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutAcceptOrderRepresentation} [putAcceptOrderRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdAcceptPut(id: string, apiVersion?: string, putAcceptOrderRepresentation?: PutAcceptOrderRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdAcceptPut(id, apiVersion, putAcceptOrderRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdAcceptQuotePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdAcceptQuotePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdBmhxmlGet(id: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.smOrderIdBmhxmlGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdCancelPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdCancelPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdCompletePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdCompletePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutContactPersonRepresentation} [putContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdContactPut(id: string, apiVersion?: string, putContactPersonRepresentation?: PutContactPersonRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdContactPut(id, apiVersion, putContactPersonRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CopyOrderRepresentation} [copyOrderRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdCopyPost(id: string, apiVersion?: string, copyOrderRepresentation?: CopyOrderRepresentation, options?: any): AxiosPromise<string> {
                return localVarFp.smOrderIdCopyPost(id, apiVersion, copyOrderRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdExpeditionAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdExpeditionAddressPut(id, apiVersion, orderAddressRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionConditionRepresentation} [putExpeditionConditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdExpeditionConditionPut(id: string, apiVersion?: string, putExpeditionConditionRepresentation?: PutExpeditionConditionRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdExpeditionConditionPut(id, apiVersion, putExpeditionConditionRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdExpeditionContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdExpeditionContactPut(id, apiVersion, orderContactPersonRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionDateRepresentation} [putExpeditionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdExpeditionPreferredDatePut(id: string, apiVersion?: string, putExpeditionDateRepresentation?: PutExpeditionDateRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdExpeditionPreferredDatePut(id, apiVersion, putExpeditionDateRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderExpeditionRepresentation} [putOrderExpeditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdExpeditionPut(id: string, apiVersion?: string, putOrderExpeditionRepresentation?: PutOrderExpeditionRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdExpeditionPut(id, apiVersion, putOrderExpeditionRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderReferenceRepresentation} [putOrderReferenceRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdExpeditionReferencePut(id: string, apiVersion?: string, putOrderReferenceRepresentation?: PutOrderReferenceRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdExpeditionReferencePut(id, apiVersion, putOrderReferenceRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {boolean} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdFastCopyPost(id: string, apiVersion?: string, body?: boolean, options?: any): AxiosPromise<string> {
                return localVarFp.smOrderIdFastCopyPost(id, apiVersion, body, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdFinancialAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdFinancialAddressPut(id, apiVersion, orderAddressRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdFinancialContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdFinancialContactPut(id, apiVersion, orderContactPersonRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPaymentConditionRepresentation} [putPaymentConditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdFinancialPaymentConditionPut(id: string, apiVersion?: string, putPaymentConditionRepresentation?: PutPaymentConditionRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdFinancialPaymentConditionPut(id, apiVersion, putPaymentConditionRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPricelistRepresentation} [putPricelistRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdFinancialPricelistPut(id: string, apiVersion?: string, putPricelistRepresentation?: PutPricelistRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdFinancialPricelistPut(id, apiVersion, putPricelistRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProjectDiscountRepresentation} [putProjectDiscountRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdFinancialProjectDiscountPut(id: string, apiVersion?: string, putProjectDiscountRepresentation?: PutProjectDiscountRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdFinancialProjectDiscountPut(id, apiVersion, putProjectDiscountRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderFinancialRepresentation} [putOrderFinancialRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdFinancialPut(id: string, apiVersion?: string, putOrderFinancialRepresentation?: PutOrderFinancialRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdFinancialPut(id, apiVersion, putOrderFinancialRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdIssueQuotePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdIssueQuotePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdIssueQuoteWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdIssueQuoteWithRemarkPut(id, apiVersion, putWizardOrderRemarkRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderDateRepresentation} [putOrderDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdOrderDatePut(id: string, apiVersion?: string, putOrderDateRepresentation?: PutOrderDateRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdOrderDatePut(id, apiVersion, putOrderDateRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdPlacePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdPlacePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdPlaceWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdPlaceWithRemarkPut(id, apiVersion, putWizardOrderRemarkRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPlannedProductionDateRepresentation} [putPlannedProductionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdProductionPlannedProductionDatePut(id: string, apiVersion?: string, putPlannedProductionDateRepresentation?: PutPlannedProductionDateRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdProductionPlannedProductionDatePut(id, apiVersion, putPlannedProductionDateRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutQuoteExpiryDateRepresentation} [putQuoteExpiryDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdQuoteExpiryDatePut(id: string, apiVersion?: string, putQuoteExpiryDateRepresentation?: PutQuoteExpiryDateRepresentation, options?: any): AxiosPromise<OrderRepresentation> {
                return localVarFp.smOrderIdQuoteExpiryDatePut(id, apiVersion, putQuoteExpiryDateRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdRejectQuotePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdRejectQuotePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderUrgentRepresentation} [putOrderUrgentRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdUrgentPut(id: string, apiVersion?: string, putOrderUrgentRepresentation?: PutOrderUrgentRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.smOrderIdUrgentPut(id, apiVersion, putOrderUrgentRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<OrderListViewArrayODataValue> {
                return localVarFp.smOrderListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * OrderApi - interface
            * @export
            * @interface OrderApi
            */
            export interface OrderApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            getOrderActionLog(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderActionLogsRepresentation>;

                /**
                * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderCustomerIdDraftPost(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderDraftRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutAcceptOrderRepresentation} [putAcceptOrderRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdAcceptPut(id: string, apiVersion?: string, putAcceptOrderRepresentation?: PutAcceptOrderRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdAcceptQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdBmhxmlGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdCancelPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdCompletePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutContactPersonRepresentation} [putContactPersonRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdContactPut(id: string, apiVersion?: string, putContactPersonRepresentation?: PutContactPersonRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CopyOrderRepresentation} [copyOrderRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdCopyPost(id: string, apiVersion?: string, copyOrderRepresentation?: CopyOrderRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdExpeditionAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionConditionRepresentation} [putExpeditionConditionRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdExpeditionConditionPut(id: string, apiVersion?: string, putExpeditionConditionRepresentation?: PutExpeditionConditionRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdExpeditionContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionDateRepresentation} [putExpeditionDateRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdExpeditionPreferredDatePut(id: string, apiVersion?: string, putExpeditionDateRepresentation?: PutExpeditionDateRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderExpeditionRepresentation} [putOrderExpeditionRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdExpeditionPut(id: string, apiVersion?: string, putOrderExpeditionRepresentation?: PutOrderExpeditionRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderReferenceRepresentation} [putOrderReferenceRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdExpeditionReferencePut(id: string, apiVersion?: string, putOrderReferenceRepresentation?: PutOrderReferenceRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {boolean} [body] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdFastCopyPost(id: string, apiVersion?: string, body?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdFinancialAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdFinancialContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPaymentConditionRepresentation} [putPaymentConditionRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdFinancialPaymentConditionPut(id: string, apiVersion?: string, putPaymentConditionRepresentation?: PutPaymentConditionRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPricelistRepresentation} [putPricelistRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdFinancialPricelistPut(id: string, apiVersion?: string, putPricelistRepresentation?: PutPricelistRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProjectDiscountRepresentation} [putProjectDiscountRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdFinancialProjectDiscountPut(id: string, apiVersion?: string, putProjectDiscountRepresentation?: PutProjectDiscountRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderFinancialRepresentation} [putOrderFinancialRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdFinancialPut(id: string, apiVersion?: string, putOrderFinancialRepresentation?: PutOrderFinancialRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdIssueQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdIssueQuoteWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderDateRepresentation} [putOrderDateRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdOrderDatePut(id: string, apiVersion?: string, putOrderDateRepresentation?: PutOrderDateRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdPlacePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdPlaceWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPlannedProductionDateRepresentation} [putPlannedProductionDateRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdProductionPlannedProductionDatePut(id: string, apiVersion?: string, putPlannedProductionDateRepresentation?: PutPlannedProductionDateRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutQuoteExpiryDateRepresentation} [putQuoteExpiryDateRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdQuoteExpiryDatePut(id: string, apiVersion?: string, putQuoteExpiryDateRepresentation?: PutQuoteExpiryDateRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdRejectQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderUrgentRepresentation} [putOrderUrgentRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderIdUrgentPut(id: string, apiVersion?: string, putOrderUrgentRepresentation?: PutOrderUrgentRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderApiInterface
                */
            smOrderListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderListViewArrayODataValue>;

            }

        /**
        * OrderApi - object-oriented interface
        * @export
        * @class OrderApi
        * @extends {BaseAPI}
        */
            export class OrderApi extends BaseAPI implements OrderApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public getOrderActionLog(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).getOrderActionLog(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} customerId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderCustomerIdDraftPost(customerId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderCustomerIdDraftPost(customerId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutAcceptOrderRepresentation} [putAcceptOrderRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdAcceptPut(id: string, apiVersion?: string, putAcceptOrderRepresentation?: PutAcceptOrderRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdAcceptPut(id, apiVersion, putAcceptOrderRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdAcceptQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdAcceptQuotePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdBmhxmlGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdBmhxmlGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdCancelPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdCancelPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdCompletePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdCompletePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutContactPersonRepresentation} [putContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdContactPut(id: string, apiVersion?: string, putContactPersonRepresentation?: PutContactPersonRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdContactPut(id, apiVersion, putContactPersonRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CopyOrderRepresentation} [copyOrderRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdCopyPost(id: string, apiVersion?: string, copyOrderRepresentation?: CopyOrderRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdCopyPost(id, apiVersion, copyOrderRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdExpeditionAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdExpeditionAddressPut(id, apiVersion, orderAddressRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionConditionRepresentation} [putExpeditionConditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdExpeditionConditionPut(id: string, apiVersion?: string, putExpeditionConditionRepresentation?: PutExpeditionConditionRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdExpeditionConditionPut(id, apiVersion, putExpeditionConditionRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdExpeditionContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdExpeditionContactPut(id, apiVersion, orderContactPersonRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutExpeditionDateRepresentation} [putExpeditionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdExpeditionPreferredDatePut(id: string, apiVersion?: string, putExpeditionDateRepresentation?: PutExpeditionDateRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdExpeditionPreferredDatePut(id, apiVersion, putExpeditionDateRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderExpeditionRepresentation} [putOrderExpeditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdExpeditionPut(id: string, apiVersion?: string, putOrderExpeditionRepresentation?: PutOrderExpeditionRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdExpeditionPut(id, apiVersion, putOrderExpeditionRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderReferenceRepresentation} [putOrderReferenceRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdExpeditionReferencePut(id: string, apiVersion?: string, putOrderReferenceRepresentation?: PutOrderReferenceRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdExpeditionReferencePut(id, apiVersion, putOrderReferenceRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {boolean} [body] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdFastCopyPost(id: string, apiVersion?: string, body?: boolean, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdFastCopyPost(id, apiVersion, body, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderAddressRepresentation} [orderAddressRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdFinancialAddressPut(id: string, apiVersion?: string, orderAddressRepresentation?: OrderAddressRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdFinancialAddressPut(id, apiVersion, orderAddressRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {OrderContactPersonRepresentation} [orderContactPersonRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdFinancialContactPut(id: string, apiVersion?: string, orderContactPersonRepresentation?: OrderContactPersonRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdFinancialContactPut(id, apiVersion, orderContactPersonRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPaymentConditionRepresentation} [putPaymentConditionRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdFinancialPaymentConditionPut(id: string, apiVersion?: string, putPaymentConditionRepresentation?: PutPaymentConditionRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdFinancialPaymentConditionPut(id, apiVersion, putPaymentConditionRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPricelistRepresentation} [putPricelistRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdFinancialPricelistPut(id: string, apiVersion?: string, putPricelistRepresentation?: PutPricelistRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdFinancialPricelistPut(id, apiVersion, putPricelistRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProjectDiscountRepresentation} [putProjectDiscountRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdFinancialProjectDiscountPut(id: string, apiVersion?: string, putProjectDiscountRepresentation?: PutProjectDiscountRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdFinancialProjectDiscountPut(id, apiVersion, putProjectDiscountRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderFinancialRepresentation} [putOrderFinancialRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdFinancialPut(id: string, apiVersion?: string, putOrderFinancialRepresentation?: PutOrderFinancialRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdFinancialPut(id, apiVersion, putOrderFinancialRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdIssueQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdIssueQuotePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdIssueQuoteWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdIssueQuoteWithRemarkPut(id, apiVersion, putWizardOrderRemarkRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderDateRepresentation} [putOrderDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdOrderDatePut(id: string, apiVersion?: string, putOrderDateRepresentation?: PutOrderDateRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdOrderDatePut(id, apiVersion, putOrderDateRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdPlacePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdPlacePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutWizardOrderRemarkRepresentation} [putWizardOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdPlaceWithRemarkPut(id: string, apiVersion?: string, putWizardOrderRemarkRepresentation?: PutWizardOrderRemarkRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdPlaceWithRemarkPut(id, apiVersion, putWizardOrderRemarkRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutPlannedProductionDateRepresentation} [putPlannedProductionDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdProductionPlannedProductionDatePut(id: string, apiVersion?: string, putPlannedProductionDateRepresentation?: PutPlannedProductionDateRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdProductionPlannedProductionDatePut(id, apiVersion, putPlannedProductionDateRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutQuoteExpiryDateRepresentation} [putQuoteExpiryDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdQuoteExpiryDatePut(id: string, apiVersion?: string, putQuoteExpiryDateRepresentation?: PutQuoteExpiryDateRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdQuoteExpiryDatePut(id, apiVersion, putQuoteExpiryDateRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdRejectQuotePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdRejectQuotePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutOrderUrgentRepresentation} [putOrderUrgentRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderIdUrgentPut(id: string, apiVersion?: string, putOrderUrgentRepresentation?: PutOrderUrgentRepresentation, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderIdUrgentPut(id, apiVersion, putOrderUrgentRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderApi
            */
                public smOrderListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderApiFp(this.configuration).smOrderListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * OrderLineApi - axios parameter creator
    * @export
    */
    export const OrderLineApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {CopySillOrderLineModel} [copySillOrderLineModel] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderlineIdCopyPut: async (id: string, apiVersion?: string, copySillOrderLineModel?: CopySillOrderLineModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderlineIdCopyPut', 'id', id)
            const localVarPath = `/sm/orderline/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(copySillOrderLineModel, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderlineIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderlineIdDelete', 'id', id)
            const localVarPath = `/sm/orderline/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {SmOrderlineIdPostRequest} [smOrderlineIdPostRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderlineIdPost: async (id: string, apiVersion?: string, smOrderlineIdPostRequest?: SmOrderlineIdPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderlineIdPost', 'id', id)
            const localVarPath = `/sm/orderline/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(smOrderlineIdPostRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {SmOrderlineIdPutRequest} [smOrderlineIdPutRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderlineIdPut: async (id: string, apiVersion?: string, smOrderlineIdPutRequest?: SmOrderlineIdPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderlineIdPut', 'id', id)
            const localVarPath = `/sm/orderline/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(smOrderlineIdPutRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {SbomModel} [sbomModel] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderlineSbomIdPut: async (id: string, apiVersion?: string, sbomModel?: SbomModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderlineSbomIdPut', 'id', id)
            const localVarPath = `/sm/orderline/sbom/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(sbomModel, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * OrderLineApi - functional programming interface
        * @export
        */
        export const OrderLineApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = OrderLineApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {CopySillOrderLineModel} [copySillOrderLineModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderlineIdCopyPut(id: string, apiVersion?: string, copySillOrderLineModel?: CopySillOrderLineModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderLineRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderlineIdCopyPut(id, apiVersion, copySillOrderLineModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderLineApi.smOrderlineIdCopyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderlineIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderLineRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderlineIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderLineApi.smOrderlineIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {SmOrderlineIdPostRequest} [smOrderlineIdPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderlineIdPost(id: string, apiVersion?: string, smOrderlineIdPostRequest?: SmOrderlineIdPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderLineRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderlineIdPost(id, apiVersion, smOrderlineIdPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderLineApi.smOrderlineIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {SmOrderlineIdPutRequest} [smOrderlineIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderlineIdPut(id: string, apiVersion?: string, smOrderlineIdPutRequest?: SmOrderlineIdPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderLineRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderlineIdPut(id, apiVersion, smOrderlineIdPutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderLineApi.smOrderlineIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {SbomModel} [sbomModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderlineSbomIdPut(id: string, apiVersion?: string, sbomModel?: SbomModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderLineRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderlineSbomIdPut(id, apiVersion, sbomModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderLineApi.smOrderlineSbomIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * OrderLineApi - factory interface
        * @export
        */
        export const OrderLineApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = OrderLineApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CopySillOrderLineModel} [copySillOrderLineModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderlineIdCopyPut(id: string, apiVersion?: string, copySillOrderLineModel?: CopySillOrderLineModel, options?: any): AxiosPromise<OrderLineRepresentation> {
                return localVarFp.smOrderlineIdCopyPut(id, apiVersion, copySillOrderLineModel, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderlineIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<OrderLineRepresentation> {
                return localVarFp.smOrderlineIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmOrderlineIdPostRequest} [smOrderlineIdPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderlineIdPost(id: string, apiVersion?: string, smOrderlineIdPostRequest?: SmOrderlineIdPostRequest, options?: any): AxiosPromise<OrderLineRepresentation> {
                return localVarFp.smOrderlineIdPost(id, apiVersion, smOrderlineIdPostRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmOrderlineIdPutRequest} [smOrderlineIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderlineIdPut(id: string, apiVersion?: string, smOrderlineIdPutRequest?: SmOrderlineIdPutRequest, options?: any): AxiosPromise<OrderLineRepresentation> {
                return localVarFp.smOrderlineIdPut(id, apiVersion, smOrderlineIdPutRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SbomModel} [sbomModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderlineSbomIdPut(id: string, apiVersion?: string, sbomModel?: SbomModel, options?: any): AxiosPromise<OrderLineRepresentation> {
                return localVarFp.smOrderlineSbomIdPut(id, apiVersion, sbomModel, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * OrderLineApi - interface
            * @export
            * @interface OrderLineApi
            */
            export interface OrderLineApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CopySillOrderLineModel} [copySillOrderLineModel] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderLineApiInterface
                */
            smOrderlineIdCopyPut(id: string, apiVersion?: string, copySillOrderLineModel?: CopySillOrderLineModel, options?: RawAxiosRequestConfig): AxiosPromise<OrderLineRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderLineApiInterface
                */
            smOrderlineIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderLineRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmOrderlineIdPostRequest} [smOrderlineIdPostRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderLineApiInterface
                */
            smOrderlineIdPost(id: string, apiVersion?: string, smOrderlineIdPostRequest?: SmOrderlineIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderLineRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmOrderlineIdPutRequest} [smOrderlineIdPutRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderLineApiInterface
                */
            smOrderlineIdPut(id: string, apiVersion?: string, smOrderlineIdPutRequest?: SmOrderlineIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderLineRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SbomModel} [sbomModel] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderLineApiInterface
                */
            smOrderlineSbomIdPut(id: string, apiVersion?: string, sbomModel?: SbomModel, options?: RawAxiosRequestConfig): AxiosPromise<OrderLineRepresentation>;

            }

        /**
        * OrderLineApi - object-oriented interface
        * @export
        * @class OrderLineApi
        * @extends {BaseAPI}
        */
            export class OrderLineApi extends BaseAPI implements OrderLineApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CopySillOrderLineModel} [copySillOrderLineModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderLineApi
            */
                public smOrderlineIdCopyPut(id: string, apiVersion?: string, copySillOrderLineModel?: CopySillOrderLineModel, options?: RawAxiosRequestConfig) {
                return OrderLineApiFp(this.configuration).smOrderlineIdCopyPut(id, apiVersion, copySillOrderLineModel, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderLineApi
            */
                public smOrderlineIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderLineApiFp(this.configuration).smOrderlineIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmOrderlineIdPostRequest} [smOrderlineIdPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderLineApi
            */
                public smOrderlineIdPost(id: string, apiVersion?: string, smOrderlineIdPostRequest?: SmOrderlineIdPostRequest, options?: RawAxiosRequestConfig) {
                return OrderLineApiFp(this.configuration).smOrderlineIdPost(id, apiVersion, smOrderlineIdPostRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmOrderlineIdPutRequest} [smOrderlineIdPutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderLineApi
            */
                public smOrderlineIdPut(id: string, apiVersion?: string, smOrderlineIdPutRequest?: SmOrderlineIdPutRequest, options?: RawAxiosRequestConfig) {
                return OrderLineApiFp(this.configuration).smOrderlineIdPut(id, apiVersion, smOrderlineIdPutRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SbomModel} [sbomModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderLineApi
            */
                public smOrderlineSbomIdPut(id: string, apiVersion?: string, sbomModel?: SbomModel, options?: RawAxiosRequestConfig) {
                return OrderLineApiFp(this.configuration).smOrderlineSbomIdPut(id, apiVersion, sbomModel, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * OrderRemarkApi - axios parameter creator
    * @export
    */
    export const OrderRemarkApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PostOrderRemarkRepresentation} [postOrderRemarkRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdRemarkPost: async (id: string, apiVersion?: string, postOrderRemarkRepresentation?: PostOrderRemarkRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdRemarkPost', 'id', id)
            const localVarPath = `/sm/order/{id}/remark`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(postOrderRemarkRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderRemarkIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderRemarkIdDelete', 'id', id)
            const localVarPath = `/sm/order/remark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * OrderRemarkApi - functional programming interface
        * @export
        */
        export const OrderRemarkApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = OrderRemarkApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PostOrderRemarkRepresentation} [postOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdRemarkPost(id: string, apiVersion?: string, postOrderRemarkRepresentation?: PostOrderRemarkRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRemarkRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdRemarkPost(id, apiVersion, postOrderRemarkRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderRemarkApi.smOrderIdRemarkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderRemarkIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRemarkRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderRemarkIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderRemarkApi.smOrderRemarkIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * OrderRemarkApi - factory interface
        * @export
        */
        export const OrderRemarkApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = OrderRemarkApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostOrderRemarkRepresentation} [postOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdRemarkPost(id: string, apiVersion?: string, postOrderRemarkRepresentation?: PostOrderRemarkRepresentation, options?: any): AxiosPromise<OrderRemarkRepresentation> {
                return localVarFp.smOrderIdRemarkPost(id, apiVersion, postOrderRemarkRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderRemarkIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<OrderRemarkRepresentation> {
                return localVarFp.smOrderRemarkIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * OrderRemarkApi - interface
            * @export
            * @interface OrderRemarkApi
            */
            export interface OrderRemarkApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostOrderRemarkRepresentation} [postOrderRemarkRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderRemarkApiInterface
                */
            smOrderIdRemarkPost(id: string, apiVersion?: string, postOrderRemarkRepresentation?: PostOrderRemarkRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<OrderRemarkRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof OrderRemarkApiInterface
                */
            smOrderRemarkIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderRemarkRepresentation>;

            }

        /**
        * OrderRemarkApi - object-oriented interface
        * @export
        * @class OrderRemarkApi
        * @extends {BaseAPI}
        */
            export class OrderRemarkApi extends BaseAPI implements OrderRemarkApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostOrderRemarkRepresentation} [postOrderRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderRemarkApi
            */
                public smOrderIdRemarkPost(id: string, apiVersion?: string, postOrderRemarkRepresentation?: PostOrderRemarkRepresentation, options?: RawAxiosRequestConfig) {
                return OrderRemarkApiFp(this.configuration).smOrderIdRemarkPost(id, apiVersion, postOrderRemarkRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof OrderRemarkApi
            */
                public smOrderRemarkIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return OrderRemarkApiFp(this.configuration).smOrderRemarkIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * PriceApi - axios parameter creator
    * @export
    */
    export const PriceApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {Array<string>} [catalogItemIds] 
            * @param {Array<string>} [priceListIds] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPricesChangesGet: async (catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/prices/changes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (catalogItemIds) {
                            localVarQueryParameter['catalogItemIds'] = catalogItemIds;
                    }

                    if (priceListIds) {
                            localVarQueryParameter['priceListIds'] = priceListIds;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPricesChangesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smPricesChangesIdGet', 'id', id)
            const localVarPath = `/sm/prices/changes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {Array<string>} [catalogItemIds] 
            * @param {Array<string>} [priceListIds] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPricesGet: async (catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (catalogItemIds) {
                            localVarQueryParameter['catalogItemIds'] = catalogItemIds;
                    }

                    if (priceListIds) {
                            localVarQueryParameter['priceListIds'] = priceListIds;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {SmPricesPostRequest} [smPricesPostRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPricesPost: async (apiVersion?: string, smPricesPostRequest?: SmPricesPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(smPricesPostRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * PriceApi - functional programming interface
        * @export
        */
        export const PriceApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = PriceApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {Array<string>} [catalogItemIds] 
                * @param {Array<string>} [priceListIds] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPricesChangesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPricesChangesGet(catalogItemIds, priceListIds, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceApi.smPricesChangesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPricesChangesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceChange>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPricesChangesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceApi.smPricesChangesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {Array<string>} [catalogItemIds] 
                * @param {Array<string>} [priceListIds] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPricesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemPrice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPricesGet(catalogItemIds, priceListIds, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceApi.smPricesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {SmPricesPostRequest} [smPricesPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPricesPost(apiVersion?: string, smPricesPostRequest?: SmPricesPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceChange>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPricesPost(apiVersion, smPricesPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceApi.smPricesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * PriceApi - factory interface
        * @export
        */
        export const PriceApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = PriceApiFp(configuration)
        return {
            /**
            * 
                    * @param {Array<string>} [catalogItemIds] 
                    * @param {Array<string>} [priceListIds] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPricesChangesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: any): AxiosPromise<Array<PriceChange>> {
                return localVarFp.smPricesChangesGet(catalogItemIds, priceListIds, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPricesChangesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<PriceChange> {
                return localVarFp.smPricesChangesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {Array<string>} [catalogItemIds] 
                    * @param {Array<string>} [priceListIds] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPricesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: any): AxiosPromise<Array<ItemPrice>> {
                return localVarFp.smPricesGet(catalogItemIds, priceListIds, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmPricesPostRequest} [smPricesPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPricesPost(apiVersion?: string, smPricesPostRequest?: SmPricesPostRequest, options?: any): AxiosPromise<PriceChange> {
                return localVarFp.smPricesPost(apiVersion, smPricesPostRequest, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * PriceApi - interface
            * @export
            * @interface PriceApi
            */
            export interface PriceApiInterface {
                /**
                * 
                    * @param {Array<string>} [catalogItemIds] 
                    * @param {Array<string>} [priceListIds] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceApiInterface
                */
            smPricesChangesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PriceChange>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceApiInterface
                */
            smPricesChangesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<PriceChange>;

                /**
                * 
                    * @param {Array<string>} [catalogItemIds] 
                    * @param {Array<string>} [priceListIds] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceApiInterface
                */
            smPricesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ItemPrice>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmPricesPostRequest} [smPricesPostRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceApiInterface
                */
            smPricesPost(apiVersion?: string, smPricesPostRequest?: SmPricesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PriceChange>;

            }

        /**
        * PriceApi - object-oriented interface
        * @export
        * @class PriceApi
        * @extends {BaseAPI}
        */
            export class PriceApi extends BaseAPI implements PriceApiInterface {
            /**
            * 
                    * @param {Array<string>} [catalogItemIds] 
                    * @param {Array<string>} [priceListIds] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceApi
            */
                public smPricesChangesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PriceApiFp(this.configuration).smPricesChangesGet(catalogItemIds, priceListIds, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceApi
            */
                public smPricesChangesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PriceApiFp(this.configuration).smPricesChangesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {Array<string>} [catalogItemIds] 
                    * @param {Array<string>} [priceListIds] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceApi
            */
                public smPricesGet(catalogItemIds?: Array<string>, priceListIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PriceApiFp(this.configuration).smPricesGet(catalogItemIds, priceListIds, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SmPricesPostRequest} [smPricesPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceApi
            */
                public smPricesPost(apiVersion?: string, smPricesPostRequest?: SmPricesPostRequest, options?: RawAxiosRequestConfig) {
                return PriceApiFp(this.configuration).smPricesPost(apiVersion, smPricesPostRequest, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * PriceListApi - axios parameter creator
    * @export
    */
    export const PriceListApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPriceListsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/price-lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPriceListsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smPriceListsIdDelete', 'id', id)
            const localVarPath = `/sm/price-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPriceListsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smPriceListsIdGet', 'id', id)
            const localVarPath = `/sm/price-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdatePriceListRequest} [updatePriceListRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPriceListsIdPut: async (id: string, apiVersion?: string, updatePriceListRequest?: UpdatePriceListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smPriceListsIdPut', 'id', id)
            const localVarPath = `/sm/price-lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updatePriceListRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreatePriceListCommand} [createPriceListCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smPriceListsPost: async (apiVersion?: string, createPriceListCommand?: CreatePriceListCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/price-lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createPriceListCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * PriceListApi - functional programming interface
        * @export
        */
        export const PriceListApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = PriceListApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPriceListsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPriceListsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceListApi.smPriceListsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPriceListsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPriceListsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceListApi.smPriceListsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPriceListsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPriceListsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceListApi.smPriceListsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdatePriceListRequest} [updatePriceListRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPriceListsIdPut(id: string, apiVersion?: string, updatePriceListRequest?: UpdatePriceListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPriceListsIdPut(id, apiVersion, updatePriceListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceListApi.smPriceListsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreatePriceListCommand} [createPriceListCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smPriceListsPost(apiVersion?: string, createPriceListCommand?: CreatePriceListCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smPriceListsPost(apiVersion, createPriceListCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceListApi.smPriceListsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * PriceListApi - factory interface
        * @export
        */
        export const PriceListApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = PriceListApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPriceListsGet(apiVersion?: string, options?: any): AxiosPromise<Array<PriceList>> {
                return localVarFp.smPriceListsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPriceListsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.smPriceListsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPriceListsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<PriceList> {
                return localVarFp.smPriceListsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdatePriceListRequest} [updatePriceListRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPriceListsIdPut(id: string, apiVersion?: string, updatePriceListRequest?: UpdatePriceListRequest, options?: any): AxiosPromise<PriceList> {
                return localVarFp.smPriceListsIdPut(id, apiVersion, updatePriceListRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreatePriceListCommand} [createPriceListCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smPriceListsPost(apiVersion?: string, createPriceListCommand?: CreatePriceListCommand, options?: any): AxiosPromise<PriceList> {
                return localVarFp.smPriceListsPost(apiVersion, createPriceListCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * PriceListApi - interface
            * @export
            * @interface PriceListApi
            */
            export interface PriceListApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceListApiInterface
                */
            smPriceListsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PriceList>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceListApiInterface
                */
            smPriceListsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceListApiInterface
                */
            smPriceListsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<PriceList>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdatePriceListRequest} [updatePriceListRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceListApiInterface
                */
            smPriceListsIdPut(id: string, apiVersion?: string, updatePriceListRequest?: UpdatePriceListRequest, options?: RawAxiosRequestConfig): AxiosPromise<PriceList>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreatePriceListCommand} [createPriceListCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PriceListApiInterface
                */
            smPriceListsPost(apiVersion?: string, createPriceListCommand?: CreatePriceListCommand, options?: RawAxiosRequestConfig): AxiosPromise<PriceList>;

            }

        /**
        * PriceListApi - object-oriented interface
        * @export
        * @class PriceListApi
        * @extends {BaseAPI}
        */
            export class PriceListApi extends BaseAPI implements PriceListApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceListApi
            */
                public smPriceListsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PriceListApiFp(this.configuration).smPriceListsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceListApi
            */
                public smPriceListsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PriceListApiFp(this.configuration).smPriceListsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceListApi
            */
                public smPriceListsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PriceListApiFp(this.configuration).smPriceListsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdatePriceListRequest} [updatePriceListRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceListApi
            */
                public smPriceListsIdPut(id: string, apiVersion?: string, updatePriceListRequest?: UpdatePriceListRequest, options?: RawAxiosRequestConfig) {
                return PriceListApiFp(this.configuration).smPriceListsIdPut(id, apiVersion, updatePriceListRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreatePriceListCommand} [createPriceListCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PriceListApi
            */
                public smPriceListsPost(apiVersion?: string, createPriceListCommand?: CreatePriceListCommand, options?: RawAxiosRequestConfig) {
                return PriceListApiFp(this.configuration).smPriceListsPost(apiVersion, createPriceListCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * PrintApi - axios parameter creator
    * @export
    */
    export const PrintApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [id] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionPrintPdfPackingslipGet: async (id?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/print/pdf/packingslip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (id !== undefined) {
                                    localVarQueryParameter['id'] = id;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PostPackingSlipsPdf} [postPackingSlipsPdf] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionPrintPdfPackingslipsPost: async (apiVersion?: string, postPackingSlipsPdf?: PostPackingSlipsPdf, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/expedition/print/pdf/packingslips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(postPackingSlipsPdf, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pdfWorkorderPut: async (apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pdf/workorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} productionItemCode 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionPrintCodeProductionItemCodePdfWorkorderGet: async (productionItemCode: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'productionItemCode' is not null or undefined
                    assertParamExists('pmProductionPrintCodeProductionItemCodePdfWorkorderGet', 'productionItemCode', productionItemCode)
            const localVarPath = `/pm/production/print/code/{productionItemCode}/pdf/workorder`
                .replace(`{${"productionItemCode"}}`, encodeURIComponent(String(productionItemCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} productionBatchId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionPrintProductionBatchIdPdfWorkordersGet: async (productionBatchId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'productionBatchId' is not null or undefined
                    assertParamExists('pmProductionPrintProductionBatchIdPdfWorkordersGet', 'productionBatchId', productionBatchId)
            const localVarPath = `/pm/production/print/{productionBatchId}/pdf/workorders`
                .replace(`{${"productionBatchId"}}`, encodeURIComponent(String(productionBatchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} productionItemId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionPrintProductionItemIdPdfWorkorderGet: async (productionItemId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'productionItemId' is not null or undefined
                    assertParamExists('pmProductionPrintProductionItemIdPdfWorkorderGet', 'productionItemId', productionItemId)
            const localVarPath = `/pm/production/print/{productionItemId}/pdf/workorder`
                .replace(`{${"productionItemId"}}`, encodeURIComponent(String(productionItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdPdfConfirmationGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdPdfConfirmationGet', 'id', id)
            const localVarPath = `/sm/order/{id}/pdf/confirmation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        smOrderIdPdfQuoteGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('smOrderIdPdfQuoteGet', 'id', id)
            const localVarPath = `/sm/order/{id}/pdf/quote`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * PrintApi - functional programming interface
        * @export
        */
        export const PrintApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = PrintApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [id] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionPrintPdfPackingslipGet(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionPrintPdfPackingslipGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.emExpeditionPrintPdfPackingslipGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PostPackingSlipsPdf} [postPackingSlipsPdf] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionPrintPdfPackingslipsPost(apiVersion?: string, postPackingSlipsPdf?: PostPackingSlipsPdf, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionPrintPdfPackingslipsPost(apiVersion, postPackingSlipsPdf, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.emExpeditionPrintPdfPackingslipsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pdfWorkorderPut(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfWorkorderPut(apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.pdfWorkorderPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} productionItemCode 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionPrintCodeProductionItemCodePdfWorkorderGet(productionItemCode: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionPrintCodeProductionItemCodePdfWorkorderGet(productionItemCode, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.pmProductionPrintCodeProductionItemCodePdfWorkorderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} productionBatchId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionPrintProductionBatchIdPdfWorkordersGet(productionBatchId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionPrintProductionBatchIdPdfWorkordersGet(productionBatchId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.pmProductionPrintProductionBatchIdPdfWorkordersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} productionItemId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionPrintProductionItemIdPdfWorkorderGet(productionItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionPrintProductionItemIdPdfWorkorderGet(productionItemId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.pmProductionPrintProductionItemIdPdfWorkorderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdPdfConfirmationGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdPdfConfirmationGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.smOrderIdPdfConfirmationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async smOrderIdPdfQuoteGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smOrderIdPdfQuoteGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintApi.smOrderIdPdfQuoteGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * PrintApi - factory interface
        * @export
        */
        export const PrintApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = PrintApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionPrintPdfPackingslipGet(id?: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.emExpeditionPrintPdfPackingslipGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostPackingSlipsPdf} [postPackingSlipsPdf] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionPrintPdfPackingslipsPost(apiVersion?: string, postPackingSlipsPdf?: PostPackingSlipsPdf, options?: any): AxiosPromise<File> {
                return localVarFp.emExpeditionPrintPdfPackingslipsPost(apiVersion, postPackingSlipsPdf, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pdfWorkorderPut(apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<File> {
                return localVarFp.pdfWorkorderPut(apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} productionItemCode 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionPrintCodeProductionItemCodePdfWorkorderGet(productionItemCode: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.pmProductionPrintCodeProductionItemCodePdfWorkorderGet(productionItemCode, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} productionBatchId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionPrintProductionBatchIdPdfWorkordersGet(productionBatchId: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.pmProductionPrintProductionBatchIdPdfWorkordersGet(productionBatchId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} productionItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionPrintProductionItemIdPdfWorkorderGet(productionItemId: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.pmProductionPrintProductionItemIdPdfWorkorderGet(productionItemId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdPdfConfirmationGet(id: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.smOrderIdPdfConfirmationGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            smOrderIdPdfQuoteGet(id: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.smOrderIdPdfQuoteGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * PrintApi - interface
            * @export
            * @interface PrintApi
            */
            export interface PrintApiInterface {
                /**
                * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            emExpeditionPrintPdfPackingslipGet(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostPackingSlipsPdf} [postPackingSlipsPdf] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            emExpeditionPrintPdfPackingslipsPost(apiVersion?: string, postPackingSlipsPdf?: PostPackingSlipsPdf, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            pdfWorkorderPut(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} productionItemCode 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            pmProductionPrintCodeProductionItemCodePdfWorkorderGet(productionItemCode: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} productionBatchId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            pmProductionPrintProductionBatchIdPdfWorkordersGet(productionBatchId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} productionItemId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            pmProductionPrintProductionItemIdPdfWorkorderGet(productionItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            smOrderIdPdfConfirmationGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof PrintApiInterface
                */
            smOrderIdPdfQuoteGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

            }

        /**
        * PrintApi - object-oriented interface
        * @export
        * @class PrintApi
        * @extends {BaseAPI}
        */
            export class PrintApi extends BaseAPI implements PrintApiInterface {
            /**
            * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public emExpeditionPrintPdfPackingslipGet(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).emExpeditionPrintPdfPackingslipGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostPackingSlipsPdf} [postPackingSlipsPdf] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public emExpeditionPrintPdfPackingslipsPost(apiVersion?: string, postPackingSlipsPdf?: PostPackingSlipsPdf, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).emExpeditionPrintPdfPackingslipsPost(apiVersion, postPackingSlipsPdf, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public pdfWorkorderPut(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).pdfWorkorderPut(apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} productionItemCode 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public pmProductionPrintCodeProductionItemCodePdfWorkorderGet(productionItemCode: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).pmProductionPrintCodeProductionItemCodePdfWorkorderGet(productionItemCode, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} productionBatchId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public pmProductionPrintProductionBatchIdPdfWorkordersGet(productionBatchId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).pmProductionPrintProductionBatchIdPdfWorkordersGet(productionBatchId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} productionItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public pmProductionPrintProductionItemIdPdfWorkorderGet(productionItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).pmProductionPrintProductionItemIdPdfWorkorderGet(productionItemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public smOrderIdPdfConfirmationGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).smOrderIdPdfConfirmationGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof PrintApi
            */
                public smOrderIdPdfQuoteGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return PrintApiFp(this.configuration).smOrderIdPdfQuoteGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProductionBatchApi - axios parameter creator
    * @export
    */
    export const ProductionBatchApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        ekiExport: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('ekiExport', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/eki`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PostProductionBatchFromFilterRepresentation} [postProductionBatchFromFilterRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchCreateFromFilterPost: async (apiVersion?: string, postProductionBatchFromFilterRepresentation?: PostProductionBatchFromFilterRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-batch/createFromFilter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(postProductionBatchFromFilterRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PostProductionBatchForNeutenRepresentation} [postProductionBatchForNeutenRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchCreateLosseNeutenBatchPost: async (apiVersion?: string, postProductionBatchForNeutenRepresentation?: PostProductionBatchForNeutenRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-batch/createLosseNeutenBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(postProductionBatchForNeutenRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdAcceptedPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdAcceptedPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/accepted`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdAppendPut: async (id: string, apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdAppendPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/append`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdCancelledPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdCancelledPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/cancelled`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdDraftedPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdDraftedPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/drafted`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdFailedPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdFailedPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/failed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdFinishedPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdFinishedPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/finished`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdGet', 'id', id)
            const localVarPath = `/pm/production-batch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdReducePut: async (id: string, apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdReducePut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/reduce`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdRegenerateBmhXmlPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdRegenerateBmhXmlPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/regenerate-bmh-xml`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdRegenerateGroupNumbersPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdRegenerateGroupNumbersPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/regenerate-group-numbers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionBatchRemarkRepresentation} [putProductionBatchRemarkRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdRemarkPut: async (id: string, apiVersion?: string, putProductionBatchRemarkRepresentation?: PutProductionBatchRemarkRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdRemarkPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/remark`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionBatchRemarkRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdStartedPut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdStartedPut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/started`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionBatchTitleRepresentation} [putProductionBatchTitleRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdTitlePut: async (id: string, apiVersion?: string, putProductionBatchTitleRepresentation?: PutProductionBatchTitleRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdTitlePut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/title`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionBatchTitleRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionBatchTypeRepresentation} [putProductionBatchTypeRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdTypePut: async (id: string, apiVersion?: string, putProductionBatchTypeRepresentation?: PutProductionBatchTypeRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdTypePut', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/type`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionBatchTypeRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchIdXmlGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionBatchIdXmlGet', 'id', id)
            const localVarPath = `/pm/production-batch/{id}/xml`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchListOdataGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-batch/list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PostProductionBatchRepresentation} [postProductionBatchRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchPost: async (apiVersion?: string, postProductionBatchRepresentation?: PostProductionBatchRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(postProductionBatchRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-batch/regenerate-bmh-xml-not-started-batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProductionBatchApi - functional programming interface
        * @export
        */
        export const ProductionBatchApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProductionBatchApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async ekiExport(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ekiExport(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.ekiExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PostProductionBatchFromFilterRepresentation} [postProductionBatchFromFilterRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchCreateFromFilterPost(apiVersion?: string, postProductionBatchFromFilterRepresentation?: PostProductionBatchFromFilterRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionBatchRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchCreateFromFilterPost(apiVersion, postProductionBatchFromFilterRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchCreateFromFilterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PostProductionBatchForNeutenRepresentation} [postProductionBatchForNeutenRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchCreateLosseNeutenBatchPost(apiVersion?: string, postProductionBatchForNeutenRepresentation?: PostProductionBatchForNeutenRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionBatchRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchCreateLosseNeutenBatchPost(apiVersion, postProductionBatchForNeutenRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchCreateLosseNeutenBatchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdAcceptedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdAcceptedPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdAcceptedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdAppendPut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionBatchRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdAppendPut(id, apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdAppendPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdCancelledPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdCancelledPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdCancelledPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdDraftedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdDraftedPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdDraftedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdFailedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdFailedPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdFailedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdFinishedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdFinishedPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdFinishedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionBatchRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdReducePut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionBatchRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdReducePut(id, apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdReducePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdRegenerateBmhXmlPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdRegenerateBmhXmlPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdRegenerateBmhXmlPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdRegenerateGroupNumbersPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdRegenerateGroupNumbersPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdRegenerateGroupNumbersPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionBatchRemarkRepresentation} [putProductionBatchRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdRemarkPut(id: string, apiVersion?: string, putProductionBatchRemarkRepresentation?: PutProductionBatchRemarkRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdRemarkPut(id, apiVersion, putProductionBatchRemarkRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdRemarkPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdStartedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdStartedPut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdStartedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionBatchTitleRepresentation} [putProductionBatchTitleRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdTitlePut(id: string, apiVersion?: string, putProductionBatchTitleRepresentation?: PutProductionBatchTitleRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdTitlePut(id, apiVersion, putProductionBatchTitleRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdTitlePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionBatchTypeRepresentation} [putProductionBatchTypeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdTypePut(id: string, apiVersion?: string, putProductionBatchTypeRepresentation?: PutProductionBatchTypeRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdTypePut(id, apiVersion, putProductionBatchTypeRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdTypePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchIdXmlGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchIdXmlGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchIdXmlGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionBatchViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchListOdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PostProductionBatchRepresentation} [postProductionBatchRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchPost(apiVersion?: string, postProductionBatchRepresentation?: PostProductionBatchRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionBatchRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchPost(apiVersion, postProductionBatchRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionBatchApi.pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProductionBatchApi - factory interface
        * @export
        */
        export const ProductionBatchApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProductionBatchApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            ekiExport(id: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.ekiExport(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchFromFilterRepresentation} [postProductionBatchFromFilterRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchCreateFromFilterPost(apiVersion?: string, postProductionBatchFromFilterRepresentation?: PostProductionBatchFromFilterRepresentation, options?: any): AxiosPromise<ProductionBatchRepresentation> {
                return localVarFp.pmProductionBatchCreateFromFilterPost(apiVersion, postProductionBatchFromFilterRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchForNeutenRepresentation} [postProductionBatchForNeutenRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchCreateLosseNeutenBatchPost(apiVersion?: string, postProductionBatchForNeutenRepresentation?: PostProductionBatchForNeutenRepresentation, options?: any): AxiosPromise<ProductionBatchRepresentation> {
                return localVarFp.pmProductionBatchCreateLosseNeutenBatchPost(apiVersion, postProductionBatchForNeutenRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdAcceptedPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdAcceptedPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdAppendPut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<ProductionBatchRepresentation> {
                return localVarFp.pmProductionBatchIdAppendPut(id, apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdCancelledPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdCancelledPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdDraftedPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdDraftedPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdFailedPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdFailedPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdFinishedPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdFinishedPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ProductionBatchRepresentation> {
                return localVarFp.pmProductionBatchIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdReducePut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<ProductionBatchRepresentation> {
                return localVarFp.pmProductionBatchIdReducePut(id, apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdRegenerateBmhXmlPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdRegenerateBmhXmlPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdRegenerateGroupNumbersPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdRegenerateGroupNumbersPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchRemarkRepresentation} [putProductionBatchRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdRemarkPut(id: string, apiVersion?: string, putProductionBatchRemarkRepresentation?: PutProductionBatchRemarkRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdRemarkPut(id, apiVersion, putProductionBatchRemarkRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdStartedPut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdStartedPut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchTitleRepresentation} [putProductionBatchTitleRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdTitlePut(id: string, apiVersion?: string, putProductionBatchTitleRepresentation?: PutProductionBatchTitleRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdTitlePut(id, apiVersion, putProductionBatchTitleRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchTypeRepresentation} [putProductionBatchTypeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdTypePut(id: string, apiVersion?: string, putProductionBatchTypeRepresentation?: PutProductionBatchTypeRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchIdTypePut(id, apiVersion, putProductionBatchTypeRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchIdXmlGet(id: string, apiVersion?: string, options?: any): AxiosPromise<File> {
                return localVarFp.pmProductionBatchIdXmlGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<ProductionBatchViewArrayODataValue> {
                return localVarFp.pmProductionBatchListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchRepresentation} [postProductionBatchRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchPost(apiVersion?: string, postProductionBatchRepresentation?: PostProductionBatchRepresentation, options?: any): AxiosPromise<ProductionBatchRepresentation> {
                return localVarFp.pmProductionBatchPost(apiVersion, postProductionBatchRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut(apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProductionBatchApi - interface
            * @export
            * @interface ProductionBatchApi
            */
            export interface ProductionBatchApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            ekiExport(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchFromFilterRepresentation} [postProductionBatchFromFilterRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchCreateFromFilterPost(apiVersion?: string, postProductionBatchFromFilterRepresentation?: PostProductionBatchFromFilterRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<ProductionBatchRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchForNeutenRepresentation} [postProductionBatchForNeutenRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchCreateLosseNeutenBatchPost(apiVersion?: string, postProductionBatchForNeutenRepresentation?: PostProductionBatchForNeutenRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<ProductionBatchRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdAcceptedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdAppendPut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<ProductionBatchRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdCancelledPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdDraftedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdFailedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdFinishedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionBatchRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdReducePut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<ProductionBatchRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdRegenerateBmhXmlPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdRegenerateGroupNumbersPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchRemarkRepresentation} [putProductionBatchRemarkRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdRemarkPut(id: string, apiVersion?: string, putProductionBatchRemarkRepresentation?: PutProductionBatchRemarkRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdStartedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchTitleRepresentation} [putProductionBatchTitleRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdTitlePut(id: string, apiVersion?: string, putProductionBatchTitleRepresentation?: PutProductionBatchTitleRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchTypeRepresentation} [putProductionBatchTypeRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdTypePut(id: string, apiVersion?: string, putProductionBatchTypeRepresentation?: PutProductionBatchTypeRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchIdXmlGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionBatchViewArrayODataValue>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchRepresentation} [postProductionBatchRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchPost(apiVersion?: string, postProductionBatchRepresentation?: PostProductionBatchRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<ProductionBatchRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionBatchApiInterface
                */
            pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * ProductionBatchApi - object-oriented interface
        * @export
        * @class ProductionBatchApi
        * @extends {BaseAPI}
        */
            export class ProductionBatchApi extends BaseAPI implements ProductionBatchApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public ekiExport(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).ekiExport(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchFromFilterRepresentation} [postProductionBatchFromFilterRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchCreateFromFilterPost(apiVersion?: string, postProductionBatchFromFilterRepresentation?: PostProductionBatchFromFilterRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchCreateFromFilterPost(apiVersion, postProductionBatchFromFilterRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchForNeutenRepresentation} [postProductionBatchForNeutenRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchCreateLosseNeutenBatchPost(apiVersion?: string, postProductionBatchForNeutenRepresentation?: PostProductionBatchForNeutenRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchCreateLosseNeutenBatchPost(apiVersion, postProductionBatchForNeutenRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdAcceptedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdAcceptedPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdAppendPut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdAppendPut(id, apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdCancelledPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdCancelledPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdDraftedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdDraftedPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdFailedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdFailedPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdFinishedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdFinishedPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdReducePut(id: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdReducePut(id, apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdRegenerateBmhXmlPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdRegenerateBmhXmlPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdRegenerateGroupNumbersPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdRegenerateGroupNumbersPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchRemarkRepresentation} [putProductionBatchRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdRemarkPut(id: string, apiVersion?: string, putProductionBatchRemarkRepresentation?: PutProductionBatchRemarkRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdRemarkPut(id, apiVersion, putProductionBatchRemarkRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdStartedPut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdStartedPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchTitleRepresentation} [putProductionBatchTitleRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdTitlePut(id: string, apiVersion?: string, putProductionBatchTitleRepresentation?: PutProductionBatchTitleRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdTitlePut(id, apiVersion, putProductionBatchTitleRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionBatchTypeRepresentation} [putProductionBatchTypeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdTypePut(id: string, apiVersion?: string, putProductionBatchTypeRepresentation?: PutProductionBatchTypeRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdTypePut(id, apiVersion, putProductionBatchTypeRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchIdXmlGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchIdXmlGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PostProductionBatchRepresentation} [postProductionBatchRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchPost(apiVersion?: string, postProductionBatchRepresentation?: PostProductionBatchRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchPost(apiVersion, postProductionBatchRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionBatchApi
            */
                public pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionBatchApiFp(this.configuration).pmProductionBatchRegenerateBmhXmlNotStartedBatchesPut(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProductionItemApi - axios parameter creator
    * @export
    */
    export const ProductionItemApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [id] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteProductionItems: async (id?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/delete-production-items-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (id !== undefined) {
                                    localVarQueryParameter['id'] = id;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} code 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemBarcodeCodeGet: async (code: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'code' is not null or undefined
                    assertParamExists('pmProductionItemBarcodeCodeGet', 'code', code)
            const localVarPath = `/pm/production-item/barcode/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemBulkPreviewPost: async (apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/bulk-preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemCancelledPut: async (apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/cancelled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemStatusRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemFailedPut: async (apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/failed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemStatusRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemFinishedPut: async (apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/finished`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemStatusRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {Array<string>} [scope] 
            * @param {Array<string>} [filters] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemGet: async (scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (scope) {
                            localVarQueryParameter['scope'] = scope;
                    }

                    if (filters) {
                            localVarQueryParameter['filters'] = filters;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionItemIdGet', 'id', id)
            const localVarPath = `/pm/production-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemIndexRepresentation} [putProductionItemIndexRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemIndexPut: async (apiVersion?: string, putProductionItemIndexRepresentation?: PutProductionItemIndexRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemIndexRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemInstructionsRepresentation} [putProductionItemInstructionsRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemInstructionsPut: async (apiVersion?: string, putProductionItemInstructionsRepresentation?: PutProductionItemInstructionsRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/instructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemInstructionsRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemKomoCertifiedRepresentation} [putProductionItemKomoCertifiedRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemKomoCertifiedPut: async (apiVersion?: string, putProductionItemKomoCertifiedRepresentation?: PutProductionItemKomoCertifiedRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/komo-certified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemKomoCertifiedRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemListOdataGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/list-odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemNotStartedPut: async (apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/not-started`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemStatusRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemOnPalletRepresentation} [putProductionItemOnPalletRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemOnPalletPut: async (apiVersion?: string, putProductionItemOnPalletRepresentation?: PutProductionItemOnPalletRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/onPallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemOnPalletRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemOperationBmhBarCodeRepresentation} [putProductionItemOperationBmhBarCodeRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemOperationBmhBarcodePut: async (apiVersion?: string, putProductionItemOperationBmhBarCodeRepresentation?: PutProductionItemOperationBmhBarCodeRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/operation-bmh-barcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemOperationBmhBarCodeRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemOperationCodeSequenceRepresentation} [putProductionItemOperationCodeSequenceRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemOperationCodesequencePut: async (apiVersion?: string, putProductionItemOperationCodeSequenceRepresentation?: PutProductionItemOperationCodeSequenceRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/operation-codesequence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemOperationCodeSequenceRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemOperationRepresentation} [putProductionItemOperationRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemOperationPut: async (apiVersion?: string, putProductionItemOperationRepresentation?: PutProductionItemOperationRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/operation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemOperationRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} code 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemOrderIdByBarcodeCodeGet: async (code: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'code' is not null or undefined
                    assertParamExists('pmProductionItemOrderIdByBarcodeCodeGet', 'code', code)
            const localVarPath = `/pm/production-item/order-id-by-barcode/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemDateRepresentation} [putProductionItemDateRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemProductionDatePut: async (apiVersion?: string, putProductionItemDateRepresentation?: PutProductionItemDateRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/production-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemDateRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemRemarkRepresentation} [putProductionItemRemarkRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemRemarkPut: async (apiVersion?: string, putProductionItemRemarkRepresentation?: PutProductionItemRemarkRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/remark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemRemarkRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemStartedPut: async (apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/started`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemStatusRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemUrgentRepresentation} [putProductionItemUrgentRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemUrgentPut: async (apiVersion?: string, putProductionItemUrgentRepresentation?: PutProductionItemUrgentRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/urgent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemUrgentRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [id] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        recreateProductionItems: async (id?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item/recreate-production-items-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (id !== undefined) {
                                    localVarQueryParameter['id'] = id;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProductionItemApi - functional programming interface
        * @export
        */
        export const ProductionItemApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProductionItemApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [id] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteProductionItems(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductionItems(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.deleteProductionItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} code 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemBarcodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionItemRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemBarcodeCodeGet(code, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemBarcodeCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemBulkPreviewPost(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemBulkPreviewPost(apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemBulkPreviewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemCancelledPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemCancelledPut(apiVersion, putProductionItemStatusRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemCancelledPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemFailedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemFailedPut(apiVersion, putProductionItemStatusRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemFailedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemFinishedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemFinishedPut(apiVersion, putProductionItemStatusRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemFinishedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {Array<string>} [scope] 
                * @param {Array<string>} [filters] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemGet(scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionItemRepresentationFacetedSearchResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemGet(scope, filters, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionItemRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemIndexRepresentation} [putProductionItemIndexRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemIndexPut(apiVersion?: string, putProductionItemIndexRepresentation?: PutProductionItemIndexRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemIndexPut(apiVersion, putProductionItemIndexRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemIndexPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemInstructionsRepresentation} [putProductionItemInstructionsRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemInstructionsPut(apiVersion?: string, putProductionItemInstructionsRepresentation?: PutProductionItemInstructionsRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemInstructionsPut(apiVersion, putProductionItemInstructionsRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemInstructionsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemKomoCertifiedRepresentation} [putProductionItemKomoCertifiedRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemKomoCertifiedPut(apiVersion?: string, putProductionItemKomoCertifiedRepresentation?: PutProductionItemKomoCertifiedRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemKomoCertifiedPut(apiVersion, putProductionItemKomoCertifiedRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemKomoCertifiedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionItemViewRepresentationArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemListOdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemNotStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemNotStartedPut(apiVersion, putProductionItemStatusRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemNotStartedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemOnPalletRepresentation} [putProductionItemOnPalletRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemOnPalletPut(apiVersion?: string, putProductionItemOnPalletRepresentation?: PutProductionItemOnPalletRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemOnPalletPut(apiVersion, putProductionItemOnPalletRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemOnPalletPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemOperationBmhBarCodeRepresentation} [putProductionItemOperationBmhBarCodeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemOperationBmhBarcodePut(apiVersion?: string, putProductionItemOperationBmhBarCodeRepresentation?: PutProductionItemOperationBmhBarCodeRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionItemNextOperationRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemOperationBmhBarcodePut(apiVersion, putProductionItemOperationBmhBarCodeRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemOperationBmhBarcodePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemOperationCodeSequenceRepresentation} [putProductionItemOperationCodeSequenceRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemOperationCodesequencePut(apiVersion?: string, putProductionItemOperationCodeSequenceRepresentation?: PutProductionItemOperationCodeSequenceRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionItemNextOperationRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemOperationCodesequencePut(apiVersion, putProductionItemOperationCodeSequenceRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemOperationCodesequencePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemOperationRepresentation} [putProductionItemOperationRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemOperationPut(apiVersion?: string, putProductionItemOperationRepresentation?: PutProductionItemOperationRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionItemNextOperationRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemOperationPut(apiVersion, putProductionItemOperationRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemOperationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} code 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemOrderIdByBarcodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemOrderIdByBarcodeCodeGet(code, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemOrderIdByBarcodeCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemDateRepresentation} [putProductionItemDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemProductionDatePut(apiVersion?: string, putProductionItemDateRepresentation?: PutProductionItemDateRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemProductionDatePut(apiVersion, putProductionItemDateRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemProductionDatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemRemarkRepresentation} [putProductionItemRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemRemarkPut(apiVersion?: string, putProductionItemRemarkRepresentation?: PutProductionItemRemarkRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemRemarkPut(apiVersion, putProductionItemRemarkRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemRemarkPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemStartedPut(apiVersion, putProductionItemStatusRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemStartedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemUrgentRepresentation} [putProductionItemUrgentRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemUrgentPut(apiVersion?: string, putProductionItemUrgentRepresentation?: PutProductionItemUrgentRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemUrgentPut(apiVersion, putProductionItemUrgentRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.pmProductionItemUrgentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [id] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async recreateProductionItems(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recreateProductionItems(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemApi.recreateProductionItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProductionItemApi - factory interface
        * @export
        */
        export const ProductionItemApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProductionItemApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteProductionItems(id?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deleteProductionItems(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemBarcodeCodeGet(code: string, apiVersion?: string, options?: any): AxiosPromise<ProductionItemRepresentation> {
                return localVarFp.pmProductionItemBarcodeCodeGet(code, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemBulkPreviewPost(apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<CountMessage> {
                return localVarFp.pmProductionItemBulkPreviewPost(apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemCancelledPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemCancelledPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemFailedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemFailedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemFinishedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemFinishedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemGet(scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: any): AxiosPromise<ProductionItemRepresentationFacetedSearchResultList> {
                return localVarFp.pmProductionItemGet(scope, filters, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ProductionItemRepresentation> {
                return localVarFp.pmProductionItemIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemIndexRepresentation} [putProductionItemIndexRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemIndexPut(apiVersion?: string, putProductionItemIndexRepresentation?: PutProductionItemIndexRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemIndexPut(apiVersion, putProductionItemIndexRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemInstructionsRepresentation} [putProductionItemInstructionsRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemInstructionsPut(apiVersion?: string, putProductionItemInstructionsRepresentation?: PutProductionItemInstructionsRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemInstructionsPut(apiVersion, putProductionItemInstructionsRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemKomoCertifiedRepresentation} [putProductionItemKomoCertifiedRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemKomoCertifiedPut(apiVersion?: string, putProductionItemKomoCertifiedRepresentation?: PutProductionItemKomoCertifiedRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemKomoCertifiedPut(apiVersion, putProductionItemKomoCertifiedRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<ProductionItemViewRepresentationArrayODataValue> {
                return localVarFp.pmProductionItemListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemNotStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemNotStartedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOnPalletRepresentation} [putProductionItemOnPalletRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemOnPalletPut(apiVersion?: string, putProductionItemOnPalletRepresentation?: PutProductionItemOnPalletRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemOnPalletPut(apiVersion, putProductionItemOnPalletRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationBmhBarCodeRepresentation} [putProductionItemOperationBmhBarCodeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemOperationBmhBarcodePut(apiVersion?: string, putProductionItemOperationBmhBarCodeRepresentation?: PutProductionItemOperationBmhBarCodeRepresentation, options?: any): AxiosPromise<Array<ProductionItemNextOperationRepresentation>> {
                return localVarFp.pmProductionItemOperationBmhBarcodePut(apiVersion, putProductionItemOperationBmhBarCodeRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationCodeSequenceRepresentation} [putProductionItemOperationCodeSequenceRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemOperationCodesequencePut(apiVersion?: string, putProductionItemOperationCodeSequenceRepresentation?: PutProductionItemOperationCodeSequenceRepresentation, options?: any): AxiosPromise<Array<ProductionItemNextOperationRepresentation>> {
                return localVarFp.pmProductionItemOperationCodesequencePut(apiVersion, putProductionItemOperationCodeSequenceRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationRepresentation} [putProductionItemOperationRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemOperationPut(apiVersion?: string, putProductionItemOperationRepresentation?: PutProductionItemOperationRepresentation, options?: any): AxiosPromise<Array<ProductionItemNextOperationRepresentation>> {
                return localVarFp.pmProductionItemOperationPut(apiVersion, putProductionItemOperationRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemOrderIdByBarcodeCodeGet(code: string, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.pmProductionItemOrderIdByBarcodeCodeGet(code, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemDateRepresentation} [putProductionItemDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemProductionDatePut(apiVersion?: string, putProductionItemDateRepresentation?: PutProductionItemDateRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemProductionDatePut(apiVersion, putProductionItemDateRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemRemarkRepresentation} [putProductionItemRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemRemarkPut(apiVersion?: string, putProductionItemRemarkRepresentation?: PutProductionItemRemarkRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemRemarkPut(apiVersion, putProductionItemRemarkRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemStartedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemUrgentRepresentation} [putProductionItemUrgentRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemUrgentPut(apiVersion?: string, putProductionItemUrgentRepresentation?: PutProductionItemUrgentRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemUrgentPut(apiVersion, putProductionItemUrgentRepresentation, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            recreateProductionItems(id?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.recreateProductionItems(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProductionItemApi - interface
            * @export
            * @interface ProductionItemApi
            */
            export interface ProductionItemApiInterface {
                /**
                * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            deleteProductionItems(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemBarcodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionItemRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemBulkPreviewPost(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<CountMessage>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemCancelledPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemFailedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemFinishedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemGet(scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionItemRepresentationFacetedSearchResultList>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionItemRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemIndexRepresentation} [putProductionItemIndexRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemIndexPut(apiVersion?: string, putProductionItemIndexRepresentation?: PutProductionItemIndexRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemInstructionsRepresentation} [putProductionItemInstructionsRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemInstructionsPut(apiVersion?: string, putProductionItemInstructionsRepresentation?: PutProductionItemInstructionsRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemKomoCertifiedRepresentation} [putProductionItemKomoCertifiedRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemKomoCertifiedPut(apiVersion?: string, putProductionItemKomoCertifiedRepresentation?: PutProductionItemKomoCertifiedRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionItemViewRepresentationArrayODataValue>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemNotStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOnPalletRepresentation} [putProductionItemOnPalletRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemOnPalletPut(apiVersion?: string, putProductionItemOnPalletRepresentation?: PutProductionItemOnPalletRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationBmhBarCodeRepresentation} [putProductionItemOperationBmhBarCodeRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemOperationBmhBarcodePut(apiVersion?: string, putProductionItemOperationBmhBarCodeRepresentation?: PutProductionItemOperationBmhBarCodeRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductionItemNextOperationRepresentation>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationCodeSequenceRepresentation} [putProductionItemOperationCodeSequenceRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemOperationCodesequencePut(apiVersion?: string, putProductionItemOperationCodeSequenceRepresentation?: PutProductionItemOperationCodeSequenceRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductionItemNextOperationRepresentation>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationRepresentation} [putProductionItemOperationRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemOperationPut(apiVersion?: string, putProductionItemOperationRepresentation?: PutProductionItemOperationRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductionItemNextOperationRepresentation>>;

                /**
                * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemOrderIdByBarcodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemDateRepresentation} [putProductionItemDateRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemProductionDatePut(apiVersion?: string, putProductionItemDateRepresentation?: PutProductionItemDateRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemRemarkRepresentation} [putProductionItemRemarkRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemRemarkPut(apiVersion?: string, putProductionItemRemarkRepresentation?: PutProductionItemRemarkRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemUrgentRepresentation} [putProductionItemUrgentRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            pmProductionItemUrgentPut(apiVersion?: string, putProductionItemUrgentRepresentation?: PutProductionItemUrgentRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemApiInterface
                */
            recreateProductionItems(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * ProductionItemApi - object-oriented interface
        * @export
        * @class ProductionItemApi
        * @extends {BaseAPI}
        */
            export class ProductionItemApi extends BaseAPI implements ProductionItemApiInterface {
            /**
            * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public deleteProductionItems(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).deleteProductionItems(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemBarcodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemBarcodeCodeGet(code, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemBulkPreviewPost(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemBulkPreviewPost(apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemCancelledPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemCancelledPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemFailedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemFailedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemFinishedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemFinishedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemGet(scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemGet(scope, filters, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemIndexRepresentation} [putProductionItemIndexRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemIndexPut(apiVersion?: string, putProductionItemIndexRepresentation?: PutProductionItemIndexRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemIndexPut(apiVersion, putProductionItemIndexRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemInstructionsRepresentation} [putProductionItemInstructionsRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemInstructionsPut(apiVersion?: string, putProductionItemInstructionsRepresentation?: PutProductionItemInstructionsRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemInstructionsPut(apiVersion, putProductionItemInstructionsRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemKomoCertifiedRepresentation} [putProductionItemKomoCertifiedRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemKomoCertifiedPut(apiVersion?: string, putProductionItemKomoCertifiedRepresentation?: PutProductionItemKomoCertifiedRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemKomoCertifiedPut(apiVersion, putProductionItemKomoCertifiedRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemListOdataGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemListOdataGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemNotStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemNotStartedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOnPalletRepresentation} [putProductionItemOnPalletRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemOnPalletPut(apiVersion?: string, putProductionItemOnPalletRepresentation?: PutProductionItemOnPalletRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemOnPalletPut(apiVersion, putProductionItemOnPalletRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationBmhBarCodeRepresentation} [putProductionItemOperationBmhBarCodeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemOperationBmhBarcodePut(apiVersion?: string, putProductionItemOperationBmhBarCodeRepresentation?: PutProductionItemOperationBmhBarCodeRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemOperationBmhBarcodePut(apiVersion, putProductionItemOperationBmhBarCodeRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationCodeSequenceRepresentation} [putProductionItemOperationCodeSequenceRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemOperationCodesequencePut(apiVersion?: string, putProductionItemOperationCodeSequenceRepresentation?: PutProductionItemOperationCodeSequenceRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemOperationCodesequencePut(apiVersion, putProductionItemOperationCodeSequenceRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationRepresentation} [putProductionItemOperationRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemOperationPut(apiVersion?: string, putProductionItemOperationRepresentation?: PutProductionItemOperationRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemOperationPut(apiVersion, putProductionItemOperationRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemOrderIdByBarcodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemOrderIdByBarcodeCodeGet(code, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemDateRepresentation} [putProductionItemDateRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemProductionDatePut(apiVersion?: string, putProductionItemDateRepresentation?: PutProductionItemDateRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemProductionDatePut(apiVersion, putProductionItemDateRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemRemarkRepresentation} [putProductionItemRemarkRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemRemarkPut(apiVersion?: string, putProductionItemRemarkRepresentation?: PutProductionItemRemarkRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemRemarkPut(apiVersion, putProductionItemRemarkRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemStatusRepresentation} [putProductionItemStatusRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemStartedPut(apiVersion?: string, putProductionItemStatusRepresentation?: PutProductionItemStatusRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemStartedPut(apiVersion, putProductionItemStatusRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemUrgentRepresentation} [putProductionItemUrgentRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public pmProductionItemUrgentPut(apiVersion?: string, putProductionItemUrgentRepresentation?: PutProductionItemUrgentRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).pmProductionItemUrgentPut(apiVersion, putProductionItemUrgentRepresentation, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [id] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemApi
            */
                public recreateProductionItems(id?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemApiFp(this.configuration).recreateProductionItems(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProductionItemGroupApi - axios parameter creator
    * @export
    */
    export const ProductionItemGroupApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} code 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemGroupCodeCodeGet: async (code: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'code' is not null or undefined
                    assertParamExists('pmProductionItemGroupCodeCodeGet', 'code', code)
            const localVarPath = `/pm/production-item-group/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PutProductionItemOperationCodeRepresentation} [putProductionItemOperationCodeRepresentation] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionItemGroupOperationPut: async (apiVersion?: string, putProductionItemOperationCodeRepresentation?: PutProductionItemOperationCodeRepresentation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-item-group/operation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(putProductionItemOperationCodeRepresentation, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProductionItemGroupApi - functional programming interface
        * @export
        */
        export const ProductionItemGroupApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProductionItemGroupApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} code 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemGroupCodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionItemGroupRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemGroupCodeCodeGet(code, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemGroupApi.pmProductionItemGroupCodeCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PutProductionItemOperationCodeRepresentation} [putProductionItemOperationCodeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionItemGroupOperationPut(apiVersion?: string, putProductionItemOperationCodeRepresentation?: PutProductionItemOperationCodeRepresentation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionItemGroupOperationPut(apiVersion, putProductionItemOperationCodeRepresentation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionItemGroupApi.pmProductionItemGroupOperationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProductionItemGroupApi - factory interface
        * @export
        */
        export const ProductionItemGroupApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProductionItemGroupApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemGroupCodeCodeGet(code: string, apiVersion?: string, options?: any): AxiosPromise<ProductionItemGroupRepresentation> {
                return localVarFp.pmProductionItemGroupCodeCodeGet(code, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationCodeRepresentation} [putProductionItemOperationCodeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionItemGroupOperationPut(apiVersion?: string, putProductionItemOperationCodeRepresentation?: PutProductionItemOperationCodeRepresentation, options?: any): AxiosPromise<void> {
                return localVarFp.pmProductionItemGroupOperationPut(apiVersion, putProductionItemOperationCodeRepresentation, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProductionItemGroupApi - interface
            * @export
            * @interface ProductionItemGroupApi
            */
            export interface ProductionItemGroupApiInterface {
                /**
                * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemGroupApiInterface
                */
            pmProductionItemGroupCodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionItemGroupRepresentation>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationCodeRepresentation} [putProductionItemOperationCodeRepresentation] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionItemGroupApiInterface
                */
            pmProductionItemGroupOperationPut(apiVersion?: string, putProductionItemOperationCodeRepresentation?: PutProductionItemOperationCodeRepresentation, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * ProductionItemGroupApi - object-oriented interface
        * @export
        * @class ProductionItemGroupApi
        * @extends {BaseAPI}
        */
            export class ProductionItemGroupApi extends BaseAPI implements ProductionItemGroupApiInterface {
            /**
            * 
                    * @param {string} code 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemGroupApi
            */
                public pmProductionItemGroupCodeCodeGet(code: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionItemGroupApiFp(this.configuration).pmProductionItemGroupCodeCodeGet(code, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PutProductionItemOperationCodeRepresentation} [putProductionItemOperationCodeRepresentation] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionItemGroupApi
            */
                public pmProductionItemGroupOperationPut(apiVersion?: string, putProductionItemOperationCodeRepresentation?: PutProductionItemOperationCodeRepresentation, options?: RawAxiosRequestConfig) {
                return ProductionItemGroupApiFp(this.configuration).pmProductionItemGroupOperationPut(apiVersion, putProductionItemOperationCodeRepresentation, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProductionOrderApi - axios parameter creator
    * @export
    */
    export const ProductionOrderApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionOrderIdProductionItemSagaStateGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionOrderIdProductionItemSagaStateGet', 'id', id)
            const localVarPath = `/pm/production-order/{id}/production-item-saga-state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionOrderIdProductionItemsPost: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionOrderIdProductionItemsPost', 'id', id)
            const localVarPath = `/pm/production-order/{id}/production-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionOrderIdSummaryGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pmProductionOrderIdSummaryGet', 'id', id)
            const localVarPath = `/pm/production-order/{id}/summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProductionOrderApi - functional programming interface
        * @export
        */
        export const ProductionOrderApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProductionOrderApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionOrderIdProductionItemSagaStateGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionOrderIdProductionItemSagaStateGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionOrderApi.pmProductionOrderIdProductionItemSagaStateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionOrderIdProductionItemsPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionOrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionOrderIdProductionItemsPost(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionOrderApi.pmProductionOrderIdProductionItemsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionOrderIdSummaryGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionOrderRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionOrderIdSummaryGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionOrderApi.pmProductionOrderIdSummaryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProductionOrderApi - factory interface
        * @export
        */
        export const ProductionOrderApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProductionOrderApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionOrderIdProductionItemSagaStateGet(id: string, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.pmProductionOrderIdProductionItemSagaStateGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionOrderIdProductionItemsPost(id: string, apiVersion?: string, options?: any): AxiosPromise<ProductionOrderRepresentation> {
                return localVarFp.pmProductionOrderIdProductionItemsPost(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionOrderIdSummaryGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ProductionOrderRepresentation> {
                return localVarFp.pmProductionOrderIdSummaryGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProductionOrderApi - interface
            * @export
            * @interface ProductionOrderApi
            */
            export interface ProductionOrderApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionOrderApiInterface
                */
            pmProductionOrderIdProductionItemSagaStateGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionOrderApiInterface
                */
            pmProductionOrderIdProductionItemsPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionOrderRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionOrderApiInterface
                */
            pmProductionOrderIdSummaryGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionOrderRepresentation>;

            }

        /**
        * ProductionOrderApi - object-oriented interface
        * @export
        * @class ProductionOrderApi
        * @extends {BaseAPI}
        */
            export class ProductionOrderApi extends BaseAPI implements ProductionOrderApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionOrderApi
            */
                public pmProductionOrderIdProductionItemSagaStateGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionOrderApiFp(this.configuration).pmProductionOrderIdProductionItemSagaStateGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionOrderApi
            */
                public pmProductionOrderIdProductionItemsPost(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionOrderApiFp(this.configuration).pmProductionOrderIdProductionItemsPost(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionOrderApi
            */
                public pmProductionOrderIdSummaryGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionOrderApiFp(this.configuration).pmProductionOrderIdSummaryGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProductionPlanningApi - axios parameter creator
    * @export
    */
    export const ProductionPlanningApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {number} [$top] 
            * @param {number} [$skip] 
            * @param {string} [$orderby] 
            * @param {string} [$filter] 
            * @param {boolean} [$count] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionPlanningToBeManufacturedByWeekGet: async ($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-planning/to-be-manufactured-by-week`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if ($top !== undefined) {
                                    localVarQueryParameter['$top'] = $top;
                    }

                    if ($skip !== undefined) {
                                    localVarQueryParameter['$skip'] = $skip;
                    }

                    if ($orderby !== undefined) {
                                    localVarQueryParameter['$orderby'] = $orderby;
                    }

                    if ($filter !== undefined) {
                                    localVarQueryParameter['$filter'] = $filter;
                    }

                    if ($count !== undefined) {
                                    localVarQueryParameter['$count'] = $count;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProductionPlanningApi - functional programming interface
        * @export
        */
        export const ProductionPlanningApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProductionPlanningApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {number} [$top] 
                * @param {number} [$skip] 
                * @param {string} [$orderby] 
                * @param {string} [$filter] 
                * @param {boolean} [$count] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionPlanningToBeManufacturedByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionItemsToBeManufacturedByWeekViewArrayODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionPlanningToBeManufacturedByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionPlanningApi.pmProductionPlanningToBeManufacturedByWeekGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProductionPlanningApi - factory interface
        * @export
        */
        export const ProductionPlanningApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProductionPlanningApiFp(configuration)
        return {
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionPlanningToBeManufacturedByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: any): AxiosPromise<ProductionItemsToBeManufacturedByWeekViewArrayODataValue> {
                return localVarFp.pmProductionPlanningToBeManufacturedByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProductionPlanningApi - interface
            * @export
            * @interface ProductionPlanningApi
            */
            export interface ProductionPlanningApiInterface {
                /**
                * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionPlanningApiInterface
                */
            pmProductionPlanningToBeManufacturedByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionItemsToBeManufacturedByWeekViewArrayODataValue>;

            }

        /**
        * ProductionPlanningApi - object-oriented interface
        * @export
        * @class ProductionPlanningApi
        * @extends {BaseAPI}
        */
            export class ProductionPlanningApi extends BaseAPI implements ProductionPlanningApiInterface {
            /**
            * 
                    * @param {number} [$top] 
                    * @param {number} [$skip] 
                    * @param {string} [$orderby] 
                    * @param {string} [$filter] 
                    * @param {boolean} [$count] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionPlanningApi
            */
                public pmProductionPlanningToBeManufacturedByWeekGet($top?: number, $skip?: number, $orderby?: string, $filter?: string, $count?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionPlanningApiFp(this.configuration).pmProductionPlanningToBeManufacturedByWeekGet($top, $skip, $orderby, $filter, $count, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProductionSillApi - axios parameter creator
    * @export
    */
    export const ProductionSillApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} catalogItemId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionSillCatalogItemIdGet: async (catalogItemId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'catalogItemId' is not null or undefined
                    assertParamExists('pmProductionSillCatalogItemIdGet', 'catalogItemId', catalogItemId)
            const localVarPath = `/pm/production-sill/{catalogItemId}`
                .replace(`{${"catalogItemId"}}`, encodeURIComponent(String(catalogItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProductionSillApi - functional programming interface
        * @export
        */
        export const ProductionSillApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProductionSillApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} catalogItemId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionSillCatalogItemIdGet(catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSillRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionSillCatalogItemIdGet(catalogItemId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionSillApi.pmProductionSillCatalogItemIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProductionSillApi - factory interface
        * @export
        */
        export const ProductionSillApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProductionSillApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionSillCatalogItemIdGet(catalogItemId: string, apiVersion?: string, options?: any): AxiosPromise<ProductionSillRepresentation> {
                return localVarFp.pmProductionSillCatalogItemIdGet(catalogItemId, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProductionSillApi - interface
            * @export
            * @interface ProductionSillApi
            */
            export interface ProductionSillApiInterface {
                /**
                * 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionSillApiInterface
                */
            pmProductionSillCatalogItemIdGet(catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionSillRepresentation>;

            }

        /**
        * ProductionSillApi - object-oriented interface
        * @export
        * @class ProductionSillApi
        * @extends {BaseAPI}
        */
            export class ProductionSillApi extends BaseAPI implements ProductionSillApiInterface {
            /**
            * 
                    * @param {string} catalogItemId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionSillApi
            */
                public pmProductionSillCatalogItemIdGet(catalogItemId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionSillApiFp(this.configuration).pmProductionSillCatalogItemIdGet(catalogItemId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProductionStationApi - axios parameter creator
    * @export
    */
    export const ProductionStationApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {ProductionItemOperations} [operation] 
            * @param {Array<string>} [scope] 
            * @param {Array<string>} [filters] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmProductionStationStatGet: async (operation?: ProductionItemOperations, scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/production-station/stat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (operation !== undefined) {
                                        localVarQueryParameter['operation'] = operation;
                    }

                    if (scope) {
                            localVarQueryParameter['scope'] = scope;
                    }

                    if (filters) {
                            localVarQueryParameter['filters'] = filters;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProductionStationApi - functional programming interface
        * @export
        */
        export const ProductionStationApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProductionStationApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {ProductionItemOperations} [operation] 
                * @param {Array<string>} [scope] 
                * @param {Array<string>} [filters] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmProductionStationStatGet(operation?: ProductionItemOperations, scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionItemRepresentationFacetedSearchResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmProductionStationStatGet(operation, scope, filters, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductionStationApi.pmProductionStationStatGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProductionStationApi - factory interface
        * @export
        */
        export const ProductionStationApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProductionStationApiFp(configuration)
        return {
            /**
            * 
                    * @param {ProductionItemOperations} [operation] 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmProductionStationStatGet(operation?: ProductionItemOperations, scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: any): AxiosPromise<ProductionItemRepresentationFacetedSearchResultList> {
                return localVarFp.pmProductionStationStatGet(operation, scope, filters, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProductionStationApi - interface
            * @export
            * @interface ProductionStationApi
            */
            export interface ProductionStationApiInterface {
                /**
                * 
                    * @param {ProductionItemOperations} [operation] 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProductionStationApiInterface
                */
            pmProductionStationStatGet(operation?: ProductionItemOperations, scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionItemRepresentationFacetedSearchResultList>;

            }

        /**
        * ProductionStationApi - object-oriented interface
        * @export
        * @class ProductionStationApi
        * @extends {BaseAPI}
        */
            export class ProductionStationApi extends BaseAPI implements ProductionStationApiInterface {
            /**
            * 
                    * @param {ProductionItemOperations} [operation] 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProductionStationApi
            */
                public pmProductionStationStatGet(operation?: ProductionItemOperations, scope?: Array<string>, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProductionStationApiFp(this.configuration).pmProductionStationStatGet(operation, scope, filters, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProfileApi - axios parameter creator
    * @export
    */
    export const ProfileApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        bulkActivateProfiles: async (apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        bulkDeactivateProfiles: async (apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles/deactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        bulkPreviewProfiles: async (apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles/bulk-preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateProfileBulkActiveRequest} [updateProfileBulkActiveRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        bulkUpdateProfiles: async (apiVersion?: string, updateProfileBulkActiveRequest?: UpdateProfileBulkActiveRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateProfileBulkActiveRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<CreateProfileRequestInner>} [createProfileRequestInner] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        createProfile: async (apiVersion?: string, createProfileRequestInner?: Array<CreateProfileRequestInner>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createProfileRequestInner, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteProfile: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('deleteProfile', 'id', id)
            const localVarPath = `/pim/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getProfile: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getProfile', 'id', id)
            const localVarPath = `/pim/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {Array<string>} [scope] 
            * @param {Array<string>} [filters] 
            * @param {boolean} [active] 
            * @param {boolean} [hasProgram] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        listProfiles: async (scope?: Array<string>, filters?: Array<string>, active?: boolean, hasProgram?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (scope) {
                            localVarQueryParameter['scope'] = scope;
                    }

                    if (filters) {
                            localVarQueryParameter['filters'] = filters;
                    }

                    if (active !== undefined) {
                                    localVarQueryParameter['active'] = active;
                    }

                    if (hasProgram !== undefined) {
                                    localVarQueryParameter['hasProgram'] = hasProgram;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateProfileRequest} [updateProfileRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        updateProfile: async (id: string, apiVersion?: string, updateProfileRequest?: UpdateProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('updateProfile', 'id', id)
            const localVarPath = `/pim/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateProfileRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProfileApi - functional programming interface
        * @export
        */
        export const ProfileApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async bulkActivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkActivateProfiles(apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.bulkActivateProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async bulkDeactivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDeactivateProfiles(apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.bulkDeactivateProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async bulkPreviewProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkPreviewProfiles(apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.bulkPreviewProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateProfileBulkActiveRequest} [updateProfileBulkActiveRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async bulkUpdateProfiles(apiVersion?: string, updateProfileBulkActiveRequest?: UpdateProfileBulkActiveRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateProfiles(apiVersion, updateProfileBulkActiveRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.bulkUpdateProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<CreateProfileRequestInner>} [createProfileRequestInner] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async createProfile(apiVersion?: string, createProfileRequestInner?: Array<CreateProfileRequestInner>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProfileInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfile(apiVersion, createProfileRequestInner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.createProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteProfile(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.deleteProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getProfile(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.getProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {Array<string>} [scope] 
                * @param {Array<string>} [filters] 
                * @param {boolean} [active] 
                * @param {boolean} [hasProgram] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async listProfiles(scope?: Array<string>, filters?: Array<string>, active?: boolean, hasProgram?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProfiles(scope, filters, active, hasProgram, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.listProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateProfileRequest} [updateProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async updateProfile(id: string, apiVersion?: string, updateProfileRequest?: UpdateProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(id, apiVersion, updateProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.updateProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProfileApi - factory interface
        * @export
        */
        export const ProfileApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProfileApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            bulkActivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
                return localVarFp.bulkActivateProfiles(apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            bulkDeactivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
                return localVarFp.bulkDeactivateProfiles(apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            bulkPreviewProfiles(apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<CountMessage> {
                return localVarFp.bulkPreviewProfiles(apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateProfileBulkActiveRequest} [updateProfileBulkActiveRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            bulkUpdateProfiles(apiVersion?: string, updateProfileBulkActiveRequest?: UpdateProfileBulkActiveRequest, options?: any): AxiosPromise<void> {
                return localVarFp.bulkUpdateProfiles(apiVersion, updateProfileBulkActiveRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<CreateProfileRequestInner>} [createProfileRequestInner] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            createProfile(apiVersion?: string, createProfileRequestInner?: Array<CreateProfileRequestInner>, options?: any): AxiosPromise<Array<IProfileInfo>> {
                return localVarFp.createProfile(apiVersion, createProfileRequestInner, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteProfile(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deleteProfile(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getProfile(id: string, apiVersion?: string, options?: any): AxiosPromise<GetProfile200Response> {
                return localVarFp.getProfile(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {boolean} [active] 
                    * @param {boolean} [hasProgram] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            listProfiles(scope?: Array<string>, filters?: Array<string>, active?: boolean, hasProgram?: boolean, apiVersion?: string, options?: any): AxiosPromise<ProfileSearchResult> {
                return localVarFp.listProfiles(scope, filters, active, hasProgram, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateProfileRequest} [updateProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            updateProfile(id: string, apiVersion?: string, updateProfileRequest?: UpdateProfileRequest, options?: any): AxiosPromise<GetProfile200Response> {
                return localVarFp.updateProfile(id, apiVersion, updateProfileRequest, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProfileApi - interface
            * @export
            * @interface ProfileApi
            */
            export interface ProfileApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            bulkActivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            bulkDeactivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            bulkPreviewProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<CountMessage>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateProfileBulkActiveRequest} [updateProfileBulkActiveRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            bulkUpdateProfiles(apiVersion?: string, updateProfileBulkActiveRequest?: UpdateProfileBulkActiveRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<CreateProfileRequestInner>} [createProfileRequestInner] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            createProfile(apiVersion?: string, createProfileRequestInner?: Array<CreateProfileRequestInner>, options?: RawAxiosRequestConfig): AxiosPromise<Array<IProfileInfo>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            deleteProfile(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            getProfile(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProfile200Response>;

                /**
                * 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {boolean} [active] 
                    * @param {boolean} [hasProgram] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            listProfiles(scope?: Array<string>, filters?: Array<string>, active?: boolean, hasProgram?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProfileSearchResult>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateProfileRequest} [updateProfileRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileApiInterface
                */
            updateProfile(id: string, apiVersion?: string, updateProfileRequest?: UpdateProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetProfile200Response>;

            }

        /**
        * ProfileApi - object-oriented interface
        * @export
        * @class ProfileApi
        * @extends {BaseAPI}
        */
            export class ProfileApi extends BaseAPI implements ProfileApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public bulkActivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).bulkActivateProfiles(apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public bulkDeactivateProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).bulkDeactivateProfiles(apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public bulkPreviewProfiles(apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).bulkPreviewProfiles(apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateProfileBulkActiveRequest} [updateProfileBulkActiveRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public bulkUpdateProfiles(apiVersion?: string, updateProfileBulkActiveRequest?: UpdateProfileBulkActiveRequest, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).bulkUpdateProfiles(apiVersion, updateProfileBulkActiveRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<CreateProfileRequestInner>} [createProfileRequestInner] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public createProfile(apiVersion?: string, createProfileRequestInner?: Array<CreateProfileRequestInner>, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).createProfile(apiVersion, createProfileRequestInner, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public deleteProfile(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).deleteProfile(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public getProfile(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).getProfile(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {Array<string>} [scope] 
                    * @param {Array<string>} [filters] 
                    * @param {boolean} [active] 
                    * @param {boolean} [hasProgram] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public listProfiles(scope?: Array<string>, filters?: Array<string>, active?: boolean, hasProgram?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).listProfiles(scope, filters, active, hasProgram, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateProfileRequest} [updateProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileApi
            */
                public updateProfile(id: string, apiVersion?: string, updateProfileRequest?: UpdateProfileRequest, options?: RawAxiosRequestConfig) {
                return ProfileApiFp(this.configuration).updateProfile(id, apiVersion, updateProfileRequest, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProfileGenerationApi - axios parameter creator
    * @export
    */
    export const ProfileGenerationApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {Array<string>} [rabbetIds] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        generate: async (rabbetIds?: Array<string>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (rabbetIds) {
                            localVarQueryParameter['rabbetIds'] = rabbetIds;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getCompartmentProfileGenerationOptions: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles/generate/compartment-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {PreviewCompartmentProfileGenerationCommand} [previewCompartmentProfileGenerationCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        previewCompartmentProfileGeneration: async (apiVersion?: string, previewCompartmentProfileGenerationCommand?: PreviewCompartmentProfileGenerationCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/profiles/generate/compartment-profile/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(previewCompartmentProfileGenerationCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProfileGenerationApi - functional programming interface
        * @export
        */
        export const ProfileGenerationApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProfileGenerationApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {Array<string>} [rabbetIds] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async generate(rabbetIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generate(rabbetIds, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileGenerationApi.generate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getCompartmentProfileGenerationOptions(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompartmentProfileGenerationOptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompartmentProfileGenerationOptions(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileGenerationApi.getCompartmentProfileGenerationOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {PreviewCompartmentProfileGenerationCommand} [previewCompartmentProfileGenerationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async previewCompartmentProfileGeneration(apiVersion?: string, previewCompartmentProfileGenerationCommand?: PreviewCompartmentProfileGenerationCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompartmentProfileGenerationPreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewCompartmentProfileGeneration(apiVersion, previewCompartmentProfileGenerationCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileGenerationApi.previewCompartmentProfileGeneration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProfileGenerationApi - factory interface
        * @export
        */
        export const ProfileGenerationApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProfileGenerationApiFp(configuration)
        return {
            /**
            * 
                    * @param {Array<string>} [rabbetIds] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            generate(rabbetIds?: Array<string>, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.generate(rabbetIds, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getCompartmentProfileGenerationOptions(apiVersion?: string, options?: any): AxiosPromise<CompartmentProfileGenerationOptions> {
                return localVarFp.getCompartmentProfileGenerationOptions(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PreviewCompartmentProfileGenerationCommand} [previewCompartmentProfileGenerationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            previewCompartmentProfileGeneration(apiVersion?: string, previewCompartmentProfileGenerationCommand?: PreviewCompartmentProfileGenerationCommand, options?: any): AxiosPromise<Array<CompartmentProfileGenerationPreview>> {
                return localVarFp.previewCompartmentProfileGeneration(apiVersion, previewCompartmentProfileGenerationCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProfileGenerationApi - interface
            * @export
            * @interface ProfileGenerationApi
            */
            export interface ProfileGenerationApiInterface {
                /**
                * 
                    * @param {Array<string>} [rabbetIds] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileGenerationApiInterface
                */
            generate(rabbetIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileGenerationApiInterface
                */
            getCompartmentProfileGenerationOptions(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CompartmentProfileGenerationOptions>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PreviewCompartmentProfileGenerationCommand} [previewCompartmentProfileGenerationCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileGenerationApiInterface
                */
            previewCompartmentProfileGeneration(apiVersion?: string, previewCompartmentProfileGenerationCommand?: PreviewCompartmentProfileGenerationCommand, options?: RawAxiosRequestConfig): AxiosPromise<Array<CompartmentProfileGenerationPreview>>;

            }

        /**
        * ProfileGenerationApi - object-oriented interface
        * @export
        * @class ProfileGenerationApi
        * @extends {BaseAPI}
        */
            export class ProfileGenerationApi extends BaseAPI implements ProfileGenerationApiInterface {
            /**
            * 
                    * @param {Array<string>} [rabbetIds] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileGenerationApi
            */
                public generate(rabbetIds?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProfileGenerationApiFp(this.configuration).generate(rabbetIds, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileGenerationApi
            */
                public getCompartmentProfileGenerationOptions(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ProfileGenerationApiFp(this.configuration).getCompartmentProfileGenerationOptions(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {PreviewCompartmentProfileGenerationCommand} [previewCompartmentProfileGenerationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileGenerationApi
            */
                public previewCompartmentProfileGeneration(apiVersion?: string, previewCompartmentProfileGenerationCommand?: PreviewCompartmentProfileGenerationCommand, options?: RawAxiosRequestConfig) {
                return ProfileGenerationApiFp(this.configuration).previewCompartmentProfileGeneration(apiVersion, previewCompartmentProfileGenerationCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ProfileTagsApi - axios parameter creator
    * @export
    */
    export const ProfileTagsApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {{ [key: string]: string; }} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimProfilesIdTagsPut: async (id: string, apiVersion?: string, requestBody?: { [key: string]: string; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimProfilesIdTagsPut', 'id', id)
            const localVarPath = `/pim/profiles/{id}/tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ProfileTagsApi - functional programming interface
        * @export
        */
        export const ProfileTagsApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ProfileTagsApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {{ [key: string]: string; }} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimProfilesIdTagsPut(id: string, apiVersion?: string, requestBody?: { [key: string]: string; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimProfilesIdTagsPut(id, apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileTagsApi.pimProfilesIdTagsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ProfileTagsApi - factory interface
        * @export
        */
        export const ProfileTagsApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ProfileTagsApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {{ [key: string]: string; }} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimProfilesIdTagsPut(id: string, apiVersion?: string, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<GetProfile200Response> {
                return localVarFp.pimProfilesIdTagsPut(id, apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ProfileTagsApi - interface
            * @export
            * @interface ProfileTagsApi
            */
            export interface ProfileTagsApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {{ [key: string]: string; }} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ProfileTagsApiInterface
                */
            pimProfilesIdTagsPut(id: string, apiVersion?: string, requestBody?: { [key: string]: string; }, options?: RawAxiosRequestConfig): AxiosPromise<GetProfile200Response>;

            }

        /**
        * ProfileTagsApi - object-oriented interface
        * @export
        * @class ProfileTagsApi
        * @extends {BaseAPI}
        */
            export class ProfileTagsApi extends BaseAPI implements ProfileTagsApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {{ [key: string]: string; }} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ProfileTagsApi
            */
                public pimProfilesIdTagsPut(id: string, apiVersion?: string, requestBody?: { [key: string]: string; }, options?: RawAxiosRequestConfig) {
                return ProfileTagsApiFp(this.configuration).pimProfilesIdTagsPut(id, apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * RabbetApi - axios parameter creator
    * @export
    */
    export const RabbetApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateRabbetCommand} [createRabbetCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        createRabbet: async (apiVersion?: string, createRabbetCommand?: CreateRabbetCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/rabbets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createRabbetCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteRabbet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('deleteRabbet', 'id', id)
            const localVarPath = `/pim/rabbets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getRabbet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getRabbet', 'id', id)
            const localVarPath = `/pim/rabbets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getWidths: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/rabbets/widths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {Array<RabbetType>} [rabbetTypes] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        listRabbets: async (rabbetTypes?: Array<RabbetType>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/rabbets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (rabbetTypes) {
                            localVarQueryParameter['rabbetTypes'] = rabbetTypes;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {RabbetUpdateModel} [rabbetUpdateModel] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        updateRabbet: async (id: string, apiVersion?: string, rabbetUpdateModel?: RabbetUpdateModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('updateRabbet', 'id', id)
            const localVarPath = `/pim/rabbets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(rabbetUpdateModel, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * RabbetApi - functional programming interface
        * @export
        */
        export const RabbetApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = RabbetApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateRabbetCommand} [createRabbetCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async createRabbet(apiVersion?: string, createRabbetCommand?: CreateRabbetCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rabbet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRabbet(apiVersion, createRabbetCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RabbetApi.createRabbet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteRabbet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRabbet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RabbetApi.deleteRabbet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getRabbet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rabbet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRabbet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RabbetApi.getRabbet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getWidths(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWidths(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RabbetApi.getWidths']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {Array<RabbetType>} [rabbetTypes] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async listRabbets(rabbetTypes?: Array<RabbetType>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rabbet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRabbets(rabbetTypes, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RabbetApi.listRabbets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {RabbetUpdateModel} [rabbetUpdateModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async updateRabbet(id: string, apiVersion?: string, rabbetUpdateModel?: RabbetUpdateModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rabbet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRabbet(id, apiVersion, rabbetUpdateModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RabbetApi.updateRabbet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * RabbetApi - factory interface
        * @export
        */
        export const RabbetApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = RabbetApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateRabbetCommand} [createRabbetCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            createRabbet(apiVersion?: string, createRabbetCommand?: CreateRabbetCommand, options?: any): AxiosPromise<Rabbet> {
                return localVarFp.createRabbet(apiVersion, createRabbetCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteRabbet(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deleteRabbet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getRabbet(id: string, apiVersion?: string, options?: any): AxiosPromise<Rabbet> {
                return localVarFp.getRabbet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getWidths(apiVersion?: string, options?: any): AxiosPromise<Array<number>> {
                return localVarFp.getWidths(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {Array<RabbetType>} [rabbetTypes] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            listRabbets(rabbetTypes?: Array<RabbetType>, apiVersion?: string, options?: any): AxiosPromise<Array<Rabbet>> {
                return localVarFp.listRabbets(rabbetTypes, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {RabbetUpdateModel} [rabbetUpdateModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            updateRabbet(id: string, apiVersion?: string, rabbetUpdateModel?: RabbetUpdateModel, options?: any): AxiosPromise<Rabbet> {
                return localVarFp.updateRabbet(id, apiVersion, rabbetUpdateModel, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * RabbetApi - interface
            * @export
            * @interface RabbetApi
            */
            export interface RabbetApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateRabbetCommand} [createRabbetCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RabbetApiInterface
                */
            createRabbet(apiVersion?: string, createRabbetCommand?: CreateRabbetCommand, options?: RawAxiosRequestConfig): AxiosPromise<Rabbet>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RabbetApiInterface
                */
            deleteRabbet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RabbetApiInterface
                */
            getRabbet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Rabbet>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RabbetApiInterface
                */
            getWidths(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<number>>;

                /**
                * 
                    * @param {Array<RabbetType>} [rabbetTypes] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RabbetApiInterface
                */
            listRabbets(rabbetTypes?: Array<RabbetType>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Rabbet>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {RabbetUpdateModel} [rabbetUpdateModel] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RabbetApiInterface
                */
            updateRabbet(id: string, apiVersion?: string, rabbetUpdateModel?: RabbetUpdateModel, options?: RawAxiosRequestConfig): AxiosPromise<Rabbet>;

            }

        /**
        * RabbetApi - object-oriented interface
        * @export
        * @class RabbetApi
        * @extends {BaseAPI}
        */
            export class RabbetApi extends BaseAPI implements RabbetApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateRabbetCommand} [createRabbetCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RabbetApi
            */
                public createRabbet(apiVersion?: string, createRabbetCommand?: CreateRabbetCommand, options?: RawAxiosRequestConfig) {
                return RabbetApiFp(this.configuration).createRabbet(apiVersion, createRabbetCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RabbetApi
            */
                public deleteRabbet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RabbetApiFp(this.configuration).deleteRabbet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RabbetApi
            */
                public getRabbet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RabbetApiFp(this.configuration).getRabbet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RabbetApi
            */
                public getWidths(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RabbetApiFp(this.configuration).getWidths(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {Array<RabbetType>} [rabbetTypes] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RabbetApi
            */
                public listRabbets(rabbetTypes?: Array<RabbetType>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RabbetApiFp(this.configuration).listRabbets(rabbetTypes, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {RabbetUpdateModel} [rabbetUpdateModel] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RabbetApi
            */
                public updateRabbet(id: string, apiVersion?: string, rabbetUpdateModel?: RabbetUpdateModel, options?: RawAxiosRequestConfig) {
                return RabbetApiFp(this.configuration).updateRabbet(id, apiVersion, rabbetUpdateModel, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * RenderApi - axios parameter creator
    * @export
    */
    export const RenderApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [id] 
            * @param {boolean} [embed] 
            * @param {boolean} [debug] 
            * @param {boolean} [document] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderConfiguredSillCatalogItemGet: async (id?: string, embed?: boolean, debug?: boolean, document?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/configured-sill/catalog-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (id !== undefined) {
                                    localVarQueryParameter['id'] = id;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (document !== undefined) {
                                    localVarQueryParameter['document'] = document;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [id] 
            * @param {boolean} [embed] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderConfiguredSillGet: async (id?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/configured-sill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (id !== undefined) {
                                    localVarQueryParameter['id'] = id;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [geometry] 
            * @param {boolean} [embed] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderGeoGet: async (geometry?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/geo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (geometry !== undefined) {
                                    localVarQueryParameter['geometry'] = geometry;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [geometry] 
            * @param {number} [neutWidth] 
            * @param {boolean} [embed] 
            * @param {number} [w] 
            * @param {number} [h] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderPreviewProfileGet: async (geometry?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/preview/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (geometry !== undefined) {
                                    localVarQueryParameter['geometry'] = geometry;
                    }

                    if (neutWidth !== undefined) {
                                    localVarQueryParameter['neutWidth'] = neutWidth;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (w !== undefined) {
                                    localVarQueryParameter['w'] = w;
                    }

                    if (h !== undefined) {
                                    localVarQueryParameter['h'] = h;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [geometryA] 
            * @param {string} [geometryB] 
            * @param {number} [neutWidth] 
            * @param {boolean} [embed] 
            * @param {number} [w] 
            * @param {number} [h] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderPreviewProfileMatchGet: async (geometryA?: string, geometryB?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/preview/profileMatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (geometryA !== undefined) {
                                    localVarQueryParameter['geometryA'] = geometryA;
                    }

                    if (geometryB !== undefined) {
                                    localVarQueryParameter['geometryB'] = geometryB;
                    }

                    if (neutWidth !== undefined) {
                                    localVarQueryParameter['neutWidth'] = neutWidth;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (w !== undefined) {
                                    localVarQueryParameter['w'] = w;
                    }

                    if (h !== undefined) {
                                    localVarQueryParameter['h'] = h;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [geometry] 
            * @param {boolean} [embed] 
            * @param {number} [w] 
            * @param {number} [h] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderPreviewRabbetGet: async (geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/preview/rabbet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (geometry !== undefined) {
                                    localVarQueryParameter['geometry'] = geometry;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (w !== undefined) {
                                    localVarQueryParameter['w'] = w;
                    }

                    if (h !== undefined) {
                                    localVarQueryParameter['h'] = h;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [geometry] 
            * @param {number} [defaultNeutHeight] 
            * @param {boolean} [embed] 
            * @param {number} [w] 
            * @param {number} [h] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderPreviewSillContraGet: async (geometry?: string, defaultNeutHeight?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/preview/sill-contra`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (geometry !== undefined) {
                                    localVarQueryParameter['geometry'] = geometry;
                    }

                    if (defaultNeutHeight !== undefined) {
                                    localVarQueryParameter['defaultNeutHeight'] = defaultNeutHeight;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (w !== undefined) {
                                    localVarQueryParameter['w'] = w;
                    }

                    if (h !== undefined) {
                                    localVarQueryParameter['h'] = h;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [geometry] 
            * @param {boolean} [embed] 
            * @param {number} [w] 
            * @param {number} [h] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderPreviewSluitpotGet: async (geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/preview/sluitpot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (geometry !== undefined) {
                                    localVarQueryParameter['geometry'] = geometry;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (w !== undefined) {
                                    localVarQueryParameter['w'] = w;
                    }

                    if (h !== undefined) {
                                    localVarQueryParameter['h'] = h;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {number} [sillWidth] 
            * @param {boolean} [right] 
            * @param {boolean} [embed] 
            * @param {boolean} [debug] 
            * @param {string} [apiVersion] The requested API version
            * @param {RenderPreviewWallConnectionPostRequest} [renderPreviewWallConnectionPostRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        renderPreviewWallConnectionPost: async (sillWidth?: number, right?: boolean, embed?: boolean, debug?: boolean, apiVersion?: string, renderPreviewWallConnectionPostRequest?: RenderPreviewWallConnectionPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/render/preview/wall-connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (sillWidth !== undefined) {
                                    localVarQueryParameter['sillWidth'] = sillWidth;
                    }

                    if (right !== undefined) {
                                    localVarQueryParameter['right'] = right;
                    }

                    if (embed !== undefined) {
                                    localVarQueryParameter['embed'] = embed;
                    }

                    if (debug !== undefined) {
                                    localVarQueryParameter['debug'] = debug;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(renderPreviewWallConnectionPostRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * RenderApi - functional programming interface
        * @export
        */
        export const RenderApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = RenderApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [id] 
                * @param {boolean} [embed] 
                * @param {boolean} [debug] 
                * @param {boolean} [document] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderConfiguredSillCatalogItemGet(id?: string, embed?: boolean, debug?: boolean, document?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderConfiguredSillCatalogItemGet(id, embed, debug, document, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderConfiguredSillCatalogItemGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [id] 
                * @param {boolean} [embed] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderConfiguredSillGet(id?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderConfiguredSillGet(id, embed, debug, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderConfiguredSillGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [geometry] 
                * @param {boolean} [embed] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderGeoGet(geometry?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderGeoGet(geometry, embed, debug, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderGeoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [geometry] 
                * @param {number} [neutWidth] 
                * @param {boolean} [embed] 
                * @param {number} [w] 
                * @param {number} [h] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderPreviewProfileGet(geometry?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderPreviewProfileGet(geometry, neutWidth, embed, w, h, debug, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderPreviewProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [geometryA] 
                * @param {string} [geometryB] 
                * @param {number} [neutWidth] 
                * @param {boolean} [embed] 
                * @param {number} [w] 
                * @param {number} [h] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderPreviewProfileMatchGet(geometryA?: string, geometryB?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderPreviewProfileMatchGet(geometryA, geometryB, neutWidth, embed, w, h, debug, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderPreviewProfileMatchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [geometry] 
                * @param {boolean} [embed] 
                * @param {number} [w] 
                * @param {number} [h] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderPreviewRabbetGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderPreviewRabbetGet(geometry, embed, w, h, debug, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderPreviewRabbetGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [geometry] 
                * @param {number} [defaultNeutHeight] 
                * @param {boolean} [embed] 
                * @param {number} [w] 
                * @param {number} [h] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderPreviewSillContraGet(geometry?: string, defaultNeutHeight?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderPreviewSillContraGet(geometry, defaultNeutHeight, embed, w, h, debug, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderPreviewSillContraGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [geometry] 
                * @param {boolean} [embed] 
                * @param {number} [w] 
                * @param {number} [h] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderPreviewSluitpotGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderPreviewSluitpotGet(geometry, embed, w, h, debug, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderPreviewSluitpotGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {number} [sillWidth] 
                * @param {boolean} [right] 
                * @param {boolean} [embed] 
                * @param {boolean} [debug] 
                * @param {string} [apiVersion] The requested API version
                * @param {RenderPreviewWallConnectionPostRequest} [renderPreviewWallConnectionPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async renderPreviewWallConnectionPost(sillWidth?: number, right?: boolean, embed?: boolean, debug?: boolean, apiVersion?: string, renderPreviewWallConnectionPostRequest?: RenderPreviewWallConnectionPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderPreviewWallConnectionPost(sillWidth, right, embed, debug, apiVersion, renderPreviewWallConnectionPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RenderApi.renderPreviewWallConnectionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * RenderApi - factory interface
        * @export
        */
        export const RenderApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = RenderApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [id] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {boolean} [document] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderConfiguredSillCatalogItemGet(id?: string, embed?: boolean, debug?: boolean, document?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderConfiguredSillCatalogItemGet(id, embed, debug, document, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [id] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderConfiguredSillGet(id?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderConfiguredSillGet(id, embed, debug, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderGeoGet(geometry?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderGeoGet(geometry, embed, debug, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [geometry] 
                    * @param {number} [neutWidth] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderPreviewProfileGet(geometry?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderPreviewProfileGet(geometry, neutWidth, embed, w, h, debug, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [geometryA] 
                    * @param {string} [geometryB] 
                    * @param {number} [neutWidth] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderPreviewProfileMatchGet(geometryA?: string, geometryB?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderPreviewProfileMatchGet(geometryA, geometryB, neutWidth, embed, w, h, debug, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderPreviewRabbetGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderPreviewRabbetGet(geometry, embed, w, h, debug, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [geometry] 
                    * @param {number} [defaultNeutHeight] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderPreviewSillContraGet(geometry?: string, defaultNeutHeight?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderPreviewSillContraGet(geometry, defaultNeutHeight, embed, w, h, debug, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderPreviewSluitpotGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: any): AxiosPromise<string> {
                return localVarFp.renderPreviewSluitpotGet(geometry, embed, w, h, debug, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {number} [sillWidth] 
                    * @param {boolean} [right] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {RenderPreviewWallConnectionPostRequest} [renderPreviewWallConnectionPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            renderPreviewWallConnectionPost(sillWidth?: number, right?: boolean, embed?: boolean, debug?: boolean, apiVersion?: string, renderPreviewWallConnectionPostRequest?: RenderPreviewWallConnectionPostRequest, options?: any): AxiosPromise<string> {
                return localVarFp.renderPreviewWallConnectionPost(sillWidth, right, embed, debug, apiVersion, renderPreviewWallConnectionPostRequest, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * RenderApi - interface
            * @export
            * @interface RenderApi
            */
            export interface RenderApiInterface {
                /**
                * 
                    * @param {string} [id] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {boolean} [document] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderConfiguredSillCatalogItemGet(id?: string, embed?: boolean, debug?: boolean, document?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [id] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderConfiguredSillGet(id?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderGeoGet(geometry?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [geometry] 
                    * @param {number} [neutWidth] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderPreviewProfileGet(geometry?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [geometryA] 
                    * @param {string} [geometryB] 
                    * @param {number} [neutWidth] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderPreviewProfileMatchGet(geometryA?: string, geometryB?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderPreviewRabbetGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [geometry] 
                    * @param {number} [defaultNeutHeight] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderPreviewSillContraGet(geometry?: string, defaultNeutHeight?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderPreviewSluitpotGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

                /**
                * 
                    * @param {number} [sillWidth] 
                    * @param {boolean} [right] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {RenderPreviewWallConnectionPostRequest} [renderPreviewWallConnectionPostRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof RenderApiInterface
                */
            renderPreviewWallConnectionPost(sillWidth?: number, right?: boolean, embed?: boolean, debug?: boolean, apiVersion?: string, renderPreviewWallConnectionPostRequest?: RenderPreviewWallConnectionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

            }

        /**
        * RenderApi - object-oriented interface
        * @export
        * @class RenderApi
        * @extends {BaseAPI}
        */
            export class RenderApi extends BaseAPI implements RenderApiInterface {
            /**
            * 
                    * @param {string} [id] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {boolean} [document] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderConfiguredSillCatalogItemGet(id?: string, embed?: boolean, debug?: boolean, document?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderConfiguredSillCatalogItemGet(id, embed, debug, document, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [id] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderConfiguredSillGet(id?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderConfiguredSillGet(id, embed, debug, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderGeoGet(geometry?: string, embed?: boolean, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderGeoGet(geometry, embed, debug, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [geometry] 
                    * @param {number} [neutWidth] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderPreviewProfileGet(geometry?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderPreviewProfileGet(geometry, neutWidth, embed, w, h, debug, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [geometryA] 
                    * @param {string} [geometryB] 
                    * @param {number} [neutWidth] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderPreviewProfileMatchGet(geometryA?: string, geometryB?: string, neutWidth?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderPreviewProfileMatchGet(geometryA, geometryB, neutWidth, embed, w, h, debug, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderPreviewRabbetGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderPreviewRabbetGet(geometry, embed, w, h, debug, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [geometry] 
                    * @param {number} [defaultNeutHeight] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderPreviewSillContraGet(geometry?: string, defaultNeutHeight?: number, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderPreviewSillContraGet(geometry, defaultNeutHeight, embed, w, h, debug, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [geometry] 
                    * @param {boolean} [embed] 
                    * @param {number} [w] 
                    * @param {number} [h] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderPreviewSluitpotGet(geometry?: string, embed?: boolean, w?: number, h?: number, debug?: boolean, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderPreviewSluitpotGet(geometry, embed, w, h, debug, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {number} [sillWidth] 
                    * @param {boolean} [right] 
                    * @param {boolean} [embed] 
                    * @param {boolean} [debug] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {RenderPreviewWallConnectionPostRequest} [renderPreviewWallConnectionPostRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof RenderApi
            */
                public renderPreviewWallConnectionPost(sillWidth?: number, right?: boolean, embed?: boolean, debug?: boolean, apiVersion?: string, renderPreviewWallConnectionPostRequest?: RenderPreviewWallConnectionPostRequest, options?: RawAxiosRequestConfig) {
                return RenderApiFp(this.configuration).renderPreviewWallConnectionPost(sillWidth, right, embed, debug, apiVersion, renderPreviewWallConnectionPostRequest, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ReportApi - axios parameter creator
    * @export
    */
    export const ReportApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        weeklyOrdersPlacedByStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/report/weekly-orders-placed-by-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        weeklySalesSillStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/report/weekly-sales-sill-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        weeklySalesStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/report/weekly-sales-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ReportApi - functional programming interface
        * @export
        */
        export const ReportApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async weeklyOrdersPlacedByStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: WeeklyOrdersPlacedByStatsRow; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weeklyOrdersPlacedByStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.weeklyOrdersPlacedByStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async weeklySalesSillStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: WeeklySalesSillStatsRow; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weeklySalesSillStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.weeklySalesSillStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async weeklySalesStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: WeeklySalesStatsRow; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weeklySalesStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.weeklySalesStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ReportApi - factory interface
        * @export
        */
        export const ReportApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ReportApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            weeklyOrdersPlacedByStats(apiVersion?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: WeeklyOrdersPlacedByStatsRow; }; }> {
                return localVarFp.weeklyOrdersPlacedByStats(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            weeklySalesSillStats(apiVersion?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: WeeklySalesSillStatsRow; }; }> {
                return localVarFp.weeklySalesSillStats(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            weeklySalesStats(apiVersion?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: WeeklySalesStatsRow; }; }> {
                return localVarFp.weeklySalesStats(apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ReportApi - interface
            * @export
            * @interface ReportApi
            */
            export interface ReportApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportApiInterface
                */
            weeklyOrdersPlacedByStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: WeeklyOrdersPlacedByStatsRow; }; }>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportApiInterface
                */
            weeklySalesSillStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: WeeklySalesSillStatsRow; }; }>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportApiInterface
                */
            weeklySalesStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: WeeklySalesStatsRow; }; }>;

            }

        /**
        * ReportApi - object-oriented interface
        * @export
        * @class ReportApi
        * @extends {BaseAPI}
        */
            export class ReportApi extends BaseAPI implements ReportApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportApi
            */
                public weeklyOrdersPlacedByStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportApiFp(this.configuration).weeklyOrdersPlacedByStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportApi
            */
                public weeklySalesSillStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportApiFp(this.configuration).weeklySalesSillStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportApi
            */
                public weeklySalesStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportApiFp(this.configuration).weeklySalesStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ReportingApi - axios parameter creator
    * @export
    */
    export const ReportingApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [id] 
            * @param {number} [year] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        customer: async (id?: string, year?: number, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/reporting/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (id !== undefined) {
                                    localVarQueryParameter['id'] = id;
                    }

                    if (year !== undefined) {
                                    localVarQueryParameter['year'] = year;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        customerMap: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/reporting/customer-map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [dateStart] 
            * @param {string} [dateEnd] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        dailyProductionAnalytics: async (dateStart?: string, dateEnd?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/reporting/dashboard/daily-production-analytics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (dateStart !== undefined) {
                            localVarQueryParameter['DateStart'] = (dateStart as any instanceof Date) ?
                            (dateStart as any).toISOString().substring(0,10) :
                        dateStart;
                    }

                    if (dateEnd !== undefined) {
                            localVarQueryParameter['DateEnd'] = (dateEnd as any instanceof Date) ?
                            (dateEnd as any).toISOString().substring(0,10) :
                        dateEnd;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        dailyProductionGraphStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/reporting/dashboard/daily-production-graph-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        monthlySalesStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/reporting/monthly-sales-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [dateStart] 
            * @param {string} [dateEnd] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        productionDashboardStats: async (dateStart?: string, dateEnd?: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/reporting/dashboard/production-dashboard-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (dateStart !== undefined) {
                            localVarQueryParameter['DateStart'] = (dateStart as any instanceof Date) ?
                            (dateStart as any).toISOString().substring(0,10) :
                        dateStart;
                    }

                    if (dateEnd !== undefined) {
                            localVarQueryParameter['DateEnd'] = (dateEnd as any instanceof Date) ?
                            (dateEnd as any).toISOString().substring(0,10) :
                        dateEnd;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        weeklyExpeditionStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/reporting/weekly-expedition-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        weeklyLevertijdStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/em/reporting/weekly-levertijd-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        weeklyProductionStats: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/reporting/dashboard/weekly-production-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ReportingApi - functional programming interface
        * @export
        */
        export const ReportingApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ReportingApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [id] 
                * @param {number} [year] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async customer(id?: string, year?: number, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSillStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customer(id, year, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.customer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async customerMap(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerMapItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerMap(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.customerMap']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [dateStart] 
                * @param {string} [dateEnd] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async dailyProductionAnalytics(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyProductionAnalyticsRow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dailyProductionAnalytics(dateStart, dateEnd, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.dailyProductionAnalytics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async dailyProductionGraphStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyProductionGraphStatsRow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dailyProductionGraphStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.dailyProductionGraphStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async monthlySalesStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: MonthlySalesStatsRow; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monthlySalesStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.monthlySalesStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [dateStart] 
                * @param {string} [dateEnd] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async productionDashboardStats(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionDashboardStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productionDashboardStats(dateStart, dateEnd, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.productionDashboardStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async weeklyExpeditionStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: WeeklyExpeditionStatsRow; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weeklyExpeditionStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.weeklyExpeditionStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async weeklyLevertijdStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: WeeklyLevertijdStatsRow; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weeklyLevertijdStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.weeklyLevertijdStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async weeklyProductionStats(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: WeeklyProductionStatsRow; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weeklyProductionStats(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportingApi.weeklyProductionStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ReportingApi - factory interface
        * @export
        */
        export const ReportingApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ReportingApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [id] 
                    * @param {number} [year] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            customer(id?: string, year?: number, apiVersion?: string, options?: any): AxiosPromise<CustomerSillStats> {
                return localVarFp.customer(id, year, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            customerMap(apiVersion?: string, options?: any): AxiosPromise<Array<CustomerMapItem>> {
                return localVarFp.customerMap(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [dateStart] 
                    * @param {string} [dateEnd] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            dailyProductionAnalytics(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: any): AxiosPromise<Array<DailyProductionAnalyticsRow>> {
                return localVarFp.dailyProductionAnalytics(dateStart, dateEnd, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            dailyProductionGraphStats(apiVersion?: string, options?: any): AxiosPromise<Array<DailyProductionGraphStatsRow>> {
                return localVarFp.dailyProductionGraphStats(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            monthlySalesStats(apiVersion?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: MonthlySalesStatsRow; }; }> {
                return localVarFp.monthlySalesStats(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [dateStart] 
                    * @param {string} [dateEnd] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            productionDashboardStats(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: any): AxiosPromise<ProductionDashboardStats> {
                return localVarFp.productionDashboardStats(dateStart, dateEnd, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            weeklyExpeditionStats(apiVersion?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: WeeklyExpeditionStatsRow; }; }> {
                return localVarFp.weeklyExpeditionStats(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            weeklyLevertijdStats(apiVersion?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: WeeklyLevertijdStatsRow; }; }> {
                return localVarFp.weeklyLevertijdStats(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            weeklyProductionStats(apiVersion?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: WeeklyProductionStatsRow; }; }> {
                return localVarFp.weeklyProductionStats(apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ReportingApi - interface
            * @export
            * @interface ReportingApi
            */
            export interface ReportingApiInterface {
                /**
                * 
                    * @param {string} [id] 
                    * @param {number} [year] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            customer(id?: string, year?: number, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<CustomerSillStats>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            customerMap(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerMapItem>>;

                /**
                * 
                    * @param {string} [dateStart] 
                    * @param {string} [dateEnd] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            dailyProductionAnalytics(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DailyProductionAnalyticsRow>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            dailyProductionGraphStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DailyProductionGraphStatsRow>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            monthlySalesStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: MonthlySalesStatsRow; }; }>;

                /**
                * 
                    * @param {string} [dateStart] 
                    * @param {string} [dateEnd] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            productionDashboardStats(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductionDashboardStats>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            weeklyExpeditionStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: WeeklyExpeditionStatsRow; }; }>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            weeklyLevertijdStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: WeeklyLevertijdStatsRow; }; }>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ReportingApiInterface
                */
            weeklyProductionStats(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: WeeklyProductionStatsRow; }; }>;

            }

        /**
        * ReportingApi - object-oriented interface
        * @export
        * @class ReportingApi
        * @extends {BaseAPI}
        */
            export class ReportingApi extends BaseAPI implements ReportingApiInterface {
            /**
            * 
                    * @param {string} [id] 
                    * @param {number} [year] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public customer(id?: string, year?: number, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).customer(id, year, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public customerMap(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).customerMap(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [dateStart] 
                    * @param {string} [dateEnd] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public dailyProductionAnalytics(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).dailyProductionAnalytics(dateStart, dateEnd, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public dailyProductionGraphStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).dailyProductionGraphStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public monthlySalesStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).monthlySalesStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [dateStart] 
                    * @param {string} [dateEnd] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public productionDashboardStats(dateStart?: string, dateEnd?: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).productionDashboardStats(dateStart, dateEnd, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public weeklyExpeditionStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).weeklyExpeditionStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public weeklyLevertijdStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).weeklyLevertijdStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ReportingApi
            */
                public weeklyProductionStats(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ReportingApiFp(this.configuration).weeklyProductionStats(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * ShipmentApi - axios parameter creator
    * @export
    */
    export const ShipmentApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionShipmentIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('emExpeditionShipmentIdGet', 'id', id)
            const localVarPath = `/em/expedition/shipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        emExpeditionShipmentIdWithPhotoUrlsGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('emExpeditionShipmentIdWithPhotoUrlsGet', 'id', id)
            const localVarPath = `/em/expedition/shipment/{id}/with-photo-urls`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * ShipmentApi - functional programming interface
        * @export
        */
        export const ShipmentApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = ShipmentApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionShipmentIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionShipmentIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentApi.emExpeditionShipmentIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async emExpeditionShipmentIdWithPhotoUrlsGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpeditionRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emExpeditionShipmentIdWithPhotoUrlsGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShipmentApi.emExpeditionShipmentIdWithPhotoUrlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * ShipmentApi - factory interface
        * @export
        */
        export const ShipmentApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = ShipmentApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionShipmentIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ExpeditionRepresentation> {
                return localVarFp.emExpeditionShipmentIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            emExpeditionShipmentIdWithPhotoUrlsGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ExpeditionRepresentation> {
                return localVarFp.emExpeditionShipmentIdWithPhotoUrlsGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * ShipmentApi - interface
            * @export
            * @interface ShipmentApi
            */
            export interface ShipmentApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ShipmentApiInterface
                */
            emExpeditionShipmentIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionRepresentation>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof ShipmentApiInterface
                */
            emExpeditionShipmentIdWithPhotoUrlsGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpeditionRepresentation>;

            }

        /**
        * ShipmentApi - object-oriented interface
        * @export
        * @class ShipmentApi
        * @extends {BaseAPI}
        */
            export class ShipmentApi extends BaseAPI implements ShipmentApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ShipmentApi
            */
                public emExpeditionShipmentIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ShipmentApiFp(this.configuration).emExpeditionShipmentIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof ShipmentApi
            */
                public emExpeditionShipmentIdWithPhotoUrlsGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return ShipmentApiFp(this.configuration).emExpeditionShipmentIdWithPhotoUrlsGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SillApi - axios parameter creator
    * @export
    */
    export const SillApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateSillCommand} [createSillCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        createSill: async (apiVersion?: string, createSillCommand?: CreateSillCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createSillCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteCompartmentLayout: async (sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('deleteCompartmentLayout', 'sillId', sillId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('deleteCompartmentLayout', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/pim/sills/{sillId}/compartment-layouts/{compartmentLayoutTypeId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)))
                .replace(`{${"compartmentLayoutTypeId"}}`, encodeURIComponent(String(compartmentLayoutTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteSill: async (sillId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('deleteSill', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getSill: async (sillId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('getSill', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getSluitpotAreas: async (sillId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('getSluitpotAreas', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}/sluitpot-areas`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        listSills: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSillContraProfileRequest} [updateSillContraProfileRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillsSillIdContraProfilePut: async (sillId: string, apiVersion?: string, updateSillContraProfileRequest?: UpdateSillContraProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('pimSillsSillIdContraProfilePut', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}/contra-profile`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSillContraProfileRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpsertAerationAndDrainageOptionsRequest} [upsertAerationAndDrainageOptionsRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        putAerationAndDrainageOptions: async (sillId: string, apiVersion?: string, upsertAerationAndDrainageOptionsRequest?: UpsertAerationAndDrainageOptionsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('putAerationAndDrainageOptions', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}/aeration-and-drainage-options`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(upsertAerationAndDrainageOptionsRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSillPriceComponentsRequest} [updateSillPriceComponentsRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        putPriceComponents: async (sillId: string, apiVersion?: string, updateSillPriceComponentsRequest?: UpdateSillPriceComponentsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('putPriceComponents', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}/price-components`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSillPriceComponentsRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSillContourProfileRequest} [updateSillContourProfileRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        putSillContourProfile: async (sillId: string, apiVersion?: string, updateSillContourProfileRequest?: UpdateSillContourProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('putSillContourProfile', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}/contour-profile`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSillContourProfileRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSillSluitpotAreasRequest} [updateSillSluitpotAreasRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        putSluitpotAreas: async (sillId: string, apiVersion?: string, updateSillSluitpotAreasRequest?: UpdateSillSluitpotAreasRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('putSluitpotAreas', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}/sluitpot-areas`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSillSluitpotAreasRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSillRequest} [updateSillRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        updateSill: async (sillId: string, apiVersion?: string, updateSillRequest?: UpdateSillRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('updateSill', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSillRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpsertCompartmentLayoutRequest} [upsertCompartmentLayoutRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        upsertCompartmentLayout: async (sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertCompartmentLayoutRequest?: UpsertCompartmentLayoutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('upsertCompartmentLayout', 'sillId', sillId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('upsertCompartmentLayout', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/pim/sills/{sillId}/compartment-layouts/{compartmentLayoutTypeId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)))
                .replace(`{${"compartmentLayoutTypeId"}}`, encodeURIComponent(String(compartmentLayoutTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(upsertCompartmentLayoutRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SillApi - functional programming interface
        * @export
        */
        export const SillApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SillApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateSillCommand} [createSillCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async createSill(apiVersion?: string, createSillCommand?: CreateSillCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSill(apiVersion, createSillCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.createSill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompartmentLayout(sillId, compartmentLayoutTypeId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.deleteCompartmentLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteSill(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSill(sillId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.deleteSill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getSill(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSill(sillId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.getSill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getSluitpotAreas(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SillSluitpotArea>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSluitpotAreas(sillId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.getSluitpotAreas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async listSills(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SillDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSills(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.listSills']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSillContraProfileRequest} [updateSillContraProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillsSillIdContraProfilePut(sillId: string, apiVersion?: string, updateSillContraProfileRequest?: UpdateSillContraProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillsSillIdContraProfilePut(sillId, apiVersion, updateSillContraProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.pimSillsSillIdContraProfilePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpsertAerationAndDrainageOptionsRequest} [upsertAerationAndDrainageOptionsRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async putAerationAndDrainageOptions(sillId: string, apiVersion?: string, upsertAerationAndDrainageOptionsRequest?: UpsertAerationAndDrainageOptionsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAerationAndDrainageOptions(sillId, apiVersion, upsertAerationAndDrainageOptionsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.putAerationAndDrainageOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSillPriceComponentsRequest} [updateSillPriceComponentsRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async putPriceComponents(sillId: string, apiVersion?: string, updateSillPriceComponentsRequest?: UpdateSillPriceComponentsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPriceComponents(sillId, apiVersion, updateSillPriceComponentsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.putPriceComponents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSillContourProfileRequest} [updateSillContourProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async putSillContourProfile(sillId: string, apiVersion?: string, updateSillContourProfileRequest?: UpdateSillContourProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSillContourProfile(sillId, apiVersion, updateSillContourProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.putSillContourProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSillSluitpotAreasRequest} [updateSillSluitpotAreasRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async putSluitpotAreas(sillId: string, apiVersion?: string, updateSillSluitpotAreasRequest?: UpdateSillSluitpotAreasRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSluitpotAreas(sillId, apiVersion, updateSillSluitpotAreasRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.putSluitpotAreas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSillRequest} [updateSillRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async updateSill(sillId: string, apiVersion?: string, updateSillRequest?: UpdateSillRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSill(sillId, apiVersion, updateSillRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.updateSill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpsertCompartmentLayoutRequest} [upsertCompartmentLayoutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async upsertCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertCompartmentLayoutRequest?: UpsertCompartmentLayoutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertCompartmentLayout(sillId, compartmentLayoutTypeId, apiVersion, upsertCompartmentLayoutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillApi.upsertCompartmentLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SillApi - factory interface
        * @export
        */
        export const SillApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SillApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillCommand} [createSillCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            createSill(apiVersion?: string, createSillCommand?: CreateSillCommand, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.createSill(apiVersion, createSillCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.deleteCompartmentLayout(sillId, compartmentLayoutTypeId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteSill(sillId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deleteSill(sillId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getSill(sillId: string, apiVersion?: string, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.getSill(sillId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getSluitpotAreas(sillId: string, apiVersion?: string, options?: any): AxiosPromise<Array<SillSluitpotArea>> {
                return localVarFp.getSluitpotAreas(sillId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            listSills(apiVersion?: string, options?: any): AxiosPromise<Array<SillDetails>> {
                return localVarFp.listSills(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillContraProfileRequest} [updateSillContraProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillsSillIdContraProfilePut(sillId: string, apiVersion?: string, updateSillContraProfileRequest?: UpdateSillContraProfileRequest, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.pimSillsSillIdContraProfilePut(sillId, apiVersion, updateSillContraProfileRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertAerationAndDrainageOptionsRequest} [upsertAerationAndDrainageOptionsRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            putAerationAndDrainageOptions(sillId: string, apiVersion?: string, upsertAerationAndDrainageOptionsRequest?: UpsertAerationAndDrainageOptionsRequest, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.putAerationAndDrainageOptions(sillId, apiVersion, upsertAerationAndDrainageOptionsRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillPriceComponentsRequest} [updateSillPriceComponentsRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            putPriceComponents(sillId: string, apiVersion?: string, updateSillPriceComponentsRequest?: UpdateSillPriceComponentsRequest, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.putPriceComponents(sillId, apiVersion, updateSillPriceComponentsRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillContourProfileRequest} [updateSillContourProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            putSillContourProfile(sillId: string, apiVersion?: string, updateSillContourProfileRequest?: UpdateSillContourProfileRequest, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.putSillContourProfile(sillId, apiVersion, updateSillContourProfileRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillSluitpotAreasRequest} [updateSillSluitpotAreasRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            putSluitpotAreas(sillId: string, apiVersion?: string, updateSillSluitpotAreasRequest?: UpdateSillSluitpotAreasRequest, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.putSluitpotAreas(sillId, apiVersion, updateSillSluitpotAreasRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillRequest} [updateSillRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            updateSill(sillId: string, apiVersion?: string, updateSillRequest?: UpdateSillRequest, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.updateSill(sillId, apiVersion, updateSillRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertCompartmentLayoutRequest} [upsertCompartmentLayoutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            upsertCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertCompartmentLayoutRequest?: UpsertCompartmentLayoutRequest, options?: any): AxiosPromise<SillDetails> {
                return localVarFp.upsertCompartmentLayout(sillId, compartmentLayoutTypeId, apiVersion, upsertCompartmentLayoutRequest, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SillApi - interface
            * @export
            * @interface SillApi
            */
            export interface SillApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillCommand} [createSillCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            createSill(apiVersion?: string, createSillCommand?: CreateSillCommand, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            deleteCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            deleteSill(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            getSill(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            getSluitpotAreas(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SillSluitpotArea>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            listSills(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SillDetails>>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillContraProfileRequest} [updateSillContraProfileRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            pimSillsSillIdContraProfilePut(sillId: string, apiVersion?: string, updateSillContraProfileRequest?: UpdateSillContraProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertAerationAndDrainageOptionsRequest} [upsertAerationAndDrainageOptionsRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            putAerationAndDrainageOptions(sillId: string, apiVersion?: string, upsertAerationAndDrainageOptionsRequest?: UpsertAerationAndDrainageOptionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillPriceComponentsRequest} [updateSillPriceComponentsRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            putPriceComponents(sillId: string, apiVersion?: string, updateSillPriceComponentsRequest?: UpdateSillPriceComponentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillContourProfileRequest} [updateSillContourProfileRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            putSillContourProfile(sillId: string, apiVersion?: string, updateSillContourProfileRequest?: UpdateSillContourProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillSluitpotAreasRequest} [updateSillSluitpotAreasRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            putSluitpotAreas(sillId: string, apiVersion?: string, updateSillSluitpotAreasRequest?: UpdateSillSluitpotAreasRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillRequest} [updateSillRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            updateSill(sillId: string, apiVersion?: string, updateSillRequest?: UpdateSillRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertCompartmentLayoutRequest} [upsertCompartmentLayoutRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillApiInterface
                */
            upsertCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertCompartmentLayoutRequest?: UpsertCompartmentLayoutRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDetails>;

            }

        /**
        * SillApi - object-oriented interface
        * @export
        * @class SillApi
        * @extends {BaseAPI}
        */
            export class SillApi extends BaseAPI implements SillApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillCommand} [createSillCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public createSill(apiVersion?: string, createSillCommand?: CreateSillCommand, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).createSill(apiVersion, createSillCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public deleteCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).deleteCompartmentLayout(sillId, compartmentLayoutTypeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public deleteSill(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).deleteSill(sillId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public getSill(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).getSill(sillId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public getSluitpotAreas(sillId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).getSluitpotAreas(sillId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public listSills(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).listSills(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillContraProfileRequest} [updateSillContraProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public pimSillsSillIdContraProfilePut(sillId: string, apiVersion?: string, updateSillContraProfileRequest?: UpdateSillContraProfileRequest, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).pimSillsSillIdContraProfilePut(sillId, apiVersion, updateSillContraProfileRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertAerationAndDrainageOptionsRequest} [upsertAerationAndDrainageOptionsRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public putAerationAndDrainageOptions(sillId: string, apiVersion?: string, upsertAerationAndDrainageOptionsRequest?: UpsertAerationAndDrainageOptionsRequest, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).putAerationAndDrainageOptions(sillId, apiVersion, upsertAerationAndDrainageOptionsRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillPriceComponentsRequest} [updateSillPriceComponentsRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public putPriceComponents(sillId: string, apiVersion?: string, updateSillPriceComponentsRequest?: UpdateSillPriceComponentsRequest, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).putPriceComponents(sillId, apiVersion, updateSillPriceComponentsRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillContourProfileRequest} [updateSillContourProfileRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public putSillContourProfile(sillId: string, apiVersion?: string, updateSillContourProfileRequest?: UpdateSillContourProfileRequest, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).putSillContourProfile(sillId, apiVersion, updateSillContourProfileRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillSluitpotAreasRequest} [updateSillSluitpotAreasRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public putSluitpotAreas(sillId: string, apiVersion?: string, updateSillSluitpotAreasRequest?: UpdateSillSluitpotAreasRequest, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).putSluitpotAreas(sillId, apiVersion, updateSillSluitpotAreasRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillRequest} [updateSillRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public updateSill(sillId: string, apiVersion?: string, updateSillRequest?: UpdateSillRequest, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).updateSill(sillId, apiVersion, updateSillRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertCompartmentLayoutRequest} [upsertCompartmentLayoutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillApi
            */
                public upsertCompartmentLayout(sillId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertCompartmentLayoutRequest?: UpsertCompartmentLayoutRequest, options?: RawAxiosRequestConfig) {
                return SillApiFp(this.configuration).upsertCompartmentLayout(sillId, compartmentLayoutTypeId, apiVersion, upsertCompartmentLayoutRequest, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SillDrainageOptionApi - axios parameter creator
    * @export
    */
    export const SillDrainageOptionApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillDrainageOptionsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sill-drainage-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillDrainageOptionsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSillDrainageOptionsIdDelete', 'id', id)
            const localVarPath = `/pim/sill-drainage-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillDrainageOptionsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSillDrainageOptionsIdGet', 'id', id)
            const localVarPath = `/pim/sill-drainage-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSillDrainageOptionRequest} [updateSillDrainageOptionRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillDrainageOptionsIdPut: async (id: string, apiVersion?: string, updateSillDrainageOptionRequest?: UpdateSillDrainageOptionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSillDrainageOptionsIdPut', 'id', id)
            const localVarPath = `/pim/sill-drainage-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSillDrainageOptionRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateSillDrainageOptionCommand} [createSillDrainageOptionCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillDrainageOptionsPost: async (apiVersion?: string, createSillDrainageOptionCommand?: CreateSillDrainageOptionCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sill-drainage-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createSillDrainageOptionCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SillDrainageOptionApi - functional programming interface
        * @export
        */
        export const SillDrainageOptionApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SillDrainageOptionApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillDrainageOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SillDrainageOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillDrainageOptionsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillDrainageOptionApi.pimSillDrainageOptionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillDrainageOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillDrainageOptionsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillDrainageOptionApi.pimSillDrainageOptionsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillDrainageOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDrainageOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillDrainageOptionsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillDrainageOptionApi.pimSillDrainageOptionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSillDrainageOptionRequest} [updateSillDrainageOptionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillDrainageOptionsIdPut(id: string, apiVersion?: string, updateSillDrainageOptionRequest?: UpdateSillDrainageOptionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDrainageOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillDrainageOptionsIdPut(id, apiVersion, updateSillDrainageOptionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillDrainageOptionApi.pimSillDrainageOptionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateSillDrainageOptionCommand} [createSillDrainageOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillDrainageOptionsPost(apiVersion?: string, createSillDrainageOptionCommand?: CreateSillDrainageOptionCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillDrainageOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillDrainageOptionsPost(apiVersion, createSillDrainageOptionCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillDrainageOptionApi.pimSillDrainageOptionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SillDrainageOptionApi - factory interface
        * @export
        */
        export const SillDrainageOptionApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SillDrainageOptionApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillDrainageOptionsGet(apiVersion?: string, options?: any): AxiosPromise<Array<SillDrainageOption>> {
                return localVarFp.pimSillDrainageOptionsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillDrainageOptionsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSillDrainageOptionsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillDrainageOptionsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SillDrainageOption> {
                return localVarFp.pimSillDrainageOptionsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillDrainageOptionRequest} [updateSillDrainageOptionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillDrainageOptionsIdPut(id: string, apiVersion?: string, updateSillDrainageOptionRequest?: UpdateSillDrainageOptionRequest, options?: any): AxiosPromise<SillDrainageOption> {
                return localVarFp.pimSillDrainageOptionsIdPut(id, apiVersion, updateSillDrainageOptionRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillDrainageOptionCommand} [createSillDrainageOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillDrainageOptionsPost(apiVersion?: string, createSillDrainageOptionCommand?: CreateSillDrainageOptionCommand, options?: any): AxiosPromise<SillDrainageOption> {
                return localVarFp.pimSillDrainageOptionsPost(apiVersion, createSillDrainageOptionCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SillDrainageOptionApi - interface
            * @export
            * @interface SillDrainageOptionApi
            */
            export interface SillDrainageOptionApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillDrainageOptionApiInterface
                */
            pimSillDrainageOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SillDrainageOption>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillDrainageOptionApiInterface
                */
            pimSillDrainageOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillDrainageOptionApiInterface
                */
            pimSillDrainageOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SillDrainageOption>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillDrainageOptionRequest} [updateSillDrainageOptionRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillDrainageOptionApiInterface
                */
            pimSillDrainageOptionsIdPut(id: string, apiVersion?: string, updateSillDrainageOptionRequest?: UpdateSillDrainageOptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<SillDrainageOption>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillDrainageOptionCommand} [createSillDrainageOptionCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillDrainageOptionApiInterface
                */
            pimSillDrainageOptionsPost(apiVersion?: string, createSillDrainageOptionCommand?: CreateSillDrainageOptionCommand, options?: RawAxiosRequestConfig): AxiosPromise<SillDrainageOption>;

            }

        /**
        * SillDrainageOptionApi - object-oriented interface
        * @export
        * @class SillDrainageOptionApi
        * @extends {BaseAPI}
        */
            export class SillDrainageOptionApi extends BaseAPI implements SillDrainageOptionApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillDrainageOptionApi
            */
                public pimSillDrainageOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillDrainageOptionApiFp(this.configuration).pimSillDrainageOptionsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillDrainageOptionApi
            */
                public pimSillDrainageOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillDrainageOptionApiFp(this.configuration).pimSillDrainageOptionsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillDrainageOptionApi
            */
                public pimSillDrainageOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillDrainageOptionApiFp(this.configuration).pimSillDrainageOptionsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillDrainageOptionRequest} [updateSillDrainageOptionRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillDrainageOptionApi
            */
                public pimSillDrainageOptionsIdPut(id: string, apiVersion?: string, updateSillDrainageOptionRequest?: UpdateSillDrainageOptionRequest, options?: RawAxiosRequestConfig) {
                return SillDrainageOptionApiFp(this.configuration).pimSillDrainageOptionsIdPut(id, apiVersion, updateSillDrainageOptionRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillDrainageOptionCommand} [createSillDrainageOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillDrainageOptionApi
            */
                public pimSillDrainageOptionsPost(apiVersion?: string, createSillDrainageOptionCommand?: CreateSillDrainageOptionCommand, options?: RawAxiosRequestConfig) {
                return SillDrainageOptionApiFp(this.configuration).pimSillDrainageOptionsPost(apiVersion, createSillDrainageOptionCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SillNeutPositionApi - axios parameter creator
    * @export
    */
    export const SillNeutPositionApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} sillId 
            * @param {string} [apiVersion] The requested API version
            * @param {AddNeutPosition} [addNeutPosition] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        addNeutPosition: async (sillId: string, apiVersion?: string, addNeutPosition?: AddNeutPosition, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('addNeutPosition', 'sillId', sillId)
            const localVarPath = `/pim/sills/{sillId}/neut-positions`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(addNeutPosition, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} neutPositionId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteNeutPosition: async (sillId: string, neutPositionId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('deleteNeutPosition', 'sillId', sillId)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('deleteNeutPosition', 'neutPositionId', neutPositionId)
            const localVarPath = `/pim/sills/{sillId}/neut-positions/{neutPositionId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)))
                .replace(`{${"neutPositionId"}}`, encodeURIComponent(String(neutPositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} neutPositionId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteNeutPositionCompartmentLayout: async (sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('deleteNeutPositionCompartmentLayout', 'sillId', sillId)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('deleteNeutPositionCompartmentLayout', 'neutPositionId', neutPositionId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('deleteNeutPositionCompartmentLayout', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/pim/sills/{sillId}/neut-positions/{neutPositionId}/compartment-layouts/{compartmentLayoutTypeId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)))
                .replace(`{${"neutPositionId"}}`, encodeURIComponent(String(neutPositionId)))
                .replace(`{${"compartmentLayoutTypeId"}}`, encodeURIComponent(String(compartmentLayoutTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} neutPositionId 
            * @param {string} [apiVersion] The requested API version
            * @param {SillNeutPositionUpdate} [sillNeutPositionUpdate] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        updateNeutPosition: async (sillId: string, neutPositionId: string, apiVersion?: string, sillNeutPositionUpdate?: SillNeutPositionUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('updateNeutPosition', 'sillId', sillId)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('updateNeutPosition', 'neutPositionId', neutPositionId)
            const localVarPath = `/pim/sills/{sillId}/neut-positions/{neutPositionId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)))
                .replace(`{${"neutPositionId"}}`, encodeURIComponent(String(neutPositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(sillNeutPositionUpdate, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} sillId 
            * @param {string} neutPositionId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [apiVersion] The requested API version
            * @param {UpsertNeutPositionCompartmentLayoutRequest} [upsertNeutPositionCompartmentLayoutRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        upsertNeutPositionCompartmentLayout: async (sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertNeutPositionCompartmentLayoutRequest?: UpsertNeutPositionCompartmentLayoutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'sillId' is not null or undefined
                    assertParamExists('upsertNeutPositionCompartmentLayout', 'sillId', sillId)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('upsertNeutPositionCompartmentLayout', 'neutPositionId', neutPositionId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('upsertNeutPositionCompartmentLayout', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/pim/sills/{sillId}/neut-positions/{neutPositionId}/compartment-layouts/{compartmentLayoutTypeId}`
                .replace(`{${"sillId"}}`, encodeURIComponent(String(sillId)))
                .replace(`{${"neutPositionId"}}`, encodeURIComponent(String(neutPositionId)))
                .replace(`{${"compartmentLayoutTypeId"}}`, encodeURIComponent(String(compartmentLayoutTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(upsertNeutPositionCompartmentLayoutRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SillNeutPositionApi - functional programming interface
        * @export
        */
        export const SillNeutPositionApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SillNeutPositionApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} sillId 
                * @param {string} [apiVersion] The requested API version
                * @param {AddNeutPosition} [addNeutPosition] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async addNeutPosition(sillId: string, apiVersion?: string, addNeutPosition?: AddNeutPosition, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNeutPosition(sillId, apiVersion, addNeutPosition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillNeutPositionApi.addNeutPosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} neutPositionId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNeutPosition(sillId, neutPositionId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillNeutPositionApi.deleteNeutPosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} neutPositionId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNeutPositionCompartmentLayout(sillId, neutPositionId, compartmentLayoutTypeId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillNeutPositionApi.deleteNeutPositionCompartmentLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} neutPositionId 
                * @param {string} [apiVersion] The requested API version
                * @param {SillNeutPositionUpdate} [sillNeutPositionUpdate] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async updateNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, sillNeutPositionUpdate?: SillNeutPositionUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNeutPosition(sillId, neutPositionId, apiVersion, sillNeutPositionUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillNeutPositionApi.updateNeutPosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} sillId 
                * @param {string} neutPositionId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [apiVersion] The requested API version
                * @param {UpsertNeutPositionCompartmentLayoutRequest} [upsertNeutPositionCompartmentLayoutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async upsertNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertNeutPositionCompartmentLayoutRequest?: UpsertNeutPositionCompartmentLayoutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertNeutPositionCompartmentLayout(sillId, neutPositionId, compartmentLayoutTypeId, apiVersion, upsertNeutPositionCompartmentLayoutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillNeutPositionApi.upsertNeutPositionCompartmentLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SillNeutPositionApi - factory interface
        * @export
        */
        export const SillNeutPositionApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SillNeutPositionApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {AddNeutPosition} [addNeutPosition] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            addNeutPosition(sillId: string, apiVersion?: string, addNeutPosition?: AddNeutPosition, options?: any): AxiosPromise<void> {
                return localVarFp.addNeutPosition(sillId, apiVersion, addNeutPosition, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deleteNeutPosition(sillId, neutPositionId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deleteNeutPositionCompartmentLayout(sillId, neutPositionId, compartmentLayoutTypeId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SillNeutPositionUpdate} [sillNeutPositionUpdate] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            updateNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, sillNeutPositionUpdate?: SillNeutPositionUpdate, options?: any): AxiosPromise<void> {
                return localVarFp.updateNeutPosition(sillId, neutPositionId, apiVersion, sillNeutPositionUpdate, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertNeutPositionCompartmentLayoutRequest} [upsertNeutPositionCompartmentLayoutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            upsertNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertNeutPositionCompartmentLayoutRequest?: UpsertNeutPositionCompartmentLayoutRequest, options?: any): AxiosPromise<void> {
                return localVarFp.upsertNeutPositionCompartmentLayout(sillId, neutPositionId, compartmentLayoutTypeId, apiVersion, upsertNeutPositionCompartmentLayoutRequest, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SillNeutPositionApi - interface
            * @export
            * @interface SillNeutPositionApi
            */
            export interface SillNeutPositionApiInterface {
                /**
                * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {AddNeutPosition} [addNeutPosition] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillNeutPositionApiInterface
                */
            addNeutPosition(sillId: string, apiVersion?: string, addNeutPosition?: AddNeutPosition, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillNeutPositionApiInterface
                */
            deleteNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillNeutPositionApiInterface
                */
            deleteNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SillNeutPositionUpdate} [sillNeutPositionUpdate] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillNeutPositionApiInterface
                */
            updateNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, sillNeutPositionUpdate?: SillNeutPositionUpdate, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertNeutPositionCompartmentLayoutRequest} [upsertNeutPositionCompartmentLayoutRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillNeutPositionApiInterface
                */
            upsertNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertNeutPositionCompartmentLayoutRequest?: UpsertNeutPositionCompartmentLayoutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * SillNeutPositionApi - object-oriented interface
        * @export
        * @class SillNeutPositionApi
        * @extends {BaseAPI}
        */
            export class SillNeutPositionApi extends BaseAPI implements SillNeutPositionApiInterface {
            /**
            * 
                    * @param {string} sillId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {AddNeutPosition} [addNeutPosition] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillNeutPositionApi
            */
                public addNeutPosition(sillId: string, apiVersion?: string, addNeutPosition?: AddNeutPosition, options?: RawAxiosRequestConfig) {
                return SillNeutPositionApiFp(this.configuration).addNeutPosition(sillId, apiVersion, addNeutPosition, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillNeutPositionApi
            */
                public deleteNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillNeutPositionApiFp(this.configuration).deleteNeutPosition(sillId, neutPositionId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillNeutPositionApi
            */
                public deleteNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillNeutPositionApiFp(this.configuration).deleteNeutPositionCompartmentLayout(sillId, neutPositionId, compartmentLayoutTypeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {SillNeutPositionUpdate} [sillNeutPositionUpdate] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillNeutPositionApi
            */
                public updateNeutPosition(sillId: string, neutPositionId: string, apiVersion?: string, sillNeutPositionUpdate?: SillNeutPositionUpdate, options?: RawAxiosRequestConfig) {
                return SillNeutPositionApiFp(this.configuration).updateNeutPosition(sillId, neutPositionId, apiVersion, sillNeutPositionUpdate, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} sillId 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpsertNeutPositionCompartmentLayoutRequest} [upsertNeutPositionCompartmentLayoutRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillNeutPositionApi
            */
                public upsertNeutPositionCompartmentLayout(sillId: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, upsertNeutPositionCompartmentLayoutRequest?: UpsertNeutPositionCompartmentLayoutRequest, options?: RawAxiosRequestConfig) {
                return SillNeutPositionApiFp(this.configuration).upsertNeutPositionCompartmentLayout(sillId, neutPositionId, compartmentLayoutTypeId, apiVersion, upsertNeutPositionCompartmentLayoutRequest, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SillProfilesApi - axios parameter creator
    * @export
    */
    export const SillProfilesApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} id 
            * @param {string} neutPositionId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        addSillProfiles: async (id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('addSillProfiles', 'id', id)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('addSillProfiles', 'neutPositionId', neutPositionId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('addSillProfiles', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/pim/sills/{id}/profiles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (neutPositionId !== undefined) {
                                    localVarQueryParameter['neutPositionId'] = neutPositionId;
                    }

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} neutPositionId 
            * @param {string} [compartmentLayoutTypeId] 
            * @param {string} [apiVersion] The requested API version
            * @param {Array<string>} [requestBody] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deleteSillProfiles: async (id: string, neutPositionId: string, compartmentLayoutTypeId?: string, apiVersion?: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('deleteSillProfiles', 'id', id)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('deleteSillProfiles', 'neutPositionId', neutPositionId)
            const localVarPath = `/pim/sills/{id}/profiles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (neutPositionId !== undefined) {
                                    localVarQueryParameter['neutPositionId'] = neutPositionId;
                    }

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} neutPositionId 
            * @param {string} compartmentLayoutTypeId 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getSillProfiles: async (id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('getSillProfiles', 'id', id)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('getSillProfiles', 'neutPositionId', neutPositionId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('getSillProfiles', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/pim/sills/{id}/profiles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (neutPositionId !== undefined) {
                                    localVarQueryParameter['neutPositionId'] = neutPositionId;
                    }

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} neutPositionId 
            * @param {string} compartmentLayoutTypeId 
            * @param {RabbetPosition} [rabbetPosition] 
            * @param {boolean} [excludeExisting] 
            * @param {Array<string>} [filters] 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        searchSillProfiles: async (id: string, neutPositionId: string, compartmentLayoutTypeId: string, rabbetPosition?: RabbetPosition, excludeExisting?: boolean, filters?: Array<string>, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('searchSillProfiles', 'id', id)
                    // verify required parameter 'neutPositionId' is not null or undefined
                    assertParamExists('searchSillProfiles', 'neutPositionId', neutPositionId)
                    // verify required parameter 'compartmentLayoutTypeId' is not null or undefined
                    assertParamExists('searchSillProfiles', 'compartmentLayoutTypeId', compartmentLayoutTypeId)
            const localVarPath = `/pim/sills/{id}/profiles/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (neutPositionId !== undefined) {
                                    localVarQueryParameter['neutPositionId'] = neutPositionId;
                    }

                    if (compartmentLayoutTypeId !== undefined) {
                                    localVarQueryParameter['compartmentLayoutTypeId'] = compartmentLayoutTypeId;
                    }

                    if (rabbetPosition !== undefined) {
                                        localVarQueryParameter['rabbetPosition'] = rabbetPosition;
                    }

                    if (excludeExisting !== undefined) {
                                    localVarQueryParameter['excludeExisting'] = excludeExisting;
                    }

                    if (filters) {
                            localVarQueryParameter['filters'] = filters;
                    }

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SillProfilesApi - functional programming interface
        * @export
        */
        export const SillProfilesApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SillProfilesApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} id 
                * @param {string} neutPositionId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async addSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSillProfiles200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillProfilesApi.addSillProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} neutPositionId 
                * @param {string} [compartmentLayoutTypeId] 
                * @param {string} [apiVersion] The requested API version
                * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deleteSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId?: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillProfilesApi.deleteSillProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} neutPositionId 
                * @param {string} compartmentLayoutTypeId 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async getSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSillProfiles200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillProfilesApi.getSillProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} neutPositionId 
                * @param {string} compartmentLayoutTypeId 
                * @param {RabbetPosition} [rabbetPosition] 
                * @param {boolean} [excludeExisting] 
                * @param {Array<string>} [filters] 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async searchSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, rabbetPosition?: RabbetPosition, excludeExisting?: boolean, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSillProfiles(id, neutPositionId, compartmentLayoutTypeId, rabbetPosition, excludeExisting, filters, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillProfilesApi.searchSillProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SillProfilesApi - factory interface
        * @export
        */
        export const SillProfilesApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SillProfilesApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            addSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<GetSillProfiles200Response> {
                return localVarFp.addSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} [compartmentLayoutTypeId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deleteSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId?: string, apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
                return localVarFp.deleteSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, requestBody, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            getSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: any): AxiosPromise<GetSillProfiles200Response> {
                return localVarFp.getSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {RabbetPosition} [rabbetPosition] 
                    * @param {boolean} [excludeExisting] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            searchSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, rabbetPosition?: RabbetPosition, excludeExisting?: boolean, filters?: Array<string>, apiVersion?: string, options?: any): AxiosPromise<ProfileSearchResult> {
                return localVarFp.searchSillProfiles(id, neutPositionId, compartmentLayoutTypeId, rabbetPosition, excludeExisting, filters, apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SillProfilesApi - interface
            * @export
            * @interface SillProfilesApi
            */
            export interface SillProfilesApiInterface {
                /**
                * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillProfilesApiInterface
                */
            addSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<GetSillProfiles200Response>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} [compartmentLayoutTypeId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillProfilesApiInterface
                */
            deleteSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId?: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillProfilesApiInterface
                */
            getSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSillProfiles200Response>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {RabbetPosition} [rabbetPosition] 
                    * @param {boolean} [excludeExisting] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillProfilesApiInterface
                */
            searchSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, rabbetPosition?: RabbetPosition, excludeExisting?: boolean, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProfileSearchResult>;

            }

        /**
        * SillProfilesApi - object-oriented interface
        * @export
        * @class SillProfilesApi
        * @extends {BaseAPI}
        */
            export class SillProfilesApi extends BaseAPI implements SillProfilesApiInterface {
            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillProfilesApi
            */
                public addSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return SillProfilesApiFp(this.configuration).addSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} [compartmentLayoutTypeId] 
                    * @param {string} [apiVersion] The requested API version
                    * @param {Array<string>} [requestBody] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillProfilesApi
            */
                public deleteSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId?: string, apiVersion?: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
                return SillProfilesApiFp(this.configuration).deleteSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillProfilesApi
            */
                public getSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillProfilesApiFp(this.configuration).getSillProfiles(id, neutPositionId, compartmentLayoutTypeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} neutPositionId 
                    * @param {string} compartmentLayoutTypeId 
                    * @param {RabbetPosition} [rabbetPosition] 
                    * @param {boolean} [excludeExisting] 
                    * @param {Array<string>} [filters] 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillProfilesApi
            */
                public searchSillProfiles(id: string, neutPositionId: string, compartmentLayoutTypeId: string, rabbetPosition?: RabbetPosition, excludeExisting?: boolean, filters?: Array<string>, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillProfilesApiFp(this.configuration).searchSillProfiles(id, neutPositionId, compartmentLayoutTypeId, rabbetPosition, excludeExisting, filters, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SillWrappingOptionApi - axios parameter creator
    * @export
    */
    export const SillWrappingOptionApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillWrappingOptionsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sill-wrapping-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillWrappingOptionsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSillWrappingOptionsIdDelete', 'id', id)
            const localVarPath = `/pim/sill-wrapping-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillWrappingOptionsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSillWrappingOptionsIdGet', 'id', id)
            const localVarPath = `/pim/sill-wrapping-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSillWrappingOptionCommand} [updateSillWrappingOptionCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillWrappingOptionsIdPut: async (id: string, apiVersion?: string, updateSillWrappingOptionCommand?: UpdateSillWrappingOptionCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSillWrappingOptionsIdPut', 'id', id)
            const localVarPath = `/pim/sill-wrapping-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSillWrappingOptionCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateSillWrappingOptionCommand} [createSillWrappingOptionCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSillWrappingOptionsPost: async (apiVersion?: string, createSillWrappingOptionCommand?: CreateSillWrappingOptionCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sill-wrapping-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createSillWrappingOptionCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SillWrappingOptionApi - functional programming interface
        * @export
        */
        export const SillWrappingOptionApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SillWrappingOptionApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillWrappingOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SillWrappingOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillWrappingOptionsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillWrappingOptionApi.pimSillWrappingOptionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillWrappingOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillWrappingOptionsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillWrappingOptionApi.pimSillWrappingOptionsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillWrappingOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillWrappingOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillWrappingOptionsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillWrappingOptionApi.pimSillWrappingOptionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSillWrappingOptionCommand} [updateSillWrappingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillWrappingOptionsIdPut(id: string, apiVersion?: string, updateSillWrappingOptionCommand?: UpdateSillWrappingOptionCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillWrappingOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillWrappingOptionsIdPut(id, apiVersion, updateSillWrappingOptionCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillWrappingOptionApi.pimSillWrappingOptionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateSillWrappingOptionCommand} [createSillWrappingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSillWrappingOptionsPost(apiVersion?: string, createSillWrappingOptionCommand?: CreateSillWrappingOptionCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SillWrappingOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSillWrappingOptionsPost(apiVersion, createSillWrappingOptionCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SillWrappingOptionApi.pimSillWrappingOptionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SillWrappingOptionApi - factory interface
        * @export
        */
        export const SillWrappingOptionApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SillWrappingOptionApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillWrappingOptionsGet(apiVersion?: string, options?: any): AxiosPromise<Array<SillWrappingOption>> {
                return localVarFp.pimSillWrappingOptionsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillWrappingOptionsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSillWrappingOptionsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillWrappingOptionsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SillWrappingOption> {
                return localVarFp.pimSillWrappingOptionsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillWrappingOptionCommand} [updateSillWrappingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillWrappingOptionsIdPut(id: string, apiVersion?: string, updateSillWrappingOptionCommand?: UpdateSillWrappingOptionCommand, options?: any): AxiosPromise<SillWrappingOption> {
                return localVarFp.pimSillWrappingOptionsIdPut(id, apiVersion, updateSillWrappingOptionCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillWrappingOptionCommand} [createSillWrappingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSillWrappingOptionsPost(apiVersion?: string, createSillWrappingOptionCommand?: CreateSillWrappingOptionCommand, options?: any): AxiosPromise<SillWrappingOption> {
                return localVarFp.pimSillWrappingOptionsPost(apiVersion, createSillWrappingOptionCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SillWrappingOptionApi - interface
            * @export
            * @interface SillWrappingOptionApi
            */
            export interface SillWrappingOptionApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillWrappingOptionApiInterface
                */
            pimSillWrappingOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SillWrappingOption>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillWrappingOptionApiInterface
                */
            pimSillWrappingOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillWrappingOptionApiInterface
                */
            pimSillWrappingOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SillWrappingOption>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillWrappingOptionCommand} [updateSillWrappingOptionCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillWrappingOptionApiInterface
                */
            pimSillWrappingOptionsIdPut(id: string, apiVersion?: string, updateSillWrappingOptionCommand?: UpdateSillWrappingOptionCommand, options?: RawAxiosRequestConfig): AxiosPromise<SillWrappingOption>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillWrappingOptionCommand} [createSillWrappingOptionCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SillWrappingOptionApiInterface
                */
            pimSillWrappingOptionsPost(apiVersion?: string, createSillWrappingOptionCommand?: CreateSillWrappingOptionCommand, options?: RawAxiosRequestConfig): AxiosPromise<SillWrappingOption>;

            }

        /**
        * SillWrappingOptionApi - object-oriented interface
        * @export
        * @class SillWrappingOptionApi
        * @extends {BaseAPI}
        */
            export class SillWrappingOptionApi extends BaseAPI implements SillWrappingOptionApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillWrappingOptionApi
            */
                public pimSillWrappingOptionsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillWrappingOptionApiFp(this.configuration).pimSillWrappingOptionsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillWrappingOptionApi
            */
                public pimSillWrappingOptionsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillWrappingOptionApiFp(this.configuration).pimSillWrappingOptionsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillWrappingOptionApi
            */
                public pimSillWrappingOptionsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SillWrappingOptionApiFp(this.configuration).pimSillWrappingOptionsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSillWrappingOptionCommand} [updateSillWrappingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillWrappingOptionApi
            */
                public pimSillWrappingOptionsIdPut(id: string, apiVersion?: string, updateSillWrappingOptionCommand?: UpdateSillWrappingOptionCommand, options?: RawAxiosRequestConfig) {
                return SillWrappingOptionApiFp(this.configuration).pimSillWrappingOptionsIdPut(id, apiVersion, updateSillWrappingOptionCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSillWrappingOptionCommand} [createSillWrappingOptionCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SillWrappingOptionApi
            */
                public pimSillWrappingOptionsPost(apiVersion?: string, createSillWrappingOptionCommand?: CreateSillWrappingOptionCommand, options?: RawAxiosRequestConfig) {
                return SillWrappingOptionApiFp(this.configuration).pimSillWrappingOptionsPost(apiVersion, createSillWrappingOptionCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SluitpotApi - axios parameter creator
    * @export
    */
    export const SluitpotApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotsGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sluitpots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSluitpotContourCommand} [updateSluitpotContourCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotsIdContourPut: async (id: string, apiVersion?: string, updateSluitpotContourCommand?: UpdateSluitpotContourCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotsIdContourPut', 'id', id)
            const localVarPath = `/pim/sluitpots/{id}/contour`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSluitpotContourCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotsIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotsIdDelete', 'id', id)
            const localVarPath = `/pim/sluitpots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotsIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotsIdGet', 'id', id)
            const localVarPath = `/pim/sluitpots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotsIdImagePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotsIdImagePut', 'id', id)
            const localVarPath = `/pim/sluitpots/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSluitpotCommand} [updateSluitpotCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotsIdPut: async (id: string, apiVersion?: string, updateSluitpotCommand?: UpdateSluitpotCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotsIdPut', 'id', id)
            const localVarPath = `/pim/sluitpots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSluitpotCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateSluitpotCommand} [createSluitpotCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotsPost: async (apiVersion?: string, createSluitpotCommand?: CreateSluitpotCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sluitpots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createSluitpotCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SluitpotApi - functional programming interface
        * @export
        */
        export const SluitpotApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SluitpotApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotsGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sluitpot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotsGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotApi.pimSluitpotsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSluitpotContourCommand} [updateSluitpotContourCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotsIdContourPut(id: string, apiVersion?: string, updateSluitpotContourCommand?: UpdateSluitpotContourCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotsIdContourPut(id, apiVersion, updateSluitpotContourCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotApi.pimSluitpotsIdContourPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotsIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotApi.pimSluitpotsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sluitpot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotsIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotApi.pimSluitpotsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotsIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotsIdImagePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotApi.pimSluitpotsIdImagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSluitpotCommand} [updateSluitpotCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotsIdPut(id: string, apiVersion?: string, updateSluitpotCommand?: UpdateSluitpotCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sluitpot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotsIdPut(id, apiVersion, updateSluitpotCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotApi.pimSluitpotsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateSluitpotCommand} [createSluitpotCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotsPost(apiVersion?: string, createSluitpotCommand?: CreateSluitpotCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sluitpot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotsPost(apiVersion, createSluitpotCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotApi.pimSluitpotsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SluitpotApi - factory interface
        * @export
        */
        export const SluitpotApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SluitpotApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotsGet(apiVersion?: string, options?: any): AxiosPromise<Array<Sluitpot>> {
                return localVarFp.pimSluitpotsGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotContourCommand} [updateSluitpotContourCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotsIdContourPut(id: string, apiVersion?: string, updateSluitpotContourCommand?: UpdateSluitpotContourCommand, options?: any): AxiosPromise<void> {
                return localVarFp.pimSluitpotsIdContourPut(id, apiVersion, updateSluitpotContourCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotsIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSluitpotsIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<Sluitpot> {
                return localVarFp.pimSluitpotsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotsIdImagePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSluitpotsIdImagePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotCommand} [updateSluitpotCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotsIdPut(id: string, apiVersion?: string, updateSluitpotCommand?: UpdateSluitpotCommand, options?: any): AxiosPromise<Sluitpot> {
                return localVarFp.pimSluitpotsIdPut(id, apiVersion, updateSluitpotCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotCommand} [createSluitpotCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotsPost(apiVersion?: string, createSluitpotCommand?: CreateSluitpotCommand, options?: any): AxiosPromise<Sluitpot> {
                return localVarFp.pimSluitpotsPost(apiVersion, createSluitpotCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SluitpotApi - interface
            * @export
            * @interface SluitpotApi
            */
            export interface SluitpotApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotApiInterface
                */
            pimSluitpotsGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Sluitpot>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotContourCommand} [updateSluitpotContourCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotApiInterface
                */
            pimSluitpotsIdContourPut(id: string, apiVersion?: string, updateSluitpotContourCommand?: UpdateSluitpotContourCommand, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotApiInterface
                */
            pimSluitpotsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotApiInterface
                */
            pimSluitpotsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Sluitpot>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotApiInterface
                */
            pimSluitpotsIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotCommand} [updateSluitpotCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotApiInterface
                */
            pimSluitpotsIdPut(id: string, apiVersion?: string, updateSluitpotCommand?: UpdateSluitpotCommand, options?: RawAxiosRequestConfig): AxiosPromise<Sluitpot>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotCommand} [createSluitpotCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotApiInterface
                */
            pimSluitpotsPost(apiVersion?: string, createSluitpotCommand?: CreateSluitpotCommand, options?: RawAxiosRequestConfig): AxiosPromise<Sluitpot>;

            }

        /**
        * SluitpotApi - object-oriented interface
        * @export
        * @class SluitpotApi
        * @extends {BaseAPI}
        */
            export class SluitpotApi extends BaseAPI implements SluitpotApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotApi
            */
                public pimSluitpotsGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotApiFp(this.configuration).pimSluitpotsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotContourCommand} [updateSluitpotContourCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotApi
            */
                public pimSluitpotsIdContourPut(id: string, apiVersion?: string, updateSluitpotContourCommand?: UpdateSluitpotContourCommand, options?: RawAxiosRequestConfig) {
                return SluitpotApiFp(this.configuration).pimSluitpotsIdContourPut(id, apiVersion, updateSluitpotContourCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotApi
            */
                public pimSluitpotsIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotApiFp(this.configuration).pimSluitpotsIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotApi
            */
                public pimSluitpotsIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotApiFp(this.configuration).pimSluitpotsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotApi
            */
                public pimSluitpotsIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotApiFp(this.configuration).pimSluitpotsIdImagePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotCommand} [updateSluitpotCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotApi
            */
                public pimSluitpotsIdPut(id: string, apiVersion?: string, updateSluitpotCommand?: UpdateSluitpotCommand, options?: RawAxiosRequestConfig) {
                return SluitpotApiFp(this.configuration).pimSluitpotsIdPut(id, apiVersion, updateSluitpotCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotCommand} [createSluitpotCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotApi
            */
                public pimSluitpotsPost(apiVersion?: string, createSluitpotCommand?: CreateSluitpotCommand, options?: RawAxiosRequestConfig) {
                return SluitpotApiFp(this.configuration).pimSluitpotsPost(apiVersion, createSluitpotCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SluitpotBeslagApi - axios parameter creator
    * @export
    */
    export const SluitpotBeslagApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBeslagGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sluitpot-beslag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBeslagIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotBeslagIdDelete', 'id', id)
            const localVarPath = `/pim/sluitpot-beslag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBeslagIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotBeslagIdGet', 'id', id)
            const localVarPath = `/pim/sluitpot-beslag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBeslagIdImagePut: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotBeslagIdImagePut', 'id', id)
            const localVarPath = `/pim/sluitpot-beslag/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSluitpotBeslagCommand} [updateSluitpotBeslagCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBeslagIdPut: async (id: string, apiVersion?: string, updateSluitpotBeslagCommand?: UpdateSluitpotBeslagCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotBeslagIdPut', 'id', id)
            const localVarPath = `/pim/sluitpot-beslag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSluitpotBeslagCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateSluitpotBeslagCommand} [createSluitpotBeslagCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBeslagPost: async (apiVersion?: string, createSluitpotBeslagCommand?: CreateSluitpotBeslagCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sluitpot-beslag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createSluitpotBeslagCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SluitpotBeslagApi - functional programming interface
        * @export
        */
        export const SluitpotBeslagApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SluitpotBeslagApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBeslagGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SluitpotBeslag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBeslagGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBeslagApi.pimSluitpotBeslagGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBeslagIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBeslagIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBeslagApi.pimSluitpotBeslagIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBeslagIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SluitpotBeslag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBeslagIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBeslagApi.pimSluitpotBeslagIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBeslagIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBeslagIdImagePut(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBeslagApi.pimSluitpotBeslagIdImagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSluitpotBeslagCommand} [updateSluitpotBeslagCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBeslagIdPut(id: string, apiVersion?: string, updateSluitpotBeslagCommand?: UpdateSluitpotBeslagCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SluitpotBeslag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBeslagIdPut(id, apiVersion, updateSluitpotBeslagCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBeslagApi.pimSluitpotBeslagIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateSluitpotBeslagCommand} [createSluitpotBeslagCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBeslagPost(apiVersion?: string, createSluitpotBeslagCommand?: CreateSluitpotBeslagCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SluitpotBeslag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBeslagPost(apiVersion, createSluitpotBeslagCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBeslagApi.pimSluitpotBeslagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SluitpotBeslagApi - factory interface
        * @export
        */
        export const SluitpotBeslagApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SluitpotBeslagApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBeslagGet(apiVersion?: string, options?: any): AxiosPromise<Array<SluitpotBeslag>> {
                return localVarFp.pimSluitpotBeslagGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBeslagIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSluitpotBeslagIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBeslagIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SluitpotBeslag> {
                return localVarFp.pimSluitpotBeslagIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBeslagIdImagePut(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSluitpotBeslagIdImagePut(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotBeslagCommand} [updateSluitpotBeslagCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBeslagIdPut(id: string, apiVersion?: string, updateSluitpotBeslagCommand?: UpdateSluitpotBeslagCommand, options?: any): AxiosPromise<SluitpotBeslag> {
                return localVarFp.pimSluitpotBeslagIdPut(id, apiVersion, updateSluitpotBeslagCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotBeslagCommand} [createSluitpotBeslagCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBeslagPost(apiVersion?: string, createSluitpotBeslagCommand?: CreateSluitpotBeslagCommand, options?: any): AxiosPromise<SluitpotBeslag> {
                return localVarFp.pimSluitpotBeslagPost(apiVersion, createSluitpotBeslagCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SluitpotBeslagApi - interface
            * @export
            * @interface SluitpotBeslagApi
            */
            export interface SluitpotBeslagApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBeslagApiInterface
                */
            pimSluitpotBeslagGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SluitpotBeslag>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBeslagApiInterface
                */
            pimSluitpotBeslagIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBeslagApiInterface
                */
            pimSluitpotBeslagIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SluitpotBeslag>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBeslagApiInterface
                */
            pimSluitpotBeslagIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotBeslagCommand} [updateSluitpotBeslagCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBeslagApiInterface
                */
            pimSluitpotBeslagIdPut(id: string, apiVersion?: string, updateSluitpotBeslagCommand?: UpdateSluitpotBeslagCommand, options?: RawAxiosRequestConfig): AxiosPromise<SluitpotBeslag>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotBeslagCommand} [createSluitpotBeslagCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBeslagApiInterface
                */
            pimSluitpotBeslagPost(apiVersion?: string, createSluitpotBeslagCommand?: CreateSluitpotBeslagCommand, options?: RawAxiosRequestConfig): AxiosPromise<SluitpotBeslag>;

            }

        /**
        * SluitpotBeslagApi - object-oriented interface
        * @export
        * @class SluitpotBeslagApi
        * @extends {BaseAPI}
        */
            export class SluitpotBeslagApi extends BaseAPI implements SluitpotBeslagApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBeslagApi
            */
                public pimSluitpotBeslagGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotBeslagApiFp(this.configuration).pimSluitpotBeslagGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBeslagApi
            */
                public pimSluitpotBeslagIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotBeslagApiFp(this.configuration).pimSluitpotBeslagIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBeslagApi
            */
                public pimSluitpotBeslagIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotBeslagApiFp(this.configuration).pimSluitpotBeslagIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBeslagApi
            */
                public pimSluitpotBeslagIdImagePut(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotBeslagApiFp(this.configuration).pimSluitpotBeslagIdImagePut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotBeslagCommand} [updateSluitpotBeslagCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBeslagApi
            */
                public pimSluitpotBeslagIdPut(id: string, apiVersion?: string, updateSluitpotBeslagCommand?: UpdateSluitpotBeslagCommand, options?: RawAxiosRequestConfig) {
                return SluitpotBeslagApiFp(this.configuration).pimSluitpotBeslagIdPut(id, apiVersion, updateSluitpotBeslagCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotBeslagCommand} [createSluitpotBeslagCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBeslagApi
            */
                public pimSluitpotBeslagPost(apiVersion?: string, createSluitpotBeslagCommand?: CreateSluitpotBeslagCommand, options?: RawAxiosRequestConfig) {
                return SluitpotBeslagApiFp(this.configuration).pimSluitpotBeslagPost(apiVersion, createSluitpotBeslagCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SluitpotBlockTypeApi - axios parameter creator
    * @export
    */
    export const SluitpotBlockTypeApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBlockTypesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sluitpot-block-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBlockTypesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotBlockTypesIdDelete', 'id', id)
            const localVarPath = `/pim/sluitpot-block-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBlockTypesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotBlockTypesIdGet', 'id', id)
            const localVarPath = `/pim/sluitpot-block-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateSluitpotBlockTypeCommand} [updateSluitpotBlockTypeCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBlockTypesIdPut: async (id: string, apiVersion?: string, updateSluitpotBlockTypeCommand?: UpdateSluitpotBlockTypeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimSluitpotBlockTypesIdPut', 'id', id)
            const localVarPath = `/pim/sluitpot-block-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateSluitpotBlockTypeCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateSluitpotBlockTypeCommand} [createSluitpotBlockTypeCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSluitpotBlockTypesPost: async (apiVersion?: string, createSluitpotBlockTypeCommand?: CreateSluitpotBlockTypeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/sluitpot-block-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createSluitpotBlockTypeCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SluitpotBlockTypeApi - functional programming interface
        * @export
        */
        export const SluitpotBlockTypeApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SluitpotBlockTypeApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBlockTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SluitpotBlockType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBlockTypesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBlockTypeApi.pimSluitpotBlockTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBlockTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBlockTypesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBlockTypeApi.pimSluitpotBlockTypesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBlockTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SluitpotBlockType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBlockTypesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBlockTypeApi.pimSluitpotBlockTypesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateSluitpotBlockTypeCommand} [updateSluitpotBlockTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBlockTypesIdPut(id: string, apiVersion?: string, updateSluitpotBlockTypeCommand?: UpdateSluitpotBlockTypeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SluitpotBlockType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBlockTypesIdPut(id, apiVersion, updateSluitpotBlockTypeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBlockTypeApi.pimSluitpotBlockTypesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateSluitpotBlockTypeCommand} [createSluitpotBlockTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSluitpotBlockTypesPost(apiVersion?: string, createSluitpotBlockTypeCommand?: CreateSluitpotBlockTypeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SluitpotBlockType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSluitpotBlockTypesPost(apiVersion, createSluitpotBlockTypeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SluitpotBlockTypeApi.pimSluitpotBlockTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SluitpotBlockTypeApi - factory interface
        * @export
        */
        export const SluitpotBlockTypeApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SluitpotBlockTypeApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBlockTypesGet(apiVersion?: string, options?: any): AxiosPromise<Array<SluitpotBlockType>> {
                return localVarFp.pimSluitpotBlockTypesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBlockTypesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSluitpotBlockTypesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBlockTypesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SluitpotBlockType> {
                return localVarFp.pimSluitpotBlockTypesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotBlockTypeCommand} [updateSluitpotBlockTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBlockTypesIdPut(id: string, apiVersion?: string, updateSluitpotBlockTypeCommand?: UpdateSluitpotBlockTypeCommand, options?: any): AxiosPromise<SluitpotBlockType> {
                return localVarFp.pimSluitpotBlockTypesIdPut(id, apiVersion, updateSluitpotBlockTypeCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotBlockTypeCommand} [createSluitpotBlockTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSluitpotBlockTypesPost(apiVersion?: string, createSluitpotBlockTypeCommand?: CreateSluitpotBlockTypeCommand, options?: any): AxiosPromise<SluitpotBlockType> {
                return localVarFp.pimSluitpotBlockTypesPost(apiVersion, createSluitpotBlockTypeCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SluitpotBlockTypeApi - interface
            * @export
            * @interface SluitpotBlockTypeApi
            */
            export interface SluitpotBlockTypeApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBlockTypeApiInterface
                */
            pimSluitpotBlockTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SluitpotBlockType>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBlockTypeApiInterface
                */
            pimSluitpotBlockTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBlockTypeApiInterface
                */
            pimSluitpotBlockTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<SluitpotBlockType>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotBlockTypeCommand} [updateSluitpotBlockTypeCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBlockTypeApiInterface
                */
            pimSluitpotBlockTypesIdPut(id: string, apiVersion?: string, updateSluitpotBlockTypeCommand?: UpdateSluitpotBlockTypeCommand, options?: RawAxiosRequestConfig): AxiosPromise<SluitpotBlockType>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotBlockTypeCommand} [createSluitpotBlockTypeCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SluitpotBlockTypeApiInterface
                */
            pimSluitpotBlockTypesPost(apiVersion?: string, createSluitpotBlockTypeCommand?: CreateSluitpotBlockTypeCommand, options?: RawAxiosRequestConfig): AxiosPromise<SluitpotBlockType>;

            }

        /**
        * SluitpotBlockTypeApi - object-oriented interface
        * @export
        * @class SluitpotBlockTypeApi
        * @extends {BaseAPI}
        */
            export class SluitpotBlockTypeApi extends BaseAPI implements SluitpotBlockTypeApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBlockTypeApi
            */
                public pimSluitpotBlockTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotBlockTypeApiFp(this.configuration).pimSluitpotBlockTypesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBlockTypeApi
            */
                public pimSluitpotBlockTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotBlockTypeApiFp(this.configuration).pimSluitpotBlockTypesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBlockTypeApi
            */
                public pimSluitpotBlockTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SluitpotBlockTypeApiFp(this.configuration).pimSluitpotBlockTypesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateSluitpotBlockTypeCommand} [updateSluitpotBlockTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBlockTypeApi
            */
                public pimSluitpotBlockTypesIdPut(id: string, apiVersion?: string, updateSluitpotBlockTypeCommand?: UpdateSluitpotBlockTypeCommand, options?: RawAxiosRequestConfig) {
                return SluitpotBlockTypeApiFp(this.configuration).pimSluitpotBlockTypesIdPut(id, apiVersion, updateSluitpotBlockTypeCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateSluitpotBlockTypeCommand} [createSluitpotBlockTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SluitpotBlockTypeApi
            */
                public pimSluitpotBlockTypesPost(apiVersion?: string, createSluitpotBlockTypeCommand?: CreateSluitpotBlockTypeCommand, options?: RawAxiosRequestConfig) {
                return SluitpotBlockTypeApiFp(this.configuration).pimSluitpotBlockTypesPost(apiVersion, createSluitpotBlockTypeCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * StopProfileApi - axios parameter creator
    * @export
    */
    export const StopProfileApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimStopProfilesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/stop-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimStopProfilesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimStopProfilesIdDelete', 'id', id)
            const localVarPath = `/pim/stop-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimStopProfilesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimStopProfilesIdGet', 'id', id)
            const localVarPath = `/pim/stop-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateStopProfileCommand} [updateStopProfileCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimStopProfilesIdPut: async (id: string, apiVersion?: string, updateStopProfileCommand?: UpdateStopProfileCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimStopProfilesIdPut', 'id', id)
            const localVarPath = `/pim/stop-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateStopProfileCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateStopProfileCommand} [createStopProfileCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimStopProfilesPost: async (apiVersion?: string, createStopProfileCommand?: CreateStopProfileCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/stop-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createStopProfileCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * StopProfileApi - functional programming interface
        * @export
        */
        export const StopProfileApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = StopProfileApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimStopProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StopProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimStopProfilesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StopProfileApi.pimStopProfilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimStopProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimStopProfilesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StopProfileApi.pimStopProfilesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimStopProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimStopProfilesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StopProfileApi.pimStopProfilesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateStopProfileCommand} [updateStopProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimStopProfilesIdPut(id: string, apiVersion?: string, updateStopProfileCommand?: UpdateStopProfileCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimStopProfilesIdPut(id, apiVersion, updateStopProfileCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StopProfileApi.pimStopProfilesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateStopProfileCommand} [createStopProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimStopProfilesPost(apiVersion?: string, createStopProfileCommand?: CreateStopProfileCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimStopProfilesPost(apiVersion, createStopProfileCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StopProfileApi.pimStopProfilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * StopProfileApi - factory interface
        * @export
        */
        export const StopProfileApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = StopProfileApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimStopProfilesGet(apiVersion?: string, options?: any): AxiosPromise<Array<StopProfile>> {
                return localVarFp.pimStopProfilesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimStopProfilesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimStopProfilesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimStopProfilesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<StopProfile> {
                return localVarFp.pimStopProfilesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateStopProfileCommand} [updateStopProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimStopProfilesIdPut(id: string, apiVersion?: string, updateStopProfileCommand?: UpdateStopProfileCommand, options?: any): AxiosPromise<StopProfile> {
                return localVarFp.pimStopProfilesIdPut(id, apiVersion, updateStopProfileCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateStopProfileCommand} [createStopProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimStopProfilesPost(apiVersion?: string, createStopProfileCommand?: CreateStopProfileCommand, options?: any): AxiosPromise<StopProfile> {
                return localVarFp.pimStopProfilesPost(apiVersion, createStopProfileCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * StopProfileApi - interface
            * @export
            * @interface StopProfileApi
            */
            export interface StopProfileApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof StopProfileApiInterface
                */
            pimStopProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StopProfile>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof StopProfileApiInterface
                */
            pimStopProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof StopProfileApiInterface
                */
            pimStopProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<StopProfile>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateStopProfileCommand} [updateStopProfileCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof StopProfileApiInterface
                */
            pimStopProfilesIdPut(id: string, apiVersion?: string, updateStopProfileCommand?: UpdateStopProfileCommand, options?: RawAxiosRequestConfig): AxiosPromise<StopProfile>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateStopProfileCommand} [createStopProfileCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof StopProfileApiInterface
                */
            pimStopProfilesPost(apiVersion?: string, createStopProfileCommand?: CreateStopProfileCommand, options?: RawAxiosRequestConfig): AxiosPromise<StopProfile>;

            }

        /**
        * StopProfileApi - object-oriented interface
        * @export
        * @class StopProfileApi
        * @extends {BaseAPI}
        */
            export class StopProfileApi extends BaseAPI implements StopProfileApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof StopProfileApi
            */
                public pimStopProfilesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return StopProfileApiFp(this.configuration).pimStopProfilesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof StopProfileApi
            */
                public pimStopProfilesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return StopProfileApiFp(this.configuration).pimStopProfilesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof StopProfileApi
            */
                public pimStopProfilesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return StopProfileApiFp(this.configuration).pimStopProfilesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateStopProfileCommand} [updateStopProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof StopProfileApi
            */
                public pimStopProfilesIdPut(id: string, apiVersion?: string, updateStopProfileCommand?: UpdateStopProfileCommand, options?: RawAxiosRequestConfig) {
                return StopProfileApiFp(this.configuration).pimStopProfilesIdPut(id, apiVersion, updateStopProfileCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateStopProfileCommand} [createStopProfileCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof StopProfileApi
            */
                public pimStopProfilesPost(apiVersion?: string, createStopProfileCommand?: CreateStopProfileCommand, options?: RawAxiosRequestConfig) {
                return StopProfileApiFp(this.configuration).pimStopProfilesPost(apiVersion, createStopProfileCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * SystemAdminApi - axios parameter creator
    * @export
    */
    export const SystemAdminApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {EnqueueNeutDeduplicationCommand} [enqueueNeutDeduplicationCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deduplicateNeuten: async (apiVersion?: string, enqueueNeutDeduplicationCommand?: EnqueueNeutDeduplicationCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/system/deduplicate-neuten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(enqueueNeutDeduplicationCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deduplicateProfiles: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/system/deduplicate-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {EnqueueSillDeduplicationCommand} [enqueueSillDeduplicationCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deduplicateSillsAsync: async (apiVersion?: string, enqueueSillDeduplicationCommand?: EnqueueSillDeduplicationCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/system/deduplicate-sills-async`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(enqueueSillDeduplicationCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        deduplicateWallConnections: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/system/deduplicate-wall-connectionas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        enqueueReSaveConfiguredSills: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/system/enqueue-resave-sills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimSystemReindexProfilesPost: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/system/reindex-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pmSystemReindexProductionitemsPost: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/system/reindex-productionitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        productionItemsSagas: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pm/system/productionitems-saga`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        rehashOrderAddresses: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sm/system/rehash-order-addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        updateWallCollectionStatistics: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/system/update-wall-connection-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * SystemAdminApi - functional programming interface
        * @export
        */
        export const SystemAdminApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = SystemAdminApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {EnqueueNeutDeduplicationCommand} [enqueueNeutDeduplicationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deduplicateNeuten(apiVersion?: string, enqueueNeutDeduplicationCommand?: EnqueueNeutDeduplicationCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deduplicateNeuten(apiVersion, enqueueNeutDeduplicationCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.deduplicateNeuten']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deduplicateProfiles(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deduplicateProfiles(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.deduplicateProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {EnqueueSillDeduplicationCommand} [enqueueSillDeduplicationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deduplicateSillsAsync(apiVersion?: string, enqueueSillDeduplicationCommand?: EnqueueSillDeduplicationCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deduplicateSillsAsync(apiVersion, enqueueSillDeduplicationCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.deduplicateSillsAsync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async deduplicateWallConnections(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deduplicateWallConnections(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.deduplicateWallConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async enqueueReSaveConfiguredSills(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueueReSaveConfiguredSills(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.enqueueReSaveConfiguredSills']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimSystemReindexProfilesPost(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimSystemReindexProfilesPost(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.pimSystemReindexProfilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pmSystemReindexProductionitemsPost(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pmSystemReindexProductionitemsPost(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.pmSystemReindexProductionitemsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async productionItemsSagas(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionItemsSaga>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productionItemsSagas(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.productionItemsSagas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async rehashOrderAddresses(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rehashOrderAddresses(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.rehashOrderAddresses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async updateWallCollectionStatistics(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWallCollectionStatistics(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemAdminApi.updateWallCollectionStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * SystemAdminApi - factory interface
        * @export
        */
        export const SystemAdminApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = SystemAdminApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {EnqueueNeutDeduplicationCommand} [enqueueNeutDeduplicationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deduplicateNeuten(apiVersion?: string, enqueueNeutDeduplicationCommand?: EnqueueNeutDeduplicationCommand, options?: any): AxiosPromise<void> {
                return localVarFp.deduplicateNeuten(apiVersion, enqueueNeutDeduplicationCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deduplicateProfiles(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deduplicateProfiles(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {EnqueueSillDeduplicationCommand} [enqueueSillDeduplicationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deduplicateSillsAsync(apiVersion?: string, enqueueSillDeduplicationCommand?: EnqueueSillDeduplicationCommand, options?: any): AxiosPromise<void> {
                return localVarFp.deduplicateSillsAsync(apiVersion, enqueueSillDeduplicationCommand, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            deduplicateWallConnections(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.deduplicateWallConnections(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            enqueueReSaveConfiguredSills(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.enqueueReSaveConfiguredSills(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimSystemReindexProfilesPost(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimSystemReindexProfilesPost(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pmSystemReindexProductionitemsPost(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pmSystemReindexProductionitemsPost(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            productionItemsSagas(apiVersion?: string, options?: any): AxiosPromise<Array<ProductionItemsSaga>> {
                return localVarFp.productionItemsSagas(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            rehashOrderAddresses(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.rehashOrderAddresses(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            updateWallCollectionStatistics(apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.updateWallCollectionStatistics(apiVersion, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * SystemAdminApi - interface
            * @export
            * @interface SystemAdminApi
            */
            export interface SystemAdminApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {EnqueueNeutDeduplicationCommand} [enqueueNeutDeduplicationCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            deduplicateNeuten(apiVersion?: string, enqueueNeutDeduplicationCommand?: EnqueueNeutDeduplicationCommand, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            deduplicateProfiles(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {EnqueueSillDeduplicationCommand} [enqueueSillDeduplicationCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            deduplicateSillsAsync(apiVersion?: string, enqueueSillDeduplicationCommand?: EnqueueSillDeduplicationCommand, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            deduplicateWallConnections(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            enqueueReSaveConfiguredSills(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            pimSystemReindexProfilesPost(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            pmSystemReindexProductionitemsPost(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            productionItemsSagas(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductionItemsSaga>>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            rehashOrderAddresses(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof SystemAdminApiInterface
                */
            updateWallCollectionStatistics(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

            }

        /**
        * SystemAdminApi - object-oriented interface
        * @export
        * @class SystemAdminApi
        * @extends {BaseAPI}
        */
            export class SystemAdminApi extends BaseAPI implements SystemAdminApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {EnqueueNeutDeduplicationCommand} [enqueueNeutDeduplicationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public deduplicateNeuten(apiVersion?: string, enqueueNeutDeduplicationCommand?: EnqueueNeutDeduplicationCommand, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).deduplicateNeuten(apiVersion, enqueueNeutDeduplicationCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public deduplicateProfiles(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).deduplicateProfiles(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {EnqueueSillDeduplicationCommand} [enqueueSillDeduplicationCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public deduplicateSillsAsync(apiVersion?: string, enqueueSillDeduplicationCommand?: EnqueueSillDeduplicationCommand, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).deduplicateSillsAsync(apiVersion, enqueueSillDeduplicationCommand, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public deduplicateWallConnections(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).deduplicateWallConnections(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public enqueueReSaveConfiguredSills(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).enqueueReSaveConfiguredSills(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public pimSystemReindexProfilesPost(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).pimSystemReindexProfilesPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public pmSystemReindexProductionitemsPost(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).pmSystemReindexProductionitemsPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public productionItemsSagas(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).productionItemsSagas(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public rehashOrderAddresses(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).rehashOrderAddresses(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof SystemAdminApi
            */
                public updateWallCollectionStatistics(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return SystemAdminApiFp(this.configuration).updateWallCollectionStatistics(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
            /**
    * TurboholTypeApi - axios parameter creator
    * @export
    */
    export const TurboholTypeApiAxiosParamCreator = function (configuration?: ClientConfig) {
    return {
        /**
        * 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimTurboholTypesGet: async (apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/turbohol-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimTurboholTypesIdDelete: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimTurboholTypesIdDelete', 'id', id)
            const localVarPath = `/pim/turbohol-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimTurboholTypesIdGet: async (id: string, apiVersion?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimTurboholTypesIdGet', 'id', id)
            const localVarPath = `/pim/turbohol-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} id 
            * @param {string} [apiVersion] The requested API version
            * @param {UpdateTurboholTypeRequest} [updateTurboholTypeRequest] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimTurboholTypesIdPut: async (id: string, apiVersion?: string, updateTurboholTypeRequest?: UpdateTurboholTypeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
                    // verify required parameter 'id' is not null or undefined
                    assertParamExists('pimTurboholTypesIdPut', 'id', id)
            const localVarPath = `/pim/turbohol-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(updateTurboholTypeRequest, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        /**
        * 
            * @param {string} [apiVersion] The requested API version
            * @param {CreateTurboholTypeCommand} [createTurboholTypeCommand] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        pimTurboholTypesPost: async (apiVersion?: string, createTurboholTypeCommand?: CreateTurboholTypeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pim/turbohol-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
            baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

                // authentication oauth2 required
                    // oauth required
                    await setOAuthToObject(localVarHeaderParameter, "oauth2", ["https://app-api-gateway-tst.bravepond-4a530b93.westeurope.azurecontainerapps.io", "openid", "profile", "email"], configuration)

                    if (apiVersion !== undefined) {
                                    localVarQueryParameter['api-version'] = apiVersion;
                    }


        
                    localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
                localVarRequestOptions.data = serializeDataIfNeeded(createTurboholTypeCommand, localVarRequestOptions, configuration)

            return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
            };
            },
        }
        };

        /**
        * TurboholTypeApi - functional programming interface
        * @export
        */
        export const TurboholTypeApiFp = function(configuration?: ClientConfig) {
        const localVarAxiosParamCreator = TurboholTypeApiAxiosParamCreator(configuration)
        return {
            /**
            * 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimTurboholTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TurboholType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimTurboholTypesGet(apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TurboholTypeApi.pimTurboholTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimTurboholTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimTurboholTypesIdDelete(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TurboholTypeApi.pimTurboholTypesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimTurboholTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurboholType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimTurboholTypesIdGet(id, apiVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TurboholTypeApi.pimTurboholTypesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} id 
                * @param {string} [apiVersion] The requested API version
                * @param {UpdateTurboholTypeRequest} [updateTurboholTypeRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimTurboholTypesIdPut(id: string, apiVersion?: string, updateTurboholTypeRequest?: UpdateTurboholTypeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurboholType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimTurboholTypesIdPut(id, apiVersion, updateTurboholTypeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TurboholTypeApi.pimTurboholTypesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
            /**
            * 
                * @param {string} [apiVersion] The requested API version
                * @param {CreateTurboholTypeCommand} [createTurboholTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            async pimTurboholTypesPost(apiVersion?: string, createTurboholTypeCommand?: CreateTurboholTypeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurboholType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pimTurboholTypesPost(apiVersion, createTurboholTypeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TurboholTypeApi.pimTurboholTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            },
        }
        };

        /**
        * TurboholTypeApi - factory interface
        * @export
        */
        export const TurboholTypeApiFactory = function (configuration?: ClientConfig, basePath?: string, axios?: AxiosInstance) {
        const localVarFp = TurboholTypeApiFp(configuration)
        return {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimTurboholTypesGet(apiVersion?: string, options?: any): AxiosPromise<Array<TurboholType>> {
                return localVarFp.pimTurboholTypesGet(apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimTurboholTypesIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
                return localVarFp.pimTurboholTypesIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimTurboholTypesIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<TurboholType> {
                return localVarFp.pimTurboholTypesIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateTurboholTypeRequest} [updateTurboholTypeRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimTurboholTypesIdPut(id: string, apiVersion?: string, updateTurboholTypeRequest?: UpdateTurboholTypeRequest, options?: any): AxiosPromise<TurboholType> {
                return localVarFp.pimTurboholTypesIdPut(id, apiVersion, updateTurboholTypeRequest, options).then((request) => request(axios, basePath));
                },
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateTurboholTypeCommand} [createTurboholTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            */
            pimTurboholTypesPost(apiVersion?: string, createTurboholTypeCommand?: CreateTurboholTypeCommand, options?: any): AxiosPromise<TurboholType> {
                return localVarFp.pimTurboholTypesPost(apiVersion, createTurboholTypeCommand, options).then((request) => request(axios, basePath));
                },
        };
        };

            /**
            * TurboholTypeApi - interface
            * @export
            * @interface TurboholTypeApi
            */
            export interface TurboholTypeApiInterface {
                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof TurboholTypeApiInterface
                */
            pimTurboholTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TurboholType>>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof TurboholTypeApiInterface
                */
            pimTurboholTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof TurboholTypeApiInterface
                */
            pimTurboholTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig): AxiosPromise<TurboholType>;

                /**
                * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateTurboholTypeRequest} [updateTurboholTypeRequest] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof TurboholTypeApiInterface
                */
            pimTurboholTypesIdPut(id: string, apiVersion?: string, updateTurboholTypeRequest?: UpdateTurboholTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<TurboholType>;

                /**
                * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateTurboholTypeCommand} [createTurboholTypeCommand] 
                * @param {*} [options] Override http request option.
                * @throws {RequiredError}
                * @memberof TurboholTypeApiInterface
                */
            pimTurboholTypesPost(apiVersion?: string, createTurboholTypeCommand?: CreateTurboholTypeCommand, options?: RawAxiosRequestConfig): AxiosPromise<TurboholType>;

            }

        /**
        * TurboholTypeApi - object-oriented interface
        * @export
        * @class TurboholTypeApi
        * @extends {BaseAPI}
        */
            export class TurboholTypeApi extends BaseAPI implements TurboholTypeApiInterface {
            /**
            * 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof TurboholTypeApi
            */
                public pimTurboholTypesGet(apiVersion?: string, options?: RawAxiosRequestConfig) {
                return TurboholTypeApiFp(this.configuration).pimTurboholTypesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof TurboholTypeApi
            */
                public pimTurboholTypesIdDelete(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return TurboholTypeApiFp(this.configuration).pimTurboholTypesIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof TurboholTypeApi
            */
                public pimTurboholTypesIdGet(id: string, apiVersion?: string, options?: RawAxiosRequestConfig) {
                return TurboholTypeApiFp(this.configuration).pimTurboholTypesIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} id 
                    * @param {string} [apiVersion] The requested API version
                    * @param {UpdateTurboholTypeRequest} [updateTurboholTypeRequest] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof TurboholTypeApi
            */
                public pimTurboholTypesIdPut(id: string, apiVersion?: string, updateTurboholTypeRequest?: UpdateTurboholTypeRequest, options?: RawAxiosRequestConfig) {
                return TurboholTypeApiFp(this.configuration).pimTurboholTypesIdPut(id, apiVersion, updateTurboholTypeRequest, options).then((request) => request(this.axios, this.basePath));
                }

            /**
            * 
                    * @param {string} [apiVersion] The requested API version
                    * @param {CreateTurboholTypeCommand} [createTurboholTypeCommand] 
            * @param {*} [options] Override http request option.
            * @throws {RequiredError}
            * @memberof TurboholTypeApi
            */
                public pimTurboholTypesPost(apiVersion?: string, createTurboholTypeCommand?: CreateTurboholTypeCommand, options?: RawAxiosRequestConfig) {
                return TurboholTypeApiFp(this.configuration).pimTurboholTypesPost(apiVersion, createTurboholTypeCommand, options).then((request) => request(this.axios, this.basePath));
                }
        }


    
