import React, {useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCardBody} from "../../../../shared/components/card/EkoCardBody";
import {EkoCard} from "../../../../shared/components/card/EkoCard";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import {EkoCardToolbar} from "../../../../shared/components/card/EkoCardToolbar";
import {NeutPicker} from "../../pim/components/NeutPicker";
import TextField from "../../../../shared/components/TextField";
import {usePostLosseNeutenBatch} from "../../pim/neut/use-post-neut-production-list";
import {FieldArray, Form, Formik, FormikHelpers} from "formik";
import {NeutProductionItemRepresentation} from "../../../../modules/api-client/generated";
import ConfiguredNeutSvg from "../../../../shared/components/ConfiguredNeutSvg";

type ProductionListItem = NeutProductionItemRepresentation & {
    code: string,
    svg: string,
}

export interface ProductionListItemFormValues {
    title: string,
    remark?: string,
    items: ProductionListItem[]
}

const CreateNeutenBatch: React.FC = () => {

    const {mutate, serverValidationErrors} = usePostLosseNeutenBatch();
    const createBatch = (values: ProductionListItemFormValues, {setSubmitting}: FormikHelpers<ProductionListItemFormValues>) => {

        return new Promise<void>((resolve, reject) => {
            mutate(values, {
                onSuccess: () => {
                    resolve();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    const [initialValues, setInitialValues] = useState<ProductionListItemFormValues>({
        items: [],
        remark: "",
        title: ""
    })

    const addNeut = (id: string, code: string, svg: string) => {
        if (id && code) {
            const newValues = {...initialValues};
            newValues.items.push({
                id: id,
                code: code,
                svg: svg,
                quantity: 1,
                remark: ""
            });
            setInitialValues({...newValues, ...initialValues});
        }
    }

    return (
        <>
            <PageTitle>Neuten batch aanmaken</PageTitle>
            <div className="input-group mb-3">
                <NeutPicker fieldName={'neutId'} onChange={addNeut}/>
            </div>
            <Formik
                initialValues={initialValues}
                // enableReinitialize
                onSubmit={createBatch}
            >
                {({values}) => (
                    <Form>
                        <EkoCard className={'mb-5'}>
                            <EkoCardHeader title={`Losse neuten batch`}>
                                <EkoCardToolbar>
                                    {/*{Object.keys(productionList).length > 0 &&*/}
                                    {/*    <button className='btn btn-light btn-sm' onClick={() => setProductionList({})}>*/}
                                    {/*        <i className={'fas fa-times me-0'}></i>*/}
                                    {/*    </button>*/}
                                    {/*}*/}
                                </EkoCardToolbar>
                            </EkoCardHeader>
                            <EkoCardBody>

                                {/*<TextField row={true} name={'title'}/>*/}
                                <FieldArray name="items" render={({remove}) => {
                                    return (
                                        <div>
                                            {values.items.length > 0 ?
                                                values.items.map((neut, index) => (
                                                    <div className="d-flex mb-2 align-items-center" key={index}>
                                                        <div className="w-25 me-3 d-flex">
                                                            <ConfiguredNeutSvg svg={neut.svg} svgWidth={'65px'}
                                                                               svgHeight={'95px'}/>
                                                            <div className={'form-control-plaintext fw-bold'}>
                                                                {neut.code}
                                                            </div>
                                                        </div>
                                                        <div className="w-25 me-3">
                                                            <TextField
                                                                name={`items.${index}.quantity`}
                                                                placeholder="1"
                                                                min={1}
                                                                type="number"
                                                            />
                                                        </div>
                                                        <div className="w-35 me-3">
                                                            <TextField
                                                                name={`items.${index}.remark`}
                                                                placeholder="..."
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="w-10 d-flex align-items-center">
                                                            <div
                                                                className="badge badge-circle bg-secondary ms-auto"
                                                                onClick={() => remove(index)}
                                                            >
                                                                <i className={'fas fa-times'}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <>Geen neuten geselecteerd...</>
                                            }

                                        </div>)
                                }
                                }
                                />
                                <hr/>
                                <TextField row={false} placeholder={'Optionele opmerking...'}
                                           name={'remark'}/>

                            </EkoCardBody>
                            <div className="card-footer">

                                <button type="submit" className={'btn btn-primary'}>Batch aanmaken</button>

                            </div>
                        </EkoCard>
                    </Form>
                )}
            </Formik>

        </>
    )
}

export default CreateNeutenBatch
