import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {NotFound} from '../../../../../modules/api-client/Responses';

export const useDeleteImportProfileMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<void, NotFound, string>(
		(importProfileMappingId) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingProfileIdDelete(importProfileMappingId).then((response: any) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingImportedProfileMapsCustomerIdGet']);
				queryClient.invalidateQueries(['aclImportMappingProfileIdGet']);
				
				toast.success(`Profiel mapping successvol verwijderd`);
			},
			onError: () => {
				toast.error('Profiel mapping verwijderen mislukt');
			},
		}
	);
};
