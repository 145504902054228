import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {AxiosError} from 'axios';
import {toast} from 'react-hot-toast';
import {isNotEmpty} from '../../../../../../_metronic/helpers';
import {ImportSluitpotMappingRepresentation} from '../../../../../modules/api-client/generated';

export const useGetImportSluitpotMapping = (importSluitpotMappingId: string | undefined, enabled: boolean = true) => {
	const response = useQuery<ImportSluitpotMappingRepresentation, AxiosError, ImportSluitpotMappingRepresentation>(
		['aclImportMappingSluitpotIdGet', importSluitpotMappingId],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSluitpotIdGet(importSluitpotMappingId!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(importSluitpotMappingId),
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};
