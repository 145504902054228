import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {ValidationFailed} from '../../../../../modules/api-client/Responses';
import {ResolveImportValidationCommand} from '../../../../../modules/api-client/generated';

export const usePutValidationResolve = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<void, ValidationFailed, ResolveImportValidationCommand>(
		(command) => {
			return ApiClient.Acl.ImportValidation.aclImportValidationResolvePut(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportValidationImportValidationIdGet']);
				queryClient.invalidateQueries(['aclImportValidationListImportIdGet']);
				queryClient.invalidateQueries(['aclImportIdGet']);
				queryClient.invalidateQueries(['aclImportMappingImportedProfileMapsCustomerIdGet']);
				queryClient.invalidateQueries(['aclImportMappingSluitpotCustomerCustomerIdGet']);
			},
			onError: () => {
				toast.error('Oplossen mislukt!');
			},
		}
	);
};
