import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePostCopyGroeneveldImport = () => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, string>((importId) => {
        return ApiClient.Acl.Groeneveld.aclGroeneveldCopyImportIdPost(importId).then((response: any)=> response.data);
    },
    {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['aclImportListOdataGet'])                        
            toast.success(`Groeneveld import wordt gekopieerd.`);
        },
        onError: () => {
            toast.error("Groeneveld import kopieren mislukt.");
        }
    }
    );
};
