/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../../../../../_metronic/layout/core';
import {EkoCard, EkoCardBody} from '../../../../../../_metronic/helpers';
import {FilterContextProvider} from '../../../../../shared/context/FilterContext';
import {CustomerSearchFilter} from '../../../../../shared/import/components/CustomerSearchFilter';
import {CustomerImportProfileMappings} from '../components/CustomerImportProfileMappings';

export function CustomerImportProfileMappingsPage() {
	return (
		<>
			<PageTitle>Import Profiel Mappings</PageTitle>
			<EkoCard>
				<EkoCardBody>
					<FilterContextProvider>
						<CustomerSearchFilter />
						<CustomerImportProfileMappings />
					</FilterContextProvider>
				</EkoCardBody>
			</EkoCard>
		</>
	);
}
