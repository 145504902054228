/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {Outlet, useParams} from "react-router-dom";
import {

    ImportedProfileMappingRepresentation, MappingKind
} from "../../../../../../modules/api-client/generated";
import ProfileMatchPreviewSvg from "../../../../../../shared/components/ProfileMatchPreviewSvg";
import {ProfileMapperModal} from "../../../../acl/import/components/ProfileMapperModal";
import {EkoCard, EkoCardBody} from "../../../../../../shared/components/card";
import {
    useGetCustomerImportedProfileMappings
} from '../../../../acl/import/hooks/use-get-customer-imported-profile-mappings';


const CustomerImportProfileMapping: React.FC = () => {

    const {id} = useParams<{ id: string }>();

    const {isInitialLoading, data: customerImportProfileMappings, isError} = useGetCustomerImportedProfileMappings(id);
    const [selectedProfile, setSelectedProfile] = useState<ImportedProfileMappingRepresentation | undefined>(undefined);

    const groupedProfiles = customerImportProfileMappings?.reduce((acc, profile) => {
        const {sillCode, compartmentLayoutName} = profile;

        if (!acc[sillCode]) {
            acc[sillCode] = {};
        }

        if (!acc[sillCode][compartmentLayoutName]) {
            acc[sillCode][compartmentLayoutName] = [];
        }

        acc[sillCode][compartmentLayoutName].push(profile);

        return acc;
    }, {} as Record<string, Record<string, ImportedProfileMappingRepresentation[]>>);


    const nonMappedProfiles = customerImportProfileMappings ? customerImportProfileMappings.filter((x) => !x.mappedProfileId) : [];
    const mappedProfiles = customerImportProfileMappings ? customerImportProfileMappings.filter((x) => x.mappedProfileId) : [];

    if (isInitialLoading) {
        return <>Laden...</>;
    }

    if (isError) {
        return <>Fout bij het ophalen van de profielen!</>;
    }

    return (
        <>
            <Outlet/>
            {(!customerImportProfileMappings || !customerImportProfileMappings) ?
                <div className={'alert alert-warning'}>
                    Er zijn nog geen profielen geïmporteerd voor deze klant.
                </div>
                :
                <>

                    {selectedProfile && (
                        <ProfileMapperModal profile={selectedProfile!}
                                            show={true} handleClose={() => {
                            setSelectedProfile(undefined);
                        }}></ProfileMapperModal>
                    )}
                    <div className={'lead'}>
                        <p>
                            Op deze pagina kunt u de profiel koppeling tussen uw werkvoorbereidingspakket en Ekosiet
                            (EDS)
                            beheren.
                            U kiest per dorpel en vakindeling hoe het geimporteerde profiel gekoppeld moet worden aan
                            een
                            EDS profiel. Het kan dus zijn dat een geïmporteerd profiel meerdere keren voorkomt.
                        </p>
                        <p>
                            Bij het importeren van een export bestand lezen we de geometrie van de profielen in. Deze
                            geometrie moet gekoppeld worden aan een EDS profiel. Om te voorkomen dat u dit voor elke
                            import
                            opnieuw moet doen, kunt u de koppelingen opslaan. Bij een volgende import worden deze
                            koppelingen dan automatisch toegepast.
                        </p>
                        <p>
                            Er zijn 2 type koppelingen:<br/>
                            <strong><MappingKindBadge kind={MappingKind.Definitive}/></strong>:  Een definitieve koppeling wordt automatisch toegepast bij
                            een volgende import, u krijgt hier geen melding meer van.
                            <br/>
                            <strong><MappingKindBadge kind={MappingKind.Suggestion}/></strong>: Een suggestie wordt bij een volgende import voorgesteld, de
                            suggestie wordt getoond en u dient deze te bevestigen of een ander profiel te kiezen.
                        </p>
                        <p>
                        </p>
                    </div>

                    <div className={'alert alert-info'}>
                        De groene lijn is het geimporteerde profiel. De paarse lijn is het gekoppelde EDS profiel.
                        De maatvoering is voor het geimporteerde profiel.
                        <br/>
                        Voor GA profielen geldt: de radiussen komen helaas niet mee in de export, kies daarom een
                        profiel met de juiste radiussen.
                    </div>


                    {Object.keys(groupedProfiles!).map((sillCode) => (
                        <div key={sillCode}>
                            <h1>Dorpel: {sillCode}</h1>
                            {Object.keys(groupedProfiles![sillCode]).map((compartmentLayoutTypeId) => (
                                <div key={compartmentLayoutTypeId}>
                                    <h2>Vakindeling: {compartmentLayoutTypeId}</h2>
                                    <div className={'row mt-4'}>
                                        {groupedProfiles![sillCode][compartmentLayoutTypeId].map((profile: ImportedProfileMappingRepresentation, index: number) => (
                                            <div key={index} className={'col-6 col-md-4 col-lg-3 col-xl-2 mb-4'}>
                                                <ProfileCard
                                                    profile={profile}
                                                    onClick={() => {
                                                        setSelectedProfile(profile);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    {/*{nonMappedProfiles && nonMappedProfiles.length > 0 && (*/}
                    {/*    <>*/}
                    {/*        <h1>Niet gekoppelde profielen</h1>*/}
                    {/*        <div className={'row mt-4'}>*/}
                    {/*            {nonMappedProfiles.map((item: ImportedProfileMappingRepresentation, index: number) => (*/}
                    {/*                <div key={index} className={'col-6 col-md-4 col-lg-3 col-xl-2 mb-4'}>*/}
                    {/*                    <ProfileCard profile={item} onClick={() => {*/}
                    {/*                        setSelectedProfile(item);*/}
                    {/*                    }}/>*/}
                    {/*                </div>*/}
                    {/*            ))}*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*)}*/}
                    {/*{mappedProfiles && mappedProfiles.length > 0 &&*/}
                    {/*    (*/}
                    {/*        <>*/}
                    {/*            <h1>Gekoppelde profielen</h1>*/}
                    {/*            <div className={'row mt-4'}>*/}
                    {/*                {mappedProfiles.map((item: ImportedProfileMappingRepresentation, index: number) => (*/}
                    {/*                    <div key={index} className={'col-6 col-md-4 col-lg-3 col-xl-2 mb-4'}>*/}
                    {/*                        <ProfileCard profile={item} onClick={() => {*/}
                    {/*                            setSelectedProfile(item);*/}
                    {/*                        }}/>*/}
                    {/*                    </div>*/}
                    {/*                ))}*/}
                    {/*            </div>*/}
                    {/*        </>*/}
                    {/*    )*/}

                    {/*}*/}
                </>
            }
        </>
    )
        ;
};

interface ProfileCardProps {
    profile: ImportedProfileMappingRepresentation;
    onClick: () => void;
}

const ProfileCard: React.FC<ProfileCardProps> = ({profile, onClick}) => {
    return (
        <EkoCard className={'border cursor-pointer'}>
            <div  style={{position:'absolute', left:'-8px', top:'15px'}}>
                <MappingKindBadge kind={profile.kind!}/>

            </div>

            <div className={'p-5 pb-0'} onClick={onClick}>
                <ProfileMatchPreviewSvg profileA={profile.leftProfileGeometry}
                                        profileB={profile.leftMappedProfileGeometryAsString}/>
            </div>
            {profile.mappedProfileId && (
                <EkoCardBody>
                    <div>{profile.mappedProfileName}</div>
                    <a href={'#'} onClick={onClick} className={'stretched-link'}></a>
                </EkoCardBody>
            )}
        </EkoCard>
    );
};


const MappingKindBadge: React.FC<{ kind: MappingKind }> = ({kind}) => {
    switch (kind){
        case MappingKind.Suggestion:
            return <span className={'badge bg-info'}>Suggestie</span>;
        case  MappingKind.Definitive:
            return <span className={'badge bg-danger'}>Definitief</span>;
    }
    return (
        <span className={'badge bg-primary'}>{kind}</span>
    );
}

export {CustomerImportProfileMapping};
