import React, {CSSProperties, ReactNode, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useMutation} from "@tanstack/react-query";
import {useDragging} from "../../../../shared/hooks/useDragging";
import ProfileScanDialog from "./ProfileScanDialog";
import instance from "../../../../modules/api-client/AxiosClient";

export interface ProfileDropzoneProps {
    preview: (profile: string) => ReactNode;
    onSelect?: (selected: string) => void;
    children?: ((config: ProfileDropzoneConfig) => React.ReactNode) | React.ReactNode;
    show?: 'drag' | 'always'
    disabled?: boolean;
}

const DefaultProfileDropzoneProps = {
    show: 'drag',
    disabled: false,
}

export interface ProfileDropzoneConfig {
    openFileDialog: () => void;
    disabled: boolean;
}

export interface DfxScan {
    profile: string;

    width: number;
    height: number;
    svg: string;
}


const ProfileDropzone: React.FC<ProfileDropzoneProps> = (props) => {

    const [scans, setScans] = useState<DfxScan[] | null>(null);
    const [scanError, setScanError] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const scanOnServer = (f: File) => {

        setScanError(null);
        setScans(null);

        const formData = new FormData();
        formData.append("files", f);
        return instance.post(`/dxf/scan`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => res.data);
    };

    const scanMutation = useMutation<DfxScan[], any, File>(scanOnServer, {
        onSuccess: data => {
            if (data.length === 0) {
                setScanError('Profiel kon niet worden herkend.')
                return;
            }
            // if (data.length === 1) {
            //     select(data[0]);
            // }

            setScanError(null);
            setScans(data);
        },
        onError: (error: any) => {
            setScanError(`${error}`);
        }
    });


    const scan = (f: File) => {
        scanMutation.mutate(f);
        setDialogOpen(true);
    };

    const {getRootProps, getInputProps, open} = useDropzone({
        // maxFiles: 1
        multiple: false,
        accept: {
            'application/dxf': ['.dxf']
        },
        disabled: props.disabled,
        onDropAccepted: acceptedFiles => {
            if (acceptedFiles.length > 0) {
                setFile(acceptedFiles[0]);
                scan(acceptedFiles[0]);
            }
        },
        onDrop: () => {

        },
        onDropRejected: (fileRejections) => {

            const rejection = fileRejections[0];
            const message = rejection.errors[0].code === 'file-invalid-type'
                ? 'Alleen .dxf bestanden zijn toegestaan.'
                : rejection.errors[0].message;

            setScanError(message);
            setDialogOpen(true);
        },
        onError: e => {
            setScanError(e.message);
        }
    });

    const onConfirm = (selected: string) => {
        props.onSelect?.call(this, selected);
        setDialogOpen(false);
    };


    const childNodes = typeof (props.children) === 'function' ? props.children.call(this, {
        openFileDialog: open,
        disabled: props.disabled ?? DefaultProfileDropzoneProps.disabled
    }) : props.children;


    const dragging = useDragging();

    const visible = props.show === "always" || (props.show === "drag" && dragging);

    const style: CSSProperties = {
        position: 'absolute',
        inset: visible ? '0' : '-10000px -10000px 20000px 20000px',

        visibility: visible ? 'visible' : 'collapse',
        display: visible ? 'flex' : 'none',
        opacity: visible ? '.8' : '0',
    };
    return (
        <>
            {!props.disabled &&
                <div {...getRootProps({
                    className: 'dropzone h-100 flex-column justify-content-center',
                    style: style
                })}>
                    <div className="m-2">
                        <input {...getInputProps()} />
                        <div className="h3">
                            Sleep een .dxf bestand hier
                        </div>
                    </div>
                </div>
            }
            {childNodes}
            {!props.disabled &&
                <ProfileScanDialog filename={file?.name}
                                   scanning={scanMutation.isLoading}
                                   error={scanError}
                                   scans={scans}
                                   show={dialogOpen}
                                   onHide={() => setDialogOpen(false)}
                                   onConfirm={onConfirm} rescan={() => scan(file!)}
                                   preview={props.preview}

                />
            }
        </>
    );
};

export default ProfileDropzone;