import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CompartmentLayoutType,
    CreateCompartmentLayoutTypeCommand,
    UpdateCompartmentLayoutTypeCommand
} from "../../../../modules/api-client/generated";
import {FormValues} from "./CompartmentLayoutTypeUpsert";


export const useCompartmentLayoutTypeUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<CompartmentLayoutType, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateCompartmentLayoutTypeCommand | UpdateCompartmentLayoutTypeCommand = {
                code: values.code,
                name: values.name,
                rabbetPosition: values.rabbetPosition,
                sluitpotBeslagIds: values.sluitpotBeslagIds,
            };

            if (id) {
                return ApiClient.Pim.CompartmentLayoutType.pimCompartmentLayoutTypesIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.CompartmentLayoutType.pimCompartmentLayoutTypesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCompartmentLayoutTypeIdGet', id]).then();
                queryClient.invalidateQueries(['pimCompartmentLayoutTypesGet']).then();
            }
        }
    );
};

export const useCompartmentLayoutTypeQuery = (id: string | undefined) => {
    return useQuery(['pimCompartmentLayoutTypeIdGet', id],
        () => {
            return ApiClient.Pim.CompartmentLayoutType.pimCompartmentLayoutTypesIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}


export const useCompartmentLayoutTypeDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.CompartmentLayoutType.pimCompartmentLayoutTypesIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCompartmentLayoutTypeIdGet', id]).then();
                queryClient.invalidateQueries(['pimCompartmentLayoutTypesGet']).then();
            }
        }
    );
};



