import {Route, Routes, Navigate} from 'react-router-dom'
import {ErrorBoundary, FallbackProps} from "react-error-boundary";

import React from "react";
import axios from "axios";
import {BackofficeRoutePath} from "../../RoutePath";
import RabbetList from "../pim/rabbet/RabbetList";
import {MasterData} from "../../pages/MasterData";
import RabbetUpsert from "../pim/rabbet/RabbetUpsert";
import {RabbetDetails} from '../pim/rabbet';
import ColorList from "../pim/color/ColorList";
import CornerPieceList from "../pim/cornerpiece/CornerPieceList";
import CategoryList from "../pim/category/CategoryList";
import DrainageOptionList from "../pim/drainage-options/DrainageOptionList";
import MountingOptionList from "../pim/mounting-options/MountingOptionList";
import WrappingOptionList from "../pim/wrapping-options/WrappingOptionList";
import GlazingBarList from "../pim/glazingbar/GlazingBarList";
import GlazingProfileList from "../pim/glazingprofile/GlazingProfileList";
import StopProfileList from "../pim/stopprofile/StopProfileList";
import SluitpotList from "../pim/sluitpot/SluitpotList";
import DiscountGroupList from "../pim/discountgroup/DiscountGroupList";
import CatalogList from "../pim/catalog/CatalogList";
import PriceListList from "../pim/pricelist/PriceListList";
import DiscountRuleList from "../pim/discountrule/DiscountRuleList";
import TurboholTypeList from "../pim/turbohol-type/TurboholTypeList";
import CompartmentLayoutTypeList from "../pim/compartment-layout-type/CompartmentLayoutTypeList";
import SluitpotBeslagList from "../pim/sluitpotbeslag/SluitpotBeslagList";
import SluitpotBlockTypeList from "../pim/sluitpot-block-type/SluitpotBlockTypeList";

export const MasterdataRoutePath = {


    rabbetList: 'sponningen',
    rabbetAdd: 'sponningen/add',
    rabbetEdit: 'sponningen/:id/edit',
    rabbetDetails: 'sponningen/:id',

    colorList: 'kleur',
    cornerPieceList: 'hoekstuk',
    drainageOptionList: 'afwatering',
    mountingOptionList: 'bevestiging',
    wrappingOptionList: 'inpakken',
    glazingBarList: 'glaslat',
    glazingProfileList: 'beglazingsprofiel',
    stopProfileList: 'aanslagprofiel',
    sluitpotList: 'sluitpot',
    beslagList: 'beslag',
    sluitpotBlockTypeList: 'sluitpotBlokType',
    drillHoleList: 'boorgat',

    turboholTypeList: 'turboholtype',
    compartmentLayoutType: 'Vakindeling',

    categoryList: 'categorie',
    discountGroupList: 'kortingsgroep',
    discountRuleList: 'kortingsregel',
    catalogList: 'catalogus',
    pricelistList: 'prijslijst',


    link(path:string){
        return BackofficeRoutePath.masterdata+path;
    },

};

const MasterdataRoutes = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={Fallback}><MasterData/></ErrorBoundary>}>

                <Route path={MasterdataRoutePath.rabbetList} element={<RabbetList/>}/>
                <Route path={MasterdataRoutePath.rabbetAdd} element={<RabbetUpsert/>}/>
                <Route path={MasterdataRoutePath.rabbetEdit} element={<RabbetUpsert/>}/>
                <Route path={MasterdataRoutePath.rabbetDetails} element={<RabbetDetails/>}/>

                <Route path={MasterdataRoutePath.colorList} element={<ColorList/>}/>
                <Route path={MasterdataRoutePath.cornerPieceList} element={<CornerPieceList/>}/>
                <Route path={MasterdataRoutePath.drainageOptionList} element={<DrainageOptionList/>}/>
                <Route path={MasterdataRoutePath.mountingOptionList} element={<MountingOptionList/>}/>
                <Route path={MasterdataRoutePath.wrappingOptionList} element={<WrappingOptionList/>}/>
                <Route path={MasterdataRoutePath.glazingBarList} element={<GlazingBarList/>}/>
                <Route path={MasterdataRoutePath.glazingProfileList} element={<GlazingProfileList/>}/>
                <Route path={MasterdataRoutePath.stopProfileList} element={<StopProfileList/>}/>
                <Route path={MasterdataRoutePath.beslagList} element={<SluitpotBeslagList/>}/>
                <Route path={MasterdataRoutePath.sluitpotList} element={<SluitpotList/>}/>
                <Route path={MasterdataRoutePath.sluitpotBlockTypeList} element={<SluitpotBlockTypeList/>}/>
                <Route path={MasterdataRoutePath.turboholTypeList} element={<TurboholTypeList/>}/>
                <Route path={MasterdataRoutePath.compartmentLayoutType} element={<CompartmentLayoutTypeList/>}/>

                <Route path={MasterdataRoutePath.categoryList} element={<CategoryList/>}/>
                <Route path={MasterdataRoutePath.discountGroupList} element={<DiscountGroupList/>}/>
                <Route path={MasterdataRoutePath.discountRuleList} element={<DiscountRuleList/>}/>
                <Route path={MasterdataRoutePath.catalogList} element={<CatalogList/>}/>
                <Route path={MasterdataRoutePath.pricelistList} element={<PriceListList/>}/>

            </Route>
            <Route index element={<Navigate to={MasterdataRoutePath.rabbetList}/>}/>
        </Routes>
    )
}

const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default MasterdataRoutes
