import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {ErrorBoundary, FallbackProps} from "react-error-boundary";

import React from "react";
import axios from "axios";
import {BackofficeRoutePath} from "../../RoutePath";
import {MgtDashboard} from "./pages/MgtDashboard";
import {MgtSales} from "./pages/MgtSales";
import {MgtProduction} from "./pages/MgtProduction";
import {MgtExpedition} from "./pages/MgtExpedition";
import {MgtCrm} from "./pages/MgtCrm";
import {MgtCustomer} from "./pages/MgtCustomer";
import {MgtProductionBacklog} from "./pages/MgtProductionBacklog";
import {MgtSillStats} from "./pages/MgtSillStats";

export const MgtRoutePath = {
    dashboard: 'dashboard',
    sales: 'sales',
    sillStats: 'sill-stats',
    production: 'productie',
    productionBacklog: 'productie-backlog',
    expedition: 'expeditie',
    crm: 'crm',
    customer: 'crm/customer/:id',
    link(path:string){
        return BackofficeRoutePath.mgt+path;
    },
    customerLink: (id: string) => {
        return MgtRoutePath.customer.replace(':id', id);
    },

};

const MgtRoutes = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet/></ErrorBoundary>}>
                <Route path={MgtRoutePath.dashboard} element={<MgtDashboard/>}/>
                <Route path={MgtRoutePath.sales} element={<MgtSales/>}/>
                <Route path={MgtRoutePath.production} element={<MgtProduction/>}/>
                <Route path={MgtRoutePath.productionBacklog} element={<MgtProductionBacklog/>}/>
                <Route path={MgtRoutePath.expedition} element={<MgtExpedition/>}/>
                <Route path={MgtRoutePath.customer} element={<MgtCustomer />} />
                <Route path={MgtRoutePath.crm} element={<MgtCrm/>}/>
                <Route path={MgtRoutePath.sillStats} element={<MgtSillStats/>}/>
            </Route>
            <Route index element={<Navigate to={MgtRoutePath.dashboard}/>}/>
        </Routes>
    )
}

const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default MgtRoutes
