import React from "react";
import {API_GATEWAY_BASE_URL} from "../../constants";

export interface SillContourSvgProps {
    profile?: string | null;
    profileSvg?: string | null;
    svgWidth?: string;
    svgHeight?: string;
}

const SillContourSvg: React.FC<SillContourSvgProps> = (props) => {

        if (props.profileSvg) {
            return <div className="preview-svg"
                        dangerouslySetInnerHTML={{__html: props.profileSvg}}/>
        }

        if (props.profile) {
            let imgSrc = props.profile ? `${API_GATEWAY_BASE_URL}/render/geo?geometry=${encodeURIComponent(props.profile)}&` : undefined;

            return <img src={imgSrc} style={{
                width: "100%",
                height: "100%",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain"
            }} alt=""/>
        }

        return <></>;
    }
;

export default SillContourSvg;
