import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import { AxiosError, AxiosResponse } from "axios";
import { NeutListItemArrayODataValue } from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";

export const useListNeutenOdata = (
    $top?: number | undefined, 
    $skip?: number | undefined, 
    $orderBy?: string, 
    $filter?: string, 
    searchTerm?: string, 
    $count: boolean | undefined = false, 
    enabled: boolean | undefined = true
    )
    : UseQueryResult<NeutListItemArrayODataValue, unknown> => {

    if ($filter === "") {
        $filter = undefined
    }

    if (searchTerm === "") {
        searchTerm = undefined
    }

    const key = 'pimNeutenList';

    const response = useQuery(
        [key, $filter, searchTerm ,$top, $skip, $count, $orderBy], () => {
            return ApiClient.Pim.Neuten
                .neutenList($top, $skip, $orderBy, $filter, searchTerm, $count, undefined, undefined)
                .then((d: AxiosResponse<NeutListItemArrayODataValue>) => d.data);
        },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};
