/* eslint-disable jsx-a11y/anchor-is-valid */
import {useNavigate, useParams} from "react-router-dom";
import {useGetImportValidation} from "../../../backoffice/modules/acl/import/hooks/use-get-import-validation";
import Loading from "../../components/Loading";
import {
    GroeneveldImportCompartmentProfileMissingValidation, GroeneveldImportCompartmentProfileSuggestionValidation,
    ImportMappingValidation,
    ImportValidation,
    ImportValidationType
} from "../../../modules/api-client/generated";
import {SillTypeResolver} from "../components/validationResolvers/SillTypeResolver";
import {modalsRoot} from "../../../global-helpers";
import {Button, Modal} from "react-bootstrap";
import {SluitpotResolver} from "../components/validationResolvers/SluitpotResolver";
import {ProfileResolver} from "../components/validationResolvers/ProfileResolver";


export type ValidationResolverProps = {
    validation: ImportValidation;
    show: boolean;
    handleClose: () => void;
};

export function ValidationResolver() {
    const navigate = useNavigate();
    const {id:importId, customerId, validationId} = useParams<{ id:string, customerId:string, validationId: string }>();
    const {isLoading, data: importValidation, isError} = useGetImportValidation(validationId);

    if (isLoading) {
        return <Loading/>;
    }

    if (!importValidation) {
        return <>Geen validatie gevonden</>;
    }

    if (isError) {
        return <>Fout bij het ophalen van de validatie!</>;
    }
    const renderValidationComponent = () => {
        switch (importValidation.validationType) {
            case ImportValidationType.GroeneveldImportSillMappingValidation:
                return <SillTypeResolver validation={importValidation} handleClose={() => navigate(-1)} show={true}/>;
            case ImportValidationType.GroeneveldImportSluitpotBeslagMappingValidation:
                return <SluitpotResolver validation={importValidation as ImportMappingValidation} handleClose={() => navigate(-1)} show={true}/>;                           
            case ImportValidationType.GroeneveldImportCompartmentProfileMissingValidation: 
                // dit is niet correct, todo implement:
            case ImportValidationType.GroeneveldImportCompartmentProfilesNotEqualValidation:            
                return <ProfileResolver customerId={customerId!} validation={importValidation as GroeneveldImportCompartmentProfileMissingValidation} handleClose={() => navigate(-1)} show={true}/>;
            case ImportValidationType.GroeneveldImportCompartmentProfileSuggestionValidation:
                return <ProfileResolver customerId={customerId!} validation={importValidation as GroeneveldImportCompartmentProfileSuggestionValidation} handleClose={() => navigate(-1)} show={true}/>;
                //todo implement
            default:
                return (
                    <Modal
                        container={modalsRoot}
                        tabIndex={-1}
                        aria-hidden="true"
                        autoFocus={true}
                        className="configurator-log-modal"
                        dialogClassName={'modal-dialog-centered'}
                        contentClassName={'shadow-lg'}
                        show={true}
                        onHide={() => navigate(-1)}
                        size="lg"
                        animation={false}
                        backdrop={'static'}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Resolver voor dit type nog niet geimplementeerd.
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="flex-fill">
                            Neem contact op met Ekosiet.
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="link" className="mx-4" onClick={() => navigate(-1)}>
                                Sluit
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
        }
    };

    return (
        <>
            {renderValidationComponent()}
        </>
    );
}

