import React from "react";
import {Field} from "formik";
import {ConfiguratorSluitpotBeslag} from "../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import CustomSelect, {IconOption, OptionsType, SelectIconOption} from "../../../../shared/components/CustomSelect";

type Props = {
    name: string;
    multi?: boolean;
    required?: boolean;
}

export const SluitpotBeslagPicker: React.FC<Props> = ({name, multi=false, required=false}) => {
    
    let options: OptionsType<SelectIconOption> = [];

    const {
        data: list,
    } = useQuery<ConfiguratorSluitpotBeslag[]>(['getSluitpotHardware'], () => ApiClient.Pim.Configurator.getSluitpotHardware().then((res) => res.data));


    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!, imageUrl: item.imageUrl}
        })
    }

    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}            
                component={
                    CustomSelect
                }
                placeholder={multi? "Kies een of meerdere sluitpotten...":'Kies een sluitpot'}
                isMulti={multi}
                isClearable={!required}      
                optionComponent={
                     IconOption
                 }          
            />
        </>
    )
}