import {Dispatch, FC, SetStateAction, useEffect} from 'react'
import {Field, ErrorMessage, useFormikContext, useField} from 'formik'
import {useGetCustomer} from '../../../../../crm/hooks/use-get-customer';
import {CustomerContactPersonPicker} from '../../../../../../../customer-portal/components/CustomerContactPersonPicker'
import {useListCustomerContactPersons} from '../../../../../crm/hooks/use-list-customer-contact-persons'
import {IAddOrderForm} from '../../types/IAddOrderForm';
import TextField from "../../../../../../../shared/components/TextField";
import {useEkosietAuth0} from "../../../../../../../modules/auth0/core/useEkosietAuth0";

export type Props = {
    setFormValues: Dispatch<SetStateAction<IAddOrderForm>>
}

const SelectContactPerson: FC<Props> = ({setFormValues}) => {

    const {values,setValues} = useFormikContext<IAddOrderForm>();
    const [metaCustomerContactPersonId] = useField("orderContactPersonPickerId");
    const {value: valueCustomerContactPersonId} = metaCustomerContactPersonId;
    const {user} = useEkosietAuth0();

    const {data: customerContactPersonsData} = useListCustomerContactPersons(values.customerId);

    useEffect(() => {
        if (valueCustomerContactPersonId === undefined) return;

        const selectedContactPerson =
            customerContactPersonsData?.find(
                contactPerson => contactPerson.id === valueCustomerContactPersonId);

        if (selectedContactPerson) {
            setFormValues({
                ...values,
                contactPerson: {
                    email: selectedContactPerson.email || "",
                    firstName: selectedContactPerson.firstName || "",
                    lastName: selectedContactPerson.lastName || "",
                    telephone: selectedContactPerson.telephone
                }
            });
        } else {
            setFormValues({
                ...values,
                contactPerson: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    telephone: ''
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueCustomerContactPersonId]);

    useEffect(() => {
        if (!customerContactPersonsData || customerContactPersonsData.length === 0) return;

        const selectedContactPerson =
            customerContactPersonsData?.find(
                contactPerson => contactPerson.email === user?.email) || customerContactPersonsData[0];

        if (selectedContactPerson) {
            setValues({
                ...values,
                contactPerson: {
                    email: selectedContactPerson.email || "",
                    firstName: selectedContactPerson.firstName || "",
                    lastName: selectedContactPerson.lastName || "",
                    telephone: selectedContactPerson.telephone
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerContactPersonsData]);

    return (
        <div className='w-100'>
            <div className='mb-5 mb-lg-10'>
                <h2 className='fw-bolder text-dark'>Contactpersoon</h2>
                <div className='text-gray-400 fw-bold fs-6'>
                  Contactpersoon voor deze bestelling.
                </div>
            </div>

            <div className={'row'}>
                <div className={'col'}>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>Bestaande contactpersoon</label>
                        </div>
                        <div className='col'>
                            <CustomerContactPersonPicker fieldName='orderContactPersonPickerId'
                                                         contactPersons={customerContactPersonsData}/>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>Naam</label>
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.firstName'
                                className='form-control form-control-lg '
                            />
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.lastName'
                                className='form-control form-control-lg '
                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>E-mail</label>
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.email'
                                className='form-control form-control-lg '

                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>Telefoonnummer</label>
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.telephone'
                                className='form-control form-control-lg '
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {SelectContactPerson}

