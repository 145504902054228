/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {NavLink, Outlet, useParams} from "react-router-dom";
import clsx from "clsx";
import {useGetCustomerCard} from "../hooks/use-get-customer";
import Loading from "../../../../shared/components/Loading";
import {CrmRoutePath} from "../CrmRoutes";
import Euro from "../../../../shared/components/Euro";
import {DateFromIso} from "../../../../shared/components/date";
import {NumericFormat} from "react-number-format";

export function CustomerPage() {

    const {id} = useParams<{ id: string }>();
    const {isInitialLoading, data: customer} = useGetCustomerCard(id!);
    if (!customer) return null;
    if (isInitialLoading) return <Loading/>

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <div className="me-7 mb-4">
                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                <div
                                    className={clsx(
                                        'symbol-label fs-3',
                                        `bg-light-success`,
                                        `text-dark`
                                    )}
                                >
                                    DD
                                </div>
                                <div
                                    className={clsx(
                                        'position-absolute translate-middle bottom-0 start-100 mb-6',
                                        'rounded-circle border border-4 border-body h-20px w-20px',
                                        {
                                            'bg-success': !customer.isArchived,
                                            'bg-danger': customer.isArchived,
                                        }
                                    )}></div>
                            </div>
                        </div>
                        <div className="flex-grow-1 d-flex flex-column ">
                            <div className="d-flex justify-content-between align-items-start flex-wrap ">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="text-gray-900 fs-2 fw-bold me-1">
                                            {customer.companyName}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-0 pe-2">
                                        <div
                                            className="d-flex align-items-center text-gray-500 me-5 mb-2">
                                            <i className="far fa-address-book me-1"></i>
                                            {customer.code}
                                        </div>
                                        {customer.telephone &&
                                            <div
                                                className="d-flex align-items-center text-gray-500 me-5 mb-2">
                                                <i className="fas fa-phone text-muted me-1"></i>
                                                {customer.telephone}
                                            </div>
                                        }
                                        <div
                                            className="d-flex align-items-center text-gray-500 mb-2">
                                            <i className="fas fa-envelope me-1"></i>
                                            {customer.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap flex-stack">
                                <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap">
                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center fs-2 fw-bold">
                                                <Euro value={customer.sales}/>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-500">Omzet</div>
                                        </div>
                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bold">
                                                    {customer.orderCount}
                                                </div>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-500">Orders</div>
                                        </div>
                                        {customer.growthInSales &&
                                        <div
                                            className={`border border-gray-300 border-dashed bg-light-${customer.growthInSales > 1.2 ? 'success' : customer.growthInSales < 0.8 ? 'danger' : 'light'} rounded min-w-125px py-3 px-4 me-6 mb-3`}>
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bold">
                                                    <span
                                                        title={`${customer.avgSalesLast4Months} / ${customer.avgSalesLast12Months} = ${customer.growthInSales}`}
                                                        className={`bullet bullet-dot bg-${customer.growthInSales > 1.2 ? 'success' : customer.growthInSales < 0.8 ? 'danger' : 'light'} h-15px w-15px me-1`}></span>
                                                            <NumericFormat
                                                                className={'me-1 text-nowrap'} decimalScale={0}
                                                                value={(customer.growthInSales * 100) - 100}
                                                                displayType={'text'}
                                                                thousandSeparator={true} suffix={'%'}/>

                                                </div>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-500">Groei</div>
                                        </div>
                                        }

                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bold">
                                                    {customer.latestOrderDate === null ? '-' :
                                                        <DateFromIso isoDateString={customer.latestOrderDate!}/>}
                                                </div>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-500">Nieuwste order</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerEdit}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Gegevens
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerAddresses}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Adressen
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerOrders}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Orders
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerImport}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Import
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerAnalytics}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Statistieken
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerUsers}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Gebruikers
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerPricelist}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Prijslijst
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerInvoices}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Facturatie
                            </NavLink>
                        </li>
                        <li className="nav-item mt-2">
                            <NavLink to={CrmRoutePath.customerActivity}
                                     className="nav-link text-active-primary ms-0 me-10 py-5">
                                Activiteit
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div
                    className="tab-pane fade show active"
                    id="kt_tab_pane_1"
                    role="tabpanel"
                >
                    <Outlet/>
                </div>
            </div>

        </>
    )
}
