/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {EkoCard, EkoCardBody, KTSVG} from "../../../../../../../_metronic/helpers";
import {Person} from "./Person";
import {
    OrderContactPersonRepresentation, 
    OrderStatusCodes    
} from "../../../../../../modules/api-client/generated";
import {PutContactForm} from "./forms/PutContactForm";
import {PutReferenceForm} from "./forms/PutReferenceForm";
import {formatDate} from "../../../../../../shared/components/date";
import { OrderContactTypes } from '../../hooks/use-put-contact';
import {PutOrderDateForm} from "./forms/PutOrderDateForm";
import {Link} from "react-router-dom";
import {CrmRoutePath} from "../../../../crm/CrmRoutes";

type Props = {
    code?: string
    status?: string
    orderDate?: string
    companyName?: string
    placedDate?: string
    person?:OrderContactPersonRepresentation
    orderId?:string
    customerId?:string
    reference?:string|null
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement| undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const OrderDetails: React.FC<Props> = ({code,orderDate,placedDate,status,companyName,orderId,customerId,person,reference,setShowEditModal,setModalTitle,setModalComponent}) => {

    if (!orderId || !customerId) return (<></>)
    
    var editOrderContact = () => {
        setModalComponent(()=><PutContactForm handleClose={()=>setShowEditModal(false)} contact={person} id={orderId} type={OrderContactTypes.order} />)
        setModalTitle('Wijzig order contact')
        setShowEditModal(true)
    }
    var editReference = () => {
        setModalComponent(()=><PutReferenceForm handleClose={()=>setShowEditModal(false)} reference={reference} id={orderId}/>)
        setModalTitle('Wijzig order referentie')
        setShowEditModal(true)
    }
    var editOrderDate= () => {
        setModalComponent(()=><PutOrderDateForm handleClose={()=>setShowEditModal(false)} orderDate={ new Date(orderDate!)} id={orderId}/>)
        setModalTitle('Wijzig order datum')
        setShowEditModal(true)
    }

  return (
<>
    <EkoCard className={'py-4 flex-row-fluid'} stretch={"stretch"} flush={true}>

          <div className="card-header">
              <div className="card-title w-100 d-flex justify-content-between">
                  <h2>Order Details ({code})</h2>
                  {/*<OrderStatus status={status}/>*/}
              </div>
          </div>
          <EkoCardBody className={'pt-0'}>
              <div className="table-responsive">
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
                      <tbody className="fw-semibold text-gray-600">
                      <tr>
                          <td className="text-muted">
                              <div className="d-flex align-items-center">
                                  <KTSVG path={'/media/icons/duotune/communication/com005.svg'} className='svg-icon-2 me-2' />
                                 Orderdatum

                              </div>
                          </td>
                          <td className="fw-bold text-end">
                              <div className="d-flex align-items-center justify-content-end">
                                  {status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Placed  ||  status === OrderStatusCodes.Completed ?
                                      <a href='#'
                                         onClick={() => editOrderDate()}>
                                              <>{formatDate(orderDate)}</>
                                      </a>
                                      :
                                      <>{formatDate(orderDate)}</>
                                  }
                              </div>
                          </td>
                      </tr>
                      <tr>
                          <td className="text-muted">
                              <div className="d-flex align-items-center">
                                  <KTSVG path={'/media/icons/duotune/communication/com005.svg'} className='svg-icon-2 me-2' />
                                Klant

                              </div>
                          </td>
                          <td className="fw-bold text-end">
                              <div className="d-flex align-items-center justify-content-end">
                                  <Link
                                      className=''
                                      to={CrmRoutePath.link(CrmRoutePath.customerLink(customerId))}>
                                      {companyName}
                                  </Link>
                              </div>
                          </td>
                      </tr>
                      <tr>
                          <td className="text-muted">
                              <div className="d-flex align-items-center">
                                  <KTSVG path={'/media/icons/duotune/communication/com005.svg'} className='svg-icon-2 me-2' />
                                  Contact

                              </div>
                          </td>

                          <td className=" text-end">
                              <div className="d-flex align-items-center justify-content-end">
                                  {status===OrderStatusCodes.Draft || status === OrderStatusCodes.Placed  || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed ?
                                      <a href='#'
                                         onClick={() => editOrderContact()}>
                                          {person ?
                                              <Person person={person}/>
                                              :
                                              <div className={'text-danger'}>
                                                  <i className={'fas fa-exclamation-triangle'}></i> Voeg
                                                  contactpersoon toe
                                              </div>
                                          }
                                      </a>
                                      :
                                      <Person person={person}/>
                                  }
                              </div>
                          </td>
                      </tr>
                      <tr>
                          <td className="text-muted">
                              <div className="d-flex align-items-center">
                                  <KTSVG path={'/media/icons/duotune/communication/com009.svg'}
                                         className='svg-icon-2 me-2'/>
                                   Referentie
                              </div>
                          </td>
                          <td className="text-end">
                              {status===OrderStatusCodes.Draft || status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Placed  ||  status === OrderStatusCodes.Completed ?
                                  <a href='#'
                                     onClick={() => editReference()}>
                                      {reference ?
                                      <>{reference}</>
                                          :
                                          <>Voeg een referentie toe</>
                                      }
                                  </a>
                                  :
                                  <>{reference}</>
                              }
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </EkoCardBody>
      </EkoCard>
</>
  )
}

export {OrderDetails}
