/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import { EkoTable,TableHeader } from "../../../../../shared/components/table";
import { useEkosietFilter } from '../../../../../shared/context/FilterContext';
import { useListInvoicesOdata } from '../hooks/use-list-invoices-odata';
import { formatDate } from "../../../../../shared/components/date";
import { OrderFinancialStatus } from "../../../sm/orders/edit-order/components/OrderFinancialStatus";
import Euro from "../../../../../shared/components/Euro";
import { SmRoutePath } from '../../../sm/SmRoutes';
import { Pagination } from 'react-bootstrap';
import {ExpeditionStatus} from "../../../../../shared/components/ExpeditionStatus";

const InvoiceTable: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [pageSize, setPageSize] = useState(150);
    const [currentPage, setPage] = useState(1);
    const [orderCount, setOrdersCount] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const skip = (currentPage - 1) * pageSize;
    const { filterQuery } = useEkosietFilter()

    const {
        isInitialLoading,
        data: invoiceList,
        isError,
    } = useListInvoicesOdata(pageSize, skip, "CustomerCode desc, OrderCode asc", filterQuery, true);

    useEffect(() => {
        const page = searchParams.get('page');
        const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

        if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
            setPage(pageParamNumber)
        }
    }, [pageCount, searchParams]);

    useEffect(() => {
        if (invoiceList && invoiceList['@odata.count']) {
            setOrdersCount(invoiceList['@odata.count']!)
        }
    }, [invoiceList]);

    useEffect(() => {
        if (orderCount > 0) {
            setPageCount(Math.ceil(orderCount / pageSize))
        }
    }, [orderCount, pageSize]);

    const paginationItems = useMemo(() => {
		let items = []
		for (let number = 1; number <= pageCount; number++) {
			items.push(
				<Pagination.Item key={number} active={number === currentPage} onClick={() => { setSearchParams(`?${new URLSearchParams({ page: number.toString() })}`) }} >
					{number}
				</Pagination.Item>,
			);
		}
		return items
	}, [pageCount, currentPage, setSearchParams]);

    if (isInitialLoading) {
        return <>Laden...</>;
    }

    if (isError) {
        return <>Fout bij het ophalen van de facturen!</>;
    }

    if (!invoiceList || !invoiceList.value) return <></>;

    const sum = invoiceList.value.reduce((sum: number, b) => sum + b.totalPrice, 0);   

    return (
        <>
            <EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
                <TableHeader>
                    <th className='min-w-40px'>Order Code</th>
                    <th className='min-w-140px'>Klant</th>
                    <th className='min-w-50px text-center'>Geplande leverdatum</th>
                    <th className='min-w-50px text-center'>Realiseerde leverdatum</th>
                    <th className='min-w-50px text-center'>Expeditie</th>
                    <th className='min-w-50px text-end'>Totaal</th>
                    <th className='min-w-50px text-end'>Acties</th>
                </TableHeader>
                <tbody>
                    <tr key="first-row-sum">
                        <td colSpan={5}></td>
                        <td className={'text-end'}>
                            <div className='text-dark fw-bold fs-6 '><Euro value={sum} /></div>
                        </td>
                        <td></td>
                    </tr>
                    {invoiceList.value && invoiceList.value.map((invoice) => {
                        return <tr key={invoice.id}>
                            <td>
                                <Link to={SmRoutePath.link(SmRoutePath.orderEditLink(invoice.orderId!))}
                                    className='text-hover-primary'>
                                    {invoice.orderCode}
                                </Link>
                                <div className={'text-muted text-nowrap'}>
                                    {formatDate(invoice.orderDate!, "dd-MM-yy HH:mm")}
                                </div>
                            </td>
                            <td>
                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                    {invoice.customerName}
                                </div>
                                <span className={'text-muted'}>{invoice.reference}</span>

                            </td>
                            <td className='text-center'>
                                <div className={'text-nowrap'}>
                                    {
                                        invoice.plannedExpeditionDate
                                            ? formatDate(invoice.plannedExpeditionDate)
                                            : '-'
                                    }
                                </div>
                            </td>
                            <td className='text-center'>
                                <div className={'text-nowrap'}>
                                    {
                                        invoice.realisedExpeditionDate
                                            ? formatDate(invoice.realisedExpeditionDate)
                                            : '-'
                                    }
                                </div>
                            </td>
                            <td className="text-center">
                                <ExpeditionStatus status={invoice.expeditionStatus} />
                            </td>
                            <td className={'text-end'}>
                                <div className='text-dark fw-bold fs-6 '>€{invoice.totalPrice}</div>
                                <span className='text-muted'>
                                    {invoice.totalProducts} item(s)
                                </span>
                            </td>
                            <td className='text-end'>
                                <OrderFinancialStatus orderId={invoice.orderId} status={invoice.status} />
                            </td>
                        </tr>
                    }
                    )}

                </tbody>
            </EkoTable>

            <div>
                <span className={`text-muted`}>{invoiceList.value.length}/{invoiceList["@odata.count"]} orders</span>
                <Pagination className='justify-content-start mt-3'>{paginationItems}</Pagination>
            </div>

        </>
    )
}

export { InvoiceTable }