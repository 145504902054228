import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {ValidationFailed} from '../../../../../modules/api-client/Responses';
import {CreateImportSluitpotMappingCommand} from '../../../../../modules/api-client/generated';

export const usePostImportSluitpotMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<string, ValidationFailed, CreateImportSluitpotMappingCommand>(
		(command) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSluitpotPost(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingSluitpotCustomerCustomerIdGet']);
            	queryClient.invalidateQueries(['aclImportMappingSluitpotIdGet']);            

				toast.success("Sluitpot mapping opgeslagen!");
			},
			onError: () => {
            	toast.error("Sluitpot mappen mislukt!");
			},
		}
	);
};
