import {useMutation, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {ValidationFailed} from '../../../../../modules/api-client/Responses';
import {CreateImportProfileMappingCommand} from '../../../../../modules/api-client/generated';
import {StartImportFormValues} from "../../../../../shared/import/components/StartImportForm";
import instance from "../../../../../modules/api-client/AxiosClient";

const upload = (form: StartImportFormValues) => {

	const formData = new FormData();
	if (form.customerId) {
		formData.append("customerId", form.customerId);
	}
	if (form.title) {
		formData.append("title", form.title);
	}
	formData.append("detectWallConnections", form.detectWallConnections ? 'true' : 'false');
	formData.append("file", form.file as File);

	return instance.post(`/acl/import`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then(x => x.data);
};

export const usePostImport = () => {

	const queryClient = useQueryClient()

	return useMutation<string, void, StartImportFormValues>(
		upload,
		{
			onSuccess: (id) => {
				queryClient.invalidateQueries(['aclImportListOdataGet'])
				toast.success("Import gestart");
				return id;
			},
			onError: () => {
				toast.error("Import mislukt");
			}
		}
	);
};
