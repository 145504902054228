/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useEkosietFilter } from '../../context/FilterContext'
import { CustomerFilter } from '../../../backoffice/modules/crm/components/CustomerFilter'


const CustomerSearchFilter: React.FC = () => {

    const { reset } = useEkosietFilter()

    return (
        <>
            <div className="row mb-3">
                <div className="col col-lg-12">
                    <CustomerFilter fieldName='filterCustomerId' />
                </div>                              
            </div>

            <div className="row mb-3">                              
                <div className="col col-lg-2 ms-auto d-flex justify-content-end align-items-center">
                    <button className="btn btn-secondary btn-secondary-icon btn-sm" type='reset' onClick={reset}>
                        <span>
                            <i className="la la-close"></i>
                            <span>Reset filters</span>
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

export { CustomerSearchFilter }