import React from 'react';

const Loading: React.FC<{ message?: string }> = ({message}) => {

    message ??= 'Laden ...';

    return (
        <div id='' className='d-flex justify-content-around align-items-center max-width-150px max-height-150px m-5'>
            <div className={'d-flex flex-column align-items-center justify-content-around '}>
                <img src='/media/logos/logo-ekosiet-400x400.png' alt='Ekosiet Logo' className={'w-75px h-75px mb-5 '}/>
                <span><i className="fas fa-spinner fa-spin"></i> {message}</span>
            </div>
        </div>
    );
};

export default Loading;