import React, {useCallback, useRef} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import RabbetSvg from '../../../../shared/components/RabbetSvg';
import ProfileDropzone from '../components/ProfileDropzone';
import RabbetTypeText from './RabbetTypeText';
import ActiveBadge from '../../../../shared/components/ActiveBadge';
import RabbetApplicationText from './RabbetApplicationText';
import {useRabbetDeleteMutation, useRabbetQuery} from './hooks';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog';
import {MasterdataRoutePath} from '../../masterdata/MasterdataRoutes';
import {RabbetType} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {API_GATEWAY_BASE_URL} from '../../../../constants';
import {RabbetProfileList} from './RabbetProfileList';
import {Mm} from "../../../../shared/components/Mm";

export const RabbetDetails: React.FC = () => {

    const {id} = useParams<{ id: string }>();

    const {data: rabbet, isInitialLoading} = useRabbetQuery(id);

    const navigate = useNavigate();

    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useRabbetDeleteMutation(id!);

    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
        navigate(MasterdataRoutePath.rabbetList);
    }, [deleteMutation, id, navigate]);


    if (isInitialLoading) return <Loading/>;

    if (!rabbet) return <></>;

    const geoUrl = `${API_GATEWAY_BASE_URL}/render/geo?embed=true&debug=true&geometry=${rabbet.geometry}`;
    // const geoUrl = `${API_GATEWAY_BASE_URL}/render/preview/rabbet?embed=true&debug=true&geometry=${rabbet.geometry}`;

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-5'>
                        <div className='card-header'>
                            <h3 className='card-title'>Sponning {rabbet.code}</h3>
                            <div className='card-toolbar'>
                                <Link to='edit' relative='path' className='btn btn-primary ms-2'>
                                    Sponning wijzigen
                                </Link>
                            </div>
                        </div>

                        <div className='card-body position-relative'>
                            <ProfileDropzone preview={(profile) => <RabbetSvg profile={profile}/>}>
                                {() => (
                                    <div className='row'>
                                        <div className='col-3'>
                                            <div style={{height: '200px', width: '100%'}}>
                                                <RabbetSvg profileSvg={rabbet.svgPreview} svgHeight='140px'/>
                                            </div>
                                        </div>

                                        <div className='col'>
                                            <div className='row mb-4'>
                                                <div className='col-3'>
                                                    <label className='form-label'>Code</label>
                                                </div>
                                                <div className='col'>{rabbet.code}</div>
                                            </div>
                                            <div className='row mb-4'>
                                                <div className='col-3'>
                                                    <label className='form-label'>Type</label>
                                                </div>
                                                <div className='col'>
                                                    <RabbetTypeText code={rabbet.type}/>
                                                </div>
                                            </div>

                                            <div className='row mb-4'>
                                                <div className='col-3'>
                                                    <label className='form-label'>Toepassing</label>
                                                </div>
                                                <div className='col'>
                                                    <RabbetApplicationText code={rabbet.application}/>
                                                </div>
                                            </div>
                                            <div className='row mb-4'>
                                                <div className='col-3'>
                                                    <label className='form-label'>Naam</label>
                                                </div>
                                                <div className='col'>{rabbet.name ||
                                                    <span className='text-muted'>geen</span>}</div>
                                            </div>

                                            <div className='row mb-4'>
                                                <div className='col-3'>
                                                    <label className='form-label'>Breedte/diepte</label>
                                                </div>
                                                <div className='col'>
                                                    {rabbet.width}x<Mm value={rabbet.depth} />
                                                </div>
                                            </div>

                                            {rabbet.type === RabbetType.HefSchuif && <>
                                                <div className='row mb-4'>
                                                    <div className='col-3'>
                                                        <label className='form-label'>Groef</label>
                                                    </div>
                                                    <div className='col'>
                                                        {rabbet.groove?.width}x<Mm value={rabbet.groove?.depth} /> <i className="fa-regular fa-heart"></i> <Mm value={rabbet.groove?.center}></Mm>
                                                    </div>
                                                </div>
                                            </>}


                                            {(rabbet.turboholRabbet) && (
                                                <>

                                                    <div className='row mb-4'>
                                                        <div className='col-3'>
                                                            <label className='form-label'>Turboholsponning</label>
                                                        </div>
                                                        <div className='col'>
                                                            {rabbet.turboholRabbet?.width}x{rabbet.turboholRabbet?.depth}mm
                                                        </div>
                                                    </div>

                                                    {(rabbet.turboholRabbet.aanslagNok) && (
                                                        <div className='row mb-4'>
                                                            <div className='col-3'>
                                                                <label className='form-label'>Aanslagnok</label>
                                                            </div>
                                                            <div className='col'>
                                                                {rabbet.turboholRabbet?.aanslagNok.width}x{rabbet.turboholRabbet?.aanslagNok.depth}mm
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className='row mb-4'>
                                                        <div className='col-3'>
                                                            <label className='form-label'>Diepte beslagsponning</label>
                                                        </div>
                                                        <div className='col'>
                                                            {rabbet.turboholRabbet.beslagSponningDepth}mm
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {rabbet.type !== RabbetType.Groef && (
                                                <div className='row mb-4'>
                                                    <div className='col-3'>
                                                        <label className='form-label'>Vakindelingen</label>
                                                    </div>
                                                    <div
                                                        className='col'>{rabbet.compartmentLayoutTypes ? <>{rabbet.compartmentLayoutTypes.map((l) => l.name).join(', ')}</> :
                                                        <span className='text-muted'>-</span>}</div>
                                                </div>
                                            )}

                                            <div className='row mb-4'>
                                                <div className='col-3'>
                                                    <label className='form-label'>Actief</label>
                                                </div>
                                                <div className='col'>
                                                    <ActiveBadge value={rabbet.active}/>
                                                </div>
                                            </div>

                                            <div className='row mb-4'>
                                                <div className='col-3'>
                                                    <label className='form-label'>Profiel string</label>
                                                </div>
                                                <div className='col'>
                                                    <div className='input-group'>
                                                        <input className='form-control' value={rabbet.geometry ?? ''}
                                                               readOnly={true}/>
                                                        <button className='btn btn-outline-secondary'
                                                                onClick={() => navigator.clipboard.writeText(rabbet.geometry)}
                                                                disabled={!rabbet.geometry} type='button'>
                                                            <i className='fa-regular fa-copy'></i>
                                                        </button>
                                                    </div>
                                                    <a href={geoUrl} target="_blank">{geoUrl}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </ProfileDropzone>
                        </div>
                    </div>

                    <div className='card mb-5'>
                        <div className='card-header'>
                            <h3 className='card-title'>Profielen</h3>
                        </div>

                        <div className='card-body'>
                            <RabbetProfileList rabbetId={id!}/>
                        </div>
                    </div>

                    <div className='card mb-5'>
                        <div className='card-header'>
                            <h3 className='card-title'>Gevarenzone</h3>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-10'>
                                    <strong>Sponning archiveren</strong>
                                    <p>Een gearchiveerde sponning kan niet meer worden gekozen in de configurator.</p>
                                </div>
                                <div className='col d-flex justify-content-end'>
                                    <div>
                                        <button type='button' className='btn btn-secondary'>
                                            Sponning archiveren
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-10'>
                                    <strong>Sponning verwijderen</strong>
                                    <p>Verwijder deze sponning definitief.</p>
                                </div>
                                <div className='col d-flex justify-content-end'>
                                    <div>
                                        <button type='button' className='btn btn-danger' disabled={false}
                                                onClick={() => openDeleteDialog.current?.call(this, true)}>
                                            Sponning verwijderen
                                        </button>
                                        <ConfirmDialog
                                            show={openDeleteDialog}
                                            onConfirm={deleteCallback}
                                            dialogStyle='danger'
                                            title='Verwijderen'
                                            message='Je staat op het punt deze sponning te verwijderen. Wil je doorgaan?'
                                            confirmText='Sponning verwijderen'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
};
