import React, {useState} from "react";
import {useProductionItemGetByBarCode} from "../hooks/use-get-production-item";
import {toast} from "react-hot-toast";
import BarcodeReader from "react-barcode-reader";
import {useGetProductionSill} from "../hooks/use-get-production-sill";
import {Link} from "react-router-dom";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {
    ProductionBatchStatusCodes, ProductionBatchTypes,
    ProductionItemOperations,
    PutProductionItemStatusRepresentation
} from "../../../../modules/api-client/generated";
import {
    ProductionItemStationStatusGroup,
} from "../../../../shared/components/ProductionItemStatus";
import {EkoCardHeader,EkoCardToolbar} from "../../../../shared/components/card";
import {formatDate} from "../../../../shared/components/date";
import {PmRoutePath} from "../PmRoutes";
import {StationOperationToggle} from "../components/StationOperationToggle";
import {usePutProductionItemStarted} from "../hooks/use-put-production-item-started";
import {usePutProductionItemNotStarted} from "../hooks/use-put-production-item-not-started";
import {usePutProductionItemFinished} from "../hooks/use-put-production-item-finished";
import {usePutProductionItemFailed} from "../hooks/use-put-production-item-failed";
import {usePutProductionItemCancelled} from "../hooks/use-put-production-item-cancelled";
import ConfiguredSillSpecification from "../../../../shared/components/ConfiguredSillSpecification";
import AfmontageSpecification from "../components/AfmontageSpecification";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextField from "../../../../shared/components/TextField";
import FieldErrors from "../../../../shared/components/FieldErrors";
import {generateDymoLabel, LabelPrinter} from "../../../components/LabelPrinter";
import {printLabel} from "../../../../shared/utils/react-dymo";
import {ActivityLogRow} from "./ProductionItem";
import {EkoTable,TableHeader} from "../../../../shared/components/table";
import ProductionBatchStatus from "../../../../shared/components/ProductionBatchStatus";

type InputValue = {
    code: string
}

const formSchema = Yup.object().shape({
    code: Yup.string().length(9).required('Voer 9 cijferige dorpelcode in.')
});

const ProductionItemOperationDetail: React.FC = () => {

    const [barCode, setBarCode] = useState<string | undefined>(undefined);
    const {data: pi, isError} = useProductionItemGetByBarCode(barCode);
    const {
        data: productionSill,
        isError: isProductionSillError,
    } = useGetProductionSill(pi?.catalogItem?.id)

    const startMutation = usePutProductionItemStarted()
    const notStartedMutation = usePutProductionItemNotStarted()
    const finishedMutation = usePutProductionItemFinished()
    const failedMutation = usePutProductionItemFailed()
    const cancelledMutation = usePutProductionItemCancelled()

    const failed = (id: string) => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: [id],
            }
            failedMutation.mutate(command, {});
        }).finally();
    }
    const cancel = (id: string) => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: [id],
            }
            cancelledMutation.mutate(command, {});
        }).finally();
    }
    
    const finish = async (id: string) => {
        const labelXml  = generateDymoLabel(pi!);
        const response = await printLabel("DYMO LabelWriter Wireless on DYMOLWW12C1DA", labelXml, "");
        if(response.status == 200) {
            console.log('Label geprint');
        } else {
            console.log('Label niet geprint');

        }

        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: [id],
            }
            finishedMutation.mutate(command, {});
        }).finally();

    }
    const started = (id: string) => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: [id],
            }
            startMutation.mutate(command, {});
        }).finally();
    }
    const notStarted = (id: string) => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: [id],
            }
            notStartedMutation.mutate(command, {});
        }).finally();
    }

    const handleScan = async (code: string) => {

        var reg = /^\d+$/;

        if (!reg.test(code) || code.length !== 9) {
            handleScanError('Scan een EDS barcode!')
            return;
        }

        if (code.length === 9) {
            handleEdsBarCodeScan(code);
            return;
        }

        handleScanError('Scan een EDS barcode!')
        return;
    }

    const handleEdsBarCodeScan = (code: string) => {

        toast.success(
            `EDS barcode gescanned: ${code}`,
            {
                duration: 3000
            }
        );

        setBarCode(code)
    }

    const handleScanError = (err: string) => {
        toast.error(
            `Scan error: '${err}'`,
            {
                duration: 3000
            }
        );
    }

    const initValues: InputValue = {
        code: ''
    }

    function handleInput(data: InputValue) {
        setBarCode(data.code)
    }

    function getBakNumber():string {
        if(!pi){return '-'}
        const batches = pi.batches.filter((batch) => {
            return batch.type === ProductionBatchTypes.Neuten
                &&
                (batch.status === ProductionBatchStatusCodes.Accepted
                    || batch.status === ProductionBatchStatusCodes.Started
                    || batch.status === ProductionBatchStatusCodes.Finished)
    });
        if(batches.length === 0 ||  !batches[0]){return '-'}
        return batches[0].neutenGroupNumber ? batches[0].neutenGroupNumber?.toString(): '-';
    }

    return (
        <>


            <BarcodeReader
                onError={handleScanError}
                onScan={handleScan}
            />
            {(isError || isProductionSillError) &&
                <>
                    Fout bij het ophalen van gegevens voor barcode {barCode}
                </>
            }
            <>
                <PageTitle toolbar={
                    <>

                        {pi && pi.urgent &&
                            <span className={'p-4 bg-danger text-bg-danger fw-bold'}>SPOED</span>
                        }

                        <div className={'d-flex align-items-center justify-content-center'}>
                            <>Scan een EDS barcode of</>
                            <Formik initialValues={initValues} onSubmit={handleInput} validationSchema={formSchema}>
                                {({
                                      values,
                                  }) => (
                                    <Form>
                                        <div className={'ms-3'}>
                                        <div className={'input-group d-flex align-items-start'}>
                                            <TextField name='code' hideFieldErrors={true} className={'form-control form-control-sm'} placeholder={'9-cijferige EDS dorpel code'}
                                                       value={values.code}/>
                                            <button
                                                type='submit'
                                                className='btn btn-sm btn-primary'>
                                                <span className='indicator-label'>Zoek dorpel</span>
                                            </button>
                                        </div>
                                        <FieldErrors field={'code'} />
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        {(pi && productionSill) &&
                            <>
                                <button
                                    className="btn btn-success  me-3"
                                    type="button"
                                    // onClick={()=>{if(confirm()){finish(pi.id, true)}}}
                                    onClick={() => {
                                        if(pi?.instructions){
                                            if(window.confirm('Heb je de instructies gelezen?')){ finish(pi.id) }
                                        } else {
                                            finish(pi!.id);
                                        }
                                    }}
                                    aria-expanded="false">
                                    Dorpel gereedmelden
                                </button>
                                {/*<LabelPrinter withPreview={false} xml={generateDymoLabel(pi!)} />*/}
                            </>
                        }
                    </>
                }>
                    Dorpel Productie Details
                </PageTitle>
                {(pi && productionSill) &&
                    <>

                        <AfmontageSpecification id={productionSill.id} remark={pi!.remark!} bakNumber={getBakNumber()}
                                                instruction={pi!.instructions!}/>

                        <div className='card mt-4'>
                            <div className='card-body'>
                                <ConfiguredSillSpecification catalogItemId={productionSill.id}/>
                            </div>
                        </div>

                        <div className={'row mt-4'}>
                            <div className={'col col-lg-4'}>
                                <EkoCard className={'mb-4'}>
                                    <EkoCardBody>
                                        <div className={'row mb-4'}>
                                            <div className={'col'}>
                                                <label className="form-label">Status</label>
                                            </div>
                                            <div className={'col'}>
                                                <ProductionItemStationStatusGroup pi={pi}/>
                                            </div>
                                        </div>

                                        <div className={'row mb-4'}>
                                            <div className={'col'}>
                                                <label className="form-label">Update status</label>
                                            </div>
                                            <div className={'col'}>
                                                <div>
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-secondary dropdown-toggle btn-sm  me-3"
                                                            type="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                            Update status
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="#"
                                                                   onClick={() => notStarted(pi!.id)}>Reset</a>
                                                            </li>
                                                            <li><a className="dropdown-item" href="#"
                                                                   onClick={() => started(pi!.id)}>Start</a>
                                                            </li>
                                                            <li><a className="dropdown-item" href="#"
                                                                   onClick={() => finish(pi!.id)}>Gereed</a>
                                                            </li>
                                                            <li><a className="dropdown-item" href="#"
                                                                   onClick={() => cancel(pi!.id)}>Annuleren</a>
                                                            </li>
                                                            <li><a className="dropdown-item" href="#"
                                                                   onClick={() => failed(pi!.id)}>Mislukt</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className={'row mb-4'}>
                                            <div className={'col'}>
                                                <label className="form-label">Print label</label>
                                            </div>
                                            <div className={'col'}>
                                                <LabelPrinter withPreview={false} xml={generateDymoLabel(pi!)} />
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <label className="form-label">Dorpel</label>
                                            </div>
                                            <div className={'col'}>
                                                {productionSill.sillCode} {productionSill.neuten.count} neuten
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <label className="form-label">Productiedatum</label>
                                            </div>
                                            <div className={'col'}>
                                                {formatDate(pi.plannedProductionDate)}
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <label className="form-label">Klant</label>
                                            </div>
                                            <div className={'col'}>
                                                {pi.customer?.name}
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <label className="form-label">Order</label>
                                            </div>
                                            <div className={'col'}>
                                                {pi.order?.code}
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <label className="form-label">Merk</label>
                                            </div>
                                            <div className={'col'}>
                                                {pi.merk}
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <label className="form-label">Opmerking</label>
                                            </div>
                                            <div className={'col'}>
                                                {pi?.remark}
                                            </div>
                                        </div>
                                    </EkoCardBody>
                                </EkoCard>

                                <EkoCard className={'mb-5'}>
                                    <EkoCardHeader title={'Komt voor in de volgende batches'}>
                                    </EkoCardHeader>
                                    <EkoCardBody>
                                        <EkoTable>
                                            <TableHeader>
                                                <th>Batch Nummer</th>

                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Baknummer</th>
                                                <th>BMH\3DM barcode</th>
                                            </TableHeader>
                                            <tbody>
                                            {pi.batches.map((batch) => {
                                                return (
                                                    <tr key={batch.id}>
                                                        <td>
                                                            EDS: {batch.code}<br/>
                                                            BMH\3DM: {batch.bmhBatchCode}
                                                        </td>
                                                        <td>
                                                            {batch.type}
                                                        </td>
                                                        <td>
                                                            <ProductionBatchStatus status={batch.status}/>
                                                        </td>
                                                        <td>
                                                            {batch.type === 'latten' ? batch.lattenGroupNumber : ''}
                                                            {batch.type === 'neuten' ? batch.neutenGroupNumber : ''}
                                                            {batch.type === 'dorpel' ? 'n.v.t.' : ''}
                                                        </td>
                                                        <td>
												<span
                                                    className='barcode'>*{batch.bmhBatchCode}{batch.itemBatchSequence}*</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </EkoTable>
                                    </EkoCardBody>
                                </EkoCard>
                                <EkoCard className={'mb-5'}>
                                    <EkoCardHeader title={'Activiteit'}>
                                    </EkoCardHeader>
                                    <EkoCardBody>
                                        <ActivityLogRow title={'Aangemaakt/reset'} transition={pi.notStarted!}/>
                                        <ActivityLogRow title={'Gestart'} transition={pi.started!}/>
                                        <ActivityLogRow title={'Gereed'} transition={pi.finished!}/>
                                        <ActivityLogRow title={'Gefaald'} transition={pi.failed!}/>
                                        <ActivityLogRow title={'Geannuleerd'} transition={pi.itemCancelled!}/>
                                        <hr/>
                                        <ActivityLogRow title={'Neuten gemaakt'} transition={pi.stationStatus.neutenGemaaktTransition!}/>
                                        <ActivityLogRow title={'Latten gemaakt'} transition={pi.stationStatus.lattenGemaaktTransition!}/>
                                        <ActivityLogRow title={'Dorpels gezaagd'} transition={pi.stationStatus.dorpelProfielGezaagdTransition!}/>
                                        <ActivityLogRow title={'Dorpels bewerkt'} transition={pi.stationStatus.dorpelBewerktTransition!}/>
                                        <ActivityLogRow title={'Neuten gemonteerd'} transition={pi.stationStatus.neutenGemonteerdTransition!}/>
                                        <ActivityLogRow title={'Sluitpotten gemonteerd'} transition={pi.stationStatus.sluitpottenGemonteerdTransition!}/>
                                        <ActivityLogRow title={'Latten gemonteerd'} transition={pi.stationStatus.lattenGemonteerdTransition!}/>
                                        <ActivityLogRow title={'Kopisolatoren'} transition={pi.stationStatus.kopisolatorenGemonteerdTransition!}/>
                                        <ActivityLogRow title={'Manchettes'} transition={pi.stationStatus.manchettesGemonteerdTransition!}/>
                                        <ActivityLogRow title={'Hefschuif/Vouwwand'} transition={pi.stationStatus.hefschuifVouwwandTransition!}/>
                                        <ActivityLogRow title={'Inpakken'} transition={pi.stationStatus.ingepaktTransition!}/>
                                    </EkoCardBody>
                                </EkoCard>

                            </div>
                            <div className={'col col-lg-8'}>
                                <EkoCard className={'mb-4'}>
                                    <EkoCardBody>
                                        <div className={'row'}>
                                            <div className={'col-2'}>
                                                <label className="form-label">Instructies</label>
                                            </div>
                                            <div className={'col-10'}>
                                                <div dangerouslySetInnerHTML={{__html: pi.instructions!}}></div>
                                            </div>
                                        </div>

                                    </EkoCardBody>
                                </EkoCard>
                                <EkoCard>
                                    <EkoCardHeader title={'Productie'}>
                                        <EkoCardToolbar>
                                            <Link to={PmRoutePath.link(PmRoutePath.productionItemGroupLink(pi.code))}
                                                  className='text-hover-primary fs-6'>
                                                Groepeer op orderregel
                                            </Link>
                                        </EkoCardToolbar>

                                    </EkoCardHeader>
                                    <EkoCardBody>
                                        <>
                                            <StationOperationToggle id={pi.id} status={pi.stationStatus.neutenGemaakt}
                                                                    operation={ProductionItemOperations.NeutenGemaakt}
                                                                    title={'Neuten maken'}/>
                                            <StationOperationToggle id={pi.id} status={pi.stationStatus.lattenGemaakt}
                                                                    operation={ProductionItemOperations.LattenGemaakt}
                                                                    title={'Latten maken'}/>
                                            <StationOperationToggle id={pi.id}
                                                                    status={pi.stationStatus.dorpelProfielGezaagd}
                                                                    operation={ProductionItemOperations.DorpelProfielGezaagd}
                                                                    title={'Dorpel zagen'}/>
                                            <StationOperationToggle id={pi.id} status={pi.stationStatus.dorpelBewerkt}
                                                                    operation={ProductionItemOperations.DorpelBewerkt}
                                                                    title={'Dorpel bewerken'}/>
                                            <StationOperationToggle id={pi.id}
                                                                    status={pi.stationStatus.neutenGemonteerd}
                                                                    operation={ProductionItemOperations.NeutenGemonteerd}
                                                                    title={'Neuten monteren'}/>
                                            {/*<StationOperationToggle id={pi.id} status={pi.stationStatus.afgemonteerd} operation={ProductionItemOperations.Afgemonteerd} title={'Afmontage'} />*/}
                                            <StationOperationToggle id={pi.id}
                                                                    status={pi.stationStatus.sluitpottenGemonteerd}
                                                                    operation={ProductionItemOperations.SluitpottenGemonteerd}
                                                                    title={'Sluitpotten monteren'}/>
                                            <StationOperationToggle id={pi.id}
                                                                    status={pi.stationStatus.lattenGemonteerd}
                                                                    operation={ProductionItemOperations.LattenGemonteerd}
                                                                    title={'Latten monteren'}/>
                                            <StationOperationToggle id={pi.id}
                                                                    status={pi.stationStatus.kopisolatorenGemonteerd}
                                                                    operation={ProductionItemOperations.KopisolatorenGemonteerd}
                                                                    title={'Kopisolatoren'}/>
                                            <StationOperationToggle id={pi.id}
                                                                    status={pi.stationStatus.manchettesGemonteerd}
                                                                    operation={ProductionItemOperations.ManchettesGemonteerd}
                                                                    title={'Manchettes'}/>
                                            <StationOperationToggle id={pi.id}
                                                                    status={pi.stationStatus.hefschuifVouwwand}
                                                                    operation={ProductionItemOperations.HefschuifVouwwand}
                                                                    title={'Hefschuif/Vouwwand'}/>

                                            <StationOperationToggle id={pi.id} status={pi.stationStatus.ingepakt}
                                                                    operation={ProductionItemOperations.Ingepakt}
                                                                    title={'Inpakken'}/>
                                        </>
                                    </EkoCardBody>
                                </EkoCard>


                            </div>
                        </div>
                    </>
                }
            </>
        </>
    )
}

export default ProductionItemOperationDetail
