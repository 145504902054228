/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {useEkosietFilter} from '../../../../../shared/context/FilterContext';
import {EkoTable, TableHeader} from '../../../../../shared/components/table';
import {ImportSluitpotMappingRepresentation} from '../../../../../modules/api-client/generated';
import {useGetCustomerSluitpotMappings} from '../hooks/use-get-customer-sluitpot-mappings';
import {useDeleteImportSluitpotMapping} from '../hooks/use-delete-import-sluitpot-mapping';
import ImportSluitpotMappingUpsert from './ImportSluitpotMappingUpsert';

const CustomerImportSluitpotMappings: React.FC = () => {
	const {filters} = useEkosietFilter();
	const [selectedSluitpotMapping, setSelectedSluitpotMapping] = useState<ImportSluitpotMappingRepresentation | undefined>(undefined);
	const customerId: string | undefined = filters.length ? filters[0].values[0] ?? undefined : undefined;
	const {isInitialLoading, data: customerImportedSluitpotMappings, isError} = useGetCustomerSluitpotMappings(customerId);
	var mutationDeleteImportSluitpotMapping = useDeleteImportSluitpotMapping();

	if (isInitialLoading) {
		return <>Laden...</>;
	}

	if (isError) {
		return <>Fout bij het ophalen van de klant import sluitpot mappings!</>;
	}

	if (!filters.length) {
		return <>Selecteer een klant</>;
	}

	return (
		<>
			<ImportSluitpotMappingUpsert 
				id={selectedSluitpotMapping?.id} 
				customerId={selectedSluitpotMapping?.customer.id ?? customerId} 
				handleSucces={
					() => setSelectedSluitpotMapping(undefined)
				} 
				/>

			{(!customerImportedSluitpotMappings || !customerImportedSluitpotMappings.length) && <>Geen sluitpot mappings gevonden voor deze klant</>}

			<EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
				<TableHeader>
					<th className="min-w-200px">Klant</th>
					<th className="min-w-100px">External Code</th>
					<th className="min-w-200px">Sluitpot Beslag Naam</th>
					<th className="min-w-100px text-end">Acties</th>
				</TableHeader>
				<tbody>
					{customerImportedSluitpotMappings &&
						customerImportedSluitpotMappings &&
						customerImportedSluitpotMappings.map((item: ImportSluitpotMappingRepresentation, index: number) => (
							<tr key={item.id}>
								<td>
									{item.customer.name} ({item.customer.code})
								</td>
								<td>{item.externalCode}</td>
								<td>{item.sluitpotBeslag && <span>{item.sluitpotBeslag.name}</span>}</td>
								<td className="text-end">
									<button
										type="button"
										className={`btn btn-icon btn-sm btn-light me-2 `}
										title="Wijzig Mapping"
										onClick={() => {
											setSelectedSluitpotMapping(item);
										}}
									>
										<i className="fas fa-edit"></i>
									</button>

									<button
										type="button"
										className={`btn btn-icon btn-sm btn-light me-2 `}
										title="Verwijder mapping"
										onClick={async () => {
											await mutationDeleteImportSluitpotMapping.mutateAsync(item.id);
										}}
									>
										{mutationDeleteImportSluitpotMapping.isLoading && mutationDeleteImportSluitpotMapping.variables == item.id ? (
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
										) : (
											<i className="fas fa-trash"></i>
										)}
									</button>
								</td>
							</tr>
						))}
				</tbody>
			</EkoTable>
		</>
	);
};

export {CustomerImportSluitpotMappings};
