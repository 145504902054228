/* eslint-disable jsx-a11y/anchor-is-valid */
import {useParams} from 'react-router-dom'
import {EkoCard, EkoCardBody} from '../../../_metronic/helpers'
import {PageTitle} from "../../../_metronic/layout/core";
import {ReactElement, useRef, useState} from "react";
import {useGetOrder} from "../../backoffice/modules/sm/orders/hooks/use-get-order";
import {OrderConfiguratorModal} from "../../backoffice/modules/sm/orders/edit-order/components/OrderConfiguratorModal";
import {EditOrderModal} from "../../backoffice/modules/sm/orders/edit-order/components/EditOrderModal";
import {EditOrderToolbar} from "../../backoffice/modules/sm/orders/edit-order/components/EditOrderToolbar";
import {OrderLineTable} from "../components/OrderLineTable";
import {BillingDetails} from "../components/BillingDetails";
import {OrderDetails} from "../components/OrderDetails";
import {ShippingDetails} from "../components/ShippingDetails";
import {OrderRemarks} from "../components/OrderRemarks";
import {OrderActions} from "../components/OrderActions";

export function Order() {
    const {id} = useParams<{ id: string }>();
    const {data: order} = useGetOrder(id);
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('lg-down')
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);
    const openConfigurator = useRef<(show: boolean) => void>();

    return (
        <>
            {order?.id &&
                <>
                    <OrderConfiguratorModal show={openConfigurator} orderId={order.id} catalogId={order.catalog.id}/>
                    <EditOrderModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                                    title={modalTitle}
                                    size={'lg'}
                                    fullscreen={modalFullscreen}
                                    component={modalComponent}
                    />
                    <PageTitle toolbar={
                        <EditOrderToolbar
                            setModalTitle={setModalTitle}
                            setModalComponent={setModalComponent}
                            setShowEditModal={setShowEditModal}
                            orderId={order.id}
                            status={order.status}
                            orderNbr={order.code}
                        />
                    }>{`Order ${order.code}`}</PageTitle>

                    <div className="row">
                        <div className={'col-12 col-lg-9 order-last order-lg-first'}>
                            <div className={'row mb-5'}>
                                <div className={'col col-lg-7'}>

                                    {/*<OrderDetails*/}
                                    {/*    code={order.code}*/}
                                    {/*    person={order.orderContact}*/}
                                    {/*    reference={order.expedition?.reference}*/}
                                    {/*    orderId={order.id}*/}
                                    {/*    customerId={order.customer?.id}*/}
                                    {/*    setModalTitle={setModalTitle}*/}
                                    {/*    setModalComponent={setModalComponent}*/}
                                    {/*    setShowEditModal={setShowEditModal}*/}
                                    {/*/>*/}
                                <ShippingDetails status={order.status}
                                                 contact={order.orderContact}
                                                 reference={order.expedition?.reference}
                                                 person={order.expedition?.contactPerson}
                                                 deliverycondition={order.expedition?.deliveryCondition}
                                                 preferredExpeditionDate={order.expedition?.preferredExpeditionDate ? new Date(order.expedition?.preferredExpeditionDate) : null}
                                                 plannedExpeditionDate={order.expedition?.plannedExpeditionDate ? new Date(order.expedition?.plannedExpeditionDate) : null}
                                                 deliveredDate={order.expedition?.delivered?.date ? new Date(order.expedition.delivered.date) : null}
                                                 address={order.expedition?.deliveryAddress}
                                                 orderId={order.id}
                                                 expeditionStatus={order.expedition?.status}
                                                 setModalTitle={setModalTitle}
                                                 setModalComponent={setModalComponent}
                                                 setShowEditModal={setShowEditModal}
                                />
                            </div>
                            <div className={'col'}>

                                <OrderRemarks remarks={order.remarks}
                                              orderId={order.id}
                                              setModalTitle={setModalTitle}
                                              setModalComponent={setModalComponent}
                                              setShowEditModal={setShowEditModal}
                                />
                                </div>

                            </div>
                            <div className="d-flex flex-column gap-5" style={{overflowAnchor: "auto"}}>
                                <EkoCard className={'py-4 flex-row-fluid overflow-hidden'} flush={true}>
                                    <EkoCardBody className={'pt-0'}>

                                        <OrderLineTable
                                            orderId={order.id}
                                            customerId={order.customer.id}
                                            pricelistId={order.financial.priceList.id}
                                            catalogId={order.catalog.id}
                                            lines={order.lines}
                                            projectDiscount={order.financial.projectDiscount}
                                            totalPriceGross={order.financial.totalPriceGross}
                                            totalPriceNet={order.financial.totalPriceNet}
                                            status={order.status}
                                            setModalTitle={setModalTitle}
                                            setModalComponent={setModalComponent}
                                            setModalFullscreen={setModalFullscreen}
                                            setShowEditModal={setShowEditModal}
                                            isOrderEdit={true}
                                        />

                                    </EkoCardBody>
                                </EkoCard>
                            </div>
                        </div>

                        <div className="col">
                            <OrderActions
                                order={order}
                                setModalTitle={setModalTitle}
                                setModalComponent={setModalComponent}
                                setShowEditModal={setShowEditModal}
                            />



                            {/*<BillingDetails paymentStatus={order.financial.status}*/}
                            {/*                priceList={order.financial.priceList}*/}
                            {/*                address={order.financial.invoiceAddress}*/}
                            {/*                person={order.financial.contactPerson}*/}
                            {/*                projectDiscount={order.financial.projectDiscount ? order.financial.projectDiscount : 0.00}*/}
                            {/*                paymentCondition={order.financial.paymentCondition}*/}
                            {/*                status={order.status}*/}
                            {/*                orderId={order.id}*/}
                            {/*                setModalTitle={setModalTitle}*/}
                            {/*                setModalComponent={setModalComponent}*/}
                            {/*                setShowEditModal={setShowEditModal}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </>
            }
        </>
    )
}
