import React, {FC, useState} from 'react';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useQuery} from '@tanstack/react-query';
import {Modal} from 'react-bootstrap';
import {Diff, Hunk, parseDiff} from 'react-diff-view/esm';
import {File} from 'gitdiff-parser';

export interface ConfiguredSillDetailsDiffAlertProps {
	configuredSillId: string;
}

export const ConfiguredSillDetailsDiffAlert: FC<ConfiguredSillDetailsDiffAlertProps> = (props) => {

	const [showDiffModel, setShowDiffModel] = useState(false);

	const {
		data,
		isInitialLoading,
		isLoadingError,
	} = useQuery(['ApiClient.Core.ConfiguredSill.recalculate', props.configuredSillId], async () => {
		const response = await ApiClient.Core.ConfiguredSill.recalculate(props.configuredSillId);
		return response.data;
	});

	if (isLoadingError || isInitialLoading || !data) return <></>;

	const { isDifferent, diff, hash, recalculatedHash} = data;

	const openDiffModal = () => {
		setShowDiffModel(true);
	};

	const closeDiffModal = () => {
		setShowDiffModel(false);
	};

	const files = parseDiff(diff ?? '');

	const renderFile = ({oldRevision, newRevision, type, hunks}: File): React.ReactNode => (
		<Diff key={oldRevision + '-' + newRevision} viewType='split' diffType={type} hunks={hunks}>
			{hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
		</Diff>
	);

	return <>

		{isDifferent &&
			<>
				<div className='alert bg-warning d-flex justify-content-between align-items-center'>
					<div>
						<strong>LET OP</strong> Deze dorpel gebasseerd op niet-actuele stamgegevens.
					</div>
					<button type='button' className='btn btn-primary' onClick={openDiffModal}>Bekijk verschil</button>
				</div>

				<Modal
					tabIndex={-1}
					aria-hidden='true'
					dialogClassName={'modal-dialog-scrollable'}
					contentClassName={'shadow-lg'}
					show={showDiffModel}
					size='xl'
					onHide={closeDiffModal}
					animation={false}
					backdrop={true}>
					<Modal.Header closeButton>
						<Modal.Title>Diff</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='bg-white'>
							<div className="d-flex">
								<input type='text' readOnly className="form-control me-1" value={hash} />
								<input type='text' readOnly className="form-control ms-1" value={recalculatedHash} />
							</div>
							{files && files.map(renderFile)}
						</div>
					</Modal.Body>
				</Modal>
			</>
		}
	</>;
};
