import clsx from 'clsx';
import { FC } from 'react'
import {useFormikContext, getIn} from "formik";
import { ContactPersonRepresentation } from '../../modules/api-client/generated';
import Select, {components, ControlProps} from "react-select"
import {useEkosietAuth0} from "../../modules/auth0/core/useEkosietAuth0";

type Props= {    
  fieldName: string;
  contactPersons?: Array<ContactPersonRepresentation> | undefined
}


export const CustomerContactPersonPicker : FC<Props> = (props: Props) => {

  const formik = useFormikContext();
  const {user} = useEkosietAuth0();

  let options: any[] = [{ value: '', label: 'Voer een nieuw contactpersoon op...' }];
  let userContactPersonId:string|undefined;

  if (props.contactPersons) {
    let customerContactPersonOptions = props.contactPersons.map((item: ContactPersonRepresentation) => {
      return { value: item.id, label: `${item.firstName} ${item.lastName} (${item.email})` }
    });

    options = [...options, ...customerContactPersonOptions];
    if (user) {
      userContactPersonId = getIn(formik.values, props.fieldName)? undefined: props.contactPersons.find((item: ContactPersonRepresentation) => item.email === user.email)?.id;
    }
  }

  let selectedValue = options.find((option: any) => option.value === getIn(formik.values, props.fieldName) || option.value === userContactPersonId);
  return (
    <>
            <Select
                className={
              clsx(
                  '',
                  {'is-invalid': getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName)},
                  {'is-valid': getIn(formik.touched, props.fieldName) && !getIn(formik.errors, props.fieldName)},
                )}
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 7,
                  padding: 5,
                })}
                classNames={{
                  control: (state) =>
                    clsx(
                      'form-control form-control p-2 border-secondary',
                    ),
                  singleValue: (state) =>
                  {
                    return clsx(
                      'text-gray-700',
                    )
                  }
                }}
                value={selectedValue}
                placeholder="Selecteer een contactpersoon"
                isClearable={false}
                isSearchable={true}
                name={props.fieldName}
                options={options}
                onChange={(newValue) => {
                  formik.setFieldValue(props.fieldName, newValue?.value)
                  formik.validateForm();
                }}
              />

          {getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName) && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{getIn(formik.errors, props.fieldName)}</span>
              </div>
            </div>
          )}

    </>
  )
}
