import React from "react";
import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { useParams } from "react-router-dom";
import {
    NeutRepresentation,
} from "../../../../modules/api-client/generated";
import { EkoCard, EkoCardBody } from "../../../../../_metronic/helpers";

import Loading from "../../../../shared/components/Loading";
import ConfiguredNeutSvg from "../../../../shared/components/ConfiguredNeutSvg";
import { PageTitle } from "../../../../../_metronic/layout/core";



const Neut: React.FC = () => {
    const { id: neutId } = useParams<{ id: string }>();
    const {
        isInitialLoading,
        data: neut,
        isError
    } = useQuery<NeutRepresentation, TypeError, NeutRepresentation>(['pimNeutGet'], () => ApiClient.Pim.Neuten.getById(neutId!).then((res) => res.data));

    if (isInitialLoading) {
        return <Loading />;
    }
    if (isError) {
        return <>ERROR!</>;
    }
    if (!neut) return <></>;

    return <>
        <PageTitle toolbar={<button className={'btn btn-primary btn-sm disabled'} onClick={() => 1}>BMH downloaden</button>}
            breadcrumbs={[]}>{`Neut ` + neut.code}</PageTitle>

        <div className={'row'}>
            <div className={'col-7'}>
                <EkoCard>
                    <EkoCardBody>
                        <div className={'row'}>
                            <div className={'col col-lg-3'}>
                                Id
                            </div>
                            <div className={'col'}>
                                {neut.id}
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col col-lg-3'}>
                                Code
                            </div>
                            <div className={'col'}>
                                {neut.code}
                            </div>
                        </div>
                    </EkoCardBody>
                </EkoCard>
            </div>
            <div className={'col-5'}>
                <EkoCard>
                    <EkoCardBody>
                        <ConfiguredNeutSvg svg={neut.svg} svgWidth={'165px'} svgHeight={'195px'} />
                    </EkoCardBody>
                </EkoCard>
            </div>
        </div>
    </>
}

export default Neut;