import React, {ReactElement, useEffect, useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    ExpeditionForProductionItemRepresentation,
} from "../../../../modules/api-client/generated";
import Loading from "../../../../shared/components/Loading";
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import {usePutExpeditionReadyForShipment} from "../../sm/orders/hooks/use-put-expedition-status";
import {dateToString, formatDate, weekDayColor} from "../../../../shared/components/date";
import {DetailModal} from "../expedition/components/DetailModal";
import {PackingSlipsDocument} from "../expedition/components/PackingSlipsDocument";
import {useGetExpeditionItems} from "../expedition/hooks/use-get-expedition-items";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import {ExpeditionSillDetailsByCode} from "../expedition/components/ExpeditionWeekTable";
import {DateTime} from "luxon";
import {EmRoutePath} from "../EmRoutes";
import {ExpeditionStatus} from "../../../../shared/components/ExpeditionStatus";
import {Form, Formik} from "formik";
import TextField from "../../../../shared/components/TextField";
import FieldErrors from "../../../../shared/components/FieldErrors";
import {toast} from "react-hot-toast";
import * as Yup from "yup";
import BarcodeReader from "react-barcode-reader";

export interface OrderItemsProps {
    orderId: string | undefined,
    showHeader: boolean,
    showOnlyNonProductionItems: boolean,
}
type InputValue = {
    code: string
}
const formSchema = Yup.object().shape({
    code: Yup.string().length(9, 'Voer een 9 cijferige dorpelcode in.').required('Voer een 9 cijferige dorpelcode in.')
});

export const OrderItems: React.FC<OrderItemsProps> = ({orderId,showOnlyNonProductionItems,showHeader}) => {

    const {
        isInitialLoading,
        data,
        isError
    } = useGetExpeditionItems(orderId);

    if (isInitialLoading) {
        return <>Laden...</>;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!data) return <></>;

    return (
        <>
            <EkoTable>
                {showHeader &&
                <TableHeader>
                    <th>Aantal</th>
                    <th>Omschrijving</th>
                </TableHeader>
                }
                <tbody>
                {data &&
                    data.lines &&
                    data.lines
                        .filter(line => showOnlyNonProductionItems ? line.type != "configurationLine" && line.type != "shippingCostLine": line.type !== "shippingCostLine")
                        .map(line =>
                    <tr key={'oi-' + line.id}>
                        <td>{line.quantity}</td>
                        <td>{line.title}</td>
                    </tr>
                )}
                {data && data.mountings && data.mountings.filter(mounting => mounting.quantity > 0).map(mounting =>
                    <tr key={'oi-' + mounting.id}>
                        <td>{mounting.quantity}</td>
                        <td>{mounting.name}</td>
                    </tr>
                )}
                </tbody>
            </EkoTable>
        </>
    )
}

const Expedition: React.FC = () => {


    const {id: orderId,barcode:barcode} = useParams<{ id: string,barcode:string }>();
    const readyForShipmentMutation = usePutExpeditionReadyForShipment()
    var navigate = useNavigate();

    const [showModal, setShowModal] = useState<boolean>(!!barcode);
    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('xxl-down');
    const [modalTitle, setModalTitle] = useState<string | undefined | null>('Details');
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(
    );



    const {
        isInitialLoading,
        data: expedition,
        isError
    } = useQuery<ExpeditionForProductionItemRepresentation>(['emExpeditionOrderIdGet'], () => ApiClient.Em.Expedition.emExpeditionOrderIdGet(orderId!).then((res) => res.data));
    useEffect(() => {
        if(barcode && expedition) {
            setModalComponent(<>
                <ExpeditionSillDetailsByCode productionItemCode={barcode!} handleClose={() => setShowModal(false)}
                                             plannedExpeditionDate={dateToString(expedition.expedition.plannedExpeditionDate!, {
                                                 weekday: 'short',
                                                 year: '2-digit',
                                                 month: 'numeric',
                                                 day: 'numeric',
                                                 timeZone: 'UTC'
                                             })}/>
            </>);
            setModalTitle('Details');
            setModalFullscreen('xxl-down');
            setShowModal(true);
        }
    }, [expedition!]);

    if (isError) {
        return <>ERROR!</>;
    }
    if (isInitialLoading) {
        return <Loading/>;
    }
    if (!expedition) return <></>;

    const viewSillDetailsByBarCode = () => {
        setModalComponent(<>
            <ExpeditionSillDetailsByCode productionItemCode={barcode!} handleClose={() => setShowModal(false)} plannedExpeditionDate={dateToString(expedition.expedition!.plannedExpeditionDate!,{
                weekday: 'narrow',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                timeZone: 'UTC'
            })}/>
        </>);
        setModalTitle('Details');
        setModalFullscreen('xxl-down');
        setShowModal(true);
    }


    const setReadyForShipment = (orderId: string) => {
        return new Promise<void>(() => {
            readyForShipmentMutation.mutate(orderId, {});
        }).finally();
    }

    const openDocument = (orderId: string, fileName: string) => {
        setModalComponent(() => <PackingSlipsDocument orderIds={[orderId]} fileName={`pakbon-${fileName}`}/>)
        setModalTitle('Document')
        setShowModal(true)
    }

    const handleScan = async (code: string) => {

        var reg = /^\d+$/;

        if (!reg.test(code) || code.length !== 9) {
            handleScanError('Scan een EDS barcode!')
            return;
        }

        if (code.length === 9) {
            handleEdsBarCodeScan(code);
            return;
        }

        handleScanError('Scan een EDS barcode!')
        return;
    }

    const handleEdsBarCodeScan = (code: string) => {

        toast.success(
            `EDS barcode gescanned: ${code}`,
            {
                duration: 3000
            }
        );
        navigate(EmRoutePath.link(EmRoutePath.expeditionByProductionItemLink(code)));
    }

    const handleScanError = (err: string) => {
        toast.error(
            `Scan error: '${err}'`,
            {
                duration: 3000
            }
        );
    }

    const initValues: InputValue = {
        code: ''
    }

    function handleInput(data: InputValue) {
        navigate(EmRoutePath.link(EmRoutePath.expeditionByProductionItemLink(data.code)));
    }


    if (isInitialLoading) {
        return <Loading/>;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    if (!expedition) return <></>;

    const relatedOrders = Object.entries(expedition?.relatedExpeditions);
    const expeditionDate = DateTime.fromFormat(expedition.expedition!.plannedExpeditionDate!,'yyyy-MM-dd');

    return (
        <>
            <BarcodeReader
                onError={handleScanError}
                onScan={handleScan}
            />
            <DetailModal show={showModal} handleClose={() => setShowModal(false)}
                         title={modalTitle}
                         size={'xl'}
                         fullscreen={modalFullscreen}
                         component={modalComponent}
            />
            <PageTitle toolbar={<>
                <Link
                    to={EmRoutePath.link(EmRoutePath.planningWeekLink(expeditionDate.year, expeditionDate.weekNumber))}
                    className={`btn btn-${weekDayColor[expeditionDate.weekday.toString()]}`}>
                    <span className="d-flex flex-column align-items-center ms-2">
                        {dateToString(expedition.expedition!.plannedExpeditionDate!)}
                    </span>
                </Link>

                <div>
                {barcode &&
                <button className={'btn btn-primary btn-sm'} onClick={() => viewSillDetailsByBarCode()}>Dorpel details
                </button>
                }
                </div>

                        <div className={'d-flex align-items-center justify-content-center'}>
                            <Formik initialValues={initValues} onSubmit={handleInput} validationSchema={formSchema}>
                                {({
                                      values,
                                  }) => (
                                    <Form>
                                        <div className={'ms-3'}>
                                            <div className={'input-group d-flex align-items-start'}>
                                                <TextField name='code'
                                                           hideFieldErrors={true}
                                                           className={'form-control form-control-sm'}
                                                           placeholder={'9-cijferige EDS dorpel code'}
                                                           value={values.code}
                                                />
                                                <button
                                                    type='submit'
                                                    className='btn btn-sm btn-primary'>
                                                    <span className='indicator-label'>Zoek dorpel</span>
                                                </button>
                                            </div>
                                            <FieldErrors field={'code'} />
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
            </>
            }>
                Expeditie
            </PageTitle>
            <div className={'row'}>
                <div className={'col-7'}>
                    <EkoCard>
                        <EkoCardHeader className={`d-flex align-items-center`} title={expedition?.expedition!.order.code}>
                            <div className={'badge badge-secondary p-3 fs-3'}>
                                {expedition?.expedition!.customer.name}
                            </div>
                            <button className={'btn btn-primary btn-sm'}
                                    onClick={() => openDocument(expedition?.expedition!.order.id, expedition?.expedition!.order.code)}>Print
                                pakbon
                            </button>
                            {expedition?.expedition!.status === 'planned' &&
                                <button className={`btn btn-info ms-3`}
                                        onClick={() => setReadyForShipment(expedition?.expedition!.order.id!)}>
                                    Verzendklaar melden
                                </button>
                            }
                        </EkoCardHeader>
                        <EkoCardBody>
                            <div className={'row'}>
                                <div className={'col col-lg-3'}>
                                    Status:
                                </div>
                                <div className={'col'}>
                                    <ExpeditionStatus status={expedition?.expedition!.status}/>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col col-lg-3'}>
                                    Merk:
                                </div>
                                <div className={'col'}>
                                    {expedition?.expedition!.order.reference}
                                </div>
                            </div>

                            <hr/>
                            <OrderItems orderId={expedition?.expedition!.order.id!} showHeader={true} showOnlyNonProductionItems={false}/>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className={'col-5'}>
                    {relatedOrders.length > 0 &&
                        <EkoCard>
                            <EkoCardHeader
                                title={'Gerelateerde orders van ' + expedition?.expedition!.customer.name}></EkoCardHeader>
                            <EkoCardBody>
                                {/*Langste dorpel: {data.maxSillLength}mm<br/>*/}
                                {/*Totaal aantal items: {data.itemCount}<br/>*/}
                                {relatedOrders.map((relExpedition, index) => {
                                    return (
                                        <>
                                            <EkoTable>
                                                <TableHeader>
                                                    <th  className={`${DateTime.fromFormat(relExpedition[0], 'yyyy-MM-dd').equals(DateTime.fromFormat(expedition.expedition.plannedExpeditionDate!, 'yyyy-MM-dd')) ? 'h2 text-success':'h6'}`}>
                                                        {dateToString(relExpedition[0])}</th>
                                                    <th>Status</th>
                                                    <th>{relExpedition[1].itemCount} dorpels</th>
                                                    <th>{relExpedition[1].maxSillLength}mm</th>
                                                </TableHeader>
                                                <tbody>
                                                {relExpedition[1].expeditions.map((exp) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{exp.orderCode}</td>
                                                                    <td>
                                                                        <ExpeditionStatus status={exp.expeditionStatus}/>
                                                                    </td>
                                                                    <td>{exp.itemCount} dorpels</td>
                                                                    <td>{exp.maxSillLengthOrder}mm</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                )}
                                                </tbody>
                                            </EkoTable>
                                        </>
                                    );
                                })}
                            </EkoCardBody>
                        </EkoCard>
                    }
                </div>
            </div>
        </>
    )
}

export default Expedition
