import {useQueryClient} from '@tanstack/react-query';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {toast} from 'react-hot-toast';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {ValidationFailed} from '../../../../../modules/api-client/Responses';

export const usePostCreateEdsOrder = (id: string | undefined) => {
	const queryClient = useQueryClient();

	return useEkosietMutation<string, ValidationFailed, string>(
		(id) => {
			return ApiClient.Acl.Import.aclImportCreateEdsOrderPost(undefined, id).then((response: any) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportListOdataGet']);
				queryClient.invalidateQueries(['aclImportIdGet']);
			},
			onError: () => {
				toast.error('Order aanmaken mislult');
			},
		}
	);
};
