import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import React from "react";
import axios from "axios";
import { BackofficeRoutePath } from "../../RoutePath";
import StationProductionTodo from "./StationProductieToDo";
import ProductionItem from "./pages/ProductionItem";
import ProductieBatchList from "./pages/ProductieBatchList";
import ProductionDashboard from './pages/ProductionDashboard';
import ProductionBatchPage from "./pages/ProductieBatch";
import ProductionItemList from "./pages/ProductionItemList";
import SawMaster from "./pages/SawMaster";
import DorpelsBewerken from "./pages/DorpelsBewerken";
import NeutenMonteren from "./pages/NeutenMonteren";
import Afmontage from "./pages/Afmontage";
import LattenMaken from "./pages/LattenMaken";
import NeutenMaken from "./pages/NeutenMaken";
import Inpakken from "./pages/Inpakken";
import ProductieBatchStationList from "./pages/ProductieBatchStationList";
import ProductionItemOperationDetail from "./pages/ProductionItemOperationDetail";
import ProductionItemGroup from './pages/ProductionItemGroup';
import AfmeldenLanding from "./pages/AfmeldenLanding";
import Afmelden from "./pages/Afmelden";
import HefschuifVouwwand from "./pages/HefschuifVouwwand";
import ManchettesMonteren from "./pages/ManchettesMonteren";
import KopisolatorenMonteren from "./pages/KopisolatorenMonteren";
import LattenMonteren from "./pages/LattenMonteren";
import SluitpottenMonteren from "./pages/SluitpottenMonteren";
import PlanningDashboard from "./pages/PlanningDashboard";
import CreateNeutenBatch from "./pages/CreateNeutenBatch";
import Custom from "./pages/Custom";

export const PmRoutePath = {
    planningDashboard: 'planningDashboard',
    productionItemList: 'productionItems',
    productionItemGroup: 'productionItemGroup/:code',
    productionItem: 'productionItem/:id',
    productionItemOperationDetail: 'productionItem/operation/detail',
    productionBatchList: 'batchlist',
    productionBatchStationList: 'batch/station/list',
    productionBatch: 'batch/:id',
    station: 'station/:id',


    createNeutenBatch: 'batch/create/losseNeut',

    lattenMaken: 'stations/latten-maken',
    neutenMaken: 'stations/neuten-maken',

    sawmaster: 'stations/dorpel-profielen-zagen',
    dorpelsBewerken: 'stations/dorpel-profielen-bewerken',
    neutenMonteren: 'stations/neuten-monteren',
    afmontage: 'stations/afmontage',
    inpakken: 'stations/inpakken',

    sluitpottenGemonteerd: 'stations/sluitpotten-monteren',
    lattenGemonteerd: 'stations/latten-monteren',
    kopisolatorenGemonteerd: 'stations/kopisolatoren-monteren',
    manchettesGemonteerd: 'stations/manchettes-monteren',
    hefschuifVouwwand: 'stations/hefschuif-vouwwand',
    custom: 'stations/custom',

    afmelden: 'afmelden/:id',
    afmeldenlanding: 'afmeldenlanding',

    stationsDashboard: 'stations/dashboard',
    link(path: string) {
        return BackofficeRoutePath.pm + path;
    },
    productionItemLink: (id: string) => {
        return PmRoutePath.productionItem.replace(':id', id);
    },
    productionItemDetailLink: (code: string) => {
        return PmRoutePath.productionItem.replace(':code', code);
    },
    productionItemGroupLink: (code: string) => {
        return PmRoutePath.productionItemGroup.replace(':code', code);
    },
    productionBatchLink: (id: string) => {
        return PmRoutePath.productionBatch.replace(':id', id);
    },
    afmeldenLink: (id: string) => {
        return PmRoutePath.afmelden.replace(':id', id);
    },
};

export const PmStationRoutes = () => {
    return (
        <Routes>
            {stationRoutes}
        </Routes>
    )
}

const PmRoutes = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
                <Route path={PmRoutePath.stationsDashboard} element={<ProductionDashboard />} />
                <Route path={PmRoutePath.planningDashboard} element={<PlanningDashboard />} />
                <Route path={PmRoutePath.productionItemList} element={<ProductionItemList />} />
                <Route path={PmRoutePath.productionItem} element={<ProductionItem />} />
                <Route path={PmRoutePath.productionItemOperationDetail} element={<ProductionItemOperationDetail />} />
                <Route path={PmRoutePath.productionItemGroup} element={<ProductionItemGroup />} />
                <Route path={PmRoutePath.productionBatchList} element={<ProductieBatchList />} />
                <Route path={PmRoutePath.productionBatch} element={<ProductionBatchPage />} />
                <Route path={PmRoutePath.createNeutenBatch} element={<CreateNeutenBatch />} />
                <Route path={PmRoutePath.afmeldenlanding} element={<AfmeldenLanding />} />
                <Route path={PmRoutePath.afmelden} element={<Afmelden/>} />
                {stationRoutes}
            </Route>
            <Route index element={<Navigate to={PmRoutePath.productionItemOperationDetail} />} />
        </Routes>
    )
}

const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

const stationRoutes =
    <>
        <Route path={PmRoutePath.productionItem} element={<ProductionItem />} />

        <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>

            <Route path={PmRoutePath.stationsDashboard} element={<ProductionDashboard />} />
            <Route path={PmRoutePath.station} element={<StationProductionTodo />} />
            <Route path={PmRoutePath.sawmaster} element={<SawMaster />} />
            <Route path={PmRoutePath.dorpelsBewerken} element={<DorpelsBewerken />} />
            <Route path={PmRoutePath.neutenMaken} element={<NeutenMaken />} />
            <Route path={PmRoutePath.neutenMonteren} element={<NeutenMonteren />} />
            <Route path={PmRoutePath.afmontage} element={<Afmontage />} />

            <Route path={PmRoutePath.sluitpottenGemonteerd} element={<SluitpottenMonteren />} />
            <Route path={PmRoutePath.lattenGemonteerd} element={<LattenMonteren />} />
            <Route path={PmRoutePath.kopisolatorenGemonteerd} element={<KopisolatorenMonteren />} />
            <Route path={PmRoutePath.manchettesGemonteerd} element={<ManchettesMonteren />} />
            <Route path={PmRoutePath.hefschuifVouwwand} element={<HefschuifVouwwand />} />

            <Route path={PmRoutePath.afmeldenlanding} element={<AfmeldenLanding />} />
            <Route path={PmRoutePath.afmelden} element={<Afmelden/>} />

            <Route path={PmRoutePath.inpakken} element={<Inpakken />} />
            <Route path={PmRoutePath.lattenMaken} element={<LattenMaken />} />
            <Route path={PmRoutePath.custom} element={<Custom />} />
            <Route path={PmRoutePath.productionBatchStationList} element={<ProductieBatchStationList />} />
            <Route path={PmRoutePath.productionItemOperationDetail} element={<ProductionItemOperationDetail />} />

        </Route>
        <Route index element={<Navigate to={PmRoutePath.link(PmRoutePath.productionItemOperationDetail)} />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
    </>

export default PmRoutes;
