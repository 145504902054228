import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {ImportValidation} from '../../../../../modules/api-client/generated';
import {AxiosError} from 'axios';
import {toast} from 'react-hot-toast';
import {isNotEmpty} from '../../../../../../_metronic/helpers';

export const useGetImportValidation = (id: string | undefined, enabled: boolean = true) => {
	const response = useQuery<ImportValidation, AxiosError, ImportValidation>(
		['aclImportValidationImportValidationIdGet', id],
		() => {
			return ApiClient.Acl.ImportValidation.aclImportValidationImportValidationIdGet(id!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(id),
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}
	return response;
};
