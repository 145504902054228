import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {ValidationFailed} from '../../../../../modules/api-client/Responses';
import {CreateImportProfileMappingCommand} from '../../../../../modules/api-client/generated';

export const usePostImportProfileMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<string, ValidationFailed, CreateImportProfileMappingCommand>(
		(command) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingProfilePost(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingImportedProfileMapsCustomerIdGet']);
				queryClient.invalidateQueries(['aclImportMappingProfileIdGet']);
			},
			onError: () => {
            	toast.error("Profiel mappen mislukt!");
			},
		}
	);
};
