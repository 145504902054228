import React from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import axios from 'axios';
import {BackofficeRoutePath} from '../../RoutePath';
import CatalogItemPage from './pages/CatalogItemPage';
import SillList from './pages/SillList';
import SillUpsert from './sill/SillUpsert';
import SillDetailsPage from './pages/SillDetailsPage';
import ProfileList from './pages/ProfileList';
import {RoutableSillSluitpotAreaEditorModal} from './sill/SillSluitpotAreaEditorModal';
import {ConfiguredSillDiffPage} from '../core/page/ConfiguredSillDiffPage';
import {GenerateCompartmentProfilesDialog} from './pages/GenerateCompartmentProfilesDialog';
import ConfiguredSillList from "./pages/ConfiguredSillList";
import NeutList from "./pages/NeutList";
import Neut from "./pages/Neut";
import {FilterContextProvider} from "../../../shared/context/FilterContext";
import SillProfilesPage from "./pages/SillProfilesPage";
import {generatePath} from "react-router";

export const PimRoutePath = {

    catalog: 'producten',
    configuredSills: 'geconfigureerde-dorpels',
    sills: 'dorpels',
    sillAdd: 'dorpels/add',
    sillEdit: 'dorpels/:id/edit',
    sillConfigurator: 'dorpels/:id/configurator',
    sillDetails: 'dorpels/:id',
    sillDetailsMounting: 'dorpels/:id/mounting',

    sillProfiles: 'dorpels/:id/profiles',

    neuten: 'neuten',
    neutDetails: 'neuten/:id',

    profilesAdd: 'profiles/add',
    profiles: 'profiles',

    configuredSillDiff: 'configured-sill/diff',

    link(path: string) {
        return BackofficeRoutePath.pim + path;
    },

    path(path: string, params?: object) {
        return generatePath(BackofficeRoutePath.pim + path, params);
    }
};

const PimRoutes: React.FC = () => {

    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={PimFallback}><Outlet/></ErrorBoundary>}>
                <Route path={PimRoutePath.catalog} element={<CatalogItemPage/>}/>
                <Route path={PimRoutePath.sills} element={<SillList/>}/>
                <Route path={PimRoutePath.sillAdd} element={<SillUpsert/>}/>
                <Route path={PimRoutePath.sillEdit} element={<SillUpsert/>}/>
                <Route path={PimRoutePath.sillDetails} element={<><SillDetailsPage/><Outlet/></>}>
                    <Route path={'sluitpot-areas/add'} element={<RoutableSillSluitpotAreaEditorModal/>}/>
                    <Route path={'sluitpot-areas/:sluitpotAreaId'}
                           element={<RoutableSillSluitpotAreaEditorModal/>}/>
                </Route>
                <Route path={PimRoutePath.sillProfiles}
                       element={<><SillProfilesPage/></>}>
                </Route>
                <Route path={PimRoutePath.profiles} element={<><ProfileList/><Outlet/></>}>
                    <Route path={'add/*'} element={<GenerateCompartmentProfilesDialog/>}/>
                </Route>
                <Route path={PimRoutePath.configuredSillDiff} element={<ConfiguredSillDiffPage/>}/>
                <Route path={PimRoutePath.configuredSills} element={<ConfiguredSillList/>}/>
                <Route path={PimRoutePath.neuten} element={<FilterContextProvider><NeutList/></FilterContextProvider>}/>
                <Route path={PimRoutePath.neutDetails} element={<Neut/>}/>

            </Route>
            <Route index element={<Navigate to={PimRoutePath.catalog}/>}/>
        </Routes>
    );
};

const PimFallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default PimRoutes;