import React from "react";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../modules/api-client/ApiClient";
import Loading from "./Loading";
import Error from "./Error";
import { AxiosResponse } from "axios";

export interface ConfiguredNeutSvgProps {
    catalogItemId?: string
    svg?: string;

    svgWidth?: string;
    svgHeight?: string;
}

const ConfiguredNeutSvg: React.FC<ConfiguredNeutSvgProps> = (props) => {

    const renderOnServer = !props.svg && !!(props.catalogItemId);
    const {
        isInitialLoading,
        data: serverSvg,
        isError
    } = useQuery<string, string, string>(
        ['renderConfiguredNeutGet', props.catalogItemId],
        async () => ApiClient.Pim.Render.renderConfiguredSillCatalogItemGet(props.catalogItemId, false).then((res: AxiosResponse<string>) => res.data),
        {
            enabled: renderOnServer,
        });

    if (renderOnServer && isInitialLoading) return <Loading message={'Neut tekening ophalen....'}/>;

    if (renderOnServer && isError) return <Error/>;

    const svg = renderOnServer ? serverSvg : props.svg;

    if (!svg) return <></>;

    return (
        <div className='sill-configuration-preview-container' style={{height: props.svgHeight}}
             dangerouslySetInnerHTML={{__html: svg}}/>
    );
};
export default ConfiguredNeutSvg;