import clsx from 'clsx';
import {FC, useState} from 'react'
import Select from "react-select"
import {build, FilterParameter, useEkosietFilter} from "../../../../shared/context/FilterContext";
import { useListNeutenOdata } from "../neut/use-list-neuten-odata";
import { NeutListItem } from '../../../../modules/api-client/generated';
import ApiClient from "../../../../modules/api-client/ApiClient";
import AsyncSelect from "react-select/async";

type Props = {
    fieldName: string;
    value?: string;
    onChange?: (id: string, code: string, svg: string) => void;
}


interface SelectOption {
    label: string;
    value: string | number;
    imageUrl?: string | null;
    svg?: string | null;
}
export const NeutPicker: FC<Props> = (props: Props) => {

    // const { filterQuery } = useEkosietFilter();
    // const {
    //     data: list,
    // } = useListNeutenOdata(undefined, undefined, undefined, filterQuery);
    //
    // let orderListOptions: any[] = []
    // if (list) {
    //     let orderOptions = list.value.map((item: NeutListItem) => {
    //         return { value: item.id, label: item.code, svg: item.svg }
    //     });
    //     orderListOptions = [...orderListOptions, ...orderOptions]
    // }
    //
    // let orderListSelectedValue =
    //     orderListOptions.find((option: any) => option.value === props.value) || null;



    const [options, setOptions] = useState<SelectOption[]>([]);

    let orderListSelectedValue =
        options.find((option: SelectOption) => option.value === props.value);
    
    // const {
    //     data: list,
    // } = useListNeutenOdata(15, undefined, undefined, filterQuery);
    //
    // let orderListOptions: any[] = []
    // if (list) {
    //     let orderOptions = list.value.map((item: NeutListItem) => {
    //         return { value: item.id, label: item.code, svg: item.svg }
    //     });
    //     orderListOptions = [...orderListOptions, ...orderOptions]
    // }

    const promiseOptions = (inputValue: string, callback: (options: SelectOption[]) => void) => {
        const getData = async () => {
            const arr: SelectOption[] = [];
            await ApiClient.Pim.Neuten.neutenList(15, undefined, 'Sold', undefined, inputValue, false).then((res: any) => {
                let result = res.data.value;
                result.map((neut: NeutListItem) => {
                    return arr.push({value: neut.id, label: neut.code, svg: neut.svg});
                });
                setOptions(arr)
                callback(arr);
            });
        };
        getData();
    }


    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={promiseOptions}
                defaultOptions
                className={clsx(
                    'w-250px',
                )}
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                classNamePrefix="select"
                defaultValue={orderListSelectedValue}
                value={orderListSelectedValue}
                formatOptionLabel={neut => (
                    <div className="">
                        {neut.label}
                    </div>
                )}
                placeholder="Selecteer een neut"
                isClearable={true}
                isSearchable={true}
                name={props.fieldName}
                onChange={(newValue) => {
                    if (props.onChange && newValue) {
                        props.onChange(newValue?.value.toString(), newValue?.label, newValue?.svg!)
                    }
                }}
            />
        </>
    )
}
