/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {EkoTable, TableHeader} from '../../components/table';
import {formatDate} from '../../components/date';
import {useEkosietFilter} from '../../context/FilterContext';
import { Pagination,} from 'react-bootstrap';
import {useListImportsOdata} from '../../../backoffice/modules/acl/import/hooks/use-list-import-odata';
import {ImportListView} from '../../../modules/api-client/generated';
import {ImportRoutePath} from '../routes/ImportRoutes';
import {usePostCopyGroeneveldImport} from '../../../backoffice/modules/acl/groeneveld/hooks/use-post-copy-groeneveld-import';
import ImportStatusBadge from "../../components/import/ImportStatusBadge";
import ImportTypeBadge from "../../components/import/ImportTypeBadge";
import {ImportDownloadButton} from "./ImportDownloadButton";

const ImportList: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [pageSize] = useState(150);
	const [currentPage, setPage] = useState(1);
	const [importCount, setImportCount] = useState(0);
	const [pageCount, setPageCount] = useState(1);
	const skip = (currentPage - 1) * pageSize;
	const {filterQuery} = useEkosietFilter();

	const {isInitialLoading, data: importList, isError} = useListImportsOdata(pageSize, skip, 'ImportDate desc', filterQuery, true);
	const mutationCopyImport = usePostCopyGroeneveldImport();

	useEffect(() => {
		const page = searchParams.get('page');
		const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

		if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
			setPage(pageParamNumber);
		}
	}, [pageCount, searchParams]);

	useEffect(() => {
		if (importList && importList['@odata.count']) {
			setImportCount(importList['@odata.count']!);
		}
	}, [importList]);

	useEffect(() => {
		if (importCount > 0) {
			setPageCount(Math.ceil(importCount / pageSize));
		}
	}, [importCount, pageSize]);

	const copy = async (importId: string) => {
		await mutationCopyImport.mutateAsync(importId);
	};

	const paginationItems = useMemo(() => {
		let items = [];
		for (let number = 1; number <= pageCount; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === currentPage}
					onClick={() => {
						setSearchParams(`?${new URLSearchParams({page: number.toString()})}`);
					}}
				>
					{number}
				</Pagination.Item>
			);
		}
		return items;
	}, [pageCount, currentPage, setSearchParams]);

	if (isInitialLoading) {
		return <>Laden...</>;
	}

	if (isError) {
		return <>Fout bij het ophalen van de imports!</>;
	}

	return (
		<>

			{(!importList || !importList.value) && <>Geen imports gevonden</>}

			<EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
				<TableHeader>
					<th className="min-w-100px">Klant</th>
					<th className="min-w-100px">Bestandsnaam</th>
					<th className="min-w-40px text-end">Type</th>
					<th className="min-w-100px text-end">Status</th>
					<th className="min-w-50px text-end">Acties</th>
				</TableHeader>
				<tbody>
				{importList &&
					importList.value &&
					importList.value.map((item: ImportListView, index) => (
						<tr key={item.id}>
							<td>
								<div
									className='text-dark d-block fs-6'>
									{item.customerName}
								</div>
								<div className={'text-muted'}>{item.title}</div>
								<div className={'text-muted'}>{item.importDate ? formatDate(item.importDate, "dd-MM-yyyy HH:mm:ss") : 'Onbekend'}</div>
								<div className={'text-muted'}> {item.importUser}</div>
							</td>
							<td>
								{item.fileName}&nbsp;<ImportDownloadButton importId={item.id}/>
							</td>
							<td className="text-end">
								<ImportTypeBadge type={item.importType}/>
							</td>
								<td className="text-end">
									<ImportStatusBadge status={item.status}/>
								</td>
							<td className="text-end">
								<Link to={'../'+ImportRoutePath.importLink(item.id)} className="btn btn-icon btn-sm btn-light me-2" title="Open Groeneveld Order">
									<i className="la la-eye"></i>
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</EkoTable>

			<div>
				{importList && importList.value && importList.value.length !== undefined && (
					<>
						<span className={`text-muted`}>
							{importList.value.length}/{importList['@odata.count']} imports
						</span>
						<Pagination className="justify-content-start mt-3">{paginationItems}</Pagination>
					</>
				)}
			</div>
		</>
	);
};

export {ImportList};
