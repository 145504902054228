/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useState } from 'react'
import { EkoTable } from "../../../../../shared/components/table/EkoTable";
import { dateToString } from '../../../../../shared/components/date';
import { NumericFormat } from "react-number-format";
import {
    usePutExpeditionDelivered,
    usePutExpeditionInTransit,
} from "../../../sm/orders/hooks/use-put-expedition-status";
import classNames from "classnames";
import { useGetShipmentDeliveryDetails } from '../hooks/use-get-shipment-delivery-details';
import { useParams } from 'react-router';
import { DetailModal } from './DetailModal';
import { useListTransportPlanningByOrder } from '../hooks/use-list-transport-planning-by-order-odata';

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export interface ExpeditionOrderDetailsProps {
    orderId: string,
    handleClose: () => void,
}

export const ExpeditionOrderDetails: React.FC<ExpeditionOrderDetailsProps> = ({ orderId, handleClose }) => {

    const {
        isInitialLoading,
        data,
        isError
    } = useGetShipmentDeliveryDetails(orderId);

    if (isInitialLoading) {
        return <>Laden...</>;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!data) return <></>;

    return (
        <>
            <h1>Order Aflevergegevens</h1>

            {data.address &&
                <>
                    <h3>Adres</h3>
                    <div>
                        {data.address.attention &&
                            <>
                                <span className={'fw-bold'}>{data.address.attention}</span><br />
                            </>
                        }
                        {data.address.street} {data.address.houseNumber} {data.address.houseNumberAddition}<br />
                        {data.address.extraAddressLine &&
                            <>
                                {data.address.extraAddressLine}<br />
                            </>
                        }
                        {data.address.postalCode} {data.address.city}

                        <br />{data.address.country}
                    </div>
                </>
            }

            {data.contactPerson &&
                <>
                    <h3>Contactpersoon</h3>
                    <div>
                        <span className={'fw-bold'}>{data.contactPerson.firstName} {data.contactPerson.lastName}</span>
                        <br />
                        {data.contactPerson.email}
                        {data.contactPerson.telephone &&
                            <>
                                <br />
                                {data.contactPerson.telephone}
                            </>
                        }
                    </div>

                </>
            }
        </>
    )
}

const $statusFilter = "(ExpeditionStatus eq 'Planned' or ExpeditionStatus eq 'ReadyForShipment' or ExpeditionStatus eq 'InTransit' or ExpeditionStatus eq 'Delivered')"

const TransportPlanningByWeekAndOrder: React.FC = () => {

    const [showModal, setShowModal] = useState<boolean>(false)
    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('xxl-down')
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    const { year, week } = useParams<{ year: string, week: string }>();

    const $filter = $statusFilter + `and PlannedExpeditionYear eq ${year} and PlannedExpeditionWeek eq ${week}`

    const inTransitMutation = usePutExpeditionInTransit();
    const deliveredMutation = usePutExpeditionDelivered();

    const {
        isInitialLoading,
        data: expeditionOrderWeekList,
        isError
    } = useListTransportPlanningByOrder($filter);

    if (isInitialLoading) {
        return <>Laden...</>;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!expeditionOrderWeekList || !expeditionOrderWeekList.value) return <></>;

    let sorted = expeditionOrderWeekList.value.sort((a, b) => {

        let bDate = (b.plannedExpeditionDate ? new Date(b.plannedExpeditionDate) : new Date());
        let aDate = (a.plannedExpeditionDate ? new Date(a.plannedExpeditionDate) : new Date());
        let x = +aDate - +bDate;
        return x;
    })

    let itemsByPlannedExpeditionDate = groupBy(sorted, eow => eow.plannedExpeditionDate || '')

    const setInTransitMutation = (orderId: string) => {
        return new Promise<void>(() => {
            inTransitMutation.mutate(orderId, {});
        }).finally();
    }
    const setDeliverdMutation = (orderId: string) => {
        return new Promise<void>(() => {
            deliveredMutation.mutate(orderId, {});
        }).finally();
    }

    const viewExpeditionDetail = (orderId: string) => {
        setModalComponent(<ExpeditionOrderDetails handleClose={() => setShowModal(false)} orderId={orderId} />);
        setModalTitle('Orderdetails');
        setModalFullscreen('xxl-down');
        setShowModal(true);
    }
    return (<>
        <DetailModal show={showModal} handleClose={() => setShowModal(false)}
            title={modalTitle}
            size={'xl'}
            fullscreen={modalFullscreen}
            component={modalComponent}
        />
        <div className="accordion" id="accordionExample">

            {itemsByPlannedExpeditionDate && Object.entries(itemsByPlannedExpeditionDate).map((groupedItem, index) => {

                const ordersByExpeditionDate = groupedItem[1]
                const firstItem = ordersByExpeditionDate[0]                              

                return (
                    <>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target={'#orders-for-' +  firstItem.plannedExpeditionDate} aria-expanded="true" aria-controls={firstItem.plannedExpeditionDate!}>
                                    <div className="d-flex flex-column justify-content-between align-items-center w-100 flex-lg-row">
                                        <h3 className="">
                                            {firstItem.plannedExpeditionDate && dateToString(firstItem.plannedExpeditionDate)}
                                        </h3>
                                        <div className={'me-1 me-lg-5'}>
                                            <div className={'d-flex my-lg-3 me-5'}>                                                
                                                {statSticker(firstItem.orderAddressCount?.toString() || '', 'Totaal Adressen')}
                                                {statSticker(ordersByExpeditionDate.length.toString(), 'Totaal Orders')}
                                                {statSticker(ordersByExpeditionDate.filter((o) => o.expeditionStatus === 'planned').length.toString(), 'Niet gereed')}
                                                {statSticker(ordersByExpeditionDate.filter((o) => o.expeditionStatus === 'readyForShipment').length.toString(), 'Ingepakt')}
                                                {statSticker(ordersByExpeditionDate.filter((o) => o.expeditionStatus === 'inTransit').length.toString(), 'Onderweg')}
                                                {statSticker(ordersByExpeditionDate.filter((o) => o.expeditionStatus === 'delivered').length.toString(), 'Afgeleverd')}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id={'orders-for-' + firstItem.plannedExpeditionDate!} className={`collapse show`} data-bs-parent="#accordionExample">
                                <div className={'accordion-body scroll mh-750px'}>
                                    {ordersByExpeditionDate && ordersByExpeditionDate.map((order) => {
                                                                                                                
                                        return (
                                            <>
                                                <EkoTable className={'card-xxl-stretch'}>
                                                    <colgroup>
                                                        <col width={'15%'} />
                                                        <col width={'25%'} />
                                                        <col width={'10'} />
                                                        <col width={'20%'} />
                                                        <col width={'10%'} />
                                                        <col width={'20%'} />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr key={order.orderId}
                                                            className={classNames(order.expeditionStatus === 'delivered' ? 'text-decoration-line-through text-muted' : '')
                                                            }
                                                        >
                                                            <td>
                                                                <a href={'#'} onClick={() => viewExpeditionDetail(order.orderId!)}
                                                                    className={classNames("btn btn-primary fs-6")}>
                                                                    {order.orderCode}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <span className='fs-6'>
                                                                    {order.customerCompanyName}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {order.orderDeliveryCity ? order.orderDeliveryCity : 'Onbekend'}
                                                            </td>
                                                            <td>
                                                                Langste dorpel:&nbsp;
                                                                <NumericFormat
                                                                    className={'me-1 text-nowrap fs-4'} decimalScale={2}
                                                                    value={order.maxSillLengthInOrder}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true} suffix={' m'} />
                                                            </td>
                                                            <td>
                                                                {order.sillsInOrder} dorpels
                                                            </td>
                                                            <td className={'d-flex justify-content-end'}>
                                                                {order.expeditionStatus === 'planned' &&
                                                                    <button className={`btn btn-warning`} disabled={true}
                                                                    >
                                                                        Order nog niet gereed
                                                                    </button>
                                                                }
                                                                {order.expeditionStatus === 'readyForShipment' &&
                                                                    <button className={`btn btn-info`}
                                                                        onClick={() => setInTransitMutation(order.orderId!)}>
                                                                        Order onderweg melden
                                                                    </button>
                                                                }
                                                                {order.expeditionStatus === 'inTransit' &&
                                                                    <button className={`btn btn-success`}
                                                                        onClick={() => setDeliverdMutation(order.orderId!)}>
                                                                        Afleveren
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </EkoTable>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}
        </div>
    </>
    )
}

export {TransportPlanningByWeekAndOrder}

const statSticker = (value:string, label:string) => {
    return (
        <div className="nav-item me-1 me-lg-6" role="presentation">
            <a className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden active
              p-1 py-lg-2 px-lg-2
             "
               id="kt_stats_widget_16_tab_link_5" data-bs-toggle="pill" href="#"
               aria-selected="true" role="tab">
                <div className="fs-lg-3 fs-7">
                    {value}
                </div>
                <span className="nav-text text-gray-800 fs-7 text-nowrap">
                    {label}
                </span>
            </a>
        </div>
    )
}